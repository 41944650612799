import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';
import { Icon } from '../../../../components/Images/Images';

export default function CardRankingUp(props) {
    const { description, image } = props;

    return (
        <Card className="card card-ranking-up">
            <FeedCardHeader {...props} />
            <CardContent className="d-flex flex-row pt-0">
                <div className="card-image-circle avatar mr-3">
                    <img src={image} alt={description} />
                </div>
                <div className="d-flex flex-row align-items-center">
                    <Icon ic="arrow_up" color="success" />
                    <Typography variant="body2" component="p">
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
