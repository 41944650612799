import React from 'react';
import ImgHandMobile from '../../../../../dist/img/intros/trail/ImgHandMobile';
import ImgFlashcardContent from '../../../../../dist/img/intros/trail/ImgFlashcardContent';
import ImgFlashcardNone from '../../../../../dist/img/intros/trail/ImgFlashcardNone';
import ImgWind from '../../../../../dist/img/intros/trail/ImgWind';

export default function CourseIntro() {
    return (
        <div id="intro-slider-trail">
            <div className="intro-content">
                <div className="wind">
                    <ImgWind />
                </div>
                <div className="hand-mobile">
                    <ImgHandMobile />
                    <div className="flashcard-none-2">
                        <ImgFlashcardNone />
                    </div>
                    <div className="flashcard-none-1">
                        <ImgFlashcardNone />
                    </div>
                    <div className="flashcard-content">
                        <ImgFlashcardContent />
                    </div>
                </div>
            </div>
        </div>
    )
}
