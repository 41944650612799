import * as React from "react"

function ImgBubble4(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M32.8 4H7.216c-1.76 0-3.202 1.47-3.202 3.267L4 26.87c0 1.805 1.432 3.269 3.2 3.269h2.4c.441 0 .8.365.8.816v3.408c0 1.456 1.723 2.184 2.731 1.155l4.8-4.9c.301-.308.708-.48 1.132-.48H32.8c1.76 0 3.2-1.47 3.2-3.267V7.267C36 5.47 34.56 4 32.8 4z"
                    className="svg-bubble"
                    fillRule="nonzero"
                />
                <path
                    d="M25.263 9a2.737 2.737 0 00-2.637 3.456l-.049.023-5.635 2.817-.048.024a2.737 2.737 0 00-2.157-1.057 2.737 2.737 0 102.159 4.417l.046.024 5.635 2.817.046.023a2.737 2.737 0 10.607-1.109l-.089-.044-5.634-2.817-.088-.044c.07-.35.069-.71 0-1.06l.088-.044 5.634-2.817.087-.043A2.737 2.737 0 1025.263 9z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export default ImgBubble4
