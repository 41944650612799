import React from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';

const TrailQuestionHeader = ({ data, type }) => {
    function calcProgress(correct, total) {
        return Math.round((correct / total) * 100);
    }

    return (
        <>
            <Grid container justify="center">
                <div className="question-star-progress">
                    {type !== 'CHECK' && (config.learn.trail.type === 'stars' && data.progress?.stars_positions) ? <>
                        {/* Star 1 */}
                        <div
                            className={"star" + (data.progress.right >= parseInt(data.progress.stars_positions[0]) ? " active" : "")}
                            style={{
                                left: `calc(${calcProgress(parseInt(data.progress.stars_positions[0]), data.progress.total)}% - 1.125rem)`
                            }}
                        >
                            <Icon ic="star_filled" className="icon-md" />
                        </div>

                        {/* Star 2 */}
                        <div
                            className={"star" + (data.progress.right >= parseInt(data.progress.stars_positions[1]) ? " active" : "")}
                            style={{
                                left: `calc(${calcProgress(parseInt(data.progress.stars_positions[1]), data.progress.total)}% - 1.125rem)`
                            }}
                        >
                            <Icon ic="star_filled" className="icon-md" />
                        </div>

                        {/* Star 3 */}
                        <div
                            className={"star" + (data.progress.right >= parseInt(data.progress.stars_positions[2]) ? " active" : "")}
                            style={{
                                left: `calc(${calcProgress(parseInt(data.progress.stars_positions[2]), data.progress.total)}% - 1.125rem)`
                            }}>
                            <Icon ic="star_filled" className="icon-md" />
                        </div>
                    </> : null}

                    {type === 'CHECK' || (type === 'EXAM' && !(config.learn.trail.type === 'stars' && data.progress?.stars_positions)) ? <div className="progress-content">
                        <LinearProgress variant="determinate" className="progress-bar __check"
                            value={calcProgress(data.progress.answered, data.progress.total)}
                        />
                    </div> : <div className="progress-content">
                        {/* Acertos */}
                        <LinearProgress variant="determinate" className="progress-bar __front"
                            value={calcProgress(data.progress.right, data.progress.total)}
                        />

                        {/* Respondidas */}
                        <LinearProgress variant="determinate" className="progress-bar"
                            value={calcProgress(data.progress.answered, data.progress.total)}
                        />
                    </div>}
                </div>
            </Grid>
            <Grid container justify="center">
                <Typography variant="body2" component="p">
                    {`${intl.get("LABEL_QUESTION")} ${parseInt(data.progress.answered) + 1} / ${data.progress.total}`}
                </Typography>
            </Grid>
        </>
    )
}

export default TrailQuestionHeader