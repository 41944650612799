import { periodicService } from '../../services/periodic.service';

// CONST
export const Types = {
    LIST: 'periodic/LIST',
    RESET: 'periodic/RESET',
    QUESTION: 'periodic/QUESTION',
    RESULT: 'periodic/RESULT',
};

// INITIAL STATE
const initialState = {
    list: {},
    question: {},
    result: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.QUESTION:
            return {
                ...state,
                question: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESULT:
            return {
                ...state,
                result: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                list: {},
                question: {},
                result: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getList(params) {
    return dispatch => {
        return periodicService.getList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.periodic_questions,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getQuestion(params) {
    return dispatch => {
        return periodicService.getQuestion(params).then(data => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getNextQuestion(params) {
    return dispatch => {
        return periodicService.getNextQuestion(params).then(data => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function sendAnswer(params) {
    return dispatch => {
        return periodicService.sendAnswer(params).then(data => {
            dispatch({
                type: Types.RESULT,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.RESULT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getList,
    getQuestion,
    getNextQuestion,
    sendAnswer,
    reset
}
