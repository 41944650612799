import * as React from "react"

function ImgIdentification(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M23 17.867C23 19.044 22.062 20 20.905 20H3.095C1.938 20 1 19.044 1 17.867V6.133C1 4.955 1.938 4 3.095 4h17.81C22.062 4 23 4.955 23 6.133v11.734z"
                    fill="#03A9F4"
                />
                <path
                    d="M9.381 10.4c0 1.178-.938 2.133-2.095 2.133-1.157 0-2.096-.955-2.096-2.133s.939-2.133 2.096-2.133S9.38 9.222 9.38 10.4m1.571 4.8s-1.015-1.6-3.666-1.6c-2.653 0-3.667 1.6-3.667 1.6v1.067h7.333V15.2zm9.429-6.4h-7.857v1.067h7.857V8.8zm0 2.667h-7.857v1.066h7.857v-1.066zm-3.667 2.666h-4.19V15.2h4.19v-1.067z"
                    fill="#E3F2FD"
                />
            </g>
        </svg>
    )
}

export default ImgIdentification
