import * as React from "react";

function ImgHelpEliminate2(props) {
    if (props.status === "disabled") {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path
                        className="svg-help-disabled-2"
                        d="M19.496 7.102l-5.973.709 3.5-6.525h-6.18L8.59 11.389l4.605-.568-5.126 11.894 5.126-3.215-1.96-.402z"
                    />
                    <path
                        className="svg-help-disabled-1"
                        d="M8.59 11.388l2.253-10.102H5.649L4.504 15.062l4.527-.683-.773 3.815-1.632-.581 1.444 5.101 5.124-11.893z"
                    />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path
                        fill="#F8A600"
                        d="M19.496 7.102l-5.973.709 3.5-6.525h-6.18L8.59 11.389l4.605-.568-5.126 11.894 5.126-3.215-1.96-.402z"
                    />
                    <path
                        fill="#FDC436"
                        d="M8.59 11.388l2.253-10.102H5.649L4.504 15.062l4.527-.683-.773 3.815-1.632-.581 1.444 5.101 5.124-11.893z"
                    />
                </g>
            </svg>
        )
    }
}

export default ImgHelpEliminate2
