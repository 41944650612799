import React from 'react';
import intl from 'react-intl-universal';
import Resizer from 'react-image-file-resizer';
import { imageParser } from '../utils/utils';
import { Button, Grid, Avatar } from '@material-ui/core';
import { Icon } from './Images/Images';

export default function SelectAvatar(props) {
    return (
        <Grid container justify="center">
            <div className="position-relative">
                <input
                    id="contained-button-file"
                    type="file"
                    className="d-none"
                    accept="image/*"
                    multiple
                    onChange={(e) => getFile(e, props)}
                />
                <label htmlFor="contained-button-file">
                    {
                        props.type === "button" ?
                            <Button variant="outlined" color="primary" component="span">
                                <Icon ic="camera" className="mr-2" />
                                {intl.get('BTN_SELECT_PHOTO')}
                            </Button>
                            :
                            <div className="cursor-pointer">
                                <Avatar alt={props.alt} src={props.blob ? imageParser(props.blob) : "/img/img_avatar_user.png"} className="avatar avatar-xl avatar-bordered-lg avatar-bordered" />
                                <div className="edit-photo">
                                    <Icon ic="camera" />
                                </div>
                            </div>
                    }
                </label>
            </div>
        </Grid>
    )
}

async function getFile(e, props) {
    if (e.target.files && e.target.files.length > 0) {
        var fileInput = false;
        if (e.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            Resizer.imageFileResizer(
                e.target.files[0],
                1280,
                1280,
                'JPEG',
                100,
                0,
                uri => {
                    props.imageDataUrl(uri);
                    document.getElementById("contained-button-file").value = "";
                },
                'base64'
            );
        }
    }
}