// CONST
export const Types = {
    SHOW: 'scorm/SHOW',
};

// INITIAL STATE

const initialState = {
    showingAppbarImg: false,
}

// REDUCER
export default function reducer (state = initialState, action) {
    switch (action.type) {
        case Types.SHOW:
            return {
                ...state,
                showingAppbarImg: action.payload.hidden,
            }
        default:
            return state
    }
}

// ACTIONS
function setShowingAppbarImg (params) {
    return dispatch => {
        dispatch({
            type: Types.SHOW,
            payload: {
                hidden: params.hidden,
            }
        });
    }    
}

export const actions = {
    setShowingAppbarImg,
}
