import * as React from "react"

function ImgHandshake(props) {
    return (
        <svg width={360} height={212} viewBox="0 0 360 212" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M223.44 149.99a7.315 7.315 0 10-14.63 0 7.315 7.315 0 0014.63 0"
                    className="svg-hand-left"
                />
                <path
                    d="M144.747 107.494s7.3 4.404 18.194 3.129c10.894-1.275 55.975 34.304 55.975 34.304l-36.621 23.757c-4.938 3.206-11.16 3.74-16.558 1.388-10.39-4.529-26.831-12.836-32.232-22.017 0 0-2.55-5.794-6.258-8.228l17.5-32.333z"
                    className="svg-hand-left"
                />
                <path
                    d="M219.614 143.559c-.358.67-.573 1.18-.64 1.425-.01.038-.063.048-.152.031l-.024-.012.118-.077s-45.08-35.578-55.975-34.303c-.5.059-.994.105-1.479.14-3.89 3.045-8.246 7.664-11.921 14.593 0 0-.598 2.986 1.493 3.583 2.09.597 14.331.299 21.199-9.554 0 0 40.756 27.768 46.728 25.678a2.274 2.274 0 001.29-1.114 7.317 7.317 0 00-.637-.39"
                    fillOpacity={0.1}
                    fill="#000"
                />
                <path
                    d="M216.639 103.18s-9.284 4.56-23.118-1.04c-13.833-5.598-28.9-6.872-36.2 6.223-7.301 13.096-11.01 16.225-7.533 18.543 3.477 2.317 18.542-2.666 22.483-10.778 0 0 46.356 30.131 46.703 28.857.348-1.275 4.668-9.695 12.706-10.758l-15.04-31.047z"
                    className="svg-hand-right"
                />
                <path
                    d="M231.68 134.227l-15.04-31.047s-2.033.998-5.612 1.486l13.928 32.488c1.832-1.43 4.077-2.578 6.725-2.928"
                    fillOpacity={0.1}
                    fill="#000"
                />
                <path
                    d="M187.981 169.975a6.868 6.868 0 00-10.057-7.068l-14.332-6.96a5.467 5.467 0 00-4.801 9.824l18.093 8.82c.326.159.672.29 1.034.397a6.869 6.869 0 009.795-3.89 3.5 3.5 0 00.268-1.123"
                    className="svg-hand-left"
                />
                <path
                    d="M238.006 132.854l-20.184-34.96a1.024 1.024 0 00-1.398-.374l-6.256 3.612c-.49.282-.658.908-.375 1.398l20.184 34.96c.283.49.91.657 1.399.375l6.255-3.612c.49-.283.658-.909.375-1.399"
                    fill="#FFF"
                />
                <path
                    d="M217.822 97.894a1.024 1.024 0 00-1.398-.374l-2.787 1.609 16.372 29.512-3.583 2.836 3.551 6.013c.283.49.909.657 1.399.375l6.255-3.612c.49-.283.658-.909.375-1.399l-20.184-34.96z"
                    fillOpacity={0.2}
                    fill="#000"
                />
                <path
                    d="M147.594 104.961l-6.274-3.622a1.013 1.013 0 00-1.384.37l-20.195 34.979a1.013 1.013 0 00.371 1.384l6.274 3.622c.485.28 1.104.114 1.384-.37l20.195-34.979a1.013 1.013 0 00-.371-1.384"
                    fill="#FFF"
                />
                <path
                    d="M147.594 104.961l-6.274-3.622a1.013 1.013 0 00-1.384.37l-20.195 34.979a1.01 1.01 0 00-.105.75l19.852-34.385c.28-.484.9-.65 1.384-.37l6.274 3.622c.248.143.412.376.476.634l.343-.594a1.013 1.013 0 00-.371-1.384"
                    fill="#FFF"
                />
                <path
                    d="M126.102 132.97l15.336-29.16a14.06 14.06 0 00-.202-2.514 1.013 1.013 0 00-1.3.414l-20.195 34.978a1.013 1.013 0 00.371 1.384l6.274 3.622c.485.28 1.104.114 1.384-.37l2.97-5.143-4.638-3.21z"
                    fillOpacity={0.2}
                    fill="#000"
                />
                <path
                    d="M367 65.924V0L211.8 93.87a1.273 1.273 0 00-.39 1.757l29.558 46.479c.252.395 42.262-24.999 126.032-76.182z"
                    className="svg-suit-right"
                />
                <path
                    d="M-8.395 0l.186 66.752 123.372 75.641a1.466 1.466 0 002.023-.45l29.352-46.153a1.465 1.465 0 00-.45-2.023L-8.395 0z"
                    className="svg-suit-left"
                />
                <path
                    d="M366.791 65.924c.395-.251.465-5.747.209-16.489l-131.617 78.758-19.868-26.111 25.453 40.024a1.273 1.273 0 001.757.39l124.066-76.572z"
                    fillOpacity={0.1}
                    fill="#000"
                />
                <path
                    d="M192.387 154.543c-.513 0-1.015.04-1.504.11l-12.661-7.062c-3.107-1.793-6.92-1.006-8.516 1.76-1.597 2.764-.372 6.46 2.735 8.253l11.513 6.608c1.091 3.086 4.453 5.335 8.433 5.335 4.865 0 8.808-3.36 8.808-7.502 0-4.143-3.943-7.502-8.808-7.502"
                    className="svg-hand-left"
                />
                <path
                    d="M206.446 149.796l-15.029-8.435c-3.107-1.794-7.04-.797-8.786 2.227-1.746 3.023-.642 6.928 2.465 8.722l12.33 7.59c1.252 2.845 4.472 4.87 8.248 4.87 4.865 0 8.808-3.36 8.808-7.503 0-3.921-3.533-7.138-8.036-7.471"
                    className="svg-hand-left"
                />
                <path
                    d="M149.71 153.722l-5.548 6.393a5.266 5.266 0 11-7.955-6.902l5.547-6.393a5.266 5.266 0 117.955 6.902"
                    className="svg-hand-right"
                />
                <path
                    d="M159.548 158.107l-6.944 8.003a5.266 5.266 0 01-7.954-6.902l6.943-8.003a5.266 5.266 0 017.955 6.902"
                    className="svg-hand-right"
                />
                <path
                    d="M170.009 162.437l-6.761 7.792a5.647 5.647 0 11-8.531-7.402l6.76-7.792a5.647 5.647 0 018.532 7.402"
                    className="svg-hand-right"
                />
                <path
                    d="M177.262 170.016l-4.01 4.62a5.266 5.266 0 01-7.954-6.901l4.01-4.621a5.266 5.266 0 117.954 6.902"
                    className="svg-hand-right"
                />
                <path
                    d="M139.095 107.494l-16.427 19.803L-8.395 46.62v20.133l123.558 75.641a1.465 1.465 0 002.023-.45l11.98-18.839 9.929-15.61z"
                    fillOpacity={0.1}
                    fill="#000"
                />
                <path
                    d="M112.666 127.596a4.18 4.18 0 10-8.36 0 4.18 4.18 0 008.36 0m-11.944-6.569a4.18 4.18 0 10-8.36 0 4.18 4.18 0 008.36 0"
                    className="svg-suit-left-button"
                />
                <path
                    d="M255.836 127.596a4.18 4.18 0 10-8.36 0 4.18 4.18 0 008.36 0m11.495-6.569a4.18 4.18 0 10-8.36 0 4.18 4.18 0 008.36 0"
                    className="svg-suit-right-button"
                />
            </g>
        </svg>
    )
}

export default ImgHandshake
