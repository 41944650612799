import * as React from "react";

const ImgBadgeStars = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={240} height={240} viewBox="0 0 240 240" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFD137"
                className="svg-badge-stars-primary"
                d="M217.202 197.168a9.831 9.831 0 0 0 7.63 7.63.205.205 0 0 1 0 .404 9.831 9.831 0 0 0-7.63 7.63.205.205 0 0 1-.404 0 9.834 9.834 0 0 0-7.63-7.63.205.205 0 0 1 0-.404 9.834 9.834 0 0 0 7.63-7.63.205.205 0 0 1 .404 0M195.15 12.126a7.375 7.375 0 0 0 5.723 5.723.153.153 0 0 1 0 .302 7.375 7.375 0 0 0-5.723 5.723.154.154 0 0 1-.303 0 7.375 7.375 0 0 0-5.722-5.723.153.153 0 0 1 0-.302 7.375 7.375 0 0 0 5.722-5.723.154.154 0 0 1 .303 0M25.252 29.21a12.294 12.294 0 0 0 9.54 9.54c.278.055.278.445 0 .502a12.295 12.295 0 0 0-9.54 9.538c-.057.28-.447.28-.503 0a12.295 12.295 0 0 0-9.54-9.538c-.279-.057-.279-.447 0-.503a12.294 12.294 0 0 0 9.54-9.54c.056-.279.446-.279.503 0"
            />
            <path
                className="svg-badge-stars-secondary"
                d="M197.001 205a3 3 0 1 1-6 0 3 3 0 0 1 6 0m20-20a4 4 0 1 1-8 0 4 4 0 0 1 8 0M199 38a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-22-17a3 3 0 1 1-6 0 3 3 0 0 1 6 0M28 65a4 4 0 1 1-8 0 4 4 0 0 1 8 0m23-31a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
            />
        </g>
    </svg>
)

export default ImgBadgeStars
