import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Grid, Typography, Hidden, IconButton } from '@material-ui/core';
import FormLoginEmail from './components/FormLoginEmail';
import FormLoginUsername from './components/FormLoginUsername';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import Intro from './components/IntroLayout';

export default function Login(props) {
    const auth = useSelector(state => state.auth)
    const { history } = useReactRouter();
    const loading = useSelector(state => state.auth.loading);
    const [redirect, setRedirect] = useState(false);
    const type = props.location.state && props.location.state.type ? props.location.state.type : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        if ((config.login_method.email.login.enabled || config.login_method.username.login.enabled) && origin === "intro") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (auth.authenticated === true && Object.keys(auth.user).length !== 0) {
            if (auth.user.answered_secret_questions && auth.user.answered_secret_questions === 'N') {
                history.push({ pathname: '/secret-questions', state: { redirect: "N" } })
            } else if (auth.user.is_temporary_password === "Y") {
                history.push({ pathname: '/temporary-password', state: { jwt: auth.jwt, redirect: "N" } })
            } else {
                if (auth.user.is_expired_password === "Y") {
                    history.push({ pathname: '/expired-password', state: { jwt: auth.jwt, redirect: "N" } })
                } else if (auth.user.pending_data === "Y") {
                    history.push({ pathname: '/complete-data', state: { redirect: "N" } });
                } else {
                    history.replace('/home')
                }
            }
        }
    }, [auth, history, origin]);

    return (
        !redirect ?
            <Intro title={intl.get("INTRO_LOGIN_TITLE")} id="intro-login" char="intro-login" appbarMobile={config.intro.vector_images ? "overlay" : "default"}>
                <Grid container>
                    <Grid container direction="column" alignItems="center" justify="center" className={`${!loading ? 'clear' : ''}`}>
                        <LoadingBounce />
                    </Grid>
                    <Grid container className={`${loading ? 'clear' : ''}`}>
                        <Hidden smDown>
                            <Grid container className="intro-column-nav" justify="center" alignItems="center">
                                <Grid item xs={2}>
                                    <IconButton aria-label={intl.get('BTN_BACK')} onClick={() => history.push('/')}>
                                        <Icon ic="back" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography component="h2" variant="h3" align="center" className="text-dark">
                                        {intl.get('INTRO_LOGIN_TITLE')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                        </Hidden>

                        {
                            type === "email" ?
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormLoginEmail page={true} />
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormLoginUsername page={true} />
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                </Grid>
            </Intro>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
};
