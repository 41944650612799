import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ClassroomActions } from '../../../../redux/ducks/classroom_course';
import QrReader from 'react-qr-reader'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import SnackBar from '../../../../components/snackBar';
import { Icon } from '../../../../components/Images/Images';

export default function QrCodeReader(props) {
    const dispatch = useDispatch();
    const classroom = useSelector(state => state.classroom_course);
    const [delay] = useState(100);
    const camTypes = ['environment', 'user'];
    const [camMode, setCamMode] = useState(camTypes[0]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackStatus, setSnackStatus] = useState('error');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleScan(data) {
        if (data && !loading) {
            let t = data.substring(data.indexOf('=') + 1, data.indexOf('&'));
            let cod = data.substring(data.indexOf('cod=') + 4);

            if (t.length !== 0 && cod.length !== 0 && t === props.id_training && cod === props.class_id) {
                setLoading(true);
                await dispatch(ClassroomActions.presence({
                    id_class: cod,
                    id_training: t,
                    presence: 'Y'
                }));
                handleClose();
            } else {
                setSnackMessage(intl.get('CLASSROOM_QRCODE_ERROR_TEXT'));
                setSnackOpen(true);
            }
        }
    }

    const handleError = (err) => {
        handleClose()
        setSnackMessage(err.localized_messages ? err.localized_messages[0] : intl.get('CLASSROOM_QRCODE_ERROR_TEXT'));
        setSnackOpen(true);
        setSnackStatus('error');
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (classroom.error) {
        handleClose();
    }

    return (
        <div>
            <Button id="btn_classroom_ticket_qrcode" variant="contained" className="btn-block" onClick={handleClickOpen}>
                <Icon ic="camera" className="mr-2" /> {intl.get('CLASSROOM_TICKET_QRCODE_BUTTON')}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick
            >
                <DialogContent>
                    <QrReader
                        delay={delay}
                        className="qrcode-size"
                        onError={handleError}
                        onScan={handleScan}
                        facingMode={camMode}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        {intl.get('BTN_CANCEL')}
                    </Button>
                    <Button color="primary" onClick={() => setCamMode(camMode === camTypes[0] ? camTypes[1] : camTypes[0])}>
                        <Icon ic="flip_camera" className="mr-1" /> {intl.get('BTN_FLIP_CAMERA')}
                    </Button>
                </DialogActions>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => { setSnackOpen(false); }}
                />
            </Dialog>
        </div>
    )
}
