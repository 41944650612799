import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import config from '../../../config';

import ClassroomList from './list.classroom';
import ClassroomDetails from './details';

import ClassroomTicket from './ticket';
import Survey from '../../../components/Survey';

export default function ClassroomCourse(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <Switch>
                    <Route path={`${props.match.path}`} exact component={(props) =>
                        <ClassroomList {...props} />}
                    />
                    <Route path={`${props.match.path}/:idClassroomCourse`} exact component={(props) =>
                        <ClassroomDetails {...props} />}
                    />

                    <Route path={`${props.match.path}/:idClassroomCourse/ticket/:idClass`} exact component={(props) =>
                        <ClassroomTicket {...props}
                        />}
                    />

                    <Route path={`${props.match.path}/:idClassroomCourse/survey`} exact component={
                        (props) => <Survey {...props} />}
                    />
                </Switch>
            }
        </div>
    )
};
