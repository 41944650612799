import React from 'react';
import intl from 'react-intl-universal';
import { Grid, List, Button } from '@material-ui/core';

function SectionList(props) {
    const { children, action, className, seeAllButton } = props;

    return (
        <Grid container>
            <List component="nav" className={`w-100 ${className}`}>
                {children}
            </List>
            {
                seeAllButton ?
                    <Grid container className="p-3" justify="center">
                        <Button
                            onClick={action}
                            className="btn-block-xs"
                            variant="outlined"
                            color="primary"
                        >
                            {intl.get("BUTTON_SEE_ALL")}
                        </Button>
                    </Grid>
                    :
                    null
            }
        </Grid>
    );
}

export default SectionList;