import * as React from "react"

const OngoingCourses = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" fill-rule="evenodd">
            <path fill="#2B97DD" d="M12 2c-2.3749 0-4.5577.9029-6.2344 2.3958-.2288.1898-.34.4945-.29.7947.0501.3002.2535.548.5304.6463.2768.0982.5831.0312.7987-.1747C8.2033 4.4167 10.0133 3.6667 12 3.6667c4.1572 0 7.5608 3.2816 7.961 7.5H17.6l3.2 5 3.2-5h-2.4406C21.1504 6.043 17.0223 2 12 2ZM3.2 7.8333l-3.2 5h2.4406C2.8496 17.957 6.9777 22 12 22c2.3749 0 4.5577-.9029 6.2344-2.3958.2288-.1898.34-.4945.29-.7947-.0501-.3002-.2535-.548-.5303-.6463-.277-.0982-.5832-.0312-.7988.1747-1.3986 1.2454-3.2086 1.9954-5.1953 1.9954-4.1572 0-7.5608-3.2816-7.961-7.5H6.4l-3.2-5Z" />
            <path fill="#37474F" fill-rule="nonzero" d="M15 9.9814H9s-.75 2.4793-.75 4.4628l3.75.248 3.75-.248c0-1.9835-.75-4.4628-.75-4.4628Z" />
            <path fill="#37474F" fill-rule="nonzero" d="M12 11.717c-2.071 0-3.75 1.9056-3.75 2.7272 2.5.248 3.7415 1.9773 3.7415 1.9773l.0085.0062.0085-.0062S13.25 14.692 15.75 14.4442c0-.8216-1.679-2.7273-3.75-2.7273Z" />
            <path fill="#78909C" fill-rule="nonzero" d="M11.775 7.0558 6.15 9.5103a.26.26 0 0 0 0 .4711l5.625 2.4546c.15.0495.3.0495.45 0l5.625-2.4546a.26.26 0 0 0 0-.471l-5.625-2.4546c-.15-.0744-.3-.0744-.45 0Z" />
            <path fill="#FFB300" fill-rule="nonzero" d="M7.25 15.436a.249.249 0 0 1-.25-.248v-4.9587c0-.122.0895-.2258.211-.245l4.75-.7437c.1355-.0206.2645.0709.286.2063a.2483.2483 0 0 1-.208.2836l-4.539.7106v4.747a.249.249 0 0 1-.25.2479Z" />
            <path fill="#FFB300" fill-rule="nonzero" d="M7.25 15.188c.425 0 .75.3223.75.7438 0 .4215-.75 1.4876-.75 1.4876s-.75-1.066-.75-1.4876c0-.4215.325-.7438.75-.7438Z" />
            <path fill="#FFCA28" fill-rule="nonzero" d="M7.25 14.6921c-.2761 0-.5.222-.5.496 0 .2738.2239.4958.5.4958s.5-.222.5-.4959c0-.2738-.2239-.4959-.5-.4959Z" />
        </g>
    </svg>
)

export default OngoingCourses
