import { scormService } from '../../services/scorm.service';

// CONST
export const Types = {
    LIST: 'scorm/LIST',
    THEMES: 'scorm/THEMES',
    DETAILS: 'scorm/DETAILS',
    OPEN: 'scorm/OPEN',
    RESET: 'scorm/RESET',
};

// INITIAL STATE
const initialState = {
    themes: {},
    scorms: {},
    details: {},
    package: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.THEMES:
            return {
                ...state,
                themes: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LIST:
            return {
                ...state,
                scorms: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.DETAILS:
            return {
                ...state,
                details: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.OPEN:
            return {
                ...state,
                package: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                themes: action.payload.themes,
                scorms: action.payload.trails,
                details: action.payload.details
            }
        default:
            return state
    }
}

// ACTIONS
function getThemesList(params) {
    return dispatch => {
        return scormService.themes(params).then(data => {
            dispatch({
                type: Types.THEMES,
                payload: {
                    data: data.categories,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.THEMES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormList(params) {
    return dispatch => {
        return scormService.list(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.scorms,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getDetails(params) {
    return dispatch => {
        return scormService.details(params).then(data => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function packageOpen(params) {
    return dispatch => {
        return scormService.packageOpen(params).then(data => {
            dispatch({
                type: Types.OPEN,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.OPEN,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET,
            payload: {
                themes: {},
                scorms: {},
                details: {},
                package: {}
            }
        });
    }
}

export const actions = {
    getThemesList,
    getScormList,
    getDetails,
    packageOpen,
    reset
}
