import * as React from "react"

function ImgBubble1(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M7.2 4h25.584c1.76 0 3.202 1.47 3.202 3.267L36 26.87c0 1.805-1.432 3.269-3.2 3.269h-2.4c-.441 0-.8.365-.8.816v3.408c0 1.456-1.723 2.184-2.731 1.155l-4.8-4.9a1.582 1.582 0 00-1.132-.48H7.2c-1.76 0-3.2-1.47-3.2-3.267V7.267C4 5.47 5.44 4 7.2 4z"
                    className="svg-bubble"
                    fillRule="nonzero"
                />
                <path
                    d="M13.714 10C12.77 10 12 10.816 12 11.818V21.92c0 1.448 1.11 2.626 2.476 2.626h11.048c1.365 0 2.476-1.178 2.476-2.626v-6.868c0-.934-.669-1.696-1.524-1.798v8.262c0 .335-.256.606-.571.606-.316 0-.572-.271-.572-.606v-9.697c0-1.002-.769-1.818-1.714-1.818h-9.905zm1.143 3.636h7.62c.315 0 .57.272.57.606 0 .335-.255.606-.57.606h-7.62c-.316 0-.571-.271-.571-.606 0-.334.255-.606.571-.606zm0 3.233h2.667c.316 0 .571.271.571.606v2.828c0 .335-.255.606-.571.606h-2.667c-.316 0-.571-.271-.571-.606v-2.828c0-.335.255-.606.571-.606zm4.953 0h2.666c.316 0 .572.271.572.606 0 .334-.256.606-.572.606H19.81c-.316 0-.572-.272-.572-.606 0-.335.256-.606.572-.606zm-4.381 1.212v1.616h1.523v-1.616H15.43zm4.38 1.616h2.667c.316 0 .572.271.572.606 0 .335-.256.606-.572.606H19.81c-.316 0-.572-.271-.572-.606 0-.335.256-.606.572-.606z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export default ImgBubble1
