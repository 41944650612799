import React, {useEffect, useState} from 'react';
import useReactRouter from 'use-react-router';
import {useSelector, useDispatch} from 'react-redux';
import {actions as AuthActions} from '../../../redux/ducks/auth';
import {LoadingContent} from '../../../components/Loading';

export default function AuthProjectSignin(props) {
    const dispatch = useDispatch();
    const {history, match} = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [didMount, setDidMount] = useState(true);
    let token = props.match.params.token ? props.match.params.token : '';

    useEffect(() => {
        if (didMount && token) {
            setDidMount(false)
            localStorage.clear();
            dispatch(AuthActions.login({
                login: token, auth_provider: 'YAMAHA'
            }));
        }

        if (!token) {
            history.push("/");
        }

        return () => {
            setDidMount(false);
        }
    }, [token, didMount, dispatch, history]);

    useEffect(() => {
        if (auth.authenticated === true && Object.keys(auth.user).length !== 0) {
            if (auth.user.answered_secret_questions && auth.user.answered_secret_questions === 'N') {
                history.push({pathname: '/secret-questions', state: {redirect: "N"}})
            } else if (auth.user.is_temporary_password === "Y") {
                history.push({pathname: '/temporary-password', state: {jwt: auth.jwt, redirect: "N"}})
            } else {
                if (auth.user.is_expired_password === "Y") {
                    history.push({pathname: '/expired-password', state: {jwt: auth.jwt, redirect: "N"}})
                } else if (auth.user.pending_data === "Y") {
                    history.push({pathname: '/complete-data', state: {redirect: "N"}});
                } else {
                    if (match.path === '/auth/project/signin/course/:token') {
                        history.replace('/trail');
                    } else if (match.path === '/auth/project/signin/profile/:token') {
                        history.replace('/profile');
                    } else {
                        history.replace('/home')
                    }
                }
            }
        }

        if (auth.loginError && auth.loginError.length > 0) {
            history.push("/");
        }
    }, [history, auth, match]);

    return <LoadingContent/>;
}