import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import config from "../../../config";
import { classroomService } from '../../../services/classroom.service';
import {
    Container, Grid, List, Divider, InputBase, IconButton, useMediaQuery,
    AppBar, Toolbar, Typography, Tabs, Tab
} from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import ClassroomCategoryItem from './components/ClassroomCategoryItem';
import ClassroomListItem from './components/ClassroomListItem';
import { LoadingListAvatar2, LoadingCategoryItem } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

function tabsProps(index) {
    return {
        id: `appbar-tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid container
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </Grid>
    );
}

export default function ClassroomList(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [value, setValue] = useState(props.location.state ? props.location.state.tab ? props.location.state.tab : 0 : 0);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [upcomingList, setUpcomingList] = useState([]);
    const [availableList, setAvailableList] = useState([]);
    const [previousList, setPreviousList] = useState([]);
    const [loading, setLoading] = useState("loading");
    const [didMount, setDidMount] = useState(false);
    const [query, setQuery] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const limit = 10;
    let notMatched = 0;

    useEffect(() => {
        if (auth.authenticated === (false || null)) {
            history.replace('/');
        }
    }, [auth, history]);

    useEffect(() => {
        setDidMount(true);
        if (didMount) {
            Promise.all([
                classroomService.list({}),
                classroomService.listUpcoming({}),
                classroomService.listPrevious({})
            ]).then(([available, upcoming, previous]) => {
                setAvailableList(available.list_training);
                setUpcomingList(upcoming.list_training_class);
                setPreviousList(previous.list_training);
                setLoading("finished");
            }).catch(err => {
                console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
                setLoading("error");
            })
        }
        return () => {
            setDidMount(false);
        }
    }, [didMount])

    function noResultsFound(list) {
        notMatched++;

        if (notMatched === list.length && list.length > 0)
            return (
                <EmptyContent key={notMatched} icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            );
    }

    const UpcomingList = () => {
        return (
            <List className="classroom-list">
                {
                    upcomingList.map((item, index) => {
                        let title = item.training_name && config.app.name_project !== 'FLEURY' ? item.training_name : item.training_theme;

                        if (title.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').includes(query.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''))) {
                            return (
                                <div key={index}>
                                    <ClassroomListItem
                                        action={() => history.push({ pathname: `${props.match.path}/${item.id_training}` })}
                                        title={title}
                                        theme={item.training_theme}
                                        image={item.training_image}
                                        dateStart={item.date_start_class}
                                        dateEnd={item.date_end_class}
                                    />
                                    {
                                        index < upcomingList.length - 1
                                            ?
                                            <Divider variant="inset" />
                                            : null
                                    }
                                </div>
                            )
                        } else {
                            return (
                                noResultsFound(upcomingList)
                            )
                        }
                    })
                }
            </List>
        )
    }

    const AvailableList = () => {
        return (
            availableList.map((item, index) => {
                let title = item.training_name && config.app.name_project !== 'FLEURY' ? item.training_name : item.training_theme;

                if (title.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').includes(query.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''))) {
                    return (
                        <Grid item key={index} xs={6} sm={3} md={4} lg={3}>
                            <ClassroomCategoryItem
                                action={() => history.push({ pathname: `${props.match.path}/${item.id_training}` })}
                                title={title}
                                image={item.training_image}
                                dateStart={item.date_start_training}
                                dateEnd={item.date_end_training}
                                subscriptions={item.total_subscription}
                                capacity={item.room_capacity}
                                userSubscription={item.user_subscription === "Y" ? true : false}
                            />
                        </Grid>
                    )
                } else {
                    return (
                        noResultsFound(availableList)
                    )
                }
            })
        )
    }

    const PreviousList = () => {
        return (
            previousList.map((item, index) => {
                let title = item.training_name && config.app.name_project !== 'FLEURY' ? item.training_name : item.training_theme;

                if (title.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').includes(query.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''))) {
                    return (
                        <Grid item key={index} xs={6} sm={3} md={4} lg={3}>
                            <ClassroomCategoryItem
                                action={() => history.push({ pathname: `${props.match.path}/${item.id_training}` })}
                                title={title}
                                image={item.training_image}
                                dateStart={item.date_start_training}
                                dateEnd={item.date_end_training}
                                subscriptions={item.total_subscription}
                                capacity={item.room_capacity}
                                userSubscription={item.user_subscription === "Y" ? true : false}
                            />
                        </Grid>
                    )
                } else {
                    return (
                        noResultsFound(previousList)
                    )
                }
            })
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const LoadingList = (props) => {
        const { limit, type } = props;
        var loadingList = [];

        for (var i = 0; i < limit; i++) {
            if (type && type === "upcoming") {
                loadingList.push(
                    <div key={i}>
                        <LoadingListAvatar2 />
                    </div>
                );
            } else {
                loadingList.push(
                    <Grid item key={i} xs={6} sm={3} md={4} lg={3}>
                        <LoadingCategoryItem />
                    </Grid>
                );
            }
        }

        return (
            loadingList
        );
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default __tabs" position="sticky" color="primary">
                <Toolbar>
                    {
                        config.layout.navbar ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            : null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('CLASSROOM_TITLE')}
                        </Typography>
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    autoFocus
                                    className="appbar-search-bar"
                                    placeholder={intl.get("LABEL_SEARCH")}
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setQuery(''); setSearchOpen(false); }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>

                <Tabs value={value} onChange={handleChange} aria-label="tabs" variant={isScreenUpMd ? "standard" : "fullWidth"} className="appbar-default-tabs" centered>
                    <Tab label={intl.get("CLASSROOM_LIST_TAB_1")} {...tabsProps(0)} />
                    <Tab label={intl.get("CLASSROOM_LIST_TAB_2")} {...tabsProps(1)} />
                    <Tab label={intl.get("CLASSROOM_LIST_TAB_3")} {...tabsProps(2)} />
                </Tabs>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="classroom-list" hideSidebar>
                    {/* QrAppBar */}
                    <QrAppBar />
                    
                    <TabPanel value={value} index={0}>
                        <Container maxWidth="lg" className="pt-3 pb-3">
                            <Grid container spacing={2} className="category-list __fluid">
                                {
                                    loading === "finished" ?
                                        availableList.length !== 0 ?
                                            <AvailableList />
                                            :
                                            <EmptyContent icon={<Icon ic="play_classroom" className="icon-xxl" />} text={intl.get("EMPTY_CLASSROOM")} />
                                        : loading === "error" ?
                                            <ErrorContent />
                                            :
                                            <LoadingList limit={limit} />
                                }
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Container maxWidth="lg" className="container-list pt-3 pb-3">
                            {
                                loading === "finished" ?
                                    upcomingList.length !== 0 ?
                                        <UpcomingList />
                                        :
                                        <EmptyContent icon={<Icon ic="play_classroom" className="icon-xxl" />} text={intl.get("EMPTY_CLASSROOM")} padding={true} />
                                    :
                                    loading === "error" ?
                                        <ErrorContent padding={true} />
                                        :
                                        <LoadingList limit={limit} type="upcoming" />
                            }

                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Container maxWidth="lg" className="pt-3 pb-3">
                            <Grid container spacing={2} className="category-list __fluid">
                                {
                                    loading === "finished" ?
                                        previousList.length !== 0 ?
                                            <PreviousList />
                                            :
                                            <EmptyContent icon={<Icon ic="play_classroom" className="icon-xxl" />} text={intl.get("EMPTY_CLASSROOM")} />
                                        : loading === "error" ?
                                            <ErrorContent />
                                            :
                                            <LoadingList limit={limit} />
                                }
                            </Grid>
                        </Container>
                    </TabPanel>
                </QrContent>
            </div>
        </div>
    )
}
