import React from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Radar } from 'react-chartjs-2';
import { imageParser } from '../../../utils/utils';
import {
    Grid, Typography, Divider, CardActionArea,
    Card, CardHeader, CardContent
} from '@material-ui/core';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const project = config.app.name_project.toLowerCase()
let theme = require(`../../../assets/scss/projects/${project}/_export.scss`);

export default function CardPerformanceChart(props) {
    const { isLoggedUser, name } = props;
    const { history } = useReactRouter();
    const performanceStats = useSelector(state => state.profile.performanceStats);

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            red: parseInt(result[1], 16),
            green: parseInt(result[2], 16),
            blue: parseInt(result[3], 16)
        } : null;
    }

    var { red, green, blue } = hexToRgb(theme["primary"]);

    const dataChart = {
        labels: performanceStats && performanceStats.load === "finished" ? !performanceStats.error ? performanceStats.data && performanceStats.data.efficiency.map(item => item.category) : [""] : [""],
        datasets: [
            {
                data: performanceStats && performanceStats.load === "finished" ? !performanceStats.error ? performanceStats.data && performanceStats.data.efficiency.map(item => item.efficience) : [0] : [0],
                backgroundColor: `rgba(${red},${green},${blue}, 0.6)`,
                borderColor: `rgba(${red},${green},${blue}, 1)`,
                borderWidth: 2,
                pointBackgroundColor: `rgba(${red},${green},${blue}, 1)`,
                hoverBackgroundColor: `rgba(${red},${green},${blue}, 1)`,
                hoverBorderColor: `rgba(${red},${green},${blue}, 1)`,
            }
        ]
    };

    const options = {
        legend: {
            display: false,
        },
        layout: {
            padding: 4
        },
        scale: {
            ticks: {
                suggestedMin: 0,
                suggestedMax: 100,
                maxTicksLimit: 4,
                display: false
            },
            pointLabels: "",
            gridLines: {
                lineWidth: 2,
                zeroLineWidth: 2,
            },
            angleLines: {
                lineWidth: 2,
            }
        },
        tooltips: {
            mode: 'single',
            callbacks: {
                title: function (tooltipItem, data) {
                    return data['labels'][tooltipItem[0]['index']];
                },
                label: function (tooltipItem, data) {
                    return data['datasets'][0]['data'][tooltipItem['index']] + '%';
                }
            },
            titleFontFamily: "Nunito",
            bodyFontFamily: "Nunito",
            bodyFontSize: 14,
            bodyFontStyle: "bold",
            displayColors: false
        }
    };

    return (
        <Card className="profile-card __free">
            <CardHeader
                title={
                    <Typography variant="h3" component="h2" align="center">
                        {intl.get("PROFILE_CARD_FREE_TITLE")}
                    </Typography>
                }
                subheader={
                    <Typography variant="body2" component="p" className="fs-md" align="center">
                        {intl.get("PROFILE_CARD_FREE_SUBTITLE")}
                    </Typography>
                }
            />
            <div className="profile-card-content">
                {
                    performanceStats && performanceStats.load === "finished" ?
                        !performanceStats.error ?
                            <>
                                <CardContent className="w-100">
                                    <Grid container direction="column" alignItems="center" justify="space-between" className="chart-wrap">
                                        <Grid item xs={12} className="chart-content">
                                            <Grid container direction="column" alignItems="center" justify="center">
                                                {
                                                    performanceStats.data.efficiency.length !== 0 ?
                                                        <div>
                                                            {performanceStats.data.efficiency.map((item, index) =>
                                                                <div key={index} className={"cat cat" + (index + 1)} style={{ backgroundColor: item.bgcolor }}>
                                                                    <img src={item.image ? imageParser(item.image) : ""} alt="" />
                                                                </div>
                                                            )}
                                                            <Radar data={dataChart} options={options} height={150} />
                                                        </div>
                                                        :
                                                        <Typography variant="body1" component="h4">
                                                            {intl.get("EMPTY_PERFORMANCE")}
                                                        </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="chart-footer">
                                            <Grid container direction="row" alignItems="center" justify="center">
                                                <Grid item xs={6} className="chart-data-item">
                                                    <div className="chart-data-item-icon left">
                                                        <Icon ic="questions_filled" className="icon-sm" />
                                                    </div>
                                                    <div className="chart-data-item-text">
                                                        <Typography variant="body2" component="p">
                                                            {intl.get("PROFILE_CARD_FREE_ANSWERED")}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {performanceStats.data.answered}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} className="chart-data-item">
                                                    <div className="chart-data-item-icon right">
                                                        <Icon ic="doughnut_chart" className="icon-sm" />
                                                    </div>
                                                    <div className="chart-data-item-text">
                                                        <Typography variant="body2" component="p">
                                                            {intl.get("PROFILE_CARD_FREE_AVERAGE")}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {performanceStats.data.general_average}%
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {
                                    isLoggedUser
                                        ?
                                        <Grid container direction="column">
                                            <Divider />
                                            <CardActionArea
                                                disabled={performanceStats.data.efficiency.length === 0}
                                                className="card-action-button"
                                                onClick={() => history.push({
                                                    pathname: `${history.location.pathname + '/performance'}`,
                                                    state: {
                                                        nameUser: name
                                                    }
                                                })}
                                            >
                                                <Typography variant="body1" component="p">
                                                    {intl.get("BTN_SEE_ALL")}
                                                </Typography>
                                            </CardActionArea>
                                        </Grid>
                                        : null
                                }
                            </>
                            :
                            <Grid container alignItems="center" justify="center" className="chart-wrap">
                                <ErrorContent type="section" padding={true} />
                            </Grid>
                        :
                        <Grid container alignItems="center" justify="center" className="chart-wrap">
                            <LoadingBounce />
                        </Grid>
                }
            </div>
        </Card>
    )
}