import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import config from '../../config';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import {
    AppBar, Button, Grid, Container, Typography, IconButton,
    Toolbar, useMediaQuery
} from '@material-ui/core';
import { Icon, Play } from '../../components/Images/Images';
import { getPeriodicTitle } from '../../utils/utils';

export default function LearnIndex(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_LEARN');
    let enabledModules = [];

    function reorderLearnArray(array) {
        array.sort(function (a, b) {
            return config.learn.order.indexOf(a) - config.learn.order.indexOf(b);
        });
        return array;
    }

    function ButtonContent({ img, classe, title, text, link, url, stateObj }) {
        return (
            <Button variant="contained" className={"btn-play-game " + (classe)}
                onClick={
                    url ?
                        () => window.open(url, '_blank')
                        :
                        () => history.push({ pathname: link, state: { origin: 'learn', ...stateObj } })
                }>
                <div className="play-icon" >
                    <Play type={img} />
                </div>
                <div className="play-content no-wrap">
                    <Typography variant="h3" align="left" component="h4" noWrap>
                        {title}
                    </Typography>
                    <Typography variant="body2" align="left" component="p">
                        {text}
                    </Typography>
                </div>
            </Button>
        )
    }

    Object.entries(auth.modules).map(item => {
        if (item[item.length - 1].ENABLED === 'Y') {
            (config.learn.order.map(value => {
                if (value === item[0])
                    enabledModules.push(item[0])
                return null;
            }));
        }
        return null;
    });

    enabledModules = reorderLearnArray(enabledModules);

    // enabledModules
    function ModulesList(ModuleName, position) {
        switch (ModuleName) {
            case "VIRTUAL_LIBRARY":
                return (
                    auth.modules.VIRTUAL_LIBRARY &&
                    auth.modules.VIRTUAL_LIBRARY.ENABLED === 'Y' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-library"
                            img="library"
                            title={intl.get("MENU_LIBRARY")}
                            text={intl.get("BUTTON_PLAY_LIBRARY_DESCRIPTION")}
                            link="/library"
                        />
                    </Grid>
                )
            case "PERIODIC_QUESTION":
                return (
                    auth.modules.PERIODIC_QUESTION &&
                    auth.modules.PERIODIC_QUESTION.ENABLED === 'Y' &&
                    process.env.REACT_APP_QR_PROJECT !== 'FLEURY' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-periodic"
                            img="periodic-question"
                            title={getPeriodicTitle(auth.modules.PERIODIC_QUESTION.SCHEDULE)}
                            text={intl.get("BUTTON_PLAY_QOTD_DESCRIPTION")}
                            link="/periodic"
                        />
                    </Grid>
                )

            case "TRAIL":
                return (
                    auth.modules.TRAIL &&
                    auth.modules.TRAIL.ENABLED === 'Y' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-trail"
                            img="trail"
                            title={intl.get("TRAIL_TITLE")}
                            text={intl.get("BUTTON_PLAY_COURSES_DESCRIPTION")}
                            link="/trail"
                        />
                    </Grid>
                )

            case "SCORM":
                return (
                    auth.modules.SCORM &&
                    auth.modules.SCORM.ENABLED === 'Y' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-scorm"
                            img="scorm"
                            title={intl.get('SCORM_TITLE')}
                            text={intl.get("BUTTON_PLAY_SCORM_DESCRIPTION")}
                            link="/scorm"
                        />
                    </Grid>
                )

            case "CLASSROOM_COURSE":
                return (
                    auth.modules.CLASSROOM_COURSE &&
                    auth.modules.CLASSROOM_COURSE.ENABLED === 'Y' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-classroom"
                            img="classroom"
                            title={intl.get("CLASSROOM_TITLE")}
                            text={intl.get("BUTTON_PLAY_CLASSROOM_DESCRIPTION")}
                            link="/classroom"
                        />
                    </Grid>
                )

            case "EXAM":
                return (
                    auth.modules.EXAM &&
                    auth.modules.EXAM.ENABLED === 'Y' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-exam"
                            img="exam"
                            title={intl.get("EXAM_TITLE")}
                            text={intl.get("BUTTON_PLAY_EXAM_DESCRIPTION")}
                            link="/exam"
                        />
                    </Grid>
                )

            case "FREE":
                return (
                    auth.modules.FREE &&
                    auth.modules.FREE.ENABLED === 'Y' &&
                    <Grid key={ModuleName} item xs={12} lg={8} xl={6}>
                        <ButtonContent
                            classe="play-free"
                            img="free"
                            title={intl.get("FREE_TITLE")}
                            text={intl.get("BUTTON_PLAY_FREE_DESCRIPTION")}
                            link="/free"
                        />
                    </Grid>
                )
            default:
                return null;
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        config.layout.navbar && !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('LEARN_CHOOSE_A_MODE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("LEARN_CHOOSE_A_MODE_SUB")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="learn" bottomNavigation={bottomMenuItem} hideSidebar>
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            <Container maxWidth="lg" className="page-bottom-menu">
                                <Grid
                                    container
                                    className="pt-2 pb-3"
                                    justify="center"
                                    alignItems="center"
                                    align="center"
                                    spacing={2}
                                >
                                    {
                                        enabledModules.map((item, index) => {
                                            // ADICIONANDO TEMPORARIAMENTE PARA ACESSO DE SCORMS PARA MÉDICOS EM SEGUNDO LUGAR DA LISTA
                                            if (config.app.name_project === "FLEURY" && item === "TRAIL") {
                                                return (
                                                    <>
                                                        {ModulesList(item, index)}
                                                        <Grid item xs={12} lg={8} xl={6}>
                                                            <ButtonContent
                                                                classe="play-scorm-doctors"
                                                                img="scorm-doctors"
                                                                title={intl.get("BUTTON_PLAY_SCORM_DOCTORS_TITLE")}
                                                                text={intl.get("BUTTON_PLAY_SCORM_DOCTORS_DESCRIPTION")}
                                                                link="/trail/x4YV9vOvP41odzkb/list"
                                                                stateObj={{
                                                                    theme: {
                                                                        title: intl.get("BUTTON_PLAY_SCORM_DOCTORS_TITLE"),
                                                                        id_category: "x4YV9vOvP41odzkb"
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            return (
                                                ModulesList(item, index)
                                            )
                                        })
                                    }
                                    {
                                        config.app.name_project === "FLEURY" ?
                                            <Grid item xs={12} lg={8} xl={6}>
                                                <ButtonContent
                                                    classe="play-educational-partners"
                                                    img="educational-partners"
                                                    title={intl.get("BUTTON_PLAY_EDUCATIONAL_PARTNERS_TITLE")}
                                                    text={intl.get("BUTTON_PLAY_EDUCATIONAL_PARTNERS_DESCRIPTION")}
                                                    url="https://grupofleury.sharepoint.com/sites/ParceriasEducacionais/SitePages/LearningTeamHome.aspx"
                                                />
                                            </Grid>
                                            :
                                            null
                                    }
                                </Grid>

                            </Container>
                        </QrContent>
                    </div>
                </div>
            }
        </div>
    )
};
