import React from 'react';
import intl from "react-intl-universal";
import config from '../../../../config';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Icon, StageMedal } from '../../../../components/Images/Images';

export default function ButtonSurvey(props) {
    const { action, item } = props;
    let complete = item.survey_answered === "Y";
    let unlock = parseInt(item.course_progress) === 100 && item.survey_answered === "N";

    return (
        <Accordion className="btn-card __subcategory" defaultExpanded={config.learn.trail.unified_stages.expanded === "auto" ? !complete : config.learn.trail.unified_stages.expanded === true ? true : false}>
            <AccordionSummary
                expandIcon={!unlock ? <Icon ic="chevron_down" color="lighter" className="icon-xxxs" /> : <Icon ic="chevron_up" color="lighter" className="icon-xxxs" />}
                className="subcategory-content __text"
            >
                <div className="accordion-content">
                    <div className="d-flex align-items-center w-100">
                        {
                            complete ?
                                <Icon ic="checked" color="success" className="img-xs mr-2" />
                                : null
                        }
                        <Typography variant="body1" component="h4">
                            {intl.get('SURVEY_HEADER')}
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <div className={`step-content ${!unlock ? "__disabled" : "__active"}`} onClick={unlock ? action : null}>
                        <div className={`step-content-medal ${complete ? "__cleared" : "__bordered"}`}>
                            {
                                complete ?
                                    <>
                                        <StageMedal
                                            className="medal"
                                            type="gold"
                                            survey="true"
                                        />
                                        <div className="step-content-medal-number __clean">
                                            <Icon ic="rating" />
                                        </div>
                                    </>
                                    :
                                    <Icon ic="rating" />
                            }
                        </div>
                        <div className="step-content-text">
                            <div>
                                <Typography variant="body1" component="h4">
                                    {intl.get("SURVEY_TITLE")}
                                </Typography>
                                {
                                    unlock ?
                                        <Typography variant="caption" component="p">
                                            {intl.get('TRAIL_STAGES_SURVEY_TEXT_UNLOCK')}
                                        </Typography>
                                        :
                                        complete ?
                                            <Typography variant="caption" component="p">
                                                {intl.get('TRAIL_STAGES_SURVEY_TEXT_ANSWERED')}
                                            </Typography>
                                            :
                                            <Typography variant="caption" component="p">
                                                {intl.get('TRAIL_STAGES_SURVEY_TEXT_DISABLED')}
                                            </Typography>
                                }
                            </div>
                            {
                                unlock ?
                                    <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                                    :
                                    complete ?
                                        <Icon ic="checked" className="icon-sm ml-3" />
                                        :
                                        <Icon ic="lock" className="icon-sm ml-3" />
                            }
                        </div>
                    </div>
                </Grid>
            </AccordionDetails>
        </Accordion>

    )
}
