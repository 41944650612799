import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Grid, Divider, Button, useMediaQuery } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from '../../Learn/Scorm/components/CategoryItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingCategoryList, LoadingCategoryGrid } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ScormSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const scorms = useSelector(state => state.home.scorms);
    const [didMount, setDidMount] = useState(false);
    const isScreenMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (config.home.sections.scorm.categories === "themes") {
                dispatch(HomeActions.getScormThemesList({
                    limit: limit,
                    page: 1
                }));
            } else {
                dispatch(HomeActions.getScormList({
                    limit: limit,
                    page: 1
                }));
            }
        }

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                imageType={config.home.sections.scorm.image_type}
                image={config.home.sections.scorm.image}
                sectionColor={config.home.sections.scorm.section_color}
                icon={config.home.sections.scorm.icon}
                iconColor={config.home.sections.scorm.icon_color}
                title={intl.get("SCORM_TITLE")}
                subtitle={intl.get("SCORM_SUBTITLE_SECTION")}
                action={() => history.push('/scorm')}
                cardStyle={config.learn.scorm.categories.grid.style}
                list={props.list ? props.list : false}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionThemesContent = () => {
        return (
            <Section list={isScreenMobile && config.home.sections.scorm.mobile_grid === "grid"}>
                {
                    isScreenMobile && config.home.sections.scorm.mobile_grid === "grid" ?
                        <>
                            {
                                scorms.data.slice(0, 6).map((item, index) => {
                                    return (
                                        <Grid item key={index} xs={config.learn.scorm.categories.grid.mobile === "full" ? 12 : 6} sm={config.learn.scorm.categories.grid.mobile === "full" ? 6 : 4} md={4} lg={3}>
                                            <CategoryItem
                                                key={index}
                                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_encrypted}/list`, state: { theme: item, origin: "home" } })}
                                                type="themes"
                                                bgColor={item.bg_color}
                                                image={item.image}
                                                title={item.title}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                scorms.data.length > 6 ?
                                    <Grid item container xs={12} justify="center" className="mb-4">
                                        <Button
                                            onClick={() => history.push('/scorm')}
                                            className="btn-block-xs"
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {intl.get("BUTTON_SEE_ALL")}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </>
                        :
                        <SectionCategoryGrid action={() => history.push('/scorm')} size={config.learn.scorm.categories.grid.size}>
                            {
                                scorms.data.map((item, index) => {
                                    return (
                                        <CategoryItem
                                            key={index}
                                            action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_encrypted}/list`, state: { theme: item, origin: "home" } })}
                                            type="themes"
                                            bgColor={item.bg_color}
                                            image={item.image}
                                            title={item.title}
                                        />
                                    )
                                })
                            }
                        </SectionCategoryGrid>
                }
            </Section>
        )
    }

    const SectionContent = () => {
        return (
            <Section list={isScreenMobile && config.home.sections.scorm.mobile_grid === "grid"}>
                {
                    isScreenMobile && config.home.sections.scorm.mobile_grid === "grid" ?
                        <>
                            {
                                scorms.data.slice(0, 6).map((item, index) => {
                                    return (
                                        <Grid item key={index} xs={config.learn.scorm.categories.grid.mobile === "full" ? 12 : 6} sm={config.learn.scorm.categories.grid.mobile === "full" ? 6 : 4} md={4} lg={3}>
                                            <CategoryItem
                                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_scorm}/details`, state: { origin: "home" } })}
                                                image={item.image}
                                                title={item.title}
                                                status={item.completed}
                                                totalCompleted={item.total_package_complete}
                                                totalLessons={item.total_package}
                                                obligatory={item.obligatory}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                scorms.data.length > 6 ?
                                    <Grid item container xs={12} justify="center" className="mb-4">
                                        <Button
                                            onClick={() => history.push('/scorm')}
                                            className="btn-block-xs"
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {intl.get("BUTTON_SEE_ALL")}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </>
                        :
                        <SectionCategoryGrid action={() => history.push('/scorm')} size={config.learn.scorm.categories.grid.size}>
                            {
                                scorms.data.map((item, index) => {
                                    return (
                                        <CategoryItem
                                            key={index}
                                            action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_scorm}/details`, state: { origin: "home" } })}
                                            image={item.image}
                                            title={item.title}
                                            status={item.completed}
                                            totalCompleted={item.total_package_complete}
                                            totalLessons={item.total_package}
                                            obligatory={item.obligatory}
                                        />
                                    )
                                })
                            }
                        </SectionCategoryGrid>
                }
            </Section>
        )
    }

    return (
        scorms && scorms.load === "finished" ?
            !scorms.error ?
                scorms.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        {
                            config.home.sections.scorm.categories === "themes" ?
                                <SectionThemesContent />
                                :
                                <SectionContent />
                        }
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="play_scorm" className="icon-xxl" />}
                                    text={intl.get("EMPTY_SCORMS")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        {
                            isScreenMobile && config.home.sections.scorm.mobile_grid === "grid" ?
                                <div className="pl-3 pr-3">
                                    <LoadingCategoryList limit={6} mobileSize={config.learn.scorm.categories.grid.mobile} />
                                </div>
                                :
                                <LoadingCategoryGrid action={() => history.push('/scorm')} limit={limit} size={config.learn.scorm.categories.grid.size} />
                        }
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    {
                        isScreenMobile && config.home.sections.scorm.mobile_grid === "grid" ?
                            <div className="pl-3 pr-3">
                                <LoadingCategoryList limit={6} mobileSize={config.learn.scorm.categories.grid.mobile} />
                            </div>
                            :
                            <LoadingCategoryGrid action={() => history.push('/scorm')} limit={limit} size={config.learn.scorm.categories.grid.size} />
                    }
                </Section>
            </div>
    )
};
