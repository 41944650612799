import * as React from "react"

function Boss(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          d="M21.45 7.5c.72 0 1.35.675 1.35 1.446 0 .188-.037.37-.104.537.565.171 1.004.748 1.004 1.392 0 .771-.63 1.446-1.35 1.446-.554 0-1.055-.4-1.256-.935l-6.663 2.859 5.931 2.546c.25-.366.653-.612 1.088-.612.72 0 1.35.675 1.35 1.446 0 .644-.439 1.22-1.003 1.394.066.166.103.347.103.535 0 .771-.63 1.446-1.35 1.446-.72 0-1.35-.675-1.35-1.446 0-.385.157-.746.404-1.011l-7.593-3.259-7.607 3.267c.242.264.396.621.396 1.003C4.8 20.325 4.17 21 3.45 21c-.72 0-1.35-.675-1.35-1.446 0-.188.037-.37.104-.537-.565-.171-1.004-.748-1.004-1.392 0-.771.63-1.446 1.35-1.446.438 0 .843.25 1.093.62l5.948-2.554-6.682-2.867c-.2.54-.702.943-1.259.943-.72 0-1.35-.675-1.35-1.446 0-.644.439-1.22 1.003-1.394a1.452 1.452 0 01-.103-.535c0-.771.63-1.446 1.35-1.446.72 0 1.35.675 1.35 1.446 0 .251-.067.492-.181.702l8.292 3.559 8.275-3.55a1.47 1.47 0 01-.186-.71c0-.772.63-1.447 1.35-1.447z"
          fill="#C8CBC5"
        />
        <path
          d="M18.3 11.357c0 4.243-2.79 3.857-6.3 3.857s-6.3.386-6.3-3.857S8.49 3.643 12 3.643s6.3 3.471 6.3 7.714z"
          fill="#E8EAE6"
        />
        <path
          d="M15.6 16.179c0 1.639-1.17 2.892-2.7 2.892h-1.8c-1.53 0-2.7-1.253-2.7-2.892v-.965c0-1.639 1.17-2.893 2.7-2.893h1.8c1.53 0 2.7 1.254 2.7 2.893v.965z"
          fill="#E8EAE6"
        />
        <path
          d="M12.9 14.25c0 .579-.36 0-.9 0s-.9.579-.9 0 .36-1.929.9-1.929.9 1.35.9 1.929zm3.6-4.821c0 1.06-.81 2.892-1.8 2.892s-1.8-.867-1.8-1.928c0-1.06.81-1.929 1.8-1.929s1.8-.096 1.8.965zm-9 0c0 1.06.81 2.892 1.8 2.892s1.8-.867 1.8-1.928c0-1.06-.81-1.929-1.8-1.929s-1.8-.096-1.8.965z"
          fill="#50524F"
        />
      </g>
    </svg>
  )
}

export default Boss
