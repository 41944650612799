import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { goToTopAnimation } from '../../../utils/utils';
import {
    AppBar, Toolbar, Container, Grid, IconButton,
    Typography, List, Divider, Slide
} from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import Pagination from '@material-ui/lab/Pagination';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import DialogFile from '../../Library/dialog.file';
import FileItem from '../../Library/components/FileItem';
import { LoadingAvatarList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Materials(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const { materials } = useSelector(state => state.trail);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const [didMount, setDidMount] = useState(false);
    const itemsPerPage = 10;
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState([]);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(TrailActions.getMaterialsList({
                id_stage: props.match.params.idStage,
                page: 1,
                limit: 0,
                order: 3
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(TrailActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    function goBack() {
        let currentOrigin = "materials";

        switch (origin) {
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'steps':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    function openDialog(file) {
        setOpen(true);
        setFile(file);
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("TRAIL_MATERIALS_TITLE")}
                        </Typography>
                        {
                            materials && materials.load === "finished" ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {materials.data.stage_name}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const Files = () => {
        return (
            <List component="nav" className="w-100">
                {
                    materials.data.files
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((file, index) => {
                            return (
                                <div key={index}>
                                    <FileItem
                                        file={file}
                                        bgColor={file.bgcolor}
                                        blob={file.blob}
                                        content={file.content}
                                        title={file.title}
                                        description={file.description}
                                        date={file.file_date}
                                        type={file.type}
                                        openDialog={openDialog}
                                    />
                                    <Divider variant="inset" />
                                </div>
                            )
                        })
                }
            </List>
        );
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="trail-materials" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md" className="container-list pt-2 pb-4">
                        {
                            materials && materials.load === "finished" ?
                                !materials.error ?
                                    materials.data.files.length !== 0 ?
                                        <>
                                            <Files />
                                            <DialogFile
                                                open={open}
                                                close={() => setOpen(false)}
                                                transition={Transition}
                                                title={file && file.title}
                                                file={file}
                                            />
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Pagination
                                                        count={Math.ceil(materials.data.files.length / itemsPerPage)}
                                                        page={page}
                                                        onChange={(e, value) => { setPage(value); goToTopAnimation(); }}
                                                        defaultPage={1}
                                                        color="primary"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        <EmptyContent icon={<Icon ic="book_stack_filled" className="icon-xxl" />} text={intl.get("EMPTY_LIBRARY")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingAvatarList limit={10} />
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}
