import React from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { Icon, Points } from '../../../components/Images/Images';

export default function ActivityInfoCard(props) {
    return (
        <Grid item xs={12} sm className="activities-info-card">
            <Card className="h-100">
                <CardContent className="pb-3 h-100">
                    <Grid container direction="row" alignItems="center" justify="space-between" className="flex-nowrap">
                        <div className="card-content">
                            <div className={`card-icon ${props.section}`}>
                                <Icon ic={props.icon} color="white" />
                            </div>
                            <Typography variant="body2" component="h4" className="mt-2">
                                {props.title}
                            </Typography>
                            <div className="d-flex align-items-center justify-content-start flex-row flex-nowrap">
                                <Typography variant="h3" component="div">
                                    {props.total}
                                </Typography>
                                {
                                    props.arrow ?
                                        <Icon ic={`arrow_${props.arrow}`} color={props.positive === 'Y' ? 'success' : 'danger'} className="icon-xs ml-1" />
                                        : null
                                }
                            </div>
                            {
                                props.points ?
                                    <div className="d-flex align-items-center justify-content-start flex-row flex-nowrap">
                                        <Points type="xp" className="img-xxs" />
                                        <Typography variant="caption" component="p" className="text-xp ml-1">
                                            {props.points}
                                        </Typography>
                                    </div>
                                    :
                                    <Typography variant="caption" component="p" className="font-weight-bold">
                                        {props.description}
                                    </Typography>
                            }
                        </div>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}