import { qrService } from './base.service'

function getList(params) {
    return qrService('/v4/app/free/list', params)
}

function getBuyCategory(params) {
    return qrService('/v4/app/free/billing/purchase', params)
}

function setPlay(params) {
    return qrService('/v4/app/free/play', params)
}

function setAnswer(params) {
    return qrService('/v4/app/free/answer', params)
}

function setWarningProcess(params) {
    return qrService('/v4/app/free/warning/process', params)
}

export const freeService = {
    getList,
    getBuyCategory,
    setPlay,
    setAnswer,
    setWarningProcess
}