import * as React from "react";
import config from "../../../config";
import AssaiCharIntroLogin from '../projects/assai/chars/CharIntroLogin';

function CharIntroLogin(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={192} height={136} viewBox="0 0 192 136" {...props}>
                <defs>
                    <filter
                        x="-5.5%"
                        y="-7.7%"
                        width="110.9%"
                        height="115.4%"
                        filterUnits="objectBoundingBox"
                        id="prefix__a"
                    >
                        <feOffset dy={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                        />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter
                        x="-39.8%"
                        y="-143.2%"
                        width="179.7%"
                        height="438.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__h"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <filter
                        x="-39.8%"
                        y="-143.2%"
                        width="179.7%"
                        height="438.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__j"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <filter
                        x="-39.8%"
                        y="-143.2%"
                        width="179.7%"
                        height="438.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__l"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <filter
                        x="-39.8%"
                        y="-143.2%"
                        width="179.7%"
                        height="438.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__n"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <rect id="prefix__i" x={0} y={0} width={16.32} height={3.84} rx={0.6} />
                    <rect id="prefix__k" x={0} y={0} width={16.32} height={3.84} rx={0.6} />
                    <rect id="prefix__m" x={0} y={0} width={16.32} height={3.84} rx={0.6} />
                    <rect id="prefix__o" x={0} y={0} width={16.32} height={3.84} rx={0.6} />
                    <path id="prefix__b" d="M0 0h192v136H0z" />
                    <path id="prefix__d" d="M0 48h48V0H0z" />
                    <path
                        d="M20.16.576H1.728c-.691 0-1.152.46-1.152 1.152v33.408c0 .691.46 1.152 1.152 1.152H20.16c.691 0 1.152-.46 1.152-1.152V1.728c0-.691-.46-1.152-1.152-1.152z"
                        id="prefix__f"
                    />
                </defs>
                <g filter="url(#prefix__a)" fill="none" fillRule="evenodd">
                    <mask id="prefix__c" fill="#fff">
                        <use xlinkHref="#prefix__b" />
                    </mask>
                    <g mask="url(#prefix__c)">
                        <path
                            d="M44.117 84.861l68.165.295C120.5 110 116.5 138.5 116.5 147.913l-9.439 9.327c-1.545 5.048-6.861 7.88-11.874 6.323-5.012-1.557-7.823-6.911-6.278-11.96l1.13-3.69-17.612-.06h-.856c2.961 5.68.67 13.617-4.518 14.394-5.188.779-13.29.652-14.062-4.572l-1.804-9.822-7.07-62.172"
                            fill="#46CA64"
                        />
                        <path
                            d="M95.109 110.922c4.465.79 9.83 1.569 14.457 2.238l20.5 1.167c10.69 1.327 26.202 1.553 27.435-6.821 3.973-26.993-12.298-18.697-12.283-18.816 1.977-17.117-15.708-16.673-9.117-1.402l-35.957 4.88"
                            fill="#F3C430"
                        />
                        <path
                            d="M44.117 84.861l22.166 2.752 19.994 34.17 12.144-34.996 13.861-1.631C120 110 116 133 117.5 147l-10.439 10.24c-1.545 5.048-6.861 7.88-11.874 6.323-5.012-1.557-7.823-6.911-6.278-11.96l1.13-3.69-17.612-.06h-.856c2.961 5.68.67 13.617-4.518 14.394-5.188.779-13.29.652-14.062-4.572l-1.804-9.822-7.07-62.992z"
                            fill="#46CA64"
                        />
                        <path
                            d="M120.104 49.696s-.276 26.377-.241 26.496c-.177 4.34-4.068 14.153-15.772 15.895-13.98 2.082-44.718 3.274-44.718 3.274s-13.17-1.59-13.933-15.82c-.039.242.144-26.972.144-26.972l74.52-2.873"
                            fillOpacity={0.15}
                            fill="#000"
                        />
                        <path
                            fill="#46CA64"
                            d="M110.706 86.884l17.753.017.844 28.133-15.911 2.282zM27 136c0-5.5 0-43 16.681-56.496C45.808 77.784 48.247 96.616 51 136H27z"
                        />
                        <path
                            d="M125.991 72.722c-.106 4.35-3.518 16.168-17.681 16.313l-53.137.546s-17.048-1.048-18.309-15.34c-.04.242-1.142-27.008-1.142-27.008h90.27c-.031 16.913-.031 25.41 0 25.49z"
                            fill="#FDC436"
                        />
                        <path
                            d="M65 4.824c-4.26.53-2.465.179-5.421.863-15.914 4.279-24.265 23.595-23.841 38.96l.139 5.045c-.166.108 90.157-2.06 90.127-2.213l-.148-5.356c-.425-15.362-9.827-34.183-25.954-37.565-4.645-.975-10.073-.724-14.462 1.163-2.38 1.021-4.245 2.507-5.562 4.358-1.42-1.774-3.361-3.153-5.794-4.04-3.283-1.23-5.675-1.162-9.083-1.215M71.228 74.297c6.735.111 12.281-5.03 12.387-11.482.105-6.453-5.27-11.774-12.006-11.886-6.735-.112-12.281 5.029-12.386 11.48-.106 6.454 5.269 11.776 12.005 11.888"
                            fill="#212321"
                        />
                        <path
                            d="M74.234 71.113c3.285.054 5.99-2.453 6.04-5.6.052-3.146-2.568-5.741-5.854-5.797-3.284-.054-5.99 2.454-6.04 5.6-.052 3.146 2.57 5.742 5.854 5.797M40.854 44.503l37.384-1.047-.691-25.056c-.114-4.137-2.166-6.406-5.206-7.517-3.49-1.274-7.88-1.172-11.44-.217-2.94.792-5.568 2.23-7.876 4.133 1.338 2.15 3.664 2.909 6.188 2.265 1.483-.378 2.83-.859 4.375-1.027 5.043-.546 9.892 2.726 11.327 7.626.656 2.12-.394 3.85-1.654 4.18-1.383.363-2.83-.215-3.312-2.297-1.173-4.882-5.719-4.21-9.425-3.266-4.366 1.116-8.648-.241-11.305-3.596a33.351 33.351 0 00-3.19 4.78c1.237.804 2.333 1.908 3.223 3.303.837 1.313 1.442 2.79 2.308 4.061 1.648 2.436 4.31 3.01 6.94 1.866 1.403-.61 2.66-1.3 4.157-1.711 4.894-1.346 10.198 1.11 12.385 5.719.982 1.985.219 3.865-.973 4.392-1.31.578-2.832.24-3.632-1.74-1.928-4.632-6.311-3.243-9.82-1.718-4.975 2.163-10.39.779-13.474-3.772-.916-1.353-1.524-2.833-2.388-4.19a5.87 5.87 0 00-1.032-1.233c-2.037 5.3-3.009 11.008-2.87 16.062zm79.885-2.236l-37.385 1.046-.691-25.056c-.114-4.137 1.81-6.517 4.785-7.797 3.412-1.467 7.802-1.611 11.41-.856 2.979.626 5.682 1.914 8.092 3.686-1.219 2.22-3.498 3.109-6.054 2.608-1.502-.296-2.874-.7-4.424-.781-5.067-.263-9.729 3.275-10.89 8.248-.54 2.153.606 3.822 1.88 4.082 1.403.284 2.816-.374 3.181-2.48.904-4.94 5.48-4.523 9.23-3.787 4.423.869 8.623-.726 11.092-4.224a33.348 33.348 0 013.447 4.595c-1.189.87-2.225 2.035-3.036 3.478-.763 1.357-1.287 2.866-2.078 4.184-1.516 2.524-4.139 3.245-6.827 2.25-1.437-.53-2.729-1.15-4.247-1.476-4.96-1.07-10.12 1.68-12.05 6.403-.872 2.038-.005 3.872 1.214 4.33 1.339.505 2.839.082 3.531-1.94 1.67-4.733 6.121-3.59 9.71-2.263 5.084 1.88 10.415.195 13.242-4.52.843-1.402 1.367-2.915 2.156-4.317.283-.503.606-.934.962-1.289 2.327 5.176 3.61 10.823 3.75 15.876z"
                            fill="#FFF"
                        />
                        <path
                            d="M103.754 73.98c6.736.112 12.282-5.029 12.388-11.481.105-6.453-5.27-11.774-12.005-11.886-6.736-.112-12.282 5.03-12.388 11.48-.105 6.454 5.27 11.776 12.005 11.888"
                            fill="#212321"
                        />
                        <path
                            d="M106.56 71.018c3.284.054 5.989-2.453 6.04-5.6.051-3.146-2.57-5.741-5.855-5.797-3.285-.053-5.989 2.454-6.04 5.6-.052 3.146 2.569 5.742 5.854 5.797"
                            fill="#FFF"
                        />
                        <path
                            fillOpacity={0.15}
                            fill="#000"
                            d="M53.5 98.5L49.294 136H53.5zM113 89l6.5 27.5h-2z"
                        />
                    </g>
                    <g mask="url(#prefix__c)">
                        <g transform="rotate(32 -32.816 279.95)">
                            <mask id="prefix__e" fill="#fff">
                                <use xlinkHref="#prefix__d" />
                            </mask>
                            <g mask="url(#prefix__e)">
                                <path
                                    d="M10.56 42.432V5.568A4.622 4.622 0 0115.168.96H33.6a4.622 4.622 0 014.608 4.608v36.864A4.622 4.622 0 0133.6 47.04H15.168a4.622 4.622 0 01-4.608-4.608z"
                                    fill="#3D3D3D"
                                />
                                <path
                                    fill="#000"
                                    fillRule="nonzero"
                                    opacity={0.2}
                                    d="M20.928 42.432h6.912v2.304h-6.912z"
                                />
                                <g transform="translate(13.44 3.84)">
                                    <mask id="prefix__g" fill="#fff">
                                        <use xlinkHref="#prefix__f" />
                                    </mask>
                                    <use fill="#FFF" xlinkHref="#prefix__f" />
                                    <path
                                        fill="#FDC436"
                                        mask="url(#prefix__g)"
                                        d="M0 0h22.08v14H0z"
                                    />
                                    <g mask="url(#prefix__g)" fill="#FFF">
                                        <path d="M6 2.8h9v1H6zM4.8 7.68h12.48v1H4.8zM4.8 11.52h12.48v1H4.8zM2.88 9.6H19.2v1H2.88z" />
                                    </g>
                                    <g mask="url(#prefix__g)">
                                        <g transform="translate(2.88 16.32)">
                                            <use
                                                fill="#000"
                                                filter="url(#prefix__h)"
                                                xlinkHref="#prefix__i"
                                            />
                                            <use fill="#FFF" xlinkHref="#prefix__i" />
                                            <circle fill="#FDC436" cx={1.92} cy={1.92} r={1} />
                                        </g>
                                    </g>
                                    <g mask="url(#prefix__g)">
                                        <g transform="translate(2.88 21.12)">
                                            <use
                                                fill="#000"
                                                filter="url(#prefix__j)"
                                                xlinkHref="#prefix__k"
                                            />
                                            <use fill="#FFF" xlinkHref="#prefix__k" />
                                            <circle fill="#FDC436" cx={1.92} cy={1.92} r={1} />
                                        </g>
                                    </g>
                                    <g mask="url(#prefix__g)">
                                        <g transform="translate(2.88 25.92)">
                                            <use
                                                fill="#000"
                                                filter="url(#prefix__l)"
                                                xlinkHref="#prefix__m"
                                            />
                                            <use fill="#FFF" xlinkHref="#prefix__m" />
                                            <circle fill="#FDC436" cx={1.92} cy={1.92} r={1} />
                                        </g>
                                    </g>
                                    <g mask="url(#prefix__g)">
                                        <g transform="translate(2.88 30.72)">
                                            <use
                                                fill="#000"
                                                filter="url(#prefix__n)"
                                                xlinkHref="#prefix__o"
                                            />
                                            <use fill="#FFF" xlinkHref="#prefix__o" />
                                            <circle fill="#FDC436" cx={1.92} cy={1.92} r={1} />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharIntroLogin {...props} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (gender === "male") {
            return (
                <svg width={192} height={136} viewBox="0 0 192 136" {...props}>
                    <defs>
                        <filter
                            x="-5.5%"
                            y="-7.7%"
                            width="110.9%"
                            height="115.4%"
                            filterUnits="objectBoundingBox"
                            id="prefix__a"
                        >
                            <feOffset dy={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                            <feGaussianBlur
                                stdDeviation={2}
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                            />
                            <feColorMatrix
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <path id="prefix__b" d="M0 0h192v136H0z" />
                    </defs>
                    <g filter="url(#prefix__a)" fill="none" fillRule="evenodd">
                        <mask id="prefix__c" fill="#fff">
                            <use xlinkHref="#prefix__b" />
                        </mask>
                        <g mask="url(#prefix__c)">
                            <path
                                d="M110.48 105.5l1.387 10.644s9.468 16.321 18.28 18.454c7.972 1.928 11.726-5.236 11.726-11.94 0-4.051-1.493-9.168-1.493-9.168s-8.314 1.492-11.939 4.05c0 0 2.345 3.839 2.63 6.326.307 2.687-2.654.653-3.696-.142-4.762-3.632-15.474-15.665-16.895-18.224"
                                className="svg-char-shirt-shade-1"
                            />
                            <path
                                d="M110.757 105.271s3.967 15.004 5.197 30.695c0 0-5.196 2.295-19.383 2.295-7.735 0-16.582-.937-20.955-2.295 0 0 .546-9.596 3.947-29.399 0 0 4.619.66 14.45.66 9.832 0 16.744-1.956 16.744-1.956"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M97.62 116.044c.893-1.837 3.161-1.807 3.977.07 1.304 3.001 4.76 12.768 4.616 13.498-.277 1.38-4.972 5.8-6.629 5.523-1.907-.318-6.705-4.891-6.628-5.8.192-2.276 3.038-9.943 4.665-13.29zm1.964-8.666a2.9 2.9 0 11-.001 5.802 2.9 2.9 0 01.001-5.802z"
                                className="svg-char-necktie"
                            />
                            <path
                                d="M79.895 106.492l-.852 6.976s-13.097 1.742-13.507 7.119c-.325 4.262 11.94 5.97 11.94 5.97l-1.845 9.237s-18.623-3.268-18.907-14.354c-.285-11.087 16.022-14.948 23.171-14.948"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M83.38 124.765c5.143 3.2 2.517 8.24-.787 11.741-3.217 3.407-6.874 4.988-11.087 1.422-3.696-3.127-1.99-8.67.427-11.513 2.43-2.859 8.385-3.554 11.447-1.65"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M110.48 59.585c.04 10.236 6.294 14.864 14.454 14.833 8.16-.031 13.28-15.152 13.559-21.884.454-10.956-5.55-18.703-13.71-18.672-8.16.032-14.343 15.487-14.303 25.723"
                                className={`svg-char-${color}-hair-shade-2`}
                            />
                            <path
                                d="M53.167 92.057c3.747 5.959 10.524 7.107 14.387 5.604 3.846-1.5 1.644-5.658-.425-11.036-2.067-5.379-6.872-8.523-10.728-7.02-3.854 1.502-6.328 7.534-3.234 12.452"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M57.112 90.934c1.26 3.016 5.908 4.465 8.328 3.774 2.422-.691 1.348-3.138.435-6.275-.908-3.138-3.61-5.12-6.031-4.428-2.42.693-4.202 3.409-2.732 6.929"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M137.911 78.194c-1.307 4.93-5.557 8.46-8.719 8.284-3.149-.181-2.715-4.085-2.709-8.928.005-4.843 2.57-8.625 5.726-8.445 3.155.179 6.933 4.46 5.702 9.089"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M134.803 77.332c.207 2.748-2.999 6.138-4.911 6.227-1.914.089-1.816-2.149-2.023-4.896-.21-2.747 1.176-5.045 3.089-5.133 1.912-.088 3.64 1.057 3.845 3.802"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M110.558 106.342s29.787-11.015 19.686-51.607C120.143 14.143 92.875 16.521 79.25 20.267 65.627 24.013 52.94 34.574 53.214 47.076c.95 43.285 17.556 55.82 26.75 59.743 5.034 2.15 18.555 4.608 30.594-.477"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M74.517 76.377c.63-.02 1.16-.52 1.22-1.184.25-2.798 2.14-4.591 4.935-4.68 2.494-.08 4.63 1.489 5.715 4.199.268.668 1.01.986 1.657.712.648-.275.955-1.038.688-1.706-1.506-3.759-4.55-5.934-8.143-5.819-4.05.13-7.015 2.963-7.379 7.05-.063.719.45 1.356 1.149 1.424.053.004.106.006.158.004M109.88 74.945c.631.008 1.183-.468 1.272-1.129.374-2.783 2.342-4.49 5.14-4.455 2.494.032 4.558 1.696 5.521 4.451.238.679.965 1.03 1.623.785.66-.245 1-.994.764-1.673-1.337-3.822-4.28-6.131-7.876-6.177-4.05-.05-7.14 2.646-7.685 6.713-.096.715.389 1.375 1.083 1.474.053.007.106.01.158.011"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M97.57 79.485a3.977 3.977 0 107.954-.03 3.977 3.977 0 00-7.954.03"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M107.94 91.758s-20.19-4.468-33.267-6.123c0 0-1.223 12.187 12.56 15.295 17.348 3.911 20.707-9.172 20.707-9.172"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M87.234 100.93c-5.436-1.226-8.532-3.864-10.296-6.609 2.831-1.114 6.738-1.54 10.95-1.001 6.327.81 11.438 3.558 13.037 6.673-3.11 1.562-7.509 2.332-13.691.937"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M57.9 79.187s13.868-13.254 5.88-31.22c0 0 4.031-2.474 21.842 1.115 17.53 3.532 44.093 6.235 56.487-6.575 9.321-9.634-.197-19.797-9.412-13.641 0 0 5.275-1.22 4.041-10.095-1.368-9.836-14.925-12.18-22.214-4.593 0 0 4.46-3.543.93-7.683-3.978-4.664-12.124-1.273-14.376 5.814 0 0-30.607-2.881-46.132 15.896C43.376 42.198 44.51 60.161 57.9 79.187"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M92.575 47.739c12.541 2.04 30.245 1.437 41.269-2.205 8.924-2.948 11.386-9.8 12-12.304.406 2.722-.593 6.03-3.736 9.277-12.393 12.81-38.955 10.107-56.486 6.575-17.81-3.588-21.842-1.116-21.842-1.116 6.7-4.701 22.311-1.282 28.795-.227M57.602 63.828c6.72.214 8.622-4.353 8.622-4.353s-.04 1.116-.159 2.893c-1.251 10.21-8.164 16.82-8.164 16.82-5.288-7.514-8.656-14.86-10.094-21.863 1.807 2.92 5.019 6.35 9.795 6.503"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M122.668 12.953c-.21 1.047 1.296 2.231 3.363 2.644 2.067.414 3.913-.1 4.123-1.147.208-1.046-1.297-2.23-3.364-2.645-2.068-.412-3.912.101-4.122 1.148"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                            <path
                                d="M129.983 116.345h-8.994a4.025 4.025 0 01-4.038-4.185l.753-21.567c.08-2.301 2.03-4.184 4.33-4.184h8.995a4.025 4.025 0 014.038 4.184l-.753 21.567c-.08 2.302-2.03 4.185-4.33 4.185"
                                className="svg-smartphone"
                            />
                            <path
                                d="M124.616 112.721c-.095 3.719 3.046 8.901 7.756 8.885 3.958-.014 8.435-1.72 8.887-7.472.37-4.71-2.48-8.947-7.189-9.032-5.26-.095-9.35 3.496-9.454 7.62"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M141.167 111.825s-.188-5.044-2.488-7.937c-1.738-2.186-4.79-3.563-7.413-2.42-2.584 1.124-1.666 4.349.463 4.045 2.13-.303 3.991 1.244 4.004 2.666.012 1.42 3.172 4.946 5.434 3.646"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M132.152 101.21s.392-.072-.53.29c-.744.29-1.212.976-1.338 1.433-.207.748.048 1.384.549 1.736.484.34 1.46.342 2.136.443.676.1 1.672.234 2.391 1.152.719.916.802 2.195.445 2.091 0 0-.408-1.95-1.692-2.436s-2.24-.229-2.846-.366c-.605-.136-1.497-.502-1.614-1.673-.117-1.171.458-2.349 2.499-2.67"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                        </g>
                    </g>
                </svg>
            )
        } else {
            return (
                <svg width={192} height={136} viewBox="0 0 192 136" {...props}>
                    <defs>
                        <path id="prefix__a" d="M0 0h192v136H0z" />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <mask id="prefix__b" fill="#fff">
                            <use xlinkHref="#prefix__a" />
                        </mask>
                        <g mask="url(#prefix__b)">
                            <path
                                d="M112.686 108.165l-.146 10.001s8.12 11.657 17.59 13.016c8.34 1.197 12.045-5.417 12.045-12.355 0-4.19-1.533-9.485-1.533-9.485s-8.38 3.161-12.102 5.808c0 0 2.866 2.646 2.388 5.532-.492 2.973-5.493.241-6.687-.48-4.216-2.549-10.095-9.39-11.555-12.037m-25.955 1.165l-.221 6.782s-13.488 3.149-13.795 7.79c-.292 4.412 12.265 6.177 12.265 6.177l-1.896 9.558s-19.129-3.382-19.421-14.853c-.292-11.472 15.768-14.413 23.068-15.454"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M113.76 108.34s.671 13.957 4.738 30.243c0 0-4.332 2.439-16.264 2.637-6.506.109-13.963-.733-17.663-2.073 0 0 1.639-4.59 2.515-9.417.896-4.936-.843-11.627.307-20.952 0 0 3.897.617 12.166.48 8.268-.138 14.2-.918 14.2-.918"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M83.423 139.414s2.217-5.592 3.662-9.684c.61-1.726-1.729-10.798-.814-20.557 0 0 4.08.178 11.229.175 1.363 5.533 4.006 10.04 5.423 10.255 1.436.217 2.83-2.092 4.128-10.414 3.41-.246 5.946-1.543 5.946-1.543s3.19 5.24 3.95 7.873c.602 2.086-.836 7.232-.582 8.19.867 3.261.815 6 1.813 8.632.772 2.036 2.772 3.685 3.117 5.866 0 0-6.199 3.564-19.22 3.78-7.1.119-14.788.02-18.652-2.573"
                                className="svg-char-suit"
                            />
                            <path
                                d="M48 24.808c0 11.292 9.355 20.447 20.895 20.447S89.791 36.1 89.791 24.808c0-11.293-9.355-20.447-20.896-20.447C57.355 4.36 48 13.515 48 24.808"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M55.202 42.738c5.149 8.984 18.305 11.044 29.383 4.601 11.08-6.443 15.886-18.949 10.736-27.933-5.149-8.984-18.304-11.044-29.383-4.6-11.079 6.442-15.886 18.949-10.736 27.932"
                                className="svg-char-ribbon"
                            />
                            <path
                                d="M48 24.808c0-4.132 1.257-7.975 3.41-11.19-.307 2.476-.56 8.666 3.754 12.633 0 0-.716 2.887 2.866 4.33l-4.019 8.573C50.295 35.463 48 30.398 48 24.808"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M137.075 79.272s7.88-13.35 5.373-33.918c-2.508-20.567-19.707-34.125-42.269-35-12.662-.49-24.589 3.49-33.357 10.498-10.58 8.455-15.99 21.216-13.688 36.049 3.344 21.55 12.299 32.835 17.314 36.443 10.338 7.439 49.074 18.402 66.627-14.072"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M59.33 94.29c3.706 5.987 10.446 7.164 14.295 5.672 3.834-1.49 1.659-5.666-.38-11.065-2.037-5.399-6.806-8.57-10.648-7.078-3.842 1.491-6.327 7.529-3.268 12.47"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M63.26 93.179c1.242 3.028 5.863 4.497 8.273 3.814 2.413-.682 1.354-3.14.458-6.288-.892-3.15-3.574-5.145-5.986-4.461-2.41.684-4.194 3.4-2.745 6.935"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M143.627 81.11c-1.32 4.937-5.563 8.46-8.708 8.27-3.133-.193-2.687-4.104-2.662-8.959.023-4.854 2.59-8.636 5.73-8.444 3.14.192 6.883 4.498 5.64 9.134"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M140.537 80.234c.196 2.756-3.007 6.142-4.91 6.223-1.906.082-1.8-2.16-1.995-4.916-.198-2.754 1.19-5.051 3.093-5.132 1.904-.081 3.618 1.073 3.812 3.825"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M116.299 109.22s29.684-10.926 19.788-51.654c-9.897-40.73-37.041-38.452-50.614-34.749C71.9 26.52 59.234 37.057 59.459 49.59c.78 43.393 17.257 56.022 26.392 59.991 5.001 2.174 18.448 4.691 30.448-.36"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M129.983 116.345h-8.994a4.025 4.025 0 01-4.038-4.185l.753-21.567c.08-2.301 2.03-4.184 4.33-4.184h8.995a4.025 4.025 0 014.038 4.184l-.753 21.567c-.08 2.302-2.03 4.185-4.33 4.185"
                                className="svg-smartphone"
                            />
                            <path
                                d="M124.447 108.546c-.096 3.848 3.13 9.21 7.968 9.193 4.066-.014 8.665-1.78 9.13-7.731.38-4.874-2.548-9.258-7.385-9.345-5.404-.098-9.605 3.617-9.713 7.883"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M141.45 107.62s-.193-5.22-2.556-8.214c-1.786-2.262-4.921-3.686-7.615-2.505-2.655 1.164-1.712 4.5.475 4.187 2.188-.314 4.1 1.287 4.113 2.758.013 1.47 3.259 5.118 5.583 3.773"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M132.189 96.636s.403-.075-.545.3c-.764.3-1.244 1.01-1.374 1.482-.212.774.05 1.433.564 1.797.497.351 1.5.354 2.194.458.694.103 1.718.242 2.457 1.191.738.948.823 2.272.457 2.165 0 0-.42-2.019-1.738-2.522-1.319-.502-2.302-.236-2.924-.378-.622-.14-1.538-.519-1.658-1.73-.12-1.213.47-2.431 2.567-2.763"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M80.591 77.733a1.317 1.317 0 001.422-1.009c.655-2.767 2.853-4.287 5.733-3.966 2.568.286 4.534 2.173 5.259 5.049a1.314 1.314 0 001.597.957c.704-.18 1.129-.9.95-1.608-1.005-3.989-3.815-6.617-7.517-7.029-4.173-.465-7.62 1.94-8.579 5.982a1.325 1.325 0 001.135 1.624m36.925-1.04a1.315 1.315 0 001.441-.98c.712-2.751 2.94-4.225 5.813-3.845 2.562.34 4.49 2.267 5.156 5.156a1.314 1.314 0 001.576.99 1.324 1.324 0 00.983-1.589c-.924-4.008-3.68-6.693-7.372-7.182-4.163-.55-7.659 1.782-8.7 5.804a1.325 1.325 0 001.103 1.646"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M103.477 82.249c0 2.203 1.772 3.987 3.958 3.987s3.959-1.784 3.959-3.987c0-2.202-1.773-3.988-3.959-3.988s-3.958 1.786-3.958 3.988"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M57.91 43.55c2.866-23.814 33.818-30.804 53.732-23.814 13.25 4.65 18.399 12.022 20.365 17.603 10.314 6 7.217 22.348 7.217 22.348-13.91-6.107-21.693-15.97-24.744-20.574l-.015.023C109.9 45.467 96.563 60.443 71.76 61.878c-.497 15.312-7.894 19.64-7.894 19.64S55.045 67.364 57.91 43.55z"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M61.82 82.9c-3.104-5.051-6.536-13.38-8.33-23.87.076-.388.122-.605.122-.605s1.642 11.185 17.403 10.103c0 0-1.582 10.494-9.194 14.372"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M114.215 95.78s-20.11-4.4-33.13-6.009c0 0-1.17 12.221 12.56 15.283 17.278 3.854 20.57-9.274 20.57-9.274"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M93.645 105.054c-5.415-1.207-8.506-3.84-10.271-6.584 2.813-1.13 6.699-1.57 10.892-1.047 6.3.788 11.397 3.522 13 6.639-3.09 1.578-7.463 2.367-13.621.992"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M114.406 37.757a.535.535 0 01.738-.181.544.544 0 01.18.743c-.167.279-4.225 6.908-13.497 13.335-7.844 5.437-20.92 11.61-39.848 11.61-1.678 0-3.404-.048-5.174-.15a.542.542 0 01.061-1.08c21.41 1.233 35.909-5.432 44.305-11.24 9.1-6.295 13.195-12.97 13.235-13.037zm5.708-1.404a.535.535 0 01.723.236c.064.127 6.538 12.72 20.108 21.44.25.16.324.495.165.747a.536.536 0 01-.742.165c-13.815-8.877-20.424-21.731-20.488-21.86a.543.543 0 01.234-.728zm3.322-7.516a.537.537 0 01.677.348c.043.135 4.488 13.604 16.522 24.35.222.199.243.54.046.764a.533.533 0 01-.759.046c-12.261-10.95-16.787-24.689-16.831-24.826a.542.542 0 01.345-.682zm-5.067-.136a.535.535 0 01.75-.123c.241.175.296.512.123.755s-4.364 6.026-14.397 11.702c-8.7 4.923-23.524 10.597-45.625 10.597-1.33 0-2.69-.02-4.072-.063a.54.54 0 01.033-1.083c24.073.743 39.975-5.224 49.074-10.358 9.852-5.56 14.073-11.369 14.114-11.427zm-55.487-1.8c14.133-6.477 27.942-7.17 37.039-6.61 9.858.606 16.48 2.778 16.546 2.8a.542.542 0 01-.337 1.028c-.258-.086-26.139-8.452-52.803 3.767a.537.537 0 01-.711-.268.543.543 0 01.266-.717zm57.146-7.797c.64-.976 2.708-.737 4.623.535 1.915 1.273 2.947 3.096 2.309 4.073-.64.976-2.712.736-4.625-.538-1.915-1.271-2.948-3.094-2.307-4.07z"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                            <path
                                d="M90.09 128.225c5.283 3.31 2.585 8.526-.81 12.149-3.303 3.525-7.06 5.16-11.387 1.47-3.797-3.235-2.045-8.971.438-11.912 2.496-2.958 8.614-3.677 11.758-1.707"
                                className={`svg-char-${color}-skin`}
                            />
                        </g>
                    </g>
                </svg>
            )
        }
    }
}

export default CharIntroLogin
