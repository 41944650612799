import { libraryService } from '../../services/library.service';

// CONST
export const Types = {
    LIST: 'library/LIST',
    FILE_LIST: 'library/FILE_LIST',
    FILE: 'library/FILE',
    RESET: 'library/RESET',
};

// INITIAL STATE
const initialState = {
    list: {},
    fileList: {},
    file: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.FILE_LIST:
            return {
                ...state,
                fileList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.FILE:
            return {
                ...state,
                file: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                list: {},
                fileList: {},
                file: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getCategoryList(params) {
    return dispatch => {
        return libraryService.getCategoryList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.categories,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getFileList(params) {
    return dispatch => {
        return libraryService.getFileList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.files,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getFileListCategory(params) {
    return dispatch => {
        return libraryService.getFileList(params).then(data => {
            dispatch({
                type: Types.FILE_LIST,
                payload: {
                    data: data.files,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FILE_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getFile(params) {
    return dispatch => {
        return libraryService.getFile(params).then(data => {
            dispatch({
                type: Types.FILE,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FILE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getCategoryList,
    getFileList,
    getFileListCategory,
    getFile,
    reset
}
