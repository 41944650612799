import React, { useState } from 'react';
import config from '../../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as FreeActions } from '../../../../redux/ducks/free';
import { actions as AuthActions } from '../../../../redux/ducks/auth';
import { imageParser } from '../../../../utils/utils';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import PointsBar from '../../../../components/PointsBar';
import DialogPurchase from './DialogPurchase';
import DialogLevelQuestion from './DialogLevelQuestion';
import DialogWarningProcess from './DialogWarningProcess';
import { Icon } from '../../../../components/Images/Images';

export default function FreeCategoryItem(props) {
    const { item, origin, path } = props;
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [openDialogLevel, setOpenDialogLevel] = useState(false);
    const [openDialogPurchase, setOpenDialogPurchase] = useState(false);
    const [openDialogWarning, setOpenDialogWarning] = useState(false);

    const FlagPremium = () => {
        if (item.purchased === "N") {
            return (
                <>
                    <div className="category-flag __premium"></div>
                    <div className="category-flag-icon">
                        <Icon ic="crown_filled" className="icon-xs" />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="category-flag __success"></div>
                    <div className="category-flag-icon">
                        <Icon ic="check" className="icon-xs" />
                    </div>
                </>
            )
        }
    }

    function onCategorySelected() {
        if (item.premium === "Y" && item.purchased === "N" && auth.user.premium === "N") {
            setOpenDialogPurchase(true);
        } else {
            if (item.has_children && item.has_children === 'Y') {
                history.push({
                    pathname: `${path.replace(/./g, '')}/free/${item.id_category}`,
                    state: {
                        category: item,
                        origin: origin
                    }
                });
            } else {
                if (item.available_levels.length === 1) {
                    history.push({
                        pathname: `${path.replace(/./g, '')}/free/question`,
                        state: {
                            idCategory: item.id_category,
                            idLevel: "0",
                            category: item.name,
                            subcategory: ""
                        }
                    });
                } else {
                    setOpenDialogLevel(true);
                }
            }
        }
    }

    async function buyCategory(category) {
        setOpenDialogPurchase(false);
        dispatch(FreeActions.getBuyCategory({
            id_category: category.id_category,
            purchase_type: 2 // 2 - Categoria Modo Livre
        }));
        dispatch(AuthActions.updateProfile({
            neuros: auth.user.neuros - parseInt(item.neuro_cost)
        }))
    }

    async function sendWarningProcess(type) {
        setOpenDialogWarning(false);
        dispatch(FreeActions.setWarningProcess({
            id_category: item.id_category,
            warning_type: type
        }));
        onCategorySelected();
    }

    return (
        <div className={`category-list-item${config.learn.free.categories.grid.style === "card" ? " __card" : " __default"}`}>
            <div className={`category-list-item-content${config.learn.free.categories.grid.size === "large" ? " __large" : ""}`}>
                <Card>
                    <CardActionArea onClick={() => item.warning ? setOpenDialogWarning(true) : onCategorySelected()}>
                        {
                            item.premium === "Y" ?
                                <FlagPremium />
                                : null
                        }
                        {
                            item.premium === "Y" && item.purchased === "N" && auth.user.premium === "N" ?
                                <PointsBar type="neuros" value={item.neuro_cost} />
                                : null
                        }
                        <CardMedia
                            style={{ backgroundColor: item.bgcolor }}
                            className={`category-image${config.learn.free.categories.grid.mobile === "full" ? " __full" : ""}`}
                            image={item ? imageParser(item.category_image) : null}
                            title={item.url}
                        />
                        <CardContent className="category-text">
                            <div className="category-text-info">
                                <Typography variant="body2" component="h4" align="center">{item.name}</Typography>
                            </div>
                        </CardContent>
                    </CardActionArea>
                    <DialogLevelQuestion open={openDialogLevel} close={() => setOpenDialogLevel(false)} item={item} path={path} category={item.name} type="category" />
                    <DialogPurchase open={openDialogPurchase} close={() => setOpenDialogPurchase(false)} item={item} purchase={buyCategory} />
                    {
                        item.warning ?
                            <DialogWarningProcess open={openDialogWarning} close={() => setOpenDialogWarning(false)} warning={item.warning} send={sendWarningProcess} />
                            : null
                    }
                </Card>
            </div>
        </div>
    );
}
