import * as React from "react"

function ImgCircles(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M38.189 36.184c1.017 2.5.347 5.365-1.816 6.64-2.576 1.519-6.148.443-7.301-2.34-1.05-2.534-.003-5.59 2.354-6.599l.137-.056.11-.04.02-.018.036-.027.037-.026.079-.046c2.159-1.1 5.41.22 6.344 2.512zm-5.85-.787a.835.835 0 01-.103.006l-.027-.002-.075.035c-1.594.791-2.168 3.23-1.113 4.83.98 1.487 3.434 1.82 4.75.647 1.175-1.047 1.418-3.094.508-4.448-.706-1.05-1.937-1.305-3.94-1.068z"
                    className="svg-circle-1"
                />
                <path
                    d="M28.287 26.803c1.275 3.134.436 6.718-2.26 8.308-3.223 1.9-7.695.553-9.134-2.92-1.32-3.188.014-7.035 2.99-8.268l.152-.06.134-.05.043-.039.038-.03.04-.027.085-.05c2.682-1.366 6.752.286 7.912 3.136zm-6.923-1.312l-.455.048a.902.902 0 01-.093.007l-.09-.004-.088.04c-2.188 1.04-2.977 4.336-1.55 6.5 1.32 2.004 4.6 2.449 6.372.87 1.58-1.407 1.904-4.143.682-5.961-.9-1.34-2.408-1.72-4.778-1.5z"
                    className="svg-circle-2"
                />
                <path
                    d="M25.992 8.683c1.728 4.245.595 9.088-3.037 11.23-4.353 2.565-10.4.745-12.339-3.937-1.8-4.344.052-9.595 4.135-11.202l.172-.065.147-.052.039-.039.07-.06.077-.053.082-.047c3.598-1.833 9.097.4 10.654 4.225zM15.94 6.587c-.07.008-.14.009-.207.003l-.055-.008-.118.052c-3.23 1.473-4.4 6.272-2.321 9.426 1.916 2.908 6.642 3.548 9.211 1.26 2.288-2.039 2.755-5.98.987-8.61-1.396-2.078-3.758-2.566-7.497-2.123z"
                    className="svg-circle-3"
                />
            </g>
        </svg>
    )
}

export default ImgCircles
