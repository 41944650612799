import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as NotificationActions } from '../../redux/ducks/notification_module';
import { identifyIphone, imageParser } from '../../utils/utils';
import EmptyContent from '../../components/EmptyContent';
import VideoDialog from '../../components/VideoDialog';
import NotificationListItem from './components/NotificationListItem';
import { Divider, List, Grid } from '@material-ui/core';
import { LoadingListAvatar2 } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function NotificationsList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [isFilter, setFilter] = React.useState(false);
    const [selectedNotification, setSelectedNotification] = React.useState(null);
    var userAux = JSON.parse(localStorage.getItem('auth.state.user'));
    const [didMount, setDidMount] = useState(false);
    const notification = useSelector(state => state.notification);
    const notificatonArray = props.notifications ? props.notifications : notification.notifications;
    const limit = 25;

    const handleClose = () => {
        setSelectedNotification(null);
        setFilter(false);
    };

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }

        setDidMount(true);

        if (didMount) {
            dispatch(NotificationActions.list({ page: 1, limit: 100 }));
        }

        return () => {
            setDidMount(false);
        }
    }, [auth, history, dispatch, didMount]);

    if (notification.updateAll) {
        dispatch(NotificationActions.updateAll(false));
        dispatch(NotificationActions.notificationUpdateAll({ notification_status: 'read' }));
        dispatch(NotificationActions.clear());
    }

    function getNotificationClick(notification) {
        goToInfo(notification);

        if (notification.read === 'N') {
            dispatch(NotificationActions.clear());

            if (notification.message_mode === 'GERAL') {
                dispatch(NotificationActions.notificationUpdate(
                    { general_notifications_ids: notification.id, notification_status: 'read' }))
                userAux.notification_count = userAux.notification_count - 1;
                localStorage.setItem('auth.state.user', JSON.stringify(userAux));
            } else {
                userAux.notification_count = userAux.notification_count - 1;
                localStorage.setItem('auth.state.user', JSON.stringify(userAux));
                dispatch(NotificationActions.notificationUpdate(
                    { user_notifications_ids: notification.id, notification_status: 'read' }))
            }
        }
    }

    function Loading(limit) {
        var loading = [];

        for (var i = 0; i < limit; i++) {
            loading.push(
                <div key={i}>
                    <LoadingListAvatar2 />
                </div>
            );
        }

        return (
            loading
        );
    }

    const ListItems = () => {
        return (
            <List className="notifications-list">
                {notificatonArray.map((notification, i) => {
                    return (
                        <div key={notification.id}>
                            <NotificationListItem
                                action={() => getNotificationClick(notification)}
                                image={notification.image ? imageParser(notification.image) : "https://b.qranio.com/avatar"}
                                status={notification.read}
                                text={notification.message}
                                date={notification.date}
                            />
                            {notificatonArray.length - 1 === i ? "" : <Divider variant="inset" component="li" />}
                        </div>
                    );
                })}
                {isFilter && <VideoDialog isFilter={isFilter} handleClose={handleClose} data={selectedNotification} type={selectedNotification.type} />}
            </List>
        )
    }

    function isDomain(link) {
        return process.env.REACT_APP_DOMAIN && process.env.REACT_APP_DOMAIN.length !== 0 && link.includes(process.env.REACT_APP_DOMAIN)
    }

    function openEmbed(link) {
        if (!isDomain(link)) {
            if (identifyIphone() && link.includes('pdf')) {
                window.open(link);
            } else {
                setFilter(true);
            }
        } else {
            window.location.assign(link)
        }
    }


    async function goToInfo(notification) {
        switch (notification.code) {
            case 'CAT_PERG':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'free')}/question`,
                    state: { idCategory: JSON.parse(notification.reference).id, idLevel: 1, next: false, type: 'first' }
                })
                break;

            case 'CAT_NOVA':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'free')}/question`,
                    state: { idCategory: JSON.parse(notification.reference).id, idLevel: 1, next: false, type: 'first' }
                })
                break;

            case 'CAT_NOVA_SP':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'free')}/question`,
                    state: { idCategory: JSON.parse(notification.reference).id, idLevel: 1, next: false, type: 'first' }
                })
                break;

            case 'LNK_WEB':
                if (notification.source || (notification.blob) || JSON.parse(notification.reference).link) {
                    openEmbed(JSON.parse(notification.reference).link);
                    if (notification.source) {
                        setSelectedNotification({ reference: JSON.stringify({ link: notification.source }), type: 'link' });
                    } else {
                        if (JSON.parse(notification.reference).link) {
                            setSelectedNotification({ reference: notification.reference, type: 'link' });
                        } else {
                            setSelectedNotification({ reference: notification, type: 'link' });
                        }
                    }
                }
                break;

            case 'VID_EXIBIR':
                if (notification.source || (notification.blob) || JSON.parse(notification.reference).link) {
                    setFilter(true);
                    if (notification.source) {
                        setSelectedNotification({ reference: { link: notification.source }, type: 'video' });
                    } else {
                        if (JSON.parse(notification.reference).link) {
                            setSelectedNotification({ reference: { link: JSON.parse(notification.reference).link }, type: 'video' });
                        } else {
                            setSelectedNotification({ reference: notification, type: 'video' });
                        }
                    }
                }
                break;

            case 'CAT_NOVA_FILHOS_SP':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'free')}/question`,
                    state: { idCategory: JSON.parse(notification.reference).id, idLevel: 1, next: false, type: 'first' }
                })
                break;

            case 'TRE_NOVO':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'classroom')}/${JSON.parse(notification.reference).id}`,
                    state: { i: JSON.parse(notification.reference).id, origin: history.location.pathname, mode: 'detail' }
                })
                break;

            case 'SCO_NOVO':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'scorm')}/${JSON.parse(notification.reference).id}/details`,
                    state: { id_course: JSON.parse(notification.reference).id, origin: history.location.pathname, }
                })
                break;

            case 'CUR_NOVO':
                history.push({
                    pathname: `${history.location.pathname.replace(/./g, '')}/trail/${JSON.parse(notification.reference).id}`,
                    state: { origin: "notifications" }
                })
                break;

            case 'RES_AMIGO':
                //console.log(notification.code);
                break;

            case 'PER_DIA':
                history.push({
                    pathname: `${history.location.pathname.replace(/^\/[\w\s]+$/, 'periodic')}/question`,
                    state: { next: false, type: 'first', origin: history.location.pathname, }
                })
                break;

            case 'PRE_NOVO':
                //console.log(notification.code);
                break;

            case 'RAN_CHAMP_MONTH':
                //console.log(notification.code);
                break;

            case 'RAN_CHAMP_WEEK':
                //console.log(notification.code);
                break;

            case 'RAN_CHAMP_DAY':
                //console.log(notification.code);
                break;

            case 'MIS_NOVA':
                //console.log(notification.code);
                break;

            case 'AVI_GERAL':
                //console.log(notification.code);
                break;

            case 'CAT_NOVA_MP':
                //console.log(notification.code);
                break;

            case 'CAT_PERG_SP':
                //console.log(notification.code);
                break;

            case 'CAT_PERG_MP':
                //console.log(notification.code);
                break;

            case 'ANI_BONUS':
                //console.log(notification.code);
                break;

            case 'ASS_BENEF':
                //console.log(notification.code);
                break;

            case 'LOG_BONIF':
                //console.log(notification.code);
                break;

            case 'RAN_SUB':
                //console.log(notification.code);
                break;

            case 'RAN_DESC':
                //console.log(notification.code);
                break;

            case 'RAN_NOVA':
                //console.log(notification.code);
                break;

            case 'PRE_RESG':
                //console.log(notification.code);
                break;

            case 'PRE_FDISP':
                //console.log(notification.code);
                break;

            case 'PRE_DISP':
                //console.log(notification.code);
                break;

            case 'BAD_AMIGO':
                //console.log(notification.code);
                break;

            case 'QUI_CONS':
                //console.log(notification.code);
                break;

            case 'AMI_SEG':
                //console.log(notification.code);
                break;

            case 'DES_SOLIC':
                //console.log(notification.code);
                break;

            default:
                return null;
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {
                    notification.notifications && notification.notifications.length !== 0
                        ?
                        <ListItems />
                        : notification.notifications && notification.notifications.length === 0 && notification.status === 'finished'
                            ?
                            <EmptyContent icon={<Icon ic="bell_filled" className="icon-xxl" />} text={intl.get("YOU_HAVE_NO_NOTIFICATIONS")} />
                            :
                            <div className="pl-3 pr-3">
                                {Loading(limit)}
                            </div>
                }
            </Grid>
        </Grid>
    )
};
