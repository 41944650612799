import React from 'react';
import config from '../../config'
import SvgIcon from '@material-ui/core/SvgIcon'
import FlagIconFactory from 'react-flag-icon-css';
import {
    StageMedalImg, RibbonImg, PlayImg,
    FreeLevelImg, MedalImg, RatingImg,
    PointsImg, GameHelpImg, ExamGradeImg
} from '../../dist/imagesLib';
import CharImg from '../../dist/charsLib';

const project = config.app.name_project.toLowerCase()
let theme = require(`../../assets/scss/projects/${project}/_export.scss`)

// ==============================================
// LOGO
// ==============================================

export const Logo = (props) => {
    const { type } = props;

    function selectLogo(l) {
        switch (l) {
            case 'app-icon':
                return <config.logos.app_icon.default {...props} />
            case 'main-color':
                return <config.logos.main.color.default {...props} />
            case 'main-clean':
                return <config.logos.main.clean.default {...props} />
            case 'group-color':
                return <config.logos.group.color.default {...props} />
            case 'group-clean':
                return <config.logos.group.clean.default {...props} />
            case 'badge-color':
                return <config.logos.badge.color.default {...props} />
            case 'badge-clean':
                return <config.logos.badge.clean.default {...props} />
            case 'qranio-color':
                return <config.logos.qranio.color.default {...props} />
            case 'qranio-clean':
                return <config.logos.qranio.clean.default {...props} />
            default:
                return <config.logos.main.color.default {...props} />
        }
    }

    return selectLogo(type);
}

// ==============================================
// CHARS
// ==============================================

export function Char(props) {
    return (
        <CharImg {...props} />
    )
}

// ==============================================
// ICONS
// ==============================================

export function Icon(props) {
    const mergeObjects = (a, b) => {
        try {
            return Object.assign(a, b)
        } catch (err) {
            return {}
        }
    }

    const tryRequire = () => {
        try {
          return require(`../../dist/img/projects/${project}/iconsLib.js`).default;
        } catch (err) {
            return {}
        }
      };
    
    const iconLibGen = require(`../../dist/iconsLib`).default;
    const iconLibPro = tryRequire();
    let iconPath = mergeObjects(iconLibGen, iconLibPro);

    return (
        <SvgIcon
            htmlColor={theme[props.color]}
            viewBox="0 0 24 24"
            fontSize={props.fontSize}
            className={props.className}
        >
            <path d={iconPath[props.ic]} />
        </SvgIcon>
    )
}

// ==============================================
// POINTS
// ==============================================

export function Points(props) {
    return (
        // type='qis' or 'xp' or 'neuros'
        <PointsImg {...props} />
    )
}

// ==============================================
// GAME HELP
// ==============================================

export function GameHelp(props) {
    return (
        // type='time' or 'eliminate-1' or 'eliminate-2' or 'answered' or 'skip'
        <GameHelpImg {...props} />
    )
}

// ==============================================
// FLAGS
// ==============================================

export function Flag(props) {
    const FlagIcon = FlagIconFactory(React, { useCssModules: false })

    if (props.type === "country") {
        return (
            <FlagIcon code={props.code} squared={true} className={`avatar-bordered-sm border-lighter rounded-circle ${props.className}`} />
        )
    } else if (props.type === "states") {
        return (
            <img src={require(`../../dist/img/flags/states/${props.code}.svg`)} className={`avatar-bordered-sm border-lighter rounded-circle ${props.className}`} alt={props.code} />
        )
    } else {
        return (
            <span className="text-danger font-weight-bold">Coloque o tipo da bandeira</span>
        )
    }
}

// ==============================================
// STAGE MEDAL
// ==============================================

export function StageMedal(props) {
    return (
        // type='gold' or 'silver' or 'bronze'
        <StageMedalImg {...props} />
    )
}

// ==============================================
// RIBBON
// ==============================================

export function Ribbon(props) {
    return (
        // type='straight-filled' or 'straight-outline' or 'curved'
        // text='Title'
        <RibbonImg {...props} />
    )
}

// ==============================================
// MODULE
// ==============================================

export function Play(props) {
    return (
        // type='#module name'
        <PlayImg {...props} />
    )
}

// ==============================================
// FREE DIFFICULTY LEVEL
// ==============================================

export function FreeLevel(props) {
    return (
        // type='easy' or 'medium' or 'hard' or 'random'
        <FreeLevelImg {...props} />
    )
}

// ==============================================
// MEDAL
// ==============================================

export function Medal(props) {
    return (
        // type='gold' or 'silver' or 'bronze'
        <MedalImg {...props} />
    )
}

// ==============================================
// EXAM GRADE
// ==============================================

export function ExamGrade(props) {
    return (
        <ExamGradeImg {...props} />
    )
}

// ==============================================
// RATING
// ==============================================

export function Rating(props) {
    return (
        <RatingImg {...props} />
    )
}
