import React, { useEffect, useState } from 'react';
import config from '../../config';
import useReactRouter from 'use-react-router'
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuList from './menu.list';
import HowToPlay from './howtoplay';
import MyProfile from './myprofile';
import FrequentlyAsked from './frequently.asked';
import FrequentlyAskedAnswer from './frequently.asked.answer';
import Contact from './contact';
import TermsOfUse from './terms';
import PrivacyPolicies from './privacy';

export default function Faq(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === (false || null)) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <Switch>
                    <Route path={`${props.match.path}`} exact component={
                        (props) => <MenuList {...props} />}
                    />
                    <Route path={`${props.match.path}/how-to-play`} exact component={
                        (props) => <HowToPlay {...props} />}
                    />
                    <Route path={`${props.match.path}/my-profile`} exact component={
                        (props) => <MyProfile {...props} />}
                    />
                    <Route path={`${props.match.path}/questions`} exact component={
                        (props) => <FrequentlyAsked {...props} />}
                    />
                    <Route path={`${props.match.path}/questions/:idCategory/:idQuestion`} exact component={
                        (props) => <FrequentlyAskedAnswer {...props} />}
                    />
                    <Route path={`${props.match.path}/contact`} exact component={
                        (props) => <Contact {...props} />}
                    />
                    <Route path={`${props.match.path}/terms-of-use`} exact component={
                        (props) => <TermsOfUse {...props} />}
                    />
                    <Route path={`${props.match.path}/privacy-policies`} exact component={
                        (props) => <PrivacyPolicies {...props} />}
                    />
                </Switch>
            }
        </div>
    )
}
