import * as React from "react"

function ImgChangePassword(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M11.704 2c3.384 0 6.442 1.803 8.19 4.632L22 5.5l-1.465 5-4.396-1.85 2.047-1.101C16.769 5.379 14.364 4 11.704 4c-4.313 0-7.8 3.586-7.8 8s3.487 8 7.8 8c3.215 0 6.061-2.013 7.237-5.016l1.762.76C19.233 19.497 15.693 22 11.704 22 6.348 22 2 17.528 2 12S6.348 2 11.704 2z"
                    fill="#7CB342"
                />
                <path
                    fill="#FFA000"
                    d="M17.053 15.037l-5.041-5.042-2.017 2.017 1.49 1.489h1.16v1.14h1.141v1.142h1.14v1.14h1.843l.688-.676z"
                />
                <path
                    d="M7.777 7.777a2.852 2.852 0 104.034 4.033 2.852 2.852 0 00-4.034-4.033zm1.766 1.766a.856.856 0 11-1.21-1.212.856.856 0 011.21 1.212z"
                    fill="#FFA000"
                />
                <path
                    fill="#D67C05"
                    d="M12.011 11.608l.404-.403 5.042 5.042-.404.403z"
                />
                <path
                    d="M12.999 10.982l-.453-.452a2.837 2.837 0 01-2.016 2.016l.453.454a3.398 3.398 0 002.015-2.018z"
                    fill="#D67C05"
                />
            </g>
        </svg>
    )
}

export default ImgChangePassword
