import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import config from '../../../config';
import {
    Grid, Hidden, Typography, Container, Card, CardContent,
    AppBar, Toolbar, IconButton
} from '@material-ui/core';
import Popup from './Popup';
import Carousel from './Carousel';
import Footer from '../../../components/Footer';
import { Icon, Logo, Char } from '../../../components/Images/Images';

export default function IntroLayout(props) {
    const { id, char, title, children, appbarMobile, backButton } = props;
    const { history } = useReactRouter();

    return (
        config.intro.type === 1 && config.intro.slider.filter(obj => obj.enabled === true).length !== 0 ?

            /* ### INTRO TYPE 1 (com tutorial) ### */
            <div id="intro-type-1">

                {/* Small Screen */}
                <Hidden mdUp>
                    <Grid container className="__small">
                        {
                            history.location.pathname === "/"
                                ?
                                <Grid item xs={12} id={id}>
                                    <Grid container id="intro-slider">
                                        {
                                            config.intro.show_popup ?
                                                <Popup />
                                                : null
                                        }
                                        <Carousel />
                                        <Grid item xs={12} className="intro-slider-footer">
                                            <Grid container alignContent="space-between" justify="center" spacing={2}>
                                                {
                                                    config.intro.show_title ?
                                                        <Grid item xs={12} align="center">
                                                            <Typography variant="body1" className="text-light" component="p">
                                                                {intl.get("INTRO_TITLE")}
                                                            </Typography>
                                                        </Grid>
                                                        : null
                                                }
                                                <Grid item xs={12} sm={8}>
                                                    {children}
                                                </Grid>
                                                {
                                                    config.intro.show_footer ?
                                                        <Grid item xs={12}>
                                                            <Footer />
                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container id={id} alignContent="flex-start" className="intro-mobile-page">
                                    <AppBar className={`"appbar appbar-${appbarMobile}`} position={appbarMobile === "overlay" ? "absolute" : "sticky"} color="primary">
                                        <Toolbar>
                                            {
                                                backButton !== false ?
                                                    <IconButton
                                                        color="inherit"
                                                        aria-label="back"
                                                        edge="start"
                                                        onClick={() => history.goBack()}
                                                    >
                                                        <Icon ic="back" />
                                                    </IconButton>
                                                    : null
                                            }
                                            <div className="w-100 overflow-hidden">
                                                <Typography variant="h4" noWrap>
                                                    {title}
                                                </Typography>
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    {
                                        config.intro.vector_images ?
                                            char ?
                                                <Grid container justify="center" alignItems="flex-end" className="intro-mobile-page-header">
                                                    <Char type={char} />
                                                </Grid>
                                                : null
                                            : null
                                    }
                                    <Container maxWidth="sm" className="pt-3 pb-4">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {children}
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>
                        }
                    </Grid>
                </Hidden>

                {/* Large Screen */}
                <Hidden smDown>
                    <Grid container className="__large">
                        <Grid item xs={12} md={config.intro.large_split_screen ? 6 : 7} lg={config.intro.large_split_screen ? 6 : 8} xl={config.intro.large_split_screen ? 6 : 9}>
                            <Grid container id="intro-slider">
                                <Carousel />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={config.intro.large_split_screen ? 6 : 5} lg={config.intro.large_split_screen ? 6 : 4} xl={config.intro.large_split_screen ? 6 : 3} className="intro-column">
                            {
                                config.intro.show_popup ?
                                    <Popup />
                                    : null
                            }
                            <Grid container alignContent="space-between" justify="center" className="intro-column-container">
                                <Grid item xs={12} align="center">
                                    <Logo type={config.intro.logo.large_screen.type} height={config.intro.logo.large_screen.size} className="logo-main" />
                                </Grid>
                                <Grid item xs={12} lg={config.intro.large_split_screen ? 8 : 12}>
                                    {children}
                                </Grid>
                                <Grid item xs={12} className="intro-column-footer" align="center">
                                    {
                                        config.intro.show_footer ?
                                            <Footer />
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
            :
            /* ### INTRO TYPE 2 (sem tutorial) ### */
            <div id="intro-type-2">

                {/* Small Screen */}
                <Hidden mdUp>
                    <Grid container className="__small">
                        {
                            history.location.pathname === "/" ?
                                <Container maxWidth="sm" className="pb-3">
                                    <Grid container justify="center" className="min-vh-100" spacing={2}>
                                        {
                                            config.intro.show_popup ?
                                                <Grid item xs={12} sm={8} className="pt-3">
                                                    <Popup />
                                                </Grid>
                                                : null
                                        }
                                        <Grid item xs={12} sm={8}>
                                            <Grid container direction="column" alignItems="center" justify="center" className="h-100 flex-nowrap">
                                                <Card className="w-100">
                                                    <div className="intro-clean-header">
                                                        <Logo type={config.intro.logo.small_screen.type} height={config.intro.logo.small_screen.size} />
                                                    </div>
                                                    <CardContent className="intro-clean-content">
                                                        {children}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="intro-clean-footer">
                                            {
                                                config.intro.show_footer ?
                                                    <Grid container direction="column" alignItems="center" justify="flex-end" className="h-100 flex-nowrap">
                                                        <Footer />
                                                    </Grid>
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <Grid container id={id} alignContent="flex-start" className="intro-mobile-page">
                                    <AppBar className={`"appbar appbar-${appbarMobile}`} position={appbarMobile === "overlay" ? "absolute" : "sticky"} color="primary">
                                        <Toolbar>
                                            {
                                                backButton !== false ?
                                                    <IconButton
                                                        color="inherit"
                                                        aria-label="back"
                                                        edge="start"
                                                        onClick={() => history.goBack()}
                                                    >
                                                        <Icon ic="back" />
                                                    </IconButton>
                                                    : null
                                            }
                                            <div className="w-100 overflow-hidden">
                                                <Typography variant="h4" noWrap>
                                                    {title}
                                                </Typography>
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    {
                                        char ?
                                            <Grid container justify="center" alignItems="flex-end" className="intro-mobile-page-header">
                                                <Char type={char} />
                                            </Grid>
                                            : null
                                    }
                                    <Container maxWidth="sm" className="pt-3 pb-4">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {children}
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>
                        }
                    </Grid>
                </Hidden>

                {/* Large Screen */}
                <Hidden smDown>
                    <Grid container className="__large">
                        <Container maxWidth="sm" className="pb-3">
                            <Grid container justify="center" className="min-vh-100" spacing={2}>
                                {
                                    config.intro.show_popup ?
                                        <Grid item xs={12} sm={8} className="pt-3">
                                            <Popup />
                                        </Grid>
                                        : null
                                }
                                <Grid item xs={12} sm={8}>
                                    <Grid container direction="column" alignItems="center" justify="center" className="h-100 flex-nowrap">
                                        <Card className="w-100">
                                            <div className="intro-clean-header">
                                                <Logo type={config.intro.logo.large_screen.type} height={config.intro.logo.large_screen.size} />
                                            </div>
                                            <CardContent className="intro-clean-content">
                                                {children}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="intro-clean-footer">
                                    {
                                        config.intro.show_footer ?
                                            <Grid container direction="column" alignItems="center" justify="flex-end" className="h-100 flex-nowrap">
                                                <Footer />
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Hidden>
            </div>
    )
}