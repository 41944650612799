import * as React from "react"

function ImgStars3(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M21.062 9.295c.598 1.182.96 2.482 1.053 3.844a10.044 10.044 0 01-.516 3.96 10.197 10.197 0 017.812-.57 10.01 10.01 0 01-1.053-3.843 10.046 10.046 0 01.517-3.961 10.196 10.196 0 01-7.813.57"
                    className="svg-stars-right-1"
                />
                <path
                    d="M8.586 13.82a7.54 7.54 0 011.09 2.796 7.561 7.561 0 01-.077 3.006 7.69 7.69 0 015.81-1.036 7.555 7.555 0 01-1.09-2.796 7.562 7.562 0 01.078-3.007 7.693 7.693 0 01-5.811 1.037"
                    className="svg-stars-right-4"
                />
                <path
                    d="M24.16 24.25c.417.644.703 1.37.84 2.15a5.81 5.81 0 01-.06 2.313 5.917 5.917 0 014.47-.798 5.8 5.8 0 01-.838-2.15 5.808 5.808 0 01.059-2.313 5.917 5.917 0 01-4.47.797"
                    className="svg-stars-right-2"
                />
                <path
                    d="M12.296 27.08c.463.92.74 1.93.81 2.99a7.81 7.81 0 01-.409 3.076 7.92 7.92 0 016.072-.427 7.786 7.786 0 01-.81-2.989 7.79 7.79 0 01.41-3.076 7.924 7.924 0 01-6.073.427"
                    className="svg-stars-right-3"
                />
            </g>
        </svg>
    )
}

export default ImgStars3
