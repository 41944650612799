import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { actions as ExamActions } from '../../../../redux/ducks/exam';
import { goToTopAnimation } from '../../../../utils/utils';
import {
    Grid, IconButton, useTheme, useMediaQuery, Button,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';

function splitArrayIntoChunks(arr, chunkSize) {
    let chunks = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
}

export default function ExamNavigation(props) {
    const { idExam, questions } = props
    const dispatch = useDispatch();
    const isScreenLg = useMediaQuery(theme => theme.breakpoints.up('md'));
    const isScreenSm = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(null);
    const [chunks, setChunks] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [itemId, setItemId] = useState(null);
    const activeQuestion = questions.find(
        question => question.question_active === "Y"
    );

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    useEffect(() => {
        const chunkSize = isScreenLg ? 12 : isScreenSm ? 8 : 5;
        setChunks(splitArrayIntoChunks(questions, chunkSize));
    }, [isScreenLg, isScreenSm, questions]);

    useEffect(() => {
        if (chunks.length > 0) {
            for (let i = 0; i < chunks.length; i++) {
                const objectIndex = chunks[i].findIndex(obj => obj.question_active === "Y");
                if (objectIndex !== -1) {
                    setActiveStep(i);
                    return; // Para após encontrar
                }
            }
            setActiveStep(0); // Se não encontrar, define como null
        }
    }, [chunks]);

    async function setQuestion(id) {
        setOpenAlert(false);
        goToTopAnimation();
        dispatch(ExamActions.getQuestions({
            id_exam: idExam,
            id_question: id,
        }));
        dispatch(ExamActions.setLoading({}));
        
    }

    const handleClickOpen = (id) => {
        setItemId(id);
        setOpenAlert(true);
    };

    return (
        <>
            <Grid container justify="center">
                <Grid item container direction="row" xs={12} sm={config.learn.exam.navigation === "top" ? 12 : 10} className="nav-content">
                    <div className="nav-action-button mr-1">
                        <IconButton onClick={handleBack} disabled={activeStep === 0} aria-label={intl.get("BTN_BACK")}>
                            <Icon ic="arrow_left" />
                        </IconButton>
                    </div>
                    <SwipeableViews
                        className="nav-action-content"
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        slideClassName="overflow-hidden"
                    >
                        {
                            chunks.map((arr, index) => {
                                return (
                                    <div key={index} className="slide-content">
                                        {
                                            arr.map((item, index) => {
                                                return (
                                                    <IconButton
                                                        key={index}
                                                        disabled={item.question_active === "Y"}
                                                        onClick={() => activeQuestion.user_answered === "Y" ? setQuestion(item.id_question) : handleClickOpen(item.id_question)}
                                                        className={`MuiButton-root MuiButton-outlined btn-question${item.question_active === "Y" ? " __active" : item.user_answered === "Y" ? " __answered" : ""}`}
                                                    >
                                                        {item.number_question}
                                                    </IconButton>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </SwipeableViews>
                    <div className="nav-action-button ml-1">
                        <IconButton onClick={handleNext} disabled={activeStep === chunks.length - 1} aria-label={intl.get("BTN_NEXT")}>
                            <Icon ic="arrow_right" />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {intl.get("EXAM_QUESTION_CHANGE_ALERT_TITLE")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {intl.get("EXAM_QUESTION_CHANGE_ALERT_TEXT")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAlert(false)} color="primary">
                        {intl.get("BTN_CANCEL")}
                    </Button>
                    <Button onClick={() => setQuestion(itemId)} color="primary">
                        {intl.get("BTN_YES")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}