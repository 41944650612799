import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import countries from 'i18n-iso-countries';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { actions as SettingsActions } from '../../redux/ducks/settings';
import * as yup from 'yup';
import cep from 'cep-promise';
import {
    Grid, TextField, Container, Button, AppBar, Toolbar, IconButton,
    Typography, Divider, Card, CardActions, CardContent, Slide, Dialog,
    MenuItem, FormControl, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import SnackBar from '../../components/snackBar';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RedeemAddress(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const user = useSelector(state => state.auth.user);
    const redeemAddress = useSelector(state => state.settings.redeemAddress);
    const [loading, setLoading] = useState(false);
    const [countryData, setCountryData] = useState("BR");
    const [open, setOpen] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState('error');
    const [snackMessage, setSnackMessage] = useState(intl.get('ERROR_UPDATE_DATA'));
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    let language = user.language.substr(0, 2);
    let states = require('../../utils/states.json');

    useEffect(() => {
        dispatch(SettingsActions.getRedeemAddressList({}));
        return () => {
            dispatch(SettingsActions.resetRedeemAddress());
        }
    }, [dispatch]);

    const validations = yup.object().shape({
        country: yup.string()
            .required(() => intl.get('ERROR_SELECT_COUNTRY')),
        zip_code: countryData === "BR" ? yup.string()
            .test("is-cep", intl.get('ERROR_INVALID_ZIP_CODE'), function (value) {
                return new Promise((resolve) => {
                    cep(value).then((cep) => {
                        resolve(true)
                    }).catch(() => {
                        resolve(false)
                    })
                })
            })
            :
            yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
        address: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
        number: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
        neighborhood: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
        city: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
        state: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
    });

    const countryList = Object.keys(countries.getNames(language)).map((country) => {
        return {
            name: countries.getName(country, language),
            code: countries.getAlpha2Code(countries.getName(country, language), language)
        }
    });

    const sendData = (data) => {
        setLoading(true);
        setOpen(false);
        dispatch(SettingsActions.setRedeemAddress({
            country: data.country,
            zip_code: data.zip_code,
            address: data.address,
            number: String(data.number),
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            main_address: data.main_address ? "Y" : "N"
        }));
    }

    const deleteAddress = (id) => {
        setLoading(true);
        dispatch(SettingsActions.deleteRedeemAddress({ id_address: id }));
    }

    const setMainAddress = (id) => {
        setLoading(true);
        dispatch(SettingsActions.setRedeemMainAddress({ id_address: id }));
    }

    useEffect(() => {
        if (loading) {
            if (redeemAddress?.data?.success === "Y") {
                setLoading(false);
                setSnackStatus('success');
                setSnackOpen(true);
                setSnackMessage(intl.get(redeemAddress.data.message));
                delete redeemAddress.data.success;
                delete redeemAddress.data.message;

                dispatch(AuthActions.updateMainAddress({}));
                setUpdateUser(false);

                if (origin === "prizes" && user.confirmed_redeem_data === "N") {
                    history.push({
                        pathname: `${props.match.path.replace(/./g, '')}/settings/data-redeem/info`,
                        state: { origin: 'prizes' }
                    });
                } else if (origin === "prizes" && user.confirmed_redeem_data === "Y") {
                    history.push({ pathname: `${props.match.path.replace(/./g, '')}/prizes` });
                }
            } else if (redeemAddress?.data?.success === "N") {
                setLoading(false);
                setSnackStatus('error');
                setSnackOpen(true);
                setSnackMessage(intl.get(redeemAddress.data.message));
                delete redeemAddress.data.success;
                delete redeemAddress.data.message;
            }
        }
    }, [loading, redeemAddress, user.has_main_address, origin, dispatch, snackOpen, updateUser, history, props.match.path, user.confirmed_redeem_data]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("SETTINGS_DATA_REDEEM_ADDRESS_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="settings-redeem-address" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md">
                        {
                            redeemAddress && redeemAddress.load === "finished" ?
                                !redeemAddress.error ?
                                    redeemAddress.data?.addresses.length !== 0 ?
                                        !loading ?
                                            <Grid container justify="center" spacing={2} className="pt-3 pb-4">
                                                {
                                                    redeemAddress.data.addresses.map((item, index) => {
                                                        return (
                                                            <Grid key={index} item xs={12}>
                                                                <Card className={`card-address${item.main_address === "Y" ? " __main" : ""}`}>
                                                                    <CardContent>
                                                                        <Typography variant="body1" component="h2" className="mb-3">
                                                                            {item.main_address === "Y" ? intl.get("LABEL_MAIN_ADDRESS") : `${intl.get("LABEL_SECONDARY_ADDRESS")} ${index}`}
                                                                        </Typography>
                                                                        <Divider />
                                                                        <Typography variant="body2" component="p" className="mt-3">
                                                                            {`${item.address}, ${item.number} - ${item.neighborhood}`}
                                                                        </Typography>
                                                                        {
                                                                            item.complement ?
                                                                                <Typography variant="body2" component="p">
                                                                                    {`${intl.get("LABEL_COMPLEMENT")}: ${item.complement}`}
                                                                                </Typography>
                                                                                : null
                                                                        }
                                                                        <Typography variant="body2" component="p">
                                                                            {`${item.city}, ${item.state}`}
                                                                        </Typography>
                                                                        <Typography variant="body2" component="p">
                                                                            {`${intl.get("LABEL_ZIP_CODE")}: ${item.zip_code}`}
                                                                        </Typography>
                                                                        <Typography variant="body2" component="p">
                                                                            {countryList.find(country => country.code === item.country).name}
                                                                        </Typography>
                                                                    </CardContent>
                                                                    {
                                                                        item.main_address === "N" ?
                                                                            <CardActions>
                                                                                <Button size="small" color="primary" onClick={() => deleteAddress(item.id_address)}>
                                                                                    {intl.get("BTN_DELETE")}
                                                                                </Button>
                                                                                <Button size="small" color="primary" onClick={() => setMainAddress(item.id_address)}>
                                                                                    {intl.get("BTN_SET_ADDRESS_MAIN")}
                                                                                </Button>
                                                                            </CardActions>
                                                                            : null
                                                                    }
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })

                                                }
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Button
                                                        id="btn_settings_redeem_address_new"
                                                        variant="contained"
                                                        className="btn-block"
                                                        onClick={() => setOpen(true)}
                                                    >
                                                        <Icon ic="plus" className="icon-xs mr-2" /> {intl.get("BTN_NEW_ADDRESS")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            :
                                            <LoadingContent />
                                        :
                                        <EmptyContent icon={<Icon ic="address" className="icon-xxl" />} text={intl.get("EMPTY_ADDRESS")} button={true} buttonAction={() => setOpen(true)} buttonText={intl.get("BTN_NEW_ADDRESS")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingContent />
                        }
                        <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
                            <AppBar className="appbar appbar-default" position="sticky">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                                        <Icon ic="close" />
                                    </IconButton>
                                    <div className="w-100 overflow-hidden">
                                        <Typography variant="h4" noWrap>
                                            {intl.get("SETTINGS_DATA_REDEEM_NEW_ADDRESS_TITLE")}
                                        </Typography>
                                    </div>
                                </Toolbar>
                            </AppBar>
                            <Container maxWidth="md" className="pt-4 pb-4">
                                <Formik
                                    validationSchema={validations}
                                    initialValues={{
                                        country: 'BR',
                                        zip_code: '',
                                        address: '',
                                        number: '',
                                        complement: '',
                                        neighborhood: '',
                                        city: '',
                                        state: '',
                                        main_address: redeemAddress?.data?.addresses.length !== 0 ? false : true
                                    }}
                                    onSubmit={data => { sendData(data) }}
                                >
                                    {
                                        ({ handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, values }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Grid container justify="center" spacing={3}>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            select
                                                            fullWidth
                                                            required
                                                            name="country"
                                                            label={intl.get('LABEL_COUNTRY')}
                                                            variant={config.layout.input_variant}
                                                            defaultValue="BR"
                                                            value={values.country}
                                                            onBlur={handleBlur}
                                                            onChange={value => {
                                                                setFieldValue("country", value)
                                                                setCountryData(value.target.value)
                                                            }}
                                                            error={(errors.country && touched.country)}
                                                            helperText={(errors.country && touched.country) && errors.country}
                                                        >
                                                            {countryList.map((country) => (
                                                                <MenuItem
                                                                    value={country.code}
                                                                    key={country.code}
                                                                >
                                                                    {country.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            name="zip_code"
                                                            type="text"
                                                            label={intl.get('LABEL_ZIP_CODE')}
                                                            variant={config.layout.input_variant}
                                                            value={values.zip_code}
                                                            fullWidth
                                                            required
                                                            onBlur={handleBlur}
                                                            onChange={value => {
                                                                setFieldValue("zip_code", value.target.value);
                                                                cep(value.target.value).then((cep) => {
                                                                    setFieldValue("address", cep.street);
                                                                    setFieldValue("neighborhood", cep.neighborhood);
                                                                    setFieldValue("state", cep.state);
                                                                    setFieldValue("city", cep.city);
                                                                })
                                                            }}
                                                            error={(errors.zip_code && touched.zip_code)}
                                                            helperText={(errors.zip_code && touched.zip_code) && errors.zip_code}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            name="address"
                                                            type="text"
                                                            label={intl.get('LABEL_ADDRESS')}
                                                            variant={config.layout.input_variant}
                                                            value={values.address}
                                                            fullWidth
                                                            required
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.address && touched.address)}
                                                            helperText={(errors.address && touched.address) && errors.address}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            name="number"
                                                            type="number"
                                                            label={intl.get('LABEL_NUMBER')}
                                                            variant={config.layout.input_variant}
                                                            value={values.number}
                                                            fullWidth
                                                            required
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.number && touched.number)}
                                                            helperText={(errors.number && touched.number) && errors.number}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            name="complement"
                                                            type="text"
                                                            label={intl.get('LABEL_COMPLEMENT')}
                                                            variant={config.layout.input_variant}
                                                            value={values.complement}
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            name="neighborhood"
                                                            type="text"
                                                            label={intl.get('LABEL_NEIGHBORHOOD')}
                                                            variant={config.layout.input_variant}
                                                            value={values.neighborhood}
                                                            fullWidth
                                                            required
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.neighborhood && touched.neighborhood)}
                                                            helperText={(errors.neighborhood && touched.neighborhood) && errors.neighborhood}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <TextField
                                                            name="city"
                                                            type="text"
                                                            label={intl.get('LABEL_CITY')}
                                                            variant={config.layout.input_variant}
                                                            value={values.city}
                                                            fullWidth
                                                            required
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.city && touched.city)}
                                                            helperText={(errors.city && touched.city) && errors.city}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        {
                                                            countryData === "BR" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required
                                                                    name="state"
                                                                    label={intl.get('LABEL_STATE')}
                                                                    variant={config.layout.input_variant}
                                                                    defaultValue={values.state ? values.state : ""}
                                                                    value={values.state}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.state && touched.state)}
                                                                    helperText={(errors.state && touched.state) && errors.state}
                                                                >
                                                                    {states.br.map((item) => (
                                                                        <MenuItem
                                                                            value={item.uf}
                                                                            key={item.uf}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="state"
                                                                    type="text"
                                                                    label={intl.get('LABEL_STATE')}
                                                                    variant={config.layout.input_variant}
                                                                    value={values.state}
                                                                    fullWidth
                                                                    required
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.state && touched.state)}
                                                                    helperText={(errors.state && touched.state) && errors.state}
                                                                />
                                                        }
                                                    </Grid>
                                                    {
                                                        redeemAddress.data?.addresses.length !== 0 ?
                                                            <Grid item xs={12} sm={10}>
                                                                <FormControl component="fieldset">
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color="primary" checked={values.main_address} onChange={handleChange} name="main_address" />}
                                                                            label={intl.get('BTN_SET_ADDRESS_MAIN')}
                                                                            inputprops={{ 'aria-label': intl.get('BTN_SET_ADDRESS_MAIN') }}
                                                                        />
                                                                    </FormGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            : null
                                                    }
                                                    <Grid item xs={12} sm={6} lg={4}>
                                                        <Button
                                                            id="btn_settings_save"
                                                            variant="contained"
                                                            className="btn-block"
                                                            type="submit"
                                                        >
                                                            {intl.get('BTN_SAVE')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                </Formik>
                            </Container>
                        </Dialog>
                    </Container>
                </QrContent>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    closeSnack={() => setSnackOpen(false)}
                />
            </div>
        </div>
    )
}