import React from 'react';
import { feedDateTime, imageParser, getMonth, getDate, diffDate } from '../../../utils/utils';
import CardPeriodicQuestion from './cards/CardPeriodicQuestion';
import CardPrizeHighlight from './cards/CardPrizeHighlight';
import CardNewCategoryFree from './cards/CardNewCategoryFree';
import CardLinkWeb from './cards/CardLinkWeb';
import CardNewSubcategoryFree from './cards/CardNewSubcategoryFree';
import CardFavoritePrizeAvailable from './cards/CardFavoritePrizeAvailable';
import CardPrizeRedeem from './cards/CardPrizeRedeem';
import CardFriendRedeem from './cards/CardFriendRedeem';
import CardPrizeAvailable from './cards/CardPrizeAvailable';
import CardNewQuestionsFree from './cards/CardNewQuestionsFree';
import CardNewCategoryDuels from './cards/CardNewCategoryDuels';
import CardNewQuestionsDuels from './cards/CardNewQuestionsDuels';
import CardNewCategoryGeneral from './cards/CardNewCategoryGeneral';
import CardNewQuestionsGeneral from './cards/CardNewQuestionsGeneral';
import CardNewTrail from './cards/CardNewTrail';
import CardChampMonth from './cards/CardChampMonth';
import CardChampWeek from './cards/CardChampWeek';
import CardChampDay from './cards/CardChampDay';
import CardRankingUp from './cards/CardRankingUp';
import CardRankingDown from './cards/CardRankingDown';
import CardNewRankingPosition from './cards/CardNewRankingPosition';
import CardNewScorm from './cards/CardNewScorm';
import CardNewExam from './cards/CardNewExam';
import CardNewTraining from './cards/CardNewTraining';
import CardNewAchievement from './cards/CardNewAchievement';
import CardVideo from './cards/CardVideo';
import CardReport from './cards/CardReport';
import CardBirthday from './cards/CardBirthday';
import CardPremium from './cards/CardPremium';
import CardBonusLogin from './cards/CardBonusLogin';
import { Grid } from '@material-ui/core';

export default function FeedCard(props) {
    const { data } = props;

    return (
        <Grid item xs={12}>
            {data.map((item, index) => {
                switch (item && item.feed_type) {
                    case 'SCO_NOVO':
                        return (
                            // --- Novo SCORM ---
                            <CardNewScorm
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                subheader={item.scorm && item.scorm.name ? item.scorm.name : ""}
                                description={item.message}
                                image={typeof item.scorm !== "undefined" ? imageParser(item.scorm.image) : null}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'EXA_NOVO':
                        return (
                            // --- Novo Exame ---
                            <CardNewExam
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                subheader={item.exam && item.exam.name ? item.exam.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'TRE_NOVO':
                        return (
                            // --- Novo Treinamento ---
                            <CardNewTraining
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                day={getDate(item.training.date_start)}
                                month={getMonth(item.training.date_start)}
                                singleClass={diffDate(item.training.date_start, item.training.date_finished) ? true : false}
                                subheader={item.training && item.training.name ? item.training.name : ""}
                                instructor={item.training.name_instructor}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'PER_DIA':
                        return (
                            // --- Pergunta do Dia ---
                            <CardPeriodicQuestion
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                image={typeof item.periodic_question !== "undefined" ? imageParser(item.periodic_question.image) : null}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'PRE_NOVO':
                        return (
                            // --- Prêmio em destaque ---
                            <CardPrizeHighlight
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.prize !== "undefined" ? imageParser(item.prize.image) : null}
                                name={item.prize.name ? item.prize.name : ""}
                                partner={item.prize.partner_name}
                                value={Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(item.prize.value)}
                                premium={item.prize.premium === "Y" ? true : false}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                hasFavorite={item.user_favorite ? true : false}
                                totalFavorite={item.total_favorite}
                                userFavorite={item.user_favorite}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_NOVA_SP':
                        return (
                            // --- Categoria Nova [Livre] ---
                            <CardNewCategoryFree
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'LNK_WEB':
                        return (
                            // --- Link Web ---
                            <CardLinkWeb
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_NOVA_FILHOS_SP':
                        return (
                            // --- Subcategoria Nova [Livre] ---
                            <CardNewSubcategoryFree
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CUR_NOVO':
                        return (
                            // --- Nova Trilha ---
                            <CardNewTrail
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.course !== "undefined" ? imageParser(item.course.image) : null}
                                bgColor={item.course.bgcolor}
                                subheader={item.course.name ? item.course.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RES_AMIGO':
                        return (
                            // --- Prêmio resgatado pelo amigo ---
                            <CardFriendRedeem
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                avatar={item.card_icon}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                premium={item.prize.premium === "Y" ? true : false}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'VID_EXIBIR':
                        return (
                            // --- Vídeo ---
                            <CardVideo
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RAN_CHAMP_MONTH':
                        return (
                            // --- Campeão do Mês ---
                            <CardChampMonth
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RAN_CHAMP_WEEK':
                        return (
                            // --- Campeão da Semana ---
                            <CardChampWeek
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RAN_CHAMP_DAY':
                        return (
                            // --- Campeão do Dia ---
                            <CardChampDay
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'MIS_NOVA':
                        return (
                            // --- Nova Conquista ---
                            <CardNewAchievement
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'AVI_GERAL':
                        return (
                            // --- Avisos ---
                            <CardReport
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_NOVA_MP':
                        return (
                            // --- Categoria Nova [Duelos] ---
                            <CardNewCategoryDuels
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_PERG_SP':
                        return (
                            // --- Novas Perguntas [Livre] ---
                            <CardNewQuestionsFree
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_PERG_MP':
                        return (
                            // --- Novas Perguntas [Duelos] ---
                            <CardNewQuestionsDuels
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'ANI_BONUS':
                        return (
                            // --- Aniversariante ---
                            <CardBirthday
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'ASS_BENEF':
                        return (
                            // --- Premium ---
                            <CardPremium
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'LOG_BONIF':
                        return (
                            // --- Bônus Login ---
                            <CardBonusLogin
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RAN_SUB':
                        return (
                            // --- Passou o amigo no Ranking ---
                            <CardRankingUp
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RAN_DESC':
                        return (
                            // --- Seu amigo o passou no Ranking ---
                            <CardRankingDown
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.image !== "undefined" ? imageParser(item.image) : null}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'RAN_NOVA':
                        return (
                            // --- Nova posição no Ranking ---
                            <CardNewRankingPosition
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_PERG':
                        return (
                            // --- Novas Perguntas [Geral] ---
                            <CardNewQuestionsGeneral
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'CAT_NOVA':
                        return (
                            // --- Nova Categoria [Geral] ---
                            <CardNewCategoryGeneral
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.category !== "undefined" ? imageParser(item.category.image) : null}
                                bgColor={item.category.bgcolor}
                                subheader={item.category.name ? item.category.name : ""}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'PRE_RESG':
                        return (
                            // --- Posso resgatar ---
                            <CardPrizeRedeem
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.prize !== "undefined" ? imageParser(item.prize.image) : null}
                                name={item.prize.name ? item.prize.name : ""}
                                area={item.prize.area}
                                partner={item.prize.partner_name}
                                value={Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(item.prize.value)}
                                premium={item.prize.premium === "Y" ? true : false}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                hasFavorite={item.user_favorite ? true : false}
                                totalFavorite={item.total_favorite}
                                userFavorite={item.user_favorite}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'PRE_FDISP':
                        return (
                            // --- Prêmio Favoritado Disponível ---
                            <CardFavoritePrizeAvailable
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.prize !== "undefined" ? imageParser(item.prize.image) : null}
                                name={item.prize.name ? item.prize.name : ""}
                                area={item.prize.area}
                                partner={item.prize.partner_name}
                                value={Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(item.prize.value)}
                                premium={item.prize.premium === "Y" ? true : false}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                hasFavorite={item.user_favorite ? true : false}
                                totalFavorite={item.total_favorite}
                                userFavorite={item.user_favorite}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'PRE_DISP':
                        return (
                            // --- Prêmio Disponível ---
                            <CardPrizeAvailable
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                image={typeof item.prize !== "undefined" ? imageParser(item.prize.image) : null}
                                name={item.prize.name ? item.prize.name : ""}
                                area={item.prize.area}
                                partner={item.prize.partner_name}
                                value={Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(item.prize.value)}
                                premium={item.prize.premium === "Y" ? true : false}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                hasFavorite={item.user_favorite ? true : false}
                                totalFavorite={item.total_favorite}
                                userFavorite={item.user_favorite}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'BAD_AMIGO':
                        return (
                            // --- Amigo conquistou uma patente ---
                            <CardReport
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    case 'QUI_CONS':
                        return (
                            // --- Acertos consecutivos ---
                            <CardReport
                                key={index}
                                id={item.id_feed}
                                type={item.feed_type}
                                cardType={item.card_type}
                                title={item.feed_title}
                                date={feedDateTime(item.date)}
                                description={item.message}
                                hasLike={item.has_like === "Y" ? true : false}
                                totalLike={item.total_like}
                                userLiked={item.user_liked}
                                hasComment={item.has_comment === "Y" ? true : false}
                                totalComment={item.total_comment}
                                hasShare={item.has_share === "Y" ? true : false}
                                hasEmail={item.has_email === "Y" ? true : false}
                                reference={item.reference}
                                showFooter={true}
                                feed={item}
                                {...props}
                            />
                        )
                    default:
                }
                return null;
            })}
        </Grid>
    )
}