import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
    AppBar, Toolbar, Grid, IconButton, Typography,
    Slide, Dialog, DialogContent, Container
} from '@material-ui/core';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

var HtmlToReactParser = require('html-to-react').Parser;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogTermsPrivacy(props) {
    const { type, open, close } = props;
    const termsOfUse = useSelector(state => state.auth.termsOfUse);
    const privacyPolitics = useSelector(state => state.auth.privacyPolitics);

    function getTerms() {
        var htmlInput = termsOfUse.data;
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement = htmlToReactParser.parse(htmlInput);
        return (reactElement);
    }

    function getPrivacy() {
        var htmlInput = privacyPolitics.data;
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement = htmlToReactParser.parse(htmlInput);
        return (reactElement);
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={close}
                    >
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" component="h4" noWrap>
                            {
                                type === "terms" ?
                                    intl.get("SETTINGS_INFO_TERMS_TITLE")
                                    :
                                    intl.get("SETTINGS_INFO_PRIVACY_POLICIES_TITLE")
                            }
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
            <QrAppBar />
            <DialogContent>
                <Container maxWidth="md" className="pt-3 pb-3">
                    <Grid container justify="center">
                        <Grid item xs={12} className="text-dark">
                            {
                                type === "terms" ?
                                    termsOfUse && termsOfUse.load === "finished" ?
                                        getTerms()
                                        :
                                        <LoadingContent />
                                    :
                                    privacyPolitics && privacyPolitics.load === "finished" ?
                                        getPrivacy()
                                        :
                                        <LoadingContent />
                            }
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    )
}