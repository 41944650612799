
export function addStorage(items) {
    items.map((item) => {
        window.localStorage.setItem(item.name, item.content);
        return null;
    })
}

export function getStorage(name) {
    return window.localStorage.getItem(name);
}
export function deleteStorage(name) {
    return window.localStorage.removeItem(name);
}
