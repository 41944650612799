import React from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import {
    Container, Grid, InputBase, Button
} from '@material-ui/core';
import { Formik } from 'formik';
import GameHeader from './partials/GameHeader';
import { Icon } from '../Images/Images';

const OpenAnswer = ({ children, data, send, onlyView }) => {
    return (
        <div className={`w-100${config.layout.points.neuros && data.help && !onlyView ? " __game-bar" : ""}`}>
            <GameHeader data={data}>
                {children}
            </GameHeader>
            <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10}>
                        <Formik
                            initialValues={{
                                open_answer: data.question.user_answer ? data.question.user_answer : ''
                            }}
                        >
                            {
                                ({ values, handleBlur, handleChange }) => (
                                    <Grid container justify="center" className="answer-type-open-answer" spacing={2}>
                                        <Grid item xs={12}>
                                            <div className="btn-card card __answer">
                                                <InputBase
                                                    fullWidth
                                                    multiline
                                                    id="open_answer"
                                                    name="open_answer"
                                                    placeholder={intl.get("LABEL_ENTER_YOUR_ANSWER")}
                                                    value={values.open_answer}
                                                    inputProps={{ 'aria-label': 'open_answer' }}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <Button
                                                className={`btn-check btn-card __answer${values.open_answer ? ' active' : ''}`}
                                                disabled={!values.open_answer}
                                                variant="outlined"
                                                onClick={() => send(values.open_answer, data.question.id_question)}
                                            >
                                                <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default OpenAnswer;