import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { Formik, useField } from 'formik';
import { userService } from '../../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import {
    Button, TextField, Grid, Typography, Hidden,
    Stepper, Step, StepLabel, FormControl, MenuItem, Select
} from '@material-ui/core';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';

export default function SecretQuestions(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [didMount, setDidMount] = useState(false);
    const [questionValue, setQuestionValue] = useState({ 0: "1", 1: "1", 2: "1", 3: "1", 4: "1", });
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState(null);
    const [, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");
    const [redirectPage, setRedirectPage] = useState(false);
    const [sendCaptchaNotInvisible, setSendCaptchaNotInvisible] = useState(false);
    const [questionsData, setQuestionData] = useState({});
    const [questions, setQuestions] = useState([]);
    const [captcha, setCaptcha] = useState(config.captcha.type === 'invisible' ? true : false);
    const redirect = props.history.location.state && props.history.location.state.redirect ? props.history.location.state.redirect : '';

    useEffect(() => {
        setDidMount(true);

        async function fetchData() {
            try {
                let ret = await userService.getAuthQuestionList({});
                setData(ret);
            } catch (err) {
                setError(true);
            }
        }

        if (didMount)
            fetchData();

        return () => {
            setDidMount(false);
        }
    }, [didMount]);

    // --- Google Captcha ---
    const sendData = async (data) => {
        let answers = [data.answer0, data.answer1, data.answer2, data.answer3, data.answer4]

        for (let i = 0; i < Object.keys(questionValue).length; i++) {
            setQuestions(questions => [...questions, { [questionValue[i]]: answers[i] }])
        }

        if (config.captcha.type === 'invisible') {
            window.grecaptcha.execute();
        } else {
            setSendCaptchaNotInvisible(true)
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        let obj = Object.assign(questions[0], questions[1], questions[2], questions[3], questions[4])

        questionsData.questions = JSON.stringify(obj);
        questionsData.auth_captcha = token;

        if (config.captcha.type === 'invisible') {
            questionsData.token_captcha_invisible = "Y";
        }

        setLoading(true);
        setCaptcha(true);
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (redirect === "N") {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }
    }, [redirect]);

    useEffect(() => {
        async function dispatchData(data) {
            setCaptcha(false);
            setSendCaptchaNotInvisible(false);
            
            await dispatch(AuthActions.setAuthUserAnswerUpdate(data));
        }

        if (sendCaptchaNotInvisible && questions) {
            let obj = Object.assign(questions[0], questions[1], questions[2], questions[3], questions[4])

            setSendCaptchaNotInvisible(false);

            dispatchData({questions: JSON.stringify(obj)})
        }

        if (questionsData.questions && questionsData.auth_captcha && captcha) {
            setCaptcha(false);

            window.grecaptcha.reset();

            dispatchData(questionsData)
        }

        if (loading && auth.questionsStatus === "success") {
            let userAux = localStorage.getItem('auth.state.user') && JSON.parse(localStorage.getItem('auth.state.user'));
            userAux.has_questions = auth.authUserAnswer.data;
            localStorage.setItem('auth.state.user', JSON.stringify(userAux));
            auth.questionsStatus = "";
            setLoading(false);
            setQuestionData({})

            delete questionsData.auth_captcha;

            if (config.captcha.type === 'invisible') {
                delete questionsData.token_captcha_invisible;
            }

            if (auth.authenticated === true && Object.keys(auth.user).length !== 0) {
                if (auth.user.is_temporary_password === "Y") {
                    history.push({ pathname: '/temporary-password', state: { jwt: auth.jwt, redirect: "N" } })
                } else {
                    if (auth.user.is_expired_password === "Y") {
                        history.push({ pathname: '/expired-password', state: { jwt: auth.jwt, redirect: "N" } })
                    } else if (auth.user.pending_data === "Y") {
                        history.push({ pathname: '/complete-data', state: { redirect: "N" } });
                    } else {
                        history.replace('/home')
                    }
                }
            }
        }

        if (loading && auth.questionsStatus === "error") {
            window.grecaptcha.reset();
            delete questionsData.auth_captcha;

            if (config.captcha.type === 'invisible') {
                delete questionsData.token_captcha_invisible;
            }

            auth.questionsStatus = "";
            setLoading(false);
            setSnackStatus("error");
            setSnackOpen(true);
            setSnackMessage(auth.authUserAnswer.messages);
        }
    }, [auth, history, loading, captcha, questionsData, questions, sendCaptchaNotInvisible, dispatch]);

    const handleNext = () => {
        const index = data.questions.filter(question => question.id_question !== questionValue[activeStep]);

        setData({ ...data, questions: index })
        setQuestionValue({ ...questionValue, [activeStep + 1]: index[0].id_question })
        setActiveStep((step) => step + 1)
    };

    const QuestionContent = (props) => {
        const { index, inputName } = props;
        const [field] = useField(inputName);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl className="w-100">
                        <Select
                            value={questionValue[index]}
                            onChange={(e) => setQuestionValue({ ...questionValue, [index.toString()]: e.target.value })}
                            variant={config.layout.input_variant}
                        >
                            {
                                data.questions.map((item) => {
                                    return <MenuItem key={item.id_question} value={item.id_question}>{item.question}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name={inputName}
                        type="text"
                        label={intl.get('LABEL_YOUR_ANSWER')}
                        fullWidth
                        required
                        variant={config.layout.input_variant}
                        onChange={field.onChange(inputName)}
                        value={field.value}
                    />
                </Grid>
            </Grid>
        )
    }

    const StepContent = (props) => {
        const { step } = props

        switch (step) {
            case 0:
                return <QuestionContent index={0} inputName="answer0" />
            case 1:
                return <QuestionContent index={1} inputName="answer1" />
            case 2:
                return <QuestionContent index={2} inputName="answer2" />
            case 3:
                return <QuestionContent index={3} inputName="answer3" />
            case 4:
                return <QuestionContent index={4} inputName="answer4" />
            default:
                return;
        }
    }

    return (
        !redirectPage ?
            <IntroLayout title={intl.get('INTRO_SECRET_QUESTIONS_TITLE')} appbarMobile="default" backButton={false}>

                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get('INTRO_SECRET_QUESTIONS_TITLE')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="p" variant="body2">
                                {intl.get("INTRO_SECRET_QUESTIONS_TEXT")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={{
                                    answer0: '',
                                    answer1: '',
                                    answer2: '',
                                    answer3: '',
                                    answer4: '',
                                }}
                                onSubmit={(data) => sendData(data)}
                            >
                                {({ handleSubmit }) => (
                                    <div className="w-100">
                                        <Stepper activeStep={activeStep} alternativeLabel>
                                            {
                                                Object.keys(questionValue).map((index) => (
                                                    <Step key={index}>
                                                        <StepLabel></StepLabel>
                                                    </Step>
                                                ))
                                            }
                                        </Stepper>
                                        <Grid container justify="center" spacing={2}>
                                            {
                                                data ?
                                                    <>
                                                        <Grid item xs={12}>
                                                            <StepContent step={activeStep} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={8}>
                                                            {
                                                                !loading ?
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className="btn-block"
                                                                        onClick={activeStep === Object.keys(questionValue).length - 1 ? handleSubmit : handleNext}
                                                                    >
                                                                        {intl.get(activeStep === Object.keys(questionValue).length - 1 ? "BTN_CONFIRM" : "BTN_NEXT")}
                                                                    </Button>
                                                                    :
                                                                    <Grid container alignItems="center" justify="center">
                                                                        <LoadingBounce />
                                                                    </Grid>
                                                            }
                                                        </Grid>
                                                    </>
                                                    :
                                                    <Grid item xs={12}>
                                                        <Grid container alignItems="center" justify="center">
                                                            <LoadingBounce />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </div>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Captcha */}
                <Grid container justify="center">
                    {config.captcha.type === 'invisible'
                        ? <div className="g-recaptcha"
                            data-sitekey={config.captcha.invisible_key}
                            data-callback="myCallback"
                            data-size="invisible">
                        </div>
                        : <div className="g-recaptcha mt-3"
                            data-sitekey={config.captcha.key}
                            data-callback="myCallback"
                        >
                        </div>
                    }
                </Grid>

                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}