import React from 'react';
import { feedDateTime } from '../../../utils/utils';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@material-ui/core'
import { Icon } from '../../../components/Images/Images';

export default function FileItem(props) {
    const { file, bgColor, title, description, date, type, openDialog } = props;

    return (
        <ListItem button onClick={() => openDialog(file)}>
            <ListItemAvatar>
                <Avatar alt={title} style={{ backgroundColor: bgColor }}>
                    <Icon ic={`file_${type && type.toLowerCase()}`} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="body1" component="h5">
                        {title}
                    </Typography>
                }
                secondary={
                    <div>
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>
                        <Typography variant="caption" component="p">
                            {feedDateTime(date)}
                        </Typography>
                    </div>
                }
            />
        </ListItem>
    )
}