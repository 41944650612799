import * as React from 'react';
import config from '../../../config';
import AssaiCharCourseIntroFlashcards from '../projects/assai/chars/CharCourseIntroFlashcards';

function CharCourseIntroFlashcards(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={192} height={124} viewBox="0 0 192 124" {...props}>
                <g transform="translate(6 3)" fill="none" fillRule="evenodd">
                    <path
                        d="M91.26 96c3.404-.732 7.448-1.735 10.933-2.599l14.925-5.1C125.108 86.15 125 81 127 74.585c1.86-9.385-.608-12.474-7.402-9.267 1.902-4.818-5.598-4.818-6.424.537L91 74.585"
                        fill="#FDC436"
                    />
                    <path
                        d="M28.593 97.693L30.9 114h65.6l2.524-18.662L108 92.824 100.45 70l-9.342 3.09s-26.223 8.525-34.887 9.99C46.626 84.703 19 84.648 19 84.648l9.593 13.045z"
                        fill="#46CA64"
                    />
                    <path
                        d="M12.755 118.989c3.312-.389 4.672.33 8.073-.179l17.767-3.013C46.405 114.472 50.029 105.65 49 99.5c-.166-6.12-3.634-7.953-10.405-5.5 3.464-10.851-9.389-4.618-6.095 4L11 102.317l1.755 16.672z"
                        fillOpacity={0.15}
                        fill="#000"
                    />
                    <path
                        d="M19.337 99.971l12.497-3.074c-2.805-8.696 9.836-12.464 5.806-1.932 10.285-3.078 9.86-.66 10.217 8.73l.088.643c.688 6.136-5.104 11.82-12.931 12.7l-15.924 3.817c-3.405.314-20.013 1.59-19.05-16.454C.66 92.788 20.322 85 20.322 85l7.36 3.508 1.033 9.156-9.378 2.307z"
                        fill="#FDC436"
                    />
                    <path
                        d="M18.5 100L29 97.5l-.637-10.877-6.1-2.623s-9.175 2.543-14.225 6.956c-8.463 7.395-7.93 12.12-7.93 12.12L18.5 100z"
                        fill="#3EC96A"
                    />
                    <path
                        d="M16 53.446s4.942 23.807 4.93 23.92c.935 3.894 6.301 12.087 17.508 11.614 13.384-.564 42.213-4.867 42.213-4.867s11.98-3.744 10.161-16.746C90.892 67.58 85.888 43 85.888 43L16 53.446"
                        fillOpacity={0.15}
                        fill="#000"
                    />
                    <path
                        d="M9 51.513s4.41 24.2 4.39 24.318c.867 3.951 6.075 14.15 19.013 11.83l48.54-8.706s15.354-3.91 13.969-17.176C94.99 61.993 90.554 37 90.554 37L9 51.513"
                        fill="#FDC436"
                    />
                    <path
                        d="M75.745 66.74c-6.227 1.306-12.282-2.444-13.521-8.377-1.24-5.933 2.803-11.798 9.031-13.103 6.227-1.307 12.282 2.444 13.521 8.376 1.24 5.931-2.803 11.8-9.031 13.105"
                        fill="#212321"
                    />
                    <path
                        d="M74.247 47.46c-.694 0-1.396.073-2.093.22-5.16 1.08-8.532 5.916-7.513 10.782 1.018 4.864 6.035 7.938 11.206 6.863 5.16-1.082 8.531-5.919 7.512-10.784-.49-2.34-1.914-4.343-4.009-5.64a9.663 9.663 0 00-5.103-1.44zm1.954 19.558h.01-.01zM73.77 69c-5.973 0-11.294-3.992-12.516-9.83-1.41-6.732 3.162-13.406 10.19-14.878 3.388-.709 6.842-.117 9.724 1.666 2.91 1.8 4.89 4.597 5.576 7.874 1.408 6.733-3.164 13.408-10.19 14.879-.935.196-1.867.289-2.784.289z"
                        fill="#212321"
                    />
                    <path
                        d="M77.171 56.87c-3.248.653-6.407-1.224-7.055-4.188-.646-2.966 1.464-5.9 4.713-6.552 3.25-.653 6.409 1.223 7.055 4.188.646 2.965-1.463 5.9-4.713 6.553"
                        fill="#FFF"
                    />
                    <path
                        d="M56.995 4.233c3.937-.27 2.257-.273 5.04-.17 15.098 1.094 25.96 17.267 28.222 31.38L91 40.08c.168.07-81.705 14.063-81.705 13.92l-.787-4.92c-2.264-14.114 2.981-32.973 16.95-38.916C29.48 8.45 34.422 7.72 38.707 8.667c2.323.513 4.262 1.54 5.77 3 .974-1.873 2.49-3.477 4.531-4.718 2.75-1.705 4.92-2.065 7.987-2.716"
                        fill="#212321"
                    />
                    <path
                        d="M86.951 37.186L52.39 43l-3.76-23.661c-.622-3.907.866-6.41 3.468-8.007 2.987-1.831 7.04-2.533 10.482-2.283 2.843.208 5.511 1.08 7.969 2.45-.854 2.262-2.86 3.397-5.295 3.252-1.43-.086-2.754-.292-4.203-.17-4.733.405-8.619 4.359-9.078 9.223-.23 2.11 1.038 3.545 2.254 3.625 1.336.09 2.565-.716 2.644-2.759.222-4.8 4.52-4.994 8.094-4.781 4.211.253 7.91-1.8 9.765-5.434a31.636 31.636 0 013.77 3.91c-.995.98-1.808 2.217-2.383 3.69-.539 1.385-.836 2.882-1.409 4.233-1.088 2.588-3.435 3.61-6.055 3.014-1.399-.317-2.674-.737-4.124-.852-4.737-.374-9.182 2.896-10.385 7.624-.554 2.044.478 3.67 1.666 3.949 1.306.304 2.645-.29 3.035-2.295.96-4.702 5.234-4.194 8.728-3.399 4.954 1.128 9.69-1.158 11.73-5.992.604-1.437.904-2.94 1.46-4.37.2-.513.446-.963.731-1.346 2.805 4.607 4.7 9.792 5.458 14.565M13.503 49l33.973-5.775-3.696-23.499c-.611-3.88-2.76-5.753-5.672-6.41-3.336-.753-7.329-.094-10.46 1.263-2.586 1.12-4.807 2.81-6.68 4.899 1.486 1.851 3.699 2.269 5.923 1.34 1.306-.547 2.477-1.172 3.865-1.528 4.534-1.16 9.359 1.299 11.266 5.728.858 1.912.112 3.675-.996 4.148-1.218.518-2.61.159-3.302-1.74-1.668-4.445-5.734-3.229-8.998-1.866-3.847 1.61-7.918.88-10.752-1.937a32.142 32.142 0 00-2.324 4.908c1.223.598 2.361 1.497 3.343 2.697.924 1.13 1.658 2.441 2.6 3.528 1.805 2.08 4.302 2.28 6.56.866 1.206-.754 2.267-1.565 3.585-2.143 4.297-1.895 9.437-.261 11.996 3.797 1.138 1.744.672 3.611-.352 4.26-1.122.712-2.551.587-3.524-1.173-2.327-4.114-6.154-2.245-9.167-.358-4.272 2.672-9.381 2.062-12.753-1.826-1.002-1.156-1.737-2.472-2.692-3.637a5.44 5.44 0 00-1.092-1.03c-1.21 5.25-1.397 10.749-.651 15.488"
                        fill="#FFF"
                    />
                    <path
                        d="M46.472 66c-.253 0-.51-.065-.746-.203-5.025-2.928-8.732-3.305-15.86-1.619-.815.196-1.64-.34-1.828-1.192-.187-.853.328-1.702 1.15-1.896 7.909-1.874 12.291-1.399 18.032 1.945.736.428.997 1.394.584 2.157-.28.518-.799.808-1.332.808m6.853 13c-4.892 0-9.935-1.64-11.974-2.843-1.473-.87-1.479-1.725-1.225-2.29.318-.703 1.234-1.046 2.035-.774.425.145.736.432.89.776 1.848 1.247 11.936 4.297 16.307.743.632-.516 1.627-.485 2.219.066.593.55.558 1.416-.074 1.93C59.339 78.37 56.36 79 53.325 79z"
                        fill="#212321"
                    />
                    <path fillOpacity={0.15} fill="#000" d="M27 98v-3l-6.256 4z" />
                    <ellipse
                        fill="#000"
                        opacity={0.15}
                        transform="rotate(10 120.628 67)"
                        cx={120.628}
                        cy={67}
                        rx={3}
                        ry={1}
                    />
                    <path fill="#FFF" d="M132.334 0L134 .843 122.75 67l-3.75-.452z" />
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCourseIntroFlashcards {...props} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (gender === "male") {
            return (
                <svg width={192} height={124} viewBox="0 0 192 124" {...props}>
                    <g fill="none" fillRule="evenodd">
                        <path
                            d="M43.552 92.264s-14.461 2.616-15.076 15.539c-.309 6.475 5.384 11.23 5.384 11.23l4.154-5.846s-2.967-3.025-2.308-7.077c1.077-6.615 8.194-7.452 8.194-7.452l-.348-6.394z"
                            className={`svg-char-shirt`}
                        />
                        <path
                            d="M70.18 92.368l2.024 6.709s31.574 12.792 40.156-17.325l-9.39-1.944s-4.296 21.33-32.79 12.56"
                            className="svg-char-shirt-shade-1"
                        />
                        <path
                            d="M99.569 75.68c.243 6.314 3.4 8.5 8.743 9.229 4.786.653 9.284-3.66 9.473-8.015.243-5.586-4.422-7.315-9.23-7.772-5.1-.486-9.183 1.455-8.986 6.558"
                            className={`svg-char-${color}-skin`}
                        />
                        <path fill="#FFF" d="M115.334 3l1.666.843-9.369 70.714L104.228 73z" />
                        <path
                            d="M107.098 75.956c.355-2.009-2.817-2.92-4.434-3.252.167-.142.346-.278.548-.4 0 0 6.228 1.048 5.344 4.29-1.215 4.453-5.83 2.51-7.287 1.514 0 0-.028-.12-.06-.317 2.085.792 5.32 1.382 5.89-1.835"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M74.858 117H39.81c-.115-4.675.825-12.818 2.818-24.428 0 0 6.278 2 14.923 2 8.647 0 13.231-2 13.231-2s2.992 10.63 4.075 24.428z"
                            className="svg-char-shirt"
                        />
                        <path
                            d="M57.11 101.542c.753-1.629 2.748-1.643 3.498-.006 1.198 2.616 4.406 11.145 4.29 11.788-.22 1.219-4.271 5.184-5.733 4.97-1.682-.246-5.98-4.185-5.928-4.984.13-2.006 2.5-8.795 3.873-11.768zm1.578-7.652a2.55 2.55 0 11.099 5.1 2.55 2.55 0 01-.1-5.1z"
                            className="svg-char-necktie"
                        />
                        <path
                            d="M37.655 112.52s-3.18 2.052-3.949 6.36c0 0 5.598 5.781 8.797 1.143 2.358-3.422-1.616-7.388-4.848-7.503"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M37.655 112.52s1.847-2.655 3.232-1.038c1.385 1.615-2.308 2.769-2.308 2.769l-.924-1.731z"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M70.439 50.69c.515 9.017 6.243 12.802 13.431 12.391 7.189-.41 10.99-13.973 10.92-19.918-.114-9.675-5.767-16.219-12.956-15.808-7.188.41-11.91 14.318-11.395 23.335"
                            className={`svg-char-${color}-hair-shade-2`}
                        />
                        <path
                            d="M21.463 81.99c3.582 5.075 9.607 5.768 12.94 4.263 3.318-1.503 1.183-5.063-.893-9.704-2.073-4.642-6.454-7.187-9.78-5.682-3.326 1.504-5.223 6.935-2.267 11.123"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M24.887 80.815c1.25 2.6 5.415 3.657 7.514 2.935 2.102-.723 1.041-2.828.09-5.55-.947-2.722-3.422-4.34-5.522-3.618-2.1.724-3.542 3.2-2.082 6.233"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M95.482 65.8c-.92 4.405-4.5 7.715-7.294 7.707-2.783-.011-2.584-3.47-2.806-7.739-.223-4.267 1.86-7.72 4.65-7.71 2.788.01 6.317 3.605 5.45 7.741"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M92.703 65.186c.311 2.412-2.355 5.549-4.035 5.716-1.683.169-1.702-1.807-2.012-4.219-.314-2.41.799-4.5 2.48-4.666 1.682-.168 3.257.76 3.567 3.169"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M72.701 91.884S98.43 80.78 87.625 45.49C76.821 10.197 52.907 13.572 41.078 17.512c-11.828 3.94-22.511 13.84-21.683 24.843 2.867 38.094 18.087 48.359 26.372 51.385 4.537 1.657 16.565 3.19 26.934-1.856"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M71.735 63.25c.215 3.757 2.49 6.684 5.082 6.536 2.592-.148 4.52-3.315 4.305-7.073-.215-3.759-2.49-6.685-5.082-6.537-2.593.148-4.52 3.314-4.305 7.073"
                            className={`svg-char-${color}-eyes`}
                        />
                        <path
                            d="M72.506 60.518c.076 1.338.885 2.378 1.808 2.325.922-.052 1.607-1.178 1.53-2.516-.075-1.336-.885-2.378-1.807-2.325-.922.053-1.608 1.18-1.531 2.516"
                            fill="#FFF"
                        />
                        <path
                            d="M39.947 63.758l.051.005a1.137 1.137 0 001.233-1.033c.205-2.324 2.176-4.013 4.791-4.108 1.999-.073 4.068 1.803 4.74 3.601a1.138 1.138 0 002.131-.797c-.966-2.585-3.832-5.19-6.954-5.077-3.796.138-6.664 2.68-6.974 6.181a1.137 1.137 0 00.982 1.228"
                            className={`svg-char-${color}-eyes`}
                        />
                        <path
                            d="M59.998 68.83a3.51 3.51 0 107.007-.4 3.51 3.51 0 00-7.007.4"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M69.71 79.157s-18-2.99-29.599-3.834c0 0-.506 10.795 11.785 12.887 15.468 2.632 17.814-9.053 17.814-9.053"
                            className="svg-char-mouth"
                        />
                        <path
                            d="M51.896 88.21c-4.847-.825-7.699-3.005-9.381-5.34 2.442-1.115 5.864-1.674 9.6-1.397 5.613.418 10.245 2.599 11.8 5.269-2.666 1.522-6.506 2.407-12.019 1.467"
                            className="svg-char-mouth-tongue"
                        />
                        <path
                            d="M25.03 70.428S36.628 58.1 28.747 42.643c0 0 3.437-2.367 19.297-.042 15.613 2.29 39.144 3.426 49.463-8.442 7.761-8.927-1.103-17.434-8.932-11.578 0 0 4.59-1.323 3.086-9.084-1.666-8.603-13.722-10.033-19.789-3.005 0 0 3.765-3.332.46-6.813-3.724-3.923-10.742-.554-12.394 5.797 0 0-27.103-1.103-39.902 16.17-9.538 12.872-7.696 28.647 4.996 44.782"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M54.107 41.092c11.146 1.21 26.716-.152 36.259-3.878 7.724-3.016 9.572-9.17 9.996-11.405.486 2.38-.24 5.34-2.856 8.35-10.32 11.868-33.85 10.732-49.463 8.443-15.86-2.326-19.297.041-19.297.041 5.684-4.457 19.599-2.177 25.36-1.551M24.047 56.909c5.931-.126 7.393-4.24 7.393-4.24s.017.985-.004 2.557c-.624 9.055-6.405 15.202-6.405 15.202-5.012-6.372-8.324-12.686-9.92-18.789 1.73 2.487 4.72 5.36 8.936 5.27"
                            className={`svg-char-${color}-hair-shade-1`}
                        />
                        <path
                            d="M78.99 9.03c-.136.933 1.247 1.905 3.087 2.172 1.841.268 3.444-.271 3.58-1.204.133-.932-1.248-1.904-3.09-2.172-1.84-.267-3.441.272-3.577 1.204"
                            fillOpacity={0.1}
                            fill="#FFF"
                        />
                    </g>
                </svg>
            )
        } else {
            return (
                <svg width={192} height={124} viewBox="0 0 192 124" {...props}>
                    <g fill="none" fillRule="evenodd">
                        <path
                            d="M49.569 94.12s-13.497 3.252-13.55 15.78c-.033 7.437 4.981 9.797 4.981 9.797l3.57-6.477s-2.136-2.424-1.75-6.159c.59-5.683 7.22-8.09 7.22-8.09l-.471-4.851zm20.109.797l1.883 6.307s26.632 9.847 37.367-16.288l-8.739-1.827s-6.527 19.846-30.511 11.808"
                            className="svg-char-shirt"
                        />
                        <path
                            d="M97.025 79.228c.226 5.937 3.164 7.992 8.137 8.676 4.452.614 8.638-3.44 8.814-7.535.226-5.25-4.115-6.876-8.588-7.306-4.746-.457-8.546 1.368-8.363 6.165"
                            className={`svg-char-${color}-skin`}
                        />
                        <path fill="#FFF" d="M113.106 6l1.666.843-9.304 71.999-3.458-2.258z" />
                        <path
                            d="M104.032 79.487c.33-1.888-2.622-2.745-4.126-3.056a3.87 3.87 0 01.51-.377s5.795.986 4.972 4.034c-1.13 4.186-5.424 2.36-6.781 1.423 0 0-.025-.113-.055-.298 1.94.744 4.95 1.3 5.48-1.726"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M73.94 114.658s-3.08 1.893-13.373 1.893c-5.612 0-12.031-1.09-15.203-2.302 0 0 1.336 2.65 2.16-1.513.843-4.257-.563-10.068.562-18.117 0 0 3.35.589 10.484.589 7.132 0 12.259-.59 12.259-.59s-.166 5.896 3.111 20.04z"
                            className="svg-char-shirt"
                        />
                        <path
                            d="M47.524 112.736c.245-1.526-1.339-9.364-.412-17.791 0 0 3.515.212 9.681.312 1.097 4.806 3.312 8.742 4.531 8.948 1.236.208 2.471-1.769 3.708-8.948 2.944-.163 5.149-1.249 5.149-1.249s2.677 4.578 3.296 6.867c.489 1.812-.824 6.242-.619 7.074.702 2.833 1.176 4.596 2 6.886.637 1.773-3.55 2.165-14.782 2.165-6.124 0-9.257.133-12.552-2.165-.275.311-.275-.389 0-2.1z"
                            className="svg-char-suit"
                        />
                        <path
                            d="M44.423 113.004s-3.085 1.823-3.423 6.693c0 0 6.034 5.013 8.868.246 2.09-3.516-2.202-7.076-5.445-6.94"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M44.265 113.625s1.638-2.832 3.153-1.308c1.514 1.523-2.091 2.985-2.091 2.985l-1.062-1.677z"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M14 22.161c0 9.769 8.069 17.687 18.022 17.687 9.953 0 18.022-7.918 18.022-17.687 0-9.768-8.069-17.687-18.022-17.687C22.07 4.474 14 12.393 14 22.161"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M20.211 37.672c4.442 7.771 15.788 9.553 25.343 3.98 9.556-5.573 13.701-16.392 9.26-24.163-4.441-7.772-15.787-9.553-25.343-3.98-9.555 5.574-13.7 16.391-9.26 24.163"
                            className="svg-char-ribbon"
                        />
                        <path
                            d="M14 22.161c0-3.574 1.084-6.898 2.942-9.68-.266 2.142-.483 7.497 3.237 10.929 0 0-.618 2.496 2.472 3.745l-3.467 7.416C15.98 31.378 14 26.997 14 22.161"
                            className={`svg-char-${color}-hair-shade-1`}
                        />
                        <path
                            d="M90.825 69.274s6.798-11.548 4.635-29.339C93.297 22.144 78.463 10.415 59.004 9.66c-10.921-.424-21.208 3.02-28.77 9.08-9.125 7.315-13.791 18.353-11.806 31.184 2.884 18.64 10.608 28.403 14.933 31.525 8.917 6.434 42.326 15.918 57.464-12.174"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M96.477 70.865c-1.138 4.27-4.799 7.318-7.511 7.154-2.702-.168-2.318-3.55-2.296-7.75.02-4.2 2.233-7.471 4.943-7.305 2.707.166 5.935 3.891 4.864 7.901"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M93.811 70.107c.17 2.384-2.593 5.312-4.235 5.383-1.643.07-1.551-1.87-1.72-4.252-.17-2.383 1.026-4.37 2.668-4.44 1.642-.07 3.12.928 3.287 3.309"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M23.771 82.266c3.197 5.179 9.01 6.196 12.33 4.907 3.307-1.29 1.43-4.902-.328-9.572-1.757-4.67-5.87-7.413-9.184-6.123-3.313 1.29-5.456 6.512-2.818 10.788"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M27.162 81.304c1.07 2.62 5.056 3.89 7.135 3.3 2.08-.59 1.168-2.716.395-5.44-.77-2.724-3.083-4.45-5.163-3.858-2.079.592-3.617 2.941-2.367 5.998"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M72.906 95.18s25.603-9.45 17.068-44.682C81.438 15.265 58.026 17.237 46.32 20.44 34.614 23.642 23.69 32.758 23.884 43.6c.672 37.537 14.883 48.46 22.762 51.894 4.314 1.88 15.91 4.058 26.26-.312"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M72.902 63.35c0 3.699 2.046 6.699 4.573 6.699 2.525 0 4.572-3 4.572-6.7 0-3.699-2.047-6.697-4.572-6.697-2.527 0-4.573 2.998-4.573 6.698"
                            className={`svg-char-${color}-eyes`}
                        />
                        <path
                            d="M73.906 60.13c0 1.314.728 2.382 1.626 2.382.899 0 1.627-1.068 1.627-2.383 0-1.317-.728-2.383-1.627-2.383-.898 0-1.626 1.066-1.626 2.383"
                            fill="#FFF"
                        />
                        <path
                            d="M61.848 71.85c0 1.906 1.528 3.448 3.413 3.448 1.886 0 3.415-1.542 3.415-3.448 0-1.905-1.529-3.45-3.415-3.45-1.885 0-3.413 1.545-3.413 3.45"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M70.707 82.526s-17.315-3.942-28.533-5.42c0 0-1.09 10.564 10.73 13.305 14.877 3.449 17.803-7.885 17.803-7.885"
                            className="svg-char-mouth"
                        />
                        <path
                            d="M52.905 90.41c-4.662-1.08-7.31-3.378-8.815-5.763 2.433-.959 5.788-1.315 9.4-.833 5.43.724 9.807 3.123 11.17 5.83-2.676 1.344-6.453 1.997-11.755.767"
                            className="svg-char-mouth-tongue"
                        />
                        <path
                            d="M22.548 38.375c2.471-20.601 29.167-26.648 46.342-20.601C80.318 21.797 84.758 28.174 86.455 33c8.896 5.19 6.224 19.332 6.224 19.332C80.683 47.05 73.97 38.52 71.34 34.536l-.014.02c-3.938 5.477-15.44 18.43-36.831 19.672-.43 13.245-6.81 16.99-6.81 16.99s-7.608-12.244-5.136-32.843z"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M25.92 72.413c-2.677-4.37-5.638-11.574-7.186-20.649.066-.335.106-.523.106-.523s1.416 9.676 15.01 8.74c0 0-1.365 9.077-7.93 12.432"
                            className={`svg-char-${color}-hair-shade-1`}
                        />
                        <path
                            d="M53.016 66.757a1.135 1.135 0 01-1.226-.872c-.565-2.394-2.461-3.708-4.945-3.431-2.215.248-3.91 1.88-4.536 4.367a1.133 1.133 0 01-1.377.829 1.145 1.145 0 01-.82-1.392c.867-3.45 3.29-5.723 6.484-6.08 3.599-.402 6.572 1.678 7.399 5.175a1.146 1.146 0 01-.98 1.404"
                            className={`svg-char-${color}-eyes`}
                        />
                        <path
                            d="M71.275 33.362a.462.462 0 01.636-.157.471.471 0 01.156.644c-.145.242-3.645 5.976-11.642 11.535-6.765 4.703-18.043 10.044-34.368 10.044a77.6 77.6 0 01-4.463-.13.467.467 0 01-.436-.494.464.464 0 01.49-.441c18.457 1.065 30.97-4.699 38.211-9.722 7.85-5.446 11.38-11.22 11.416-11.279zm4.923-1.214a.462.462 0 01.623.205c.055.11 5.64 11.001 17.343 18.545a.471.471 0 01.142.647.462.462 0 01-.64.143c-11.915-7.68-17.615-18.799-17.671-18.91a.471.471 0 01.203-.63zm2.864-6.5a.46.46 0 01.584.3c.037.116 3.862 11.76 14.25 21.063.192.172.21.468.04.662a.459.459 0 01-.654.039c-10.576-9.472-14.48-21.356-14.517-21.475a.469.469 0 01.297-.59zm-4.37-.118a.46.46 0 01.648-.107c.207.15.255.443.106.653-.15.21-3.764 5.212-12.418 10.123-7.504 4.258-20.29 9.166-39.351 9.166-1.147 0-2.319-.018-3.512-.055a.467.467 0 01-.45-.482.466.466 0 01.478-.453c20.76.64 34.479-4.52 42.326-8.961 8.497-4.81 12.138-9.834 12.174-9.884zm-47.857-1.558c23.325-10.72 45.99-3.37 46.217-3.296a.47.47 0 01.295.592.462.462 0 01-.586.298c-.222-.074-22.559-7.305-45.542 3.258a.463.463 0 01-.614-.232.47.47 0 01.23-.62zm49.287-6.745c.553-.844 2.337-.637 3.989.464 1.65 1.1 2.54 2.677 1.99 3.523-.552.843-2.338.636-3.988-.465-1.652-1.1-2.543-2.677-1.99-3.522z"
                            fillOpacity={0.1}
                            fill="#FFF"
                        />
                    </g>
                </svg>
            )
        }
    }
}

export default CharCourseIntroFlashcards
