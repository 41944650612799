import { RankingService } from '../../services/ranking.service';

// CONST
export const Types = {
    RANKING: 'ranking/RANKING',
    LOADMORE: 'ranking/LOADMORE',
    PAGE: 'ranking/PAGE',
    RESET: 'ranking/RESET',
};

// INITIAL STATE
const initialState = {
    ranking: {},
    page: 1,
    limit: 10,
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.RANKING:
            return {
                ...state,
                ranking: {
                    data: action.payload.data,
                    me: action.payload.me,
                    total: action.payload.total,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LOADMORE:
            return {
                ...state,
                ranking: {
                    data: [...state.ranking.data, ...action.payload.data],
                    me: action.payload.me,
                    total: action.payload.total,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.PAGE:
            return {
                ...state,
                page: action.payload.page
            }
        case Types.RESET:
            return {
                ...state,
                ranking: {},
                page: 1,
            }
        default:
            return state
    }
}

// ACTIONS
function getRanking(params) {
    return dispatch => {
        return RankingService.getRanking(params).then(data => {
            dispatch({
                type: Types.RANKING,
                payload: {
                    data: data.ranking,
                    me: data.me,
                    total: data.total,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.RANKING,
                payload: {
                    data: [],
                    me: {},
                    total: "",
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getLoadMore(params) {
    return dispatch => {
        return RankingService.getRanking(params).then(data => {
            dispatch({
                type: Types.LOADMORE,
                payload: {
                    data: data.ranking,
                    me: data.me,
                    total: data.total,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LOADMORE,
                payload: {
                    data: [],
                    me: {},
                    total: "",
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setPage(params) {
    return dispatch => {
        dispatch({
            type: Types.PAGE,
            payload: {
                page: params.page
            }
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getRanking,
    getLoadMore,
    setPage,
    reset
}
