import * as React from "react"

function ImgFeed1(props) {
    return (
        <svg width={132} height={44} viewBox="0 0 132 44" {...props}>
            <g transform="translate(4 4)" fill="none" fillRule="evenodd">
                <rect fill="#FFF" width={124} height={36} rx={10} />
                <ellipse
                    className="svg-feed-circle"
                    fillRule="nonzero"
                    cx={18.844}
                    cy={17.967}
                    rx={10.585}
                    ry={10.5}
                />
                <path
                    d="M19 12c-1.72 0-3.125 1.373-3.125 3.056 0 1.682 1.405 3.055 3.125 3.055s3.125-1.373 3.125-3.055C22.125 13.373 20.72 12 19 12zm-3.594 7.333c-.77 0-1.406.621-1.406 1.375v.367c0 .898.583 1.704 1.472 2.259.888.554 2.105.888 3.528.888s2.64-.334 3.528-.888c.89-.555 1.472-1.36 1.472-2.26v-.366c0-.754-.635-1.375-1.406-1.375h-7.188z"
                    fill="#FFF"
                />
                <rect
                    className="svg-feed-text"
                    x={36.636}
                    y={10.814}
                    width={60.488}
                    height={4.5}
                    rx={2.25}
                />
                <rect
                    className="svg-feed-text-light"
                    x={36.746}
                    y={21.456}
                    width={48.39}
                    height={4.5}
                    rx={2.25}
                />
            </g>
        </svg>
    )
}

export default ImgFeed1
