import * as React from "react"

function ImgSwordLeft(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <g fill="none">
                <path
                    fill="#DCDCDC"
                    d="M42.778 43.5L16.13 17.804l-2.284 2.203L33.642 42.03z"
                />
                <path
                    fill="#C3C3C3"
                    d="M42.778 43.5L16.13 17.804l2.284-2.202 22.84 19.088z"
                />
                <path
                    d="M5.091 12.297l5.33-5.139c.593-.573.628-1.48.104-2.091l-.105-.111a1.659 1.659 0 00-2.284 0l-5.329 5.138a1.516 1.516 0 00-.105 2.092l.105.11a1.657 1.657 0 002.284 0zm6.851 9.546l8.375-8.076c.594-.573.63-1.48.105-2.092l-.105-.11a1.657 1.657 0 00-2.284 0L9.658 19.64a1.516 1.516 0 00-.105 2.092l.105.11a1.657 1.657 0 002.284 0z"
                    fill="#F7B27B"
                />
                <path
                    className="svg-sword-handle"
                    d="M11.562 17.804l2.284-2.202-6.091-5.873-2.284 2.202z"
                />
                <path
                    className="svg-sword-handle-dark"
                    d="M13.847 15.602l2.284-2.203-6.091-5.873-2.284 2.203z"
                />
            </g>
        </svg>
    )
}

export default ImgSwordLeft
