import React from 'react';
import config from '../../../../config'
import CourseSlide from './Default/TrailSlide';
import DuelsSlide from './Default/DuelsSlide';
import FeedsSlide from './Default/FeedsSlide';
import OnboardingSlide from './Default/OnboardingSlide';
import PrizesSlide from './Default/PrizesSlide';
import RankingSlide from './Default/RankingSlide';
import ScormSlide from './Default/ScormSlide';
import Slide1 from './Assai/Slide1';
import Slide2 from './Assai/Slide2';
import Slide3 from './Assai/Slide3';
import Slide4 from './Assai/Slide4';

export default function Intros({ introName }) {
    if (config.app.name_project === "ASSAI") {
        switch (introName) {
            case 'Slide1':
                return (
                    <Slide1 />
                )
            case 'Slide2':
                return (
                    <Slide2 />
                )
            case 'Slide3':
                return (
                    <Slide3 />
                )
            case 'Slide4':
                return (
                    <Slide4 />
                )
            default:
                return (
                    null
                )
        }
    } else {
        switch (introName) {
            case 'Course':
                return (
                    <CourseSlide />
                )
            case 'Duel':
                return (
                    <DuelsSlide />
                )
            case 'Feeds':
                return (
                    <FeedsSlide />
                )
            case 'Onboarding':
                return (
                    <OnboardingSlide />
                )
            case 'Prizes':
                return (
                    <PrizesSlide />
                )
            case 'Ranking':
                return (
                    <RankingSlide />
                )
            case 'Scorm':
                return (
                    <ScormSlide />
                )
            default:
                return (
                    null
                )
        }
    }

}
