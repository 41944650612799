import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#2B97DD" d="M0 0h512v512H0z" />
                <g transform="translate(43.2 76.8)">
                    <ellipse
                        fill="#78BCE9"
                        cx={329.564}
                        cy={42.981}
                        rx={24.873}
                        ry={24.561}
                    />
                    <path
                        fill="#E9F4FB"
                        d="M93.273 128.944h236.291l24.872 21.491v116.664l-15.545 24.56H93.273l-18.655-18.42V156.575z"
                    />
                    <path
                        fill="#78BCE9"
                        d="M360.655 168.855h37.309l12.436 21.491v49.122l-24.873 18.42h-24.872zm-301.582 0H27.982l-9.327 15.351v55.262l15.545 12.28h24.873z"
                    />
                    <path
                        d="M330.255 0c24.665 0 43.527 18.625 43.527 42.981 0 24.357-18.862 42.982-43.527 42.982-19.186 0-34.86-11.269-40.878-27.738-29.717 4.652-52.924 26.578-59.078 55.368h92.037c23.759 0 43.594 16.87 47.88 39.11a39.173 39.173 0 017.617-.896l.785-.008h8.291c21.155 0 38.344 16.765 38.686 37.574l.005.632v43.664c0 20.89-16.978 37.863-38.051 38.2l-.64.005h-8.29c-2.886 0-5.698-.312-8.404-.903-4.237 22.02-23.722 38.771-47.167 39.104l-.712.005H101.52c-23.638 0-43.396-16.702-47.809-38.783a39.188 39.188 0 01-5.966.57l-.763.007H38.69C17.536 271.874.347 255.11.005 234.3L0 233.67v-43.664c0-20.89 16.978-37.863 38.051-38.2l.64-.006h8.29c2.297 0 4.547.198 6.733.577 4.37-21.858 23.777-38.448 47.098-38.777l.708-.005 99.306-.002c6.759-44.468 42.496-78.867 87.989-84.214C294.367 12.023 310.437 0 330.255 0zm-7.919 145.675H101.52c-8.848 0-16.066 7.024-16.237 15.729l-.003.315v22.824c.222 1.53.352 3.087.385 4.67l.008.792v43.664a37.98 37.98 0 01-.393 5.461v22.832c0 8.745 7.113 15.866 15.921 16.034l.32.003h220.815c8.848 0 16.074-7.017 16.245-15.721l.003-.316V161.72c0-8.842-7.286-16.044-16.248-16.044zM46.982 184.547H38.69c-2.978 0-5.406 2.326-5.523 5.239l-.004.219v43.664c0 2.94 2.355 5.338 5.305 5.453l.222.005h8.29c2.979 0 5.407-2.326 5.524-5.239l.004-.22v-43.663c0-3.014-2.475-5.458-5.527-5.458zm339.927 0h-8.29c-2.979 0-5.407 2.326-5.524 5.239l-.004.219v43.664c0 2.94 2.355 5.338 5.305 5.453l.222.005h8.291c2.978 0 5.406-2.326 5.523-5.239l.004-.22v-43.663c0-3.014-2.474-5.458-5.527-5.458zM330.255 28.654c-8.706 0-14.51 5.731-14.51 14.327 0 8.597 5.804 14.328 14.51 14.328 8.705 0 14.509-5.731 14.509-14.328 0-8.596-5.804-14.327-14.51-14.327z"
                        fill="#247CB5"
                        fillRule="nonzero"
                    />
                    <path
                        d="M155.36 173.98c3.233 0 6.177.79 8.666 2.201a15.692 15.692 0 015.934 5.935c1.411 2.488 2.2 5.433 2.2 8.665v44.8c0 3.232-.789 6.176-2.2 8.665a15.692 15.692 0 01-5.934 5.934c-2.49 1.412-5.433 2.2-8.666 2.2-3.232 0-6.176-.788-8.665-2.2a15.692 15.692 0 01-5.934-5.934c-1.411-2.489-2.2-5.433-2.2-8.665v-44.8c0-3.232.789-6.177 2.2-8.665a15.692 15.692 0 015.934-5.935c2.49-1.411 5.433-2.2 8.665-2.2zm117.6 0c3.233 0 6.177.79 8.666 2.201a15.692 15.692 0 015.934 5.935c1.411 2.488 2.2 5.433 2.2 8.665v44.8c0 3.232-.789 6.176-2.2 8.665a15.692 15.692 0 01-5.934 5.934c-2.49 1.412-5.433 2.2-8.666 2.2-3.232 0-6.176-.788-8.665-2.2a15.692 15.692 0 01-5.934-5.934c-1.411-2.489-2.2-5.433-2.2-8.665v-44.8c0-3.232.789-6.177 2.2-8.665a15.692 15.692 0 015.934-5.935c2.49-1.411 5.433-2.2 8.665-2.2z"
                        fill="#EF6777"
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </svg>
    )
}

export default LogoAppIcon
