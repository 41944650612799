import React from 'react';
import intl from 'react-intl-universal';
import { Grid, Typography } from '@material-ui/core';
import { imageParser } from '../../../../utils/utils';
import FeedbackBox from '../components/FeedbackBox';
import QrImage from '../../../../components/QrImage';

const ImageAnswer = ({ data }) => {
    return (
        // Resposta Correta
        data.correct === "Y" ?
            <Grid container justify="center">
                <Grid item xs={12}>
                    <FeedbackBox
                        correct
                        title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                    >
                        <Grid item xs={12} align="center">
                            <QrImage
                                src={imageParser(data.user_answer_media.source)}
                                effect="blur"
                                className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                alt={intl.get("LABEL_USER_ANSWER")}
                            />
                        </Grid>
                    </FeedbackBox>
                </Grid>
            </Grid>
            :
            // Resposta Incorreta
            data.correct === "N" ?
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <FeedbackBox
                            correct={false}
                            title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                        >
                            <Grid item xs={12} align="center">
                                <QrImage
                                    src={imageParser(data.user_answer_media.source)}
                                    effect="blur"
                                    className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                    alt={intl.get("LABEL_USER_ANSWER")}
                                />
                            </Grid>
                        </FeedbackBox>
                    </Grid>
                </Grid>
                :
                // Aguardando Correção
                data.user_answer ?
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <FeedbackBox
                                disabled
                                title={intl.get("GAME_SOLUTION_ANSWER_SENT_SUBTITLE")}
                            >
                                <Grid item xs={12} align="center">
                                    <QrImage
                                        src={imageParser(data.user_answer_media.source)}
                                        effect="blur"
                                        className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                        alt={intl.get("LABEL_USER_ANSWER")}
                                    />
                                </Grid>
                            </FeedbackBox>
                        </Grid>
                    </Grid>
                    :
                    // Sem Resposta
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <FeedbackBox
                                disabled
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="div">
                                        {intl.get("EMPTY_ANSWER")}
                                    </Typography>
                                </Grid>
                            </FeedbackBox>
                        </Grid>
                    </Grid>
    )
}

export default ImageAnswer;