import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#51A9E3', // Blue[200]
            main: '#2b97dd', // Blue[300]
            dark: '#247CB5', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#F1828F', // Pink[200]
            main: '#EF6777', // Pink[300]
            dark: '#C45562', // Pink[400]
            contrastText: '#fff',
        },
        error: {
            light: '#E86C5D', // Red[200]
            main: '#E65748', // Red[300]
            dark: '#E24331', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
