import React from 'react';
import useReactRouter from 'use-react-router';
import intl from 'react-intl-universal';
import config from '../../../config';
import { Redirect } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import IntroFlahscards from './components/IntroFlashcards';
import IntroValidation from './components/IntroValidation';
import IntroExam from './components/IntroExam';
import IntroCheck from './components/IntroCheck';
import IntroScorm from './components/IntroScorm';
import { Icon } from '../../../components/Images/Images';

export default function StageIntro(props) {
    const { history } = useReactRouter();
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    function goBack() {
        let currentOrigin = "intro";

        switch (origin) {
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'steps':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'flashcards':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'questions':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'result':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {step.step_name}
                        </Typography>
                        {
                            step.step_number_description ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {step.step_number_description}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const IntroContent = () => {
        switch (step.step_type) {
            case "FLASHCARD":
                return (
                    <IntroFlahscards {...props} />
                )
            case "SCORM":
                return (
                    <IntroScorm {...props} />
                )
            case "CHECK":
                return (
                    <IntroCheck {...props} />
                )
            case "EXAM":
                return (
                    <IntroExam {...props} />
                )
            case "CODE":
                return (
                    <IntroValidation {...props} />
                )
            default:
                return (
                    <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS_CONTENT")} />
                )
        }
    }

    return (
        step ?
            <div id="qr-wrap">
                <QrMenu />
                <div id="qr-content">
                    <QrContent id="trail-steps-intro" hideSidebar>
                        {
                            config.layout.navbar ?
                                <QrAppBar />
                                : null
                        }
                        <IntroContent />
                    </QrContent>
                </div>
            </div>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                state: {
                    origin: 'intro'
                }
            }} />
    )
}