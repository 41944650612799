import React from 'react';
import intl from 'react-intl-universal';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Typography } from '@material-ui/core';
import FeedbackBox from '../components/FeedbackBox';
import { Icon } from '../../../../components/Images/Images';

const SelectAnswers = ({ data }) => {
    return (
        // Resposta Correta
        data.correct === "Y" ?
            <Grid container justify="center">
                <Grid item xs={12}>
                    <FeedbackBox
                        correct
                        title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                    >
                        {
                            Array.isArray(data.user_answer) ?
                                data.user_answer.map((item) =>
                                    data.answers.filter(answer => answer.id === item).map((answer, index) => {
                                        return (
                                            <Grid key={index} item xs={12} className="d-flex">
                                                <Icon ic="checkbox_checked" color="success" className="mr-2" />
                                                <Typography variant="body1" component="div">
                                                    {ReactHtmlParser(answer.answer)}
                                                </Typography>
                                            </Grid>
                                        )
                                    })
                                )
                                :
                                data.answers.filter(answer => answer.id === data.user_answer).map((item, index) =>
                                    <Grid key={index} item xs={12} className="d-flex">
                                        <Icon ic="checkbox_checked" color="success" className="mr-2" />
                                        <Typography variant="body1" component="div">
                                            {ReactHtmlParser(item.answer)}
                                        </Typography>
                                    </Grid>
                                )
                        }
                    </FeedbackBox>
                </Grid>
            </Grid>
            :
            // Resposta Incorreta
            data.correct === "N" ?
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <FeedbackBox
                            correct={false}
                            title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                        >
                            {
                                Array.isArray(data.user_answer) ?
                                    data.user_answer.map((item) =>
                                        data.answers.filter(answer => answer.id === item).map((answer, index) => {
                                            return (
                                                <Grid key={index} item xs={12} className="d-flex">
                                                    <Icon ic="checkbox_checked" color="danger" className="mr-2" />
                                                    <Typography variant="body1" component="div">
                                                        {ReactHtmlParser(answer.answer)}
                                                    </Typography>
                                                </Grid>
                                            )
                                        })
                                    )
                                    :
                                    data.answers.filter(answer => answer.id === data.user_answer).map((item, index) =>
                                        <Grid key={index} item xs={12} className="d-flex">
                                            <Icon ic="checkbox_checked" color="danger" className="mr-2" />
                                            <Typography variant="body1" component="div">
                                                {ReactHtmlParser(item.answer)}
                                            </Typography>
                                        </Grid>
                                    )
                            }
                        </FeedbackBox>
                    </Grid>
                    <Grid item xs={12}>
                        <FeedbackBox
                            correct
                            title={intl.get("GAME_SOLUTION_ANSWER_CORRECT_SUBTITLE")}
                        >
                            {
                                data.answers.filter(answer => answer.answer_right === "Y").map((item, index) =>
                                    <Grid key={index} item xs={12} className="d-flex">
                                        <Icon ic="checkbox_checked" color="success" className="mr-2" />
                                        <Typography variant="body1" component="div">
                                            {ReactHtmlParser(item.answer)}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </FeedbackBox>
                    </Grid>
                </Grid>
                :
                // Aguardando Correção
                data.user_answer ?
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <FeedbackBox
                                disabled
                                title={intl.get("GAME_SOLUTION_ANSWER_SENT_SUBTITLE")}
                            >
                                {
                                    Array.isArray(data.user_answer) ?
                                        data.user_answer.map((item) =>
                                            data.answers.filter(answer => answer.id === item).map((answer, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} className="d-flex">
                                                        <Icon ic="checkbox_checked" color="light" className="mr-2" />
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(answer.answer)}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            })
                                        )
                                        :
                                        data.answers.filter(answer => answer.id === data.user_answer).map((item, index) =>
                                            <Grid key={index} item xs={12} className="d-flex">
                                                <Icon ic="checkbox_checked" color="light" className="mr-2" />
                                                <Typography variant="body1" component="div">
                                                    {ReactHtmlParser(item.answer)}
                                                </Typography>
                                            </Grid>
                                        )
                                }
                            </FeedbackBox>
                        </Grid>
                    </Grid>
                    :
                    // Sem Resposta
                    <Grid container justify="center" spacing={2}>
                        {
                            data.answers.map((item, index) =>
                                <Grid key={index} item xs={12}>
                                    <FeedbackBox
                                        correct={item.answer_right === "Y"}
                                    >
                                        <Grid key={index} item xs={12} className="d-flex">
                                            <Icon ic={item.answer_right === "Y" ? "checkbox_checked" : "checkbox_unchecked"} color={item.answer_right === "Y" ? "success" : "danger"} className="mr-2" />
                                            <Typography variant="body1" component="div">
                                                {ReactHtmlParser(item.answer)}
                                            </Typography>
                                        </Grid>
                                    </FeedbackBox>
                                </Grid>
                            )
                        }
                    </Grid>
    )
}

export default SelectAnswers