import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../redux/ducks/home';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import QrSidebar from '../../components/QrSidebar';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

var HtmlToReactParser = require('html-to-react').Parser;

export default function Highlight(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const highlightDetails = useSelector(state => state.home.highlightDetails);
    const [didMount, setDidMount] = useState(false);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(HomeActions.getHighlightDetails({
                id_highlight: props.match.params.id
            }));

        return () => {
            setDidMount(false);
            dispatch(HomeActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        {
                            highlightDetails.data?.title &&
                            <Typography variant="h4" noWrap>
                                {highlightDetails.data.title}
                            </Typography>
                        }

                        {
                            highlightDetails.data?.description &&
                            <div className="w-100 d-flex overflow-hidden">
                                <Typography variant="body2" noWrap>
                                    {highlightDetails.data?.description}
                                </Typography>
                            </div>
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="news">
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            {
                                highlightDetails && highlightDetails.load === "finished" ?
                                    !highlightDetails.error ?
                                        highlightDetails.data.length !== 0 ?
                                            htmlToReactParser.parse(highlightDetails.data.text)
                                            :
                                            <EmptyContent icon={<Icon ic="feed_filled" className="icon-xxl" />} text={intl.get("EMPTY_HIGHLIGHT")} />
                                        :
                                        <ErrorContent />
                                    :
                                    <LoadingContent />
                            }
                        </QrContent>

                        {/* Sidebar */}
                        <QrSidebar />
                    </div>
                </div>
            }
        </div>
    )
};
