import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { addStorage } from '../../utils/storageHandler';
import {
    Grid, Typography, useMediaQuery, Hidden, Button,
} from '@material-ui/core';
import FormLoginEmail from './components/FormLoginEmail';
import FormLoginUsername from './components/FormLoginUsername';
import ButtonLoginAzure from './components/ButtonLoginAzure';
import ButtonLoginSaml2 from './components/ButtonLoginSaml2';
import ButtonLogin from './components/ButtonLogin';
import ButtonSignup from './components/ButtonSignup';
import { LoadingBounce } from '../../components/Loading';
import IntroLayout from './components/IntroLayout';

export default function Intro(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();
    const loading = useSelector(state => state.auth.loading);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

    if (props.location.state && props.location.state.from && props.location.state.from.search) {
        addStorage([{ name: 'url', content: props.location.state.from.search }])
    }

    useEffect(() => {
        if (auth.authenticated === true && Object.keys(auth.user).length !== 0) {
            if (auth.user.answered_secret_questions && auth.user.answered_secret_questions === 'N') {
                history.push({ pathname: '/secret-questions', state: { redirect: "N" } })
            } else
                if (auth.user.is_temporary_password === "Y") {
                    history.push({ pathname: '/temporary-password', state: { jwt: auth.jwt, redirect: "N" } })
                } else {
                    if (auth.user.is_expired_password === "Y") {
                        history.push({ pathname: '/expired-password', state: { jwt: auth.jwt, redirect: "N" } })
                    } else if (auth.user.pending_data === "Y") {
                        history.push({ pathname: '/complete-data', state: { redirect: "N" } });
                    } else {
                        history.replace('/home')
                    }
                }
        }

        if (process.env.REACT_APP_QR_ENV === "PRODUCTION" && window.location.origin !== process.env.REACT_APP_DOMAIN) {
            window.location.assign(process.env.REACT_APP_DOMAIN);
        }
    }, [auth, history]);

    return (
        <IntroLayout title={intl.get("INTRO_TITLE")} id="intro">
            <Grid container>
                <Grid container direction="column" alignItems="center" justify="center" className={`${!loading ? 'clear' : ''}`}>
                    <LoadingBounce />
                </Grid>
                <Grid container className={`${loading ? 'clear' : ''}`} spacing={2}>
                    {
                        config.intro.show_title ?
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <Typography component="h2" variant="h3" align="center" className="text-dark">
                                        {intl.get('INTRO_TITLE')}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            : null
                    }

                    {
                        /* LOGIN AZURE */
                        config.login_method.ad_azure.enabled ?
                            <Grid item xs={12}>
                                <ButtonLoginAzure buttonStyle={config.login_method.ad_azure.style} />
                            </Grid>
                            : null
                    }

                    {
                        /* LOGIN SAML2 */
                        config.login_method.saml2.enabled ?
                            <Grid item xs={12}>
                                {(config.login_method.ad_azure.enabled) ?
                                    <div className="divider-word">
                                        <Typography variant="body2" component="span">
                                            {intl.get('INTRO_LOGIN_DIVIDER_TEXT')}
                                        </Typography>
                                    </div>
                                    : null
                                }
                                <ButtonLoginSaml2 buttonStyle={config.login_method.saml2.style} />
                            </Grid>
                            : null
                    }

                    {
                        /* LOGIN EMAIL */
                        config.login_method.email.login.enabled ?
                            <Grid item xs={12}>
                                {
                                    config.app.name_project !== "FLEURY" ?
                                        (config.login_method.ad_azure.enabled || config.login_method.saml2.enabled) ?
                                            <div className="divider-word">
                                                <Typography variant="body2" component="span">
                                                    {intl.get('INTRO_LOGIN_DIVIDER_TEXT')}
                                                </Typography>
                                            </div>
                                            : null
                                        : null
                                }
                                {
                                    isScreenUpMd ?
                                        config.login_method.email.login.style.large_screen === "form" ?
                                            <FormLoginEmail page={false} />
                                            :
                                            <ButtonLogin type="email" buttonStyle={config.login_method.email.login.style.large_screen} />
                                        :
                                        config.login_method.email.login.style.small_screen === "form" ?
                                            <FormLoginEmail page={false} />
                                            :
                                            <ButtonLogin type="email" buttonStyle={config.login_method.email.login.style.small_screen} />
                                }
                            </Grid>
                            : null
                    }

                    {
                        /* SIGNUP BY EMAIL */
                        config.login_method.email.signup.enabled && config.login_method.email.signup.display_button.intro.enabled ?
                            <Grid item xs={12}>
                                <ButtonSignup type="email" buttonStyle={
                                    isScreenUpMd ?
                                        config.login_method.email.signup.display_button.intro.style.large_screen
                                        :
                                        config.login_method.email.signup.display_button.intro.style.small_screen
                                } />
                            </Grid>
                            : null
                    }

                    {
                        /* LOGIN USERNAME */
                        config.login_method.username.login.enabled ?
                            <Grid item xs={12}>
                                {
                                    (config.login_method.ad_azure.enabled ||
                                        config.login_method.saml2.enabled ||
                                        config.login_method.email.login.enabled ||
                                        (config.login_method.email.signup.enabled && config.login_method.email.signup.display_button.intro.enabled)) ?
                                        <div className="divider-word">
                                            <Typography variant="body2" component="span">
                                                {intl.get('INTRO_LOGIN_DIVIDER_TEXT')}
                                            </Typography>
                                        </div>
                                        : null
                                }
                                {
                                    isScreenUpMd ?
                                        config.login_method.username.login.style.large_screen === "form" ?
                                            <FormLoginUsername page={false} />
                                            :
                                            <ButtonLogin type="username" buttonStyle={config.login_method.username.login.style.large_screen} />
                                        :
                                        config.login_method.username.login.style.small_screen === "form" ?
                                            <FormLoginUsername page={false} />
                                            :
                                            <ButtonLogin type="username" buttonStyle={config.login_method.username.login.style.small_screen} />
                                }
                            </Grid>
                            : null
                    }

                    {
                        /* SIGNUP BY USERNAME */
                        config.login_method.username.signup.enabled && config.login_method.username.signup.display_button.intro.enabled ?
                            <Grid item xs={12}>
                                <ButtonSignup type="username" buttonStyle={
                                    isScreenUpMd ?
                                        config.login_method.username.signup.display_button.intro.style.large_screen
                                        :
                                        config.login_method.username.signup.display_button.intro.style.small_screen
                                } />
                            </Grid>
                            : null
                    }

                    {
                        /* FIRST ACCESS */
                        config.login_method.first_access.enabled ?
                            <Grid item xs={12} align="center">
                                <Button
                                    color="primary"
                                    onClick={() => history.push({
                                        pathname: '/first-access'
                                    })}
                                >
                                    {intl.get('BTN_FIRST_ACCESS')}
                                </Button>
                            </Grid>
                            : null
                    }
                </Grid>
            </Grid>
        </IntroLayout>
    )
};
