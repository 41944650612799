import * as React from "react"

function ImgHandLeft(props) {
    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M144.544 26.029a4.137 4.137 0 013.647 4.557l-13.5 121.003a4.145 4.145 0 01-4.562 3.648l-61.437-6.804a4.137 4.137 0 01-3.648-4.557l13.5-121.003a4.145 4.145 0 014.562-3.648l61.438 6.804z"
                    className="svg-smartphone"
                />
                <path
                    d="M85.281 27.804l55.57 6.17a3.746 3.746 0 013.3 4.125l-11.795 106.015a3.748 3.748 0 01-4.127 3.3l-55.57-6.171a3.746 3.746 0 01-3.3-4.125L81.156 31.104a3.747 3.747 0 014.126-3.3"
                    className="svg-feedback-incorrect"
                />
                <path
                    d="M106.118 25.057l12.949 1.382a.938.938 0 01.829 1.026.93.93 0 01-1.021.829l-12.95-1.382a.938.938 0 01-.828-1.026.93.93 0 011.02-.829"
                    fillOpacity={0.3}
                    fill="#000"
                />
                <path
                    d="M100.372 73.808a1.113 1.113 0 00-.83.216l-2.533 1.917a1.127 1.127 0 00-.221 1.575l6.951 9.265-9.236 6.989a1.127 1.127 0 00-.221 1.575l1.906 2.54a1.116 1.116 0 001.57.221l9.236-6.989 6.951 9.264a1.116 1.116 0 001.57.221l2.533-1.917c.495-.374.594-1.08.221-1.575l-6.952-9.264 9.236-6.99c.495-.373.595-1.08.222-1.575l-1.907-2.54a1.116 1.116 0 00-1.569-.221l-9.236 6.989-6.952-9.264a1.114 1.114 0 00-.739-.437z"
                    fill="#FFF"
                />
                <path
                    d="M74.83 88.735l1.74-16.87c4.41-3.255 9.275-4.716 9.275-4.716l12.899-6.273c-4.227 19.981-15.95 10.93-19.56 20.102-1.074 2.726-2.775 5.497-4.354 7.757"
                    fill="#000"
                    opacity={0.1}
                />
                <path
                    d="M79.73 59.375c7.552-1.084 19.051-2.124 19.051-2.124-.089 7.154-2.92 10.621-10.322 13.378-7.403 2.757-10.386 2.706-11.71 6.84-1.322 4.135-3.71 7.16-8.224 13.43s1.008 13.157 5.232 19.921c4.224 6.764 7.42 21.89 7.373 29.523-.046 7.632-.22 9.073-.22 9.073l23.907 2.626s-3.736 5.365-16.18 10.264c-12.445 4.9-20.581 11.175-31.834 31.302-4.733 8.466-22.42 31.397-24.002 39.144l-17.672-48.066c6.124-7.714 23.743-35.493 25.598-39.725 6.158-14.046.142-43.692 2.15-51.902 2.007-8.211 13.868-15.145 20.885-24.05 7.017-8.905 8.418-8.55 15.969-9.634zm59.066 51.424s17.325 5.56 22.593 9.658c5.268 4.098-8.688 6.985-24.18 4.572zm4.497-38.296s26.365-6.702 25.955-2.06c-.41 4.643-13.545 12.34-27.747 17.18zm14.792-32.875c1.146 1.947.973 6.854-5.83 13.627-6.801 6.772-7.67 7.695-7.67 7.695l1.867-18.303s10.486-4.966 11.633-3.02z"
                    className="svg-hand"
                />
                <path
                    d="M68.43 91.106c-1.262 1.915-1.657 3.913-1.476 5.965-2.811 2.227-6.818 3.411-6.818 3.411s6.126-6.094 8.293-9.376"
                    className="svg-hand-shade"
                />
            </g>
        </svg>
    )
}

export default ImgHandLeft