import React, { useState, forwardRef } from 'react';
import intl from 'react-intl-universal';
import { examDateTime } from '../../../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Typography, Grid, ListItem, ListItemText,
    Divider, Button, Slide
} from '@material-ui/core';
import DialogExamRules from './DialogExamRules';
import QrList from '../../../../components/QrList';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExamDetailsWaiting(props) {
    const { item } = props;
    const [openRules, setOpenRules] = useState(false);

    const Header = () => {
        return (
            <Grid container id="exam-details-header" justify="center" className="appbar-custom-content __waiting">
                <Container maxWidth="md">
                    <Grid container justify="center">
                        <Grid item xs={12} className="scenario-content" align="center">
                            <div className="scenario-content-text">
                                <Typography variant="h5" component="h5">
                                    {intl.get("EXAM_RESULT_WAITING_TEXT")}
                                </Typography>
                                <Typography variant="h4" component="h3" className="mt-1">
                                    {examDateTime(item.exam_feedback_date)}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

    return (
        <div>
            <Header />
            <Container maxWidth="md" className="pt-3 pb-4 __bottom-bar">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} xl={12}>
                        <Grid container justify="center" spacing={2}>
                            {
                                item.description ?
                                    <Grid item xs={12}>
                                        <Typography variant="body2" component="div">
                                            {ReactHtmlParser(item.description)}
                                        </Typography>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <QrList>
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_THEME")} secondary={item.name} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText
                                            primary={intl.get("EXAM_NUMBER_QUESTIONS")}
                                            secondary={item.total_question}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography variant="body1" component="span" className="fs-sm">
                                                    {intl.get("LABEL_TOTAL_TIME")}
                                                </Typography>
                                            }
                                            secondary={
                                                <div className="d-flex flex-column align-items-end">
                                                    <Typography variant="body2" component="span" className="fs-md font-weight-bold">
                                                        {item.session.time_spent ? item.session.time_spent : "-"}
                                                    </Typography>
                                                    <Typography variant="body2" component="span">
                                                        {item.duration}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_AVAILABLE")} secondary={examDateTime(item.exam_start_date)} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_FINISH")} secondary={examDateTime(item.exam_end_date)} />
                                    </ListItem>
                                </QrList>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div id="bottom-bar" className="relative-bar">
                                    <Grid container justify="center">
                                        <Grid item xs={12} lg={8}>
                                            <Button id="btn_exam_details_rules" variant="contained" className="btn-block" onClick={() => setOpenRules(true)}>
                                                {intl.get("BTN_RULE")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* Dialog Rules */}
            <DialogExamRules
                open={openRules}
                close={() => setOpenRules(false)}
                transition={Transition}
                item={item}
            />
        </div>
    )
}