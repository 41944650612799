import * as React from "react"

function ImgWind(props) {
    return (
        <svg width={64} height={80} viewBox="0 0 64 80" {...props}>
            <path
                d="M31.016 69.466c-.286-.423.309-.902.632-.56.473.498 1.05.823 1.541 1.284.348.327.705.631 1.067.933.407.058.756.327 1.022.726l.16.24c.941 1.413-1.016 2.977-2.097 1.855l-.2-.207c-.249-.259-.428-.695-.43-1.105-.26-.448-.544-.895-.746-1.36-.274-.629-.57-1.25-.949-1.806zM39.237 51.3c-.763-.494-.306-1.671.594-1.475 2.507.55 4.97 1.253 7.423 2.016 2.134.665 4.027.82 4.955 3.015.342.81-.361 1.57-1.031 1.842-2.102.857-4.209-.945-6.023-1.927-2.014-1.09-3.996-2.225-5.918-3.47zM35.97 25.36c2.662-1.828 7.156-1.713 10.304-1.61 3.057.1 8.085-.036 9.8 2.696.446.712.013 1.453-.743 1.732-2.55.943-5.766-.858-8.365-1.335-3.009-.554-7.474-1.401-10.472-.35-.757.267-1.088-.745-.524-1.133zm6.905-20.303c3.268-.641 3.833 4.436.61 4.46-11.648.086-24.035 3.5-34.664 8.198-.611.27-1.171-.589-.55-.926 5.482-2.98 10.967-5.592 16.908-7.538C31 7.344 36.886 6.231 42.875 5.056z"
                className="svg-wind"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default ImgWind
