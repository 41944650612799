import { SurveyService } from '../../services/survey.service';

// CONST
export const Types = {
    QUESTIONS: 'survey/QUESTIONS',
    ANSWER: 'survey/ANSWER',
    RESET: 'survey/RESET',
};

// INITIAL STATE
const initialState = {
    question: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.QUESTIONS:
            return {
                ...state,
                question: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ANSWER:
            return {
                ...state,
                question: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                question: action.payload.question
            }
        default:
            return state
    }
}

function getSurveyQuestion(params) {
    return dispatch => {
        return SurveyService.surveyPlay(params).then(data => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getSurveyAnswer(params) {
    return dispatch => {
        return SurveyService.surveyAnswer(params).then(data => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormSurveyQuestion(params) {
    return dispatch => {
        return SurveyService.scormSurveyPlay(params).then(data => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormSurveyAnswer(params) {
    return dispatch => {
        return SurveyService.scormSurveyAnswer(params).then(data => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getClassroomSurveyQuestion(params) {
    return dispatch => {
        return SurveyService.classroomSurveyPlay(params).then(data => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getClassroomSurveyAnswer(params) {
    return dispatch => {
        return SurveyService.classroomSurveyAnswer(params).then(data => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET,
            payload: {
                question: {}
            }
        });
    }
}

export const actions = {
    getSurveyQuestion,
    getSurveyAnswer,
    getScormSurveyQuestion,
    getScormSurveyAnswer,
    getClassroomSurveyQuestion,
    getClassroomSurveyAnswer,
    reset
}
