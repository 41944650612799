import * as React from "react"

function ImgFlashcardNone(props) {
    return (
        <svg width={106} height={132} viewBox="0 0 106 132" {...props}>
            <path
                d="M11 4h84a6 6 0 016 6v112a6 6 0 01-6 6H11a6 6 0 01-6-6V10a6 6 0 016-6z"
                fill="#FFF"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default ImgFlashcardNone
