import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';

export default function CardReport(props) {
    const { description } = props;

    return (
        <Card className="card card-report">
            <FeedCardHeader {...props} />
            <CardContent className="pt-0">
                <Typography variant="body2" component="p">
                    {description}
                </Typography>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
