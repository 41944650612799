import React, { forwardRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MobileBottomNavigation } from './QrMenu';
import { Slide } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBadgeDetails from '../scenes/Achievements/components/DialogBadgeDetails';
import DialogMissionsDetails from '../scenes/Achievements/components/DialogMissionsDetails';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const QrContent = ({ children, hideSidebar, id, className, bottomNavigation }) => {
    const theme = useTheme();
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const sidebarOpen = useSelector(state => state.qrLayout.sidebarOpen);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [openBadge, setOpenBadge] = useState(false);
    const [badgeQueue, setBadgeQueue] = useState([]);
    const [currentBadge, setCurrentBadge] = useState([]);

    const [openMission, setOpenMission] = useState(false);
    const [missionQueue, setMissionQueue] = useState([]);
    const [currentMission, setCurrentMission] = useState([]);

    const handleCloseBadge = () => {
        setOpenBadge(false);

        // Atualiza o localStorage ao fechar o dialog
        const updatedBadges = badgeQueue.slice(1);
        localStorage.setItem('badges_unlocked', JSON.stringify(updatedBadges.length > 0 ? updatedBadges : null));
    };

    const handleCloseMission = () => {
        setOpenMission(false);

        // Atualiza o localStorage ao fechar o dialog
        const updatedMission = missionQueue.slice(1);
        localStorage.setItem('achievement_unlocked', JSON.stringify(updatedMission.length > 0 ? updatedMission : null));
    };

    useEffect(() => {
        const badgesUnlocked = JSON.parse(localStorage.getItem('badges_unlocked'));
        const missionUnlocked = JSON.parse(localStorage.getItem('achievement_unlocked'));

        if (badgesUnlocked) {
            setBadgeQueue(Array.isArray(badgesUnlocked) ? badgesUnlocked : [badgesUnlocked]);
        }

        if (missionUnlocked) {
            setMissionQueue(Array.isArray(missionUnlocked) ? missionUnlocked : [missionUnlocked]);
        }
    }, []);

    useEffect(() => {
        if (!openBadge && badgeQueue.length > 0) {
            const nextBadge = badgeQueue[0];
            setCurrentBadge(nextBadge);
            setBadgeQueue(prevQueue => prevQueue.slice(1));
            setOpenBadge(true);
        } else if (!openMission && missionQueue.length > 0) {
            const nextMission = missionQueue[0];
            setCurrentMission(nextMission);
            setMissionQueue(prevQueue => prevQueue.slice(1));
            setOpenMission(true);
        }
    }, [openBadge, badgeQueue, missionQueue, openMission]);

    return (
        <div id="qr-middle" className={`sb__${hideSidebar ? 'hidden' : sidebarOpen ? 'open' : 'close'} menu__${menuOpen ? 'open' : 'close'}`}>
            <main id={id} className={`qr-main ${className || ""}`} role="main">
                <div className="qr-main_content">
                    {children}
                    {isMobile && bottomNavigation && <MobileBottomNavigation />}
                </div>
            </main>
            <DialogBadgeDetails
                open={openBadge}
                close={handleCloseBadge}
                data={currentBadge}
                transition={Transition}
            />
            <DialogMissionsDetails
                open={openMission}
                close={handleCloseMission}
                data={currentMission}
                transition={Transition}
            />
        </div>
    );
};

export default QrContent;
