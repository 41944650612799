import * as React from "react";

function ImgPointsNeuros(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#E65748"
                    d="M8.272 21L3 15.728V8.272L8.272 3h7.456L21 8.272v7.456L15.728 21z"
                />
                <path
                    d="M15.389 2H8.61c-.482 0-.944.192-1.285.533L2.533 7.326A1.818 1.818 0 002 8.61v6.778c0 .483.192.945.533 1.286l4.793 4.793c.34.34.803.532 1.285.532h6.778a1.82 1.82 0 001.285-.532l4.793-4.793c.341-.341.533-.803.533-1.286V8.611c0-.482-.192-.944-.533-1.285l-4.793-4.793A1.818 1.818 0 0015.39 2zm-.754 3.636l3.729 3.729v5.27l-3.73 3.729h-5.27l-3.728-3.729V9.364l3.728-3.728h5.271z"
                    fill="#BD483B"
                    fillRule="nonzero"
                />
                <path
                    d="M9.61 6.644h4.422l1.493-3.584a1.825 1.825 0 00-.664-.15H8.886c-.2 0-.439.056-.663.15l1.388 3.584z"
                    fill="#E65748"
                />
                <path
                    d="M6.708 9.732L9.715 6.69 8.223 3.06a1.814 1.814 0 00-.575.362L3.422 7.647c-.141.141-.27.351-.363.576l3.649 1.509z"
                    fill="#BD483B"
                />
                <path
                    d="M3.06 15.525c.092.223.22.433.361.574l4.228 4.227c.14.14.35.269.574.362l1.44-3.478-3.126-3.126-3.478 1.44z"
                    fill="#EF948A"
                />
                <path
                    d="M17.141 9.595l-.035 4.592 3.582 1.338c.093-.225.15-.464.15-.664V8.886c0-.2-.057-.439-.15-.663L17.14 9.595z"
                    fill="#E65748"
                />
                <path
                    d="M14.032 6.537l3.178 3.126 3.478-1.44a1.818 1.818 0 00-.363-.576L16.1 3.422a1.825 1.825 0 00-.575-.362l-1.493 3.477z"
                    fill="#EF948A"
                />
                <path
                    d="M2.91 8.884v5.98c0 .199.057.437.15.66l3.478-1.44v-4.42L3.06 8.221c-.093.224-.15.463-.15.662m5.974 11.955h5.98c.199 0 .437-.058.66-.15l-1.527-3.656H9.75l-1.527 3.656c.223.092.462.15.661.15"
                    fill="#E65748"
                />
                <path
                    d="M17.104 14.04l-3.107 2.992 1.528 3.656c.224-.093.434-.222.575-.363l4.226-4.225c.14-.141.269-.35.362-.576l-3.584-1.484z"
                    fill="#BD483B"
                />
                <path
                    fill="#EA7569"
                    d="M9.663 17.21l-3.126-3.126v-4.42l3.126-3.127h4.42l3.127 3.126v4.421l-3.126 3.126z"
                />
                <path
                    d="M11.964 8.161l.803 2.493 2.51.03c.215.003.305.291.132.426l-2.014 1.57.748 2.512c.064.216-.17.394-.346.263l-2.047-1.521-2.047 1.521c-.176.131-.41-.047-.346-.263l.748-2.511L8.09 11.11c-.173-.135-.083-.424.132-.426l2.51-.03.803-2.493a.223.223 0 01.428 0z"
                    fill="#BD483B"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default ImgPointsNeuros
