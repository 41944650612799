import { examService } from '../../services/exam.service';

// CONST
export const Types = {
    LIST: 'exam/LIST',
    LIST_DONE: 'exam/LIST_DONE',
    DETAILS: 'exam/DETAILS',
    QUESTION: 'exam/QUESTION',
    ANSWER: 'exam/ANSWER',
    UPLOAD: 'exam/UPLOAD',
    FINISH: 'exam/FINISH',
    LOADING: 'exam/LOADING',
    RESET: 'exam/RESET',
};

// INITIAL STATE
const initialState = {
    exams: {},
    examsDone: {},
    details: {},
    question: {},
    upload: {},
    finish: {},
    loading: false
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                exams: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LIST_DONE:
            return {
                ...state,
                examsDone: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.DETAILS:
            return {
                ...state,
                details: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.QUESTION:
            return {
                ...state,
                question: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                },
                loading: false
            }
        case Types.ANSWER:
            return {
                ...state,
                question: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.FINISH:
            return {
                ...state,
                finish: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LOADING:
            return {
                ...state,
                loading: true
            }
        case Types.RESET:
            return {
                ...state,
                exams: {},
                examsDone: {},
                details: {},
                question: {},
                upload: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getExamList(params) {
    return dispatch => {
        return examService.getExamList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.exams,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExamListDone(params) {
    return dispatch => {
        return examService.getExamListDone(params).then(data => {
            dispatch({
                type: Types.LIST_DONE,
                payload: {
                    data: data.exams,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST_DONE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getDetails(params) {
    return dispatch => {
        return examService.getDetails(params).then(data => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    data: data.exam,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getQuestions(params) {
    return dispatch => {
        return examService.getQuestions(params).then(data => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: data.exam,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExamAnswer(params) {
    return dispatch => {
        return examService.getExamAnswer(params).then(data => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: data.exam,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setExamFinish(params) {
    return dispatch => {
        return examService.setExamFinish(params).then(data => {
            dispatch({
                type: Types.FINISH,
                payload: {
                    data: data.exam,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FINISH,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setLoading() {
    return dispatch => {
        dispatch({
            type: Types.LOADING
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getExamList,
    getExamListDone,
    getDetails,
    getQuestions,
    getExamAnswer,
    setExamFinish,
    setLoading,
    reset
}
