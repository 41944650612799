import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as MyHistoryActions } from '../../redux/ducks/my_history';
import { getFullDate, getHours, numberMask } from '../../utils/utils';
import { Grid, Container } from '@material-ui/core';
import ActivityInfoCard from './components/ActivityInfoCard';
import TableActivities from './components/TableActivities';
import { LoadingBounce } from '../../components/Loading';
import config from "../../config";

export default function Details(props) {
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const auth = useSelector(state => state.auth);
    const { coursesAll, coursesObligatory } = useSelector(state => state.myHistory);
    const [page] = useState(0);
    const [rows] = useState(10);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(MyHistoryActions.getProfileActivities({
                offset: page,
                limit: rows,
            }));
            dispatch(MyHistoryActions.getStatisticCoursesAll({}));
            dispatch(MyHistoryActions.getStatisticCoursesObligatory({}));
        }

        return () => {
            setDidMount(false);
        }
    }, [dispatch, didMount, page, rows]);

    return (
        <Container maxWidth="lg" className="pt-2 pb-4">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="stretch">
                        <Grid item xs={12} className="activities-info">
                            <Grid container alignItems="stretch" className="activities-info-list" spacing={2}>
                                {
                                    config.my_history.head_cards.last_login ?
                                        <ActivityInfoCard
                                            section="section-home"
                                            icon="login"
                                            title={intl.get("MY_HISTORY_ACTIVITIES_LAST_LOGIN")}
                                            total={getFullDate(auth.user.server_date)}
                                            description={getHours(auth.user.server_date)}
                                        />
                                        : null
                                }
                                {
                                    config.my_history.head_cards.ranking ?
                                        <ActivityInfoCard
                                            section="section-ranking"
                                            icon="trophy_filled"
                                            title={intl.get("MY_HISTORY_ACTIVITIES_RANKING")}
                                            total={numberMask(auth.user.ranking) + "º"}
                                            points={numberMask(auth.user.total_points)}
                                        />
                                        : null
                                }
                                {
                                    config.my_history.head_cards.courses_obligatory ?
                                        <ActivityInfoCard
                                            section="section-play"
                                            icon="brain_filled"
                                            title={intl.get("MY_HISTORY_ACTIVITIES_COURSES_OBLIGATORY")}
                                            total={
                                                coursesObligatory && coursesObligatory.load === "finished" ?
                                                    `${numberMask(coursesObligatory.data.total_courses_finished)} / ${numberMask(coursesObligatory.data.total_courses_available)}`
                                                    :
                                                    <LoadingBounce />
                                            }
                                            description={intl.get("MY_HISTORY_ACTIVITIES_COURSES_OBLIGATORY_DESCRIPTION")}
                                        />
                                        : null
                                }
                                {
                                    config.my_history.head_cards.courses_completed ?
                                        <ActivityInfoCard
                                            section="bg-success"
                                            icon="checked"
                                            title={intl.get("MY_HISTORY_ACTIVITIES_COURSES_COMPLETED")}
                                            total={
                                                coursesAll && coursesAll.load === "finished" ?
                                                    `${numberMask(coursesAll.data.total_courses_finished)} / ${numberMask(coursesAll.data.total_courses_available)}`
                                                    :
                                                    <LoadingBounce />
                                            }
                                            description={intl.get("MY_HISTORY_ACTIVITIES_COURSES_COMPLETED_DESCRIPTION")}
                                        />
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    config.my_history.my_activities.enabled ?
                        <Grid item xs={12} className="my-history-activities-table">
                            <TableActivities />
                        </Grid>
                        : null
                }
            </Grid>
        </Container>
    )
};
