import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#32618c', // Dark Blue[200]
            main: '#003A70', // Dark Blue[300]
            dark: '#002e59', // Dark Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#5497e7', // Blue[200]
            main: '#2A7DE1', // Blue[300]
            dark: '#2164b4', // Blue[400]
            contrastText: '#fff',
        },
        error: {
            light: '#f15a58', // Red[200]
            main: '#EE312F', // Red[300]
            dark: '#be2725', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
