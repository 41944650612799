import qs from 'qs';
import axios from 'axios';
import project from '../config';
import { generateNonce, timestamp } from '../utils/utils';

//const chunkSize = 40000; // Tamanho máximo de cada chunk (40KB).
let refreshingToken = false; // Flag para evitar múltiplas tentativas de refresh simultâneas.

/**
 * Retorna o usuário armazenado no localStorage.
 */
function getLocalUser() {
    const user = localStorage.getItem('auth.state.user');
    return user ? JSON.parse(user) : {};
}

/**
 * Recupera o token JWT do localStorage.
 */
function getJwt() {
    return localStorage.getItem('auth.state.jwt') || '';
}

/**
 * Recupera o token de refresh do localStorage.
 */
function getRefreshToken() {
    return localStorage.getItem('auth.state.refresh_token');
}

/**
 * Monta o corpo da requisição com informações padrão.
 */
function getBody() {
    const user = getLocalUser();
    const language = user.language || process.env.REACT_APP_LANGUAGE_DEFAULT;

    const result = {
        origin: 'REACT-APP',
        language,
        version: '1',
        json_force_array: '1',
        timestamp: timestamp(),
        nonce: generateNonce(10),
    };

    // Adiciona o ID do usuário, se disponível.
    if (user.id_user) result.id_user = user.id_user;

    // Adiciona o código do projeto, se houver.
    if (project.app.code) result[project.app.code] = '1';

    return result;
}

/**
 * Cria o cabeçalho da requisição. Adiciona 'multipart/form-data' se necessário.
 */
function createHeader(jwt = '', isMultipart = false) {
    const headers = {
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...(jwt && { AUTH_JWT: jwt }), // JWT adicionado ao cabeçalho, se disponível.
        ...(isMultipart && { 'Content-Type': 'multipart/form-data' }), // Tipo de conteúdo ajustado dinamicamente.
    };
    return { headers };
}

/**
 * Realiza o refresh do token JWT quando ele expira.
 */
async function handleTokenRefresh(basepath, originalReq) {
    refreshingToken = true; // Bloqueia novas tentativas de refresh simultâneas.
    try {
        const user = getLocalUser();
        const response = await axios.post(
            `${basepath}/v3/app/login/jwt/refresh`,
            qs.stringify({
                origin: 'REACT-APP',
                version: '1',
                json_force_array: '1',
                timestamp: timestamp(),
                nonce: generateNonce(10),
                language: user.language,
                id_user: user.id_user,
                token_refresh: getRefreshToken(),
                [project.app.code]: '1',
            }),
            createHeader()
        );

        // Atualiza os tokens no localStorage.
        const { token, refresh_token } = response.data.data[0];
        localStorage.setItem('auth.state.jwt', token);
        localStorage.setItem('auth.state.refresh_token', refresh_token);

        // Reenvia a requisição original com o novo token.
        originalReq.headers.AUTH_JWT = token;
        return axios(originalReq);
    } catch (error) {
        // Se o refresh falhar, limpa o localStorage e redireciona para a página inicial.
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject(error);
    } finally {
        refreshingToken = false;
    }
}

/**
 * Configura interceptores para lidar com respostas de erro e fazer refresh do JWT.
 */
function setupInterceptors(basepath) {
    axios.interceptors.response.use(
        response => response, // Passa respostas bem-sucedidas sem modificações.
        async error => {
            const originalReq = error.config;

            // Detecta erro de sessão expirada e tenta fazer refresh do token.
            if (
                error.response?.data?.messages?.[0] === 'JWT_SESSION_EXPIRED' &&
                !refreshingToken &&
                !originalReq._retry
            ) {
                originalReq._retry = true;
                return handleTokenRefresh(basepath, originalReq);
            }
            return Promise.reject(error); // Rejeita outros erros normalmente.
        }
    );
}

/**
 * Prepara um FormData para upload de arquivos ou fotos.
 */
function prepareFormData(data) {
    const formData = new FormData();
    const user = getLocalUser();

    // Adiciona o ID do usuário ao FormData.
    formData.append('id_user', user.id_user);

    // Adiciona o código do projeto, se houver.
    if (project.app.code) {
        formData.append(project.app.code, '1');
    }

    if (data.type === "video") {
        // Divide o arquivo em chunks e adiciona ao FormData com a mesma chave 'file'.
        /* prepareChunks(data.file).forEach(chunk => {
            formData.append('file', chunk); // Cada chunk é adicionado com a mesma chave 'file'.
        }); */
        formData.append('file', data.file, data.file_name);
    }

    if (data.type === "photo") {
        formData.append('file', data.file, data.file_name); // Adiciona foto de perfil, se houver.
    }

    if (data.type === "avatar") {
        formData.append('photo', data.photo, 'photo');
    }

    return formData;
}

/**
 * Divide um arquivo em chunks de tamanho máximo definido.
 */
/* function prepareChunks(file) {
    const chunks = [];
    for (let start = 0; start < file.size; start += chunkSize) {
        const chunk = file.slice(start, Math.min(start + chunkSize, file.size)); // Evita exceder o tamanho do arquivo.
        chunks.push(chunk);
    }
    return chunks;
} */

/**
 * Envia uma requisição usando o axios e lida com respostas e erros.
 */
async function sendRequest(basepath, route, data, config) {
    const url = `${basepath}${route}`;
    try {
        const response = await axios.post(url, data, config);
        const responseData = response.data.data || response.data;

        // Verifica se 'badges_unlocked' está presente na resposta e o armazena no localStorage.
        if (responseData.badges_unlocked) {
            localStorage.setItem('badges_unlocked', JSON.stringify(responseData.badges_unlocked));
        }

        // Verifica se 'achievement_unlocked' está presente na resposta e o armazena no localStorage.
        if (responseData.badges_unlocked) {
            localStorage.setItem('achievement_unlocked', JSON.stringify(responseData.achievement_unlocked));
        }

        // Verifica se 'notifications_count' está presente na resposta e o armazena no localStorage.
        if (responseData.notifications_count) {
            localStorage.setItem('notifications_count', JSON.stringify(responseData.notifications_count));
        }

        return responseData; // Retorna os dados ou a resposta completa.
    } catch (error) {
        const { response } = error;

        if (response?.status === 200) {
            const responseData = response.data.data || response.data;

            // Verifica se 'badges_unlocked' está presente na resposta e o armazena no localStorage.
            if (responseData.badges_unlocked) {
                localStorage.setItem('badges_unlocked', JSON.stringify(responseData.badges_unlocked));
            }

            // Verifica se 'achievement_unlocked' está presente na resposta e o armazena no localStorage.
            if (responseData.badges_unlocked) {
                localStorage.setItem('achievement_unlocked', JSON.stringify(responseData.achievement_unlocked));
            }

            // Verifica se 'notifications_count' está presente na resposta e o armazena no localStorage.
            if (responseData.notifications_count) {
                localStorage.setItem('notifications_count', JSON.stringify(responseData.notifications_count));
            }

            return responseData;
        }

        return Promise.reject(response?.data || error); // Rejeita outros erros.
    }
}

/**
 * Serviço principal para requisições autenticadas.
 */
export async function qrService(route, data) {
    const basepath = process.env.REACT_APP_API_BASEPATH;
    setupInterceptors(basepath); // Configura os interceptores.

    const isMultipart = data.photo || data.file; // Verifica se a requisição é multipart.
    const config = createHeader(getJwt(), isMultipart); // Cria o cabeçalho com ou sem multipart.
    delete config.headers['Content-Type']; // Deixa o axios definir o Content-Type automaticamente.
    // Adicionado para arquivos pdfs
    if (route === '/v3/app/courses/certificate' || route === '/v1/app/scorm/user/certificate' || route === '/v1/app/classroom/training/certificate' || route === '/v2/app/prize/redeemed/voucher/user') {
        config.responseType = "blob";
    }
    const requestData = isMultipart ? prepareFormData(data) : qs.stringify({ ...getBody(), ...data });
    return sendRequest(basepath, route, requestData, config);
}

/**
 * Serviço público para requisições sem autenticação.
 */
export async function qrServicePublic(route, data) {
    const basepath = process.env.REACT_APP_API_BASEPATH;
    setupInterceptors(basepath);

    const config = createHeader();
    delete config.headers['Content-Type']; // Remove o Content-Type para evitar erros.

    const requestData = qs.stringify({ ...getBody(), ...data });
    return sendRequest(basepath, route, requestData, config);
}

/**
 * Combina dados com o corpo padrão e cabeçalhos.
 */
export function dataCombine(data) {
    const bodyDefault = getBody();
    const dataRequest = qs.stringify({ ...bodyDefault, ...data });
    const config = createHeader(getJwt());

    return { data: dataRequest, headers: config };
}
