import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" fillRule="nonzero" d="M0 0h512v512H0z" />
                <path fill="#5C7A96" d="M291.9736 76.8028c34.4887-.1949 61.5027 9.453 81.0558 28.9476 19.5494 19.4895 29.4292 47.4206 29.6355 83.7933l.6513 115.053c.0748 13.1547-10.5285 23.879-23.6833 23.9529l-8.602.0484c-13.1549.0752-23.8792-10.5281-23.953-23.683l-.6408-112.9268c-.1325-23.6205-4.973-40.5967-14.5228-50.9338-9.5435-10.3409-22.8817-15.4566-40.0108-15.3585-17.1265.0956-30.3985 5.3615-39.8337 15.8107-9.4288 10.4428-14.077 27.4725-13.9445 51.093l.6407 112.9282c.0739 13.1535-10.5294 23.8778-23.6829 23.953l-7.9092.045c-13.1547.0747-23.879-10.5286-23.9542-23.6834l-.651-115.0516c-.2075-36.3727 9.355-64.416 28.6866-84.1245 19.329-19.7175 46.2372-29.6686 80.7183-29.8635" />
                <path fill="#E91A22" d="M292.2964 166.6715c-.8166 0-1.6254-.0255-2.4292-.0765-14.0414-.8878-26.1006 9.7206-26.0395 23.7912l.563 129.014c.1325 23.6205-4.5144 40.6503-13.9445 51.093-9.434 10.4492-22.7072 15.7152-39.8337 15.8107-17.1291.0981-30.466-5.0176-40.0095-15.3584-9.5511-10.3371-14.3916-27.312-14.5241-50.9338l-.6395-112.927c-.0751-13.1547-10.7982-23.758-23.953-23.6828l-8.6033.0484c-13.1535.0751-23.7568 10.7982-23.6833 23.953l.6526 115.0528c.2063 36.3728 10.0848 64.3039 29.6354 83.7933 19.5532 19.4946 46.5672 29.1438 81.056 28.9477 34.481-.195 61.388-10.146 80.717-29.8635 19.3327-19.7086 28.894-47.7518 28.6877-84.1233l-.5694-130.5706c-.0586-13.5675-11.2988-24.8166-24.846-24.0332-.74.0433-1.4852.065-2.2367.065m122.1194 229.5387c0 21.0474-17.0629 38.1102-38.1102 38.1102-21.0474 0-38.109-17.0628-38.109-38.1102S355.2582 358.1 376.3056 358.1c21.0473 0 38.1102 17.0628 38.1102 38.1102" />
            </g>
        </svg>
    )
}

export default LogoAppIcon
