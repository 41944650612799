import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { useLastLocation } from 'react-router-last-location';
import { Grid, Button, Container } from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import { Correct, Incorrect, Continue } from '../../../components/Game/partials/Solution';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import SnackBar from '../../../components/snackBar';
import { LoadingContent, LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function StepQuestionsSolution(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const lastLocation = useLastLocation();
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const sessionState = props.location.state && props.location.state.session ? props.location.state.session : '';
    const idQuestion = props.location.state && props.location.state.idQuestion ? props.location.state.idQuestion : '';
    const answer = props.location.state && props.location.state.answer ? props.location.state.answer : '';
    const result = useSelector(state => state.trail.result);
    const session = useSelector(state => state.trail.session);
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {
        setDidMount(true);

        if (lastLocation && lastLocation.pathname.includes("questions")) {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount) {
            dispatch(TrailActions.getStepCommit({
                id_step: sessionState.id_step,
                id_session: sessionState.id_session,
                refers_id: idQuestion,
                content: answer
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(TrailActions.reset());
        }

    }, [didMount, dispatch, sessionState, lastLocation, idQuestion, answer]);

    async function nextQuestion() {
        setLoadingButton(true);
        await dispatch(TrailActions.getStepStart({
            id_step: step.id_step
        }));
        setLoadData(true);
    }

    if (loadData && (session && session.load === "finished")) {
        if (session.error) {
            setSnackMessage(session.messages);
            setSnackOpen(true);
            setLoadingButton(false);
            dispatch(TrailActions.reset());
        } else {
            return <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                state: {
                    origin: 'check',
                    step: step,
                    session: session.data,
                    question: session.data,
                    quizType: 'CHECK'
                }
            }} />
        }
    }

    const ContinueLaterButtons = ({ loadingColor }) => {
        return (
            <>
                {
                    result.data.step_result ?
                        <Button id="btn_trail_steps_questions_solution_check_next" variant="contained" className="btn-block-xs w-50"
                            onClick={() => history.push({
                                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                                state: {
                                    origin: 'solution',
                                    step: step,
                                    session: sessionState,
                                    stepResult: result.data
                                }
                            })}>
                            {intl.get("BTN_CONTINUE")}
                        </Button>
                        :
                        loadingButton ?
                            <LoadingBounce size={24} color={loadingColor} />
                            :
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button id="btn_trail_steps_questions_solution_check_exit" variant="contained" className="btn-block" onClick={() => history.push({
                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                                        state: {
                                            origin: 'solution'
                                        }
                                    })}>
                                        {intl.get("BTN_CONTINUE_LATER")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button id="btn_trail_steps_questions_solution_check_next" variant="contained" className="btn-block" onClick={nextQuestion}>
                                        {intl.get("BTN_NEXT_QUESTION")}
                                    </Button>
                                </Grid>
                            </Grid>
                }
                <SnackBar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackOpen}
                    message={snackMessage}
                    status="error"
                    time={5}
                    closeSnack={() => setSnackOpen(false)}
                />
            </>
        )
    }

    return (
        !redirect ?
            <div id="qr-wrap">
                <QrMenu />
                <div id="qr-content">
                    <QrContent
                        id="trail-steps-questions-solution"
                        className={`${result?.data?.result?.correct ? (!result.data.result.user_sent_answer && !result.data.result.image_user_sent_answer) && (!result.data.result.correct_answer && !result.data.result.image_correct_answer) ? "__full-background " : "" : ""} ${result?.data?.result?.correct ? result.data.result.correct === "Y" ? "__correct" : "__incorrect" : "__continue"}`}
                        hideSidebar
                    >
                        {
                            result && result.load === "finished" ?
                                !result.error ?
                                    result.data.length !== 0 ?
                                        result.data.result.correct ?
                                            result.data.result.correct === "Y" ?
                                                <Correct
                                                    result={result.data.result}
                                                    description={result.data.result.message}
                                                >
                                                    {
                                                        result.data.step_type && (result.data.step_type === "CHECK" || result.data.step_type === "EXAM") ?
                                                            <ContinueLaterButtons loadingColor="primary" />
                                                            :
                                                            result.data.question && result.data.question.length !== 0 ?
                                                                <Button id="btn_trail_steps_questions_solution" variant="contained" className="btn-block-xs w-50"
                                                                    onClick={() => history.push({
                                                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                                                                        state: {
                                                                            origin: 'solution',
                                                                            step: step,
                                                                            session: sessionState,
                                                                            question: result.data
                                                                        }
                                                                    })}>
                                                                    {intl.get("BTN_CONTINUE")}
                                                                </Button>
                                                                :
                                                                <Button id="btn_trail_steps_questions_solution" variant="contained" className="btn-block-xs w-50"
                                                                    onClick={() => history.push({
                                                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                                                                        state: {
                                                                            origin: 'solution',
                                                                            step: step,
                                                                            session: sessionState,
                                                                            stepResult: result.data
                                                                        }
                                                                    })}>
                                                                    {intl.get("BTN_CONTINUE")}
                                                                </Button>
                                                    }
                                                </Correct>
                                                :
                                                <Incorrect
                                                    result={result.data.result}
                                                    description={result.data.result.message}
                                                >
                                                    {
                                                        result.data.step_type && (result.data.step_type === "CHECK" || result.data.step_type === "EXAM") ?
                                                            <ContinueLaterButtons loadingColor="primary" />
                                                            :
                                                            result.data.question && result.data.question.length !== 0 ?
                                                                <Button id="btn_trail_steps_questions_solution" variant="contained" className="btn-block-xs w-50"
                                                                    onClick={() => history.push({
                                                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                                                                        state: {
                                                                            origin: 'solution',
                                                                            step: step,
                                                                            session: sessionState,
                                                                            question: result.data
                                                                        }
                                                                    })}>
                                                                    {intl.get("BTN_CONTINUE")}
                                                                </Button>
                                                                :
                                                                <Button id="btn_trail_steps_questions_solution" variant="contained" className="btn-block-xs w-50"
                                                                    onClick={() => history.push({
                                                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                                                                        state: {
                                                                            origin: 'solution',
                                                                            step: step,
                                                                            session: sessionState,
                                                                            stepResult: result.data
                                                                        }
                                                                    })}>
                                                                    {intl.get("BTN_CONTINUE")}
                                                                </Button>
                                                    }
                                                </Incorrect>
                                            :
                                            <Continue
                                                result={result.data.result}
                                            >
                                                {
                                                    result.data.step_type && (result.data.step_type === "CHECK" || result.data.step_type === "EXAM") ?
                                                        <ContinueLaterButtons loadingColor="white" />
                                                        :
                                                        result.data.question && result.data.question.length !== 0 ?
                                                            <Button id="btn_trail_steps_questions_solution" variant="contained" className="btn-block-xs w-50"
                                                                onClick={() => history.push({
                                                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                                                                    state: {
                                                                        origin: 'solution',
                                                                        step: step,
                                                                        session: sessionState,
                                                                        question: result.data
                                                                    }
                                                                })}>
                                                                {intl.get("BTN_CONTINUE")}
                                                            </Button>
                                                            :
                                                            <Button id="btn_trail_steps_questions_solution" variant="contained" className="btn-block-xs w-50"
                                                                onClick={() => history.push({
                                                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                                                                    state: {
                                                                        origin: 'solution',
                                                                        step: step,
                                                                        session: sessionState,
                                                                        stepResult: result.data
                                                                    }
                                                                })}>
                                                                {intl.get("BTN_CONTINUE")}
                                                            </Button>
                                                }
                                            </Continue>
                                        :
                                        <Container maxWidth="md">
                                            <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS_CONTENT")} />
                                        </Container>
                                    :
                                    <Container maxWidth="md">
                                        <ErrorContent />
                                    </Container>
                                :
                                <Container maxWidth="md">
                                    <LoadingContent />
                                </Container>
                        }
                    </QrContent>
                </div>
            </div>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                state: {
                    origin: 'solution'
                }
            }} />
    )
}