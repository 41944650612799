import * as React from "react"

function ImgFeed4(props) {
    return (
        <svg width={132} height={44} viewBox="0 0 132 44" {...props}>
            <g transform="translate(4 4)" fill="none" fillRule="evenodd">
                <rect fill="#FFF" width={124} height={36} rx={10} />
                <ellipse
                    className="svg-feed-circle"
                    fillRule="nonzero"
                    cx={18.191}
                    cy={18.16}
                    rx={10.585}
                    ry={10.5}
                />
                <path
                    d="M16.2 12.7c-.66 0-1.2.483-1.271 1.1H14.2c-.547 0-1 .436-1 .962v1.65c0 .983.837 1.788 1.857 1.788h.014c.382 1.148 1.428 2.01 2.7 2.17v.855h-1c-1.024 0-1.857.802-1.857 1.787v.276a.42.42 0 00.429.412h5.714a.42.42 0 00.429-.412v-.276c0-.985-.833-1.787-1.857-1.787h-1v-.855c1.272-.16 2.318-1.022 2.7-2.17h.014c1.02 0 1.857-.805 1.857-1.787v-1.65c0-.527-.453-.963-1-.963h-.729c-.072-.617-.611-1.1-1.271-1.1h-4zm-2 1.925h.714v2.613c0 .042.005.082.007.124-.49-.063-.864-.458-.864-.95v-1.65c0-.08.059-.137.143-.137zm7.286 0h.714c.084 0 .143.056.143.137v1.65c0 .492-.373.887-.864.95.002-.042.007-.082.007-.124v-2.613z"
                    fill="#FFF"
                />
                <rect
                    className="svg-feed-text"
                    x={36.39}
                    y={10.248}
                    width={54.439}
                    height={4.5}
                    rx={2.25}
                />
                <rect
                    className="svg-feed-text-light"
                    x={36.204}
                    y={20.825}
                    width={58.976}
                    height={4.5}
                    rx={2.25}
                />
            </g>
        </svg>
    )
}

export default ImgFeed4
