import * as React from "react"

function ImgBriefWaitingResult(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M18 3h-5a1 1 0 11-2 0H6c-1.106 0-2 .894-2 2v16c0 1.105.894 2 2 2h12a2 2 0 002-2V5c0-1.106-.895-2-2-2"
                    fill="#455A64"
                />
                <path
                    d="M18 21.5H6a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v16a.5.5 0 01-.5.5"
                    fill="#FFF"
                />
                <path
                    d="M13 3a1 1 0 11-2 0H7.5v2a1 1 0 001 1h7a1 1 0 001-1V3H13z"
                    fill="#90A4AE"
                />
                <path
                    d="M12 1a2 2 0 10.001 4.001A2 2 0 0012 1m0 3a1 1 0 110-2 1 1 0 010 2"
                    fill="#90A4AE"
                />
                <path
                    d="M7 10h4.5v1H7v-1zm0 6h4.5v1H7v-1zm10-7v3h-3V9h3zm-1 1h-1v1h1v-1zm1 5v3h-3v-3h3zm-1 1h-1v1h1v-1z"
                    fill="#03A9F4"
                />
                <path
                    d="M16.024 9a7.024 7.024 0 100 14.049 7.024 7.024 0 000-14.049z"
                    fill="#F44336"
                />
                <path
                    d="M16.024 10.756a5.268 5.268 0 100 10.537 5.268 5.268 0 000-10.537z"
                    fill="#EEE"
                />
                <g fill="#000">
                    <path d="M15.439 11.927h1.17v4.097h-1.17z" />
                    <path d="M18.668 17.84l-.786.787-2.236-2.235.787-.787z" />
                    <path d="M16.024 15.146a.878.878 0 100 1.756.878.878 0 000-1.756z" />
                </g>
            </g>
        </svg>
    )
}

export default ImgBriefWaitingResult
