import React from 'react';
import ImgFeed1 from '../../../../../dist/img/intros/feeds/ImgFeed1';
import ImgFeed2 from '../../../../../dist/img/intros/feeds/ImgFeed2';
import ImgFeed3 from '../../../../../dist/img/intros/feeds/ImgFeed3';
import ImgFeed4 from '../../../../../dist/img/intros/feeds/ImgFeed4';
import ImgBubble1 from '../../../../../dist/img/intros/feeds/ImgBubble1';
import ImgBubble2 from '../../../../../dist/img/intros/feeds/ImgBubble2';
import ImgBubble3 from '../../../../../dist/img/intros/feeds/ImgBubble3';
import ImgBubble4 from '../../../../../dist/img/intros/feeds/ImgBubble4';
import ImgCircles from '../../../../../dist/img/intros/feeds/ImgCircles';
import ImgArrow from '../../../../../dist/img/intros/feeds/ImgArrow';

export default function FeedsIntro() {
    return (
        <div id="intro-slider-feeds">
            <div className="intro-content">
                <div className="feed1">
                    <ImgFeed1 />
                </div>
                <div className="feed2">
                    <ImgFeed2 />
                </div>
                <div className="feed3">
                    <ImgFeed3 />
                </div>
                <div className="feed4">
                    <ImgFeed4 />
                </div>
                <div className="bubble1">
                    <ImgBubble1 />
                </div>
                <div className="bubble2">
                    <ImgBubble2 />
                </div>
                <div className="bubble3">
                    <ImgBubble3 />
                </div>
                <div className="bubble4">
                    <ImgBubble4 />
                </div>
                <div className="arrow">
                    <ImgArrow />
                </div>
                <div className="circles">
                    <ImgCircles />
                </div>
            </div>
        </div>
    )
}
