import React from 'react'
import { CardMedia, Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';
import { Icon } from '../../../../components/Images/Images';

export default function CardFriendRedeem(props) {
    const { description, image, premium } = props;

    return (
        <Card className="card card-friend-redeem">
            <FeedCardHeader {...props} />
            <CardMedia
                className="card-image-full"
                image={image}
            >
                {
                    premium ?
                        <div className="badge-premium">
                            <Icon ic="crown_filled" />
                        </div>
                        : null
                }
            </CardMedia>
            <CardContent>
                <Typography variant="body2" component="p">
                    {description}
                </Typography>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
