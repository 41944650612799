import config from "../../config";

// CONST
export const Types = {
    MENU_DRAWER: 'qr_layout/MENU_DRAWER',
    SIDEBAR_DRAWER: 'qr_layout/SIDEBAR_DRAWER'
};

// INITIAL STATE
const initialState = {
    menuOpen: config.layout.menu.expanded,
    sidebarOpen: config.layout.sidebar.expanded
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.MENU_DRAWER:
            return {
                ...state,
                menuOpen: action.payload.menuOpen,
            }
        case Types.SIDEBAR_DRAWER:
            return {
                ...state,
                sidebarOpen: action.payload.sidebarOpen,
            }
        default:
            return state
    }
}

// ACTIONS
function menuDrawer(params) {
    return dispatch => {
        dispatch({
            type: Types.MENU_DRAWER,
            payload: {
                menuOpen: params.menuOpen
            }
        });
    }
}

function sidebarDrawer(params) {
    return dispatch => {
        dispatch({
            type: Types.SIDEBAR_DRAWER,
            payload: {
                sidebarOpen: params.sidebarOpen
            }
        });
    }
}

export const actions = {
    menuDrawer,
    sidebarDrawer
}
