import * as React from "react"

const ScormDoctors = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" transform="translate(3 2)">
            <path
                fill="#0669BC"
                d="M8.5 1.5H9V0h-.5a.5.5 0 0 0-.5.5V1a.5.5 0 0 0 .5.5ZM2 0h-.5v1.5H2a.5.5 0 0 0 .5-.5V.5A.5.5 0 0 0 2 0Z"
            />
            <path
                fill="#26ABE7"
                d="M15.5 10v3.75a4.75 4.75 0 1 1-9.5 0V10a4.5 4.5 0 0 0 4.5-4.5V1a1 1 0 0 0-1-1H9v5.5a3 3 0 0 1-3 3H4.5a3 3 0 0 1-3-3V0H1a1 1 0 0 0-1 1v4.5A4.5 4.5 0 0 0 4.5 10v3.75a6.25 6.25 0 1 0 12.5 0V10h-1.5Z"
            />
            <circle cx={16.25} cy={9.75} r={2.75} fill="#0B5CA8" />
            <circle cx={16.25} cy={9.75} r={1.25} fill="#45D7F9" />
        </g>
    </svg>
)

export default ScormDoctors
