import * as React from "react"

function ImgFlashcardContent(props) {
    return (
        <svg width={106} height={132} viewBox="0 0 106 132" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M11 4h84a6 6 0 016 6v112a6 6 0 01-6 6H11a6 6 0 01-6-6V10a6 6 0 016-6z"
                    fill="#FFF"
                />
                <g transform="translate(5 4)">
                    <path
                        d="M14.5 61h67a2.5 2.5 0 012.5 2.5v1a2.5 2.5 0 01-2.5 2.5h-67a2.5 2.5 0 01-2.5-2.5v-1a2.5 2.5 0 012.5-2.5zm5 12h57a2.5 2.5 0 012.5 2.5v1a2.5 2.5 0 01-2.5 2.5h-57a2.5 2.5 0 01-2.5-2.5v-1a2.5 2.5 0 012.5-2.5zm-3 12h63a2.5 2.5 0 012.5 2.5v1a2.5 2.5 0 01-2.5 2.5h-63a2.5 2.5 0 01-2.5-2.5v-1a2.5 2.5 0 012.5-2.5zm30 25h3a2.5 2.5 0 012.5 2.5v1a2.5 2.5 0 01-2.5 2.5h-3a2.5 2.5 0 01-2.5-2.5v-1a2.5 2.5 0 012.5-2.5zm36.912-1l-.875.874 2.665 2.664H77v1.166h8.202l-2.665 2.665.875.874 4.121-4.121L83.412 109z"
                        className="svg-text"
                    />
                    <rect className="svg-rectangle" x={8} y={8} width={80} height={44} rx={4} />
                    <path
                        d="M59.451 0L0 110V6a6 6 0 016-6h53.451z"
                        fill="#FFF"
                        opacity={0.177}
                    />
                    <path
                        d="M61 24h6v24h-6V24zm-8 8h6v16h-6V32zm-8-4h6v20h-6V28zm-8 10h6v10h-6V38zm-8-4h6v14h-6V34z"
                        className="svg-chart-bar"
                    />
                    <path
                        d="M64 12a3 3 0 11-1.103 5.79L58.9 19.767a3.001 3.001 0 01-5.558.629l-3.669-.906a2.999 2.999 0 01-2.06.486L43 24.012a3 3 0 01-5.658 1.38l-3.631-.927a3 3 0 11.956-3.84l3.655.888a2.986 2.986 0 012.064-.488L45 16.986A2.998 2.998 0 0148 14a3 3 0 012.651 1.596l3.658.925A2.986 2.986 0 0156 16a3 3 0 011.136.223l3.97-2.013A3.001 3.001 0 0164 12z"
                        className="svg-chart-line"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgFlashcardContent
