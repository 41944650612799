import React, { useState } from 'react';
import config from '../../../config';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Typography, Grid, IconButton } from '@material-ui/core';
import Intros from './Slides/Slider';
import { Icon, Logo } from '../../../components/Images/Images';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Carousel(props) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    let total = config.intro.slider.filter(obj => obj.enabled === true).length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Grid container justify="space-between" className="intro-slider-content">
            <Grid container direction="column" className="position-relative">
                <div className="intro-slider-logo d-md-none">
                    <Logo type={config.intro.logo.small_screen.type} height={config.intro.logo.small_screen.size} />
                </div>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    className="w-100 h-100"
                    interval={10000}
                    onChangeIndex={handleStepChange}
                    //autoplay={!isMouseEnter}
                    autoplay={true}
                    enableMouseEvents
                >
                    {
                        config.intro.slider.filter(obj => obj.enabled === true).map((item, index) => {
                            return (
                                <div key={item.type + index} hidden={config.intro.vector_images ? activeStep - index : false} className="h-100">
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Grid container id={"slide-" + (item.type.toLowerCase())} className={`h-100${!config.intro.vector_images ? ' __no-vector' : ''}`}>
                                            {
                                                !config.intro.vector_images ?
                                                    <div className="__filter"></div>
                                                    : null
                                            }
                                            <Grid container className="intro-slider-image">
                                                {
                                                    config.intro.vector_images ?
                                                        <Intros introName={item.type} />
                                                        : null
                                                }
                                            </Grid>
                                            <Grid container className="intro-slider-text" direction="row" justify="center" alignItems="flex-start">
                                                <Grid item xs={12} sm={10} className="intro-slider-text-content">
                                                    <Typography align="center" component="p">
                                                        {item.text}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </div>
                            )
                        })
                    }
                </AutoPlaySwipeableViews>
                {
                    total > 1 ?
                        <MobileStepper
                            className="stepper col-sm-10 offset-sm-1"
                            variant="dots"
                            steps={total}
                            activeStep={activeStep}
                            nextButton={
                                <IconButton onClick={handleNext} disabled={activeStep === total - 1} aria-label="Next">
                                    {theme.direction === 'rtl'
                                        ?
                                        <Icon ic="arrow_left" />
                                        :
                                        <Icon ic="arrow_right" />}
                                </IconButton>
                            }
                            backButton={
                                <IconButton onClick={handleBack} disabled={activeStep === 0} aria-label="Back">
                                    {theme.direction === 'rtl' ? <Icon ic="arrow_right" /> : <Icon ic="arrow_left" />}
                                </IconButton>
                            }
                        />
                        : null
                }

            </Grid>
        </Grid>
    );
}