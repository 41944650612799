import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from "../../config";
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import { AppBar, Toolbar, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { Icon } from '../../components/Images/Images';
import Details from './details';

export default function MyHistory(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_MY_HISTORY');

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }
    }, [auth, history]);

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('MY_HISTORY_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("MY_HISTORY_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="my-history-details" bottomNavigation={bottomMenuItem} hideSidebar>
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                                {
                                    <Switch>
                                        <Route path={`${props.match.path}`} exact component={(props) =>
                                            <Details {...props} />}
                                        />
                                    </Switch>
                                }
                            </div>
                        </QrContent>
                    </div>
                </div>
            }
        </div>

    )
};
