import { qrService } from './base.service';

function useHelp(params) {
    return qrService('/v4/app/help/use', params)
}

function setReport(params) {
    return qrService('/v2/app/question/report/generic', params)
}

function getReportList(params) {
    return qrService('/v1/app/report/reason/list', params)
}

function sendFileUpload(params) {
    return qrService('/v3/file/upload', params)
}

export const gameService = {
    useHelp,
    sendFileUpload,
    setReport,
    getReportList
}