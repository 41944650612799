import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { Grid, AppBar, Toolbar, IconButton, Typography, Container, Button } from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import { Icon, Ribbon } from '../../components/Images/Images';
import ImgGift from '../../dist/img/svgr/ImgGift';

export default function PrizeRedeem(props) {
    const { history } = useReactRouter();
    const [redirectPage, setRedirectPage] = useState(false);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        if (origin === "prize") {
            setRedirectPage(false);
        } else {
            setRedirectPage(true);
        }
    }, [origin]);

    function goBack() {
        let currentOrigin = "redeem";

        switch (origin) {
            case 'prize_details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/prizes/${props.match.params.idPrize}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden"></div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        !redirectPage ?
            <div id="qr-wrap">
                <QrMenu />
                <div id="qr-content">
                    <QrContent id="prizes-redeem" hideSidebar>
                        {
                            config.layout.navbar ?
                                <QrAppBar />
                                : null
                        }
                        <Container maxWidth="md" className="vh-100">
                            <Grid container justify="center" alignContent="center" className="h-100">
                                <Grid item xs={12} sm={10} lg={8} align="center" className="prize-gift">
                                    <ImgGift />
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} align="center" className="prize-ribbon">
                                    <Ribbon
                                        type="straight-filled"
                                        text={intl.get("PRIZES_REDEEM_TITLE")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} align="center" className="prize-text">
                                    <Typography variant="body1" component="p">
                                        {`${intl.get('PRIZES_REDEEM_TEXT')} "${intl.get('MENU_MY_REDEEMS')}".`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} align="center">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={10} sm={8} lg={6}>
                                            <Button
                                                id="btn_prize_redeem_my_redeems"
                                                variant="contained"
                                                className="btn-block"
                                                onClick={() => history.push({
                                                    pathname: `${props.match.path.replace(/./g, '')}/my-redeems`,
                                                    state: {
                                                        origin: 'redeem'
                                                    }
                                                })}
                                            >
                                                <Icon ic="shopping_bag_filled" className="mr-2" /> {`${intl.get('BTN_GO_TO')} ${intl.get('MENU_MY_REDEEMS')}`}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </QrContent>
                </div>
            </div>
            :
            <Redirect to={history.goBack()} />
    )
}