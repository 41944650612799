import React from 'react';
import ImgHands from '../../../../../dist/img/intros/scorm/ImgHands';
import ImgBalloon1 from '../../../../../dist/img/intros/scorm/ImgBalloon1';
import ImgBalloon2 from '../../../../../dist/img/intros/scorm/ImgBalloon2';
import ImgBalloon3 from '../../../../../dist/img/intros/scorm/ImgBalloon3';

export default function ScormIntro() {
    return (
        <div id="intro-slider-scorm">
            <div className="intro-content">
                <div className="hands">
                    <ImgHands />
                </div>
                <div className="balloon1">
                    <ImgBalloon1 />
                </div>
                <div className="balloon2">
                    <ImgBalloon2 />
                </div>
                <div className="balloon3">
                    <ImgBalloon3 />
                </div>
            </div>
        </div>
    )
}
