import * as React from "react"

function PeriodicQuestion(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          d="M2 20.947V6.211h20v14.736c0 .58-.5 1.053-1.111 1.053H3.11C2.5 22 2 21.526 2 20.947z"
          fill="#2B97DD"
        />
        <path
          d="M22 4.105v3.158H2V4.105c0-.579.5-1.052 1.111-1.052H20.89c.611 0 1.111.473 1.111 1.052z"
          fill="#247CB5"
        />
        <path
          d="M5.611 5.684c-.46 0-.833-.353-.833-.79V2.79c0-.435.373-.789.833-.789.46 0 .833.354.833.79v2.105c0 .436-.373.79-.833.79zm12.778 0c-.46 0-.833-.353-.833-.79V2.79c0-.435.373-.789.833-.789.46 0 .833.354.833.79v2.105c0 .436-.373.79-.833.79z"
          fill="#51A9E3"
        />
        <path
          d="M11.93 15.738a.81.81 0 00.511-.168.744.744 0 00.273-.476c.047-.196.152-.385.315-.567.163-.182.399-.399.707-.651.327-.29.593-.537.798-.742a2.84 2.84 0 00.525-.742c.145-.29.217-.607.217-.952 0-.467-.135-.884-.406-1.253-.27-.369-.644-.658-1.12-.868-.476-.21-1.017-.315-1.624-.315-.541 0-1.08.086-1.617.259a4.991 4.991 0 00-1.435.721 1.214 1.214 0 00-.343.378 1.03 1.03 0 00-.105.49c0 .29.082.534.245.735.163.2.362.301.595.301.196 0 .453-.089.77-.266l.336-.168c.261-.15.497-.264.707-.343.21-.08.422-.119.637-.119.261 0 .467.06.616.182.15.121.224.285.224.49 0 .205-.054.392-.161.56a4.583 4.583 0 01-.483.602 4.597 4.597 0 00-.7.903c-.168.294-.252.66-.252 1.099 0 .29.068.513.203.672.135.159.324.238.567.238zm.028 3.346c.392 0 .716-.13.973-.392.257-.261.385-.583.385-.966s-.128-.705-.385-.966a1.306 1.306 0 00-.973-.392c-.383 0-.702.13-.959.392a1.325 1.325 0 00-.385.966c0 .383.128.705.385.966s.576.392.959.392z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  )
}

export default PeriodicQuestion
