import * as React from "react"
import Typography from '@material-ui/core/Typography';

function FreeDifficultyQis(props) {
    return (
        <div className="svg-difficulty">
            <div className="svg-difficulty-text">
                <Typography variant="h3" component="h2" noWrap>
                    {props.text}
                </Typography>
            </div>
            <svg width={84} height={100} viewBox="0 0 84 100" className="svg-shadow">
                <defs>
                    <path id="prefix__a" d="M0 0h84v100H0z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <g mask="url(#prefix__b)">
                        <path
                            d="M47.51 70.45c-15.998 0-36.114 2.077-35.62-35.322.404-30.593 19.622-30.583 35.62-30.583 15.996 0 28.965 14.753 28.965 32.951 0 18.201-12.97 32.954-28.966 32.954z"
                            fill="#F29500"
                        />
                        <path
                            d="M35.477 70.45C19.48 70.45 6.51 55.697 6.51 37.496c0-18.198 12.969-32.951 28.966-32.951 15.996 0 28.965 14.753 28.965 32.951 0 18.201-12.969 32.954-28.965 32.954"
                            fill="#FFBF00"
                        />
                        <path
                            d="M35.477 4.143c-16.23 0-29.366 14.945-29.366 33.354 0 18.41 13.135 33.354 29.366 33.354 16.23 0 29.365-14.944 29.365-33.354 0-18.41-13.136-33.354-29.365-33.354zm0 .8c15.763 0 28.565 14.565 28.565 32.554 0 17.99-12.801 32.554-28.565 32.554-15.764 0-28.566-14.564-28.566-32.554 0-17.989 12.803-32.554 28.566-32.554z"
                            fill="#F29500"
                            fillRule="nonzero"
                        />
                        <path
                            d="M35.013 66.758c-14.045 0-25.43-13.062-25.43-29.173 0-16.111 11.385-29.173 25.43-29.173 14.044 0 25.43 13.062 25.43 29.173 0 16.111-11.386 29.173-25.43 29.173"
                            fill="#FFC619"
                        />
                        <path
                            d="M70 64c5.523 0 10 4.477 10 10v10c0 5.523-4.477 10-10 10H14C8.477 94 4 89.523 4 84V74c0-5.523 4.477-10 10-10h56z"
                            className="svg-difficulty-bar"
                        />
                        <path
                            d="M70 65H14a9 9 0 00-8.996 8.735L5 74v10a9 9 0 008.735 8.996L14 93h56a9 9 0 008.996-8.735L79 84V74a9 9 0 00-8.735-8.996L70 65z"
                            fill="#FFF"
                        />
                        <path
                            d="M67.73 67H16.27c-4.486 0-8.137 3.332-8.266 7.487L8 74.714v8.572c0 4.184 3.572 7.59 8.026 7.71l.244.004h51.46c4.486 0 8.137-3.332 8.266-7.487l.004-.227v-8.572c0-4.184-3.572-7.59-8.026-7.71L67.73 67z"
                            fillOpacity={0.04}
                            fill="#000"
                        />
                        <path
                            d="M68.486 67l.252.003c4.497.118 8.12 3.397 8.258 7.47l.004.241v8.572l-.004.227c-.13 4.076-3.748 7.359-8.244 7.483l-.266.004H15.514l-.252-.003c-4.497-.118-8.12-3.397-8.258-7.47L7 83.285v-8.572l.004-.227c.13-4.076 3.748-7.359 8.244-7.483l.266-.004h52.972zm.26 1.003l-.25-.003H15.49c-3.984 0-7.232 2.787-7.476 6.274l-.011.228-.004.212v8.572c0 3.54 3.075 6.477 6.996 6.7l.257.01.238.004h53.018c3.984 0 7.232-2.786 7.476-6.274l.011-.228.004-.212v-8.572c0-3.54-3.075-6.477-6.996-6.7l-.257-.01z"
                            className="svg-difficulty-bar"
                        />
                        <path
                            d="M41.58 81.92c.24 0 .443-.07.61-.21.167-.14.277-.337.33-.59a2.26 2.26 0 01.48-1.05c.253-.313.633-.703 1.14-1.17.453-.44.813-.81 1.08-1.11.267-.3.497-.653.69-1.06a3.11 3.11 0 00.29-1.35c0-.64-.183-1.217-.55-1.73-.367-.513-.88-.917-1.54-1.21-.66-.293-1.403-.44-2.23-.44-.773 0-1.543.13-2.31.39a6.67 6.67 0 00-2.03 1.09 1.476 1.476 0 00-.41.47 1.31 1.31 0 00-.13.61c0 .347.097.643.29.89.193.247.43.37.71.37.253 0 .573-.107.96-.32.613-.36 1.103-.627 1.47-.8.367-.173.763-.26 1.19-.26.467 0 .837.11 1.11.33.273.22.41.517.41.89 0 .347-.083.657-.25.93-.167.273-.43.617-.79 1.03-.48.547-.843 1.047-1.09 1.5-.247.453-.37 1.02-.37 1.7 0 .333.083.6.25.8.167.2.397.3.69.3zm.04 4.48c.48 0 .88-.163 1.2-.49.32-.327.48-.737.48-1.23s-.16-.903-.48-1.23a1.61 1.61 0 00-1.2-.49c-.493 0-.9.163-1.22.49-.32.327-.48.737-.48 1.23s.16.903.48 1.23c.32.327.727.49 1.22.49z"
                            className="svg-difficulty-level-active"
                        />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default FreeDifficultyQis
