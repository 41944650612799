import * as React from "react"

function ImgGifts(props) {
    return (
        <svg width={292} height={180} viewBox="0 0 292 180" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    className="svg-gift-top-shade"
                    d="M107.407 60.484l.222 4.407.843 16.843 4.515 98.63h51.612l-2.012-97.372-.412-18.1-.1-4.408z"
                />
                <path
                    className="svg-gift-top"
                    d="M162.075 60.484l.1 4.407.412 18.1 2.013 97.373h51.376l4.515-98.649.842-16.824.223-4.407z"
                />
                <path
                    fill="#000"
                    opacity={0.1}
                    d="M221.335 64.891l-.846 16.825-29.68.656-4.03 85.012.449-84.935-24.64.545-21.634-.429.445 84.819-4.03-84.907-28.898-.742-.842-16.844z"
                />
                <path
                    className="svg-gift-top"
                    d="M225.582 59.574l-1.2 16.733-64.501 1.42-.41-18.153z"
                />
                <path
                    className="svg-gift-top-shade"
                    d="M103.286 59.574l1.2 16.733 55.395 1.42-.41-18.153z"
                />
                <path
                    className="svg-gift-top"
                    d="M159.586 59.574h66.129l-50.104-4.001-72.325 4z"
                />
                <path className="svg-gift-top-ribbon" d="M191.943 59.574l-6 120.79h13.105l6-120.79z" />
                <path className="svg-gift-top-ribbon-shade" d="M123.116 59.574l6 120.79h13.104l-6-120.79z" />
                <path
                    className="svg-gift-top-ribbon"
                    d="M123.116 59.574l33.57-2.392 3.927-.354 8.157.152 4.968-.152 8.167.563 23.143 2.183h-13.105l-28.053-1.49-27.67 1.49z"
                />
                <path
                    d="M212.01 46.224a10.41 10.41 0 01-2.347 2.385c-2.52 1.846-5.575 2.692-8.47 3.66-5.674 1.896-11.182 4.162-17.06 5.34-2.991.6-6.158.23-9.184.157l-10.215-.245c1.813-1.379 4.109-1.556 6.193-2.149 3.92-1.115 7.95-1.738 11.851-2.925 4.827-1.469 9.546-3.572 13.771-6.43.71-.48 1.416-.99 1.955-1.67.65-.817 1.243-2.362.601-3.361-.577-.899-1.509-.15-2.332-.331.981-2.135 3.79-3.719 5.875-4.334 3.69-1.089 9.2-.234 10.62 4.067.683 2.067-.076 4.15-1.257 5.836"
                    className="svg-gift-top-ribbon-shade"
                />
                <path
                    d="M213.542 41.896c-3.204-2.049-7.147-2.794-10.835-2.057-1.79.36-3.508 1.045-5.208 1.731-2.103.849-4.197 1.688-6.3 2.537-3.853 1.552-7.755 3.128-11.452 5.057-3.096 1.603-6.053 3.454-8.723 5.734-1.002.848-1.98 1.766-3.17 2.263-1.595.66-3.393.488-5.077.103.862-1.534 1.724-3.06 2.587-4.595.986-1.765 1.98-3.522 3.09-5.202a45.888 45.888 0 012.956-3.951c.008 0 .008-.01.008-.01 3.13-3.727 6.786-6.967 10.597-9.993a219.281 219.281 0 015.126-3.943c1.881-1.414 3.795-2.837 5.972-3.685 2.168-.849 4.665-1.08 6.784-.103 1.15.531 2.137 1.38 3.106 2.228l6.292 5.486c.83.72 1.668 1.448 2.349 2.323.098.128.189.257.28.385a9.183 9.183 0 011.618 5.692"
                    className="svg-gift-top-ribbon"
                />
                <path
                    d="M118.63 46.224c.655.934 1.48 1.75 2.348 2.385 2.52 1.846 5.575 2.692 8.47 3.66 5.674 1.896 11.183 4.162 17.06 5.34 2.992.6 6.158.23 9.184.157l10.215-.245c-1.813-1.379-4.108-1.556-6.193-2.149-3.919-1.115-7.95-1.738-11.85-2.925-4.828-1.469-9.547-3.572-13.773-6.43-.71-.48-1.415-.99-1.954-1.67-.65-.817-1.244-2.362-.602-3.361.578-.899 1.51-.15 2.333-.331-.981-2.135-3.79-3.719-5.875-4.334-3.69-1.089-9.2-.234-10.62 4.067-.683 2.067.076 4.15 1.258 5.836"
                    className="svg-gift-top-ribbon-shade"
                />
                <path
                    d="M117.1 41.896c3.203-2.049 7.146-2.794 10.834-2.057 1.791.36 3.508 1.045 5.208 1.731 2.103.849 4.198 1.688 6.301 2.537 3.853 1.552 7.754 3.128 11.45 5.057 3.097 1.603 6.055 3.454 8.724 5.734 1.002.848 1.98 1.766 3.171 2.263 1.593.66 3.392.488 5.076.103-.862-1.534-1.725-3.06-2.587-4.595-.986-1.765-1.98-3.522-3.089-5.202a45.888 45.888 0 00-2.957-3.951c-.009 0-.009-.01-.009-.01-3.129-3.727-6.784-6.967-10.596-9.993a220.66 220.66 0 00-5.126-3.943c-1.88-1.414-3.795-2.837-5.972-3.685-2.168-.849-4.665-1.08-6.785-.103-1.15.531-2.135 1.38-3.104 2.228l-6.293 5.486c-.83.72-1.667 1.448-2.35 2.323-.098.128-.188.257-.278.385a9.179 9.179 0 00-1.619 5.692"
                    className="svg-gift-top-ribbon"
                />
                <path
                    d="M170.57 54.948c0 2.423-2.509 2.997-5.407 2.997-2.899 0-5.091-.574-5.091-2.997s2.35-4.388 5.248-4.388c2.9 0 5.25 1.965 5.25 4.388"
                    className="svg-gift-top-ribbon"
                />
                <path
                    d="M170.371 56.004c-.642 1.54-2.792 1.939-5.206 1.939-2.902 0-5.094-.575-5.094-2.993 0-1.702 1.156-3.173 2.846-3.9a3.834 3.834 0 00-.8 2.322c0 2.424 2.194 2.999 5.094 2.999 1.162 0 2.259-.096 3.16-.367"
                    className="svg-gift-top-ribbon-shade"
                />
                <path
                    d="M165.674 52.213c-.11-.222-.139-.48-.275-.676-.23-.33-.595-.3-.942-.342-.435-.05-.886-.125-1.32-.026a9.524 9.524 0 011.108-.187c.484-.054.972-.07 1.459-.068a4.457 4.457 0 012.128.568c.337.188.651.418.933.686.325.31.442.668.648 1.06.183.348.244.744.409 1.089-.176-.367-.377-.727-.658-1.012-.283-.285-.657-.489-1.05-.488-.64.001-1.175.344-1.807.016a1.568 1.568 0 01-.573-.517.925.925 0 01-.06-.103"
                    fillOpacity={0.10}
                    fill="#FFF"
                />
                <path
                    className="svg-gift-left-shade"
                    d="M69.265 180.033H17.693l-4.51-74.926-.843-12.796-.222-3.348h54.624l.1 3.348.411 13.75z"
                />
                <path
                    className="svg-gift-left"
                    d="M126.175 88.963l-.223 3.348-.842 12.78-4.51 74.942H69.265l-2.012-73.972-.412-13.75-.099-3.348z"
                />
                <path
                    fillOpacity={0.10}
                    fill="#000"
                    d="M125.951 92.312l-.842 12.781-29.792.5-4.028 64.579.445-64.52-24.482.41-21.486-.323.44 64.433-4.023-64.502-29.002-.564-.842-12.794z"
                />
                <path
                    className="svg-gift-left"
                    d="M130.198 88.272l-1.2 12.712-64.449 1.078-.41-13.79z"
                />
                <path className="svg-gift-left-shade" d="M8 88.272l1.2 12.712 55.35 1.078-.41-13.79z" />
                <path className="svg-gift-left" d="M64.254 88.272h66.076l-50.063-3.04L8 88.272z" />
                <path
                    className="svg-gift-left-ribbon"
                    d="M96.586 88.272l-5.995 91.76h13.094l5.995-91.76z"
                />
                <path
                    className="svg-gift-left-ribbon-shade"
                    d="M40.908 88.272l5.995 91.76H33.809l-5.994-91.76z"
                />
                <path
                    className="svg-gift-left-ribbon-shade"
                    d="M27.814 88.272l33.543-1.817 3.924-.27 8.15.116 4.964-.115 8.16.427 23.125 1.659H96.586l-28.031-1.133-27.647 1.133z"
                />
                <path
                    d="M110.98 76.565a9.165 9.165 0 01-2.056 2.105c-2.208 1.63-4.885 2.376-7.421 3.23-4.971 1.673-9.798 3.674-14.947 4.713-2.62.53-5.396.202-8.046.139l-8.95-.217c1.588-1.216 3.6-1.372 5.426-1.896 3.433-.984 6.965-1.534 10.382-2.582 4.23-1.296 8.364-3.152 12.066-5.675.622-.424 1.24-.875 1.713-1.474.57-.722 1.09-2.085.527-2.967-.506-.793-1.322-.132-2.044-.292.86-1.885 3.32-3.282 5.147-3.826 3.233-.96 8.06-.205 9.305 3.59.598 1.825-.066 3.663-1.102 5.152"
                    className="svg-gift-left-ribbon-shade"
                />
                <path
                    d="M112.322 72.744c-2.807-1.808-6.261-2.466-9.493-1.815-1.569.317-3.073.922-4.563 1.528-1.842.749-3.677 1.49-5.52 2.24-3.375 1.368-6.794 2.76-10.032 4.462C80 80.574 77.41 82.21 75.07 84.22c-.878.75-1.735 1.56-2.778 1.998-1.397.582-2.973.43-4.448.09.755-1.353 1.511-2.7 2.267-4.055.864-1.558 1.734-3.108 2.706-4.591a40.469 40.469 0 012.591-3.488c.007 0 .007-.007.007-.007 2.742-3.291 5.945-6.151 9.284-8.822a194.863 194.863 0 014.491-3.48c1.648-1.248 3.325-2.503 5.232-3.252 1.9-.75 4.088-.954 5.945-.091 1.007.469 1.871 1.218 2.72 1.966l5.513 4.843c.727.635 1.46 1.278 2.058 2.05a8.145 8.145 0 011.663 5.364"
                    className="svg-gift-left-ribbon"
                />
                <path
                    d="M29.167 76.565a9.152 9.152 0 002.057 2.105c2.208 1.63 4.884 2.376 7.42 3.23 4.972 1.673 9.797 3.674 14.948 4.713 2.62.53 5.395.202 8.046.139l8.949-.217c-1.588-1.216-3.6-1.372-5.425-1.896-3.434-.984-6.966-1.534-10.384-2.582-4.229-1.296-8.363-3.152-12.065-5.675-.622-.424-1.24-.875-1.713-1.474-.57-.722-1.09-2.085-.527-2.967.507-.793 1.322-.132 2.044-.292-.86-1.885-3.32-3.282-5.147-3.826-3.232-.96-8.06-.205-9.305 3.59-.598 1.825.066 3.663 1.102 5.152"
                    className="svg-gift-left-ribbon-shade"
                />
                <path
                    d="M27.825 72.744c2.807-1.808 6.262-2.466 9.493-1.815 1.57.317 3.073.922 4.563 1.528 1.842.749 3.678 1.49 5.52 2.24 3.375 1.368 6.794 2.76 10.033 4.462 2.712 1.415 5.303 3.05 7.642 5.061.879.75 1.735 1.56 2.778 1.998 1.397.582 2.973.43 4.448.09l-2.267-4.055c-.863-1.558-1.734-3.108-2.706-4.591a40.469 40.469 0 00-2.59-3.488c-.007 0-.007-.007-.007-.007-2.743-3.291-5.945-6.151-9.285-8.822a193.47 193.47 0 00-4.49-3.48c-1.649-1.248-3.325-2.503-5.233-3.252-1.9-.75-4.088-.954-5.945-.091-1.007.469-1.87 1.218-2.72 1.966l-5.513 4.843c-.727.635-1.461 1.278-2.058 2.05a8.145 8.145 0 00-1.662 5.364"
                    className="svg-gift-left-ribbon"
                />
                <path
                    d="M74.672 84.264c0 2.14-2.197 2.646-4.737 2.646s-4.46-.507-4.46-2.646c0-2.138 2.059-3.872 4.599-3.872s4.598 1.734 4.598 3.872"
                    className="svg-gift-left-ribbon"
                />
                <path
                    d="M74.499 85.197c-.563 1.358-2.446 1.711-4.562 1.711-2.542 0-4.463-.507-4.463-2.641 0-1.502 1.012-2.8 2.494-3.443a3.397 3.397 0 00-.7 2.05c0 2.139 1.92 2.646 4.462 2.646 1.018 0 1.979-.084 2.769-.323"
                    className="svg-gift-left-ribbon-shade"
                />
                <path
                    d="M70.383 81.85c-.096-.196-.121-.423-.241-.596-.202-.292-.521-.265-.825-.302-.382-.045-.776-.11-1.156-.023.32-.074.644-.129.97-.165.424-.047.852-.062 1.278-.06a3.88 3.88 0 011.865.501c.295.166.57.37.818.606.285.273.386.59.568.937.16.306.213.655.358.96-.155-.324-.33-.642-.577-.893-.248-.252-.575-.432-.92-.431-.561 0-1.029.303-1.583.014a1.378 1.378 0 01-.503-.457.773.773 0 01-.052-.09"
                    fillOpacity={0.10}
                    fill="#FFF"
                />
                <g>
                    <path
                        className="svg-gift-left-bottom-shade"
                        d="M95.624 180.767h-40.57l-3.548-46.129-.663-8.656-.175-2.266H93.64l.078 2.266.324 9.303z"
                    />
                    <path
                        className="svg-gift-left-bottom"
                        d="M140.394 123.716l-.175 2.265-.662 8.647-3.549 46.139H95.624l-1.582-45.482-.324-9.304-.078-2.265z"
                    />
                    <path
                        fillOpacity={0.10}
                        fill="#000"
                        d="M140.22 125.981l-.661 8.649-23.45.339-3.161 39.127.354-39.088-19.258.277-16.908-.216.35 39.027-3.157-39.077-22.823-.381-.661-8.657z"
                    />
                    <path
                        className="svg-gift-left-bottom"
                        d="M143.56 123.248l-.944 8.6-50.7.73-.323-9.33z"
                    />
                    <path
                        className="svg-gift-left-bottom-shade"
                        d="M47.429 123.248l.943 8.6 43.543.73-.323-9.33z"
                    />
                    <path
                        className="svg-gift-left-bottom"
                        d="M91.683 123.248h51.98l-39.383-2.057-56.851 2.057z"
                    />
                    <path
                        className="svg-gift-left-bottom-ribbon"
                        d="M117.117 123.248l-4.715 57.518h10.3l4.716-57.518z"
                    />
                    <path
                        className="svg-gift-left-bottom-ribbon-shade"
                        d="M73.317 123.248l4.716 57.518h-10.3l-4.716-57.518z"
                    />
                    <path
                        className="svg-gift-left-bottom-ribbon"
                        d="M63.016 123.248l26.387-1.229 3.088-.182 6.411.078 3.905-.078 6.42.29 18.19 1.121h-10.3l-22.052-.766-21.748.766z"
                    />
                    <path
                        d="M130.223 115.146a7.097 7.097 0 01-1.703 1.5c-1.828 1.16-4.043 1.69-6.142 2.298-4.114 1.192-8.11 2.616-12.372 3.356-2.168.376-4.465.143-6.658.098l-7.408-.154c1.314-.866 2.979-.977 4.49-1.35 2.842-.7 5.766-1.092 8.594-1.838 3.5-.922 6.922-2.244 9.987-4.04.514-.3 1.026-.622 1.418-1.049.47-.513.901-1.484.436-2.112-.42-.564-1.095-.094-1.692-.207.71-1.342 2.748-2.337 4.26-2.723 2.676-.685 6.671-.147 7.701 2.555.496 1.298-.054 2.607-.911 3.666"
                        className="svg-gift-left-bottom-ribbon-shade"
                    />
                    <path
                        d="M131.333 112.427c-2.323-1.287-5.182-1.756-7.857-1.293-1.299.227-2.543.658-3.777 1.088-1.524.534-3.044 1.061-4.568 1.594-2.794.975-5.624 1.966-8.304 3.178-2.246 1.007-4.39 2.17-6.326 3.602-.727.533-1.436 1.11-2.3 1.422-1.155.414-2.46.307-3.68.065.624-.964 1.25-1.923 1.876-2.887.714-1.11 1.435-2.213 2.239-3.269a29.739 29.739 0 012.145-2.482c.006 0 .006-.006.006-.006 2.27-2.342 4.92-4.378 7.684-6.279a160.497 160.497 0 013.717-2.477c1.364-.889 2.752-1.782 4.33-2.316 1.573-.533 3.384-.678 4.92-.064.835.334 1.55.867 2.252 1.4l4.563 3.446c.602.453 1.21.91 1.704 1.46.071.08.137.162.202.242.829 1.023 1.252 2.31 1.174 3.576"
                        className="svg-gift-left-bottom-ribbon"
                    />
                    <path
                        d="M62.507 115.146a7.097 7.097 0 001.702 1.5c1.828 1.16 4.043 1.69 6.142 2.298 4.115 1.192 8.11 2.616 12.372 3.356 2.169.376 4.465.143 6.66.098l7.407-.154c-1.315-.866-2.98-.977-4.49-1.35-2.843-.7-5.766-1.092-8.594-1.838-3.501-.922-6.923-2.244-9.988-4.04-.514-.3-1.026-.622-1.417-1.049-.471-.513-.901-1.484-.436-2.112.419-.564 1.094-.094 1.692-.207-.711-1.342-2.749-2.337-4.26-2.723-2.676-.685-6.672-.147-7.702 2.555-.495 1.298.055 2.607.912 3.666"
                        className="svg-gift-left-bottom-ribbon-shade"
                    />
                    <path
                        d="M61.397 112.427c2.323-1.287 5.182-1.756 7.857-1.293 1.298.227 2.543.658 3.776 1.088 1.525.534 3.044 1.061 4.57 1.594 2.793.975 5.622 1.966 8.302 3.178 2.246 1.007 4.39 2.17 6.327 3.602.727.533 1.435 1.11 2.3 1.422 1.155.414 2.46.307 3.68.065-.625-.964-1.25-1.923-1.876-2.887-.715-1.11-1.436-2.213-2.24-3.269a29.61 29.61 0 00-2.144-2.482c-.007 0-.007-.006-.007-.006-2.269-2.342-4.92-4.378-7.683-6.279a161.907 161.907 0 00-3.717-2.477c-1.364-.889-2.753-1.782-4.33-2.316-1.574-.533-3.385-.678-4.921-.064-.834.334-1.549.867-2.252 1.4l-4.563 3.446c-.601.453-1.21.91-1.704 1.46-.071.08-.136.162-.202.242-.828 1.023-1.25 2.31-1.173 3.576"
                        className="svg-gift-left-bottom-ribbon"
                    />
                    <path
                        d="M100.17 120.628c0 1.522-1.817 1.883-3.92 1.883-2.102 0-3.691-.361-3.691-1.883 0-1.523 1.703-2.757 3.806-2.757 2.102 0 3.806 1.234 3.806 2.757"
                        className="svg-gift-left-bottom-ribbon"
                    />
                    <path
                        d="M100.028 121.291c-.466.968-2.025 1.219-3.777 1.219-2.103 0-3.693-.361-3.693-1.88 0-1.07.838-1.995 2.064-2.451-.368.421-.58.924-.58 1.459 0 1.522 1.59 1.883 3.694 1.883.842 0 1.638-.06 2.292-.23"
                        className="svg-gift-left-bottom-ribbon-shade"
                    />
                    <path
                        d="M96.621 118.91c-.08-.14-.1-.302-.2-.426-.167-.208-.431-.189-.682-.214-.316-.032-.643-.08-.958-.016.265-.053.533-.093.803-.118.351-.034.705-.044 1.059-.042a3.63 3.63 0 011.543.355c.244.119.472.264.677.432.235.194.32.42.47.666.132.219.176.467.296.684-.127-.23-.273-.457-.477-.636a1.18 1.18 0 00-.76-.306c-.466 0-.853.215-1.312.01a1.106 1.106 0 01-.416-.325.599.599 0 01-.043-.065"
                        fillOpacity={0.10}
                        fill="#FFF"
                    />
                </g>
                <g>
                    <path
                        className="svg-gift-right-shade"
                        d="M228.812 180.369h-45.74l-4-67.041-.747-11.449-.196-2.996h48.446l.089 2.996.365 12.303z"
                    />
                    <path
                        className="svg-gift-right"
                        d="M279.287 98.883l-.198 2.996-.747 11.435-4 67.055h-45.53l-1.783-66.187-.366-12.303-.087-2.996z"
                    />
                    <path
                        fillOpacity={0.10}
                        fill="#000"
                        d="M279.088 101.88l-.747 11.436-26.423.446-3.572 57.784.394-57.73-21.713.367-19.057-.29.392 57.653-3.57-57.714-25.72-.505-.748-11.447z"
                    />
                    <path
                        className="svg-gift-right"
                        d="M282.855 98.264l-1.064 11.375-57.16.964-.364-12.339z"
                    />
                    <path
                        className="svg-gift-right-shade"
                        d="M174.476 98.264l1.064 11.375 49.09.964-.364-12.339z"
                    />
                    <path
                        className="svg-gift-right"
                        d="M224.368 98.264h58.605l-44.402-2.72-64.095 2.72z"
                    />
                    <path
                        className="svg-gift-right-ribbon"
                        d="M253.044 98.264l-5.317 82.105h11.613l5.317-82.105z"
                    />
                    <path
                        className="svg-gift-right-ribbon-shade"
                        d="M203.663 98.264l5.316 82.105h-11.612l-5.317-82.105z"
                    />
                    <path
                        className="svg-gift-right-ribbon"
                        d="M192.05 98.264l29.75-1.625 3.48-.24 7.228.102 4.402-.103 7.238.383 20.51 1.483h-11.614l-24.86-1.013-24.52 1.013z"
                    />
                    <path
                        d="M265.81 87.79a8.16 8.16 0 01-1.823 1.883c-1.959 1.458-4.333 2.126-6.582 2.89-4.41 1.498-8.69 3.288-13.257 4.218-2.324.473-4.785.18-7.136.124l-7.937-.194c1.408-1.089 3.192-1.228 4.811-1.697 3.045-.88 6.179-1.372 9.209-2.31 3.752-1.16 7.417-2.82 10.701-5.078.552-.38 1.1-.783 1.52-1.319.504-.645.966-1.865.467-2.654-.45-.71-1.173-.118-1.813-.261.763-1.687 2.945-2.938 4.566-3.424 2.866-.86 7.148-.184 8.252 3.213.53 1.631-.059 3.277-.978 4.608"
                        className="svg-gift-right-ribbon-shade"
                    />
                    <path
                        d="M267 84.371c-2.49-1.618-5.553-2.207-8.419-1.625-1.391.285-2.725.826-4.047 1.368-1.634.67-3.262 1.333-4.895 2.003-2.994 1.225-6.026 2.471-8.899 3.994-2.406 1.266-4.704 2.728-6.779 4.529-.778.67-1.537 1.394-2.463 1.787-1.238.521-2.637.386-3.945.08l2.01-3.627c.767-1.395 1.54-2.782 2.401-4.11a36.174 36.174 0 012.298-3.12c.006 0 .006-.006.006-.006 2.432-2.945 5.272-5.504 8.234-7.893a174.07 174.07 0 013.983-3.114c1.462-1.117 2.95-2.24 4.641-2.91 1.685-.67 3.625-.853 5.272-.082.894.42 1.66 1.09 2.413 1.76l4.89 4.332c.645.57 1.295 1.144 1.825 1.834a7.334 7.334 0 011.475 4.8"
                        className="svg-gift-right-ribbon"
                    />
                    <path
                        d="M193.25 87.79a8.15 8.15 0 001.824 1.883c1.958 1.458 4.331 2.126 6.58 2.89 4.41 1.498 8.69 3.288 13.258 4.218 2.324.473 4.785.18 7.136.124l7.937-.194c-1.408-1.089-3.192-1.228-4.812-1.697-3.045-.88-6.177-1.372-9.208-2.31-3.752-1.16-7.418-2.82-10.702-5.078-.552-.38-1.1-.783-1.519-1.319-.504-.645-.966-1.865-.467-2.654.449-.71 1.172-.118 1.813-.261-.762-1.687-2.945-2.938-4.565-3.424-2.867-.86-7.15-.184-8.253 3.213-.53 1.631.058 3.277.977 4.608"
                        className="svg-gift-right-ribbon-shade"
                    />
                    <path
                        d="M192.06 84.371c2.489-1.618 5.552-2.207 8.418-1.625 1.392.285 2.726.826 4.047 1.368 1.635.67 3.263 1.333 4.896 2.003 2.994 1.225 6.025 2.471 8.898 3.994 2.407 1.266 4.705 2.728 6.78 4.529.778.67 1.537 1.394 2.463 1.787 1.238.521 2.636.386 3.945.08l-2.011-3.627c-.766-1.395-1.538-2.782-2.4-4.11a36.18 36.18 0 00-2.298-3.12c-.006 0-.006-.006-.006-.006-2.433-2.945-5.273-5.504-8.234-7.893a172.679 172.679 0 00-3.984-3.114c-1.461-1.117-2.948-2.24-4.64-2.91-1.685-.67-3.626-.853-5.272-.082-.894.42-1.66 1.09-2.413 1.76l-4.89 4.332c-.645.57-1.295 1.144-1.825 1.834a7.334 7.334 0 00-1.475 4.8"
                        className="svg-gift-right-ribbon"
                    />
                    <path
                        d="M233.608 94.679c0 1.913-1.948 2.367-4.2 2.367-2.253 0-3.956-.454-3.956-2.367s1.825-3.465 4.078-3.465c2.252 0 4.078 1.552 4.078 3.465"
                        className="svg-gift-right-ribbon"
                    />
                    <path
                        d="M233.455 95.514c-.5 1.215-2.17 1.53-4.046 1.53-2.255 0-3.959-.453-3.959-2.363 0-1.344.899-2.506 2.212-3.08a3.058 3.058 0 00-.621 1.833c0 1.915 1.705 2.369 3.958 2.369.902 0 1.755-.076 2.456-.29"
                        className="svg-gift-right-ribbon-shade"
                    />
                    <path
                        d="M229.805 92.52c-.086-.176-.108-.38-.215-.536-.179-.26-.462-.236-.732-.269-.337-.04-.688-.1-1.024-.02.283-.067.57-.116.86-.149a9.739 9.739 0 011.134-.052c.568.003 1.147.16 1.654.447.261.149.505.331.725.543.253.243.342.527.503.838.142.274.19.586.318.859-.137-.29-.293-.574-.512-.8-.219-.224-.51-.386-.815-.385-.498.001-.913.271-1.405.012a1.225 1.225 0 01-.491-.489"
                        fillOpacity={0.10}
                        fill="#FFF"
                    />
                </g>
                <g>
                    <path
                        className="svg-gift-bottom-shade"
                        d="M164.221 180.606H118.59l-4.775-34.936h48.246l.086 2.264.352 9.296z"
                    />
                    <path
                        className="svg-gift-bottom"
                        d="M214.882 145.67l-4.775 34.937H164.22l-1.723-23.377-.352-9.296-.085-2.264z"
                    />
                    <path
                        fillOpacity={0.12}
                        fill="#000"
                        d="M214.628 147.934l-100.54-.034 1.214 8.65 25.745.408 2.036 18.56.989-18.512 18.427.224 20.97-.27-.134 17.826 2.597-17.858 27.435-.354z"
                    />
                    <path
                        className="svg-gift-bottom"
                        d="M216.42 145.203l-1.027 8.594-55.21.729-.353-9.323z"
                    />
                    <path
                        className="svg-gift-bottom-shade"
                        d="M112.048 145.203l.718 8.594 47.416.729-.351-9.323z"
                    />
                    <path
                        className="svg-gift-bottom"
                        d="M159.93 145.203h56.603l-42.885-2.055-61.6 2.055z"
                    />
                    <path
                        className="svg-gift-bottom-ribbon"
                        d="M187.627 145.203l-5.136 35.404h11.216l5.136-35.404z"
                    />
                    <path
                        className="svg-gift-bottom-ribbon-shade"
                        d="M139.93 145.203l3.683 35.404h-11.217l-3.682-35.404z"
                    />
                    <path
                        className="svg-gift-bottom-ribbon"
                        d="M128.713 145.203l28.735-1.228 3.361-.182 6.981.078 4.253-.078 6.99.29 19.81 1.12h-11.216l-24.013-.766-23.684.766z"
                    />
                    <path
                        d="M197.729 137.108a6.956 6.956 0 01-1.65 1.497c-1.77 1.16-3.916 1.69-5.95 2.297-3.986 1.19-7.855 2.614-11.984 3.353-2.101.376-4.327.144-6.452.098-2.391-.05-4.784-.102-7.175-.154 1.273-.865 2.886-.976 4.35-1.347 2.753-.701 5.584-1.093 8.325-1.837 3.391-.923 6.706-2.243 9.675-4.038.498-.3.994-.62 1.372-1.048.457-.513.874-1.482.422-2.11-.405-.564-1.06-.094-1.638-.207.69-1.341 2.662-2.335 4.127-2.721 2.592-.684 6.463-.147 7.46 2.553.48 1.297-.052 2.605-.882 3.664"
                        className="svg-gift-bottom-ribbon-shade"
                    />
                    <path
                        d="M198.804 134.39c-2.25-1.286-5.02-1.754-7.611-1.291-1.258.226-2.464.657-3.659 1.087-1.477.533-2.949 1.06-4.425 1.592-2.707.975-5.448 1.965-8.044 3.176-2.176 1.006-4.254 2.168-6.129 3.6-.704.532-1.39 1.108-2.228 1.42-1.119.414-2.382.307-3.566.064.607-.963 1.213-1.92 1.818-2.883.692-1.11 1.39-2.212 2.17-3.267a29.35 29.35 0 012.077-2.48c.006 0 .006-.006.006-.006 2.199-2.34 4.766-4.374 7.444-6.274a156.591 156.591 0 013.6-2.476c1.323-.887 2.667-1.78 4.196-2.313 1.523-.533 3.278-.678 4.766-.064.808.333 1.501.865 2.182 1.398l4.42 3.444c.583.452 1.171.91 1.65 1.458.07.081.133.162.196.243a5.322 5.322 0 011.137 3.572"
                        className="svg-gift-bottom-ribbon"
                    />
                    <path
                        d="M132.13 137.108a6.944 6.944 0 001.65 1.497c1.77 1.16 3.916 1.69 5.95 2.297 3.986 1.19 7.855 2.614 11.984 3.353 2.1.376 4.326.144 6.451.098 2.392-.05 4.784-.102 7.176-.154-1.273-.865-2.886-.976-4.35-1.347-2.753-.701-5.585-1.093-8.325-1.837-3.392-.923-6.706-2.243-9.675-4.038-.498-.3-.994-.62-1.373-1.048-.456-.513-.873-1.482-.422-2.11.406-.564 1.06-.094 1.638-.207-.689-1.341-2.662-2.335-4.126-2.721-2.592-.684-6.463-.147-7.461 2.553-.48 1.297.053 2.605.883 3.664"
                        className="svg-gift-bottom-ribbon-shade"
                    />
                    <path
                        d="M131.055 134.39c2.25-1.286 5.02-1.754 7.61-1.291 1.26.226 2.465.657 3.66 1.087 1.477.533 2.949 1.06 4.426 1.592 2.706.975 5.447 1.965 8.044 3.176 2.175 1.006 4.252 2.168 6.128 3.6.704.532 1.39 1.108 2.227 1.42 1.12.414 2.384.307 3.566.064-.605-.963-1.211-1.92-1.818-2.883-.692-1.11-1.39-2.212-2.169-3.267a29.477 29.477 0 00-2.077-2.48c-.006 0-.006-.006-.006-.006-2.198-2.34-4.767-4.374-7.444-6.274a155.227 155.227 0 00-3.6-2.476c-1.322-.887-2.667-1.78-4.196-2.313-1.523-.533-3.278-.678-4.766-.064-.809.333-1.5.865-2.181 1.398l-4.421 3.444c-.583.452-1.17.91-1.65 1.458-.07.081-.133.162-.197.243a5.325 5.325 0 00-1.136 3.572"
                        className="svg-gift-bottom-ribbon"
                    />
                    <path
                        d="M168.617 142.584c0 1.522-1.762 1.882-3.798 1.882-2.037 0-3.576-.36-3.576-1.882 0-1.52 1.65-2.754 3.686-2.754 2.037 0 3.688 1.233 3.688 2.754"
                        className="svg-gift-bottom-ribbon"
                    />
                    <path
                        d="M168.478 143.248c-.452.966-1.962 1.217-3.659 1.217-2.037 0-3.578-.36-3.578-1.879 0-1.068.812-1.992 2-2.448-.357.42-.561.923-.561 1.457 0 1.522 1.54 1.883 3.578 1.883.816 0 1.586-.06 2.22-.23"
                        className="svg-gift-bottom-ribbon-shade"
                    />
                    <path
                        d="M165.178 140.868c-.078-.14-.097-.302-.194-.426-.162-.207-.418-.188-.66-.214-.307-.032-.623-.078-.928-.015.256-.053.516-.092.777-.118.34-.034.683-.045 1.025-.043a3.424 3.424 0 011.496.356c.236.118.457.264.655.432.229.193.31.419.456.665.128.219.17.467.287.683-.123-.23-.265-.456-.463-.635a1.122 1.122 0 00-.736-.306c-.451 0-.826.216-1.27.01a1.082 1.082 0 01-.404-.325.7.7 0 01-.041-.064"
                        fillOpacity={0.10}
                        fill="#FFF"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgGifts
