import { qrService } from './base.service';

function getList(params) {
    return qrService('/v3/app/prize/list', params)
}

function getPrizeDetails(params) {
    return qrService('/v3/app/prize/detail', params);
}

function setRedeem(params) {
    return qrService('/v3/app/prize/redeem', params);
}

function getRedeemedList(params) {
    return qrService('/v2/app/prize/redeemed/list', params)
}

function getRedeemedDetails(params) {
    return qrService('/v2/app/prize/redeemed/detail', params)
}

function setBookmark(params) {
    return qrService('/v2/app/prize/bookmark', params);
}

function getBookmarkList(params) {
    return qrService('/v2/app/prize/list/bookmarks', params);
}

function getDownloadVoucher(params) {
    return qrService('/v2/app/prize/redeemed/voucher/user', params);
}

export const PrizesService = {
    getList,
    getPrizeDetails,
    setRedeem,
    getRedeemedList,
    getRedeemedDetails,
    setBookmark,
    getBookmarkList,
    getDownloadVoucher
}
