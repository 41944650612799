import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as FreeActions } from '../../../../redux/ducks/free';
import { actions as AuthActions } from '../../../../redux/ducks/auth';
import { Button, Typography } from '@material-ui/core';
import PointsBar from '../../../../components/PointsBar';
import DialogPurchase from './DialogPurchase';
import DialogLevelQuestion from './DialogLevelQuestion';
import DialogWarningProcess from './DialogWarningProcess';
import { Icon } from '../../../../components/Images/Images';

export default function ButtonSubcategory(props) {
    const { category, item, path } = props;
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [openDialogLevel, setOpenDialogLevel] = useState(false);
    const [openDialogPurchase, setOpenDialogPurchase] = useState(false);
    const [openDialogWarning, setOpenDialogWarning] = useState(false);

    function onCategorySelected() {
        if (item.premium === "Y" && item.purchased === "N" && auth.user.premium === "N") {
            setOpenDialogPurchase(true);
        } else {
            if (item.has_children && item.has_children === 'Y') {
                history.push({
                    pathname: `${path.replace(/./g, '')}/free/${category.id_category}/${item.id_category}`,
                    state: {
                        category: category,
                        subcategory: item,
                        origin: "children"
                    }
                });
            } else {
                if (item.available_levels.length === 1) {
                    history.push({
                        pathname: `${path.replace(/./g, '')}/free/question`,
                        state: {
                            idCategory: item.id_category,
                            idLevel: "0",
                            category: category.name,
                            subcategory: item.name
                        }
                    });
                } else {
                    setOpenDialogLevel(true);
                }
            }
        }
    }

    async function buyCategory(category) {
        setOpenDialogPurchase(false);
        dispatch(FreeActions.getBuyCategory({
            id_category: category.id_category,
            purchase_type: 2 // 2 - Categoria Modo Livre
        }));
        dispatch(AuthActions.updateProfile({
            neuros: auth.user.neuros - parseInt(item.neuro_cost)
        }))
    }

    async function sendWarningProcess(type) {
        setOpenDialogWarning(false);
        dispatch(FreeActions.setWarningProcess({
            id_category: category.id_category,
            warning_type: type
        }));
        onCategorySelected();
    }

    return (
        <div>
            <Button variant="outlined" className="btn-card __subcategory" onClick={() => item.warning ? setOpenDialogWarning(true) : onCategorySelected()}>
                <div className="subcategory-content __text">
                    <div className="d-flex align-items-center w-100">
                        <Typography variant="body1" component="h4">
                            {item.name}
                        </Typography>
                        {item.premium === "Y" && item.purchased === "N" ?
                            <Icon ic="crown_filled" color="premium" className="icon-xs ml-2" />
                            :
                            item.premium === "Y" && item.purchased === "Y" ?
                                <Icon ic="check" color="success" className="icon-xs ml-2" />
                                : null
                        }
                    </div>
                </div>
                {
                    item.premium === "Y" && item.purchased === "N" && auth.user.premium === "N" ?
                        <PointsBar type="neuros" value={item.neuro_cost} />
                        :
                        <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                }
            </Button>
            <DialogLevelQuestion open={openDialogLevel} close={() => setOpenDialogLevel(false)} item={item} path={path} category={category.name} type="subcategory" />
            <DialogPurchase type="subcategory" open={openDialogPurchase} close={() => setOpenDialogPurchase(false)} item={item} purchase={buyCategory} />
            {
                item.warning ?
                    <DialogWarningProcess open={openDialogWarning} close={() => setOpenDialogWarning(false)} warning={item.warning} send={sendWarningProcess} />
                    : null
            }
        </div>
    )
}
