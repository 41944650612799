import * as React from "react"

function ImgHelpSkip(props) {
    if (props.status === "disabled") {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M18.383 3.532c.408.204.562.766 0 1.021.715-.306.868-.408 1.532-.51-.102-.103-.102-.358-.358-.817C19.15 2.46 17.617 2 17.362 2c.306.817.306 1.174 1.021 1.532z"
                        className="svg-help-disabled-3"
                    />
                    <path
                        d="M23.336 7.055c-.204-.306-.919-1.021-1.123-1.276-.102-.153-.358-.613-.766-.97-.562-.46-1.021-.766-1.532-.766h-.204c-1.226-1.481-3.37-1.532-3.37-1.532s.408 1.787 2.093 1.991c0 .051-.051.051-.051.051-.766.358-.51 2.043-2.043 2.043 0 0-3.625-2.553-5.617-2.553-5.004 0-5.361 6.587-5.617 8.17-.255 1.583-1.378 5.617-3.063 5.617C.766 17.83 0 16.298 0 16.298s.97 2.553 2.553 2.553 3.575-1.94 4.596-5.617c.408-1.43.51-2.043.51-2.043s1.686 1.686 1.532 3.064c-.153 1.379-2.042 2.86-2.042 3.575 0 .715 1.532 3.574 1.532 3.574s.306.97 1.532 1.022c1.225.05.51-1.022.51-1.022s-1.532-2.706-1.532-3.064c0-.357 3.064-3.574 3.064-3.574s1.226-.92 1.788-2.145c.255.051.561.102.766.102.357 0 .714-.05.97-.102l.561 1.226c0 .102-.05.204-.102.306-.102.051-.204.102-.408.102-.307 0-.51.205-.51.511 0 .306.203.51.51.51.613 0 .97-.255 1.174-.459.358-.408.358-.97.358-1.072v-1.89c.561-.357 1.276-.766 1.532-1.174.46-.715.51-2.043.51-2.043s.102-.204.307-.408c.612.613 1.634.306 1.94.255l1.072.153c.205.051.409-.05.511-.255.102-.153.46-.715.102-1.328z"
                        className="svg-help-disabled-2"
                    />
                    <path
                        d="M15.779 12.621a4.7 4.7 0 01-.97.102c-.205 0-.511-.05-.766-.102.51-1.174.408-2.604-1.788-3.983 0 0 .97-.204 2.35 1.073.765.715 1.48.766 1.684.715l-.766.561a.487.487 0 00-.153.613l.409 1.021zm-8.63.613c.408-1.43.51-2.043.51-2.043s1.686 1.686 1.532 3.064c-.05.664-.561 1.379-1.02 1.992.612-.817 2.552-2.553 1.531-5.056-.664-1.582-2.91-2.808-3.064-1.531-.919 8.63-4.085 9.191-4.085 9.191 1.583 0 3.575-1.94 4.596-5.617z"
                        className="svg-help-disabled-1"
                    />
                    <path
                        d="M21.447 5.574c0 .307-.358.511-.766.511-.409 0-.766-.204-.766-.51h1.532zm-10.724 15.83s.715 1.073-.51 1.022c-1.226-.052-1.532-1.022-1.532-1.022s-.255-.46-.51-1.021h1.99c.307.562.562 1.021.562 1.021zm5.566-7.66l.051.103c0 .102-.05.204-.102.306-.102.051-.204.102-.408.102-.307 0-.51.205-.51.511 0 .306.203.51.51.51.613 0 .97-.204 1.174-.408.46-.51.358-.97.358-1.123h-1.073zm6.18-6.127c0 .409.254.766.612.92.05-.052.102-.103.153-.154.102-.153.51-.715.102-1.328-.05-.102-.153-.204-.306-.357-.307.204-.562.51-.562.919z"
                        className="svg-help-disabled-3"
                    />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none">
                    <path
                        d="M18.383 3.532c.408.204.562.766 0 1.021.715-.306.868-.408 1.532-.51-.102-.103-.102-.358-.358-.817C19.15 2.46 17.617 2 17.362 2c.306.817.306 1.174 1.021 1.532z"
                        fill="#C76E29"
                    />
                    <path
                        d="M23.336 7.055c-.204-.306-.919-1.021-1.123-1.276-.102-.153-.358-.613-.766-.97-.562-.46-1.021-.766-1.532-.766h-.204c-1.226-1.481-3.37-1.532-3.37-1.532s.408 1.787 2.093 1.991c0 .051-.051.051-.051.051-.766.358-.51 2.043-2.043 2.043 0 0-3.625-2.553-5.617-2.553-5.004 0-5.361 6.587-5.617 8.17-.255 1.583-1.378 5.617-3.063 5.617C.766 17.83 0 16.298 0 16.298s.97 2.553 2.553 2.553 3.575-1.94 4.596-5.617c.408-1.43.51-2.043.51-2.043s1.686 1.686 1.532 3.064c-.153 1.379-2.042 2.86-2.042 3.575 0 .715 1.532 3.574 1.532 3.574s.306.97 1.532 1.022c1.225.05.51-1.022.51-1.022s-1.532-2.706-1.532-3.064c0-.357 3.064-3.574 3.064-3.574s1.226-.92 1.788-2.145c.255.051.561.102.766.102.357 0 .714-.05.97-.102l.561 1.226c0 .102-.05.204-.102.306-.102.051-.204.102-.408.102-.307 0-.51.205-.51.511 0 .306.203.51.51.51.613 0 .97-.255 1.174-.459.358-.408.358-.97.358-1.072v-1.89c.561-.357 1.276-.766 1.532-1.174.46-.715.51-2.043.51-2.043s.102-.204.307-.408c.612.613 1.634.306 1.94.255l1.072.153c.205.051.409-.05.511-.255.102-.153.46-.715.102-1.328z"
                        fill="#F38631"
                    />
                    <path
                        d="M15.779 12.621a4.7 4.7 0 01-.97.102c-.205 0-.511-.05-.766-.102.51-1.174.408-2.604-1.788-3.983 0 0 .97-.204 2.35 1.073.765.715 1.48.766 1.684.715l-.766.561a.487.487 0 00-.153.613l.409 1.021zm-8.63.613c.408-1.43.51-2.043.51-2.043s1.686 1.686 1.532 3.064c-.05.664-.561 1.379-1.02 1.992.612-.817 2.552-2.553 1.531-5.056-.664-1.582-2.91-2.808-3.064-1.531-.919 8.63-4.085 9.191-4.085 9.191 1.583 0 3.575-1.94 4.596-5.617z"
                        fill="#F7B27B"
                    />
                    <path
                        d="M21.447 5.574c0 .307-.358.511-.766.511-.409 0-.766-.204-.766-.51h1.532z"
                        fill="#464646"
                    />
                    <path
                        d="M10.723 21.404s.715 1.073-.51 1.022c-1.226-.052-1.532-1.022-1.532-1.022s-.255-.46-.51-1.021h1.99c.307.562.562 1.021.562 1.021zm5.566-7.66l.051.103c0 .102-.05.204-.102.306-.102.051-.204.102-.408.102-.307 0-.51.205-.51.511 0 .306.203.51.51.51.613 0 .97-.204 1.174-.408.46-.51.358-.97.358-1.123h-1.073zm6.18-6.127c0 .409.254.766.612.92.05-.052.102-.103.153-.154.102-.153.51-.715.102-1.328-.05-.102-.153-.204-.306-.357-.307.204-.562.51-.562.919z"
                        fill="#7E6955"
                    />
                </g>
            </svg>
        )
    }
}

export default ImgHelpSkip
