import * as React from "react"

function Onboarding(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          d="M19.04 13.568l-6.215-6.562L14.2 5.903l-1.1-1.161H5.675L1 3.58v9.29l3.245.58 6.6 7.084c.44.465 1.1.465 1.54 0 .44-.464.44-1.16 0-1.625l-.055-.058a.292.292 0 010-.407.256.256 0 01.385 0l.44.465c.44.464 1.1.464 1.54 0 .44-.465.44-1.162 0-1.626l-.165-.174a.292.292 0 010-.407.256.256 0 01.385 0l.55.58c.44.465 1.1.465 1.54 0 .44-.464.44-1.16 0-1.625l-.165-.174a.292.292 0 010-.407.256.256 0 01.385 0l.165.175c.44.464 1.1.464 1.54 0 .55-.465.55-1.22.11-1.684z"
          fill="#FFB74D"
        />
        <path
          d="M13.87 8.168l-2.585 1.045c-1.54.639-3.245.232-4.345-1.045-.22-.233-.165-.639.165-.813L14.42 3.58a2.518 2.518 0 012.695.232l.22.174c.605.465 1.43.58 2.145.407L23 3.58v9.29l-3.245.58-.44.465-5.445-5.748zM8.7 19.838c.44.465 1.1.465 1.54 0l.385-.406c.44-.464.44-1.161 0-1.626-.44-.464-1.1-.464-1.54 0l-.385.407a1.166 1.166 0 000 1.626zm-1.595-1.625c.44.464 1.1.464 1.54 0l.385-.407c.44-.464.44-1.16 0-1.625-.44-.465-1.1-.465-1.54 0l-.385.406c-.385.407-.385 1.161 0 1.626zm-1.54-1.626c.44.465 1.1.465 1.54 0l.385-.406c.44-.465.44-1.162 0-1.626-.44-.465-1.1-.465-1.54 0l-.385.406c-.44.407-.44 1.162 0 1.626zm-1.54-1.684c.44.465 1.1.465 1.54 0l.385-.406c.44-.465.44-1.162 0-1.626-.44-.465-1.1-.465-1.54 0l-.385.406a1.166 1.166 0 000 1.626z"
          fill="#C77600"
        />
        <path
          d="M10.075 19.2l-.825-.813.22-.232c.22-.232.495-.232.715 0l.055.058c.22.232.22.522 0 .755l-.165.232zM8.48 17.574l-.77-.813.22-.232c.22-.232.495-.232.715 0l.055.058c.22.232.22.523 0 .755l-.22.232zm-1.54-1.626l-.77-.813.22-.232c.22-.232.495-.232.715 0l.055.058c.22.233.22.523 0 .755l-.22.232zM5.4 14.323l-.77-.871.22-.233c.22-.232.495-.232.715 0l.055.058c.22.233.22.523 0 .755l-.22.29z"
          fill="#EBAE55"
        />
      </g>
    </svg>
  )
}

export default Onboarding
