import * as React from "react"

function ImgFeed2(props) {
    return (
        <svg width={132} height={44} viewBox="0 0 132 44" {...props}>
            <g transform="translate(4 4)" fill="none" fillRule="evenodd">
                <rect fill="#FFF" width={124} height={36} rx={10} />
                <ellipse className="svg-feed-circle" cx={18.259} cy={18.224} rx={10.585} ry={10.5} />
                <path
                    d="M17 13.497a.809.809 0 00-.81.809c0 .006.003.013.003.02-.068-.008-.135-.02-.205-.02a1.82 1.82 0 00-1.82 1.82c0 .19.037.37.092.542a2.02 2.02 0 000 3.364 1.786 1.786 0 00-.092.543c0 .942.72 1.708 1.637 1.801a1.01 1.01 0 002.003-.183v-.202a.81.81 0 00-.809-.81.404.404 0 110-.808c.296 0 .57.086.81.225v-3.473a1.986 1.986 0 01-1.214.416.404.404 0 110-.809c.669 0 1.213-.544 1.213-1.213v-1.213a.809.809 0 00-.809-.81zm2.426 0a.809.809 0 00-.809.809v1.213c0 .669.545 1.213 1.214 1.213a.404.404 0 110 .81c-.458 0-.876-.158-1.214-.416v5.067a1.011 1.011 0 002.004.183 1.812 1.812 0 001.63-1.739 2.4 2.4 0 01-.802.14.404.404 0 110-.809 1.62 1.62 0 001.618-1.618 2.02 2.02 0 00-.901-1.682 1.77 1.77 0 00.092-.542 1.82 1.82 0 00-1.82-1.82c-.07 0-.137.012-.205.02l.002-.02a.809.809 0 00-.809-.81z"
                    fill="#FFF"
                />
                <rect
                    className="svg-feed-text"
                    x={36.696}
                    y={10.79}
                    width={62}
                    height={4.5}
                    rx={2.25}
                />
                <rect
                    className="svg-feed-text-light"
                    x={35.955}
                    y={21.31}
                    width={74.098}
                    height={4.5}
                    rx={2.25}
                />
            </g>
        </svg>
    )
}

export default ImgFeed2
