import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

const FeedbackBox = ({ correct, disabled, children, title }) => {
    return (
        <Box p={2} className={`feedback-box ${!disabled ? correct ? "__correct" : "__incorrect" : "__disabled"}`}>
            <Grid container spacing={1}>
                {
                    title &&
                    <Grid item xs={12}>
                        <Typography variant="caption" component="h5">
                            {title}
                        </Typography>
                    </Grid>
                }
                {children}
            </Grid>
        </Box>
    )
}

export default FeedbackBox;