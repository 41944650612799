import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Divider } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionList from '../../../components/SectionList';
import ExamListItem from '../../Learn/Exam/components/ExamListItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingAvatarList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ExamSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const exams = useSelector(state => state.home.exams);
    const [didMount, setDidMount] = useState(false);
    const limit = 3;

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(HomeActions.getExamList({
                limit: limit,
                page: 1
            }));

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    function goToExam(item) {
        return () => history.push({
            pathname: `${props.match.path.replace(/./g, '')}/exam/${item.id_exam}`,
            state: {
                origin: 'home'
            }
        });
    }

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                imageType={config.home.sections.exam.image_type}
                image={config.home.sections.exam.image}
                sectionColor={config.home.sections.exam.section_color}
                icon={config.home.sections.exam.icon}
                iconColor={config.home.sections.exam.icon_color}
                title={intl.get("EXAM_TITLE")}
                subtitle={intl.get("EXAM_SUBTITLE_SECTION")}
                action={() => history.push('/exam')}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionContent = () => {
        return (
            <Section>
                <SectionList
                    action={() => history.push('/exam')}
                    seeAllButton={true}
                >
                    {
                        exams.data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <ExamListItem
                                        action={goToExam(item)}
                                        item={item}
                                        status="to_do"
                                    />
                                    <Divider variant="inset" component="li" className="mr-3" />
                                </div>
                            )
                        })
                    }
                </SectionList>
            </Section>
        )
    }

    return (
        exams && exams.load === "finished" ?
            !exams.error ?
                exams.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        <SectionContent />
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="play_exam" className="icon-xxl" />}
                                    text={intl.get("EMPTY_EXAM")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        <SectionList
                            action={() => history.push('/exam')}
                            seeAllButton={true}
                        >
                            <LoadingAvatarList limit={limit} />
                        </SectionList>
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    <SectionList
                        action={() => history.push('/exam')}
                        seeAllButton={true}
                    >
                        <LoadingAvatarList limit={limit} />
                    </SectionList>
                </Section>
            </div>
    )
};

