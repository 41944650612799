import { gameService } from '../../services/game.service';

// CONST
export const Types = {
    HELP: 'game/HELP',
    REPORT: 'game/REPORT',
    REPORT_LIST: 'game/REPORT_LIST',
    RESET: 'game/RESET',
};

// INITIAL STATE
const initialState = {
    help: {},
    report: {},
    reasons: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.HELP:
            let answerHistory = state.help.answer_history || [];
            let answersToEliminate = state.help.answers_to_eliminate || [];
            let skipQuestion = false;

            // Ver mais respondidas
            if (action.payload.data.help_return && action.payload.data.help_return.content.answer_history) {
                answerHistory = action.payload.data.help_return.content.answer_history;
                answerHistory.map((item, index) => {
                    return answerHistory[index].answer_percent = (item.answer_percent * 100) + "%";
                })
            }

            // Eliminar respostas erradas
            if (action.payload.data.help_return && action.payload.data.help_return.content.answers_to_eliminate) {
                if (state.help.answers_to_eliminate) {
                    answersToEliminate = action.payload.data.help_return.content.answers_to_eliminate.concat(state.help.answers_to_eliminate);
                } else {
                    answersToEliminate = action.payload.data.help_return.content.answers_to_eliminate;
                }
            }

            // Pular pergunta
            if (action.payload.data.help_return && action.payload.data.help_return.type === "SKIP") {
                skipQuestion = true;
            }

            return {
                ...state,
                help: {
                    answer_history: answerHistory,
                    answers_to_eliminate: answersToEliminate,
                    skip: skipQuestion,
                    user_neuros: action.payload.data.user ? action.payload.data.user.neuros : 0,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.REPORT:
            return {
                ...state,
                report: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.REPORT_LIST:
            return {
                ...state,
                reasons: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                help: {},
                reasons: {}
            }
        default:
            return state
    }
}

function useHelp(params) {
    return dispatch => {
        return gameService.useHelp(params).then(data => {
            dispatch({
                type: Types.HELP,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.HELP,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setReport(params) {
    return dispatch => {
        return gameService.setReport(params).then(data => {
            dispatch({
                type: Types.REPORT,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REPORT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getReportList(params) {
    return dispatch => {
        return gameService.getReportList(params).then(data => {
            dispatch({
                type: Types.REPORT_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REPORT_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    useHelp,
    setReport,
    getReportList,
    reset
}
