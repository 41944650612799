import React from 'react';
import intl from "react-intl-universal";
import config from '../../../../config';
import { getDateTime, getTimer } from '../../../../utils/utils';
import {
    Card, CardContent, CardActionArea, CardMedia, Typography, Tooltip, Zoom,
    CircularProgress, LinearProgress
} from '@material-ui/core';
import { imageParser } from '../../../../utils/utils';
import { Icon } from '../../../../components/Images/Images';

export default function CategoryItem(props) {
    const { action, type, image, bgColor, title, currentStars, totalStars, currentStep, totalStep, complete, obligatory, available, dependency, dateRelease, workload } = props;

    return (
        <Tooltip title={
            type !== "themes" && (available && available === "N") ?
                dependency ?
                    `${intl.get("TRAIL_CATEGORY_DEPENDENCY_TEXT_1")} ${dependency} ${intl.get("TRAIL_CATEGORY_DEPENDENCY_TEXT_2")}`
                    :
                    dateRelease ?
                        `${intl.get("TRAIL_CATEGORY_DATE_RELEASE_TEXT")} ${getDateTime(dateRelease)}`

                        : ""
                : ""
        }
            placement="top"
            TransitionComponent={Zoom}
            disableFocusListener
        >
            <div className={`category-list-item${config.learn.trail.categories.grid.style === "card" ? " __card" : " __default"}`}>
                <div className={`category-list-item-content${available && available === "N" ? " __disabled" : ""}${config.learn.trail.categories.grid.size === "large" ? " __large" : ""}`}>
                    <Card>
                        <CardActionArea onClick={action} disabled={available && available === "N" ? true : false}>
                            {
                                obligatory && obligatory === "Y" ?
                                    <>
                                        <div className="category-flag __obligatory"></div>
                                        <div className="category-flag-label">
                                            {intl.get("LABEL_OBLIGATORY")}
                                        </div>
                                    </>
                                    : null
                            }

                            {
                                type === "themes" || (available && available === "Y") ?
                                    <CardMedia
                                        className={`category-image  __banner${config.learn.trail.categories.grid.mobile === "full" ? " __full" : ""}`}
                                        style={bgColor ? { backgroundColor: bgColor } : null}
                                        image={imageParser(image)}
                                        title={title}
                                    />
                                    :
                                    <div className={`category-image  __banner${config.learn.trail.categories.grid.mobile === "full" ? " __full" : ""}`}>
                                        <div className="category-image-filter" style={{ backgroundImage: `url(${imageParser(image)})`, backgroundColor: bgColor ? bgColor : "" }}></div>
                                        <div className="category-image-locked">
                                            <Icon ic="lock" color="lightest" className="icon-lg" />
                                        </div>
                                    </div>
                            }
                            <CardContent className="category-text">
                                <div className="category-text-info">
                                    <Typography variant="body2" component="h4">
                                        {title}
                                    </Typography>
                                    {
                                        type !== "themes" ?
                                            <div className="category-text-info-stats">
                                                {
                                                    config.learn.trail.categories.stars &&
                                                    <div className="category-text-info-stats-item __stars">
                                                        <Icon ic="star" className="icon-xxs" />
                                                        <Typography variant="caption" component="p">
                                                            {currentStars + ' / ' + totalStars}
                                                        </Typography>
                                                    </div>
                                                }
                                                {
                                                    config.learn.trail.categories.steps &&
                                                    <div className="category-text-info-stats-item __steps">
                                                        <Icon ic="steps" className="icon-xxs" />
                                                        <Typography variant="caption" component="p">
                                                            {`${totalStep} ${intl.get("TRAIL_INFO_LEVELS_LABEL")}`}
                                                        </Typography>
                                                    </div>
                                                }
                                                {
                                                    config.learn.trail.categories.workload && workload &&
                                                    <div className="category-text-info-stats-item __workload">
                                                        <Icon ic="clock" className="icon-xxs" />
                                                        <Typography variant="caption" component="p">
                                                            {getTimer(workload)}
                                                        </Typography>
                                                    </div>
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                                {
                                    config.learn.trail.categories.progress.enabled &&
                                    <div className="category-text-progress">
                                        {
                                            config.learn.trail.categories.progress.type === "circular" ?
                                                <div className={`category-text-progress-circular __${config.learn.trail.categories.progress.position}`}>
                                                    {
                                                        complete === "Y" ?
                                                            <Icon ic="checked" className="icon-xs __finished" />
                                                            :
                                                            <div className="circular-progress">
                                                                <div className="progress">
                                                                    <CircularProgress className="progress-inactive" variant="determinate" value={100} size={20} thickness={5} />
                                                                    <CircularProgress className="progress-active" variant="determinate" value={currentStep / totalStep * 100} size={20} thickness={5} />
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        config.learn.trail.categories.progress.label &&
                                                        <Typography variant="caption" component="p" className="font-weight-bold ml-2">
                                                            {currentStep + ' / ' + totalStep}
                                                        </Typography>
                                                    }
                                                </div>
                                                :
                                                <LinearProgress className={complete === "Y" ? "__completed" : ""} variant="determinate" value={currentStep / totalStep * 100} size={20} thickness={5} />
                                        }
                                    </div>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </div>
        </Tooltip>
    )
}
