import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import { Typography, Button } from '@material-ui/core';
import { Icon, Logo } from '../../../components/Images/Images';

export default function Popup() {
    const [popupOpen, setPopupOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [deferredInstallPrompt, setdeferredInstallPrompt] = useState(null);

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (!isInStandaloneMode() && !popupOpen) {
        setPopupOpen(true);
    }

    if (!showPopup) {
        window.addEventListener('beforeinstallprompt', saveBeforeInstallPromptEvent);
    }

    function saveBeforeInstallPromptEvent(evt) {
        setShowPopup(true);
        setdeferredInstallPrompt(evt)
    }

    const addToHomeScreen = () => {
        deferredInstallPrompt.prompt();
        deferredInstallPrompt.userChoice
            .then((choice) => {
                if (choice.outcome === 'accepted') {
                    setShowPopup(false);
                }
                setdeferredInstallPrompt(null)
            });
    }

    window.addEventListener('appinstalled', () => setShowPopup(false));

    if (popupOpen) {
        return (
            showPopup ?
                <div className={`intro-popup-install${isIos() ? " __ios" : ""}`}>
                    {
                        !isIos() ?
                            <>
                                <div className="d-flex align-items-center">
                                    <Logo type="app-icon" className="img-md rounded mr-3" />
                                    <Typography variant="body1" component="p">
                                        {`${intl.get("INTRO_POPUP_INSTALL_TEXT_1")} ${config.app.name} ${intl.get("INTRO_POPUP_INSTALL_TEXT_2")}`}
                                    </Typography>
                                </div>
                                <Button variant="outlined" color="primary" className="ml-3" onClick={addToHomeScreen}>
                                    {intl.get("BTN_INSTALL")}
                                </Button>
                            </>
                            :
                            <>
                                <Logo type="app-icon" className="img-md rounded mr-3" />
                                <Typography variant="body1" component="div">
                                    {`${intl.get("INTRO_POPUP_INSTALL_TEXT_1")} ${config.app.name} ${intl.get("INTRO_POPUP_INSTALL_TEXT_2")} `}
                                    {`${intl.get("INTRO_POPUP_INSTALL_TEXT_3")} `} <Icon ic="upload_outline" className="align-bottom" />
                                    {` ${intl.get("INTRO_POPUP_INSTALL_TEXT_4")} `} <Icon ic="add_new_outline" className="align-bottom" />
                                </Typography>
                            </>
                    }
                </div>
                : null
        )
    } else return null
}