import { qrService } from './base.service'

function getList(params) {
    return qrService('/v4/app/feed/list', params)
}

function setLike(params) {
    return qrService('/v4/app/feed/like', params)
}

function setFavorite(params) {
    return qrService('/v2/app/prize/bookmark', params)
}

function sendEmail(params) {
    return qrService('/v4/app/feed/mail', params)
}

function getListComment(params) {
    return qrService('/v4/app/feed/comment/list', params)
}

function addComment(params) {
    return qrService('/v4/app/feed/comment', params)
}

function deleteComment(params) {
    return qrService('/v4/app/feed/comment/delete', params)
}

function setLikeComment(params) {
    return qrService('/v4/app/feed/comment/like', params)
}

export const FeedsService = {
    getList,
    setLike,
    setFavorite,
    sendEmail,
    getListComment,
    addComment,
    deleteComment,
    setLikeComment
}
