import * as React from "react";

const ImgGift = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="nonzero">
            <path
                fill="#D32F2F"
                d="M19.778 22H4.222c-.61 0-1.11-.5-1.11-1.111V9.778h17.777v11.11c0 .612-.5 1.112-1.111 1.112Z"
            />
            <path
                fill="#F44336"
                d="M22 9.778H2V6.444c0-.61.5-1.11 1.111-1.11H20.89c.611 0 1.111.5 1.111 1.11v3.334Z"
            />
            <path
                fill="#FF8F00"
                d="M10.889 9.778h2.222V22H10.89V9.778ZM16.444 2h-2.222L10.89 5.333h2.222L16.444 2Z"
            />
            <path fill="#FFC107" d="M9.778 2H7.556l3.333 3.333v4.445h2.222V5.333z" />
        </g>
    </svg>
)

export default ImgGift