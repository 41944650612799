import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { managerService } from '../../../services/manager.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ManagerActions } from '../../../redux/ducks/manager';
import { getDateTime, numberMask, imageParser, download_csv } from '../../../utils/utils';
import { Grid, TextField, IconButton, Tooltip, Avatar, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import { Icon, Points } from '../../../components/Images/Images';

export default function TableTeamEmployees(props) {
    const dispatch = useDispatch();
    const [responsive] = useState('standard');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [colName, setColName] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [loading, setLoading] = useState(false);
    const [download, setDownload] = useState(false);
    const [textFilter, setTextFilter] = useState('');
    const auth = useSelector(state => state.auth);
    const teamEmployees = useSelector(state => state.manager.teamEmployees);
    let data = [];
    let downloadList = [];

    const columns = [
        {
            name: "user_registration",
            label: intl.get("LABEL_REGISTER"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "full_name",
            label: intl.get("LABEL_NAME"),
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        value ?
                            <div className="d-flex align-items-center" >
                                <Avatar className="avatar-xxs mr-3" src={value && value.image && imageParser(value.image)} />
                                {value && value.name}
                            </div>
                            :
                            null
                    )
                }
            }
        },
        {
            name: "role",
            label: intl.get("LABEL_ROLE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "xp",
            label: intl.get("LABEL_XP"),
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        value ?
                            <div className="d-flex align-items-center">
                                <Points type="xp" className="img-xs" />
                                <Typography variant="body2" component="p" className="text-xp ml-1">
                                    {value}
                                </Typography>
                            </div>
                            :
                            null
                    )
                }
            }
        },
        {
            name: "last_activity_date",
            label: intl.get("LABEL_LAST_ACTIVITY"),
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    const options = {
        serverSide: true,
        page: page,
        loading: false,
        count: teamEmployees && teamEmployees.load === "finished" ? parseInt(teamEmployees.data.total_filtered) : 0,
        rowsPerPageOptions: [10, 50, 100],
        print: false,
        filter: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        responsive,
        selectableRowsHeader: false,
        textLabels: {
            body: {
                noMatch: intl.get("TABLE_BODY_NO_MATCH"),
                toolTip: intl.get("TABLE_BODY_ORDER"),
                columnHeaderTooltip: column => intl.get("TABLE_BODY_ORDER_BY") + " " + column.label
            },
            pagination: {
                next: intl.get("TABLE_PAGINATION_NEXT"),
                previous: intl.get("TABLE_PAGINATION_PREVIOUS"),
                rowsPerPage: intl.get("TABLE_PAGINATION_ROWS_PAGE"),
                displayRows: intl.get("TABLE_PAGINATION_DISPLAY_ROWS"),
            },
            toolbar: {
                search: intl.get("TABLE_TOOLBAR_SEARCH"),
                downloadCsv: intl.get("TABLE_TOOLBAR_DOWNLOAD"),
                print: intl.get("TABLE_TOOLBAR_PRINT"),
                viewColumns: intl.get("TABLE_TOOLBAR_VIEW_COLUMNS"),
                filterTable: intl.get("TABLE_TOOLBAR_FILTER_TABLE"),
            },
            filter: {
                all: intl.get("TABLE_FILTER_ALL"),
                title: intl.get("TABLE_FILTER_TITLE"),
                reset: intl.get("TABLE_FILTER_RESET"),
            },
            viewColumns: {
                title: intl.get("TABLE_COLUMNS_TITLE"),
                titleAria: intl.get("TABLE_COLUMNS_TITLE_ARIA"),
            },
            selectedRows: {
                text: intl.get("TABLE_ROWS_TEXT"),
                delete: intl.get("TABLE_ROWS_DELETE"),
                deleteAria: intl.get("TABLE_ROWS_DELETE_ARIA"),
            },
        },
        onChangePage: async (currentPage) => {
            setPage(currentPage);
            setLoading(true);
            await dispatch(ManagerActions.getTeamEmployees({
                id_manager: auth.user.id_user,
                offset: currentPage,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onChangeRowsPerPage: async (numberOfRows) => {
            setRows(numberOfRows);
            setLoading(true);
            await dispatch(ManagerActions.getTeamEmployees({
                id_manager: auth.user.id_user,
                offset: page,
                limit: numberOfRows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onColumnSortChange: async (col, dir) => {
            setLoading(true);
            setColName(col);
            setOrderBy(dir);
            await dispatch(ManagerActions.getTeamEmployees({
                id_manager: auth.user.id_user,
                offset: page,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                order_list: `${col} ${dir}`
            }));
            setLoading(false);
        },
        customToolbar: () => {
            return <CustomToolbar />
        },
        customSearchRender: () => {
            return (
                EnhancedTableToolbar()
            );
        },
    }

    async function filterTableList() {
        setLoading(true);
        await dispatch(ManagerActions.getTeamEmployees({
            id_manager: auth.user.id_user,
            offset: page,
            limit: rows,
            filter: textFilter ? 'filtro_geral:' + textFilter : "",
            order_list: `${colName} ${orderBy}`
        }));
        setLoading(false);
    }

    if (teamEmployees && teamEmployees.load === "finished") {
        if (!teamEmployees.error) {
            teamEmployees.data.list.map((row) => {
                let cols;

                cols = {
                    [columns[0].name]: row.user_registration
                        ? <div className="text-center pr-4">{row.user_registration}</div>
                        : '-',
                    [columns[1].name]: row.full_name && row.user_photo
                        ? { name: row.full_name, image: row.user_photo }
                        : '-',
                    [columns[2].name]: row.role
                        ? row.role
                        : '-',
                    [columns[3].name]: row.xp
                        ? numberMask(row.xp)
                        : '-',
                    [columns[4].name]: row.last_activity_date
                        ? getDateTime(row.last_activity_date)
                        : '-'
                }

                return data.push(cols);
            })
        }
    }

    async function downloadCSV() {
        let ret;
        let csv;

        csv = `${columns[0].label};${columns[1].label};${columns[2].label};${columns[3].label};${columns[4].label}\n`;

        setDownload(true);

        try {
            ret = await managerService.getTeamEmployees({
                id_manager: auth.user.id_user,
                offset: 0,
                limit: 0,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
            });
        }
        catch (err) {
            console.log(err)
        }

        ret.list.map(row => {
            const list = [
                row.user_registration ? row.user_registration : '-',
                row.full_name ? row.full_name : '-',
                row.role ? row.role : '-',
                row.xp ? numberMask(row.xp) : '-',
                row.last_activity_date ? getDateTime(row.last_activity_date) : '-',
            ]

            return downloadList.push(list);
        })

        download_csv(csv, downloadList);
        setDownload(false);
    }

    const CustomToolbar = () => {
        return (
            <Tooltip title={intl.get("TABLE_TOOLBAR_DOWNLOAD")}>
                <IconButton onClick={() => downloadCSV()}>
                    {download ? <LoadingBounce size={24} /> : <Icon ic="download" />}
                </IconButton>
            </Tooltip>
        )
    }

    const EnhancedTableToolbar = () => {
        return (
            <Grid container direction="row" alignItems="center" className="table-custom-search" spacing={2}>
                <Grid item xs={12} md>
                    <TextField
                        fullWidth
                        id="filter_text"
                        label={intl.get("TABLE_TOOLBAR_SEARCH")}
                        value={textFilter}
                        onChange={(e) => setTextFilter(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <IconButton onClick={() => filterTableList()}>
                        <Icon ic="send" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    return (
        <MUIDataTable
            className="qr-table"
            title={intl.get("MANAGER_TABLE_TEAM_EMPLOYEES_TITLE")}
            data={
                !loading && (teamEmployees && teamEmployees.load === "finished") ?
                    !teamEmployees.error ?
                        data
                        :
                        [[<ErrorContent type="section" />]]
                    :
                    [[<Grid container direction="row" alignItems="center" justify="center"><LoadingBounce /></Grid>]]
            }
            columns={
                !loading && (teamEmployees && teamEmployees.load === "finished") ?
                    !teamEmployees.error ?
                        columns
                        :
                        [[]]
                    :
                    [[]]
            }
            options={options}
        />
    )
}
