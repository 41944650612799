import * as React from "react";

const ImgRating5 = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFCA28"
                d="M22 12c0 5.5-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2s10 4.5 10 10Z"
            />
            <path
                fill="#B76C09"
                d="M12 18.5c-1.4 0-2.5-1.1-2.5-2.5h1c0 .85.65 1.5 1.5 1.5s1.5-.65 1.5-1.5h1c0 1.4-1.1 2.5-2.5 2.5Z"
            />
            <path
                fill="#F44336"
                d="M9.5 9.5c-.5 0-1 .25-1.25.65C8 9.75 7.5 9.5 7 9.5c-.85 0-1.5.65-1.5 1.5 0 1.5 2.75 3 2.75 3S11 12.5 11 11c0-.85-.65-1.5-1.5-1.5m7.5 0c-.5 0-1 .25-1.25.65-.25-.4-.75-.65-1.25-.65-.85 0-1.5.65-1.5 1.5 0 1.5 2.75 3 2.75 3s2.75-1.5 2.75-3c0-.85-.65-1.5-1.5-1.5"
            />
            <path
                fill="#FFF"
                d="M7.5 10.75c0 .4-.35.75-.75.75S6 11.15 6 10.75s.35-.75.75-.75.75.35.75.75Zm7.5 0c0 .4-.35.75-.75.75s-.75-.35-.75-.75.35-.75.75-.75.75.35.75.75Z"
            />
        </g>
    </svg>
)
export default ImgRating5
