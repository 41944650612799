import * as React from "react"

function ImgBubble2(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M32.8 4H7.216c-1.76 0-3.202 1.47-3.202 3.267L4 26.87c0 1.805 1.432 3.269 3.2 3.269h2.4c.441 0 .8.365.8.816v3.408c0 1.456 1.723 2.184 2.731 1.155l4.8-4.9c.301-.308.708-.48 1.132-.48H32.8c1.76 0 3.2-1.47 3.2-3.267V7.267C36 5.47 34.56 4 32.8 4z"
                    className="svg-bubble"
                    fillRule="nonzero"
                />
                <path
                    d="M16.4 10c-2.423 0-4.4 2.054-4.4 4.571 0 1.703 1.235 3.306 2.74 4.95 1.506 1.643 3.36 3.308 4.836 4.842a.584.584 0 00.848 0c1.477-1.534 3.33-3.199 4.835-4.843C26.765 17.877 28 16.274 28 14.571 28 12.054 26.023 10 23.6 10c-1.39 0-2.601.776-3.6 2.093C19.001 10.776 17.79 10 16.4 10z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export default ImgBubble2
