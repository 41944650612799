import * as React from "react";

const ImgRating3 = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFCA28"
                d="M22 12c0 5.5-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2s10 4.5 10 10Z"
            />
            <path
                fill="#6D4C41"
                d="M10.5 11.5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z"
            />
            <path
                fill="#FFF"
                d="M8.5 10.75c0 .4-.35.75-.75.75S7 11.15 7 10.75s.35-.75.75-.75.75.35.75.75Z"
            />
            <path
                fill="#6D4C41"
                d="M17.5 11.5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z"
            />
            <path
                fill="#FFF"
                d="M15.5 10.75c0 .4-.35.75-.75.75s-.75-.35-.75-.75.35-.75.75-.75.75.35.75.75Z"
            />
            <path fill="#B76C09" d="M9.5 16.5h5v1h-5z" />
        </g>
    </svg>
)
export default ImgRating3
