import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../../redux/ducks/auth';
import { Button, Grid } from '@material-ui/core';
import SnackBar from '../../../components/snackBar';

export default function ButtonLoginSaml2(props) {
    const { buttonStyle } = props;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");

    const handleLoginClick = async () => {
        dispatch(AuthActions.isLoading(true));
        dispatch(AuthActions.getAzureSaml({}));
    };

    useEffect(() => {
        if (auth.azureSaml && !auth.azureSaml.error && auth.azureSaml.data) { 
            window.location.href = auth.azureSaml.data
        }

        if (auth.azureSaml && auth.azureSaml.error) {
            dispatch(AuthActions.isLoading(false));
            setSnackStatus("error");
            setSnackMessage(auth.azureSaml.messages);
            setSnackOpen(true);
            dispatch(AuthActions.resetAzureSaml({}));
        }
    }, [auth.azureSaml, dispatch]);

    return (
        <Grid container justify="center">
            <Button
                id={buttonStyle === "button" ? "btn_intro_login_saml2" : ""}
                variant={buttonStyle === "button" ? "contained" : "text"}
                className={buttonStyle === "button" ? "btn-block" : ""}
                color="primary"
                onClick={handleLoginClick}
            >
                {intl.get('BTN_LOGIN_SAML2')}
            </Button>
            <SnackBar
                open={snackOpen}
                message={snackMessage}
                status={snackStatus}
                time={4}
                closeSnack={() => setSnackOpen(false)}
            />
        </Grid>
    )
}
