import React from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { linkVideoParser } from '../../utils/utils';
import EmptyContent from '../../components/EmptyContent';
import { Grid, Dialog, AppBar, Toolbar, IconButton, Typography, Container } from '@material-ui/core';
import { Icon } from '../../components/Images/Images';
import Viewer from '../../components/DocViewer';
import QrVideoPlayer from '../../components/QrVideoPlayer';

export default function DialogFile(props) {
    const { open, close, transition, title, file } = props;

    function getAudio(src) {
        return (
            <audio controls>
                <source src={src} />
            </audio>
        )
    }

    function getMountFile() {
        let fileType = file.type;

        switch (fileType) {
            case 'VIDEO':
                return (
                    <Container maxWidth="md">
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} sm={8} md={12}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <QrVideoPlayer link={linkVideoParser(file.source) + "#toolbar=0"} download={config.library.videos.download} picture={config.library.videos.picture_in_picture} />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                );
            case 'AUDIO':
                return (
                    getAudio(file.source)
                );
            default:
                return (
                    <Viewer data={file.source} fileName={title} download={config.library.files.download} />
                );
        }
    }

    return (
        <Dialog fullScreen open={open} onClose={close} TransitionComponent={transition}>
            <AppBar className="appbar appbar-default" position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {title}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} className="library-file-content">
                <Grid container justify="center" alignItems="center" className="h-100">
                    <Grid item xs={12} className="pt-3 pb-3 h-100" align="center">
                        {
                            (Object.keys(file).length > 0) && (file.source && file.source.length > 0) ?
                                getMountFile()
                                :
                                <EmptyContent icon={<Icon ic="book_stack_filled" className="icon-xxl" />} text={intl.get("EMPTY_LIBRARY")} />
                        }
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
};
