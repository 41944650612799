import * as React from "react"

function ImgBriefNotFinished(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M18 3h-5a1 1 0 1 1-2 0H6c-1.106 0-2 .894-2 2v16c0 1.105.894 2 2 2h12a2 2 0 0 0 2-2V5c0-1.106-.895-2-2-2"
                    fill="#455A64"
                />
                <path
                    d="M18 21.5H6a.5.5 0 0 1-.5-.5V5a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v16a.5.5 0 0 1-.5.5"
                    fill="#FFF"
                />
                <path
                    d="M13 3a1 1 0 1 1-2 0H7.5v2a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3H13Z"
                    fill="#90A4AE"
                />
                <path
                    d="M12 1a2 2 0 1 0 .001 3.999A2 2 0 0 0 12 1m0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2"
                    fill="#90A4AE"
                />
                <path
                    d="m14.528 9.526 1.415 1.414-2.56 2.56 2.558 2.559-1.414 1.414-2.559-2.559-2.526 2.528-1.415-1.414 2.527-2.528-2.528-2.528L9.44 9.558l2.53 2.528 2.558-2.56Z"
                    fill="#E65748"
                />
            </g>
        </svg>
    )
}

export default ImgBriefNotFinished
