import React, { useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as PeriodicActions } from '../../../../redux/ducks/periodic';
import moment from 'moment';
import { Typography, Grid, Button } from '@material-ui/core';
import SnackBar from '../../../../components/snackBar';
import { LoadingBounce } from '../../../../components/Loading';

export default function PlayLatest(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const question = useSelector(state => state.periodic.question);
    const [loadingData, setLoadindData] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [redirectQuestion, setRedirectQuestion] = useState(false);

    async function loadNextQuestion() {
        setLoadindData(true);
        await dispatch(PeriodicActions.getNextQuestion({
            question_date: moment().format("YYYY-MM-DD")
        }));
        setLoadindData(false);
        setRedirectQuestion(true);
    }

    if ((question && question.load === "finished") && redirectQuestion) {
        if (question.data.length !== 0) {
            return <Redirect to={{
                pathname: `${history.location.pathname.replace(/./g, '')}/periodic/${question.data.question.id_question}/question`,
                state: {
                    origin: 'periodic',
                    nextQuestion: question,
                    date: question.data.question.date
                }
            }} />
        } else {
            setSnackOpen(true);
        }

        setRedirectQuestion(false);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} align="center">
                <Typography variant="body2" component="p">
                    {intl.get("PERIODIC_TEXT")}
                </Typography>
            </Grid>
            <Grid item xs={12} align="center">
                {
                    !loadingData ?
                        <Button
                            id="btn_periodic_next"
                            variant="contained"
                            className="btn-block-xs w-50"
                            onClick={() => loadNextQuestion()}
                        >
                            {intl.get("BTN_PLAY_LATEST")}
                        </Button>
                        :
                        <LoadingBounce size={24} />
                }
            </Grid>
            <SnackBar
                open={snackOpen}
                message={intl.get("PERIODIC_COMPLETED")}
                status="info"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                time={3}
                closeSnack={() => setSnackOpen(false)}
            />
        </Grid>
    )
}