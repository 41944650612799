import React from 'react';
import ImgHandshake from '../../../../../dist/img/intros/onboarding/ImgHandshake';
import ImgHandshakeLines from '../../../../../dist/img/intros/onboarding/ImgHandshakeLines';

export default function OnboardingIntro() {
    return (
        <div id="intro-slider-onboarding">
            <div className="handshake">
                <div className="hands">
                    <ImgHandshake />
                </div>
                <div className="lines">
                    <ImgHandshakeLines />
                </div>
            </div>
        </div>
    )
}
