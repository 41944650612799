import { qrService } from './base.service'

function getFileList(params) {
    return qrService('/v3/app/library/list', params)
}

function getCategoryList(params) {
    return qrService('/v3/app/library/category/list', params)
}

function getFile(params) {
    return qrService('/v3/app/library/get', params)
}

export const libraryService = {
    getFileList,
    getCategoryList,
    getFile
}