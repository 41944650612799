import * as React from "react"

function ImgPodium(props) {
    return (
        <svg width={292} height={180} viewBox="0 0 292 180" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    className="svg-podium-light"
                    d="M288.378 141.444h-70.075l-9.617-10.238h64.159z"
                />
                <path className="svg-podium" d="M218.303 180h70.075v-38.556h-70.075z" />
                <path className="svg-podium-dark" d="M208.686 131.206V180h9.617v-38.556z" />
                <path
                    d="M252.535 170.605c1.404 0 2.63-.21 3.679-.627 1.049-.419 1.859-1.012 2.431-1.78.572-.768.858-1.672.858-2.713 0-1.092-.29-2.023-.871-2.79-.58-.769-1.382-1.298-2.405-1.588.884-.324 1.573-.84 2.067-1.549.494-.708.741-1.531.741-2.47 0-.99-.264-1.86-.793-2.611-.529-.751-1.283-1.331-2.262-1.741-.98-.41-2.11-.614-3.393-.614-1.04 0-2.067.153-3.081.46-1.014.308-1.894.734-2.639 1.28-.295.222-.503.453-.624.692-.121.239-.182.537-.182.896 0 .529.147.977.442 1.344.295.367.659.55 1.092.55.243 0 .46-.038.65-.115.19-.077.45-.2.78-.371l.546-.256c.52-.256.992-.461 1.417-.615a4 4 0 011.365-.23c.676 0 1.205.175 1.586.525.381.35.572.84.572 1.472 0 .597-.217 1.032-.65 1.305-.433.273-1.127.41-2.08.41h-1.144c-.52 0-.927.179-1.222.537a1.952 1.952 0 00-.442 1.28c0 .495.147.922.442 1.28.295.359.702.538 1.222.538h1.612c.988 0 1.699.145 2.132.435.433.29.65.768.65 1.434 0 .717-.212 1.229-.637 1.536-.425.307-1.122.46-2.093.46a4.782 4.782 0 01-1.495-.217 11.74 11.74 0 01-1.495-.627 16.634 16.634 0 01-.572-.256 9.84 9.84 0 00-.78-.371 1.728 1.728 0 00-.65-.116c-.433 0-.797.184-1.092.55-.295.368-.442.816-.442 1.345 0 .341.06.636.182.883.121.247.33.482.624.704.763.563 1.664.994 2.704 1.293a11.74 11.74 0 003.25.448z"
                    fill="#FFF"
                />
                <path className="svg-podium" d="M3 180h81.175l.061-42.21-81.183-.11z" />
                <path
                    className="svg-podium-light"
                    d="M3.053 137.618l81.183.11 11.157-11.846-74.328-.1z"
                />
                <path className="svg-podium-dark" d="M95.394 125.882L92.316 180h-8.08v-42.272z" />
                <path
                    d="M48.88 170.4c.906 0 1.58-.182 2.023-.547.442-.365.663-.932.663-1.703 0-.75-.22-1.302-.663-1.656-.443-.355-1.117-.532-2.023-.532h-6.206l4.385-4.408c1.214-1.257 2.073-2.397 2.578-3.42.504-1.024.756-2.154.756-3.39 0-1.196-.314-2.255-.942-3.177-.627-.922-1.518-1.631-2.67-2.128-1.153-.496-2.49-.745-4.014-.745-1.173 0-2.362.183-3.566.548a12.12 12.12 0 00-3.257 1.52c-.37.243-.623.506-.757.79-.134.284-.2.649-.2 1.094 0 .629.174 1.17.524 1.627.35.456.783.684 1.297.684.206 0 .401-.03.587-.091a3.72 3.72 0 00.633-.29c.236-.13.396-.217.478-.258l.618-.304c.658-.324 1.24-.572 1.744-.744a5.08 5.08 0 011.652-.259c.741 0 1.312.183 1.714.547.4.365.602.892.602 1.581 0 1.176-.68 2.463-2.038 3.861l-6.546 6.718c-.7.71-1.05 1.5-1.05 2.372 0 .668.217 1.22.649 1.656.432.436 1.019.654 1.76.654h11.27z"
                    fill="#FFF"
                />
                <path className="svg-podium-light" d="M210.898 115.402H92.317L107.46 100h88.295z" />
                <path className="svg-podium" d="M92.316 180h118.583v-64.598H92.316z" />
                <path
                    d="M161.171 162.4c2.639 0 3.959-1.105 3.959-3.315 0-1.075-.319-1.882-.956-2.42-.637-.537-1.638-.806-3.003-.806h-3.731v-21.235c0-1.225-.372-2.195-1.115-2.912-.743-.717-1.721-1.075-2.934-1.075-1.183 0-2.442.373-3.777 1.12l-7.28 4.166c-1.062.627-1.593 1.553-1.593 2.778 0 .896.304 1.71.91 2.441.607.732 1.335 1.098 2.184 1.098.546 0 1.077-.15 1.593-.448l3.64-2.106v16.173h-3.686c-1.364 0-2.365.269-3.002.807-.637.537-.956 1.344-.956 2.419 0 2.21 1.32 3.315 3.958 3.315h15.789z"
                    fill="#FFF"
                />
                <path
                    d="M162.603 103.73c0 2.414-2.04 4.37-4.551 4.37h-13.653c-2.512 0-4.55-1.956-4.55-4.37V88.437c0-2.414 9.101-6.555 9.101-6.555h4.55s9.103 4.14 9.103 6.555v15.293z"
                    className="svg-trophy"
                    fillRule="nonzero"
                />
                <path
                    d="M153.96 81.618v2.206h-4.477v-2.206l-2.239-17.655h8.346l-1.63 17.655zm-11.843-19.926c-1.133-.284-27.804-7.14-32.219-28.176-1.963-9.368.468-14.775 2.857-17.663 2.953-3.577 6.979-4.853 9.76-4.853 3.719 0 6.593 1.009 8.548 2.999 2.458 2.51 2.423 5.561 2.414 5.905 0 3.47-2.385 8.75-8.956 8.75-.276 0-1.693-.04-3.166-.805-2.143-1.108-3.318-3.178-3.318-5.815h4.478c0 1.267.499 1.686.929 1.913.51.263 1.075.294 1.08.294 4.26 0 4.477-3.679 4.477-4.414 0-.108-.004-1.615-1.198-2.8-1.081-1.07-2.861-1.613-5.288-1.613-1.348 0-4.185.682-6.29 3.224-2.543 3.08-3.215 7.913-1.94 13.982 3.85 18.356 28.676 24.727 28.927 24.787l-1.095 4.285zm18.762.064l-1.34-4.21c.223-.064 22.548-7.199 28.897-25.107 1.946-5.473 1.812-9.8-.403-12.87-2.138-2.975-5.763-4.052-7.675-4.052-6.64 0-6.717 4.233-6.717 4.414.018 1.017.39 4.414 4.478 4.414 1.008-.027 2.24-.43 2.24-2.207h4.477c0 4.852-4.017 6.62-6.717 6.62-6.573 0-8.956-5.285-8.956-8.827 0-3.052 2.34-8.827 11.195-8.827 3.406 0 8.405 1.845 11.332 5.905 2.129 2.957 4.027 8.293.98 16.887-7.106 20.024-30.785 27.55-31.79 27.86z"
                    className="svg-trophy"
                />
                <path
                    d="M162.355 92.563h-22.26V88.14c0-2.442 8.905-6.635 8.905-6.635h4.451s8.904 4.193 8.904 6.635v4.423zm11.13-79.621c0 17.694-4.639 55.293-22.26 55.293-17.62 0-22.259-37.6-22.259-55.293h44.519z"
                    className="svg-trophy"
                />
                <path
                    d="M152.656 25.916l2.335 6.888 7.294.084c.627.007.887.804.384 1.177l-5.852 4.34 2.173 6.94c.187.597-.493 1.089-1.005.727l-5.95-4.205-5.951 4.204c-.512.362-1.192-.13-1.005-.727l2.173-6.94-5.852-4.34c-.503-.373-.243-1.17.384-1.177l7.295-.084 2.334-6.888c.201-.592 1.043-.592 1.243.001z"
                    fill="#000"
                    fillRule="nonzero"
                    opacity={0.15}
                />
                <path
                    d="M173.625 12.942L134.289 49.8c-2.346-7.684-3.848-15.198-4.506-22.542-.658-7.344-.546-12.116.337-14.316h43.505z"
                    fillOpacity={0.15}
                    fill="#FFF"
                />
                <path
                    className="svg-trophy-base"
                    fillRule="nonzero"
                    d="M135.891 90.621h31.658V108.1h-31.658z"
                />
                <path className="svg-trophy" d="M139.848 96.447h22.755v4.855h-22.755z" />
            </g>
        </svg>
    )
}

export default ImgPodium
