import * as React from "react"

function Free(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M12 4.5A2.5 2.5 0 0114.5 2c1.27 0 2.307.95 2.467 2.177A4.209 4.209 0 0120.4 8.75a5.244 5.244 0 011.6 3.776 5.245 5.245 0 01-1.606 3.78 3.683 3.683 0 01-3.344 4.098A2.632 2.632 0 0112 19.37V4.5z"
                    fill="#E65748"
                />
                <path
                    d="M12 4.5A2.5 2.5 0 009.5 2c-1.27 0-2.307.95-2.467 2.177A4.209 4.209 0 003.6 8.75 5.244 5.244 0 002 12.526c0 1.485.617 2.824 1.606 3.78a3.683 3.683 0 003.344 4.098A2.632 2.632 0 0012 19.37V4.5z"
                    fill="#F1828F"
                />
                <path
                    d="M12 16.737c0 .84-.545 1.362-1.162 1.525a.537.537 0 00-.417.513c0 .35.335.596.675.51a2.598 2.598 0 001.957-2.548H12zM8.38 9.368h.002c.247 0 .45-.177.51-.418.147-.597.607-.974 1.115-1.107a.534.534 0 00.414-.51v-.135a.532.532 0 00-.418-.51c-.597-.147-.975-.607-1.107-1.116a.533.533 0 00-.51-.414h-.005a.529.529 0 00-.51.661 2.6 2.6 0 00.968 1.444c-.47.35-.817.853-.967 1.444a.529.529 0 00.509.661zM9.322 12a.534.534 0 00-.518.448c-.147.794-.688 1.104-1.18 1.126a5.445 5.445 0 00-1.887-.497.531.531 0 00-.58.525c0 .266.2.5.467.52 1.456.107 2.485.734 3.07 1.867.12.231.4.327.643.234a.53.53 0 00.276-.744 4.43 4.43 0 00-.891-1.165c.542-.317.981-.878 1.124-1.713A.525.525 0 009.322 12z"
                    fill="#E65748"
                />
                <path
                    d="M16.902 9.363c-.49-.022-1.032-.331-1.18-1.125a.533.533 0 00-.518-.449.525.525 0 00-.524.601c.143.835.582 1.395 1.124 1.713a4.434 4.434 0 00-.89 1.166.53.53 0 00.274.744.515.515 0 00.644-.234c.585-1.133 1.614-1.76 3.07-1.867a.516.516 0 00.466-.52.53.53 0 00-.58-.525c-.7.06-1.33.23-1.886.496zM12 11.474h-1.053c0 1.241.818 2.256 1.957 2.547a.534.534 0 00.675-.51.537.537 0 00-.417-.513c-.617-.162-1.162-.685-1.162-1.524zm4.143 3.684a.535.535 0 00-.512.416c-.134.507-.51.966-1.107 1.113a.532.532 0 00-.419.51v.137c0 .247.176.447.414.51.508.132.968.509 1.116 1.105.06.242.262.42.51.42a.53.53 0 00.51-.663 2.596 2.596 0 00-.968-1.443 2.6 2.6 0 00.967-1.44.532.532 0 00-.511-.665z"
                    fill="#F1828F"
                />
            </g>
        </svg>
    )
}

export default Free
