import React, { useEffect, useState, useRef, Fragment } from 'react';
import intl from 'react-intl-universal';
import { managerService } from '../../../services/manager.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ManagerActions } from '../../../redux/ducks/manager';
import { CSVLink } from "react-csv";
import {
    dateMask,
    getTypeCourse,
    getWordByInitials,
    getStatusCourseByType,
    getIconCourseByType
} from '../../../utils/utils';
import {
    Grid, TextField, IconButton, Tooltip, Select, MenuItem,
    FormControl, InputLabel
} from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function TableGeneralActivities(props) {
    const dispatch = useDispatch();
    const [responsive] = useState('standard');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [colName, setColName] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [dataDownload, setDataDownload] = useState(false);
    const [textFilter, setTextFilter] = useState('');
    const [myTeamFilter, setMyTeamFilter] = useState('Y');
    const [usersListSelect, setUsersListSelect] = useState([]);
    const [usersListFilter, setUsersListFilter] = useState([]);
    const [regionalListSelect, setRegionalListSelect] = useState([]);
    const [regionalListFilter, setRegionalListFilter] = useState([]);
    const [subareaListSelect, setSubareaListSelect] = useState([]);
    const [subareaListFilter, setSubareaListFilter] = useState([]);
    const [brandListSelect, setBrandListSelect] = useState([]);
    const [brandListFilter, setBrandListFilter] = useState([]);
    const [costCenterListSelect, setCostCenterListSelect] = useState([]);
    const [costCenterListFilter, setCostCenterListFilter] = useState([]);
    const [obligatoryFilter, setObligatoryFilter] = useState('');
    const [typeCourseFilter, setTypeCourseFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [enableFilter, setEnableFilter] = useState('');
    const [disabledSend, setDisabledSend] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const auth = useSelector(state => state.auth);
    const { generalActivities, usersList, regionalList, subareaList, brandList, costCenterList } = useSelector(state => state.manager);
    const DropdownIndicator = () => (<div className="pl-2 pr-2"><Icon ic="search" color="lighter" className="icon-xxs" /></div>);
    const csvDownload = useRef();
    let data = [];

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(ManagerActions.getManagerUsersList({
                my_team: "Y",
                order_list: 'user_full_name asc',
                limit: 0
            }));

            dispatch(ManagerActions.getRegionalList({
                order_list: 'name asc',
                limit: 0
            }));

            dispatch(ManagerActions.getSubareaList({
                order_list: 'name asc',
                limit: 0
            }));

            dispatch(ManagerActions.getBrandList({
                order_list: 'name asc',
                limit: 0
            }));

            dispatch(ManagerActions.getCostCenterList({
                order_list: 'name asc',
                limit: 0
            }));
        }

        return () => {
            setDidMount(false);
        }
    }, [dispatch, didMount, page, rows, dataDownload]);

    useEffect(() => {
        if (dataDownload && csvDownload.current && csvDownload.current.link) {
            setTimeout(() => {
                csvDownload.current.link.click();
                setDataDownload(false);
            });
        }
    }, [dataDownload]);


    const columns = [
        {
            name: "complete",
            label: intl.get("LABEL_STATUS"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_registration",
            label: intl.get("LABEL_REGISTER"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_full_name",
            label: intl.get("LABEL_NAME"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_role",
            label: intl.get("LABEL_ROLE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "admission_date",
            label: intl.get("LABEL_ADMISSION_DATE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_regional_name",
            label: intl.get("LABEL_REGIONAL"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_user_subarea",
            label: intl.get("LABEL_UNITY"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_user_brand",
            label: intl.get("LABEL_BRAND"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_cost_center",
            label: intl.get("LABEL_USER_COST_CENTER"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "type_course",
            label: intl.get("LABEL_TYPE_COURSE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "name",
            label: intl.get("LABEL_COURSE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "date_start",
            label: intl.get("LABEL_START"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "date_finish",
            label: intl.get("LABEL_END"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "workload",
            label: intl.get("LABEL_WORKLOAD"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "average",
            label: intl.get("LABEL_AVERAGE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "obligatory",
            label: intl.get("LABEL_MANDATORY_COURSE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "enable",
            label: intl.get("LABEL_ENABLED_COURSE"),
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    const options = {
        serverSide: true,
        page: page,
        loading: false,
        count: generalActivities && generalActivities.load === "finished" ? parseInt(generalActivities.data.total_filtered) : 0,
        rowsPerPageOptions: [10, 50, 100],
        print: false,
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        tableBodyHeight: '500px',
        responsive,
        selectableRowsHeader: false,
        textLabels: {
            body: {
                noMatch: intl.get("TABLE_BODY_NO_MATCH"),
                toolTip: intl.get("TABLE_BODY_ORDER"),
                columnHeaderTooltip: column => intl.get("TABLE_BODY_ORDER_BY") + " " + column.label
            },
            pagination: {
                next: intl.get("TABLE_PAGINATION_NEXT"),
                previous: intl.get("TABLE_PAGINATION_PREVIOUS"),
                rowsPerPage: intl.get("TABLE_PAGINATION_ROWS_PAGE"),
                displayRows: intl.get("TABLE_PAGINATION_DISPLAY_ROWS"),
            },
            toolbar: {
                search: intl.get("TABLE_TOOLBAR_SEARCH"),
                downloadCsv: intl.get("TABLE_TOOLBAR_DOWNLOAD"),
                print: intl.get("TABLE_TOOLBAR_PRINT"),
                viewColumns: intl.get("TABLE_TOOLBAR_VIEW_COLUMNS"),
                filterTable: intl.get("TABLE_TOOLBAR_FILTER_TABLE"),
            },
            filter: {
                all: intl.get("TABLE_FILTER_ALL"),
                title: intl.get("TABLE_FILTER_TITLE"),
                reset: intl.get("TABLE_FILTER_RESET"),
            },
            viewColumns: {
                title: intl.get("TABLE_COLUMNS_TITLE"),
                titleAria: intl.get("TABLE_COLUMNS_TITLE_ARIA"),
            },
            selectedRows: {
                text: intl.get("TABLE_ROWS_TEXT"),
                delete: intl.get("TABLE_ROWS_DELETE"),
                deleteAria: intl.get("TABLE_ROWS_DELETE_ARIA"),
            },
        },
        onChangePage: async (currentPage) => {
            setPage(currentPage);
            setLoading(true);
            await dispatch(ManagerActions.getGeneralActivities({
                id_manager: auth.user.id_user,
                offset: currentPage,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                my_team: myTeamFilter,
                ids_users: usersListFilter,
                ids_regional: regionalListFilter,
                ids_sub_area: subareaListFilter,
                ids_brand: brandListFilter,
                ids_cost_center: costCenterListFilter,
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onChangeRowsPerPage: async (numberOfRows) => {
            setRows(numberOfRows);
            setLoading(true);
            await dispatch(ManagerActions.getGeneralActivities({
                id_manager: auth.user.id_user,
                offset: page,
                limit: numberOfRows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                my_team: myTeamFilter,
                ids_users: usersListFilter,
                ids_regional: regionalListFilter,
                ids_sub_area: subareaListFilter,
                ids_brand: brandListFilter,
                ids_cost_center: costCenterListFilter,
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onColumnSortChange: async (col, dir) => {
            setLoading(true);
            setColName(col);
            setOrderBy(dir);
            await dispatch(ManagerActions.getGeneralActivities({
                id_manager: auth.user.id_user,
                offset: page,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : null,
                my_team: myTeamFilter,
                ids_users: usersListFilter,
                ids_regional: regionalListFilter,
                ids_sub_area: subareaListFilter,
                ids_brand: brandListFilter,
                ids_cost_center: costCenterListFilter,
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
                order_list: `${col} ${dir}`
            }));
            setLoading(false);
        },
        customToolbar: () => {
            return <CustomToolbar />
        },
        customSearchRender: () => {
            return (
                EnhancedTableToolbar()
            );
        },
    }

    if (generalActivities && generalActivities.load === "finished") {
        if (!generalActivities.error) {
            generalActivities.data.activities.map((row) => {
                let cols;

                cols = {
                    [columns[0].name]: row.complete
                        ? <div className="text-center pr-3">{getIconCourseByType(row.complete, row.type_course)}</div>
                        : <div className="text-center pr-3">{getIconCourseByType(null, row.type_course)}</div>,
                    [columns[1].name]: row.user_registration
                        ? <div className="text-center pr-3">{row.user_registration}</div>
                        : '-',
                    [columns[2].name]: row.user_full_name
                        ? row.user_full_name
                        : '-',
                    [columns[3].name]: row.user_role
                        ? row.user_role
                        : '-',
                    [columns[4].name]: row.admission_date
                        ? dateMask(row.admission_date)
                        : '-',
                    [columns[5].name]: row.user_regional_name
                        ? row.user_regional_name
                        : '-',
                    [columns[6].name]: row.user_user_subarea
                        ? row.user_user_subarea
                        : '-',
                    [columns[7].name]: row.user_user_brand
                        ? row.user_user_brand
                        : '-',
                    [columns[8].name]: row.user_cost_center
                        ? row.user_cost_center
                        : '-',
                    [columns[9].name]: row.type_course
                        ? getTypeCourse(row.type_course)
                        : '-',
                    [columns[10].name]: row.name
                        ? row.name
                        : '-',
                    [columns[11].name]: row.date_start
                        ? dateMask(row.date_start)
                        : '-',
                    [columns[12].name]: row.date_finish
                        ? dateMask(row.date_finish)
                        : '-',
                    [columns[13].name]: row.workload
                        ? row.workload
                        : '-',
                    [columns[14].name]: row.average
                        ? row.average + "%"
                        : '-',
                    [columns[15].name]: row.obligatory
                        ? <div className="text-center pr-3">{getWordByInitials(row.obligatory)}</div>
                        : '-',
                    [columns[16].name]: row.enable
                        ? <div className="text-center pr-3">{getWordByInitials(row.enable)}</div>
                        : '-',
                }

                return data.push(cols);
            })
        }
    }

    async function filterTableList() {
        setUsersListFilter(usersListSelect.length > 0 ? usersListSelect.map((item) => item.user_id).join() : []);
        setRegionalListFilter(regionalListSelect.length > 0 ? regionalListSelect.map((item) => item.regional_id).join() : []);
        setSubareaListFilter(subareaListSelect.length > 0 ? subareaListSelect.map((item) => item.sub_area_id).join() : []);
        setBrandListFilter(brandListSelect.length > 0 ? brandListSelect.map((item) => item.brand_id).join() : []);
        setCostCenterListFilter(costCenterListSelect.length > 0 ? costCenterListSelect.map((item) => item.cost_center_id).join() : []);
        setLoading(true);
        await dispatch(ManagerActions.getGeneralActivities({
            id_manager: auth.user.id_user,
            offset: 0,
            limit: rows,
            filter: textFilter ? 'filtro_geral:' + textFilter : "",
            my_team: myTeamFilter,
            ids_users: usersListSelect.length > 0 ? usersListSelect.map((item) => item.user_id).join() : "",
            ids_regional: regionalListSelect.length > 0 ? regionalListSelect.map((item) => item.regional_id).join() : "",
            ids_sub_area: subareaListSelect.length > 0 ? subareaListSelect.map((item) => item.sub_area_id).join() : "",
            ids_brand: brandListSelect.length > 0 ? brandListSelect.map((item) => item.brand_id).join() : "",
            ids_cost_center: costCenterListSelect.length > 0 ? costCenterListSelect.map((item) => item.cost_center_id).join() : "",
            obligatory: obligatoryFilter,
            type_course: typeCourseFilter,
            status: statusFilter,
            enable: enableFilter,
            order_list: `${colName} ${orderBy}`
        }));

        setLoading(false);
    }

    const getDownload = async () => {
        let ret;
        let csv = [[
            columns[0].label,
            columns[1].label,
            columns[2].label,
            columns[3].label,
            columns[4].label,
            columns[5].label,
            columns[6].label,
            columns[7].label,
            columns[8].label,
            columns[9].label,
            columns[10].label,
            columns[11].label,
            columns[12].label,
            columns[13].label,
            columns[14].label,
            columns[15].label,
            columns[16].label
        ]];

        setLoadingDownload(true);

        try {
            ret = await managerService.getGeneralActivities({
                id_manager: auth.user.id_user,
                offset: 0,
                limit: 0,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                my_team: myTeamFilter,
                ids_users: usersListFilter,
                ids_regional: regionalListFilter,
                ids_sub_area: subareaListFilter,
                ids_brand: brandListFilter,
                ids_cost_center: costCenterListFilter,
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
            });

            ret.activities.map(row => {
                const list = [
                    row.complete ? getStatusCourseByType(row.complete, row.type_course) : getStatusCourseByType(null, row.type_course),
                    row.user_registration ? row.user_registration : '-',
                    row.user_full_name ? row.user_full_name : '-',
                    row.user_role ? row.user_role : '-',
                    row.admission_date ? dateMask(row.admission_date) : '-',
                    row.user_regional_name ? row.user_regional_name : '-',
                    row.user_user_subarea ? row.user_user_subarea : '-',
                    row.user_user_brand ? row.user_user_brand : '-',
                    row.user_cost_center ? row.user_cost_center : '-',
                    row.type_course ? getTypeCourse(row.type_course) : '-',
                    row.name ? row.name : '-',
                    row.date_start ? dateMask(row.date_start) : '-',
                    row.date_finish ? dateMask(row.date_finish) : '-',
                    row.workload ? row.workload : '-',
                    row.average ? row.average + "%" : '-',
                    row.obligatory ? getWordByInitials(row.obligatory) : '-',
                    row.enable ? getWordByInitials(row.enable) : '-',
                ]

                return csv.push(list);
            });

            setDataList(csv);
            setLoadingDownload(false);
            setDataDownload(true);
        } catch (err) {
            console.log(err);
            setLoadingDownload(false);
        }
    }

    const handleChangeMyTeam = async (e) => {
        setMyTeamFilter(e.target.value);
        setUsersListSelect([]);
        setUsersListFilter([]);
        setTextFilter("");

        if (e.target.value === "N") {
            if (regionalListSelect.length > 0 || subareaListSelect.length > 0 || brandListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }

        setLoadingUsers(true);
        await dispatch(ManagerActions.getManagerUsersList({
            my_team: e.target.value,
            order_list: 'user_full_name asc',
            limit: 0
        }));
        setLoadingUsers(false);
    }

    const CustomToolbar = () => {
        return (
            !loading && (generalActivities && generalActivities.load === "finished") ?
                !generalActivities.error ?
                    !loadingDownload ?
                        <Fragment>
                            <Tooltip title={intl.get("TABLE_TOOLBAR_DOWNLOAD")}>
                                <IconButton color="primary" onClick={getDownload}>
                                    <Icon ic="download" />
                                </IconButton>
                            </Tooltip>
                            <CSVLink
                                data={dataList}
                                ref={csvDownload}
                                separator={";"}
                                filename={`${intl.get("MANAGER_TABLE_GENERAL_ACTIVITIES_TITLE").split(' ').join('_').toLowerCase()}.csv`}
                            />
                        </Fragment>
                        :
                        <LoadingBounce size={24} />
                    :
                    <LoadingBounce size={24} />
                :
                <LoadingBounce size={24} />
        )
    }

    const handleTextCourse = (val) => {
        setTextFilter(val);

        if (myTeamFilter === "N") {
            if (val.length > 0 || usersListSelect.length > 0 || regionalListSelect.length > 0 || subareaListSelect.length > 0 || brandListSelect.length > 0 || costCenterListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }
    }

    const handleChangeUser = (val) => {
        setUsersListSelect(val);

        if (myTeamFilter === "N") {
            if (val.length > 0 || textFilter.length > 0 || regionalListSelect.length > 0 || subareaListSelect.length > 0 || brandListSelect.length > 0 || costCenterListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }
    }

    const handleChangeRegional = (val) => {
        setRegionalListSelect(val);

        if (myTeamFilter === "N") {
            if (val.length > 0 || textFilter.length > 0 || usersListSelect.length > 0 || subareaListSelect.length > 0 || brandListSelect.length > 0 || costCenterListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }
    }

    const handleChangeSubarea = (val) => {
        setSubareaListSelect(val);

        if (myTeamFilter === "N") {
            if (val.length > 0 || textFilter.length > 0 || regionalListSelect.length > 0 || usersListSelect.length > 0 || brandListSelect.length > 0 || costCenterListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }
    }

    const handleChangeBrand = (val) => {
        setBrandListSelect(val);

        if (myTeamFilter === "N") {
            if (val.length > 0 || textFilter.length > 0 || regionalListSelect.length > 0 || subareaListSelect.length > 0 || usersListSelect.length > 0 || costCenterListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }
    }

    const handleChangeCostCenter = (val) => {
        setCostCenterListSelect(val);

        if (myTeamFilter === "N") {
            if (val.length > 0 || textFilter.length > 0 || regionalListSelect.length > 0 || subareaListSelect.length > 0 || usersListSelect.length > 0 || brandListSelect.length > 0) {
                setDisabledSend(false);
            } else {
                setDisabledSend(true);
            }
        } else {
            setDisabledSend(false);
        }
    }

    const MultiSelect = (props) => {
        const { label, list, listId, listLabel, select, selectVal } = props;
        const [selectSearch, setSelectSearch] = useState('');

        const loadOptions = (inputValue, callback) => {
            setTimeout(() => callback(list.filter((item) => item[listLabel].normalize("NFD").replace(/[^a-zA-Z\s]/g, "").toLowerCase().includes(inputValue.normalize("NFD").replace(/[^a-zA-Z\s]/g, "").toLowerCase())).slice(0, 20)), 1000);
        };

        const handleChange = (e) => {
            select(e);
        }

        return (
            <FormControl className="w-100" disabled={list.length === 0}>
                <InputLabel shrink>
                    {label}
                </InputLabel>
                <AsyncSelect
                    isMulti
                    loadOptions={loadOptions}
                    getOptionLabel={option => option[listLabel]}
                    getOptionValue={option => option[listId]}
                    className={`qr-multiselect${selectSearch.length === 0 ? " hide-menu" : ""}`}
                    classNamePrefix="qr"
                    onChange={e => handleChange(e)}
                    onInputChange={e => setSelectSearch(e)}
                    value={selectVal}
                    components={{
                        DropdownIndicator
                    }}
                    placeholder={intl.get("LABEL_SEARCH")}
                    loadingMessage={() => intl.get("LABEL_LOADING")}
                    noOptionsMessage={() => intl.get("NO_RESULTS_FOUND")}
                />
            </FormControl>
        );
    }

    const EnhancedTableToolbar = () => {
        return (
            <Grid container direction="row" alignItems="center" className="table-custom-search flex-wrap" spacing={2}>

                <Grid item xs={12} md={6}>
                    <FormControl className="w-100">
                        <InputLabel id="myteam-filter-label">
                            {intl.get("LABEL_TEAM")}
                        </InputLabel>
                        <Select
                            labelId="myteam-filter-label"
                            id="myteam-filter-select"
                            value={myTeamFilter}
                            onChange={handleChangeMyTeam}
                        >
                            <MenuItem value="Y">{intl.get("LABEL_MY_TEAM")}</MenuItem>
                            <MenuItem value="N">{intl.get("LABEL_ALL_COLLABORATORS")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} align="center">
                    {
                        !loadingUsers && (usersList && usersList.load === "finished") ?
                            !usersList.error ?
                                <MultiSelect
                                    label={intl.get("LABEL_COLLABORATOR")}
                                    select={(sel) => handleChangeUser(sel)}
                                    selectVal={usersListSelect}
                                    list={usersList.data.users}
                                    listId="user_id"
                                    listLabel="label_view" />
                                :
                                <LoadingBounce size={24} />
                            :
                            <LoadingBounce size={24} />
                    }
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="filter_text"
                        label={intl.get("LABEL_COURSE")}
                        value={textFilter}
                        onChange={(e) => handleTextCourse(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6} align="center">
                    {
                        regionalList && regionalList.load === "finished" ?
                            !regionalList.error ?
                                <MultiSelect
                                    label={intl.get("LABEL_REGIONAL")}
                                    select={(sel) => handleChangeRegional(sel)}
                                    selectVal={regionalListSelect}
                                    list={regionalList.data.regional}
                                    listId="regional_id"
                                    listLabel="name" />
                                :
                                <LoadingBounce size={24} />
                            :
                            <LoadingBounce size={24} />
                    }
                </Grid>
                <Grid item xs={12} md={6} align="center">
                    {
                        subareaList && subareaList.load === "finished" ?
                            !subareaList.error ?
                                <MultiSelect
                                    label={intl.get("LABEL_UNITY")}
                                    select={(sel) => handleChangeSubarea(sel)}
                                    selectVal={subareaListSelect}
                                    list={subareaList.data.sub_areas}
                                    listId="sub_area_id"
                                    listLabel="name" />
                                :
                                <LoadingBounce size={24} />
                            :
                            <LoadingBounce size={24} />
                    }
                </Grid>
                <Grid item xs={12} md={6} align="center">
                    {
                        brandList && brandList.load === "finished" ?
                            !brandList.error ?
                                <MultiSelect
                                    label={intl.get("LABEL_BRAND")}
                                    select={(sel) => handleChangeBrand(sel)}
                                    selectVal={brandListSelect}
                                    list={brandList.data.brand}
                                    listId="brand_id"
                                    listLabel="name" />
                                :
                                <LoadingBounce size={24} />
                            :
                            <LoadingBounce size={24} />
                    }
                </Grid>
                <Grid item xs={12} md={6} align="center">
                    {
                        costCenterList && costCenterList.load === "finished" ?
                            !costCenterList.error ?
                                <MultiSelect
                                    label={intl.get("LABEL_USER_COST_CENTER")}
                                    select={(sel) => handleChangeCostCenter(sel)}
                                    selectVal={costCenterListSelect}
                                    list={costCenterList.data.cost_centers}
                                    listId="cost_center_id"
                                    listLabel="name" />
                                :
                                <LoadingBounce size={24} />
                            :
                            <LoadingBounce size={24} />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className="w-100">
                        <InputLabel id="type-course-filter-label">
                            {intl.get("LABEL_TYPE_COURSE")}
                        </InputLabel>
                        <Select
                            labelId="type-course-filter-label"
                            id="type-course-filter-select"
                            value={typeCourseFilter}
                            onChange={(e) => setTypeCourseFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            {
                                auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y' && <MenuItem value="C">{intl.get("TRAIL_TITLE")}</MenuItem>
                            }
                            {
                                auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y' && <MenuItem value="S">{intl.get("SCORM_TITLE")}</MenuItem>
                            }
                            {
                                auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y' && <MenuItem value="T">{intl.get("CLASSROOM_TITLE")}</MenuItem>
                            }
                            {
                                auth.modules.EXAM &&  auth.modules.EXAM.ENABLED === 'Y' && <MenuItem value="E">{intl.get("EXAM_TITLE")}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className="w-100">
                        <InputLabel id="status-filter-label">
                            {intl.get("LABEL_STATUS")}
                        </InputLabel>
                        <Select
                            labelId="status-filter-label"
                            id="status-filter-select"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="NS">{intl.get("LABEL_NOT_STARTED")}</MenuItem>
                            <MenuItem value="IP">{intl.get("LABEL_IN_PROGRESS")}</MenuItem>
                            <MenuItem value="DO">{intl.get("LABEL_COMPLETED")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className="w-100">
                        <InputLabel id="obligatory-filter-label">
                            {intl.get("LABEL_MANDATORY_COURSE")}
                        </InputLabel>
                        <Select
                            labelId="obligatory-filter-label"
                            id="obligatory-filter-select"
                            value={obligatoryFilter}
                            onChange={(e) => setObligatoryFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="Y">{intl.get("LABEL_YES")}</MenuItem>
                            <MenuItem value="N">{intl.get("LABEL_NO")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className="w-100">
                        <InputLabel id="enable-filter-label">
                            {intl.get("LABEL_ENABLED_COURSE")}
                        </InputLabel>
                        <Select
                            labelId="enabled-filter-label"
                            id="enabled-filter-select"
                            value={enableFilter}
                            onChange={(e) => setEnableFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="Y">{intl.get("LABEL_YES")}</MenuItem>
                            <MenuItem value="N">{intl.get("LABEL_NO")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md align="right">
                    <IconButton onClick={() => filterTableList()} disabled={disabledSend}>
                        <Icon ic="send" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    return (
        <MUIDataTable
            className="qr-table"
            title={intl.get("MANAGER_TABLE_GENERAL_ACTIVITIES_TITLE")}
            data={
                !loading && (generalActivities && generalActivities.load === "finished") ?
                    !generalActivities.error ?
                        data
                        :
                        [[<ErrorContent type="section" />]]
                    :
                    [[<Grid container direction="row" alignItems="center" justify="center"><LoadingBounce /></Grid>]]
            }
            columns={
                !loading && (generalActivities && generalActivities.load === "finished") ?
                    !generalActivities.error ?
                        columns
                        :
                        [[]]
                    :
                    [[]]
            }
            options={options}
        />
    )
}
