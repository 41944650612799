import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import * as yup from 'yup';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../../redux/ducks/auth';
import { Form, Formik } from 'formik';
import {
    Button, TextField, Grid, InputAdornment, IconButton, FormControl,
    FormGroup, FormControlLabel, Checkbox, FormHelperText, Typography,
    Link
} from '@material-ui/core';
import ButtonSignup from './ButtonSignup';
import SnackBar from '../../../components/snackBar';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function FormLoginEmail(props) {
    const { page } = props;
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const loading = useSelector(state => state.auth.loading);
    const [loginData, setLoginData] = useState({});
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCodeField, setShowCodeField] = useState(false);
    const [iconFocus, setIconFocus] = useState("");
    const [captcha, setCaptcha] = useState(config.captcha.type === 'invisible' ? true : false);
    let showAccessWorkConfirmation = config.login_method.email.login.show_access_work_confirmation;

    const validations = yup.object().shape({
        login: yup.string().email(() => intl.get('ERROR_INVALID_EMAIL')).required(() => intl.get('ERROR_REQUIRED_FIELD')),
        activation_code: showCodeField ? yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')) : null,
        auth_password: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
        accessWorkConfirmation: showAccessWorkConfirmation ? yup.boolean().oneOf([true], intl.get('ERROR_ACCESS_WORK_CONFIRMATION')) : null
    });

    // --- Google Captcha ---
    const loginApi = function (data) {
        setLoginData({ ...loginData, ...data });

        if (config.captcha.type === 'invisible') {
            window.grecaptcha.execute();
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        loginData.auth_captcha = token;

        if (config.captcha.type === 'invisible') {
            loginData.token_captcha_invisible = "Y";
        }

        setLoginData({ ...loginData, auth_captcha: token });
        setCaptcha(true);
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (loginData.login && loginData.auth_captcha && captcha) {
            setCaptcha(false);
            window.grecaptcha.reset();
            dispatch(AuthActions.isLoading(true));
            dispatch(AuthActions.login(loginData));

            if (config.captcha.type === 'invisible') {
                delete loginData.auth_captcha;
            }
        }

        if (auth.loginError && auth.loginError.length > 0) {
            if (auth.errorMessage === "MISSING_ACTIVATION_CODE") {
                setShowCodeField(true);
            } else {
                setLoginData({})
            }

            try {
                window.grecaptcha.reset();
            } catch (err) {
                if (loading)
                    dispatch(AuthActions.isLoading(false));
            }
            delete loginData.auth_captcha;
            setCaptcha(config.captcha.type === 'invisible' ? true : false);
            setSnackStatus(auth.status)
            setSnackOpen(true);
            setSnackMessage(auth.loginError);
            auth.loginError = "";
            auth.status = "";
        }

        if (showCodeField) {
            setShowCodeField(false);

            history.push({
                pathname: `${history.location.pathname.replace(/./g, '')}/access-code`,
                state: {
                    login: loginData.login,
                    auth_password: loginData.auth_password,
                    redirect: "N"
                }
            });
        }
    }, [auth.loginError, auth.errorMessage, auth.status, captcha, dispatch, history, loading, loginData, showCodeField]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleFocusIn = (e) => {
        if (e.currentTarget === e.target) {
            setIconFocus(e.target.name);
        }
    };

    const handleFocusOut = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIconFocus("")
        }
    };

    return (
        <Formik
            validationSchema={validations}
            initialValues={{
                login: '',
                auth_password: '',
                auth_provider: 'QRANIO',
                param_login: 'email',
                accessWorkConfirmation: false
            }}
            onSubmit={(data) => { loginApi(data); }}
        >
            {
                ({ values, handleBlur, handleChange, handleSubmit, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Grid container justify="center" spacing={2}>

                            {/* E-mail */}
                            <Grid item xs={12}>
                                <Grid container alignItems="center" className="flex-nowrap" spacing={1}>
                                    <Grid item>
                                        <Icon ic="envelope_filled" color={iconFocus === "login" ? "primary" : "light"} />
                                    </Grid>
                                    <Grid item className="w-100">
                                        <TextField
                                            fullWidth
                                            name="login"
                                            autoComplete="login"
                                            type="email"
                                            label={intl.get('LABEL_EMAIL')}
                                            variant="outlined"
                                            size="small"
                                            onBlur={e => {
                                                handleBlur(e)
                                                handleFocusOut(e)
                                            }}
                                            onChange={handleChange}
                                            onFocus={handleFocusIn}
                                            value={values.login}
                                            error={(errors.login && touched.login)}
                                            helperText={(errors.login && touched.login) && errors.login}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Password */}
                            <Grid item xs={12}>
                                <Grid container alignItems="center" className="flex-nowrap" spacing={1}>
                                    <Grid item>
                                        <Icon ic="lock" color={iconFocus === "auth_password" ? "primary" : "light"} />
                                    </Grid>
                                    <Grid item className="w-100">
                                        <TextField
                                            fullWidth
                                            name="auth_password"
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete="password"
                                            label={intl.get('LABEL_PASSWORD')}
                                            variant="outlined"
                                            size="small"
                                            onBlur={e => {
                                                handleBlur(e)
                                                handleFocusOut(e)
                                            }}
                                            onChange={handleChange}
                                            onFocus={handleFocusIn}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            error={(errors.auth_password && touched.auth_password)}
                                            helperText={(errors.auth_password && touched.auth_password) && errors.auth_password}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Work Schedule Confirmation */}
                            {
                                showAccessWorkConfirmation ?
                                    <Grid item xs={12} className="mb-3">
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    className="intro-access-confirmation"
                                                    control={<Checkbox color="primary" checked={values.accessWorkConfirmation} onChange={handleChange} name="accessWorkConfirmation" />}
                                                    label={intl.get('INTRO_LOGIN_ACCESS_WORK_TEXT')}
                                                    inputprops={{ 'aria-label': intl.get('INTRO_LOGIN_ACCESS_WORK_TEXT') }}
                                                />
                                            </FormGroup>
                                            <FormHelperText error>
                                                {(errors.accessWorkConfirmation && touched.accessWorkConfirmation) && errors.accessWorkConfirmation}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    : null
                            }

                            {/* Captcha */}
                            <Grid container justify="center">
                                {config.captcha.type === 'invisible'
                                    ? <div className="g-recaptcha"
                                        data-sitekey={config.captcha.invisible_key}
                                        data-callback="myCallback"
                                        data-size="invisible">
                                    </div>
                                    : <div className="g-recaptcha mt-3"
                                        data-sitekey={config.captcha.key}
                                        data-callback="myCallback"
                                    >
                                    </div>
                                }
                            </Grid>

                            {/* Button */}
                            <Grid item xs={12} sm={8}>
                                {
                                    !loading
                                        ?
                                        <Button
                                            id="btn_intro_login_email_send"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className="btn-block"
                                            onSubmit={() => this.onSubmit()}
                                        >
                                            {intl.get('BTN_LOGIN')}
                                        </Button>
                                        :
                                        <Grid container justify="center">
                                            <LoadingBounce />
                                        </Grid>
                                }
                            </Grid>

                            {
                                /* Recover Password */
                                config.login_method.email.forgot_password.enabled ?
                                    <Grid item xs={12} align="center">
                                        {
                                            config.app.name_project === "ASSAI" ?
                                                <Typography variant="body2" component="div" className="mt-3">
                                                    {`${intl.get('INTRO_LOGIN_RECOVERY_PASSWORD_TEXT_1')} `}
                                                    <Link
                                                        href="https://assai.service-now.com/sp?id=sc_cat_item&sys_id=057a013b1be5a810eca40dcbe54bcbbe&sysparm_category=8a71e44f1bbed11032822170f54bcba2"
                                                        target="_blank"
                                                        rel="noopener"
                                                        className="cursor-pointer"
                                                    >
                                                        {intl.get('INTRO_LOGIN_RECOVERY_PASSWORD_TEXT_2')}
                                                    </Link>
                                                    {` ${intl.get('INTRO_LOGIN_RECOVERY_PASSWORD_TEXT_3')}`}
                                                </Typography>
                                                :
                                                <Button
                                                    color="primary"
                                                    onClick={() => history.push({
                                                        pathname: '/recover-password',
                                                        state: {
                                                            redirect: 'N',
                                                            type: 'email'
                                                        }
                                                    })}
                                                >
                                                    {intl.get('BTN_RECOVER_PASSWORD')}
                                                </Button>
                                        }
                                    </Grid>
                                    : null
                            }

                            {
                                /* Signup */
                                page && (config.login_method.email.signup.enabled && config.login_method.email.signup.display_button.login.enabled) ?
                                    <Grid item xs={12}>
                                        <ButtonSignup type="email" buttonStyle={config.login_method.email.signup.display_button.login.style} />
                                    </Grid>
                                    : null
                            }
                        </Grid>
                        <SnackBar
                            open={snackOpen}
                            message={snackMessage}
                            status={snackStatus}
                            time={4}
                            closeSnack={() => setSnackOpen(false)}
                        />
                    </Form>
                )
            }
        </Formik>
    )
}
