import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { Container, Grid, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

var HtmlToReactParser = require('html-to-react').Parser;

export default function TermsOfUse(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const termsOfUse = useSelector(state => state.auth.termsOfUse);

    useEffect(() => {
        dispatch(AuthActions.getTermsOfUse({
            language: auth.user.language,
            type: 'TERMS_OF_USE'
        }));
    }, [auth, dispatch]);

    function getTerms() {
        var htmlInput = termsOfUse.data;
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement = htmlToReactParser.parse(htmlInput);
        return (reactElement);
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FAQ_INFO_TERMS_TITLE")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {intl.get("FAQ_INFO_TITLE")}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="faq-terms" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md">
                        {
                            !getTerms() ?
                                <LoadingContent />
                                :
                                <Grid container justify="center" className="pb-3">
                                    <Grid item xs={12} sm={10} xl={12}>
                                        {getTerms()}
                                    </Grid>
                                </Grid>
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}
