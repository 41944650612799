import { FeedsService } from '../../services/feeds.service';

// CONST
export const Types = {
    FEEDS: 'feeds/FEEDS',
    LOADMORE: 'feeds/LOADMORE',
    LIKE: 'feeds/LIKE',
    COMMENTS: 'feeds/COMMENTS',
    ADD_COMMENT: 'feeds/ADD_COMMENT',
    DELETE_COMMENT: 'feeds/DELETE_COMMENT',
    LIKE_COMMENT: 'feeds/FEEDS_LIKE_COMMENT',
    PAGE: 'feeds/PAGE',
    SEND_EMAIL: 'feeds/SEND_EMAIL',
    RESET: 'feeds/RESET',
    LOGOUT: 'feeds/LOGOUT'
};

// INITIAL STATE
const initialState = {
    feeds: {},
    hasMore: true,
    page: 1,
    like: {},
    comments: {},
    sendEmail: {},
    totalComments: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.FEEDS:
            return {
                ...state,
                hasMore: action.payload.data.length >= 10 ? true : false,
                feeds: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LOADMORE:
            return {
                ...state,
                hasMore: [...state.feeds.data, ...action.payload.data].length > state.feeds.data.length ? true : false,
                feeds: {
                    data: [...state.feeds.data, ...action.payload.data],
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LIKE:
            return {
                ...state,
                feeds: {
                    ...state.feeds,
                    data: action.payload.data,
                },
                like: {
                    liked: action.payload.liked,
                    total: action.payload.total,
                }
            }
        case Types.COMMENTS:
            return {
                ...state,
                comments: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                    total: action.payload.total
                }
            }
        case Types.ADD_COMMENT:
            return {
                ...state,
                comments: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                    total: action.payload.total
                }
            }
        case Types.DELETE_COMMENT:
            return {
                ...state,
                comments: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                    total: action.payload.total
                }
            }
        case Types.LIKE_COMMENT:
            return {
                ...state,
                comments: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                    total: action.payload.total
                }
            }
        case Types.PAGE:
            return {
                ...state,
                page: action.payload.page
            }
        case Types.SEND_EMAIL:
            return {
                ...state,
                sendEmail: {
                    sent: action.payload.sent,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                comments: action.payload.comments,
                sendEmail: action.payload.sendEmail
            }
        case Types.LOGOUT:
            return {
                ...state,
                feeds: {},
                page: 1,
                hasMore: true
            }
        default:
            return state
    }
}

// ACTIONS
function getFeedsList(params) {
    return dispatch => {
        return FeedsService.getList(params).then(data => {
            dispatch({
                type: Types.FEEDS,
                payload: {
                    data: data.news,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FEEDS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getLoadMore(params) {
    return dispatch => {
        return FeedsService.getList(params).then(data => {
            dispatch({
                type: Types.LOADMORE,
                payload: {
                    data: data.news,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LOADMORE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setPage(params) {
    return dispatch => {
        dispatch({
            type: Types.PAGE,
            payload: {
                page: params.page,
            }
        });
    }
}

function setLike(params) {
    return dispatch => {
        return FeedsService.setLike(params).then(data => {
            params.feeds.find(feed => feed.id_feed === params.id_feed).user_liked = params.liked;
            params.feeds.find(feed => feed.id_feed === params.id_feed).total_like = params.like_count;

            dispatch({
                type: Types.LIKE,
                payload: {
                    data: params.feeds,
                    liked: data.liked,
                    total: data.total
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        })
    }
}

function getCommentsList(params) {
    return dispatch => {
        return FeedsService.getListComment(params).then(data => {
            dispatch({
                type: Types.COMMENTS,
                payload: {
                    data: data.comments,
                    load: 'finished',
                    error: false,
                    messages: '',
                    total: parseInt(data.total)
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COMMENTS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    total: 0
                }
            });
        })
    }
}

function addComment(params) {
    return dispatch => {
        return FeedsService.addComment(params).then(data => {
            dispatch({
                type: Types.ADD_COMMENT,
                payload: {
                    data: data.comments,
                    load: 'finished',
                    error: false,
                    messages: '',
                    total: parseInt(data.total)
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ADD_COMMENT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    total: 0
                }
            });
        })
    }
}

function deleteComment(params) {
    return dispatch => {
        return FeedsService.deleteComment(params).then(data => {
            dispatch({
                type: Types.DELETE_COMMENT,
                payload: {
                    data: data.comments,
                    load: 'finished',
                    error: false,
                    messages: '',
                    total: parseInt(data.total)
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.DELETE_COMMENT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    total: 0
                }
            });
        })
    }
}

function setLikeComment(params) {
    return dispatch => {
        return FeedsService.setLikeComment(params).then(data => {
            dispatch({
                type: Types.LIKE_COMMENT,
                payload: {
                    data: data.comments,
                    load: 'finished',
                    error: false,
                    messages: '',
                    total: parseInt(data.total)
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIKE_COMMENT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    total: 0
                }
            });
        })
    }
}

function sendEmail(params) {
    return dispatch => {
        return FeedsService.sendEmail(params).then(data => {
            dispatch({
                type: Types.SEND_EMAIL,
                payload: {
                    sent: data.sent,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SEND_EMAIL,
                payload: {
                    sent: false,
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            })
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET,
            payload: {
                comments: {},
                sendEmail: {},
            }
        });
    }
}

function logout() {
    return dispatch => {
        dispatch({
            type: Types.LOGOUT
        });
    }
}

export const actions = {
    getFeedsList,
    getLoadMore,
    setPage,
    setLike,
    getCommentsList,
    addComment,
    deleteComment,
    setLikeComment,
    sendEmail,
    reset,
    logout
}
