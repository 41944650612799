import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import {
    Grid, Container, AppBar, Toolbar, IconButton, Button,
    Typography
} from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import { Icon, Ribbon } from '../../../components/Images/Images';
import ImgTimeout from '../../../dist/img/svgr/ImgTimeout';

const ExamTimeOut = (props) => {
    const { history } = useReactRouter();
    const [redirect, setRedirect] = useState(false);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        if (origin === "questions") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }
    }, [origin]);

    function goBack() {
        let currentOrigin = "timeout";

        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
            state: {
                origin: currentOrigin
            }
        })
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    {
                        config.layout.navbar ?
                            <IconButton
                                color="inherit"
                                aria-label="close"
                                edge="start"
                                onClick={() => goBack()}
                            >
                                <Icon ic="close" />
                            </IconButton>
                            : null
                    }
                    <div className="w-100 overflow-hidden"></div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        !redirect ?
            <div id="qr-wrap">
                <QrMenu />
                <div id="qr-content">
                    <QrContent id="exam-timeout" hideSidebar>
                        <QrAppBar />
                        <Container maxWidth="md" className="vh-100">
                            <Grid container justify="center" alignContent="center" className="h-100">
                                <Grid item xs={12} sm={10} lg={8} align="center">
                                    <Ribbon
                                        type="straight-chalk"
                                        text={intl.get('EXAM_TIMEOUT_TITLE')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} align="center" className="timeout-image">
                                    <ImgTimeout />
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} align="center">
                                    <Typography variant="body1" component="p">
                                        {intl.get('EXAM_TIMEOUT_TEXT')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} align="center" className="mt-3">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={10} sm={8} lg={6}>
                                            <Button
                                                id="btn_exam_timeout"
                                                variant="contained"
                                                className="btn-block"
                                                onClick={() => history.push({
                                                    pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                                                    state: {
                                                        origin: 'timeout'
                                                    }
                                                })}
                                            >
                                                {intl.get('BTN_EXIT')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </QrContent>
                </div>
            </div>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                state: {
                    origin: 'timeout'
                }
            }} />
    )
}

export default ExamTimeOut