import React from 'react';
import { numberMask } from '../utils/utils';
import { Typography } from '@material-ui/core';
import { Points } from './Images/Images';

export default function PointsBar(props) {
    const { type, value, className } = props;

    return (
        <div className={`points-bar ${className}`}>
            <div className="points-bar-icon">
                <Points type={type} className="img-sm" />
            </div>
            <div className="points-bar-content">
                <Typography variant="h5" className={`text-${type}`} component="span">
                    {numberMask(value)}
                </Typography>
            </div>
        </div>
    );
}