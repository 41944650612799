import React from 'react';
import config from '../../../../config';
import {
    Card, CardContent, CardActionArea, CardMedia, Typography,
    CircularProgress, LinearProgress
} from '@material-ui/core';
import { imageParser } from '../../../../utils/utils';
import { Icon } from '../../../../components/Images/Icons';
import intl from "react-intl-universal";

export default function CategoryItem(props) {
    const { action, type, image, bgColor, title, status, totalCompleted, totalLessons, obligatory } = props;

    return (
        <div className={`category-list-item${config.learn.scorm.categories.grid.style === "card" ? " __card" : " __default"}`}>
            <div className={`category-list-item-content${config.learn.scorm.categories.grid.size === "large" ? " __large" : ""}`}>
                <Card>
                    <CardActionArea onClick={action}>
                        {
                            obligatory && obligatory === "Y" ?
                                <>
                                    <div className="category-flag __obligatory"></div>
                                    <div className="category-flag-label">
                                        {intl.get("LABEL_OBLIGATORY")}
                                    </div>
                                </>
                                : null
                        }
                        <CardMedia
                            className={`category-image${type !== "themes" ? " __banner" : ""}${config.learn.scorm.categories.grid.mobile === "full" ? " __full" : ""}`}
                            style={bgColor ? { backgroundColor: bgColor } : null}
                            image={imageParser(image)}
                            title={title}
                        />
                        <CardContent className="category-text">
                            <div className="category-text-info">
                                <Typography variant="body2" component="h4">
                                    {title}
                                </Typography>
                            </div>
                            {
                                config.learn.scorm.categories.progress.enabled &&
                                <div className="category-text-progress">
                                    {
                                        config.learn.scorm.categories.progress.type === "circular" ?
                                            <div className={`category-text-progress-circular __${config.learn.scorm.categories.progress.position}`}>
                                                {
                                                    status === "Y" ?
                                                        <Icon ic="checked" className="icon-xs __finished" />
                                                        :
                                                        totalCompleted && totalLessons &&
                                                        <div className="circular-progress">
                                                            <div className="progress">
                                                                <CircularProgress className="progress-inactive" variant="determinate" value={100} size={20} thickness={5} />
                                                                <CircularProgress className="progress-active" variant="determinate" value={totalCompleted / totalLessons * 100} size={20} thickness={5} />
                                                            </div>
                                                        </div>
                                                }
                                                {
                                                    config.learn.trail.categories.progress.label && totalCompleted && totalLessons &&
                                                    <Typography variant="caption" component="p" className="font-weight-bold ml-2">
                                                        {totalCompleted + ' / ' + totalLessons}
                                                    </Typography>
                                                }
                                            </div>
                                            :
                                            <LinearProgress className={status === "Y" ? "__completed" : ""} variant="determinate" value={totalCompleted / totalLessons * 100} size={20} thickness={5} />
                                    }
                                </div>
                            }
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    )
}
