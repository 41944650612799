import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../../config";
import useReactRouter from 'use-react-router';
import { myHistoryService } from '../../../services/my_history.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as MyHistoryActions } from '../../../redux/ducks/my_history';
import { dateMask, getTypeCourse, download_csv, getWordByInitials, getIconCourseByType, dateGreaterThen } from '../../../utils/utils';
import {
    Grid, TextField, IconButton, Tooltip, FormControl, InputLabel,
    Select, MenuItem
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function TableActivities(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [responsive] = useState('standard');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [colName, setColName] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [loading, setLoading] = useState(false);
    const [download, setDownload] = useState(false);
    const [textFilter, setTextFilter] = useState('');
    const [obligatoryFilter, setObligatoryFilter] = useState('');
    const [typeCourseFilter, setTypeCourseFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const { userActivities } = useSelector(state => state.myHistory);
    const auth = useSelector(state => state.auth);
    let data = [];
    let downloadList = [];

    const columns = [
        {
            name: "complete",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_STATUS"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "type_course",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_COURSE_TYPE"),
            options: {
                display: config.my_history.my_activities.type_course,
                filter: false,
                sort: true,
            }
        },
        {
            name: "name",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_COURSE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "type",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_TYPE"),
            options: {
                display: config.my_history.my_activities.type,
                filter: false,
                sort: true,
            }
        },
        {
            name: "date_start",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_START_TIME"),
            options: {
                display: config.my_history.my_activities.date_start,
                filter: false,
                sort: true,
            }
        },
        {
            name: "date_finish",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_END_TIME"),
            options: {
                display: config.my_history.my_activities.date_finish,
                filter: false,
                sort: true,
            }
        },
        {
            name: "workload",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_WORKLOAD"),
            options: {
                display: config.my_history.my_activities.workload,
                filter: false,
                sort: true,
            }
        },
        {
            name: "certificate_validity",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_CERTIFICATE_VALIDITY"),
            options: {
                display: config.my_history.my_activities.certificate_validity,
                filter: false,
                sort: true,
            }
        },
        {
            name: "do_until",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_DO_UNTIL"),
            options: {
                display: config.my_history.my_activities.do_until,
                filter: false,
                sort: true,
            }
        },
        {
            name: "obligatory",
            label: intl.get("MY_HISTORY_MY_ACTIVITIES_OBLIGATORY"),
            options: {
                display: config.my_history.my_activities.obligatory,
                filter: false,
                sort: true,
            }
        },
    ];

    const options = {
        serverSide: true,
        page: page,
        loading: false,
        count: userActivities && userActivities.load === "finished" ? parseInt(userActivities.data.total_filtered) : 0,
        rowsPerPageOptions: [10, 50, 100],
        print: false,
        filter: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        responsive,
        selectableRowsHeader: false,
        textLabels: {
            body: {
                noMatch: intl.get("TABLE_BODY_NO_MATCH"),
                toolTip: intl.get("TABLE_BODY_ORDER"),
                columnHeaderTooltip: column => intl.get("TABLE_BODY_ORDER_BY") + " " + column.label
            },
            pagination: {
                next: intl.get("TABLE_PAGINATION_NEXT"),
                previous: intl.get("TABLE_PAGINATION_PREVIOUS"),
                rowsPerPage: intl.get("TABLE_PAGINATION_ROWS_PAGE"),
                displayRows: intl.get("TABLE_PAGINATION_DISPLAY_ROWS"),
            },
            toolbar: {
                search: intl.get("TABLE_TOOLBAR_SEARCH"),
                downloadCsv: intl.get("TABLE_TOOLBAR_DOWNLOAD"),
                print: intl.get("TABLE_TOOLBAR_PRINT"),
                viewColumns: intl.get("TABLE_TOOLBAR_VIEW_COLUMNS"),
                filterTable: intl.get("TABLE_TOOLBAR_FILTER_TABLE"),
            },
            filter: {
                all: intl.get("TABLE_FILTER_ALL"),
                title: intl.get("TABLE_FILTER_TITLE"),
                reset: intl.get("TABLE_FILTER_RESET"),
            },
            viewColumns: {
                title: intl.get("TABLE_COLUMNS_TITLE"),
                titleAria: intl.get("TABLE_COLUMNS_TITLE_ARIA"),
            },
            selectedRows: {
                text: intl.get("TABLE_ROWS_TEXT"),
                delete: intl.get("TABLE_ROWS_DELETE"),
                deleteAria: intl.get("TABLE_ROWS_DELETE_ARIA"),
            },
        },
        onChangePage: async (currentPage) => {
            setPage(currentPage);
            setLoading(true);
            await dispatch(MyHistoryActions.getProfileActivities({
                offset: currentPage,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onChangeRowsPerPage: async (numberOfRows) => {
            setRows(numberOfRows);
            setLoading(true);
            await dispatch(MyHistoryActions.getProfileActivities({
                offset: page,
                limit: numberOfRows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onRowClick: (obj, index) => {
            if (userActivities.data.user_activities[index.rowIndex].enable === "Y") {
                if (userActivities.data.user_activities[index.rowIndex].type_course === "course") {
                    history.push({
                        pathname: `${history.location.pathname.replace(/./g, '')}/trail/${userActivities.data.user_activities[index.rowIndex].id}`,
                        state: {
                            origin: 'myhistory'
                        }
                    });
                }
                else if (userActivities.data.user_activities[index.rowIndex].type_course === "scorm") {
                    history.push({ pathname: `${history.location.pathname.replace(/./g, '')}/scorm/${userActivities.data.user_activities[index.rowIndex].id}/details` });
                } else {
                    history.push({ pathname: `${history.location.pathname.replace(/./g, '')}/classroom/${userActivities.data.user_activities[index.rowIndex].id}` })
                }
            }
        },
        onColumnSortChange: async (col, dir) => {
            setLoading(true);
            setColName(col);
            setOrderBy(dir);
            await dispatch(MyHistoryActions.getProfileActivities({
                offset: page,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                order_list: `${col} ${dir}`
            }));
            setLoading(false);
        },
        customToolbar: () => {
            return <CustomToolbar />
        },
        customSearchRender: () => {
            return (
                EnhancedTableToolbar()
            );
        },
    }

    async function filterTableList() {
        setLoading(true);
        await dispatch(MyHistoryActions.getProfileActivities({
            offset: page,
            limit: rows,
            filter: textFilter ? 'filtro_geral:' + textFilter : "",
            obligatory: obligatoryFilter,
            type_course: typeCourseFilter,
            status: statusFilter,
            order_list: `${colName} ${orderBy}`
        }));
        setLoading(false);
    }

    if ((userActivities && userActivities.load === "finished") && userActivities.data.length !== 0) {
        userActivities.data.user_activities.map((row) => {
            let cols = {
                [columns[0].name]: row.complete
                    ? <div className="text-center pr-3">{getIconCourseByType(row.complete, row.type_course)}</div>
                    : <div className="text-center pr-3">{getIconCourseByType(null, row.type_course)}</div>,
                [columns[1].name]: row.type_course
                    ? getTypeCourse(row.type_course)
                    : '-',
                [columns[2].name]: row.name
                    ? row.name
                    : '-',
                [columns[3].name]: row.type
                    ? row.type
                    : '-',
                [columns[4].name]: row.date_start
                    ? dateMask(row.date_start)
                    : '-',
                [columns[5].name]: row.date_finish
                    ? dateMask(row.date_finish)
                    : '-',
                [columns[6].name]: row.workload
                    ? row.workload
                    : '-',
                [columns[7].name]: row.certificate_validity
                    ? dateMask(row.certificate_validity)
                    : '-',
                [columns[8].name]: row.do_until
                    ? dateGreaterThen(row.do_until) ?
                        <span className="text-danger">{row.do_until}</span>
                        :
                        <span>{row.do_until}</span>
                    : '-',
                [columns[9].name]: row.obligatory
                    ? getWordByInitials(row.obligatory)
                    : '-'
            }
            return data.push(cols);
        })
    } else {
        data.push({});
    }

    async function downloadCSV() {
        let ret;
        let csv = `${intl.get("LABEL_NAME")};${config.my_history.my_activities.type_course ? columns[1].label + ';' : ""}${columns[2].label};${config.my_history.my_activities.type ? columns[3].label + ';' : ""}${config.my_history.my_activities.date_start ? columns[4].label + ';' : ""}${config.my_history.my_activities.date_finish ? columns[5].label + ';' : ""}${config.my_history.my_activities.workload ? columns[6].label + ';' : ""}${config.my_history.my_activities.certificate_validity ? columns[7].label + ';' : ""}${config.my_history.my_activities.do_until ? columns[8].label + ';' : ""}${config.my_history.my_activities.obligatory ? columns[9].label + ';' : ""}${columns[0].label}\n`;

        setDownload(true);

        try {
            ret = await myHistoryService.getProfileActivities({
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
            });
        }
        catch (err) {
            console.log(err)
        }

        ret.user_activities.map(row => {
            const list = [
                row.user_full_name ? row.user_full_name : '-',
                config.my_history.my_activities.type_course ? row.type_course ? getTypeCourse(row.type_course) : '-' : null,
                row.name ? row.name : '-',
                config.my_history.my_activities.type ? row.type ? row.type : '-' : null,
                config.my_history.my_activities.date_start ? row.date_start ? dateMask(row.date_start) : '-' : null,
                config.my_history.my_activities.date_finish ? row.date_finish ? dateMask(row.date_finish) : '-' : null,
                config.my_history.my_activities.workload ? row.workload ? row.workload : '-' : null,
                config.my_history.my_activities.certificate_validity ? row.certificate_validity ? dateMask(row.certificate_validity) : '-' : null,
                config.my_history.my_activities.do_until ? row.do_until ? row.do_until : '-' : null,
                config.my_history.my_activities.obligatory ? row.obligatory ? getWordByInitials(row.obligatory) : '-' : null,
                row.type_course === "classroom" ? row.complete ? row.complete === 'Y' ? intl.get("MY_HISTORY_MY_ACTIVITIES_PRESENT") : intl.get("MY_HISTORY_MY_ACTIVITIES_ABSENT") : intl.get("MY_HISTORY_MY_ACTIVITIES_NOT_RELEASED") : row.complete ? row.complete === 'Y' ? intl.get("MY_HISTORY_MY_ACTIVITIES_FINISHED") : intl.get("MY_HISTORY_MY_ACTIVITIES_IN_PROGRESS") : intl.get("MY_HISTORY_MY_ACTIVITIES_NOT_STARTED"),
            ]

            return downloadList.push(list.filter(x => x != null));
        })

        download_csv(csv, downloadList);
        setDownload(false);
    }

    const CustomToolbar = () => {
        return (
            <Tooltip title={intl.get("TABLE_TOOLBAR_DOWNLOAD")}>
                <IconButton onClick={() => downloadCSV()}>
                    {download ? <LoadingBounce size={24} /> : <Icon ic="download" />}
                </IconButton>
            </Tooltip>
        )
    }

    const EnhancedTableToolbar = () => {
        return (
            <Grid container direction="row" alignItems="center" className="table-custom-search" spacing={2}>
                <Grid item xs={12} md>
                    <TextField
                        fullWidth
                        id="filter_text"
                        label={intl.get("TABLE_TOOLBAR_SEARCH")}
                        value={textFilter}
                        onChange={(e) => setTextFilter(e.target.value)}
                    />
                </Grid>
                {
                    config.my_history.my_activities.obligatory ?
                        <Grid item xs={12} md>
                            <FormControl className="w-100">
                                <InputLabel id="obligatory-filter-label">
                                    {intl.get("LABEL_OBLIGATORY")}
                                </InputLabel>
                                <Select
                                    labelId="obligatory-filter-label"
                                    id="obligatory-filter-select"
                                    value={obligatoryFilter}
                                    onChange={(e) => setObligatoryFilter(e.target.value)}
                                >
                                    <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                                    <MenuItem value="Y">{intl.get("LABEL_YES")}</MenuItem>
                                    <MenuItem value="N">{intl.get("LABEL_NO")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        : null
                }
                {
                    config.my_history.my_activities.type_course ?
                        <Grid item xs={12} md>
                            <FormControl className="w-100">
                                <InputLabel id="type-course-filter-label">
                                    {intl.get("LABEL_TYPE_COURSE")}
                                </InputLabel>
                                <Select
                                    labelId="type-course-filter-label"
                                    id="type-course-filter-select"
                                    value={typeCourseFilter}
                                    onChange={(e) => setTypeCourseFilter(e.target.value)}
                                >
                                    <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                                    {
                                        auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y' && <MenuItem value="C">{intl.get("TRAIL_TITLE")}</MenuItem>
                                    }
                                    {
                                        auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y' && <MenuItem value="S">{intl.get("SCORM_TITLE")}</MenuItem>
                                    }
                                    {
                                        auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y' && <MenuItem value="T">{intl.get("CLASSROOM_TITLE")}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        : null
                }
                <Grid item xs={12} md>
                    <FormControl className="w-100">
                        <InputLabel id="status-filter-label">
                            {intl.get("LABEL_STATUS")}
                        </InputLabel>
                        <Select
                            labelId="status-filter-label"
                            id="status-filter-select"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="NS">{intl.get("LABEL_NOT_STARTED")}</MenuItem>
                            <MenuItem value="IP">{intl.get("LABEL_IN_PROGRESS")}</MenuItem>
                            <MenuItem value="DO">{intl.get("LABEL_COMPLETED")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md>
                    <IconButton onClick={() => filterTableList()}>
                        <Icon ic="send" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    return (
        <MUIDataTable
            className="qr-table table-click"
            title={intl.get("MY_HISTORY_MY_ACTIVITIES_TITLE")}
            data={
                !loading && (userActivities && userActivities.load === "finished") ?
                    !userActivities.error ?
                        data
                        :
                        [[<ErrorContent type="section" />]]
                    :
                    [[<Grid container direction="row" alignItems="center" justify="center"><LoadingBounce /></Grid>]]
            }
            columns={
                !loading && (userActivities && userActivities.load === "finished") ?
                    !userActivities.error ?
                        columns
                        :
                        [[]]
                    :
                    [[]]
            }
            options={options}
        />
    )
}
