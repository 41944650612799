import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#056ec2', // Blue[200]
            main: '#0460a9', // Blue[300]
            dark: '#035290', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#e95d38', // Dark Blue[200]
            main: '#E74A21', // Dark Blue[300]
            dark: '#d73f17', // Dark Blue[400]
            contrastText: '#fff',
        },
        error: {
            light: '#E86C5D', // Red[200]
            main: '#E65748', // Red[300]
            dark: '#E24331', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
