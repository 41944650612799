import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Grid, Divider, Button, useMediaQuery } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from '../../Learn/Trail/components/CategoryItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingCategoryGrid, LoadingCategoryList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function OngoingCoursesSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const ongoing = useSelector(state => state.home.ongoing);
    const isScreenMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const limit = 10;

    useEffect(() => {
        dispatch(HomeActions.getOngoingCoursesList({
            limit: limit,
            page: 1,
            last_play: 'Y'
        }));
    }, [dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                imageType={config.home.sections.ongoing_courses.image_type}
                image={config.home.sections.ongoing_courses.image}
                sectionColor={config.home.sections.ongoing_courses.section_color}
                icon={config.home.sections.ongoing_courses.icon}
                iconColor={config.home.sections.ongoing_courses.icon_color}
                title={intl.get("ONGOING_COURSES_TITLE")}
                subtitle={intl.get("ONGOING_COURSES_SUBTITLE_SECTION")}
                action={() => history.push('/trail/ongoing')}
                cardStyle={config.learn.trail.categories.grid.style}
                list={props.list ? props.list : false}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionContent = () => {
        return (
            <Section list={isScreenMobile && config.home.sections.trail.mobile_grid === "grid"}>
                {
                    isScreenMobile && config.home.sections.trail.mobile_grid === "grid" ?
                        <>
                            {
                                ongoing.data.slice(0, 6).map((item, index) => {
                                    return (
                                        <Grid item key={index} xs={config.learn.trail.categories.grid.mobile === "full" ? 12 : 6} sm={config.learn.trail.categories.grid.mobile === "full" ? 6 : 4} md={4} lg={3}>
                                            <CategoryItem
                                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course}`, state: { origin: "home" } })}
                                                bgColor={item.bg_color}
                                                image={item.category_image ? item.category_image : item.image}
                                                title={item.title}
                                                currentStars={item.current_stars}
                                                totalStars={item.total_stars}
                                                currentStep={item.current_step}
                                                totalStep={item.total_step}
                                                complete={item.complete}
                                                obligatory={item.obligatory}
                                                available={item.available}
                                                dependency={item.dependency_course_name ? item.dependency_course_name : null}
                                                dateRelease={item.date_course_release ? item.date_course_release : null}
                                                workload={item.workload ? item.workload : null}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                ongoing.data.length > 6 ?
                                    <Grid item container xs={12} justify="center" className="mb-4">
                                        <Button
                                            onClick={() => history.push('/trail/ongoing')}
                                            className="btn-block-xs"
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {intl.get("BUTTON_SEE_ALL")}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </>
                        :
                        <SectionCategoryGrid action={() => history.push('/trail/ongoing')} size={config.learn.trail.categories.grid.size}>
                            {
                                ongoing.data.map((item, index) => {
                                    return (
                                        <CategoryItem
                                            key={index}
                                            action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course}`, state: { origin: "home" } })}
                                            bgColor={item.bg_color}
                                            image={item.category_image ? item.category_image : item.image}
                                            title={item.title}
                                            currentStars={item.current_stars}
                                            totalStars={item.total_stars}
                                            currentStep={item.current_step}
                                            totalStep={item.total_step}
                                            complete={item.complete}
                                            obligatory={item.obligatory}
                                            available={item.available}
                                            dependency={item.dependency_course_name ? item.dependency_course_name : null}
                                            dateRelease={item.date_course_release ? item.date_course_release : null}
                                            workload={item.workload ? item.workload : null}
                                        />
                                    )
                                })
                            }
                        </SectionCategoryGrid>
                }
            </Section>
        )
    }

    return (
        ongoing && ongoing.load === "finished" ?
            !ongoing.error ?
                ongoing.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        <SectionContent />
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="ongoing_courses" className="icon-xxl" />}
                                    text={intl.get("EMPTY_ONGOING_COURSES")}
                                    padding={true}
                                    
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        {
                            isScreenMobile && config.home.sections.trail.mobile_grid === "grid" ?
                                <div className="pl-3 pr-3">
                                    <LoadingCategoryList limit={6} mobileSize={config.learn.trail.categories.grid.mobile} />
                                </div>
                                :
                                <LoadingCategoryGrid action={() => history.push('/trail/ongoing')} limit={limit} size={config.learn.trail.categories.grid.size} />
                        }
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    {
                        isScreenMobile && config.home.sections.trail.mobile_grid === "grid" ?
                            <div className="pl-3 pr-3">
                                <LoadingCategoryList limit={6} mobileSize={config.learn.trail.categories.grid.mobile} />
                            </div>
                            :
                            <LoadingCategoryGrid action={() => history.push('/trail/ongoing')} limit={limit} size={config.learn.trail.categories.grid.size} />
                    }
                </Section>
            </div>
    )
};
