import { freeService } from '../../services/free.service';

// CONST
export const Types = {
    LIST: 'free/LIST',
    SUBCATEGORIES: 'free/SUBCATEGORIES',
    BUY_CATEGORY: 'free/BUY_CATEGORY',
    PLAY: 'free/PLAY',
    ANSWER: 'free/ANSWER',
    WARNING: 'free/WARNING',
    RESET: 'free/RESET',
};

// INITIAL STATE
const initialState = {
    list: {},
    subcategories: {},
    purchase: {},
    questions: {},
    result: {},
    warning: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SUBCATEGORIES:
            return {
                ...state,
                subcategories: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BUY_CATEGORY:
            return {
                ...state,
                purchase: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.PLAY:
            return {
                ...state,
                questions: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ANSWER:
            return {
                ...state,
                result: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.WARNING:
            return {
                ...state,
                warning: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                list: {},
                subcategories: {},
                purchase: {},
                questions: {},
                result: {},
                warning: {}
            }
        default:
            return state
    }
}

// ACTIONS
function getList(params) {
    return dispatch => {
        return freeService.getList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.categories,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getListSubcategories(params) {
    return dispatch => {
        return freeService.getList(params).then(data => {
            dispatch({
                type: Types.SUBCATEGORIES,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SUBCATEGORIES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getBuyCategory(params) {
    return dispatch => {
        return freeService.getBuyCategory(params).then(data => {
            dispatch({
                type: Types.BUY_CATEGORY,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BUY_CATEGORY,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setPlay(params) {
    return dispatch => {
        return freeService.setPlay(params).then(data => {
            dispatch({
                type: Types.PLAY,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PLAY,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setAnswer(params) {
    return dispatch => {
        return freeService.setAnswer(params).then(data => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setWarningProcess(params) {
    return dispatch => {
        return freeService.setWarningProcess(params).then(data => {
            dispatch({
                type: Types.WARNING,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.WARNING,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getList,
    getListSubcategories,
    getBuyCategory,
    setPlay,
    setAnswer,
    setWarningProcess,
    reset,
}
