import { qrService } from './base.service'

function getList(params) {
    return qrService('/v4/app/periodic-question/list', params)
}

function getQuestion(params) {
    return qrService('/v4/app/periodic-question/play', params)
}

function getNextQuestion(params) {
    return qrService('/v4/app/periodic-question/play/next', params)
}

function sendAnswer(params) {
    return qrService('/v4/app/periodic-question/answer', params)
}

export const periodicService = {
    getList,
    getQuestion,
    getNextQuestion,
    sendAnswer
}