import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Grid, Divider, Button, useMediaQuery } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from '../../Learn/Free/components/CategoryItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingCategoryGrid, LoadingCategoryList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function FreeSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const free = useSelector(state => state.home.free);
    const [didMount, setDidMount] = useState(false);
    const isScreenMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(HomeActions.getFreeList({
                limit: limit,
                page: 1
            }));

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                imageType={config.home.sections.free.image_type}
                image={config.home.sections.free.image}
                sectionColor={config.home.sections.free.section_color}
                icon={config.home.sections.free.icon}
                iconColor={config.home.sections.free.icon_color}
                title={intl.get("FREE_TITLE")}
                subtitle={intl.get("FREE_SUBTITLE_SECTION")}
                action={() => history.push('/free')}
                cardStyle={config.learn.free.categories.grid.style}
                list={props.list ? props.list : false}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionContent = () => {
        return (
            <Section list={isScreenMobile && config.home.sections.free.mobile_grid === "grid"}>
                {
                    isScreenMobile && config.home.sections.free.mobile_grid === "grid" ?
                        <>
                            {
                                free.data.slice(0, 6).map((item, index) => {
                                    return (
                                        <Grid item key={index} xs={config.learn.free.categories.grid.mobile === "full" ? 12 : 6} sm={config.learn.free.categories.grid.mobile === "full" ? 6 : 4} md={4} lg={3}>
                                            <CategoryItem
                                                item={item}
                                                origin="home"
                                                path={props.match.path}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                free.data.length > 6 ?
                                    <Grid item container xs={12} justify="center" className="mb-4">
                                        <Button
                                            onClick={() => history.push('/free')}
                                            className="btn-block-xs"
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {intl.get("BUTTON_SEE_ALL")}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </>
                        :
                        <SectionCategoryGrid action={() => history.push('/free')} size={config.learn.free.categories.grid.size}>
                            {
                                free.data.map((item, index) => {
                                    return (
                                        <CategoryItem
                                            key={index}
                                            item={item}
                                            origin="home"
                                            path={props.match.path}
                                        />
                                    )
                                })
                            }
                        </SectionCategoryGrid>
                }
            </Section>
        )
    }

    return (
        free && free.load === "finished" ?
            !free.error ?
                free.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        <SectionContent />
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="brain_filled" className="icon-xxl" />}
                                    text={intl.get("EMPTY_FREE")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        {
                            isScreenMobile && config.home.sections.free.mobile_grid === "grid" ?
                                <div className="pl-3 pr-3">
                                    <LoadingCategoryList limit={6} mobileSize={config.learn.free.categories.grid.mobile} />
                                </div>
                                :
                                <LoadingCategoryGrid action={() => history.push('/free')} limit={limit} size={config.learn.free.categories.grid.size} />
                        }
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    {
                        isScreenMobile && config.home.sections.free.mobile_grid === "grid" ?
                            <div className="pl-3 pr-3">
                                <LoadingCategoryList limit={6} mobileSize={config.learn.free.categories.grid.mobile} />
                            </div>
                            :
                            <LoadingCategoryGrid action={() => history.push('/free')} limit={limit} size={config.learn.free.categories.grid.size} />
                    }
                </Section>
            </div>
    )
};

