import * as React from "react";

const LogoIcon = (props) => (
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#5C7A96"
                d="M46.424 8c6.159-.034 10.983 1.689 14.474 5.17 3.491 3.48 5.255 8.468 5.292 14.963l.117 20.545a4.253 4.253 0 0 1-4.23 4.277l-1.536.009a4.253 4.253 0 0 1-4.277-4.23l-.115-20.165c-.023-4.218-.888-7.25-2.593-9.095-1.704-1.847-4.086-2.76-7.145-2.743-3.058.017-5.428.958-7.113 2.824-1.684 1.865-2.514 4.906-2.49 9.124l.114 20.165a4.254 4.254 0 0 1-4.228 4.278l-1.413.008a4.253 4.253 0 0 1-4.277-4.23l-.117-20.544c-.037-6.496 1.67-11.503 5.123-15.023 3.452-3.52 8.257-5.298 14.414-5.332"
            />
            <path
                fill="#E91A22"
                d="M46.481 24.049a6.851 6.851 0 0 1-.433-.014c-2.508-.159-4.661 1.736-4.65 4.248l.1 23.038c.024 4.219-.806 7.26-2.49 9.124-1.684 1.866-4.055 2.806-7.113 2.824-3.059.017-5.44-.896-7.144-2.743-1.706-1.846-2.57-4.877-2.594-9.095l-.114-20.166a4.253 4.253 0 0 0-4.278-4.229l-1.536.009A4.253 4.253 0 0 0 12 31.322l.117 20.545c.036 6.495 1.8 11.483 5.291 14.963 3.492 3.481 8.316 5.204 14.475 5.17 6.157-.035 10.962-1.812 14.414-5.333 3.452-3.52 5.16-8.527 5.122-15.022l-.101-23.317c-.01-2.422-2.018-4.431-4.437-4.291a6.834 6.834 0 0 1-.4.011m21.807 40.99a6.805 6.805 0 1 1-13.61 0 6.805 6.805 0 0 1 13.61 0"
            />
        </g>
    </svg>
)

export default LogoIcon
