import React from 'react';
import { identifyIphone } from '../../../../utils/utils';
import { CardMedia, Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import FeedCardFooter from '../FeedCardFooter';
import VideoDialog from '../../../../components/VideoDialog';

export default function CardLinkWeb(props) {
  const [isFilter, setFilter] = React.useState(false);
  const { image, description, reference } = props;
  let isDomain = (process.env.REACT_APP_DOMAIN && reference) && JSON.parse(reference).link.includes(process.env.REACT_APP_DOMAIN) ? true : false;

  const handleClose = () => {
    setFilter(false);
  };

  function openEmbed() {
    if (!isDomain) {
      if (identifyIphone()) {
        window.open(JSON.parse(reference).link);
      } else if (JSON.parse(reference).link.includes('pdf')) {
        setFilter(true);
      } else {
        window.open(JSON.parse(reference).link, '_blank');
      }
    } else {
      window.location.assign(JSON.parse(reference).link)
    }
  }

  return (
    <Card className="card card-link-web">
      <CardActionArea
        disabled={reference && reference.link ? false : true}
        onClick={() => openEmbed()}>
        {
          image ?
            <CardMedia
              className="card-image-full"
              image={image}
            />
            :
            null
        }
        <CardContent>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <FeedCardFooter {...props} />
      {isFilter &&
        <VideoDialog isFilter={isFilter} handleClose={handleClose} data={props} type={'link'} />
      }
    </Card>
  )
}
