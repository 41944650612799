import * as React from "react";

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#FDC436" d="M0 0h512v512H0z" />
                <path
                    fill="#3C3D3B"
                    d="M381.018 422.363c-7.771 25.849-30.013 44.238-59.494 44.238-28.748 0-50.617-17.488-58.89-42.325H148.177c-53.589 0-93.777-40.133-93.777-93.78V220.88c0-67.815 39.462-151.992 110.693-168.9 20.512-4.868 44.66-4.443 64.395 3.342 10.692 4.216 19.155 10.535 25.24 18.54 6.081-8.005 14.545-14.324 25.24-18.54 19.728-7.785 43.88-8.21 64.395-3.342 71.23 16.908 110.69 101.085 110.69 168.9v109.615c0 46.625-30.739 83.276-74.035 91.868"
                />
                <path
                    fill="#FDC436"
                    d="M77.14 243.62v86.875c0 41.65 30.566 71.04 71.036 71.04h124.046c7.91 0 8.684 3.211 10.157 8.28 6.994 45.507 71.641 45.394 78.338-.351 1.063-6.961 3.735-7.786 7.426-8.226 36.864-3.156 64.168-31.706 64.168-70.743V243.62H77.139Z"
                />
                <path
                    fill="#3C3D3B"
                    d="M153.745 272.11h13.009c6.187 0 11.25 5.073 11.25 11.252v21.458h21.46c6.188 0 11.25 5.07 11.25 11.253v13.005c0 6.184-5.07 11.253-11.25 11.253h-21.46v21.461c0 6.184-5.07 11.253-11.25 11.253h-13.009c-6.183 0-11.25-5.062-11.25-11.253v-21.461h-21.46c-6.18 0-11.253-5.062-11.253-11.253v-13.005c0-6.191 5.065-11.253 11.253-11.253h21.46v-21.458c0-6.187 5.063-11.252 11.25-11.252m169.202 100.934c13.371 0 24.21-10.843 24.21-24.207 0-13.372-10.839-24.214-24.21-24.214-13.368 0-24.207 10.842-24.207 24.214 0 13.364 10.839 24.207 24.207 24.207m52.515-52.512c13.367 0 24.21-10.846 24.21-24.214 0-13.368-10.843-24.21-24.21-24.21-13.368 0-24.21 10.842-24.21 24.21s10.842 24.214 24.21 24.214"
                />
                <path
                    fill="#FEFEFE"
                    d="M432.312 220.88H266.1V110.27c0-18.253 8.837-28.52 22.212-33.791 15.337-6.048 34.859-6.15 50.8-2.372 13.152 3.123 25.002 9.153 35.492 17.26-5.685 9.64-15.922 13.28-27.212 10.752-6.638-1.485-12.686-3.439-19.566-3.988-22.47-1.79-43.615 13.243-49.385 35.041-2.657 9.42 2.222 16.935 7.859 18.232 6.191 1.43 12.55-1.301 14.435-10.545 4.61-21.678 24.892-19.273 41.467-15.564 19.537 4.373 38.392-2.137 49.788-17.264 5.447 6.36 10.37 13.317 14.746 20.688-5.385 3.702-10.124 8.698-13.907 14.973-3.555 5.894-6.07 12.481-9.746 18.2-7.038 10.952-18.786 13.8-30.607 9.086-6.32-2.522-11.979-5.41-18.683-7.042-21.904-5.33-45.17 6.158-54.322 26.762-4.116 8.878-.49 17.07 4.868 19.25 5.883 2.387 12.594.708 15.923-8.122 7.994-20.673 27.637-15.083 43.41-8.79 22.363 8.922 46.25 2.148 59.395-18.301 3.907-6.085 6.425-12.686 10.098-18.775 1.32-2.184 2.804-4.039 4.435-5.575 9.695 23.13 14.713 48.194 14.713 70.494M77.14 220.88h166.217V110.27c0-18.253-8.841-28.52-22.213-33.791-15.34-6.048-34.862-6.15-50.803-2.372-13.152 3.123-25.002 9.153-35.489 17.26 5.681 9.64 15.919 13.28 27.212 10.752 6.635-1.485 12.683-3.439 19.563-3.988 22.47-1.79 43.619 13.243 49.384 35.041 2.662 9.42-2.217 16.935-7.855 18.232-6.19 1.43-12.554-1.301-14.434-10.545-4.615-21.678-24.896-19.273-41.467-15.564-19.537 4.373-38.396-2.137-49.788-17.264-5.447 6.36-10.374 13.317-14.75 20.688 5.388 3.702 10.128 8.698 13.91 14.973 3.556 5.894 6.07 12.481 9.747 18.2 7.037 10.952 18.782 13.8 30.603 9.086 6.319-2.522 11.982-5.41 18.686-7.042 21.901-5.33 45.166 6.158 54.319 26.762 4.12 8.878.494 17.07-4.868 19.25-5.88 2.387-12.595.708-15.92-8.122-7.997-20.673-27.637-15.083-43.413-8.79-22.363 8.922-46.25 2.148-59.395-18.301-3.907-6.085-6.422-12.686-10.095-18.775-1.323-2.184-2.807-4.039-4.435-5.575-9.699 23.13-14.717 48.194-14.717 70.494"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon
