import * as React from "react";

const ImgStepAnswered = (props) => (
    <svg
        width={200}
        height={200}
        viewBox="0 0 200 200"
        {...props}
    >
        <g fill="none" fillRule="nonzero">
            <path
                fill="#FFF"
                fillOpacity={0.2}
                d="M4.756 85.784a16.205 16.205 0 0 0 20.503 2.024L13.699 99.4c-3.044 3.053-4.755 7.195-4.755 11.514s1.71 8.46 4.756 11.513a16.214 16.214 0 0 0 11.481 4.77 16.205 16.205 0 0 0 9.021-2.745l-6.066 6.083c-3.045 3.054-4.756 7.195-4.756 11.514s1.711 8.46 4.756 11.513a16.205 16.205 0 0 0 20.503 2.025l-1.482 1.486c-3.045 3.054-4.756 7.195-4.756 11.514s1.71 8.46 4.756 11.514a16.214 16.214 0 0 0 11.481 4.769c4.307 0 8.437-1.716 11.482-4.77l15.386-15.43a16.316 16.316 0 0 0-2.737 9.047c0 4.319 1.711 8.46 4.756 11.514A16.214 16.214 0 0 0 99.007 190c4.306 0 8.436-1.716 11.48-4.77l23.38-23.443a16.315 16.315 0 0 0-2.737 9.046c0 4.319 1.71 8.46 4.755 11.514a16.214 16.214 0 0 0 11.482 4.769c4.306 0 8.436-1.716 11.481-4.769l36.396-36.499A16.305 16.305 0 0 0 200 134.335c0-4.319-1.71-8.46-4.756-11.514a16.205 16.205 0 0 0-20.503-2.025l7.62-7.64a16.306 16.306 0 0 0 4.755-11.513c0-4.319-1.71-8.46-4.756-11.514a16.214 16.214 0 0 0-11.481-4.769 16.205 16.205 0 0 0-9.022 2.745l15.387-15.43A16.306 16.306 0 0 0 182 61.16c0-4.318-1.71-8.46-4.756-11.514a16.214 16.214 0 0 0-11.481-4.769 16.205 16.205 0 0 0-9.021 2.745l9.799-9.827a16.306 16.306 0 0 0 4.755-11.513c0-4.319-1.71-8.46-4.755-11.514A16.214 16.214 0 0 0 155.059 10a16.214 16.214 0 0 0-11.481 4.769L121.75 36.657a16.316 16.316 0 0 0 2.737-9.047c0-4.319-1.71-8.46-4.755-11.514a16.214 16.214 0 0 0-11.482-4.769 16.214 16.214 0 0 0-11.481 4.77L66.133 46.818a16.316 16.316 0 0 0 2.737-9.047c0-4.318-1.71-8.46-4.755-11.513a16.214 16.214 0 0 0-11.482-4.77 16.214 16.214 0 0 0-11.481 4.77L4.756 62.756A16.306 16.306 0 0 0 0 74.27c0 4.318 1.71 8.46 4.756 11.513"
            />
            <path
                className="svg-feedback-continue"
                d="m40.243 154.454 9.956-28.336c-4.718-8.681-7.297-18.69-7.027-29.321C43.974 65.629 70.22 40.069 101.446 40c34.715-.076 62.395 30.078 58.117 65.57-3.107 25.786-23.68 46.768-49.426 50.473-13.736 1.975-26.661-.905-37.471-7.068l-26.956 10.72c-3.316 1.32-6.648-1.874-5.467-5.241Z"
            />
            <path
                fill="#FFF"
                d="M110.332 111.576c0 1.79 2.171 2.687 3.44 1.42l17.886-17.857a4.014 4.014 0 0 0 0-5.688l-17.885-17.857c-1.27-1.267-3.441-.37-3.441 1.42V84.25H98.245c-13.219 0-23.958 10.59-24.172 23.738l-.003.399v16.09a4.027 4.027 0 0 0 4.03 4.023h8.058a4.027 4.027 0 0 0 4.029-4.022v-16.091c0-4.445 3.606-8.045 8.058-8.045h12.089l-.001 11.235Z"
            />
        </g>
    </svg>
)
export default ImgStepAnswered
