import { qrService } from './base.service'

function knowMoreList(params) {
    return qrService('/v3/app/knowmore/list', params)
}

function getKnowMore(params) {
    return qrService('/v3/app/knowmore/get', params)
}

export const knowmoreService = {
    knowMoreList,
    getKnowMore
}