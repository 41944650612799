import React from 'react';
import QuizAnswer from './Game/QuizAnswer';
import SecretWord from './Game/SecretWord';
import TrueOrFalse from './Game/TrueOrFalse';
import OpenAnswer from './Game/OpenAnswer';
import GradeAnswer from './Game/GradeAnswer';
import SelectAnswers from './Game/SelectAnswers';
import RangeAnswer from './Game/RangeAnswer';
import VideoAnswer from './Game/VideoAnswer';
import QuizImageAnswer from './Game/QuizImageAnswer';
import ImageAnswer from './Game/ImageAnswer';

const QrGame = (props) => {
    switch (props.data.question.question_type) {
        case "T_OR_F":
            return <TrueOrFalse {...props} />
        case "FILL_WORD":
            return <SecretWord {...props} />
        case "OPEN_ANSWER":
            return <OpenAnswer {...props} />
        case "GRADE_ANSWER":
            return <GradeAnswer {...props} />
        case "SELECT_ANSWERS":
            return <SelectAnswers {...props} />
        case "RANGE_ANSWER":
            return <RangeAnswer {...props} />
        case "VIDEO_ANSWER":
            return <VideoAnswer {...props} />
        case "IMAGE_ANSWER":
            return <ImageAnswer {...props} />
        case "QUIZ_IMAGE_ANSWERS":
            return <QuizImageAnswer {...props} />
        default:
            return (
                <QuizAnswer {...props} />
            )
    }
}

export default QrGame;