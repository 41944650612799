import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as SettingsActions } from '../../redux/ducks/settings';
import * as yup from 'yup';
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import {
    Container, Grid, TextField, InputAdornment, IconButton, Button,
    AppBar, Toolbar, Typography
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';

export default function ChangePassword(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const changePassword = useSelector(state => state.settings.changePassword);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const regex = config.login_method.password.complex ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%])[a-zA-Z\d]/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;

    const validations = yup.object().shape({
        current_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
        new_password: yup.string()
            .min(config.login_method.password.min, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .matches(regex, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .required(() => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`),
        retype_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            .oneOf([yup.ref('new_password'), null], () => intl.get('ERROR_PASSWORD_NOT_MATCH'))
    });

    function sendData(data) {
        setLoading(true);
        dispatch(SettingsActions.setChangePassword({
            new_pass: sha256(md5(data.new_password).toString(), "new_password").toString(),
            old_pass: sha256(md5(data.current_password).toString(), "password").toString()
        }));
    }

    useEffect(() => {
        if (loading && changePassword?.data?.update === "success") {
            setLoading(false);
            setSnackStatus('success');
            setSnackOpen(true);
            setSnackMessage(intl.get('SUCCESS_CHANGE_PASSWORD'));
            dispatch(SettingsActions.resetChangePassword({}));
        }

        if (loading && changePassword?.error === true) {
            setLoading(false);
            setSnackStatus('error');
            setSnackOpen(true);
            setSnackMessage(changePassword.messages);
            dispatch(SettingsActions.resetChangePassword({}));
        }
    }, [loading, changePassword, dispatch]);

    const handleClickShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("SETTINGS_CHANGE_PASSWORD_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="setings-change-password" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md" className="pt-4 pb-4">
                        <Formik
                            validationSchema={validations}
                            initialValues={{
                                current_password: '',
                                new_password: '',
                                retype_password: ''
                            }}
                            onSubmit={(data) => sendData(data)}
                        >
                            {
                                ({ handleBlur, handleChange, handleSubmit, errors, touched }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Grid container justify="center" spacing={3}>
                                            <Grid item xs={12} sm={10} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="current_password"
                                                    name="current_password"
                                                    autoComplete="off"
                                                    type={showCurrentPassword ? 'text' : 'password'}
                                                    label={intl.get('LABEL_CURRENT_PASSWORD')}
                                                    variant={config.layout.input_variant}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowCurrentPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showCurrentPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                    error={(errors.current_password && touched.current_password)}
                                                    helperText={(errors.current_password && touched.current_password) && errors.current_password}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={10} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="new_password"
                                                    name="new_password"
                                                    autoComplete="new_password"
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    label={intl.get('LABEL_NEW_PASSWORD')}
                                                    variant={config.layout.input_variant}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowNewPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showNewPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                    error={(errors.new_password && touched.new_password)}
                                                    helperText={(errors.new_password && touched.new_password) && errors.new_password}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={10} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="retype_password"
                                                    name="retype_password"
                                                    autoComplete="retype_password"
                                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                                    label={intl.get('LABEL_RETYPE_NEW_PASSWORD')}
                                                    variant={config.layout.input_variant}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowConfirmPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showConfirmNewPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                    error={(errors.retype_password && touched.retype_password)}
                                                    helperText={(errors.retype_password && touched.retype_password) && errors.retype_password}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4}>
                                                {
                                                    !loading
                                                        ?
                                                        <Button
                                                            id="btn_settings_save"
                                                            variant="contained"
                                                            className="btn-block"
                                                            type="submit"
                                                            onSubmit={() => this.onSubmit()}
                                                        >
                                                            {intl.get('BTN_SAVE')}
                                                        </Button>
                                                        :
                                                        <Grid container alignItems="center" justify="center">
                                                            <LoadingBounce />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }
                        </Formik >
                    </Container>
                </QrContent>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    closeSnack={() => setSnackOpen(false)}
                />
            </div>
        </div>
    )
}
