import { qrService } from './base.service';

function getProfileActivities(params) {
    return qrService('/v3/app/profile/activities', params)
}

function getStatisticCoursesAll(params) {
    return qrService('/v3/app/profile/statistic/courses/all', params)
}

function getStatisticCoursesObligatory(params) {
    return qrService('/v3/app/profile/statistic/courses/obligatory', params)
}

export const myHistoryService = {
    getProfileActivities,
    getStatisticCoursesAll,
    getStatisticCoursesObligatory,
}