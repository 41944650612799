import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import * as moment from 'moment';
import {
    Button, TextField, Grid, Typography, Hidden,
    FormControl, MenuItem, Select, IconButton
} from '@material-ui/core';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { Icon } from '../../components/Images/Images';
import { LoadingContent, LoadingBounce } from '../../components/Loading';

export default function FirstAccessQuestions(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [questionId, setQuestionId] = useState("1");
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");
    const [tokenCaptcha, setTokenCaptcha] = useState("");
    const [redirectPage, setRedirectPage] = useState(false);
    const data = props.location.state && props.location.state.data ? props.location.state.data : '';
    const questions = props.location.state && props.location.state.questions ? props.location.state.questions : '';
    const date = props.location.state && props.location.state.date ? props.location.state.date : '';
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';

    // --- Google Captcha ---
    const sendData = async () => {
        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            dispatch(AuthActions.setFirstAccess({
                cpf: data.cpf,
                pincode: data.pincode,
                password: data.password,
                birth_date: moment(date).format('YYYY-MM-DD'),
                questions: JSON.stringify({ [questionId]: answer }),
                auth_captcha: tokenCaptcha,
            }));
            setLoading(true);
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        if (config.captcha.type === 'invisible') {
            dispatch(AuthActions.setFirstAccess({
                cpf: data.cpf,
                pincode: data.pincode,
                password: data.password,
                birth_date: moment(date).format('YYYY-MM-DD'),
                questions: JSON.stringify({ [questionId]: answer }),
                auth_captcha: token,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
            }));
            setLoading(true);
        } else {
            setTokenCaptcha(token);
        }
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (redirect === "N") {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }

        if (loading && auth.firstAccessStatus === "success") {
            window.grecaptcha.reset();
            auth.firstAccessStatus = "";
            setLoading(false);
            setSnackStatus("success");
            setSnackOpen(true);
            setSnackMessage(auth.firstAccess.messages);

            setTimeout(function () {
                history.replace('/');
            }, 4000);
        }

        if (loading && auth.firstAccessStatus === "error") {
            window.grecaptcha.reset();
            setTokenCaptcha("");
            setLoading(false);
            setAnswer("");
            setQuestionId("1");
            setSnackStatus("error");
            setSnackOpen(true);
            setSnackMessage(auth.firstAccess.messages);
            auth.firstAccessStatus = "";
        }
    }, [auth, history, loading, redirect]);


    function handleChange(e) {
        setQuestionId(e.target.value)
    }

    function handleResult(e) {
        setAnswer(e.target.value)
    }

    return (
        !redirectPage ?
            <IntroLayout title={intl.get('INTRO_FIRST_ACCESS_QUESTIONS_TITLE')} appbarMobile="default" backButton={false}>
                {
                    questions ?
                        <Grid container>
                            <Hidden smDown>
                                <Grid container className="intro-column-nav" justify="center" alignItems="center">
                                    <Grid item xs={2}>
                                        <IconButton aria-label={intl.get('BTN_BACK')}
                                            onClick={() => history.goBack()}
                                        >
                                            <Icon ic="back" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography component="h2" variant="h3" align="center" className="text-dark">
                                            {intl.get('INTRO_FIRST_ACCESS_QUESTIONS_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Hidden>
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="p" variant="body2">
                                        {intl.get("INTRO_FIRST_ACCESS_QUESTIONS_TEXT")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="w-100">
                                        <Grid container justify="center" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormControl className="w-100">
                                                            <Select
                                                                value={questionId}
                                                                onChange={handleChange}
                                                                variant={config.layout.input_variant}
                                                            >
                                                                {
                                                                    questions.map((item) => {
                                                                        return <MenuItem key={item.id_question} value={item.id_question}>{item.question}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="answer"
                                                            type="text"
                                                            label={intl.get('LABEL_YOUR_ANSWER')}
                                                            fullWidth
                                                            required
                                                            variant={config.layout.input_variant}
                                                            onChange={handleResult}
                                                            value={answer}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                {
                                                    !loading ?
                                                        <Button
                                                            id="btn_intro_first_access_send"
                                                            variant="contained"
                                                            color="primary"
                                                            className="btn-block"
                                                            onClick={sendData}
                                                        >
                                                            {intl.get("BTN_CONFIRM")}
                                                        </Button>
                                                        :
                                                        <Grid container alignItems="center" justify="center">
                                                            <LoadingBounce />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <LoadingContent />
                }
                {/* Captcha */}
                <Grid container justify="center">
                    {config.captcha.type === 'invisible'
                        ? <div className="g-recaptcha"
                            data-sitekey={config.captcha.invisible_key}
                            data-callback="myCallback"
                            data-size="invisible">
                        </div>
                        : <div className="g-recaptcha mt-3"
                            data-sitekey={config.captcha.key}
                            data-callback="myCallback"
                        >
                        </div>
                    }
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}