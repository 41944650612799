import { qrService } from './base.service';

function getBadgeList(params) {
    return qrService('/v1/app/badge/list/user', params)
}

function setBadgeRedeem(params) {
    return qrService('/v1/app/badge/redeem', params);
}

function getMissionList(params) {
    return qrService('/v3/app/achievements/list', params)
}

function setMissionRedeem(params) {
    return qrService('/v3/app/achievements/redeem', params)
}

export const achievementsService = {
    getBadgeList,
    setBadgeRedeem,
    getMissionList,
    setMissionRedeem,
}
