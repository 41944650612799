import { avatarService } from '../../services/avatar.service';

// CONST
export const Types = {
    LIST: 'avatar/LIST',
    LOADING: 'avatar/LOADING',
    UPDATE: 'avatar/UPDATE',
    PHOTO_UPDATE: 'avatar/PHOTO_UPDATE',
};


// INITIAL STATE

const initialState = {
    avatarList: [],
    loading: false,
    image: null
}

// REDUCER
export default function reducer(state = initialState, action) {
    let userAux = JSON.parse(localStorage.getItem('auth.state.user'));
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                avatarList: action.payload.avatarList,
            }
        case Types.UPDATE:
            userAux.image = action.payload.image;
            localStorage.setItem('auth.state.user', JSON.stringify(userAux));
            return {
                ...state,
                image: action.payload.image,
            }
        case Types.PHOTO_UPDATE:
            userAux.image = action.payload.image;
            localStorage.setItem('auth.state.user', JSON.stringify(userAux));
            return {
                ...state,
            }
        case Types.LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            }
        default:
            return state
    }
}

// ACTIONS
function avatarList(params) {
    return dispatch => {
        dispatch({
            type: Types.LOADING,
            payload: {
                loading: true,
            },
        });
        return avatarService.avatarList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    avatarList: data.avatar_list,
                },
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        }).finally(result => {
            dispatch({
                type: Types.LOADING,
                payload: {
                    loading: false,
                },
            });
        })
    }
}

function avatarUpdate(params) {
    return dispatch => {
        dispatch({
            type: Types.LOADING,
            payload: {
                loading: true,
            },
        });
        return avatarService.avatarUpdate(params).then(data => {
            dispatch({
                type: Types.UPDATE,
                payload: {
                    image: data.image,
                },
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        }).finally(result => {
            dispatch({
                type: Types.LOADING,
                payload: {
                    loading: false,
                },
            });
        })
    }
}

function photoUpdate(params) {
    return dispatch => {
        dispatch({
            type: Types.LOADING,
            payload: {
                loading: true,
            },
        });
        return avatarService.photoUpdate(params).then(data => {
            dispatch({
                type: Types.PHOTO_UPDATE,
                payload: {
                    image: data.image,
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        }).finally(result => {
            dispatch({
                type: Types.LOADING,
                payload: {
                    loading: false,
                },
            });
        })
    }
}

export const actions = {
    avatarList,
    avatarUpdate,
    photoUpdate
}
