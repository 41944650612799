import * as React from "react"

function Duels(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          fill="#DCDCDC"
          d="M20.667 21L8.367 9.14l-1.053 1.017 9.136 10.165z"
        />
        <path
          fill="#C3C3C3"
          d="M20.667 21L8.367 9.14l1.055-1.016 10.542 8.81z"
        />
        <path
          d="M3.273 6.599l2.46-2.372a.7.7 0 00.072-.936l-.073-.08a.766.766 0 00-1.054 0l-2.46 2.371a.7.7 0 00-.072.936l.073.08a.764.764 0 001.054 0zm3.162 4.405L10.3 7.277a.7.7 0 00.073-.936l-.073-.08a.765.765 0 00-1.054 0L5.381 9.987a.7.7 0 00-.073.936l.073.08a.764.764 0 001.054 0z"
          fill="#F7B27B"
        />
        <path fill="#E65748" d="M6.26 9.14l1.053-1.016-2.81-2.71L3.447 6.43z" />
        <path
          fill="#BD483B"
          d="M7.314 8.124l1.054-1.017-2.811-2.71-1.054 1.016z"
        />
        <path
          fill="#DCDCDC"
          d="M3.333 21l12.3-11.86 1.053 1.017L7.55 20.322z"
        />
        <path
          fill="#C3C3C3"
          d="M3.333 21l12.3-11.86-1.055-1.016-10.542 8.81z"
        />
        <path
          d="M20.727 6.599l-2.46-2.372a.7.7 0 01-.072-.936l.073-.08a.766.766 0 011.054 0l2.46 2.371a.7.7 0 01.072.936l-.073.08a.765.765 0 01-1.054 0zm-3.162 4.405L13.7 7.277a.7.7 0 01-.073-.936l.073-.08a.765.765 0 011.054 0l3.865 3.727a.7.7 0 01.073.936l-.073.08a.765.765 0 01-1.054 0z"
          fill="#F7B27B"
        />
        <path
          fill="#E65748"
          d="M17.74 9.14l-1.053-1.016 2.81-2.71 1.055 1.016z"
        />
        <path
          fill="#BD483B"
          d="M16.686 8.124l-1.054-1.017 2.811-2.71 1.054 1.016z"
        />
      </g>
    </svg>
  )
}

export default Duels
