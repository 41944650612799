import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PrizesActions } from '../../../redux/ducks/prizes';
import {
    Card, CardContent, CardActionArea, CardMedia,
    Typography, IconButton, CardActions
} from '@material-ui/core';
import { imageParser, numberMask } from '../../../utils/utils';
import { Icon, Points } from '../../../components/Images/Images';
import SnackBar from '../../../components/snackBar';

export default function PrizeItem(props) {
    const { item, action } = props;
    const dispatch = useDispatch();
    const bookmark = useSelector(state => state.prizes.bookmark);
    const [bookmarked, setBookmarked] = useState(item.bookmarked === "Y");
    const [clicked, setClicked] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    async function setbookmark(status) {
        setBookmarked(status);
        setClicked(true);
        dispatch(PrizesActions.setBookmark({
            id_prize: item.id_prize,
            favorited: status ? "Y" : "N"
        }))
    }

    useEffect(() => {
        if (clicked && bookmark?.error) {
            setClicked(false);
            setBookmarked(!bookmarked);
            setSnackOpen(true);
            dispatch(PrizesActions.resetBookmark({}));
        }
    }, [dispatch, clicked, bookmark, bookmarked]);

    return (
        <>
            <Card className="prize-card">
                <CardActionArea
                    onClick={action}
                    disabled={item.available === "N" ? true : false}
                >
                    <CardMedia
                        className="prize-card-image"
                        image={item.image ? imageParser(item.image) : null}
                        title={item.name}
                    />
                    <CardContent className="prize-card-content">
                        <div className="prize-card-content-title">
                            <Typography variant="body1" component="h4">
                                {item.name}
                            </Typography>
                        </div>
                        <div className="prize-card-content-info">
                            <Typography variant="body2" component="p" className="font-weight-bold mt-1 mb-1" noWrap>
                                {item.company}
                            </Typography>
                            <Typography variant="body2" component="p" noWrap>
                                {item.available}
                            </Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
                <CardActions className="prize-card-actions">
                    <IconButton color={`${bookmarked ? "primary" : "inherit"}`} aria-label={intl.get('LABEL_BOOKMARK')} onClick={() => setbookmark(!bookmarked)}>
                        <Icon ic={`${bookmarked ? "bookmark_filled" : "bookmark_outline"}`} />
                    </IconButton>
                    <div className="prize-card-actions-price">
                        <Points type="qis" size="md" />
                        <Typography variant="body1" component="p" className="text-qis ml-1">
                            {numberMask(item.qi)}
                        </Typography>
                    </div>
                </CardActions>
            </Card>
            <SnackBar
                open={snackOpen}
                message={intl.get('ERROR_BOOKMARK')}
                status="error"
                time={4}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </>
    )
}