import React, { useEffect, useState, useRef, useCallback } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { useSelector } from 'react-redux';
import { gameService } from '../../services/game.service';
import { useReactMediaRecorder } from 'react-media-recorder';
import moment from 'moment';
import md5 from 'crypto-js/md5';
import { generateNonce, b64toBlob } from '../../utils/utils';
import {
    Container, Button, IconButton, Typography, Grid, LinearProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import GameHeader from './partials/GameHeader';
import SnackBar from '../snackBar';
import { Icon } from '../Images/Images';

const VideoAnswer = ({ children, data, send, onlyView }) => {
    const user = useSelector(state => state.auth.user);

    // Estado consolidado para controle da gravação e estado geral.
    const [recordingState, setRecordingState] = useState({
        rec: false, // Gravação ativada/desativada.
        startRec: false, // Gravação em andamento.
        prevRec: false, // Pré-visualização da gravação.
        countdown: false, // Contagem regressiva ativa.
    });
    const [timer, setTimer] = useState(3);
    const [isPlaying, setIsPlaying] = useState(false);
    const [fileData, setFileData] = useState({
        file: null, // Arquivo em Base64.
        fileName: data.question.user_answer ? data.question.user_answer : "", // Nome do arquivo.
        blobFile: null, // Blob do vídeo gravado.
        blobFileName: "", // Nome do arquivo Blob.
    });
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [userVideoPreview, setUserVideoPreview] = useState(data.question.user_answer_media ? data.question.user_answer_media.source : null);

    const videoRef = useRef();
    const videoPreviewRef = useRef();

    const {
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl,
        previewStream,
    } = useReactMediaRecorder({ video: true });

    // Função que será chamada no evento 'play' do <video>.
    const handleVideoPlay = () => setIsPlaying(true);

    const handleVideoPause = () => setIsPlaying(false);

    // Adiciona o evento de play/pause quando o vídeo é montado.
    useEffect(() => {
        const videoElement = videoPreviewRef.current;

        if (videoElement) {
            videoElement.addEventListener('play', handleVideoPlay);
            videoElement.addEventListener('pause', handleVideoPause);
        }

        // Remove os listeners para evitar vazamentos de memória.
        return () => {
            if (videoElement) {
                videoElement.removeEventListener('play', handleVideoPlay);
                videoElement.removeEventListener('pause', handleVideoPause);
            }
        };
    }, []);

    // Gera um nome de arquivo único usando MD5 e um nonce aleatório.
    const generateFileName = useCallback(() =>
        md5(`${user.id_user}${moment().format('HH:mm:ss')}${generateNonce(10)}`).toString() + '.mp4',
        [user]
    );

    // Controla a contagem regressiva e inicia a gravação ao chegar em 0.
    useEffect(() => {
        if (recordingState.countdown && timer > 0) {
            const interval = setInterval(() => setTimer(prev => prev - 1), 1000);
            return () => clearInterval(interval);
        } else if (timer === 0) {
            setRecordingState(prev => ({ ...prev, countdown: false }));
            setTimer(3); // Reinicia o timer.
            startRecording(); // Inicia a gravação.
        }
    }, [recordingState.countdown, timer, startRecording]);

    // Função que gera um Blob a partir do mediaBlobUrl.
    const generateBlobFromUrl = useCallback(async (url) => {
        const videoBlob = await fetch(url).then(r => r.blob());
        const blob = new Blob([videoBlob], { type: 'video/mp4' });
        const fileName = generateFileName();

        setFileData({
            file: null,
            fileName,
            blobFile: blob,
            blobFileName: fileName,
        });

        setRecordingState(prev => ({ ...prev, startRec: false, prevRec: true }));
    }, [generateFileName]);

    // UseEffect para capturar o mediaBlobUrl assim que estiver disponível.
    useEffect(() => {
        if (mediaBlobUrl) {
            generateBlobFromUrl(mediaBlobUrl); // Função agora é estável
            setUserVideoPreview(null);
        }
    }, [mediaBlobUrl, generateBlobFromUrl]);

    // Componente de pré-visualização do vídeo gravado.
    const VideoPreview = ({ stream }) => {
        useEffect(() => {
            if (videoRef.current && stream) {
                videoRef.current.srcObject = stream;
            }
        }, [stream]);

        return stream ? <video ref={videoRef} autoPlay controls /> : null;
    };

    // Inicia a contagem regressiva para a gravação.
    const handleStartRecording = () => {
        setRecordingState(prev => ({ ...prev, startRec: true, countdown: true }));
    };

    const handleStopRecording = () => {
        stopRecording(); // Para a gravação. `mediaBlobUrl` será atualizado automaticamente.
    };

    // Converte um arquivo para Base64.
    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Lê o arquivo como Base64.
        reader.onload = () => resolve(reader.result); // Retorna a string Base64.
        reader.onerror = reject; // Rejeita em caso de erro.
    });

    // Lida com o upload de arquivos da biblioteca local.
    const handleFileLibrary = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileName = generateFileName();
            const base64 = await getBase64(file); // Converte o arquivo em Base64.
            setFileData({
                file: base64,
                fileName,
                blobFile: b64toBlob(base64, file.type), // Converte para Blob.
                blobFileName: fileName,
            });
        }
    };

    // Envia o vídeo gravado para o servidor.
    const handleUpload = async () => {
        setLoading(true); // Inicia o loading.
        try {
            const { blobFile, blobFileName } = fileData;
            const response = await gameService.sendFileUpload({
                type: "video",
                file: blobFile,
                file_name: blobFileName
            });

            if (response.sent === 'ok') {
                send(blobFileName, data.question.id_question); // Chama a função de callback.
                setFileData({ file: null, fileName: "", blobFile: null, blobFileName: "" }); // Reseta os dados.
            }
        } catch {
            setSnackOpen(true); // Exibe SnackBar em caso de erro.
        } finally {
            setLoading(false); // Finaliza o loading.
        }
    };

    return (
        <div className={`w-100${config.layout.points.neuros && data.help && !onlyView ? " __game-bar" : ""}`}>
            <GameHeader data={data}>
                {children}
            </GameHeader>
            <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10}>
                        {!loading ? (
                            <Grid container spacing={2} className="answer-type-upload-answer">
                                <Grid item xs={12}>
                                    <div className="qr-video-recorder">
                                        {status === 'recording' && (
                                            <div className="qr-video-recorder-status">
                                                <Icon ic="live_on" className="icon-xs mr-2" />
                                                <Typography variant="body2" component="p">
                                                    {intl.get("LABEL_VIDEO_RECORDING")}
                                                </Typography>
                                            </div>
                                        )}

                                        {
                                            !isPlaying &&
                                            <div className="qr-video-recorder-button">
                                                {
                                                    !recordingState.rec && (status !== "media_aborted" || status !== "permission_denied" || status !== "no_specified_media_found") ?
                                                        <Button
                                                            disableRipple
                                                            id="btn_video_rec_turn_on"
                                                            variant="contained"
                                                            onClick={() => setRecordingState({ ...recordingState, rec: true })}
                                                            data-qa="open-input"
                                                        >
                                                            <Icon ic="live_on" className="icon-xs mr-2" />
                                                            {intl.get("LABEL_VIDEO_REC_START")}
                                                        </Button>
                                                        :
                                                        !recordingState.prevRec ?
                                                            recordingState.countdown ?
                                                                <Typography variant="h1" component="p">
                                                                    {timer}
                                                                </Typography>
                                                                :
                                                                !recordingState.startRec ?
                                                                    <IconButton
                                                                        id="btn_video_rec_start"
                                                                        color="inherit"
                                                                        onClick={handleStartRecording}
                                                                        data-qa="start-recording"
                                                                    >
                                                                        <Icon ic="start" />
                                                                    </IconButton>
                                                                    :
                                                                    <IconButton
                                                                        id="btn_video_rec_stop"
                                                                        color="inherit"
                                                                        onClick={handleStopRecording}
                                                                        data-qa="stop-recording"
                                                                    >
                                                                        <Icon ic="stop" />
                                                                    </IconButton>
                                                            :
                                                            <Button
                                                                disableRipple
                                                                type='button'
                                                                variant="contained"
                                                                onClick={() => setRecordingState({ ...recordingState, prevRec: false })}
                                                                data-qa='start-replaying'
                                                            >
                                                                <Icon ic="replay" className="icon-xs mr-2" />
                                                                {intl.get("LABEL_VIDEO_RECORD_AGAIN")}
                                                            </Button>
                                                }
                                            </div>
                                        }

                                        <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                            {
                                                status === "media_aborted" || status === "permission_denied" || status === "no_specified_media_found" ?
                                                    <div className="no-media">
                                                        <Icon ic="live_off" className="icon-xxxl" />
                                                    </div>
                                                    :
                                                    !recordingState.startRec ?
                                                        userVideoPreview ?
                                                            <video ref={videoPreviewRef} src={userVideoPreview} controls={true} />
                                                            :
                                                            <video src={mediaBlobUrl} autoPlay controls={!recordingState.rec || recordingState.countdown ? false : true} />
                                                        :
                                                        <VideoPreview stream={previewStream} />
                                            }
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="divider-word">
                                        <Typography variant="body2" component="span">
                                            {intl.get('DIVIDER_TEXT')}
                                        </Typography>
                                    </div>
                                    <div className="position-relative">
                                        <input
                                            accept="video/mp4,video/x-m4v,video/*"
                                            style={{ display: 'none' }}
                                            id="upload-button-file"
                                            type="file"
                                            onChange={handleFileLibrary}
                                        />

                                        <label htmlFor="upload-button-file">
                                            <Button
                                                variant="outlined"
                                                className="btn-card __answer"
                                                component="div"
                                            >
                                                <Icon ic="upload_outline" className="icon-xs mr-2" />
                                                {intl.get('LABEL_UPLOAD_VIDEO')}
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert icon={fileData.fileName ? <Icon ic="video" className="icon-xs" /> : false} severity={fileData.fileName ? 'success' : 'warning'} className="rounded">
                                        {fileData.fileName || intl.get("EMPTY_UPLOAD_MEDIA")}
                                    </Alert>
                                </Grid>
                                <Grid item xs={12} align="center" className="mb-3">
                                    <Button
                                        disabled={!fileData.blobFile}
                                        className={`btn-check btn-card __answer${fileData.blobFile ? ' active' : ''}`}
                                        variant="outlined"
                                        onClick={handleUpload}
                                    >
                                        <Icon ic="check" />
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <LinearProgress />
                                </Grid>
                            </Grid>
                        )}
                        <SnackBar
                            open={snackOpen}
                            message={intl.get("ERROR_UPLOAD_MEDIA")}
                            status="error"
                            time={3}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            closeSnack={() => setSnackOpen(false)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default VideoAnswer;
