import * as React from "react";

function ImgHeaderContact(props) {
    return (
        <svg width={280} height={120} viewBox="0 0 280 120" className="img-header" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M214.755 85.894h-8.88c-2.923 0-5.29-1.614-5.29-3.607 0-1.992 2.367-3.606 5.29-3.606h1.09c3.013 0 5.825-1.3 6.605-3.284 1.18-2.999-2.15-5.733-6.372-5.733h-41V49.828h37.032c6.177 0 11.163-3.657 10.525-7.952-.555-3.742-5.507-6.474-11.025-6.474h-23.306c-2.923 0-5.29-1.614-5.29-3.607 0-1.992 2.367-3.606 5.29-3.606h14.17c3.46 0 6.61-1.697 6.957-4.043.396-2.7-2.701-4.973-6.579-4.973h-21.161c-2.19 0-3.968-1.212-3.968-2.705 0-1.494 1.778-2.705 3.968-2.705h10.202c3.46 0 6.61-1.697 6.957-4.043.397-2.7-2.7-4.974-6.579-4.974H96.48c-3.46 0-6.61 1.697-6.957 4.043-.397 2.7 2.7 4.974 6.579 4.974h9.258c2.923 0 5.29 1.614 5.29 3.606 0 1.993-2.367 3.607-5.29 3.607H71.472c-5.518 0-10.47 2.73-11.025 6.474-.638 4.295 4.348 7.952 10.525 7.952h6.613c5.113 0 9.258 2.826 9.258 6.312 0 3.485-4.145 6.311-9.258 6.311h-8.759c-5.517 0-10.47 2.73-11.025 6.474-.637 4.295 4.349 7.952 10.525 7.952h44.968v21.64H75.172c-3.013 0-5.824 1.3-6.605 3.283-1.18 3 2.15 5.733 6.372 5.733h3.968c2.923 0 5.29 1.614 5.29 3.607 0 1.992-2.367 3.606-5.29 3.606h-7.435c-5.518 0-10.47 2.73-11.025 6.474-.638 4.295 4.348 7.952 10.525 7.952h129.113c5.518 0 10.47-2.73 11.025-6.473.638-4.296-4.349-7.953-10.525-7.953h-1.323c-2.19 0-3.967-1.212-3.967-2.705s1.777-2.705 3.967-2.705h15.871c3.878 0 6.976-2.274 6.579-4.973-.344-2.346-3.497-4.043-6.957-4.043z"
                    fill="#000"
                    opacity={0.1}
                />
                <g transform="translate(98.746 11.746)" fillRule="nonzero">
                    <path
                        d="M83.07 55.635H0v-28.2c0-1.53.874-2.842 1.967-3.716L38.037.984c2.186-1.312 4.81-1.312 6.996 0l36.07 22.735c1.311.874 1.967 2.186 1.967 3.716v28.2z"
                        fill="#247CB5"
                    />
                    <path
                        d="M72.14 61.1H10.93V21.751c0-1.311.875-2.186 2.186-2.186h56.837c1.312 0 2.187.875 2.187 2.186V61.1z"
                        fill="#F5F5F5"
                    />
                    <path
                        d="M83.07 28.31v46.562c0 2.186-1.75 3.716-3.935 3.716h-69.08L20.55 64.816l62.52-36.507z"
                        fill="#78BCE9"
                    />
                    <path
                        d="M0 28.31v46.562c0 2.186 1.749 3.716 3.935 3.716h75.418a3.825 3.825 0 003.061-1.53L0 28.31z"
                        fill="#2B97DD"
                    />
                    <path
                        d="M83.07 50.388a25.825 25.825 0 00-10.93-2.404c-14.428 0-26.233 11.804-26.233 26.232 0 1.53.219 2.842.437 4.372h33.884c.219 0 .437-.218.656-.218h.218c.219 0 .438-.219.656-.437l.656-.656c.437-.656.874-1.53.874-2.405V50.388h-.218z"
                        fill="#000"
                        opacity={0.05}
                    />
                    <circle fill="#46CA64" cx={72.14} cy={74.216} r={21.86} />
                    <path
                        d="M73.233 85.147h-2.186c-.656 0-1.094-.438-1.094-1.094V64.38c0-.656.438-1.093 1.094-1.093h2.186c.655 0 1.093.437 1.093 1.093v19.674c0 .656-.438 1.094-1.093 1.094z"
                        fill="#FFF"
                    />
                    <path
                        d="M61.21 75.31v-2.187c0-.656.437-1.093 1.092-1.093h19.675c.656 0 1.093.437 1.093 1.093v2.186c0 .656-.437 1.093-1.093 1.093H62.302c-.655 0-1.093-.437-1.093-1.093z"
                        fill="#FFF"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgHeaderContact
