import * as React from "react"

function ImgBubble3(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M7.2 4h25.584c1.76 0 3.202 1.47 3.202 3.267L36 26.87c0 1.805-1.432 3.269-3.2 3.269h-2.4c-.441 0-.8.365-.8.816v3.408c0 1.456-1.723 2.184-2.731 1.155l-4.8-4.9a1.582 1.582 0 00-1.132-.48H7.2c-1.76 0-3.2-1.47-3.2-3.267V7.267C4 5.47 5.44 4 7.2 4z"
                    className="svg-bubble"
                    fillRule="nonzero"
                />
                <path
                    d="M25.158 25c-.13 0-.26-.04-.369-.12l-4.894-3.523L15 24.881a.632.632 0 01-1-.513V11.737A2.74 2.74 0 0116.737 9h6.316a2.74 2.74 0 012.736 2.737v12.631a.631.631 0 01-.631.632z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export default ImgBubble3
