import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function Viewer(props) {
    const { data, fileName, download } = props;

    const docs = [
        {
            uri: data,
            fileName: fileName ? fileName.split(' ').join('-').toLowerCase() : null
        },
    ];

    const docViewerStyle = `
        .docViewerClass #pdf-download {
            display: ${download ? '' : 'none'};
        }
    `;

    const viewerConfig = {
        header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true
        }
    };

    return (
        <>
            <style type="text/css">{docViewerStyle}</style>
            <DocViewer
                config={viewerConfig}
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                className="docViewerClass"
                style={{ minWidth: '100%', minHeight: '90%' }} />
        </>
    );
}
