import * as React from "react"
import Typography from '@material-ui/core/Typography';

function FreeDifficultyFull(props) {
    let levelStatus = ["svg-difficulty-level-disabled", "svg-difficulty-level-disabled", "svg-difficulty-level-disabled"]

    if (props.type === "easy") {
        levelStatus[0] = "svg-difficulty-level-active"
    } else if (props.type === "medium") {
        levelStatus[0] = "svg-difficulty-level-active"
        levelStatus[1] = "svg-difficulty-level-active"
    } else if (props.type === "hard") {
        levelStatus[0] = "svg-difficulty-level-active"
        levelStatus[1] = "svg-difficulty-level-active"
        levelStatus[2] = "svg-difficulty-level-active"
    }

    return (
        <div className={"svg-difficulty" + (props.size === "sm" ? " small" : "")}>
            <div className="svg-difficulty-text">
                <Typography variant="h3" component="h2" noWrap>
                    {props.text}
                </Typography>
            </div>
            <svg width={84} height={100} viewBox="0 0 84 100" className="svg-shadow">
                <defs>
                    <path id="prefix__a" d="M0 0h84v100H0z" />
                    <path id="prefix__c" d="M0 0h18v18H0z" />
                    <path id="prefix__e" d="M0 0h18v18H0z" />
                    <path id="prefix__g" d="M0 0h18v18H0z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <g mask="url(#prefix__b)">
                        <path
                            d="M47.51 70.45c-15.998 0-36.114 2.077-35.62-35.322.404-30.593 19.622-30.583 35.62-30.583 15.996 0 28.965 14.753 28.965 32.951 0 18.201-12.97 32.954-28.966 32.954z"
                            fill="#F29500"
                        />
                        <path
                            d="M35.477 70.45C19.48 70.45 6.51 55.697 6.51 37.496c0-18.198 12.969-32.951 28.966-32.951 15.996 0 28.965 14.753 28.965 32.951 0 18.201-12.969 32.954-28.965 32.954"
                            fill="#FFBF00"
                        />
                        <path
                            d="M35.477 4.143c-16.23 0-29.366 14.945-29.366 33.354 0 18.41 13.135 33.354 29.366 33.354 16.23 0 29.365-14.944 29.365-33.354 0-18.41-13.136-33.354-29.365-33.354zm0 .8c15.763 0 28.565 14.565 28.565 32.554 0 17.99-12.801 32.554-28.565 32.554-15.764 0-28.566-14.564-28.566-32.554 0-17.989 12.803-32.554 28.566-32.554z"
                            fill="#F29500"
                            fillRule="nonzero"
                        />
                        <path
                            d="M35.013 66.758c-14.045 0-25.43-13.062-25.43-29.173 0-16.111 11.385-29.173 25.43-29.173 14.044 0 25.43 13.062 25.43 29.173 0 16.111-11.386 29.173-25.43 29.173"
                            fill="#FFC619"
                        />
                        <path
                            d="M70 64c5.523 0 10 4.477 10 10v10c0 5.523-4.477 10-10 10H14C8.477 94 4 89.523 4 84V74c0-5.523 4.477-10 10-10h56z"
                            className="svg-difficulty-bar"
                        />
                        <path
                            d="M70 65H14a9 9 0 00-8.996 8.735L5 74v10a9 9 0 008.735 8.996L14 93h56a9 9 0 008.996-8.735L79 84V74a9 9 0 00-8.735-8.996L70 65z"
                            fill="#FFF"
                        />
                        <path
                            d="M67.73 67H16.27c-4.486 0-8.137 3.332-8.266 7.487L8 74.714v8.572c0 4.184 3.572 7.59 8.026 7.71l.244.004h51.46c4.486 0 8.137-3.332 8.266-7.487l.004-.227v-8.572c0-4.184-3.572-7.59-8.026-7.71L67.73 67z"
                            fillOpacity={0.04}
                            fill="#000"
                        />
                        <path
                            d="M68.486 67l.252.003c4.497.118 8.12 3.397 8.258 7.47l.004.241v8.572l-.004.227c-.13 4.076-3.748 7.359-8.244 7.483l-.266.004H15.514l-.252-.003c-4.497-.118-8.12-3.397-8.258-7.47L7 83.285v-8.572l.004-.227c.13-4.076 3.748-7.359 8.244-7.483l.266-.004h52.972zm.26 1.003l-.25-.003H15.49c-3.984 0-7.232 2.787-7.476 6.274l-.011.228-.004.212v8.572c0 3.54 3.075 6.477 6.996 6.7l.257.01.238.004h53.018c3.984 0 7.232-2.786 7.476-6.274l.011-.228.004-.212v-8.572c0-3.54-3.075-6.477-6.996-6.7l-.257-.01z"
                            className="svg-difficulty-bar"
                        />
                        <g transform="translate(11 70)">
                            <mask id="prefix__d" fill="#fff">
                                <use xlinkHref="#prefix__c" />
                            </mask>
                            <g mask="url(#prefix__d)" className={levelStatus[0]}>
                                <path d="M6.938.75c-.76 0-1.375.615-1.375 1.375 0 .012.004.023.004.035-.116-.013-.23-.035-.348-.035a3.094 3.094 0 00-3.094 3.094c0 .324.063.63.156.922a3.432 3.432 0 000 5.718 3.036 3.036 0 00-.156.922 3.083 3.083 0 002.782 3.062 1.717 1.717 0 003.405-.312v-.344c0-.758-.616-1.374-1.374-1.374a.687.687 0 110-1.376c.503 0 .968.147 1.375.383V6.917a3.376 3.376 0 01-2.063.708.687.687 0 110-1.375 2.065 2.065 0 002.063-2.063V2.126c0-.76-.616-1.375-1.376-1.375zm4.125 0c-.76 0-1.376.615-1.376 1.375v2.063c0 1.137.926 2.062 2.063 2.062a.687.687 0 110 1.375 3.38 3.38 0 01-2.063-.706v8.612a1.717 1.717 0 003.405.312 3.08 3.08 0 002.772-2.956 4.078 4.078 0 01-1.364.238.687.687 0 110-1.375A2.753 2.753 0 0017.25 9a3.432 3.432 0 00-1.53-2.859c.092-.293.155-.598.155-.922a3.094 3.094 0 00-3.094-3.094c-.119 0-.232.022-.348.035 0-.012.005-.023.005-.035 0-.76-.616-1.375-1.376-1.375z" />
                            </g>
                        </g>
                        <g transform="translate(33 70)">
                            <mask id="prefix__f" fill="#fff">
                                <use xlinkHref="#prefix__e" />
                            </mask>
                            <g mask="url(#prefix__f)" className={levelStatus[1]}>
                                <path d="M6.938.75c-.76 0-1.375.615-1.375 1.375 0 .012.004.023.004.035-.116-.013-.23-.035-.348-.035a3.094 3.094 0 00-3.094 3.094c0 .324.063.63.156.922a3.432 3.432 0 000 5.718 3.036 3.036 0 00-.156.922 3.083 3.083 0 002.782 3.062 1.717 1.717 0 003.405-.312v-.344c0-.758-.616-1.374-1.374-1.374a.687.687 0 110-1.376c.503 0 .968.147 1.375.383V6.917a3.376 3.376 0 01-2.063.708.687.687 0 110-1.375 2.065 2.065 0 002.063-2.063V2.126c0-.76-.616-1.375-1.376-1.375zm4.125 0c-.76 0-1.376.615-1.376 1.375v2.063c0 1.137.926 2.062 2.063 2.062a.687.687 0 110 1.375 3.38 3.38 0 01-2.063-.706v8.612a1.717 1.717 0 003.405.312 3.08 3.08 0 002.772-2.956 4.078 4.078 0 01-1.364.238.687.687 0 110-1.375A2.753 2.753 0 0017.25 9a3.432 3.432 0 00-1.53-2.859c.092-.293.155-.598.155-.922a3.094 3.094 0 00-3.094-3.094c-.119 0-.232.022-.348.035 0-.012.005-.023.005-.035 0-.76-.616-1.375-1.376-1.375z" />
                            </g>
                        </g>
                        <g transform="translate(54 70)">
                            <mask id="prefix__h" fill="#fff">
                                <use xlinkHref="#prefix__g" />
                            </mask>
                            <g mask="url(#prefix__h)" className={levelStatus[2]}>
                                <path d="M6.938.75c-.76 0-1.375.615-1.375 1.375 0 .012.004.023.004.035-.116-.013-.23-.035-.348-.035a3.094 3.094 0 00-3.094 3.094c0 .324.063.63.156.922a3.432 3.432 0 000 5.718 3.036 3.036 0 00-.156.922 3.083 3.083 0 002.782 3.062 1.717 1.717 0 003.405-.312v-.344c0-.758-.616-1.374-1.374-1.374a.687.687 0 110-1.376c.503 0 .968.147 1.375.383V6.917a3.376 3.376 0 01-2.063.708.687.687 0 110-1.375 2.065 2.065 0 002.063-2.063V2.126c0-.76-.616-1.375-1.376-1.375zm4.125 0c-.76 0-1.376.615-1.376 1.375v2.063c0 1.137.926 2.062 2.063 2.062a.687.687 0 110 1.375 3.38 3.38 0 01-2.063-.706v8.612a1.717 1.717 0 003.405.312 3.08 3.08 0 002.772-2.956 4.078 4.078 0 01-1.364.238.687.687 0 110-1.375A2.753 2.753 0 0017.25 9a3.432 3.432 0 00-1.53-2.859c.092-.293.155-.598.155-.922a3.094 3.094 0 00-3.094-3.094c-.119 0-.232.022-.348.035 0-.012.005-.023.005-.035 0-.76-.616-1.375-1.376-1.375z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default FreeDifficultyFull
