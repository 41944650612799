import * as React from "react"

function ImgAccessCode(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M16.947 7.737c-.315-.105-.579.105-.79.737 0 .052-.052.158-.052.21l-.842 3.263c-.052.158-.21.264-.42.21a.396.396 0 01-.264-.368c.053-.21.263-3.105.474-5.052.052-.263.21-1-.474-1.158-.684-.158-.737.632-.79.842l-.526 4.79c0 .052-.158.368-.42.315-.264-.052-.264-.42-.264-.42V5.262c0-.263-.053-.474-.158-.579a.804.804 0 00-.526-.21c-.527 0-.632.368-.632.684v6c0 .21-.052.316-.368.316-.263 0-.316-.369-.316-.369-.105-.368-1-4.842-1-4.842-.105-.474-.316-.737-.684-.737a.643.643 0 00-.474.21 1.061 1.061 0 00-.158.58c.105.631 1 6.263 1 6.79a.355.355 0 01-.21.315h-.106a.29.29 0 01-.263-.158l-1.263-1.579c-.158-.105-.421-.263-.684-.263a.976.976 0 00-.369.105c-.157.106-.263.263-.263.421-.052.21 0 .421.106.632 0 .053 1 1.79 1.315 2.368l.106.158c.42.737.736 1.263 1 1.632.052.052.105.158.157.21.527.79 1.685 2.369 3.106 2.369h2.42c1.37-.158 2.317-1.58 2.422-4.053V12.58l.42-3.58c.159-.947.106-1.158-.21-1.263z"
                    fill="#FFB74D"
                />
                <path fill="#37474F" d="M2 15.105h20v6.842H2z" />
                <path
                    d="M6.737 20.895H3.053V17.21h1.052v2.631h2.632v1.053zm14.21 0h-3.684v-1.053h2.632v-2.631h1.052v3.684z"
                    fill="#00B8D4"
                />
                <path
                    d="M20.947 6.684h-1.052V4.053h-2.632V3h3.684v3.684zm-16.842 0H3.053V3h3.684v1.053H4.105v2.631z"
                    fill="#90A4AE"
                />
                <path
                    d="M7.684 15.105c.421.737.737 1.263 1 1.58.053.052.105.157.158.21.526.79 1.684 2.368 3.105 2.368h2.421c1.369-.158 2.316-1.579 2.421-4.052v-.106H7.684z"
                    fill="#00E5FF"
                />
                <path fill="#18FFFF" d="M2 14.579h20v1.053H2z" />
            </g>
        </svg>
    )
}

export default ImgAccessCode
