import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="nonzero">
                <path fill="#FFF" d="M0 0h512v512H0z" />
                <path
                    fill="#2B97DD"
                    d="M200.201 95.963h-92.608c-16.994.025-30.765 13.793-30.793 30.787v92.608c.028 16.994 13.799 30.763 30.793 30.788H200.2c16.994-.025 30.765-13.794 30.793-30.788V126.75c-.025-16.996-13.797-30.768-30.793-30.793"
                />
                <path
                    fill="#46CA64"
                    d="M426.187 151.266 360.695 85.8c-12.036-12.001-31.514-12.001-43.55 0l-9.051 9.058-56.424 56.412a30.577 30.577 0 0 0-8.99 21.708 30.577 30.577 0 0 0 8.99 21.836l9.069 9.063 47.35 47.389 9.068 9.063c12.03 12.018 31.523 12.01 43.543-.017l56.418-56.435 9.07-9.046c12.016-12.03 12.016-31.52 0-43.55"
                />
                <path
                    fill="#E65748"
                    d="M200.201 280.994h-92.608c-16.996.025-30.768 13.797-30.793 30.793v92.609c.025 16.996 13.797 30.768 30.793 30.792H200.2c16.996-.024 30.768-13.796 30.793-30.792v-92.609c-.025-16.996-13.797-30.768-30.793-30.793"
                />
                <path
                    fill="#FDC436"
                    d="M385.233 280.994h-92.609c-16.996.025-30.768 13.797-30.792 30.793v92.609c.024 16.996 13.796 30.768 30.792 30.792h92.609c16.996-.024 30.768-13.796 30.793-30.792v-92.609c-.025-16.996-13.797-30.768-30.793-30.793"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon
