import * as React from "react"

function Adventure(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path fill="#FDD97F" d="M15 5L9 3 3 5v16l6-2 6 2 6-2V3z" />
        <path fill="#FDCE5A" d="M9 3v16l6 2V5z" />
        <path
          d="M6.062 15.265a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm11.877-1.484a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM9.772 7.099a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
          fill="#E65748"
        />
        <path
          d="M12.742 6.865a.743.743 0 100 1.485.743.743 0 000-1.485zm2.227 0a.743.743 0 100 1.485.743.743 0 000-1.485zm2.228.743a.742.742 0 100 1.484.742.742 0 000-1.484zm1.484 1.485a.742.742 0 100 1.484.742.742 0 000-1.484zm0 2.226a.743.743 0 100 1.485.743.743 0 000-1.485zm-3.712 3.712a.742.742 0 100 1.484.742.742 0 000-1.484zm-2.227-.742a.742.742 0 100 1.484.742.742 0 000-1.484zm-1.484-1.485a.743.743 0 100 1.485.743.743 0 000-1.485zm-1.953-.334a.742.742 0 100 1.484.742.742 0 000-1.484zm-1.759 1.077a.742.742 0 100 1.484.742.742 0 000-1.484zM6.804 8.35a.742.742 0 100 1.484.742.742 0 000-1.485z"
          fill="#7E6955"
        />
      </g>
    </svg>
  )
}

export default Adventure
