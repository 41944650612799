import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../../redux/ducks/auth';
import { myMSALObj, scopes } from './AzureAuthConfig';
import { Button, Grid } from '@material-ui/core';
import SnackBar from '../../../components/snackBar';

export default function ButtonLoginAzure(props) {
    const { buttonStyle } = props;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const loading = useSelector(state => state.auth.loading);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMesage] = useState("");
    const [loginCallback, setLoginCallback] = useState(true);
    let accessToken;

    // Register Callbacks for Redirect flow
    if (!loading && loginCallback) {
        myMSALObj.handleRedirectCallback(authRedirectCallBack);
    }

    function authRedirectCallBack(error, response) {
        setLoginCallback(false);
        if (!loading) {
            dispatch(AuthActions.isLoading(true));
        }
        if (error) {
            AuthActions.loginError(error);
        } else {
            dispatch(AuthActions.showSmallscreenLogin());
            if (response.tokenType === "id_token") {
                // console.log("id_token acquired at: " + new Date().toString());
                if (myMSALObj.getAccount()) {
                    getTokenRedirect();
                }
            }
            else if (response.tokenType === "access_token") {
                // console.log("access_token acquired at: " + new Date().toString());
                accessToken = response.accessToken;
                try {
                    loginApi(accessToken);
                } catch (err) {
                    AuthActions.loginError(err);
                } finally {
                }
            }
            else {
                // console.log("token type is:" + response.tokenType);
            }
        }
    }

    async function getTokenRedirect() {
        return myMSALObj.acquireTokenSilent(scopes)
            .then((response) => {
                if (response.accessToken) {
                    // console.log("access_token acquired at: " + new Date().toString());
                    accessToken = response.accessToken;
                    if (accessToken) {
                        try {
                            loginApi(accessToken);
                        } catch (err) {
                            AuthActions.loginError(err);
                        } finally {
                        }
                    }
                }
            })
            .catch(error => {
                // fallback to interaction when silent call fails
                return myMSALObj.acquireTokenRedirect(scopes);
            });
    }

    useEffect(() => {
        if (auth.loginError && auth.loginError.length > 0) {
            setLoginCallback(false);
            if (loading) {
                dispatch(AuthActions.isLoading(false));
            }
            setSnackOpen(true);
            setSnackMesage(auth.loginError);
            setSnackStatus(auth.status.length !== 0 ? auth.status : snackStatus);
            auth.loginError = "";
        }
    }, [dispatch, auth.loginError, auth.status, loading, snackStatus]);

    function loginApi(accessToken) {
        dispatch(AuthActions.login({
            auth_password: accessToken,
            auth_provider: 'AZUREAD'
        }));
    }

    function getLogin() {
        dispatch(AuthActions.isLoading(true));
        setLoginCallback(true);
        myMSALObj.loginRedirect(scopes);
    }

    return (
        <Grid container justify="center">
            <Button
                id={buttonStyle === "button" ? "btn_intro_login_azure" : ""}
                variant={buttonStyle === "button" ? "contained" : "text"}
                className={buttonStyle === "button" ? "btn-block" : ""}
                color="primary"
                onClick={getLogin}
            >
                {intl.get('BTN_LOGIN_AZURE')}
            </Button>
            <SnackBar
                open={snackOpen}
                message={snackMessage}
                status={snackStatus}
                time={4}
                closeSnack={() => setSnackOpen(false)}
            />
        </Grid>
    )
}