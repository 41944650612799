import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import intl from 'react-intl-universal';
import config from '../config';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { actions as QrLayoutActions } from '../redux/ducks/qr_layout';
import { imageParser, numberSocialMask, numberMask } from '../utils/utils';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Card, CardActionArea, CardContent, Badge,
    List, ListItem, ListItemText, ListItemIcon, ListItemAvatar,
    Divider, BottomNavigation, BottomNavigationAction, IconButton,
    Avatar, Typography, Tooltip, useMediaQuery, Hidden, Drawer,
} from '@material-ui/core';
import { Logo, Points, Icon } from './Images/Images';

const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }
}));

function ListItemLink(props) {
    const { showMenuNotification, primary, icon, to, section, setOpenDrawer } = props;
    const link = React.useMemo(() => React.forwardRef((itemProps, ref) => (
        <NavLink to={to} {...itemProps} innerRef={ref} exact />
    )), [to]);

    // Estado local para notificationsCount
    const [notificationsCount, setNotificationsCount] = useState(() => {
        // Valor inicial baseado no localStorage
        return icon === "medal"
            ? JSON.parse(localStorage.getItem('notifications_count'))?.menu_mission_total || 0
            : JSON.parse(localStorage.getItem('auth.state.user'))?.notification_count || 0;
    });

    // Atualiza o count sempre que o localStorage for alterado
    useEffect(() => {
        const handleStorageChange = () => {
            const updatedCount = icon === "medal"
                ? JSON.parse(localStorage.getItem('notifications_count'))?.menu_mission_total || 0
                : JSON.parse(localStorage.getItem('auth.state.user'))?.notification_count || 0;
            setNotificationsCount(updatedCount);
        };

        // Escuta o evento "storage"
        window.addEventListener('storage', handleStorageChange);

        // Atualiza ao recarregar a aba
        handleStorageChange();

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [icon]);

    return (
        <Tooltip title={!setOpenDrawer ? primary : ""} placement="right" arrow>
            <ListItem button component={link}>
                <ListItemAvatar>
                    <Avatar aria-label="section" className={section}>
                        <Icon ic={`${icon}_filled`} color="white" className="icon-xs" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={primary} />
                {showMenuNotification
                    ? <Badge badgeContent={notificationsCount} />
                    : null
                }
            </ListItem>
        </Tooltip>
    );
}

const Link = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />);

var itemsMenu = {
    HOME: { id: 'HOME', name: 'MENU_HOME', icon: 'home', section: 'section-home', route: '/home', enabled: true },
    FEEDS: { id: 'FEEDS', name: 'MENU_FEEDS', icon: 'feed', section: 'section-feeds', route: '/feeds', enabled: config.feeds.enabled, showMenuNotification: false },
    NOTIFICATION: { id: 'NOTIFICATION', name: 'MENU_NOTIFICATIONS', icon: 'bell', section: 'section-notifications', route: '/notifications', enabled: true, showMenuNotification: true },
    LEARN: { id: 'LEARN', name: 'MENU_LEARN', icon: 'brain', section: 'section-play', route: '/learn', enabled: config.learn.enabled },
    PRIZES: { id: 'PRIZES', name: 'MENU_PRIZES', icon: 'gift', section: 'section-prizes', route: '/prizes', enabled: true },
    MY_REDEEMS: { id: 'MY_REDEEMS', name: 'MENU_MY_REDEEMS', icon: 'shopping_bag', section: 'section-my-redeems', route: '/my-redeems', enabled: true },
    RANKING: { id: 'RANKING', name: 'MENU_RANKING', icon: 'trophy', section: 'section-ranking', route: '/ranking', enabled: true },
    MISSIONS: { id: 'MISSIONS', name: 'MENU_ACHIEVEMENTS', icon: 'medal', section: 'section-achievements', route: '/achievements', enabled: true, showMenuNotification: true },
    VIRTUAL_LIBRARY: { id: 'VIRTUAL_LIBRARY', name: 'MENU_LIBRARY', icon: 'book_stack', section: 'section-library', route: '/library', enabled: true },
    REDEEM_CODE: { id: 'REDEEM_CODE', name: 'MENU_CODE_REDEEM', icon: 'gift_card', section: 'section-code-redeem', route: '/code-redeem', enabled: true },
    MY_HISTORY: { id: 'MY_HISTORY', name: 'MENU_MY_HISTORY', icon: 'statistics', section: 'section-my-history', route: '/my-history', enabled: config.my_history.enabled },
    MANAGER_PANEL: { id: 'MANAGER_PANEL', name: 'MENU_MANAGER_PANEL', icon: 'admin_settings', section: 'section-manager-team', route: '/manager', enabled: false },
    SETTINGS: { id: 'SETTINGS', name: 'MENU_SETTINGS', icon: 'settings', section: 'section-settings', route: '/settings', enabled: true },
    MORE: { id: 'MORE', name: 'MENU_MORE', icon: 'menu', section: '', route: '/more', enabled: true },
    FREE: { id: 'FREE', name: 'MENU_FREE', icon: 'play_free', section: 'section-play-free', route: '/free', enabled: false },
    PERIODIC_QUESTION: { id: 'PERIODIC_QUESTION', name: 'MENU_PERIODIC_QUESTION', icon: 'play_periodic_question', section: 'section-play-periodic', route: '/periodic', enabled: false },
    FAQ: { id: 'FAQ', name: 'MENU_FAQ', icon: 'help', section: 'section-faq', route: '/faq', enabled: false },
};

function enabledItem(item, auth, isScreenLg) {
    if (auth.modules[`${item.id}`] && auth.modules.MENU[`${item.id}`]) {
        return item.enabled = (auth.modules[`${item.id}`] && auth.modules[`${item.id}`].ENABLED === 'Y') && auth.modules.MENU[`${item.id}`] === 'Y';
    } else if (auth.modules[`${item.id}`]) {
        return item.enabled = auth.modules[`${item.id}`] && auth.modules[`${item.id}`].ENABLED === 'Y';
    } else if (auth.modules.MENU[`${item.id}`]) {
        return item.enabled = auth.modules.MENU[`${item.id}`] === 'Y';
    } else if (item.id === "MANAGER_PANEL") {
        return item.enabled = auth.user.is_manager === 'Y';
    } else if (item.id === "FEEDS" && config.feeds.enabled && isScreenLg) {
        return item.enabled = false;
    } else if (item.id === "MORE" && item.enabled && isScreenLg) {
        return item.enabled = false;
    }
}

function RenderProfileInfo(props) {
    const auth = useSelector(state => state.auth);
    const help = useSelector(state => state.game.help);
    const { history, avatar, name, xp, qis, neuros, fade } = props;

    return (
        <Card className={`qr-nav_profile ${fade ? "animated QrFadeIn" : ""}`}>
            <CardActionArea onClick={() => history.push(`/profile`)}>
                <div className="cover"></div>
                <Avatar alt="Avatar" src={avatar} className="avatar avatar-bordered avatar-bordered-profile" />
                <CardContent>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <div>
                            <Typography variant="h4" component="h6">
                                {name}
                            </Typography>
                            <Typography variant="caption" component="p">
                                {intl.get("SEE_PROFILE_MESSAGE")}
                            </Typography>
                        </div>
                        <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                    </Grid>
                </CardContent>
            </CardActionArea>
            <Grid container direction="row" justify="space-between" alignItems="center" className="qr-nav_profile-footer">
                <Tooltip title={`${intl.get("LABEL_XP")}: ${numberMask(auth.user.total_points)}`} placement="top" arrow>
                    <Grid item xs className="points">
                        <Points type="xp" size="sm" />
                        <Typography variant="body1" className="text-xp ml-1" component="span">
                            {xp}
                        </Typography>
                    </Grid>
                </Tooltip>
                {
                    config.layout.points.qis ?
                        <Tooltip title={`${intl.get("LABEL_QIS")}: ${numberMask(auth.user.qi_credit)}`} placement="top" arrow>
                            <Grid item xs className="points">
                                <Points type="qis" size="sm" />
                                <Typography variant="body1" className="text-qis ml-1" component="span">
                                    {qis}
                                </Typography>
                            </Grid>
                        </Tooltip>
                        : null
                }
                {
                    config.layout.points.neuros ?
                        <Tooltip title={`${intl.get("LABEL_NEUROS")}: ${numberMask(help && help.user_neuros ? help.user_neuros : auth.user.neuros)}`} placement="top" arrow>
                            <Grid item xs className="points">
                                <Points type="neuros" size="sm" />
                                <Typography variant="body1" className="text-neuros ml-1" component="span">
                                    {neuros}
                                </Typography>
                            </Grid>
                        </Tooltip>
                        : null
                }
            </Grid>
        </Card>
    )
}

function reorderMenuArray(array, name) {
    array.sort(function (a, b) {
        return config.layout.menu[name].indexOf(a.name) - config.layout.menu[name].indexOf(b.name);
    });
}

export function QrMenu(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const help = useSelector(state => state.game.help);
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const classes = useStyles();
    const scrollbarMenu = useRef(null);
    const [fadeProfile, setFadeProfile] = useState(null);
    const isScreenLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isScreenMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);

    useEffect(() => {
        if (!auth)
            return;

        if (itemsMenu && (auth.modules && auth.modules.MENU)) {
            for (let i in itemsMenu) {
                enabledItem(itemsMenu[i], auth, isScreenLg);
            }

            if (props.more) {
                setItems(Object.values(itemsMenu).filter(menu => config.layout.menu.nav_bottom_order.every(bottomMenu => !bottomMenu.includes(menu.name))));
            } else {
                setItems(Object.values(itemsMenu).filter(menu => config.layout.menu.nav_order.includes(menu.name)));
            }
        }
    }, [auth, props.more, isScreenLg]);

    const handleMenuDrawer = () => {
        dispatch(QrLayoutActions.menuDrawer({
            menuOpen: !menuOpen
        }));

        setFadeProfile(!menuOpen);
    };

    reorderMenuArray(items, 'nav_order');

    const RenderMenu = () => {
        return (
            items.length > 0 || config.layout.menu.logout_button || config.layout.menu.logo_footer ?
                <Grid container className="qr-nav_content" justify="space-between">
                    <Grid item xs={12} className={`${props.more ? "__more" : ""}`}>
                        {
                            isScreenMd && !props.more && !menuOpen ?
                                <div>
                                    <Divider />
                                    <Tooltip title={intl.get('PROFILE_TITLE')} placement="right" arrow>
                                        <List component="nav" aria-label="profile" className={menuOpen === fadeProfile ? "animated QrFadeIn" : ""}>
                                            <ListItem button onClick={() => history.push(`/profile`)}>
                                                <ListItemAvatar>
                                                    <Avatar alt="Avatar" src={auth.user.image ? imageParser(auth.user.image) : "https://b.qranio.com/avatar"} className="avatar avatar-bordered" />
                                                </ListItemAvatar>
                                            </ListItem>
                                        </List>
                                    </Tooltip>
                                    <Divider />
                                </div>
                                :
                                <RenderProfileInfo
                                    history={history}
                                    avatar={auth.user.image ? imageParser(auth.user.image) : "https://b.qranio.com/avatar"}
                                    name={auth.user.fname}
                                    neuros={numberSocialMask(help && help.user_neuros ? help.user_neuros : auth.user.neuros)}
                                    xp={numberSocialMask(auth.user.total_points)}
                                    qis={numberSocialMask(auth.user.qi_credit)}
                                    fade={menuOpen === fadeProfile}
                                />

                        }
                        <List component="nav" className="qr-nav_menu" aria-label="main-menu">
                            {
                                items.map((item, index) => (
                                    item.enabled &&
                                    <ListItemLink
                                        setOpenDrawer={menuOpen}
                                        key={index}
                                        to={item.route}
                                        primary={intl.get(item.name)}
                                        icon={item.icon}
                                        section={item.section}
                                        showMenuNotification={item.showMenuNotification} />
                                ))
                            }
                        </List>
                        {
                            config.layout.menu.logout_button ?
                                <>
                                    <Divider />
                                    <List component="nav" aria-label="logout" disablePadding>
                                        <Tooltip title={!menuOpen ? intl.get('MENU_LOGOUT') : ""} placement="right" arrow>
                                            <ListItem button onClick={() => history.push('/logout')}>
                                                <ListItemIcon>
                                                    <Icon ic="exit" color="danger" className="ml-2" />
                                                </ListItemIcon>
                                                <ListItemText primary={intl.get('MENU_LOGOUT')} />
                                            </ListItem>
                                        </Tooltip>
                                    </List>
                                </>
                                : null
                        }
                    </Grid>
                    {
                        config.layout.menu.logo_footer ?
                            <Grid item xs={12} className="qr-nav_logo-footer">
                                <Logo type={themeMode === "DARK" ? "badge-clean" : "badge-color"} />
                            </Grid>
                            : null
                    }
                </Grid>
                :
                <Grid container className="qr-nav_content" justify="center" alignItems="center">
                    <Grid item xs={12} className="p-3"></Grid>
                </Grid>
        )
    }

    return (
        props.more && items.length > 0 ?
            <RenderMenu />
            : items.length > 0 || config.layout.menu.logout_button || config.layout.menu.logo_footer ?
                <Hidden smDown>
                    <Drawer
                        id="qr-left"
                        variant="permanent"
                        anchor="left"
                        open={menuOpen}
                        className={`${menuOpen ? "__open" : "__close"} 
                    ${clsx(classes.drawer, {
                            [classes.drawerOpen]: menuOpen,
                            [classes.drawerClose]: !menuOpen,
                        })}`}
                    >
                        <Grid container id="qr-left_content">
                            <Scrollbars
                                ref={scrollbarMenu}
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                renderView={props => <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />}
                                renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                            >
                                <Grid container className="qr-nav">
                                    <Grid item xs={12} className="qr-nav_header">
                                        <div className="mr-3">
                                            <IconButton onClick={handleMenuDrawer}>
                                                <Icon ic="menu_filled" />
                                            </IconButton>
                                        </div>
                                        <div className="qr-nav_logo">
                                            <Logo type={themeMode === "DARK" ? "main-clean" : "main-color"} />
                                        </div>
                                    </Grid>
                                    <RenderMenu />
                                </Grid>
                            </Scrollbars>
                        </Grid>
                    </Drawer>
                </Hidden>
                :
                null
    );
}

export function MobileBottomNavigation() {
    const [path, setPath] = React.useState(window.location.pathname);
    const auth = useSelector(state => state.auth);
    const [items, setItems] = useState([]);
    const isScreenLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

    useEffect(() => {
        if (!auth)
            return;

        if (itemsMenu && (auth.modules && auth.modules.MENU)) {
            for (let i in itemsMenu) {
                enabledItem(itemsMenu[i], auth, isScreenLg);
            }

            setItems(Object.values(itemsMenu).filter(menu => config.layout.menu.nav_bottom_order.includes(menu.name)));
        }
    }, [auth, isScreenLg]);

    reorderMenuArray(items, 'nav_bottom_order');

    function handleChange(event, newValue) {
        setPath(newValue);
    }

    return (
        items.length > 0 ?
            <BottomNavigation value={path} onChange={handleChange} id="bottom-navigation" className="sticky-bottom">
                {items.map((item, index) => (
                    <BottomNavigationAction
                        component={Link}
                        to={item.route}
                        label={intl.get(item.name + '_MOBILE')}
                        value={item.route}
                        icon={
                            <Badge color="error" badgeContent={item.showMenuNotification ? item.icon === "medal"
                                ? JSON.parse(localStorage.getItem('notifications_count'))?.menu_mission_total || 0
                                : JSON.parse(localStorage.getItem('auth.state.user'))?.notification_count || 0 : 0}>
                                <Icon ic={`${item.icon + (path === item.route ? "_filled" : "_outline")}`} color="light" />
                            </Badge>
                        }
                        key={index}
                        disabled={path === item.route}
                    />
                ))}
            </BottomNavigation>
            : null
    );
}
