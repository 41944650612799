import React from 'react';
import { Grid } from '@material-ui/core';
import { FreeLevel } from '../../../../components/Images/Images';

const FreeQuestionHeader = ({ data }) => {
    const DifficultyLevel = (id, qis) => {
        switch (id) {
            case "1":
                return (<FreeLevel type="easy" text={qis} />)
            case "2":
                return (<FreeLevel type="medium" text={qis} />)
            case "3":
                return (<FreeLevel type="hard" text={qis} />)
            default:
                return null
        }
    }

    return (
        <Grid container justify="center">
            {DifficultyLevel(String(data.question.id_level), data.question.qis)}
        </Grid>
    )
}

export default FreeQuestionHeader