import * as React from "react";
import config from "../../../config";

function StageMedal(props) {
    let medalColor = "";
    let starColor = ["svg-stage-star", "svg-stage-star", "svg-stage-star"];

    if (config.learn.trail.stage.medal) {
        if (props.type === "gold") {
            medalColor = "svg-medal-gold";
        } else if (props.type === "silver") {
            medalColor = "svg-medal-silver";
            starColor[2] = "svg-ribbon-stars-back";
        } else if (props.type === "bronze") {
            medalColor = "svg-medal-bronze";
            starColor[1] = "svg-ribbon-stars-back";
            starColor[2] = "svg-ribbon-stars-back";
        } else {
            medalColor = "svg-medal-gold";
        }
    } else {
        medalColor = "svg-medal";
    }

    return (
        <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
            <g>
                <circle cx={40} cy={40} r={36} className={medalColor} />
                {
                    config.learn.trail.stage.shadow ?
                        <>
                            <path
                                d="M15.3 13.81Q6.75 41.29 22.6 59q16.67 18.63 47.29 1.08a36 36 0 1 1-54.95-45.92Z"
                                style={{
                                    fillRule: "evenodd",
                                    opacity: 0.05,
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                d="M56 16.46q-6.85-4.77-17.3-4.57a49.59 49.59 0 0 1 14.52 7.52 90.13 90.13 0 0 1 11.18 10A19.66 19.66 0 0 0 56 16.46Z"
                                style={{
                                    fill: "#fff",
                                    fillRule: "evenodd",
                                    opacity: 0.4,
                                    isolation: "isolate",
                                }}
                            />
                        </>
                        : null
                }
                <path
                    d="M40 71A31 31 0 1 0 9 40a31 31 0 0 0 31 31Zm0 5a36 36 0 1 1 36-36 36 36 0 0 1-36 36Z"
                    style={{
                        fillRule: "evenodd",
                        opacity: 0.15,
                        isolation: "isolate",
                    }}
                />
                {
                    config.learn.trail.type === "stars" && !props.survey ?
                        <g>
                            <g>
                                <path
                                    d="M19.47 62.91a.86.86 0 0 1 .24.61L20 75.58a.88.88 0 0 1-.64.91A102.34 102.34 0 0 0 4.89 80a.5.5 0 0 1-.27 0 .63.63 0 0 1-.46-.31 1 1 0 0 1 0-.94c1-2.19 2.13-4.4 3.25-6.56C6 70.75 4.52 69.34 3.06 68a.92.92 0 0 1-.24-.84.87.87 0 0 1 .53-.67 110.25 110.25 0 0 1 15.58-3.75.61.61 0 0 1 .54.17Zm41.83-.18a109.59 109.59 0 0 1 15.58 3.75.88.88 0 0 1 .54.67.92.92 0 0 1-.25.84c-1.46 1.35-2.9 2.76-4.31 4.19 1.12 2.17 2.21 4.37 3.26 6.56a1 1 0 0 1 0 .94.65.65 0 0 1-.46.31.5.5 0 0 1-.27 0 103 103 0 0 0-14.46-3.48.87.87 0 0 1-.65-.91l.28-12.06a.91.91 0 0 1 .24-.61.62.62 0 0 1 .5-.2Z"
                                    className="svg-ribbon-stars-back"
                                    style={{
                                        fillRule: "evenodd",
                                    }}
                                />
                                <path
                                    d="M60.26 74.54q5.87.24 5.85.5c0 .25-3.14 1.25-4.69 1.49h-.2a1.17 1.17 0 0 1-.61-.15.89.89 0 0 1-.35-.76ZM20 74.51v1.11a1 1 0 0 1-.29.77 1.08 1.08 0 0 1-.57.15h-.2c-1.45-.24-4.92-1-4.94-1.28s5.93-.9 6-.75Z"
                                    className="svg-ribbon-stars-shadow"
                                    style={{
                                        fillRule: "evenodd",
                                    }}
                                />
                                <path
                                    d="M14.18 75.24a.39.39 0 0 1-.44-.09.92.92 0 0 1-.26-.57l-1.81-13.2a1 1 0 0 1 .39-1A65.37 65.37 0 0 1 39.88 54a65.38 65.38 0 0 1 27.83 6.4 1 1 0 0 1 .38 1l-1.8 13.2a1 1 0 0 1-.26.57.44.44 0 0 1-.29.12.4.4 0 0 1-.15 0Q52.74 76 39.88 76"
                                    className="svg-ribbon-stars-front"
                                    style={{
                                        fillRule: "evenodd",
                                    }}
                                />
                            </g>
                            <g>
                                <path
                                    d="m23.81 69.32 3.38 1.16a.63.63 0 0 0 .78-.87l-1.51-3.23 2.16-2.84a.64.64 0 0 0-.59-1l-3.54.46-2-2.92a.64.64 0 0 0-1.15.25l-.68 3.49-3.42 1a.63.63 0 0 0-.17 1.18l3.13 1.72-.07 3.57a.63.63 0 0 0 1.07.47Z"
                                    className={starColor[0]}
                                    style={{
                                        fillRule: "evenodd",
                                    }}
                                />
                                <path
                                    d="m40 69 3.94 2.37a.82.82 0 0 0 1.22-.89l-1-4.46 3.48-3a.81.81 0 0 0-.47-1.43l-4.57-.39-1.85-4.2a.81.81 0 0 0-1.5 0l-1.8 4.21-4.57.39a.81.81 0 0 0-.47 1.4l3.48 3-1 4.46a.82.82 0 0 0 1.22.89Z"
                                    className={starColor[1]}
                                    style={{
                                        fillRule: "evenodd",
                                    }}
                                />
                                <path
                                    d="m56.19 69.31 2.61 2.44a.63.63 0 0 0 1.07-.47l-.07-3.57L62.93 66a.64.64 0 0 0-.12-1.17l-3.42-1-.68-3.49a.64.64 0 0 0-1.15-.25L55.51 63 52 62.52a.63.63 0 0 0-.59 1l2.16 2.85L52 69.61a.63.63 0 0 0 .78.87Z"
                                    className={starColor[2]}
                                    style={{
                                        fillRule: "evenodd",
                                    }}
                                />
                            </g>
                        </g>
                        : null
                }
            </g>
        </svg>
    )
}

export default StageMedal
