import * as React from "react"

function ImgStars1(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M27.837 27.458a7.967 7.967 0 00-.756 3.077 7.994 7.994 0 00.492 3.138 8.086 8.086 0 00-6.212-.292 7.97 7.97 0 00.756-3.077 7.996 7.996 0 00-.493-3.139 8.084 8.084 0 006.213.293"
                    className="svg-stars-left-3"
                />
                <path
                    d="M18.902 9.361a10.095 10.095 0 00-.969 3.897 10.14 10.14 0 00.613 3.978 10.244 10.244 0 00-7.87-.392c.574-1.204.906-2.522.969-3.896a10.139 10.139 0 00-.614-3.979 10.246 10.246 0 007.871.392"
                    className="svg-stars-left-1"
                />
                <path
                    d="M31.667 14.016a7.583 7.583 0 00-1.028 2.835 7.619 7.619 0 00.149 3.02 7.71 7.71 0 00-5.855-.906 7.593 7.593 0 001.028-2.836 7.619 7.619 0 00-.149-3.02 7.708 7.708 0 005.855.907"
                    className="svg-stars-left-2"
                />
                <path
                    d="M16.154 24.469a5.959 5.959 0 00-.808 2.228 5.97 5.97 0 00.117 2.372 6.056 6.056 0 00-4.6-.712c.41-.671.687-1.425.807-2.227a5.968 5.968 0 00-.116-2.373 6.058 6.058 0 004.6.712"
                    className="svg-stars-left-4"
                />
            </g>
        </svg>
    )
}

export default ImgStars1
