import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { Grid, Button, Container, Typography } from '@material-ui/core';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

var HtmlToReactParser = require('html-to-react').Parser;

export default function AcceptTerms() {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const termsOfUse = useSelector(state => state.auth.termsOfUse);
    const [logout, setLogout] = useState(false);
    const userAuthenticated = auth.authenticated === true && Object.keys(auth.user).length !== 0;
    const acceptedUseTerms = localStorage.getItem('auth.state.user') && JSON.parse(localStorage.getItem('auth.state.user')).accept_terms === 'Y';

    useEffect(() => {
        if (userAuthenticated) {
            dispatch(
                AuthActions.getTermsOfUse({
                    language: auth.user.language,
                    type: 'TERMS_OF_USE'
                }));
        } else {
            history.push('/');
        }

    }, [auth.user.language, dispatch, userAuthenticated, history]);

    useEffect(() => {
        if (logout) {
            dispatch(AuthActions.logout());
            history.push('/');
        }
    }, [logout, dispatch, history]);

    useEffect(() => {
        if (acceptedUseTerms) {
            history.push('/home');
        }
    }, [acceptedUseTerms, history]);

    function getPolitics() {
        var htmlInput = termsOfUse.data;
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement = htmlToReactParser.parse(htmlInput);
        return (reactElement);
    }

    function acceptTerms() {
        dispatch(AuthActions.updateTermsOfUse({}));
        history.push('/home');
    }

    return (
        <Container maxWidth="md">
            {
                termsOfUse && termsOfUse.load === "finished" ?
                    !termsOfUse.error ?
                        <Grid container justify="center">
                            <Grid item xs={12} className="text-break pb-5 mb-4">
                                {getPolitics()}
                                <div id="bottom-bar" className="pl-0 pr-0">
                                    <Container maxWidth="sm">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button id="btn_intro_terms_refuse" className="btn-block" variant="contained" color="primary" onClick={() => setLogout(true)}>
                                                    {intl.get("BTN_REFUSE")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button id="btn_intro_terms_accept" className="btn-block" variant="contained" color="primary" onClick={() => acceptTerms()}>
                                                    {intl.get("BTN_ACCEPT")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="column" alignItems="center" justify="center" align="center" className="vh-100">
                            <Icon ic="error" color="light" className="icon-xxl mb-3" />
                            <Typography variant="h4" component="h4" className="mb-3">
                                {intl.get("ERROR_TERMS_OF_USE")}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={() => setLogout(true)}>
                                <Icon ic="arrow_left" className="mr-2" /> {intl.get("BTN_BACK")}
                            </Button>
                        </Grid>
                    :
                    <LoadingContent />
            }
        </Container>
    )
}