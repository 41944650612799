import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AvatarActions } from '../../redux/ducks/avatar';
import { imageParser } from '../../utils/utils';
import SelectAvatar from '../../components/SelectAvatar';
import Cropper from '../../components/Cropper/Cropper';
import { Button, Grid, CardActionArea, Typography, Hidden, Avatar } from '@material-ui/core';
import IntroLayout from './components/IntroLayout';
import { LoadingBounce } from '../../components/Loading';

export default function AvatarList(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { loading, avatarList } = useSelector(state => state.avatar);
    const [selectedAvatar, setSelectedAvatar] = useState(JSON.parse(localStorage.getItem('auth.state.user')).image);
    const [modalImagePicker, setModal] = useState(false);
    const [img, setImg] = useState({
        imageSrc: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
    });

    useEffect(() => {
        dispatch(AvatarActions.avatarList({}));
    }, [dispatch]);

    function imgHandler(imageDataUrl) {
        setImg({
            imageSrc: imageDataUrl,
            crop: { x: 0, y: 0 },
            zoom: 1,
        });
        setModal(true);
    }

    return (
        <IntroLayout id="intro-avatar" title={intl.get("INTRO_AVATAR_TITLE")} appbarMobile="default">
            <Grid container>
                {
                    !loading || avatarList.length !== 0 ?
                        <>
                            <Hidden smDown>
                                <Grid container className="intro-column-nav" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography component="h2" variant="h3" align="center" className="text-dark">
                                            {intl.get('INTRO_AVATAR_TITLE')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={12} align="center">
                                    <Avatar className="avatar-xxl avatar-bordered-lg border-primary" src={selectedAvatar && imageParser(selectedAvatar)} alt="Avatar" />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectAvatar type="button" alt={auth.user.fname} blob={auth.user.image ? auth.user.image.blob : null} imageDataUrl={(img) => imgHandler(img)} />
                                    {modalImagePicker && <Cropper img={img.imageSrc} closeModal={(closeModal) => {
                                        setModal(closeModal)
                                        setImg({})
                                    }} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="center" alignItems="center" spacing={1}>
                                        {
                                            avatarList && avatarList.map((item, index) => {
                                                return (
                                                    <Grid item>
                                                        <CardActionArea key={item.blob.file_name + index} onClick={() => setSelectedAvatar(item)}>
                                                            <Avatar className={`avatar-md avatar-bordered-lg rounded-lg ${selectedAvatar === item ? 'border-primary' : 'border-white'}`} src={imageParser(item)} alt="Avatar" />
                                                        </CardActionArea>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    {
                                        loading || avatarList.length === 0 ?
                                            <Grid container alignItems="center" justify="center">
                                                <LoadingBounce />
                                            </Grid>
                                            :
                                            <Button
                                                id="btn_intro_avatar"
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="btn-block"
                                                onClick={() => {
                                                    dispatch(AvatarActions.avatarUpdate({
                                                        avatar: selectedAvatar.blob.file_name.toString()
                                                    }));
                                                }}
                                            >
                                                {intl.get('BTN_FINISH')}
                                            </Button>
                                    }
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Grid container alignItems="center" justify="center">
                            <LoadingBounce />
                        </Grid>
                }
            </Grid>
        </IntroLayout>
    )
};
