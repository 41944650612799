import { managerService } from '../../services/manager.service'

// CONST
export const Types = {
    TOTAL_SCORM: 'manager/TOTAL_SCORM',
    TOTAL_CLASSROOM: 'manager/TOTAL_CLASSROOM',
    TOTAL_TRAIL: 'manager/TOTAL_TRAIL',
    TEAM_EMPLOYEES: 'manager/TEAM_EMPLOYEES',
    TEAM_ACTIVITIES: 'manager/TEAM_ACTIVITIES',
    GENERAL_ACTIVITIES: 'manager/GENERAL_ACTIVITIES',
    USERS_LIST: 'manager/USERS_LIST',
    REGIONAL_LIST: 'manager/REGIONAL_LIST',
    SUBAREA_LIST: 'manager/SUBAREA_LIST',
    BRAND_LIST: 'manager/BRAND_LIST',
    COST_CENTER_LIST: 'manager/COST_CENTER_LIST',
    RESET: 'manager/RESET',
};

// INITIAL STATE
const initialState = {
    totalScorm: {},
    totalClassroom: {},
    totalTrail: {},
    teamEmployees: {},
    teamActivities: {},
    generalActivities: {},
    usersList: {},
    regionalList: {},
    subareaList: {},
    brandList: {},
    costCenterList: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.TOTAL_SCORM:
            return {
                ...state,
                totalScorm: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }

        case Types.TOTAL_CLASSROOM:
            return {
                ...state,
                totalClassroom: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }

        case Types.TOTAL_TRAIL:
            return {
                ...state,
                totalTrail: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }

        case Types.TEAM_EMPLOYEES:
            return {
                ...state,
                teamEmployees: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }

        case Types.TEAM_ACTIVITIES:
            return {
                ...state,
                teamActivities: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.GENERAL_ACTIVITIES:
            return {
                ...state,
                generalActivities: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.USERS_LIST:
            return {
                ...state,
                usersList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.REGIONAL_LIST:
            return {
                ...state,
                regionalList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SUBAREA_LIST:
            return {
                ...state,
                subareaList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BRAND_LIST:
            return {
                ...state,
                brandList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.COST_CENTER_LIST:
            return {
                ...state,
                costCenterList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                totalScorm: {},
                totalClassroom: {},
                totalTrail: {},
                teamEmployees: {},
                teamActivities: {},
                generalActivities: {},
                usersList: {},
                regionalList: {},
                subareaList: {},
                brandList: {},
                costCenterList: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getTotalScorm(params) {
    return dispatch => {
        return managerService.getTotalScorm(params).then(data => {
            dispatch({
                type: Types.TOTAL_SCORM,
                payload: {
                    data: data.team_scorm,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TOTAL_SCORM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTotalClassroom(params) {
    return dispatch => {
        return managerService.getTotalClassroom(params).then(data => {
            dispatch({
                type: Types.TOTAL_CLASSROOM,
                payload: {
                    data: data.team_classroom,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TOTAL_CLASSROOM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTotalTrail(params) {
    return dispatch => {
        return managerService.getTotalTrail(params).then(data => {
            dispatch({
                type: Types.TOTAL_TRAIL,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TOTAL_TRAIL,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTeamEmployees(params) {
    return dispatch => {
        return managerService.getTeamEmployees(params).then(data => {
            dispatch({
                type: Types.TEAM_EMPLOYEES,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TEAM_EMPLOYEES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTeamActivities(params) {
    return dispatch => {
        return managerService.getTeamActivities(params).then(data => {
            dispatch({
                type: Types.TEAM_ACTIVITIES,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TEAM_ACTIVITIES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getGeneralActivities(params) {
    return dispatch => {
        return managerService.getGeneralActivities(params).then(data => {
            dispatch({
                type: Types.GENERAL_ACTIVITIES,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.GENERAL_ACTIVITIES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getManagerUsersList(params) {
    return dispatch => {
        return managerService.getManagerUsersList(params).then(data => {
            dispatch({
                type: Types.USERS_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.USERS_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRegionalList(params) {
    return dispatch => {
        return managerService.getRegionalList(params).then(data => {
            dispatch({
                type: Types.REGIONAL_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REGIONAL_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getSubareaList(params) {
    return dispatch => {
        return managerService.getSubareaList(params).then(data => {
            dispatch({
                type: Types.SUBAREA_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SUBAREA_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getBrandList(params) {
    return dispatch => {
        return managerService.getBrandList(params).then(data => {
            dispatch({
                type: Types.BRAND_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BRAND_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getCostCenterList(params) {
    return dispatch => {
        return managerService.getCostCenterList(params).then(data => {
            dispatch({
                type: Types.COST_CENTER_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COST_CENTER_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getTotalScorm,
    getTotalClassroom,
    getTotalTrail,
    getTeamEmployees,
    getTeamActivities,
    getGeneralActivities,
    getManagerUsersList,
    getRegionalList,
    getSubareaList,
    getBrandList,
    getCostCenterList,
    reset
}
