import { PublicService } from '../../services/public.service';

// CONST
export const Types = {
    AUTH_CERTIFICATE: 'public/AUTH_CERTIFICATE',
    RESET: 'public/RESET',
};

// INITIAL STATE
const initialState = {
    certificate: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.AUTH_CERTIFICATE:
            return {
                ...state,
                certificate: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                certificate: {}
            }
        default:
            return state
    }
}

// ACTIONS
function getAuthCertificate(params) {
    return dispatch => {
        return PublicService.getAuthCertificate(params).then(data => {
            dispatch({
                type: Types.AUTH_CERTIFICATE,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.AUTH_CERTIFICATE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getAuthCertificate,
    reset
}
