import { UserAgentApplication } from 'msal';
import config from '../../../config';

// Msal Configurations
const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_API_AZURE_ID,
        authority: process.env.REACT_APP_API_AZURE_AUTHORITY,
        redirectURI: process.env.REACT_APP_API_AZURE_REDIRECTURI,
    },
    cache: {
        // cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Authentication Parameters
export const scopes = {
    scopes: config.login_method.ad_azure.scopes
}

// export const myMSALObj = new MsalAuthProvider(config, authenticationParameters)
export const myMSALObj = new UserAgentApplication(msalConfig);
