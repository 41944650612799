import * as React from "react";

function Library(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M19.79 1L5.894.999c-1.278 0-2.788.618-2.886 3.475H3v16.21l.01-.031C3.116 22.76 4.485 23 5.088 23h14.123c1.157 0 1.157-1.158 1.157-1.158V1.58A.578.578 0 0019.79 1z"
                    fill="#7E57C2"
                />
                <path
                    d="M18.632 18.947H5.088c-.77 0-.93.648-.93 1.448 0 .8.16 1.448.93 1.448h13.544v-2.896z"
                    fill="#FFE0B2"
                />
                <path
                    d="M5.088 18.947c-.675 0-.88.497-.922 1.158h14.466v-1.158H5.088z"
                    fill="#E0B990"
                />
                <path fill="#FFECB3" d="M5.895 4.473h12.158v2.894H5.895z" />
                <path
                    d="M19.79 18.947h-1.158V23h.579c1.157 0 1.157-1.158 1.157-1.158V18.37a.578.578 0 01-.579.577z"
                    fill="#311B92"
                />
            </g>
        </svg>
    )
}

export default Library;
