import React from 'react';
import intl from 'react-intl-universal';
import {
    Button, Dialog, DialogContent, DialogContentText,
    DialogTitle, Divider
} from '@material-ui/core';

export default function DialogPurchase(props) {
    const { open, close, item, purchase, type } = props;

    return (
        <Dialog
            id="dialog-purchase"
            open={open} onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {
                    type === "subcategory" ?
                        intl.get("FREE_DIALOG_PURCHASE_SUBCATEGORY_TITLE")
                        :
                        intl.get("FREE_DIALOG_PURCHASE_CATEGORY_TITLE")
                }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="text-light">
                    {
                        type === "subcategory" ?
                            intl.get("FREE_DIALOG_PURCHASE_SUBCATEGORY_TEXT")
                            :
                            intl.get("FREE_DIALOG_PURCHASE_CATEGORY_TEXT")
                    }
                </DialogContentText>
            </DialogContent>
            <div className="d-flex flex-column">
                <Divider />
                <Button onClick={() => purchase(item)} color="primary">
                    {intl.get("BTN_BUY_WITH_NEUROS")}
                </Button>
                <Divider />
                <Button onClick={close} color="primary" autoFocus>
                    {intl.get("BTN_CANCEL")}
                </Button>
            </div>
        </Dialog>
    );
}