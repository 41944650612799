import * as React from "react"

function ImgFeed3(props) {
    return (
        <svg width={132} height={44} viewBox="0 0 132 44" {...props}>
            <g transform="translate(4 4)" fill="none" fillRule="evenodd">
                <rect fill="#FFF" width={124} height={36} rx={10} />
                <ellipse
                    className="svg-feed-circle"
                    fillRule="nonzero"
                    cx={18.332}
                    cy={18.281}
                    rx={10.585}
                    ry={10.5}
                />
                <path
                    d="M16.961 13c-.992 0-1.805.714-1.805 1.585 0 .314.107.607.288.854h-1.4c-.383 0-.694.273-.694.61v1.463c0 .337.311.61.694.61h3.89v-2.683h-.973c-.542 0-.972-.378-.972-.854 0-.475.43-.853.972-.853s.972.378.972.853v.854h.834v-.854c0-.475.43-.853.972-.853s.972.378.972.853c0 .476-.43.854-.972.854h-.972v2.683h3.889c.383 0 .694-.273.694-.61V16.05c0-.337-.311-.61-.694-.61h-1.4a1.44 1.44 0 00.288-.854c0-.87-.813-1.585-1.805-1.585-.57 0-1.057.25-1.389.614A1.868 1.868 0 0016.961 13zm-3.055 5.854v2.56c0 .874.81 1.586 1.805 1.586h2.222v-4.146h-4.027zm4.86 0V23h2.223c.994 0 1.805-.712 1.805-1.585v-2.561h-4.027z"
                    fill="#FFF"
                />
                <rect
                    className="svg-feed-text"
                    x={36.221}
                    y={10.919}
                    width={45.366}
                    height={4.154}
                    rx={2.077}
                />
                <rect
                    className="svg-feed-text-light"
                    x={35.93}
                    y={21.307}
                    width={66.537}
                    height={4.5}
                    rx={2.25}
                />
            </g>
        </svg>
    )
}

export default ImgFeed3
