import * as React from "react";

function ImgHelpTime(props) {
    if (props.status === "disabled") {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M18.294 8.663a.576.576 0 01-.804.086l-1.777-1.423a.562.562 0 01-.085-.795l1.078-1.322a.576.576 0 01.804-.084l1.778 1.423a.562.562 0 01.085.796l-1.079 1.32z"
                        className="svg-help-disabled-2"
                    />
                    <path className="svg-help-disabled-3" d="M10.5 3.5h3V6h-3z" />
                    <path
                        d="M19.5 14c0 4.143-3.357 7.5-7.5 7.5A7.499 7.499 0 014.5 14c0-4.143 3.357-7.5 7.5-7.5s7.5 3.357 7.5 7.5z"
                        className="svg-help-disabled-1"
                    />
                    <path d="M12 14V6.5c-2.072 0-3.947.84-5.303 2.197L12 14z" className="svg-help-disabled-2" />
                    <path
                        d="M10.5 14.016a1.48 1.48 0 001.5 1.483 1.519 1.519 0 001.5-1.516 1.485 1.485 0 00-1.507-1.483 1.511 1.511 0 00-1.493 1.516"
                        className="svg-help-disabled-3"
                    />
                    <path className="svg-help-disabled-3" d="M7.758 9.758l4.596 3.182-1.414 1.414z" />
                    <path
                        d="M12 5a9 9 0 10.001 18.001A9 9 0 0012 5zm0 16a7 7 0 110-14 7 7 0 010 14zm2.5-19.5v2a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-2A.5.5 0 0110 1h4a.5.5 0 01.5.5z"
                        className="svg-help-disabled-2"
                    />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none">
                    <path
                        d="M18.294 8.663a.576.576 0 01-.804.086l-1.777-1.423a.562.562 0 01-.085-.795l1.078-1.322a.576.576 0 01.804-.084l1.778 1.423a.562.562 0 01.085.796l-1.079 1.32zM10.5 3.5h3V6h-3z"
                        fill="#BD483B"
                    />
                    <path
                        d="M19.5 14c0 4.143-3.357 7.5-7.5 7.5A7.499 7.499 0 014.5 14c0-4.143 3.357-7.5 7.5-7.5s7.5 3.357 7.5 7.5z"
                        fill="#F5F5F5"
                    />
                    <path
                        d="M12 14V6.5c-2.072 0-3.947.84-5.303 2.197L12 14z"
                        fill="#EF948A"
                    />
                    <path
                        d="M10.5 14.016a1.48 1.48 0 001.5 1.483 1.519 1.519 0 001.5-1.516 1.485 1.485 0 00-1.507-1.483 1.511 1.511 0 00-1.493 1.516"
                        fill="#464646"
                    />
                    <path fill="#464646" d="M7.758 9.758l4.596 3.182-1.414 1.414z" />
                    <path
                        d="M11.5 14.008a.495.495 0 00.502.492.503.503 0 00.498-.505.495.495 0 00-.5-.494.507.507 0 00-.5.507"
                        fill="#E65748"
                    />
                    <path
                        d="M12 5a9 9 0 10.001 18.001A9 9 0 0012 5zm0 16a7 7 0 110-14 7 7 0 010 14zm2.5-19.5v2a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-2A.5.5 0 0110 1h4a.5.5 0 01.5.5z"
                        fill="#E65748"
                    />
                </g>
            </svg>
        )
    }
}

export default ImgHelpTime
