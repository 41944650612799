import * as React from "react"

function ImgBalloon1(props) {
    return (
        <svg width={64} height={64} viewBox="0 0 64 64" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M51.88 53.045a3.164 3.164 0 110 6.328 3.164 3.164 0 010-6.328zm-11.865-7.12a4.746 4.746 0 110 9.493 4.746 4.746 0 010-9.493zM6 25.095c0-4.453 2.776-8.252 6.697-9.8.614-5.61 5.388-9.977 11.19-9.977 3.163 0 6.016 1.302 8.062 3.39A11.253 11.253 0 0141.113 4c6.22 0 11.262 5.018 11.262 11.206 0 .03-.004.058-.004.087C56.343 17.731 59 22.087 59 27.073c0 7.645-6.229 13.842-13.912 13.842-2.709 0-5.229-.783-7.366-2.114a12.582 12.582 0 01-9.86 4.751A12.58 12.58 0 0116.16 35.62C10.512 35.39 6 30.772 6 25.095z"
                    fill="#FFF"
                />
                <g fillRule="nonzero">
                    <path
                        fill="#37474F"
                        d="M30.377 12.18l1.113-.196 3.532 20.032-1.113.196z"
                    />
                    <path
                        fill="#37474F"
                        d="M34.669 27.36l.926.649-4.603 6.572-.925-.648z"
                    />
                    <path
                        fill="#37474F"
                        d="M39.236 32.32l-.648.925-6.573-4.602.648-.926z"
                    />
                    <path
                        fill="#CFD8DC"
                        d="M20.099 15.714l22.257-3.925 2.748 15.58-22.258 3.925z"
                    />
                    <path
                        d="M19.444 15.255l23.37-4.12.393 2.225-23.37 4.121-.393-2.226zm2.747 15.581l23.371-4.12.196 1.112-23.37 4.12-.197-1.112zm16.546 1.099a.848.848 0 10.294 1.67.848.848 0 00-.294-1.67zm-8.347 1.472a.848.848 0 10.294 1.669.848.848 0 00-.294-1.67z"
                        fill="#607D8B"
                    />
                    <path
                        fill="#C51162"
                        d="M36.693 19.042l-2.694 3.917-3.928-2.75-3.722 5.247 1.44 1.008 2.694-3.918 3.928 2.75 3.722-5.246z"
                    />
                    <path fill="#C51162" d="M39.475 21.477l-4.582-3.208 3.895-.687z" />
                </g>
            </g>
        </svg>
    )
}

export default ImgBalloon1
