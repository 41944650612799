import * as React from "react"

function ImgHandRight(props) {
    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M94.104 27.261s-4.376 4.18-3.877 7.012l.026.148 4.69 26.595.008.05c.125.708 9.683 6.568 11.142 8.623.645.908 1.235 3.182 1.677 5.511.56 2.946 1.049 5.904 1.049 5.904L97.553 75.82l12.009 68.107c.435 2.467-1.23 4.841-3.7 5.277l-38.293 6.752s14.904 14.04 27.025 19.205c21.527 9.173 23.953 20.5 33.086 41.142l54.442-9.6c2.404-7.245.613-17.402-5.373-30.47-8.978-19.6-14.096-20.97-30.928-78.864-4.645-15.976-20.433-44.712-20.433-44.712-14.895-2.242-31.284-25.396-31.284-25.396z"
                    className="svg-hand"
                />
                <path
                    d="M81.806 16.582c2.485-.438 4.876 1.228 5.312 3.703l21.84 123.854c.436 2.475-1.241 4.859-3.727 5.297l-53.28 9.394c-2.485.439-4.876-1.227-5.312-3.702L24.799 31.273c-.435-2.474 1.241-4.858 3.727-5.296l53.28-9.395z"
                    className="svg-smartphone"
                />
                <path
                    d="M84.335 24.803a.991.991 0 011.147.8l20.119 114.099a.991.991 0 01-.804 1.143l-55.375 9.764a.991.991 0 01-1.147-.8L28.156 35.712a.991.991 0 01.805-1.144l55.374-9.764z"
                    className="svg-feedback-correct"
                />
                <path
                    d="M61.994 23.493a.906.906 0 011.048.731.907.907 0 01-.735 1.046l-12.487 2.2a.906.906 0 01-1.049-.73.906.906 0 01.735-1.046l12.488-2.202z"
                    fillOpacity={0.2}
                    fill="#000"
                />
                <path
                    d="M82.335 74.083l-2.462-1.666a1.498 1.498 0 00-2.07.39L65.736 90.523l-5.545-5.52a1.494 1.494 0 00-2.102 0L55.98 87.1a1.484 1.484 0 000 2.098l8.527 8.49c.478.475 1.228.838 1.904.838.676 0 1.357-.421 1.797-1.057L82.732 76.14a1.475 1.475 0 00-.397-2.056z"
                    fill="#FFF"
                />
                <path
                    d="M31.03 64.78s-9.213 5.878-9.744 13.318c-.53 7.44 4.381 13.373 4.381 13.373s-2.515 3.95-.95 8.74c1.564 4.788 6.58 11.54 6.58 11.54s-3.563 5.277-2.416 9.808c1.146 4.532 12.54 16.697 14.945 18.063 6.297 3.575 5.021-8.015 4.3-9.703a41.5 41.5 0 00-1.567-3.232s6.382.834 6.18-6.576c-.201-7.41-4.906-11.702-4.906-11.702s3.772-5.01 1.476-10.868c-2.296-5.857-16.615-22.664-16.615-22.664l-1.663-10.096z"
                    className="svg-hand"
                />
                <path
                    d="M108.423 79.122c.165.957.268 1.576.268 1.576l-11.11-5.486 1.566 8.88c8.325 2.955 11.736 9.083 11.736 9.083l-2.458-14.052h-.002z"
                    className="svg-hand-shade"
                />
                <path
                    d="M53.45 119.352c1.004 6.282-2.428 9.124-2.428 9.124.723 3.803 1.64 11.84-3.405 13.295l-.466-2.64c2.865-1.346 1.986-8.746 1.407-10.056-.744-1.68-1.598-3.175-1.598-3.175s6.629.762 6.49-6.548zm-2.769-16.708c1.316 4.019 1.419 10.487 1.419 10.487-1.479-3.414-3.639-5.28-3.639-5.28s1.695-2.127 2.22-5.206z"
                    fill="#000"
                    opacity={0.1}
                />
            </g>
        </svg>
    )
}

export default ImgHandRight
