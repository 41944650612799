import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../../redux/ducks/trail';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Typography, Grid, Button, Box,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import SnackBar from '../../../../components/snackBar';
import { LoadingBounce } from '../../../../components/Loading';
import { Icon, Char } from '../../../../components/Images/Images';

export default function IntroScorm(props) {
    const dispatch = useDispatch();
    const { session } = useSelector(state => state.trail);
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const [loadingButton, setLoadingButton] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [loadData, setLoadData] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    async function startScorm() {
        setLoadingButton(true);
        await dispatch(TrailActions.getStepStart({
            id_step: step.id_step
        }));
        setLoadData(true);
    }

    if (loadData && (session && session.load === "finished")) {
        if (session.error) {
            setOpenAlert(false)
            setSnackMessage(session.messages);
            setSnackOpen(true);
            setLoadingButton(false);
            dispatch(TrailActions.reset());
        } else {
            return <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/scorm`,
                state: {
                    origin: 'scorm',
                    step: step,
                    session: session.data
                }
            }} />
        }
    }

    return (
        <Grid container id="trail-steps-intro-scorm">
            {
                config.learn.trail.vector_images ?
                    <Grid container alignItems="flex-end" justify="center" className={`appbar-custom-content${!config.layout.navbar ? " __no-appbar" : ""}`}>
                        <div className={`blackboard${!config.learn.trail.chars ? " mb-4" : ""}`}>
                            <div className="blackboard-text">
                                <Typography variant="h4" component="p" className="mb-2">
                                    {intl.get("TRAIL_STEP_INTRO_SCORM_TEXT")}
                                </Typography>
                            </div>
                        </div>
                        <Char type="trail-intro-flashcards" className="char-intro-flashcards" />
                    </Grid>
                    :
                    <Grid container alignItems="flex-end" justify="center" className={`appbar-custom-content __no-vector${!config.layout.navbar ? " __no-appbar" : ""}`}>
                        <Container maxWidth="md">
                            <Grid container alignItems="center" justify="center" className="pb-5">
                                <Grid item xs={12} align="center">
                                    <Icon ic="scorm" color="white" className="icon-xxl" />
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h3" component="h2">
                                        {intl.get("TRAIL_STEP_INTRO_SCORM_TITLE")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="body1" component="p">
                                        {intl.get("TRAIL_STEP_INTRO_SCORM_NO_VECTOR_TEXT")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
            }
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Typography variant="body2" component="div" className="fs-md">
                            {ReactHtmlParser(step.description)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Box p={2} className="highlight-box">
                            <Grid container alignItems="center" className="mb-1">
                                <Icon ic="error" color="warning" className="icon-xs mr-1" />
                                <Typography variant="body1" component="h5">
                                    {intl.get("TRAIL_STEP_INTRO_SCORM_ALERT_TITLE")}
                                </Typography>
                            </Grid>
                            <Typography variant="body2" component="p">
                                {intl.get("TRAIL_STEP_INTRO_SCORM_ALERT_TEXT")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <div id="bottom-bar" className="relative-bar">
                            <Button id="btn_trail_step_intro_scorm" variant="contained" className="btn-block-xs w-50" onClick={config.learn.trail.scorm_open_alert ? () => setOpenAlert(true) : startScorm}>
                                {intl.get("BTN_TRAIL_START_2")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Dialog open={openAlert} onClose={() => setOpenAlert(false)} aria-labelledby="dialog-info-scorm">
                    <DialogTitle>
                        {intl.get("SCORM_INFO_DIALOG_TITLE")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {intl.get("SCORM_INFO_DIALOG_TEXT")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            loadingButton ?
                                <Grid container justify='center'>
                                    <LoadingBounce size={24} />
                                </Grid>
                                :
                                <>
                                    <Button onClick={() => setOpenAlert(false)} color="primary">
                                        {intl.get("BTN_CANCEL")}
                                    </Button>
                                    <Button onClick={startScorm} color="primary">
                                        {intl.get("BTN_START")}
                                    </Button>
                                </>
                        }
                    </DialogActions>
                </Dialog>
                <SnackBar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackOpen}
                    message={snackMessage}
                    status="error"
                    time={5}
                    closeSnack={() => setSnackOpen(false)}
                />
            </Container>
        </Grid>
    )
}
