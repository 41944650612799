import * as React from "react";

function ImgHelpEliminate1(props) {
    if (props.status === "disabled") {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M19.6 16.598V16.247c0-1.76-.66-3.377-1.687-4.644-.073-.21-.146-.492-.146-.703.366-1.97.88-4.362-1.834-6.19.294 1.688 0 3.446-1.466 3.446-1.467 0-.074-2.391-1.467-4.502-.733-1.126-2.2-2.532-4.4-2.462 3.007 1.688 1.687 7.035.733 7.668-1.466 1.055-1.466-2.04-3.666-2.04.88.422.44 3.165-.074 4.22a12.294 12.294 0 00-1.32 4.01c-.073.423-.146.845-.146 1.267v.352C4.347 20.538 8.087 23 12.193 23c4.254-.07 7.26-2.462 7.407-6.402z"
                        className="svg-help-disabled-2"
                    />
                    <path
                        d="M13.733 14.488c.44 1.196.367 2.11-.733 2.11-1.54 0-1.833-.914-1.467-1.547 1.54-2.885 1.174-3.307 0-4.784.66 3.236-2.933 3.588-2.933 7.809 0 1.97 1.613 3.517 3.667 3.517 2.053 0 3.666-1.548 3.666-3.517.074-.915-.44-2.533-2.2-3.588z"
                        className="svg-help-disabled-1"
                    />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none">
                    <path
                        d="M19.6 16.598V16.247c0-1.76-.66-3.377-1.687-4.644-.073-.21-.146-.492-.146-.703.366-1.97.88-4.362-1.834-6.19.294 1.688 0 3.446-1.466 3.446-1.467 0-.074-2.391-1.467-4.502-.733-1.126-2.2-2.532-4.4-2.462 3.007 1.688 1.687 7.035.733 7.668-1.466 1.055-1.466-2.04-3.666-2.04.88.422.44 3.165-.074 4.22a12.294 12.294 0 00-1.32 4.01c-.073.423-.146.845-.146 1.267v.352C4.347 20.538 8.087 23 12.193 23c4.254-.07 7.26-2.462 7.407-6.402z"
                        fill="#E65748"
                    />
                    <path
                        d="M13.733 14.488c.44 1.196.367 2.11-.733 2.11-1.54 0-1.833-.914-1.467-1.547 1.54-2.885 1.174-3.307 0-4.784.66 3.236-2.933 3.588-2.933 7.809 0 1.97 1.613 3.517 3.667 3.517 2.053 0 3.666-1.548 3.666-3.517.074-.915-.44-2.533-2.2-3.588z"
                        fill="#FDCE5A"
                    />
                </g>
            </svg>
        )
    }
}

export default ImgHelpEliminate1
