import * as React from "react"

function ImgSwordRight(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <g fill="none">
                <path
                    fill="#DCDCDC"
                    d="M5.222 43.5L31.87 17.804l2.284 2.203L14.358 42.03z"
                />
                <path
                    fill="#C3C3C3"
                    d="M5.222 43.5L31.87 17.804l-2.284-2.202L6.746 34.69z"
                />
                <path
                    d="M42.909 12.297l-5.33-5.139a1.516 1.516 0 01-.104-2.091l.105-.111a1.659 1.659 0 012.284 0l5.329 5.138c.594.573.629 1.48.105 2.092l-.105.11a1.657 1.657 0 01-2.284 0zm-6.851 9.546l-8.375-8.076a1.516 1.516 0 01-.105-2.092l.105-.11a1.657 1.657 0 012.284 0l8.375 8.075c.594.573.629 1.48.105 2.092l-.105.11a1.657 1.657 0 01-2.284 0z"
                    fill="#F7B27B"
                />
                <path
                    className="svg-sword-handle"
                    d="M36.438 17.804l-2.284-2.202 6.091-5.873 2.284 2.202z"
                />
                <path
                    className="svg-sword-handle-dark"
                    d="M34.153 15.602l-2.284-2.203 6.091-5.873 2.284 2.203z"
                />
            </g>
        </svg>
    )
}

export default ImgSwordRight
