import { qrService } from './base.service'


function list(params) {
    return qrService('/v1/app/classroom/training/list', params)
}

function listUpcoming(params) {
    return qrService('/v1/app/classroom/training/class/next/list', params)
}

function listPrevious(params) {
    return qrService('/v1/app/classroom/training/history/list', params)
}

function details(params) {
    return qrService('/v1/app/classroom/training/details', params)
}

function subscription(params) {
    return qrService('/v1/app/classroom/training/user/subscription', params)
}

function unsubscribe(params) {
    return qrService('/v1/app/classroom/training/user/unsubscribe', params)
}

function presence(params) {
    return qrService('/v1/app/classroom/training/user/class/presence', params)
}

function getTicket(params) {
    return qrService('/v1/app/classroom/training/user/class/details', params)
}

function certificate(params) {
    return qrService('/v1/app/classroom/training/certificate', params)
}


export const classroomService = {
    list,
    listUpcoming,
    listPrevious,
    details,
    subscription,
    unsubscribe,
    presence,
    getTicket,
    certificate
}
