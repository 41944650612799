import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import countries from 'i18n-iso-countries';
import config from '../../../config';
import * as yup from 'yup';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../../redux/ducks/auth';
import { getDateFormat } from '../../../utils/utils';
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import { Form, Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
    Button, TextField, Grid, IconButton, Typography,
    InputAdornment, MenuItem, Link
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DialogTermsPrivacy from './DialogTermsPrivacy';
import SnackBar from '../../../components/snackBar';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export default function FormSignupEmail(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [signupAction, setSignupAction] = useState(true);
    const [loginAction, setLoginAction] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [typeDialog, setTypeDialog] = useState("");
    const regex = config.login_method.password.complex ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%])[a-zA-Z\d]/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;
    let userLanguage = "pt_BR";
    let language = userLanguage.substr(0, 2);
    let haveEnterprise = config.login_method.email.signup.have_enterprise;
    let haveCountry = config.login_method.email.signup.have_country;
    let countryDefault = config.login_method.email.signup.country_default;
    let locale = require(`date-fns/locale/${language}`);

    const validations = yup.object().shape({
        name: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
        lastName: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
        email: yup.string().email(() => intl.get('ERROR_INVALID_EMAIL')).required(() => intl.get('ERROR_REQUIRED_FIELD')),
        enterprise: haveEnterprise ? yup.string().required(() => intl.get('ERROR_SELECT_ENTERPRISE')) : null,
        country: haveCountry ? yup.string().required(() => intl.get('ERROR_SELECT_COUNTRY')) : null,
        password: yup.string()
            .min(config.login_method.password.min, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .matches(regex, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .required(() => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`),
        confirm_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            .oneOf([yup.ref('password'), null], () => intl.get('ERROR_PASSWORD_NOT_MATCH'))
    });

    const countryList = Object.keys(countries.getNames(language)).map((country) => {
        return {
            name: countries.getName(country, language),
            code: countries.getAlpha2Code(countries.getName(country, language), language)
        }
    });

    // --- Google Captcha ---
    const sendData = async (data) => {
        setUserData({ ...userData, ...data });
        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            await dispatch(AuthActions.isLoading(true));
            await dispatch(AuthActions.signup({
                name: data.name,
                lname: data.lastName,
                email: data.email,
                birth_date: getDateFormat(data.birth_date),
                external_enterprise_id: data.enterprise ? data.enterprise : '',
                country: data.country ? data.country : config.login_method.email.signup.country_default,
                password: sha256(md5(data.password).toString(), "password").toString(),
                auth_password: data.password,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N",
            }));
            setLoading(true);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        if (config.captcha.type === 'invisible' && signupAction) {
            dispatch(AuthActions.isLoading(true));
            dispatch(AuthActions.signup({
                name: userData.name,
                lname: userData.lastName,
                email: userData.email,
                birth_date: getDateFormat(userData.birth_date),
                external_enterprise_id: userData.enterprise ? userData.enterprise : '',
                country: userData.country ? userData.country : config.login_method.email.signup.country_default,
                password: sha256(md5(userData.password).toString(), "password").toString(),
                auth_password: userData.password,
                auth_captcha: token,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N",
            }));
            setLoading(true);
            setSignupAction(false);
        }

        if (config.captcha.type === 'invisible' && loginAction) {
            userData.auth_captcha = token;
            setUserData({ ...userData, auth_captcha: token });
            dispatch(AuthActions.login({
                login: userData.email,
                auth_password: userData.password,
                auth_provider: 'QRANIO',
                auth_captcha: token,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
            }));
            setLoginAction(false);
        }
    };
    // --- end. Google Captcha ---

    useEffect(() => {
        // Caso seja necessário fazer a ativação
        if (loading && auth && auth.need_activation_code === 'Y' && auth.status === 'success') {
            auth.status = '';
            setLoading(false);
            setSnackOpen(true);
            setSnackMessage('É necessário informar o código de ativação');
            history.push({
                pathname: `${history.location.pathname.replace(/./g, '')}/access-code`,
                state: {
                    login: userData.email,
                    auth_password: userData.password,
                    redirect: "N"
                }
            })
        }

        // Caso não seja necessário fazer a ativação
        if (loading && auth && auth.need_activation_code !== 'Y' && auth.status === 'success') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
            auth.status = '';
            setLoginAction(true);
        }

        // Caso não seja necessário fazer a ativação e o login foi chamado com sucesso
        if (loading && auth && auth.authenticated && Object.keys(auth.user).length !== 0) {
            auth.status = '';
            setLoading(false);
            history.push('/');
        }

        // Caso não seja necessário fazer a ativação e o login foi chamado sem sucesso
        if (loading && auth.loginError && auth.loginError.length > 0) {
            delete userData.auth_captcha;
            setLoading(false);
            setSnackStatus(auth.status.length !== 0 ? auth.status : snackStatus)
            setSnackOpen(true);
            setSnackMessage(auth.loginError);

            if (auth.status.length !== 0 && auth.status !== 'success') {
                auth.loginError = "";
                auth.status = "";
            }
        }
    }, [auth, history, loading, snackStatus, userData]);

    function showTermsAndPrivacy(type) {
        setOpen(true);
        setTypeDialog(type);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Formik
            validationSchema={validations}
            initialValues={{
                name: '',
                lastName: '',
                email: '',
                birth_date: null,
                enterprise: '',
                country: haveCountry ? countryDefault : '',
                password: '',
                confirm_password: '',
            }}
            onSubmit={(data) => { sendData(data); }}
        >
            {
                ({ values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Grid container justify="center" spacing={2}>
                            <Grid item xs={12}>
                                <Typography component="p" variant="body2">
                                    {intl.get("INTRO_SIGNUP_TEXT")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    name="name"
                                    autoComplete="name"
                                    type="text"
                                    label={intl.get('LABEL_NAME')}
                                    fullWidth
                                    required
                                    variant={config.layout.input_variant}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    error={(errors.name && touched.name)}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    autoComplete="lastName"
                                    type="text"
                                    label={intl.get('LABEL_LASTNAME')}
                                    fullWidth
                                    required
                                    variant={config.layout.input_variant}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName}
                                    error={(errors.lastName && touched.lastName)}
                                    helperText={(errors.lastName && touched.lastName) && errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    type="email"
                                    label={intl.get('LABEL_EMAIL')}
                                    fullWidth
                                    required
                                    variant={config.layout.input_variant}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    error={(errors.email && touched.email)}
                                    helperText={(errors.email && touched.email) && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                    <KeyboardDatePicker
                                        id="birth_date"
                                        name="birth_date"
                                        margin="none"
                                        label={intl.get('LABEL_BIRTHDAY')}
                                        fullWidth
                                        required
                                        inputVariant={config.layout.input_variant}
                                        format="dd/MM/yyyy"
                                        onBlur={handleBlur}
                                        onChange={value => setFieldValue("birth_date", value)}
                                        value={values.birth_date}
                                        KeyboardButtonProps={{
                                            'aria-label': intl.get('LABEL_BIRTHDAY'),
                                        }}
                                        okLabel={intl.get('BTN_CONFIRM')}
                                        clearLabel={intl.get('BTN_CLEAR')}
                                        cancelLabel={intl.get('BTN_CANCEL')}
                                        error={(errors.birth_date && touched.birth_date)}
                                        helperText={(errors.birth_date && touched.birth_date) && errors.birth_date}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            {
                                haveEnterprise ?
                                    <Grid item xs={12}>
                                        <TextField
                                            id="enterprise"
                                            name="enterprise"
                                            label={intl.get('LABEL_ENTERPRISE')}
                                            select
                                            fullWidth
                                            required
                                            variant={config.layout.input_variant}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.enterprise}
                                            error={(errors.enterprise && touched.enterprise)}
                                            helperText={(errors.enterprise && touched.enterprise) && errors.enterprise}
                                        >
                                            {

                                                auth.enterprise && auth.enterprise.load === "finished" ?
                                                    auth.enterprise.data.map((item) => (
                                                        <MenuItem
                                                            value={item.enterprise_id}
                                                            key={item.enterprise_id}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    intl.get('LABEL_LOADING')
                                            }
                                        </TextField>
                                    </Grid>
                                    : null
                            }
                            {
                                haveCountry ?
                                    <Grid item xs={12}>
                                        <TextField
                                            id="country"
                                            name="country"
                                            label={intl.get('LABEL_COUNTRY')}
                                            select
                                            fullWidth
                                            required
                                            variant={config.layout.input_variant}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.country}
                                            error={(errors.country && touched.country)}
                                            helperText={(errors.country && touched.country) && errors.country}
                                        >
                                            {countryList.map((country) => (
                                                <MenuItem
                                                    value={country.code}
                                                    key={country.code}
                                                >
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={intl.get('LABEL_PASSWORD')}
                                    fullWidth
                                    required
                                    variant={config.layout.input_variant}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    value={values.password}
                                    error={(errors.password && touched.password)}
                                    helperText={(errors.password && touched.password) && errors.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="confirm_password"
                                    name="confirm_password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    label={intl.get('LABEL_RETYPE_PASSWORD')}
                                    fullWidth
                                    required
                                    variant={config.layout.input_variant}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showConfirmPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    value={values.confirm_password}
                                    error={(errors.confirm_password && touched.confirm_password)}
                                    helperText={(errors.confirm_password && touched.confirm_password) && errors.confirm_password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" component="div">
                                    {`${intl.get('INTRO_SIGNUP_TERMS_TEXT_1')} `}
                                    <Link onClick={() => showTermsAndPrivacy("terms")} className="cursor-pointer">{intl.get('INTRO_SIGNUP_TERMS_TEXT_2')}</Link>
                                    {` ${intl.get('INTRO_SIGNUP_TERMS_TEXT_3')} `}
                                    <Link onClick={() => showTermsAndPrivacy("privacy")} className="cursor-pointer">{intl.get('INTRO_SIGNUP_TERMS_TEXT_4')}</Link>
                                </Typography>
                            </Grid>

                            {/* Captcha */}
                            <Grid container justify="center">
                                {config.captcha.type === 'invisible'
                                    ? <div className="g-recaptcha"
                                        data-sitekey={config.captcha.invisible_key}
                                        data-callback="myCallback"
                                        data-size="invisible">
                                    </div>
                                    : <div className="g-recaptcha mt-3"
                                        data-sitekey={config.captcha.key}
                                        data-callback="myCallback"
                                    >
                                    </div>
                                }
                            </Grid>

                            {/* Button */}
                            <Grid item xs={12} sm={8}>
                                {
                                    !loading
                                        ?
                                        <Button
                                            id="btn_intro_signup_send"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className="btn-block"
                                            onSubmit={() => this.onSubmit()}
                                        >
                                            {intl.get('BTN_CONFIRM')}
                                        </Button>
                                        :
                                        <Grid container justify="center">
                                            <LoadingBounce />
                                        </Grid>
                                }
                            </Grid>
                        </Grid>
                        <DialogTermsPrivacy
                            open={open}
                            close={() => setOpen(false)}
                            type={typeDialog}
                        />
                        <SnackBar
                            open={snackOpen}
                            message={snackMessage}
                            status={snackStatus}
                            time={4}
                            closeSnack={() => setSnackOpen(false)}
                        />
                    </Form>
                )
            }
        </Formik>
    )
}
