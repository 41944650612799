import React from 'react';
import useReactRouter from 'use-react-router';
import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';

export default function CardNewCategoryFree(props) {
    const { history } = useReactRouter();
    const { image, bgColor, subheader, description, reference } = props;

    return (
        <Card className="card card-new-category-free">
            <CardActionArea
                disabled={reference && reference.link ? false : true}
                onClick={() => history.push({
                    pathname: `${history.location.pathname.replace(/./g, '')}/free/question`,
                    state: { idCategory: JSON.parse(reference).id, idLevel: 1, next: false, origin: history.location.pathname, type: 'first' }
                })}
            >
                <FeedCardHeader {...props} />
                <CardContent className="d-flex flex-row pt-0">
                    <div className="card-image-circle mr-3" style={{ backgroundColor: bgColor }}>
                        <img src={image} alt={subheader} />
                    </div>
                    <div>
                        <Typography gutterBottom variant="body1" component="h4">
                            {subheader}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <FeedCardFooter {...props} />
        </Card>
    )
}
