import { qrService } from './base.service'

function surveyPlay(params) {
    return qrService('/v3/app/courses/survey/play', params);
}

function surveyAnswer(params) {
    return qrService('/v3/app/courses/survey/answer', params);
}

function scormSurveyPlay(params) {
    return qrService('/v2/app/scorm/survey/play', params);
}

function scormSurveyAnswer(params) {
    return qrService('/v2/app/scorm/survey/answer', params);
}

function classroomSurveyPlay(params) {
    return qrService('/v1/app/classroom/training/survey/play', params);
}

function classroomSurveyAnswer(params) {
    return qrService('/v1/app/classroom/training/survey/answer', params);
}

export const SurveyService = {
    surveyPlay,
    surveyAnswer,
    scormSurveyPlay,
    scormSurveyAnswer,
    classroomSurveyPlay,
    classroomSurveyAnswer
}