import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#FDCB4F', // Yellow[200]
            main: '#FDC436', // Yellow[500]
            dark: '#FBB82F', // Orange[100]
            contrastText: '#fff',
        },
        secondary: {
            light: '#42A1E1', // Blue[200]
            main: '#2B97DD', // Blue[300]
            dark: '#2189CF', // Blue[400]
            contrastText: '#fff',
        },
        error: {
            light: '#E86C5D', // Red[200]
            main: '#E65748', // Red[300]
            dark: '#E24331', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
