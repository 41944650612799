import React from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import ReactHtmlParser from 'react-html-parser';
import {
    Grid, Container, Dialog, AppBar, Toolbar, IconButton,
    Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Icon } from '../../../../components/Images/Images';
import GameHeader from '../../../../components/Game/partials/GameHeader';
import QuizAnswer from '../feedback/QuizAnswer';
import TrueOrFalse from '../feedback/TrueOrFalse';
import SecretWord from '../feedback/SecretWord';
import OpenAnswer from '../feedback/OpenAnswer';
import GradeAnswer from '../feedback/GradeAnswer';
import SelectAnswers from '../feedback/SelectAnswers';
import RangeAnswer from '../feedback/RangeAnswer';
import VideoAnswer from '../feedback/VideoAnswer';
import ImageAnswer from '../feedback/ImageAnswer';
import QuizImageAnswer from '../feedback/QuizImageAnswer';

export default function DialogQuestionFeedback(props) {
    const { open, close, transition, exam, question, order } = props;

    const TypeFeedback = (data) => {
        switch (data.question_type) {
            case "T_OR_F":
                return <TrueOrFalse data={data} />
            case "FILL_WORD":
                return <SecretWord data={data} />
            case "OPEN_ANSWER":
                return <OpenAnswer data={data} />
            case "GRADE_ANSWER":
                return <GradeAnswer data={data} />
            case "SELECT_ANSWERS":
                return <SelectAnswers data={data} />
            case "RANGE_ANSWER":
                return <RangeAnswer data={data} />
            case "VIDEO_ANSWER":
                return <VideoAnswer data={data} />
            case "IMAGE_ANSWER":
                return <ImageAnswer data={data} />
            case "QUIZ_IMAGE_ANSWERS":
                return <QuizImageAnswer data={data} />
            default:
                return (
                    <QuizAnswer data={data} />
                )
        }
    }

    return (
        <Dialog id="dialog-question-feedback" fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={close} TransitionComponent={transition}>
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("LABEL_FEEDBACK")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {exam}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>

            <div className="qr-game-feedback">
                <GameHeader data={{ question: question }}>
                    {
                        config.learn.exam.score && typeof question.score !== "undefined" &&
                        <Grid container item xs={12} justify="center">
                            <div className="feedback-score">
                                <Typography variant="caption" component="p" noWrap>
                                    {intl.get("LABEL_SCORE")}
                                </Typography>
                                <Typography className={`feedback-score-text ${question.score >= 7 ? "__good" : question.score < 7 && question.score >= 5 ? "__regular" : "__poor"}`} variant="h4" component="div" noWrap>
                                    {question.score} <span>/ 10</span>
                                </Typography>
                            </div>
                        </Grid>
                    }
                    <Typography variant="body2" component="p" align="center">
                        {`${intl.get("LABEL_QUESTION")} ${order}`}
                    </Typography>
                </GameHeader>
                <Container maxWidth="md" className="pt-4 pb-4">
                    <Grid container justify="center">
                        {
                            question.feedback_review ?
                                <Grid item xs={12} sm={10} className="mb-3">
                                    <Alert icon={false} severity="info" className="rounded">
                                        {ReactHtmlParser(question.feedback_review)}
                                    </Alert>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12} sm={10}>
                            {TypeFeedback(question)}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Dialog>
    )
}