import * as React from "react";

function ImgHeaderDiploma(props) {
    return (
        <svg width={280} height={120} viewBox="0 0 280 120" className="img-header" {...props}>
            <defs>
                <path id="a" d="M0 0h40v40H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M214.755 85.894h-8.88c-2.923 0-5.29-1.614-5.29-3.607 0-1.992 2.367-3.606 5.29-3.606h1.09c3.013 0 5.825-1.3 6.605-3.284 1.18-2.999-2.15-5.733-6.372-5.733h-41V49.828h37.032c6.177 0 11.163-3.657 10.525-7.952-.555-3.742-5.507-6.474-11.025-6.474h-23.306c-2.923 0-5.29-1.614-5.29-3.607 0-1.992 2.367-3.606 5.29-3.606h14.17c3.46 0 6.61-1.697 6.957-4.043.396-2.7-2.701-4.973-6.579-4.973h-21.161c-2.19 0-3.968-1.212-3.968-2.705 0-1.494 1.778-2.705 3.968-2.705h10.202c3.46 0 6.61-1.697 6.957-4.043.397-2.7-2.7-4.974-6.579-4.974H96.48c-3.46 0-6.61 1.697-6.957 4.043-.397 2.7 2.7 4.974 6.579 4.974h9.258c2.923 0 5.29 1.614 5.29 3.606 0 1.993-2.367 3.607-5.29 3.607H71.472c-5.518 0-10.47 2.73-11.025 6.474-.638 4.295 4.348 7.952 10.525 7.952h6.613c5.113 0 9.258 2.826 9.258 6.312 0 3.485-4.145 6.311-9.258 6.311h-8.759c-5.517 0-10.47 2.73-11.025 6.474-.637 4.295 4.349 7.952 10.525 7.952h44.968v21.64H75.172c-3.013 0-5.824 1.3-6.605 3.283-1.18 3 2.15 5.733 6.372 5.733h3.968c2.923 0 5.29 1.614 5.29 3.607 0 1.992-2.367 3.606-5.29 3.606h-7.435c-5.518 0-10.47 2.73-11.025 6.474-.638 4.295 4.348 7.952 10.525 7.952h129.113c5.518 0 10.47-2.73 11.025-6.473.638-4.296-4.349-7.953-10.525-7.953h-1.323c-2.19 0-3.967-1.212-3.967-2.705s1.777-2.705 3.967-2.705h15.871c3.878 0 6.976-2.274 6.579-4.973-.344-2.346-3.497-4.043-6.957-4.043Z"
                    fill="#000"
                    opacity={0.1}
                />
                <g fillRule="nonzero">
                    <path
                        d="M177.746 19.746h-76c-2.43 0-6 3.26-6 5.6v60.4c0 2.34 3.57 6 6 6h76c2.431 0 6-3.66 6-6v-60.4c0-2.34-3.569-5.6-6-5.6Z"
                        fill="#2B97DD"
                    />
                    <path
                        d="M168.635 23.746c0 5.89 4.976 10.667 11.111 10.667V77.08c-6.135 0-11.11 4.776-11.11 10.666h-57.779c0-5.89-4.975-10.666-11.11-10.666V34.413c6.135 0 11.11-4.777 11.11-10.667Zm-7.989 24.889h-41.8a3.294 3.294 0 0 0-2.897 1.653 3.363 3.363 0 0 0 0 3.36 3.294 3.294 0 0 0 2.897 1.653h41.8a3.294 3.294 0 0 0 2.897-1.652c.6-1.039.6-2.323 0-3.361a3.294 3.294 0 0 0-2.897-1.653Zm4.4-13.333h-50.6a3.294 3.294 0 0 0-2.897 1.653 3.363 3.363 0 0 0 0 3.36 3.294 3.294 0 0 0 2.897 1.653h50.6a3.294 3.294 0 0 0 2.897-1.653c.6-1.038.6-2.322 0-3.36a3.294 3.294 0 0 0-2.897-1.653Z"
                        fill="#F5F5F5"
                    />
                    <path
                        d="m145.674 99.569-5.928-3.1-5.928 3.098c-.885.466-2.072-.034-2.072-.868V79.746h16V98.7c0 .834-1.186 1.334-2.072.87Z"
                        fill="#E65748"
                    />
                    <path
                        d="m154.887 74.225-1.12-.672a2.14 2.14 0 0 1-.943-1.292l-.01-.029c-.15-.54-.106-1.116.17-1.606l.633-1.141c.65-1.168-.183-2.604-1.516-2.627l-1.318-.022c-.56-.01-1.075-.259-1.468-.652a.025.025 0 0 0-.013-.013c-.397-.394-.643-.912-.653-1.468l-.022-1.318c-.022-1.334-1.459-2.163-2.626-1.516l-1.132.64a2.14 2.14 0 0 1-1.606.169l-.032-.01c-.538-.147-1.008-.467-1.292-.943l-.672-1.12a1.768 1.768 0 0 0-3.032 0l-.669 1.113c-.288.48-.764.806-1.302.953-.006.004-.01.004-.016.007-.547.15-1.126.105-1.618-.17l-1.136-.63c-1.167-.65-2.604.183-2.626 1.516l-.026 1.315c-.01.557-.259 1.075-.652 1.468-.006.003-.01.01-.013.013-.393.397-.911.643-1.468.652l-1.318.023c-1.334.022-2.162 1.458-1.516 2.626l.633 1.142a2.14 2.14 0 0 1 .17 1.606l-.01.028c-.147.538-.47 1.008-.943 1.293l-1.12.671a1.768 1.768 0 0 0 0 3.033l1.12.671c.476.288.8.758.943 1.293l.01.028c.15.541.105 1.117-.17 1.606l-.633 1.142c-.65 1.168.182 2.604 1.516 2.626l1.318.023c.56.01 1.075.259 1.468.652l.013.013c.397.393.643.911.652 1.468l.023 1.318c.022 1.334 1.458 2.162 2.626 1.516l1.142-.633a2.14 2.14 0 0 1 1.606-.17c.01.003.022.003.032.007.537.147 1.007.467 1.292.943l.672 1.12a1.768 1.768 0 0 0 3.032 0l.672-1.12a2.14 2.14 0 0 1 1.292-.943c.01-.004.022-.004.032-.007.54-.15 1.116-.105 1.606.17l1.141.633c1.168.65 2.604-.182 2.627-1.516l.022-1.318c.01-.56.259-1.075.652-1.468a.025.025 0 0 0 .013-.013c.394-.397.912-.643 1.468-.652l1.318-.023c1.334-.022 2.163-1.458 1.516-2.626l-.633-1.142a2.14 2.14 0 0 1-.17-1.606c.004-.01.004-.019.007-.028.147-.538.467-1.008.944-1.293l1.12-.671c1.138-.698 1.138-2.351-.007-3.04Z"
                        fill="#FDC436"
                    />
                </g>
                <g transform="translate(160 8)">
                    <circle fill="#FFF" cx={20.111} cy={20} r={18} />
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <path
                        d="M35.446 10.165 18.68 26.931a1.37 1.37 0 0 1-.972.402 1.37 1.37 0 0 1-.972-.402l-4.584-4.583a1.374 1.374 0 1 1 1.945-1.945l3.61 3.611 16.07-16.07C30.414 4.107 25.492 1.668 20 1.668 9.891 1.667 1.667 9.89 1.667 20 1.667 30.109 9.89 38.333 20 38.333c10.109 0 18.333-8.224 18.333-18.333 0-3.62-1.069-6.99-2.887-9.835Z"
                        fill="#46CA64"
                        mask="url(#b)"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgHeaderDiploma
