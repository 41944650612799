import React from 'react';
import useReactRouter from 'use-react-router';
import { CardMedia, Card, CardContent, Typography, CardActionArea } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';

export default function CardPeriodicQuestion(props) {
    const { history } = useReactRouter();
    const { image, description, reference } = props;

    return (
        <Card className="card card-periodic-question">
            <CardActionArea
                disabled={reference && reference.link ? false : true}
                onClick={() => history.push({
                    pathname: `${history.location.pathname.replace(/./g, '')}/periodic/question`,
                    state: { origin: history.location.pathname, }
                })}
            >
                <FeedCardHeader {...props} />
                {
                    image ?
                        <>
                            <CardMedia
                                className="card-image-full"
                                image={image}
                            />
                            <CardContent>
                                <Typography variant="body2" component="p">
                                    {description}
                                </Typography>
                            </CardContent>
                        </>
                        :
                        <CardContent className="pt-0">
                            <Typography variant="body2" component="p">
                                {description}
                            </Typography>
                        </CardContent>
                }
            </CardActionArea>
            <FeedCardFooter {...props} />
        </Card>
    )
}
