import { qrService } from './base.service'

function notificationList(params) {
    return qrService('/v3/app/notifications/list', params);
}

function notificationUpdate(params) {
    return qrService('/v1/app/notifications/update', params);
}

function notificationUpdateAll(params) {
    return qrService('/v1/app/notifications/update/all', params);
}

export const notificationModuleService = {
    notificationList,
    notificationUpdate,
    notificationUpdateAll,
}
