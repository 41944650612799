import * as React from "react";

function ImgHelpAnswered(props) {
    if (props.status === "disabled") {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path className="svg-help-disabled-2" d="M14.511 16.02l1.519-1.52 6.45 6.45-1.517 1.518z" />
                    <path
                        d="M9.585 1a8.585 8.585 0 100 17.17 8.585 8.585 0 000-17.17z"
                        className="svg-help-disabled-2"
                    />
                    <path
                        className="svg-help-disabled-3"
                        d="M16.269 17.817l1.518-1.518 4.667 4.667-1.518 1.518z"
                    />
                    <path
                        d="M9.585 2.61a6.976 6.976 0 100 13.951 6.976 6.976 0 000-13.951z"
                        className="svg-help-disabled-1"
                    />
                    <path
                        d="M2.878 9.585s2.468-2.414 6.707-2.414c4.24 0 6.708 2.414 6.708 2.414s-3.22 3.488-6.708 3.488c-3.487 0-6.707-3.488-6.707-3.488z"
                        className="svg-help-disabled-1"
                    />
                    <path
                        d="M2.878 9.585s2.468 5.098 6.707 5.098c4.24 0 6.708-5.098 6.708-5.098s-3.22 3.488-6.708 3.488c-3.487 0-6.707-3.488-6.707-3.488zm0 0s2.468-2.414 6.707-2.414c4.24 0 6.708 2.414 6.708 2.414s-2.04-4.56-6.708-4.56-6.707 4.56-6.707 4.56z"
                        className="svg-help-disabled-2"
                    />
                    <path
                        d="M7.17 7.439c-.482.59-.804 1.341-.804 2.146a3.23 3.23 0 003.22 3.22 3.23 3.23 0 003.219-3.22c0-.805-.322-1.556-.805-2.146a10.706 10.706 0 00-2.415-.268c-.912 0-1.663.107-2.414.268z"
                        className="svg-help-disabled-2"
                    />
                    <path
                        d="M9.585 8.244a1.341 1.341 0 100 2.683 1.341 1.341 0 000-2.683z"
                        className="svg-help-disabled-3"
                    />
                    <path
                        d="M10.659 7.976a.537.537 0 100 1.073.537.537 0 000-1.073z"
                        className="svg-help-disabled-1"
                    />
                </g>
            </svg>
        )
    } else {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path
                        fill="#707070"
                        d="M14.511 16.02l1.519-1.52 6.45 6.45-1.517 1.518z"
                    />
                    <path
                        d="M9.585 1a8.585 8.585 0 100 17.17 8.585 8.585 0 000-17.17z"
                        fill="#707070"
                    />
                    <path
                        fill="#3D3D3D"
                        d="M16.269 17.817l1.518-1.518 4.667 4.667-1.518 1.518z"
                    />
                    <path
                        d="M9.585 2.61a6.976 6.976 0 100 13.951 6.976 6.976 0 000-13.951z"
                        fill="#FFB74D"
                        fillRule="nonzero"
                    />
                    <path
                        d="M2.878 9.585s2.468-2.414 6.707-2.414c4.24 0 6.708 2.414 6.708 2.414s-3.22 3.488-6.708 3.488c-3.487 0-6.707-3.488-6.707-3.488z"
                        fill="#FAFAFA"
                        fillRule="nonzero"
                    />
                    <path
                        d="M2.878 9.585s2.468 5.098 6.707 5.098c4.24 0 6.708-5.098 6.708-5.098s-3.22 3.488-6.708 3.488c-3.487 0-6.707-3.488-6.707-3.488zm0 0s2.468-2.414 6.707-2.414c4.24 0 6.708 2.414 6.708 2.414s-2.04-4.56-6.708-4.56-6.707 4.56-6.707 4.56z"
                        fill="#FFA726"
                        fillRule="nonzero"
                    />
                    <path
                        d="M7.17 7.439c-.482.59-.804 1.341-.804 2.146a3.23 3.23 0 003.22 3.22 3.23 3.23 0 003.219-3.22c0-.805-.322-1.556-.805-2.146a10.706 10.706 0 00-2.415-.268c-.912 0-1.663.107-2.414.268z"
                        fill="#2AB69C"
                        fillRule="nonzero"
                    />
                    <path
                        d="M9.585 8.244a1.341 1.341 0 100 2.683 1.341 1.341 0 000-2.683z"
                        fill="#464646"
                        fillRule="nonzero"
                    />
                    <path
                        d="M10.659 7.976a.537.537 0 100 1.073.537.537 0 000-1.073z"
                        fill="#FFF"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        )
    }
}

export default ImgHelpAnswered
