import * as React from "react"

function ImgSunrise(props) {
    return (
        <svg width={360} height={360} viewBox="0 0 360 360" {...props}>
            <defs>
                <radialGradient
                    cx="50%"
                    cy="50%"
                    fx="50%"
                    fy="50%"
                    r="50.019%"
                    gradientTransform="rotate(88.431 .5 .5) scale(1 .9978)"
                    id="prefix__a"
                >
                    <stop stopOpacity={0.08} offset="0%" />
                    <stop stopOpacity={0} offset="100%" />
                </radialGradient>
            </defs>
            <path
                fill="url(#prefix__a)"
                d="M358.773 0L180.008 180.007 306.982 0H263.19l-83.18 180.003L228.91 0h-33.779l-15.12 180-15.12-180h-33.779l48.899 179.996L96.834 0H53.045L180.01 179.993 1.258 0H0v54.875L180.006 179.99 0 95.292v37.133l180.002 47.564L0 163.613v32.751l179.998-16.375L0 227.552v37.13l179.995-84.692L0 305.097V360h1.226l178.766-180.007L53.018 360H96.81l83.18-180.004L131.089 360h33.78l15.12-180 15.12 180h33.779l-48.899-179.997L263.166 360h43.79L179.99 180.007 358.742 360H360v-54.876L179.994 180.01 360 264.707v-37.132L179.998 180.01 360 196.387v-32.752L180.002 180.01 360 132.447v-37.13l-179.995 84.692L360 54.903V0z"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default ImgSunrise
