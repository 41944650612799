import * as React from "react"

function ExamGradeCircle(props) {
    return (
        <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
            <path
                d="M6.79 45.594c.467 1.19 1 2.358 1.39 3.576 3.004 9.443 8.673 16.364 17.434 20.32 21.31 9.617 46.834-7.559 46.664-31.382-.106-15.22-10.785-27.494-25.925-29.801-17.025-2.594-33.826 9.57-37.51 27.191-.32 1.526-.446 3.103-.58 4.664-.14 1.592-.442 3.197-2.32 3.034-1.832-.159-2.028-1.8-1.919-3.385.535-7.813 3.153-14.757 7.805-20.885C21.756 5.851 39.568.554 54.412 6.308c18.294 7.09 26.553 27.935 18.51 46.708-9.86 23.02-39.054 30.434-57.08 14.465-4.947-4.382-8.355-9.849-9.81-16.524-.352-1.62-.315-3.332-.457-5 .405-.122.81-.243 1.216-.363"
                className="svg-exam-grade"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default ExamGradeCircle
