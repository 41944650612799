import React, { useEffect, useState } from 'react';
import config from '../../config';
import useReactRouter from 'use-react-router'
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from './menu.list';
import Edit from './edit.profile';
import ChangePassword from './change.password';
import RedeemInfo from './redeem.info';
import RedeemAddress from './redeem.address';
import Contact from '../Faq/contact';
import HowToPlay from '../Faq/howtoplay';
import MyProfile from '../Faq/myprofile';

export default function Settings(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === (false || null)) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <Switch>
                    <Route path={`${props.match.path}`} exact component={
                        (props) => <Menu {...props} />}
                    />
                    <Route path={`${props.match.path}/edit-profile`} exact component={
                        (props) => <Edit {...props} />}
                    />
                    <Route path={`${props.match.path}/change-password`} exact component={
                        (props) => <ChangePassword {...props} />}
                    />
                    <Route path={`${props.match.path}/data-redeem/info`} exact component={
                        (props) => <RedeemInfo {...props} />}
                    />
                    <Route path={`${props.match.path}/data-redeem/address`} exact component={
                        (props) => <RedeemAddress {...props} />}
                    />
                    <Route path={`${props.match.path}/contact`} exact component={
                        (props) => <Contact {...props} />}
                    />
                    <Route path={`${props.match.path}/help/how-to-play`} exact component={
                        (props) => <HowToPlay {...props} />}
                    />
                    <Route path={`${props.match.path}/help/my-profile`} exact component={
                        (props) => <MyProfile {...props} />}
                    />
                </Switch>
            }
        </div>
    )
}
