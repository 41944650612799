import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { imageParser } from '../../utils/utils';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Grid, Hidden, Typography, Button, Avatar, TextField, InputAdornment, IconButton } from '@material-ui/core';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images'

export default function ExpiredPassword(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [auth_captcha, setAuth_captcha] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [setCaptcha] = useState(config.captcha.type === 'invisible' ? true : false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [redirectPage, setRedirectPage] = useState(false);
    const [passwordData, setPasswordData] = useState({});
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';
    const jwt = props.location.state && props.location.state.jwt ? props.location.state.jwt : '';
    const isTemporaryPass = auth.user.is_temporary_password === 'Y';
    const regex = config.login_method.password.complex ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%])[a-zA-Z\d]/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;

    const validations = yup.object().shape({
        auth_password: yup.string()
            .min(config.login_method.password.min, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .matches(regex, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .required(() => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`),
        confirm_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            .oneOf([yup.ref('auth_password'), null], () => intl.get('ERROR_PASSWORD_NOT_MATCH'))
    });

    // --- Google Captcha ---
    const sendData = async (data) => {
        setPasswordData({ ...passwordData, ...data });

        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            setLoading(true);
            await dispatch(AuthActions.updateExpiredPassword({
                password: sha256(md5(data.auth_password).toString(), "new_password").toString(),
                jwt: JSON.stringify(jwt),
                type: isTemporaryPass ? 'temporary' : 'expired',
                auth_captcha: auth_captcha
            }));
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        if (config.captcha.type === 'invisible') {
            setLoading(true);
            dispatch(AuthActions.updateExpiredPassword({
                password: sha256(md5(passwordData.auth_password).toString(), "new_password").toString(),
                jwt: JSON.stringify(jwt),
                type: isTemporaryPass ? 'temporary' : 'expired',
                auth_captcha: token,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
            }));
        } else {
            setAuth_captcha(token);
            setCaptcha(true);
        }
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (redirect === "N") {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }

        if (auth.status) {
            setLoading(false);

            if (auth.status === 'error') {
                setSnackMessage(auth.passwordSuccess);
                setSnackStatus(auth.status);
                setSnackOpen(true);
            } else {
                if (auth.status === 'expired success') {
                    history.replace('/home');
                }
            }

            auth.status = "";
        }
    }, [auth.passwordSuccess, auth.status, history, redirect]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        !redirectPage ?
            <IntroLayout title={intl.get(isTemporaryPass ? 'INTRO_TEMPORARY_PASSWORD_TITLE' : 'INTRO_EXPIRED_PASSWORD_TITLE')} appbarMobile="default">
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get(isTemporaryPass ? 'INTRO_TEMPORARY_PASSWORD_TITLE' : 'INTRO_EXPIRED_PASSWORD_TITLE')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Formik
                        validationSchema={validations}
                        initialValues={{
                            auth_password: '',
                            confirm_password: '',
                        }}
                        onSubmit={(data) => { sendData(data); }}
                    >
                        {
                            ({ values, handleBlur, handleChange, handleSubmit, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit} className="w-100">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} align="center">
                                            <Avatar alt={auth.user.fname} className="avatar-sm" src={auth.user.image ? imageParser(auth.user.image) : 'https://b.qranio.com/avatar'} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component="p" variant="body2">
                                                {intl.get(isTemporaryPass ? "INTRO_TEMPORARY_PASSWORD_TEXT" : "INTRO_EXPIRED_PASSWORD_TEXT")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="auth_password"
                                                name="auth_password"
                                                type={showPassword ? 'text' : 'password'}
                                                label={intl.get('LABEL_NEW_PASSWORD')}
                                                fullWidth
                                                required
                                                variant={config.layout.input_variant}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                                value={values.auth_password}
                                                error={(errors.auth_password && touched.auth_password)}
                                                helperText={(errors.auth_password && touched.auth_password) && errors.auth_password}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="confirm_password"
                                                name="confirm_password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                label={intl.get('LABEL_CONFIRM_PASSWORD')}
                                                fullWidth
                                                required
                                                variant={config.layout.input_variant}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowConfirmPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showConfirmPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                                value={values.confirm_password}
                                                error={(errors.confirm_password && touched.confirm_password)}
                                                helperText={(errors.confirm_password && touched.confirm_password) && errors.confirm_password}
                                            />
                                        </Grid>

                                        {/* Captcha */}
                                        <Grid container justify="center">
                                            {config.captcha.type === 'invisible'
                                                ? <div className="g-recaptcha"
                                                    data-sitekey={config.captcha.invisible_key}
                                                    data-callback="myCallback"
                                                    data-size="invisible">
                                                </div>
                                                : <div className="g-recaptcha mt-3"
                                                    data-sitekey={config.captcha.key}
                                                    data-callback="myCallback"
                                                >
                                                </div>
                                            }
                                        </Grid>

                                        {/* Button */}
                                        <Grid item xs={12} sm={8}>
                                            {
                                                !loading
                                                    ?
                                                    <Button
                                                        id="btn_intro_expired_password_send"
                                                        variant="contained"
                                                        className="btn-block"
                                                        type="submit"
                                                        color="primary"
                                                        onSubmit={() => this.onSubmit()}
                                                    >
                                                        {intl.get('BTN_SEND')}
                                                    </Button>
                                                    :
                                                    <Grid container alignItems="center" justify="center">
                                                        <LoadingBounce />
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}
