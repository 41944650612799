import React from 'react';
import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';
import { Icon } from './Images/Images';

export default function ButtonSurvey(props) {
    const { action, answered, unlock, textUnlock, textAnswered, textDisabled } = props;

    return (
        <Button variant="outlined" className="btn-card __subcategory" disabled={!unlock} onClick={action}>
            <div className="subcategory-content __image">
                <div className="d-flex align-items-center mr-3">
                    {
                        unlock ?
                            <Icon ic="rating" color="info" className="icon-lg" />
                            :
                            <Icon ic="rating" className="icon-lg" />
                    }
                </div>
                <div className="text-left mr-3">
                    <Typography variant="body1" component="h4">
                        {intl.get("BTN_SURVEY")}
                    </Typography>
                    <Typography variant="caption" component="p">
                        {
                            unlock ?
                                textUnlock
                                :
                                answered ?
                                    textAnswered
                                    :
                                    textDisabled
                        }
                    </Typography>
                </div>
            </div>
            {
                unlock ?
                    <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                    :
                    answered ?
                        <Icon ic="checked" />
                        :
                        <Icon ic="lock" />
            }
        </Button>
    )
}