import * as React from "react"

function Trail(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path fill="#FDCE5A" d="M21.167 7.586h1v11.021h-1z" />
        <path
          d="M21.625 21s.917-1.298.917-1.873-.41-1.04-.917-1.04c-.507 0-.917.465-.917 1.04S21.625 21 21.625 21z"
          fill="#FDC436"
        />
        <path
          d="M11.245 13.605L1.302 8.903c-.403-.19-.403-.84 0-1.03l9.943-4.701a1.748 1.748 0 011.51 0l9.943 4.702c.403.19.403.838 0 1.029l-9.942 4.701a1.75 1.75 0 01-1.511 0z"
          fill="#6E6E6E"
        />
        <path
          d="M12 8.202c-3.797 0-6.875.749-6.875 1.672v7.172c3.928 0 6.875 2.081 6.875 2.081s2.947-2.08 6.875-2.08V9.873c0-.923-3.078-1.672-6.875-1.672z"
          fill="#464646"
        />
      </g>
    </svg>
  )
}

export default Trail
