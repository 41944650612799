import { qrService } from './base.service'


function list(params) {
    return qrService('/v4/app/courses/list', params)
}

function themesList(params) {
    return qrService('/v4/app/courses/themes/list', params)
}

function details(params) {
    return qrService('/v4/app/courses/get', params)
}

function statistics(params) {
    return qrService('/v1/app/courses/statistics', params)
}

function stepsList(params) {
    return qrService('/v4/app/courses/stages/steps/list', params)
}

function materialsList(params) {
    return qrService('/v4/app/courses/stages/steps/files/list', params)
}

function stepStart(params) {
    return qrService('/v4/app/courses/stage/steps/start', params)
}

function stepCommit(params) {
    return qrService('/v4/app/courses/stage/steps/commit', params)
}

function stepFlashcardsQuestion(params) {
    return qrService('/v4/app/courses/stage/steps/flashcards/questions', params)
}

function ratingComments(params) {
    return qrService('/v4/app/courses/user/feedback/list', params)
}

function ratingSubmit(params) {
    return qrService('/v4/app/courses/user/feedback/submit', params)
}





function play(params) {
    // console.log('play', params)

    return qrService('/v3/app/courses/play', params)
}

function answer(params) {
    // console.log('answer', params)
    return qrService('/v3/app/courses/answer', params)
}

function validate(params) {
    // console.log('validate', params)

    return qrService('/v2/app/courses/validate', params)
}

function mockExam(params) {
    // console.log('mockExam', params)

    return qrService('/v3/app/courses/exam/play', params)
}

function mockExamAnswers(params) {
    // console.log('mockExamAnswers', params)

    return qrService('/v1/app/courses/exam/answer', params)
}

function mockHistory(params) {
    // console.log('mockHistory', params)

    return qrService('/v1/app/courses/exam/history', params)
}

function certificate(params) {
    return qrService('/v3/app/courses/certificate', params)
}

export const trailService = {
    list,
    themesList,
    details,
    statistics,
    stepsList,
    materialsList,
    stepStart,
    stepCommit,
    stepFlashcardsQuestion,
    ratingComments,
    ratingSubmit,
    play,
    answer,
    validate,
    mockExam,
    mockExamAnswers,
    mockHistory,
    certificate,
}