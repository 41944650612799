import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Grid, Typography, Button
} from '@material-ui/core';
import GameHeader from './partials/GameHeader';
import SnackBar from '../snackBar';
import { Icon } from '../Images/Images';

const SelectAnswers = ({ children, data, send, onlyView }) => {
    const help = useSelector(state => state.game.help);
    const [answerAlert, setAnswerAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackStatus, setSnackStatus] = useState("info");
    const [pressAnswer, setPressAnswer] = useState([]);

    useEffect(() => {
        if (answerAlert) {
            let timer = setTimeout(() => {
                setSnackOpen(true);
                setSnackMessage(intl.get("LABEL_ANSWER_CONFIRMATION_SELECT_ANSWERS"));
                setSnackStatus("info");
            }, 10000);

            return () => {
                setAnswerAlert(false);
                clearTimeout(timer);
            }
        }
    }, [answerAlert]);

    useEffect(() => {
        if (data.question.user_answer) {
            setPressAnswer(data.question.user_answer)
        }
    }, [data.question]);

    function pressButton(id) {
        setAnswerAlert(true);

        if (pressAnswer.includes(id)) {
            return setPressAnswer(pressAnswer.filter(item => item !== id));
        } else {
            return setPressAnswer([...pressAnswer, id]);
        }
    }

    return (
        <div className={`w-100${config.layout.points.neuros && data.help && !onlyView ? " __game-bar" : ""}`}>
            <GameHeader data={data}>
                {children}
            </GameHeader>
            <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10}>
                        <Grid container spacing={2} justify="center" className="answer-type-multi-answer">
                            {
                                data.question.answers.map((item, index) => {
                                    let history = help.answer_history && help.answer_history.find(help => help.answer === item.id);
                                    let eliminate = help.answers_to_eliminate && help.answers_to_eliminate.includes(item.id);

                                    return (
                                        <Grid key={index} item xs={12}>
                                            <Button
                                                variant="outlined"
                                                className={`btn-card __answer${pressAnswer.includes(item.id) ? " active" : ""} 
                                            ${onlyView ? item.answer_right === "Y" ? "answer-view-success" : "answer-view-danger" : ""}
                                            `}
                                                onClick={() => pressButton(item.id)}
                                                disabled={eliminate || onlyView}
                                            >
                                                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                    <div className="answer-type-text">
                                                        <div className="answer-option">
                                                            <Icon ic={pressAnswer.includes(item.id) ? "checkbox_checked" : "checkbox_unchecked"} />
                                                        </div>
                                                        <Typography variant="body2" component="p">
                                                            {ReactHtmlParser(item.answer)}
                                                        </Typography>
                                                    </div>
                                                    {
                                                        history ?
                                                            <Typography variant="h5" component="span" className="answer-help">
                                                                {history.answer_percent}
                                                            </Typography>
                                                            : null
                                                    }
                                                </div>
                                            </Button>
                                        </Grid>
                                    )
                                })
                            }
                            <Grid item xs={12} align="center">
                                <Button
                                    className={`btn-check btn-card __answer${pressAnswer.length > 0 ? ' active' : ''}`}
                                    disabled={pressAnswer.length === 0}
                                    variant="outlined"
                                    onClick={() => send(pressAnswer.join(','), data.question.id_question)}
                                >
                                    <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                                </Button>
                            </Grid>
                        </Grid>
                        <SnackBar
                            open={snackOpen}
                            message={snackMessage}
                            status={snackStatus}
                            time={3}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            closeSnack={() => setSnackOpen(false)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default SelectAnswers;