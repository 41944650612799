import React from 'react';

export default function Slide4() {
    return (
        <div className="intro-assai">
            <div className="intro-content">
                
            </div>
        </div>
    )
}
