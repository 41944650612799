import * as React from "react"

function ImgMobileHand(props) {
    return (
        <svg width={144} height={192} viewBox="0 0 144 192" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M39.56 65.783l-11.63-8.76c-3.626-2.732-8.853-.84-9.936 3.596a6.384 6.384 0 001.597 5.944c3.342 3.467 9.69 9.433 16 11.992l3.97-12.772zm1.583 22.94l-11.63-8.76c-3.626-2.731-8.854-.84-9.937 3.596a6.384 6.384 0 001.597 5.944c3.342 3.467 9.69 9.433 16 11.992l3.97-12.772zm-3.193 15.119l-10.805-8.1c-3.369-2.524-8.22-.782-9.222 3.311a5.878 5.878 0 001.489 5.49c3.106 3.204 9.005 8.718 14.865 11.087l3.674-11.788zm17.28 40.19c4.238 11.671 11.58 22.192 21.064 30.183 1.288 1.085 2.623 2.134 3.693 3.435 1.07 1.302 1.843 2.822 2.502 4.375a37.237 37.237 0 012.931 13.287l28.947 5.104-10.184-60.433-48.953 4.049z"
                    className="svg-hand"
                />
                <path
                    d="M91.57 165.727c1.512.899 3.439 1.006 5.078.359.694-.274 1.354-.688 1.804-1.291.448-.6.66-1.348.784-2.09.164-.981.183-2.02-.192-2.937-.701-1.712-2.62-2.609-4.445-2.762-1.327-.112-2.752.12-3.77.995-1.042.896-2.044 2.815-1.757 4.211a5.413 5.413 0 002.499 3.515"
                    className="svg-hand-shade"
                />
                <path
                    className="svg-hand-shade"
                    d="M111.094 114.78L93.732 99.354l-5.675 32.184 13.042 3.654z"
                />
                <g transform="rotate(10 2.282 251.493)">
                    <path
                        d="M11.336 154.254C5.076 154.254 0 149.16 0 142.876V11.378C0 5.094 5.075 0 11.336 0h55.641c6.261 0 11.336 5.094 11.336 11.378v131.498c0 6.284-5.075 11.378-11.336 11.378h-55.64z"
                        className="svg-smartphone"
                    />
                    <path
                        d="M61.41.165c6.4 0 11.452 4.315 11.452 10.599v131.498c0 6.283-5.19 11.377-11.59 11.377h5.813c6.401 0 11.59-5.094 11.59-11.377V10.764c0-6.284-3.431-10.762-9.832-10.762l-7.434.163z"
                        fillOpacity={0.2}
                        fill="#000"
                    />
                    <rect
                        className="svg-smartphone-screen"
                        x={4.529}
                        y={5.201}
                        width={63.284}
                        height={142.388}
                        rx={5}
                    />
                    <rect
                        className="svg-smartphone"
                        x={18.208}
                        y={139.183}
                        width={34.806}
                        height={1.582}
                        rx={0.791}
                    />
                </g>
                <path
                    d="M137.652 204.392L127.11 180.86a53.357 53.357 0 01-3.849-31.078l3.144-17.825a41.985 41.985 0 00-.455-16.806l-3.81-16.37-6.377-16.159c-1.717-4.35-7.81-4.527-9.761-.273-.55 1.197-.942 2.441-1.058 3.672-1.033 10.974 3.283 12.583 2.734 16.308-.548 3.725-1.526 11.127-2.348 11.489-.823.362-10.162 12.639-12.453 25.627-2.29 12.989 2.682 31.219 2.682 31.219l4.33 27.069 37.764 6.659zM17.585 112.767a9.85 9.85 0 00-.523 2.374c-.176 1.756.06 3.588.9 5.088.841 1.5 2.34 2.618 3.994 2.706 1.343.072 2.69-.534 3.7-1.504 1.01-.97 1.7-2.279 2.097-3.655.618-2.138.537-4.529-.439-6.473-.86-1.715-2.998-3.765-5.007-3.34-2.067.437-4.007 2.831-4.722 4.804"
                    className="svg-hand"
                />
                <path
                    d="M152.687 215.516l-21.955-39.214s-31.868-2.747-51.174 16.626l4.293 10.45 68.836 12.138z"
                    className="svg-hand-shirt"
                />
            </g>
        </svg>
    )
}

export default ImgMobileHand
