import { qrService } from './base.service'


function avatarList(params) {

    return qrService('/v3/app/profile/avatar/list', params)
}

function photoUpdate(params) {
    return qrService('/v3/app/profile/photo/update', params)
}

function avatarUpdate(params) {
    return qrService('/v3/app/profile/photo/avatar/update', params)
}

export const avatarService = {
    avatarList,
    photoUpdate,
    avatarUpdate,
}