import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { useSelector } from 'react-redux';
import { gameService } from '../../services/game.service';
import Resizer from 'react-image-file-resizer';
import md5 from 'crypto-js/md5';
import * as moment from 'moment';
import { generateNonce, b64toBlob, imageParser } from '../../utils/utils';
import {
    Container, Grid, Button, LinearProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import GameHeader from './partials/GameHeader';
import SnackBar from '../snackBar';
import { Icon } from '../Images/Images';
import QrImage from '../QrImage';

const ImageAnswer = ({ children, data, send, onlyView }) => {
    const user = useSelector(state => state.auth.user);
    const [imagePreview, setImagePreview] = useState(data.question.user_answer_media ? imageParser(data.question.user_answer_media) : null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(data.question.user_answer ? data.question.user_answer : "");
    const [blobFileName, setBlobFileName] = useState("");
    const [loadingFile, setLoadingFile] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const getFile = async (e) => {
        const selectedFile = e.target.files?.[0];
        if (!selectedFile) return;

        const generatedBlobName = md5(
            `${user.id_user}${moment().format('hh:MM:ss')}${generateNonce(10)}`
        ).toString() + `.${selectedFile.name.split('.').pop()}`;
        setBlobFileName(generatedBlobName);
        setFileName(generatedBlobName);

        Resizer.imageFileResizer(
            selectedFile,
            1280,
            1280,
            'JPEG',
            100,
            0,
            uri => { setFile(uri); setImagePreview(uri); },
            'base64'
        );
    };

    const sendFileUpload = async () => {
        if (!file) return;

        setLoadingFile(true);
        try {
            const blob = b64toBlob(file, fileName.type);

            const response = await gameService.sendFileUpload({
                type: "photo",
                file: blob,
                file_name: blobFileName,
            });

            if (response.sent === 'ok') {
                send(blobFileName, data.question.id_question);
                resetFileState();
            }
        } catch (error) {
            setSnackOpen(true);
        } finally {
            setLoadingFile(false);
        }
    };

    const resetFileState = () => {
        setFile(null);
        setFileName("");
        setBlobFileName("");
    };

    return (
        <div className={`w-100${config.layout.points.neuros && data.help && !onlyView ? " __game-bar" : ""}`}>
            <GameHeader data={data}>
                {children}
            </GameHeader>
            <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10}>
                        {imagePreview &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} align="center">
                                    <QrImage
                                        src={imagePreview}
                                        effect="blur"
                                        className="voucher-image embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                        alt={data.question.user_answer}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {!loadingFile ? (
                            <Grid container spacing={2} className="answer-type-upload-answer">
                                <Grid item xs={12}>
                                    <div className="position-relative">
                                        <input
                                            id="upload-button-file"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            multiple
                                            onChange={getFile}
                                        />
                                        <label htmlFor="upload-button-file">
                                            <Button
                                                variant="outlined"
                                                className="btn-card __answer"
                                                component="span"
                                            >
                                                <Icon ic="upload_outline" className="icon-xs mr-2" />
                                                {intl.get("LABEL_UPLOAD_IMAGE")}
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert
                                        severity={fileName ? "success" : "warning"}
                                        className="rounded"
                                        icon={fileName ? <Icon ic="image" className="icon-xs" /> : false}
                                    >
                                        {fileName ? fileName : intl.get("EMPTY_UPLOAD_MEDIA")}
                                    </Alert>
                                </Grid>
                                <Grid item xs={12} align="center" className="mb-3">
                                    <Button
                                        variant="outlined"
                                        className={`btn-check btn-card __answer${file ? ' active' : ''}`}
                                        disabled={!file}
                                        onClick={sendFileUpload}
                                    >
                                        <Icon ic="check" />
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <LinearProgress />
                                </Grid>
                            </Grid>
                        )}
                        <SnackBar
                            open={snackOpen}
                            message={intl.get("ERROR_UPLOAD_MEDIA")}
                            status="error"
                            time={3}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            closeSnack={() => setSnackOpen(false)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default ImageAnswer;