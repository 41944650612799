import { PrizesService } from "../../services/prizes.service";

// CONST
export const Types = {
    LIST: 'prizes/LIST',
    LOADING: 'prizes/LOADING',
    PRIZE_DETAILS: 'prizes/PRIZE_DETAILS',
    PRIZE_REDEEM: 'prizes/PRIZE_REDEEM',
    REDEEMED_LIST: 'prizes/REDEEMED_LIST',
    REDEEMED_DETAILS: 'prizes/REDEEMED_DETAILS',
    BOOKMARK: 'prizes/BOOKMARK',
    BOOKMARK_LIST: 'prizes/BOOKMARK_LIST',
    RESET_PRIZE_REDEEM: 'prizes/RESET_PRIZE_REDEEM',
    RESET_BOOKMARK: 'prizes/RESET_BOOKMARK',
    RESET: 'prizes/RESET',
};

// INITIAL STATE
const initialState = {
    list: {},
    loading: false,
    details: {},
    redeem: {},
    redeemedList: {},
    redeemedDetails: {},
    bookmark: {},
    bookmarkList: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                },
                loading: false
            }
        case Types.LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            }
        case Types.PRIZE_DETAILS:
            return {
                ...state,
                details: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.PRIZE_REDEEM:
            return {
                ...state,
                redeem: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                    redeemed: action.payload.redeemed,
                }
            }
        case Types.REDEEMED_LIST:
            return {
                ...state,
                redeemedList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                },
                loading: false
            }
        case Types.REDEEMED_DETAILS:
            return {
                ...state,
                redeemedDetails: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BOOKMARK:
            return {
                ...state,
                bookmark: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BOOKMARK_LIST:
            return {
                ...state,
                bookmarkList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET_PRIZE_REDEEM:
            return {
                ...state,
                redeem: {},
            }
        case Types.RESET_BOOKMARK:
            return {
                ...state,
                bookmark: {},
            }
        case Types.RESET:
            return {
                ...state,
                list: {},
                details: {},
                redeem: {},
                redeemedList: {},
                redeemedDetails: {},
                bookmark: {},
                bookmarkList: {},
                loading: false
            }
        default:
            return state
    }
}

// ACTIONS
function getList(params) {
    return dispatch => {
        return PrizesService.getList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error'
                }
            });
        })
    }
}

function setLoading(params) {
    return dispatch => {
        dispatch({
            type: Types.LOADING,
            payload: {
                loading: params.loading,
            }
        });
    }
}

function getPrizeDetails(params) {
    return dispatch => {
        return PrizesService.getPrizeDetails(params).then(data => {
            dispatch({
                type: Types.PRIZE_DETAILS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PRIZE_DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error'
                }
            });
        })
    }
}

function setRedeem(params) {
    return dispatch => {
        return PrizesService.setRedeem(params).then(data => {
            dispatch({
                type: Types.PRIZE_REDEEM,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: '',
                    redeemed: true
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PRIZE_REDEEM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error',
                    redeemed: false
                }
            });
        })
    }
}

function getRedeemedList(params) {
    return dispatch => {
        return PrizesService.getRedeemedList(params).then(data => {
            dispatch({
                type: Types.REDEEMED_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REDEEMED_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error'
                }
            });
        })
    }
}

function getRedeemedDetails(params) {
    return dispatch => {
        return PrizesService.getRedeemedDetails(params).then(data => {
            dispatch({
                type: Types.REDEEMED_DETAILS,
                payload: {
                    data: data.redeem,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REDEEMED_DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error'
                }
            });
        })
    }
}

function setBookmark(params) {
    return dispatch => {
        return PrizesService.setBookmark(params).then(data => {
            dispatch({
                type: Types.BOOKMARK,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BOOKMARK,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error'
                }
            });
        })
    }
}

function getBookmarkList(params) {
    return dispatch => {
        return PrizesService.getBookmarkList(params).then(data => {
            dispatch({
                type: Types.BOOKMARK_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BOOKMARK_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Undefined error'
                }
            });
        })
    }
}

function resetPrizeRedeem() {
    return dispatch => {
        dispatch({
            type: Types.RESET_PRIZE_REDEEM
        });
    }
}

function resetBookmark() {
    return dispatch => {
        dispatch({
            type: Types.RESET_BOOKMARK
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getList,
    setLoading,
    getPrizeDetails,
    setRedeem,
    getRedeemedList,
    getRedeemedDetails,
    setBookmark,
    getBookmarkList,
    resetPrizeRedeem,
    resetBookmark,
    reset,
}
