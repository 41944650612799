import React from 'react';
import useReactRouter from 'use-react-router';
import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';

export default function CardNewScorm(props) {
    const { history } = useReactRouter();
    const { subheader, description, image, reference } = props;

    return (
        <Card className="card card-new-scorm">
            <CardActionArea
                onClick={() => history.push({
                    pathname: `${history.location.pathname.replace(/./g, '')}/scorm/${JSON.parse(reference).id}/details`,
                    state: { id_course: JSON.parse(reference).id, origin: history.location.pathname, }
                })}
            >
                <FeedCardHeader {...props} />
                <CardContent className="d-flex flex-row pt-0">
                    <div className="card-image-rectangle mr-3">
                        <img src={image} alt={subheader} />
                    </div>
                    <div>
                        <Typography gutterBottom variant="body1" component="h4">
                            {subheader}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <FeedCardFooter {...props} />
        </Card>
    )
}
