import React from 'react';
import { imageParser } from '../../../utils/utils';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@material-ui/core'
import { Icon } from '../../../components/Images/Images';

export default function FileItem(props) {
    const { action, image, bgColor, title, description } = props;

    return (
        <ListItem button onClick={action} className="file-category-item">
            <div className="file-category-item-image">
                <ListItemAvatar>
                    <Avatar className="__rectangle" aria-label={title} style={{ backgroundColor: bgColor }}>
                        <img src={imageParser(image)} alt={title} />
                    </Avatar>
                </ListItemAvatar>
            </div>
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="body1" component="h5">
                        {title}
                    </Typography>
                }
                secondary={
                    <div>
                        <Typography variant="caption" component="p">
                            {description}
                        </Typography>
                    </div>
                }
            />
            <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
        </ListItem>
    )
}
