import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Icon } from '../components/Images/Icons'

const variantIcon = {
  success: "checked",
  warning: "important",
  error: "error",
  info: "info",
};

function MySnackbarContentWrapper(props) {
  const { className, message, onClose, variant, ...other } = props;
  const iconName = variantIcon[variant];

  return (
    <SnackbarContent
      className={`qr-snackbar snackbar-${variant}`}
      aria-describedby="client-snackbar"
      message={
        <div className="d-flex flex-row align-items-center">
          <Icon ic={iconName} className="icon-xs mr-3" />
          {message}
        </div>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <Icon ic="multiply" className="icon-xs" />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default function CustomizedSnackbars(props) {
  const [open, setOpen] = React.useState(props.open);
  if (props.open && !open) {
    setOpen(true);
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.closeSnack(false);
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={props.time * 1000}
      onClose={handleClose}
      anchorOrigin={props.anchorOrigin ? props.anchorOrigin : { vertical: 'bottom', horizontal: 'left' }}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={props.status}
        message={props.message}
      />
    </Snackbar>
  );
}
