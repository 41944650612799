import * as React from 'react';
import config from '../../../config';
import AssaiCharCourseFlashcardsFinishedStars1 from '../projects/assai/chars/CharCourseFlashcardsFinishedStars1';

const project = config.app.name_project.toLowerCase()
let theme = require(`../../../assets/scss/projects/${project}/_export.scss`)

function CharCourseFlashcardsFinishedStars1(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                <defs>
                    <path
                        id="prefix__b"
                        d="M.238 6.635l5.306 17.433 25.411-3.32L29.09.249"
                    />
                    <path
                        id="prefix__d"
                        d="M.238 6.635l5.306 17.433 25.411-3.32L29.09.249"
                    />
                    <path
                        id="prefix__f"
                        d="M.116 6.818l5.416 17.4 25.39-3.482L28.926.249"
                    />
                    <path
                        id="prefix__h"
                        d="M.116 6.818l5.416 17.4 25.39-3.482L28.926.249"
                    />
                    <path
                        d="M87.28 47.048c2.262 2.543 10.312 14.768 2.13 15.905-7.775 1.081-3.105-17-2.13-15.905"
                        id="prefix__k"
                    />
                    <path id="prefix__l" d="M0 0h180v63H0z" />
                    <path id="prefix__n" d="M0 0h31.5v31.5H0z" />
                    <path id="prefix__p" d="M0 0h40.5v40.5H0z" />
                    <path id="prefix__r" d="M0 0h31.5v31.5H0z" />
                    <filter
                        x="-10.7%"
                        y="-8.1%"
                        width="121.4%"
                        height="116.2%"
                        filterUnits="objectBoundingBox"
                        id="prefix__a"
                    >
                        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                        />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter
                        x="-12.5%"
                        y="-6.3%"
                        width="150%"
                        height="125%"
                        filterUnits="objectBoundingBox"
                        id="prefix__j"
                    >
                        <feOffset
                            dx={1}
                            dy={1}
                            in="SourceAlpha"
                            result="shadowOffsetOuter1"
                        />
                        <feGaussianBlur
                            stdDeviation={0.5}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <circle fill="#F38631" cx={90} cy={90} r={81} />
                    <path
                        d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                        fill="#000"
                        opacity={0.05}
                    />
                    <path
                        d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                        fill="#000"
                        opacity={0.15}
                    />
                    <path
                        d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                        fill="#FFF"
                        opacity={0.2}
                    />
                    <g filter="url(#prefix__a)" transform="translate(29 13)">
                        <path
                            d="M30.04 69.159l67.448.289c11.867 23.786.262 59.909-1.703 61.977l-3.463 8.84c-1.53 4.96-6.79 7.742-11.75 6.213-4.96-1.53-7.74-6.79-6.211-11.75l1.118-3.626-17.427-.058h-.847c2.93 5.58.663 13.377-4.47 14.14-5.134.766-13.15.64-13.915-4.492l-1.785-9.648-6.995-61.08"
                            fill="#46CA64"
                        />
                        <path
                            d="M109.465 87.524c.079 2.995.017 7.662 0 10.751v12.132c-.178 7.114-1.156 14.653-6.707 14.514l-4.009.576c-4.626.396-9.703 2.632-7.747-6.865-9.127 4.45-9.627-9.956-1.613-8.114l1.847-13.098"
                            fill="#000"
                            opacity={0.1}
                        />
                        <path
                            d="M97.15 69.92c14.282 5.189 12.749 16.813 12.792 24.289l.312 13.563c.633 7.089-2.209 15.795-7.74 16.29l-.585.039c-8.343 1.372-10.397 2.184-9.537-7.475-8.56 5.462-9.647-6.258-1.475-5.34l.471-20.497"
                            fill="#FDC436"
                        />
                        <path
                            d="M38.082 103.449l2.143 13.82c7.35-1.345 7.628 13.084-1.126 7.94 1.214 9.62-.869 8.885-9.257 7.818l-.585-.017c-5.546-.292-8.704-8.889-8.331-15.995l-.185-13.566 11.01.16"
                            fill="#000"
                            opacity={0.1}
                        />
                        <path
                            d="M31.078 77.367c-14.082 5.71-12.124 17.27-11.893 24.742l.185 13.565c-.373 7.107 2.785 15.704 8.33 15.996l.586.017c8.388 1.066 10.471 1.802 9.257-7.819 8.754 5.145 9.412-6.606 1.279-5.391L37.6 98.012l-6.523-20.645z"
                            fill="#FDC436"
                        />
                        <path
                            d="M30.867 76.782s-5.847 3.161-8.73 7.203c-4.824 6.76-4.824 21.26-4.824 21.26l22.933-1.456-9.379-27.007zM96.624 69.722s7.298 2.04 10.645 7.322c2.71 4.278 4.013 20.224 4.013 20.224l-20.276 2.275-2.458-13.4 8.076-16.42z"
                            fill="#46CA64"
                        />
                        <path
                            d="M37.214 91.899c0 .47.818 11.82.818 11.82l.797-5.549-1.615-6.271zM88.001 87.524c-.001.471 2.977 11.871 2.977 11.871l-2.559-4.084-.418-7.787z"
                            fill="#000"
                            opacity={0.1}
                        />
                        <path
                            d="M88.582 41.089s2.908 25.751 2.957 25.863c.349 4.253-2.289 14.292-13.573 17.413-13.477 3.726-43.518 8.62-43.518 8.62s-13.126.047-15.59-13.734c-.008.24-3.108-26.316-3.108-26.316l72.832-11.846"
                            fill="#000"
                            opacity={0.1}
                        />
                        <path
                            d="M93.917 37.176s3.451 25.752 3.509 25.863c.415 4.253-1.488 16.19-15.204 18.05-15.158 2.058-51.462 6.983-51.462 6.983s-16.657 1.047-19.58-12.734c-.011.241-3.69-26.316-3.69-26.316l86.427-11.846"
                            fill="#FDC436"
                        />
                        <path
                            d="M51.18 81.564c2.26-.698 3.603-2.843 3.001-4.791-.602-1.948-2.921-2.962-5.18-2.264-2.26.698-3.602 2.843-3 4.791.601 1.948 2.92 2.962 5.18 2.264M30.596 4.864c-4.043 1.034-2.355.473-5.122 1.498-14.826 6.105-20.587 25.952-18.362 40.88l.73 4.903c-.146.126 86.615-12.952 86.568-13.098l-.776-5.203C91.41 18.916 80.126 1.707 64.19.367c-4.59-.386-9.79.518-13.796 2.89C48.22 4.542 46.6 6.217 45.55 8.18c-1.578-1.557-3.611-2.666-6.06-3.236-3.308-.8-5.604-.444-8.894-.081"
                            fill="#212321"
                        />
                        <path
                            d="M12.024 46.482l35.893-5.559-3.629-24.346c-.6-4.02-2.843-5.982-5.904-6.697-3.513-.819-7.73-.186-11.048 1.178-2.738 1.128-5.1 2.849-7.098 4.986 1.543 1.932 3.873 2.39 6.23 1.456 1.384-.549 2.625-1.18 4.092-1.533 4.794-1.144 9.854 1.458 11.815 6.061.882 1.987.075 3.803-1.1 4.277-1.29.522-2.752.133-3.462-1.838-1.707-4.618-6.007-3.41-9.466-2.04-4.075 1.618-8.36.814-11.317-2.134a33.063 33.063 0 00-2.507 5.047c1.286.634 2.473 1.578 3.495 2.83.961 1.179 1.72 2.545 2.703 3.68 1.876 2.175 4.508 2.41 6.906.976 1.281-.766 2.41-1.59 3.803-2.173 4.557-1.907 9.957-.156 12.609 4.072 1.181 1.818.667 3.743-.419 4.401-1.193.723-2.699.577-3.704-1.255-2.405-4.283-6.463-2.396-9.664-.483-4.536 2.712-9.917 2.02-13.427-2.042-1.042-1.208-1.803-2.578-2.796-3.794a5.678 5.678 0 00-1.14-1.079c-1.336 5.414-1.597 11.098-.865 16.009M88.722 34.605L52.83 40.163 49.2 15.817c-.599-4.02.974-6.574 3.689-8.184 3.113-1.844 7.325-2.517 10.891-2.219 2.943.25 5.7 1.176 8.232 2.612-.912 2.312-3.003 3.456-5.525 3.277-1.482-.105-2.851-.333-4.355-.225-4.911.36-8.985 4.375-9.516 9.365-.264 2.164 1.036 3.653 2.295 3.75 1.384.107 2.668-.706 2.77-2.803.287-4.926 4.746-5.075 8.446-4.813 4.363.31 8.22-1.754 10.185-5.464a32.49 32.49 0 013.864 4.06c-1.042.995-1.901 2.255-2.513 3.76-.575 1.417-.9 2.951-1.507 4.332-1.162 2.646-3.603 3.668-6.311 3.024-1.448-.344-2.765-.79-4.266-.924-4.905-.442-9.552 2.865-10.852 7.705-.6 2.093.453 3.776 1.681 4.076 1.35.329 2.745-.266 3.173-2.32 1.05-4.818 5.473-4.245 9.086-3.387 5.121 1.216 10.058-1.073 12.225-6.014.645-1.47.972-3.008 1.566-4.47.213-.526.473-.985.774-1.375 2.854 4.765 4.758 10.114 5.49 15.025"
                            fill="#FFF"
                        />
                        <g transform="translate(17.243 51.897)">
                            <mask id="prefix__c" fill="#fff">
                                <use xlinkHref="#prefix__b" />
                            </mask>
                            <path
                                d="M16.126 21.236c6.662-.208 11.903-5.515 11.704-11.853C27.63 3.046 22.069-1.92 15.406-1.712 8.744-1.503 3.504 3.804 3.703 10.14c.198 6.337 5.76 11.306 12.423 11.096"
                                fill="#212321"
                                mask="url(#prefix__c)"
                            />
                        </g>
                        <g transform="translate(17.243 51.897)">
                            <mask id="prefix__e" fill="#fff">
                                <use xlinkHref="#prefix__d" />
                            </mask>
                            <path
                                d="M10.087 13.167c3.25-.103 5.805-2.691 5.708-5.781-.097-3.09-2.81-5.513-6.058-5.412-3.25.102-5.805 2.691-5.708 5.781.097 3.09 2.809 5.513 6.058 5.412"
                                fill="#FFF"
                                mask="url(#prefix__e)"
                            />
                        </g>
                        <g>
                            <g transform="translate(49.568 47.119)">
                                <mask id="prefix__g" fill="#fff">
                                    <use xlinkHref="#prefix__f" />
                                </mask>
                                <path
                                    d="M16.095 21.319c6.661-.252 11.868-5.592 11.629-11.928C27.485 3.055 21.89-1.876 15.23-1.625 8.57-1.373 3.363 3.967 3.602 10.301c.24 6.336 5.833 11.27 12.493 11.018"
                                    fill="#212321"
                                    mask="url(#prefix__g)"
                                />
                            </g>
                            <g transform="translate(49.568 47.119)">
                                <mask id="prefix__i" fill="#fff">
                                    <use xlinkHref="#prefix__h" />
                                </mask>
                                <path
                                    d="M9.987 13.269c3.249-.123 5.787-2.728 5.67-5.817-.116-3.09-2.844-5.495-6.093-5.374-3.248.124-5.787 2.728-5.67 5.818.117 3.088 2.844 5.495 6.093 5.373"
                                    fill="#FFF"
                                    mask="url(#prefix__i)"
                                />
                            </g>
                        </g>
                        <g>
                            <use fill="#000" filter="url(#prefix__j)" xlinkHref="#prefix__k" />
                            <use fill="#F5F5F5" xlinkHref="#prefix__k" />
                        </g>
                        <path
                            d="M15.251 81.56a.624.624 0 01-1.166.438 4.1 4.1 0 00-7.879 2.117l.13.768-.778-.042a4.102 4.102 0 00-4.05 5.533 4.111 4.111 0 003.89 2.659l.458-.006.142.436a5.712 5.712 0 007.43 3.575 5.712 5.712 0 003.336-7.347.623.623 0 011.167-.438 6.959 6.959 0 01-4.065 8.95 6.96 6.96 0 01-8.902-3.938 5.362 5.362 0 01-4.623-3.453 5.35 5.35 0 014.565-7.205 5.351 5.351 0 013.464-5.17 5.35 5.35 0 016.881 3.124zm10.647 3.443l.437 1.166-6.408 2.406-.438-1.167 6.409-2.405zm-1.47-4.207l.24 1.222-6.807 1.342-.241-1.222 6.808-1.342z"
                            fill="#FFF"
                        />
                    </g>
                    <g>
                        <g transform="translate(0 117)">
                            <mask id="prefix__m" fill="#fff">
                                <use xlinkHref="#prefix__l" />
                            </mask>
                            <g mask="url(#prefix__m)">
                                <path
                                    d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                    fill="#BD483B"
                                />
                                <path
                                    d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                    fill="#93382E"
                                />
                                <path
                                    d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                    fill="#E65748"
                                />
                            </g>
                        </g>
                        <g transform="rotate(-12 659.94 -89.693)">
                            <mask id="prefix__o" fill="#fff">
                                <use xlinkHref="#prefix__n" />
                            </mask>
                            <g mask="url(#prefix__o)" fill="#FDC436">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                        <g transform="translate(69.755 123.75)">
                            <mask id="prefix__q" fill="#fff">
                                <use xlinkHref="#prefix__p" />
                            </mask>
                            <g mask="url(#prefix__q)" fill="#BD483B">
                                <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                            </g>
                        </g>
                        <g transform="rotate(12 -554.159 616.797)">
                            <mask id="prefix__s" fill="#fff">
                                <use xlinkHref="#prefix__r" />
                            </mask>
                            <g mask="url(#prefix__s)" fill="#BD483B">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCourseFlashcardsFinishedStars1 medal={theme["bronze"]} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (config.learn.trail.chars && config.learn.trail.type === "stars") {
            if (gender === "male") {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__b" d="M0 0h180v63H0z" />
                            <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                            <filter
                                x="-11.5%"
                                y="-8.8%"
                                width="123.1%"
                                height="117.5%"
                                filterUnits="objectBoundingBox"
                                id="prefix__a"
                            >
                                <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur
                                    stdDeviation={2}
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                />
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle fill={theme["bronze"]} cx={90} cy={90} r={81} />
                            <path
                                d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                                fill="#000"
                                opacity={0.05}
                            />
                            <path
                                d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                fill="#000"
                                opacity={0.15}
                            />
                            <path
                                d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                                fill="#FFF"
                                opacity={0.2}
                            />
                            <g filter="url(#prefix__a)" transform="translate(38 8)">
                                <path
                                    d="M66.992 88.968s11.739 5.843 12.905 29.508l-7.57.84s-.616-15.594-5.636-22.638l.3-7.71zM41.02 88.859s-11.658 6.005-12.498 29.684l7.581.736s.4-15.601 5.324-22.715l-.408-7.705z"
                                    className="svg-char-shirt-shade-1"
                                />
                                <path
                                    d="M39.74 90.019s-2.641 13.706-3.981 27.695c0 0 4.769 2.09 17.685 2.09 7.043 0 15.083-.854 19.042-2.09 0 0-.741-11.89-3.891-27.638 0 0-4.732-4.594-13.331 1.472-8.952 0-15.525-1.53-15.525-1.53"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M55.662 97.494c1.536 3.049 4.252 10.029 4.465 12.101.085.827-4.208 4.992-5.94 5.28-1.503.252-5.85-3.771-6.125-5.028-.144-.664 2.843-9.557 3.98-12.289.713-1.71 2.778-1.736 3.62-.064zm-1.93-7.89a2.699 2.699 0 012.688 2.64 2.584 2.584 0 01-2.593 2.641 2.699 2.699 0 01-2.688-2.64 2.584 2.584 0 012.593-2.64z"
                                    className="svg-char-necktie"
                                />
                                <path
                                    d="M34.345 49.922c.969 9.194-4.193 13.964-11.522 14.736-7.33.772-13.41-12.301-14.321-18.318-1.483-9.793 3.147-17.337 10.478-18.11 7.329-.772 14.396 12.498 15.365 21.692"
                                    className={`svg-char-${color}-hair-shade-2`}
                                />
                                <path
                                    d="M88.99 73.454c-2.78 5.717-8.752 7.413-12.368 6.443-3.6-.97-2.032-4.92-.7-9.951 1.328-5.032 5.332-8.326 8.942-7.356 3.608.97 6.42 6.144 4.126 10.864"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M85.337 72.832c-.834 2.832-4.866 4.588-7.106 4.205-2.243-.382-1.519-2.684-1.008-5.59.508-2.908 2.74-4.951 4.982-4.568 2.24.384 4.106 2.648 3.132 5.953"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M11.542 69.322c1.657 4.298 5.82 7.05 8.64 6.582 2.81-.472 2.038-3.934 1.557-8.282-.48-4.348-3.153-7.492-5.97-7.02-2.815.47-5.787 4.684-4.227 8.72"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M14.248 68.242c.084 2.489 3.295 5.217 5.02 5.11 1.727-.109 1.42-2.108 1.336-4.595-.081-2.487-1.55-4.414-3.277-4.305-1.726.109-3.164 1.306-3.08 3.79"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M38.863 91.91s-27.825-6.967-22.739-44.403C21.21 10.07 45.926 9.529 58.526 11.557c12.6 2.026 25.028 10.263 26.008 21.515 3.394 38.956-10.286 51.84-18.155 56.265-4.31 2.423-16.208 5.958-27.516 2.573"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M47.527 83.574c.326 2.191-1.396 3.216-3.658 3.552-2.263.336-4.208-.144-4.534-2.335-.325-2.192 1.539-4.023 3.8-4.359 2.263-.336 4.067.95 4.392 3.142"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M47.889 66.522a3.592 3.592 0 11-7.145.755 3.592 3.592 0 017.145-.755"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M83.477 62.363s-13.752-10.54-8.342-27.455c0 0-3.863-1.825-19.501 3.144-15.394 4.892-38.978 9.925-51.363-.36-9.314-7.736-1.765-17.794 7.112-13.172 0 0-4.855-.578-4.618-8.666C7.027 6.888 18.97 3.453 26.259 9.55c0 0-4.353-2.744-1.59-6.807C27.785-1.835 35.43.41 38.148 6.553c0 0 27.199-5.59 42.98 9.745 11.762 11.429 12.506 27.668 2.35 46.065"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M49.259 37.529c-11.06 3.063-27.014 4.258-37.27 2.07C3.687 37.828.805 31.917.007 29.73c-.097 2.484 1.124 5.355 4.264 7.963 12.385 10.285 35.969 5.252 51.363.36 15.638-4.969 19.5-3.144 19.5-3.144-6.477-3.564-20.158 1.037-25.875 2.62M82.237 48.543c-6.012.852-8.168-3.062-8.168-3.062s.145.998.427 2.582c2.125 9.044 8.98 14.3 8.98 14.3 4.011-7.265 6.314-14.191 6.918-20.62-1.336 2.798-3.883 6.194-8.157 6.8"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M18.827 9.249c.29.92-.945 2.13-2.761 2.703-1.815.576-3.523.295-3.814-.624-.29-.92.945-2.13 2.761-2.705 1.816-.573 3.522-.293 3.814.626"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                                <path
                                    d="M31.222 55.98l-4.772.847a9.32 9.32 0 00-.11 2.895c.43 3.708 2.853 6.474 5.41 6.177 2.558-.297 4.282-3.545 3.85-7.253a9.152 9.152 0 00-1.002-3.265l-.243.043"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M26.874 57.268c.153 1.318 1.015 2.303 1.925 2.197.91-.106 1.522-1.26 1.37-2.58a3.416 3.416 0 00-.178-.758l-3.134.556c-.01.19-.006.385.017.585"
                                    fill="#FFF"
                                />
                                <path
                                    d="M60.527 52.38l-4.454.79a9.254 9.254 0 00-.156 3.12c.43 3.708 2.853 6.473 5.41 6.176 2.558-.297 4.282-3.544 3.85-7.252-.15-1.302-.551-2.484-1.113-3.462l-.398.071"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M56.42 53.607c.154 1.32 1.016 2.303 1.925 2.198.91-.106 1.524-1.26 1.37-2.58a3.394 3.394 0 00-.173-.746l-3.139.557c-.009.185-.005.376.018.571M72.134 53.578s-2.523 5.954-2.461 8.06c.024.826.644 2.263 2.813 2.263 2.285 0 2.845-1.437 2.812-2.263-.086-2.163-3.164-8.06-3.164-8.06M24.437 83.676a.603.603 0 01-1.13.424 3.967 3.967 0 00-7.625 2.048l.127.743-.753-.04a3.97 3.97 0 00-3.92 5.354 3.978 3.978 0 003.765 2.573l.444-.006.137.422a5.528 5.528 0 007.19 3.46 5.527 5.527 0 003.229-7.11.603.603 0 011.129-.424 6.734 6.734 0 01-3.934 8.662 6.735 6.735 0 01-8.614-3.811 5.189 5.189 0 01-4.475-3.342 5.177 5.177 0 014.418-6.973 5.178 5.178 0 013.352-5.003 5.177 5.177 0 016.66 3.023zm10.303 3.331l.423 1.129-6.202 2.327-.423-1.128 6.202-2.328zm-1.423-4.071l.233 1.182-6.588 1.299-.233-1.183 6.588-1.298z"
                                    fill="#FFF"
                                />
                            </g>
                            <g>
                                <g transform="translate(0 117)">
                                    <mask id="prefix__c" fill="#fff">
                                        <use xlinkHref="#prefix__b" />
                                    </mask>
                                    <g mask="url(#prefix__c)">
                                        <path
                                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                            className="svg-ribbon-stars-back"
                                        />
                                        <path
                                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                            className="svg-ribbon-stars-shadow"
                                        />
                                        <path
                                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                            className="svg-ribbon-stars-front"
                                        />
                                    </g>
                                </g>
                                <g transform="rotate(-12 659.94 -89.693)">
                                    <mask id="prefix__e" fill="#fff">
                                        <use xlinkHref="#prefix__d" />
                                    </mask>
                                    <g mask="url(#prefix__e)" className="svg-stage-star">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                                <g transform="translate(69.755 123.75)">
                                    <mask id="prefix__g" fill="#fff">
                                        <use xlinkHref="#prefix__f" />
                                    </mask>
                                    <g mask="url(#prefix__g)" className="svg-ribbon-stars-back">
                                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                    </g>
                                </g>
                                <g transform="rotate(12 -554.159 616.797)">
                                    <mask id="prefix__i" fill="#fff">
                                        <use xlinkHref="#prefix__h" />
                                    </mask>
                                    <g mask="url(#prefix__i)" className="svg-ribbon-stars-back">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            } else {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__a" d="M0 180h180V0H0z" />
                            <path id="prefix__d" d="M0 0h180v63H0z" />
                            <path id="prefix__f" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__h" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__j" d="M0 0h31.5v31.5H0z" />
                            <filter
                                x="-10.9%"
                                y="-8.4%"
                                width="121.9%"
                                height="117.6%"
                                filterUnits="objectBoundingBox"
                                id="prefix__c"
                            >
                                <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur
                                    stdDeviation={2}
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                />
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <mask id="prefix__b" fill="#fff">
                                <use xlinkHref="#prefix__a" />
                            </mask>
                            <g mask="url(#prefix__b)">
                                <circle fill={theme["bronze"]} cx={90} cy={90} r={81} />
                                <path
                                    d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                                    fill="#000"
                                    opacity={0.05}
                                />
                                <path
                                    d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                    fill="#000"
                                    opacity={0.15}
                                />
                                <path
                                    d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                                    fill="#FFF"
                                    opacity={0.2}
                                />
                                <g filter="url(#prefix__c)" transform="translate(42 3)">
                                    <path
                                        d="M37.453 95.94s-.401 12.13-3.74 26.326c0 0 3.821 2.06 14.26 2.071 5.693.007 12.204-.824 15.423-2.037 0 0-1.495-3.964-2.325-8.144-.85-4.275.582-10.108-.55-18.19 0 0-3.4.588-10.634.58-7.234-.009-12.434-.606-12.434-.606"
                                        className="svg-char-shirt"
                                    />
                                    <path
                                        d="M56.301 92s11.74 5.843 12.906 29.508l-7.57.84S61.02 106.754 56 99.71l.301-7.71zm-16.804 2S27.84 100.005 27 123.684l7.581.736s.401-15.601 5.324-22.714L39.497 94z"
                                        className="svg-char-shirt-shade-1"
                                    />
                                    <path
                                        d="M64.403 122.516s-1.664-5.538-3.124-8.569c-.187-.388.848-9.817-.605-17.967 0 0-2.731.524-8.984.617-1.12 4.824-2.747 11.698-3.983 11.903-1.254.208-3.128-4.704-4.374-11.913-2.987-.167-5.222-1.26-5.222-1.26s-2.721 4.593-3.35 6.89c-.499 1.82.664 6.244.619 7.104-.213 3.97-.633 5.222-1.47 7.52-.65 1.779-2.377 3.238-2.649 5.136 0 0 5.47 3.012 16.862 3.025 6.211.007 11.473-.193 16.28-2.486"
                                        className="svg-char-suit"
                                    />
                                    <path
                                        d="M86.05 17.887c1.184 9.735-5.981 18.615-16.003 19.834-10.021 1.219-19.106-5.685-20.29-15.42-1.184-9.736 5.98-18.616 16.002-19.835 10.022-1.219 19.106 5.685 20.29 15.42"
                                        className={`svg-char-${color}-hair`}
                                    />
                                    <path
                                        d="M81.675 34.106c-3.53 8.289-14.738 11.454-25.034 7.07-10.297-4.385-15.782-14.659-12.252-22.948 3.529-8.29 14.737-11.454 25.034-7.07 10.296 4.385 15.782 14.658 12.252 22.948"
                                        className="svg-char-ribbon"
                                    />
                                    <path
                                        d="M86.05 17.887A17.312 17.312 0 0081.914 8.6c.526 2.102 1.394 7.412-1.936 11.288 0 0 .925 2.413-2.035 4.035l4.39 6.966c2.84-3.574 4.301-8.182 3.715-13.002"
                                        className={`svg-char-${color}-hair-shade-1`}
                                    />
                                    <path
                                        d="M14.407 74.249S6.164 63.572 6.185 45.576C6.205 27.58 19.72 14.075 39.22 10.938c10.945-1.76 21.72.411 30.068 5.526 10.074 6.172 16.11 16.602 15.667 29.633-.645 18.931-7.238 29.606-11.215 33.246-8.197 7.505-40.686 21.048-59.334-5.094"
                                        className={`svg-char-${color}-hair`}
                                    />
                                    <path
                                        d="M83.496 78.985c-2.591 5.552-8.321 7.279-11.82 6.4-3.485-.88-2.034-4.71-.83-9.58 1.203-4.87 5.012-8.106 8.505-7.226 3.493.88 6.284 5.822 4.145 10.406"
                                        className={`svg-char-${color}-skin-shade-1`}
                                    />
                                    <path
                                        d="M79.966 78.442c-.76 2.741-4.62 4.496-6.784 4.162-2.167-.334-1.505-2.563-1.057-5.373.444-2.81 2.564-4.813 4.73-4.478 2.165.336 3.999 2.489 3.111 5.689"
                                        className={`svg-char-${color}-skin-shade-2`}
                                    />
                                    <path
                                        d="M8.91 76.526c1.663 4.117 5.717 6.705 8.429 6.21 2.7-.498 1.903-3.823 1.373-8.005-.53-4.183-3.155-7.172-5.863-6.675-2.706.497-5.505 4.605-3.94 8.47"
                                        className={`svg-char-${color}-skin-shade-1`}
                                    />
                                    <path
                                        d="M11.501 75.444c.119 2.397 3.256 4.977 4.917 4.846 1.663-.13 1.336-2.053 1.216-4.449-.116-2.395-1.562-4.229-3.224-4.098-1.661.132-3.029 1.308-2.909 3.7"
                                        className={`svg-char-${color}-skin-shade-2`}
                                    />
                                    <path
                                        d="M35.59 97.873S8.664 91.59 12.988 55.431c4.324-36.159 28.135-37.061 40.31-35.303 12.174 1.759 24.278 9.505 25.397 20.334 3.874 37.492-9.112 50.12-16.628 54.507-4.116 2.401-15.528 5.992-26.479 2.904"
                                        className={`svg-char-${color}-skin`}
                                    />
                                    <path
                                        d="M79.408 35.092c4.985 20.228-1.19 33.36-1.19 33.36s-6.878-2.95-8.915-16.098C47.587 53.738 34.43 42.208 29.816 37.241c-2.165 4.292-7.89 13.617-19.33 20.35 0 0-4.403-13.765 3.924-20.028 1.123-5.02 4.822-11.918 15.84-17.327 16.561-8.13 44.172-5.372 49.158 14.856z"
                                        className={`svg-char-${color}-hair`}
                                    />
                                    <path
                                        d="M80.138 69.429c2.166-4.683 4.274-12.226 4.732-21.46-.106-.326-.17-.508-.17-.508s-.253 9.816-14.053 10.548c0 0 2.474 8.88 9.491 11.42"
                                        className={`svg-char-${color}-hair-shade-1`}
                                    />
                                    <path
                                        d="M29.74 36.064c.041.053 4.297 5.376 12.86 9.842 7.9 4.12 21.194 8.335 39.653 5.01.247-.054.5.124.545.38a.47.47 0 01-.379.545c-1.496.27-2.96.49-4.39.666-16.48 2.023-28.514-1.925-35.908-5.79-8.726-4.562-12.945-9.849-13.12-10.071a.47.47 0 11.738-.582zm-5.105-.607a.47.47 0 01.28.602c-.042.118-4.434 11.898-15.5 21.01a.47.47 0 01-.597-.726c10.87-8.95 15.172-20.49 15.215-20.606a.468.468 0 01.602-.28zm-3.672-6.129a.47.47 0 01.37.552c-.023.123-2.513 12.445-12.013 23.18a.468.468 0 01-.663.04.47.47 0 01-.04-.663c9.332-10.545 11.77-22.617 11.794-22.738a.469.469 0 01.552-.37zm4.385-.652c.042.046 4.317 4.608 13.455 8.36 8.44 3.465 22.874 6.93 43.703 3.747a.478.478 0 01.536.394.47.47 0 01-.394.536c-1.153.175-2.287.331-3.4.468-19.261 2.365-32.773-.968-40.862-4.3-9.308-3.834-13.554-8.377-13.73-8.569a.47.47 0 11.692-.636zm47.997-7.413a.47.47 0 01-.283.897c-24.437-7.719-46.035 2.227-46.25 2.329a.47.47 0 11-.4-.85c.219-.103 22.151-10.204 46.933-2.376zm-50.443-.686c.658.773-.048 2.454-1.577 3.753-1.528 1.3-3.301 1.725-3.96.952-.656-.775.049-2.456 1.578-3.755 1.53-1.3 3.301-1.724 3.959-.95z"
                                        fillOpacity={0.1}
                                        fill="#FFF"
                                    />
                                    <path
                                        d="M43.252 89.821c.326 2.192-1.396 3.216-3.659 3.553-2.262.336-4.208-.144-4.533-2.335-.326-2.192 1.539-4.023 3.8-4.36 2.263-.335 4.067.95 4.392 3.142"
                                        className="svg-char-mouth"
                                    />
                                    <path
                                        d="M43.614 72.77a3.592 3.592 0 11-7.145.754 3.592 3.592 0 017.145-.754"
                                        className={`svg-char-${color}-skin-shade-1`}
                                    />
                                    <path
                                        d="M26.947 62.228l-4.772.846a9.32 9.32 0 00-.11 2.895c.43 3.708 2.853 6.474 5.41 6.177 2.558-.297 4.281-3.544 3.85-7.252a9.152 9.152 0 00-1.002-3.265l-.243.043"
                                        className={`svg-char-${color}-eyes`}
                                    />
                                    <path
                                        d="M22.599 63.515c.153 1.319 1.015 2.303 1.924 2.198.91-.106 1.523-1.261 1.37-2.58a3.416 3.416 0 00-.178-.758l-3.133.556c-.01.189-.006.384.017.584"
                                        fill="#FFF"
                                    />
                                    <path
                                        d="M56.252 58.627l-4.454.79a9.254 9.254 0 00-.157 3.12c.431 3.709 2.854 6.474 5.41 6.177 2.558-.297 4.282-3.544 3.851-7.252A9.045 9.045 0 0059.789 58l-.399.07"
                                        className={`svg-char-${color}-eyes`}
                                    />
                                    <path
                                        d="M52.145 59.855c.154 1.32 1.016 2.303 1.925 2.197.91-.106 1.523-1.26 1.37-2.58a3.394 3.394 0 00-.174-.746l-3.138.557c-.009.185-.005.376.017.572m15.713-.029s-2.523 5.954-2.46 8.06c.024.826.644 2.263 2.812 2.263 2.286 0 2.846-1.437 2.813-2.263-.086-2.163-3.165-8.06-3.165-8.06m-47.421 28.85a.603.603 0 01-1.13.424 3.967 3.967 0 00-7.625 2.048l.127.743-.753-.04a3.97 3.97 0 00-3.92 5.354 3.978 3.978 0 003.765 2.573l.444-.006.137.422a5.528 5.528 0 007.19 3.46 5.527 5.527 0 003.229-7.11.603.603 0 011.129-.424 6.734 6.734 0 01-3.934 8.662 6.735 6.735 0 01-8.614-3.811 5.189 5.189 0 01-4.475-3.342 5.177 5.177 0 014.418-6.973 5.178 5.178 0 013.352-5.003 5.177 5.177 0 016.66 3.023zm10.303 3.331l.423 1.129-6.202 2.327-.423-1.128 6.202-2.328zm-1.423-4.071l.233 1.182-6.588 1.299-.233-1.183 6.588-1.298z"
                                        fill="#FFF"
                                    />
                                </g>
                                <g transform="translate(0 117)">
                                    <mask id="prefix__e" fill="#fff">
                                        <use xlinkHref="#prefix__d" />
                                    </mask>
                                    <g mask="url(#prefix__e)">
                                        <path
                                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                            className="svg-ribbon-stars-back"
                                        />
                                        <path
                                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                            className="svg-ribbon-stars-shadow"
                                        />
                                        <path
                                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                            className="svg-ribbon-stars-front"
                                        />
                                    </g>
                                </g>
                                <g transform="rotate(-12 659.94 -89.693)">
                                    <mask id="prefix__g" fill="#fff">
                                        <use xlinkHref="#prefix__f" />
                                    </mask>
                                    <g mask="url(#prefix__g)" className="svg-stage-star">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                                <g transform="translate(69.755 123.75)">
                                    <mask id="prefix__i" fill="#fff">
                                        <use xlinkHref="#prefix__h" />
                                    </mask>
                                    <g mask="url(#prefix__i)" className="svg-ribbon-stars-back">
                                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                    </g>
                                </g>
                                <g transform="rotate(12 -554.159 616.797)">
                                    <mask id="prefix__k" fill="#fff">
                                        <use xlinkHref="#prefix__j" />
                                    </mask>
                                    <g mask="url(#prefix__k)" className="svg-ribbon-stars-back">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            }
        } else {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <g fill="none" fillRule="evenodd" transform="translate(9 9)">
                        <circle cx={81} cy={81} r={81} fill="#F38631" />
                        <path
                            fill="#000"
                            d="M25.436 22.063C12.594 63.282 18.062 97.178 41.84 123.75c25.015 27.954 60.482 28.762 106.402 2.422C133.698 147.784 109.009 162 81 162c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148Z"
                            opacity={0.05}
                        />
                        <path
                            fill="#000"
                            d="M81 150.75c38.522 0 69.75-31.228 69.75-69.75S119.522 11.25 81 11.25 11.25 42.478 11.25 81 42.478 150.75 81 150.75ZM81 162c-44.735 0-81-36.265-81-81S36.265 0 81 0s81 36.265 81 81-36.265 81-81 81Z"
                            opacity={0.15}
                        />
                        <path
                            fill="#FFF"
                            d="M117.055 28.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066Z"
                            opacity={0.2}
                        />
                    </g>
                </svg>
            )
        }
    }
}

export default CharCourseFlashcardsFinishedStars1
