import React from 'react';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';

export default function DaysOfWeek(props) {
    const Days = () => {
        let daysOfWeek = [];

        for (let i = 0; i <= 6; i++) {
            daysOfWeek[i] = moment().day(i).format('ddd');
        }

        return (
            <Grid container justify="center">
                {
                    daysOfWeek.map((item, index) => {
                        return (
                            <Grid key={index} item xs align="center">
                                <Typography variant="body2" component="h6" className="text-uppercase">
                                    {item}
                                </Typography>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    return (
        <Days />
    )
}
