import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import {
    Grid, Typography, Divider, CardActionArea,
    Card, CardHeader, CardContent
} from '@material-ui/core';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function CardTrailChart(props) {
    const { isLoggedUser, name } = props;
    const { history } = useReactRouter();
    const trailStats = useSelector(state => state.profile.trailStats);

    return (
        <Card className="profile-card __trail">
            <CardHeader
                title={
                    <Typography variant="h3" component="h2" align="center">
                        {intl.get("PROFILE_CARD_TRAIL_TITLE")}
                    </Typography>
                }
            />
            <div className="profile-card-content">
                {
                    trailStats && trailStats.load === "finished" ?
                        !trailStats.error ?
                            <>
                                <CardContent className="w-100">
                                    <Grid container direction="column" alignItems="center" justify="space-between" className="chart-wrap">
                                        <Grid item xs={12} className="chart-content">
                                            <Grid container direction="column" alignItems="center" justify="center">
                                                <Icon ic="star_filled" className="star icon-xxl" />
                                                <Typography variant="body2" component="p" className="fs-md mt-3">
                                                    {intl.get("PROFILE_CARD_TRAIL_TOTAL_STARS")}
                                                </Typography>
                                                <Typography variant="h2" component="h4">
                                                    {trailStats.data.total_stars ? trailStats.data.total_stars : 0}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="chart-footer">
                                            <Grid container direction="row" alignItems="center" justify="center">
                                                <Grid item xs={6} className="chart-data-item">
                                                    <div className="chart-data-item-icon left">
                                                        <Icon ic="play_trail" className="icon-sm" />
                                                    </div>
                                                    <div className="chart-data-item-text">
                                                        <Typography variant="body2" component="p">
                                                            {intl.get("PROFILE_CARD_TRAIL_TITLE")}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {trailStats.data.total_courses_available ? trailStats.data.total_courses_available : 0}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} className="chart-data-item">
                                                    <div className="chart-data-item-icon right">
                                                        <Icon ic="diploma" className="icon-sm" />
                                                    </div>
                                                    <div className="chart-data-item-text">
                                                        <Typography variant="body2" component="p">
                                                            {intl.get("PROFILE_CARD_TRAIL_CERTIFICATE")}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {trailStats.data.total_courses_finished ? trailStats.data.total_courses_finished : 0}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {
                                    isLoggedUser
                                        ?
                                        <Grid container direction="column">
                                            <Divider />
                                            <CardActionArea
                                                disabled={parseInt(trailStats.data.total_courses_available) === 0}
                                                className="card-action-button"
                                                onClick={() => history.push({
                                                    pathname: `${history.location.pathname + '/trail'}`,
                                                    state: {
                                                        nameUser: name
                                                    }
                                                })}
                                            >
                                                <Typography variant="body1" component="p">
                                                    {intl.get("BTN_SEE_LIST")}
                                                </Typography>
                                            </CardActionArea>
                                        </Grid>
                                        : null
                                }
                            </>
                            :
                            <Grid container alignItems="center" justify="center" className="chart-wrap">
                                <ErrorContent type="section" padding={true} />
                            </Grid>
                        :
                        <Grid container alignItems="center" justify="center" className="chart-wrap">
                            <LoadingBounce />
                        </Grid>
                }
            </div>
        </Card>
    )
}
