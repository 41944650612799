import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';

export default function EmptyContent(props) {
    const { type, icon, text, padding, button, buttonText, buttonAction, buttonDisabled, buttonDisabledText } = props;

    return (
        <Grid
            container
            className={`empty-content${type === "section" ? " section" : ""}${padding ? " content-padding" : ""}`}
            justify="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} className="empty-icon">
                        {icon}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="p">
                            {text}
                        </Typography>
                    </Grid>
                    {
                        button ?
                            <Grid item xs={12} sm={8} md={6} lg={4}>
                                <Button
                                    id="btn_empty_content"
                                    variant="contained"
                                    className="btn-block"
                                    onClick={buttonAction}
                                >
                                    {buttonText}
                                </Button>
                            </Grid>
                            : null
                    }

                    {
                        buttonDisabled ?
                            <Grid item xs={12} sm={8} md={6}>
                                <Button
                                    disabled
                                    id="btn_empty_content_disabled"
                                    variant="contained"
                                    className="btn-block"
                                >
                                    {buttonDisabledText}
                                </Button>
                            </Grid>
                            : null
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
