import * as React from "react"

function ImgShield(props) {
    return (
        <svg width={96} height={48} viewBox="0 0 96 48" {...props}>
            <path
                d="M48.03 3s7.826 8.089 17.961 8.304l.412.005c.001 0 .193 3.978-.894 9.323l14.503 7.83 1.476.796-.888 1.346-3.142 4.765 8.454.022 1.14.002-.464 1.022-1.993 4.4L96 45l-12.268-3.37-.517-.14.206-.563 1.435-3.957-9.534.77-2.08.168 1.089-1.97 2.97-5.37-12.896-5.628c-2.192 7.02-6.747 15.043-16.175 19.604-9.643-4.57-14.297-12.617-16.535-19.648L18.7 30.567l2.97 5.371 1.09 1.97-2.081-.168-9.534-.77 1.435 3.957.206.562-.517.142L0 45l11.405-4.184-1.993-4.401-.465-1.022 1.141-.002 8.454-.022-3.142-4.765-.888-1.346 1.476-.797 14.537-7.847.09.19c-1.154-5.43-.959-9.495-.959-9.495C38.844 11.309 48.031 3 48.031 3zm-.134 4.794c-3.454 2.282-8.91 5.298-15.055 6.505.591 6.167 3.302 18.825 15.381 25.452 11.754-6.607 14.415-19.193 14.999-25.388-6.567-1.154-11.97-4.221-15.325-6.57z"
                className="svg-shield"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default ImgShield
