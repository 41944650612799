import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" fillRule="nonzero" d="M0 0h512v512H0z" />
                <path
                    fill="#236092"
                    d="M229.042 390.003c-4.127-16.562 5.95-33.334 22.511-37.462 78.966-19.683 126.022-94.12 126.489-94.868l.731-1.129c16.42-24.168 26.17-58.069 4.29-80.741l-.678-.688c-18.753-18.752-49.39-18.809-68.312-.072l-8.47 8.782c-12.792 13.26-29.984 20.653-48.409 20.817-18.416.116-35.745-6.918-48.772-19.945l-9.484-9.484c-9.158-9.158-21.336-14.202-34.288-14.202-12.955 0-25.132 5.044-34.29 14.202-18.907 18.906-18.907 49.672 0 68.579 38.323 38.323 100.712 41.012 145.12 6.257 11.28-8.828 20.2-18.881 30.779-34.702 9.49-14.191 28.683-17.995 42.87-8.509 14.188 9.49 17.997 28.68 8.508 42.87-14.294 21.374-27.474 36.032-44.064 49.015-68.98 53.987-166.534 49.16-226.92-11.226-43.004-43.008-43.004-112.982 0-155.99 20.834-20.833 48.534-32.305 77.997-32.305s57.16 11.472 77.994 32.305l9.484 9.484c1.544 1.547 3.342 1.834 4.512 1.845 1.206-.01 2.96-.354 4.48-1.927l9.078-9.402c43.008-43.005 112.985-43.005 155.99 0 .098.098.939.947 1.352 1.375 39.085 40.5 40.127 102.418 2.704 157.886-5.939 9.42-63.315 96.716-163.74 121.748a31.087 31.087 0 0 1-7.498.924c-13.856 0-26.462-9.384-29.963-23.437Z"
                />
                <path
                    fill="#2A7DE1"
                    d="M271.497 130.23c43.106-41.723 112.116-41.297 154.692 1.278l.028.029c.177.18.935.946 1.321 1.346l1.161 1.221c37.944 40.515 38.594 101.75 1.545 156.665l-.193.305c-6.9 10.854-64.128 96.66-163.546 121.443l-.685.162a30.934 30.934 0 0 1-6.814.761l-.415-.003c-13.694-.185-26.081-9.521-29.548-23.434l-.12-.497c-3.808-16.399 6.235-32.878 22.63-36.964l2.36-.607c77.54-20.558 123.667-93.52 124.127-94.264l.731-1.127.49-.727c16.123-24.117 25.463-57.57 3.802-80.014l-.677-.687-.567-.557c-18.798-18.195-49.013-18.065-67.743.485l-8.473 8.781-.459.471c-12.742 12.964-29.747 20.185-47.949 20.347h-.665c-18.169-.058-35.237-7.076-48.107-19.946l-9.438-9.436c22.618 22.483 62.134-34.35 62.134-34.35l9.08-9.403Z"
                />
                <path
                    fill="#003A70"
                    d="m60.6 172.919.004-.01s-25.4 100.531 142.822 101.507l.014-.006c25.051.444 50.634-7.608 72.038-24.36l.592-.467c11.006-8.716 19.793-18.695 30.19-34.238l.287-.422c9.585-13.841 28.537-17.476 42.582-8.084l.422.287c13.841 9.583 17.481 28.535 8.087 42.58l-.67.997c-14.04 20.837-27.064 35.243-43.395 48.02l-2.078 1.603c-68.848 52.283-165.06 46.953-224.84-12.828l-1.278-1.298c-29.89-30.88-38.149-75.055-24.777-113.281Z"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon
