import React from 'react';
import {
    AppBar, Toolbar, IconButton, Slide,
    Dialog, Container, Grid
} from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSelfLink(props) {
    const { open, file, close } = props;

    return (
        <Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={close} TransitionComponent={Transition}>
            <AppBar className="appbar appbar-default" position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                    </div>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} className="library-file-content">
                <Grid container justify="center" alignItems="center" className="h-100">
                    <Grid item xs={12} className="h-100" align="center">
                        <iframe
                            title="content_link_self"
                            className="dialog-self-link"
                            src={file}>
                        </iframe>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
};
