import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { actions as AuthActions } from '../../../redux/ducks/auth';
import { Grid, Container, Typography } from '@material-ui/core';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';
import { getLanguage } from '../../../utils/utils';

export default function TermsOfUse() {
    const dispatch = useDispatch();
    const termsOfUse = useSelector(state => state.auth.termsOfUse);
    const language = navigator.language === undefined ? 'pt_BR' : navigator.language;

    useEffect(() => {
        dispatch(
            AuthActions.getTermsOfUse({
                language: getLanguage(language),
                type: 'TERMS_OF_USE'
            }));
    }, [dispatch, language]);

    return (
        <Container maxWidth="md">
            {
                termsOfUse && termsOfUse.load === "finished" ?
                    !termsOfUse.error ?
                        <Grid container justify="center">
                            <Grid item xs={12} className="pb-5 mb-4">
                                {ReactHtmlParser(termsOfUse.data)}
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="column" alignItems="center" justify="center" align="center" className="vh-100">
                            <Icon ic="error" color="light" className="icon-xxl mb-3" />
                            <Typography variant="h4" component="h4" className="mb-3">
                                {intl.get("ERROR_TERMS_OF_USE")}
                            </Typography>
                        </Grid>
                    :
                    <LoadingContent />
            }
        </Container>
    )
}