import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import config from "../../../config";

export default function ButtonLogin(props) {
    const { type, buttonStyle } = props;
    const { history } = useReactRouter();

    return (
        <Grid container justify="center">
            {
                // Mensagem apenas no projeto Fleury
                config.app.name_project === "FLEURY" ?
                    <Grid item xs={12}>
                        <Box p={2} className="highlight-box text-center mb-3">
                            <Typography variant="body2" component="p">
                                {intl.get('LOGIN_MESSAGE_EMAIL')}
                            </Typography>
                        </Box>
                    </Grid>
                    : null
            }
            <Button
                id={buttonStyle === "button" ? `btn_intro_login_${type}` : ""}
                variant={buttonStyle === "button" ? "contained" : "text"}
                className={buttonStyle === "button" ? "btn-block" : ""}
                color="primary"
                onClick={() => history.push(
                    {
                        pathname: '/login',
                        state: {
                            type: type,
                            origin: "intro"
                        }
                    })}
            >
                {
                    type === "email" ?
                        intl.get('BTN_LOGIN_EMAIL')
                        :
                        intl.get('BTN_LOGIN_USERNAME')
                }
            </Button>
        </Grid>
    )
}
