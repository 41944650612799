import React from 'react';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { Rating } from '../../../../components/Images/Images';
import { numberMask } from '../../../../utils/utils';

export default function RatingBar(props) {
    const { type, value, total } = props;

    return (
        <Grid item container xs={12} direction="row" alignItems="center">
            <Grid item xs={3} sm={4} md={3} align="right" className="pr-2">
                <Rating type={type} className="img-sm" />
            </Grid>
            <Grid item xs={6} sm={4} md={6}>
                <LinearProgress variant="determinate" value={
                    value > 0 ?
                        Math.round((parseInt(value) / parseInt(total)) * 100) : 0
                } />
            </Grid>
            <Grid item xs={3} sm={4} md={3} className="pl-2">
                <Typography variant="body2" component="p" align="left" className="font-weight-bold">
                    {numberMask(value)}
                </Typography>
            </Grid>
        </Grid>
    )
}