import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../config';
import { actions as FaqActions } from '../../redux/ducks/faq';
import {
    AppBar, Toolbar, IconButton, Typography, Container, Grid,
    Accordion, AccordionSummary, AccordionDetails, InputBase,
    ListItem, ListItemText, List
} from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function FrequentlyAsked(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const list = useSelector(state => state.faq.list);
    const fetchedList = useSelector(state => state.faq.fetchedList);
    const [didMount, setDidMount] = useState(false);
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(FaqActions.getList({}));
        }

        return () => {
            setDidMount(false);
            dispatch(FaqActions.reset());
            dispatch(FaqActions.questionReset());
        }
    }, [didMount, dispatch]);

    useEffect(() => {
        let didMount = true;

        const fetchDataFiltered = () => {
            if (didMount && search !== "") {
                dispatch(FaqActions.getFetchedList({
                    filter: search
                }));
            }
        };

        const timer = setTimeout(() => {
            if (didMount && search !== "") {
                fetchDataFiltered()
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
            dispatch(FaqActions.fetchedReset());
            didMount = false;
        };
    }, [dispatch, search]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FAQ_FREQUENTLY_ASKED_TITLE")}
                        </Typography>
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value.normalize("NFD"))}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(''); setSearchOpen(false) }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const AccordionItem = (props) => {
        const { item } = props;

        return (
            <Accordion defaultExpanded={config.faq.expanded}>
                <AccordionSummary
                    expandIcon={<Icon ic="chevron_down" color="light" className="icon-xxxs" />}
                >
                    <Typography variant="body1">
                        {item.category_name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                item.questions && item.questions.length > 0 ?
                                    <List component="nav">
                                        {
                                            item.questions.map((faq, index) => {
                                                return (
                                                    <ListItem key={index} button onClick={() => history.push({
                                                        pathname: `/faq/questions/${item.id_category}/${faq.id_question}`,
                                                        state: {
                                                            category: item.category_name,
                                                            id_question: faq.id_question,
                                                            question: faq.question,
                                                            answer: faq.answer
                                                        }
                                                    })}>
                                                        <ListItemText primary={faq.question} />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                    :
                                    <EmptyContent type="section" icon={<Icon ic="help_filled" className="icon-xxl" />} text={intl.get("EMPTY_FAQ")} />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="faq-questions" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md">
                        {
                            list && list.load === "finished" ?
                                !list.error ?
                                    list.data.length !== 0 ?
                                        <Grid container className="pt-4 pb-3">
                                            <Grid item xs={12}>
                                                {
                                                    search.length !== 0 ?
                                                        fetchedList && fetchedList.load === "finished" ?
                                                            !fetchedList.error ?
                                                                fetchedList.data.length !== 0 ?
                                                                    fetchedList.data.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className="mb-2">
                                                                                <AccordionItem item={item} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
                                                                :
                                                                <ErrorContent />
                                                            :
                                                            <LoadingContent />
                                                        :
                                                        list.data.map((item, index) => {
                                                            return (
                                                                <div key={index} className="mb-2">
                                                                    <AccordionItem item={item} />
                                                                </div>
                                                            )
                                                        })
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        <EmptyContent icon={<Icon ic="help_filled" className="icon-xxl" />} text={intl.get("EMPTY_FAQ")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingContent />
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}