export default {

    // Manter em ORDEM ALFABÉTICA (A-Z)
    //------------------------------------------

    clock: "M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Zm0 1.5c4.7033 0 8.5 3.7967 8.5 8.5 0 4.7033-3.7967 8.5-8.5 8.5-4.7033 0-8.5-3.7967-8.5-8.5 0-4.7033 3.7967-8.5 8.5-8.5Zm-.7617 2.4892A.7502.7502 0 0 0 10.5 6.75v5.5a.75.75 0 0 0 .2197.5303l2.5 2.5a.75.75 0 1 0 1.0606-1.0606L12 11.9395V6.75a.75.75 0 0 0-.7617-.7608Z",
    compass: "M12 2C6.4854 2 2 6.4854 2 12s4.4854 10 10 10 10-4.4854 10-10S17.5146 2 12 2Zm0 1.3953c4.7605 0 8.6047 3.8442 8.6047 8.6047 0 4.7605-3.8442 8.6047-8.6047 8.6047-4.7605 0-8.6047-3.8442-8.6047-8.6047 0-4.7605 3.8442-8.6047 8.6047-8.6047Zm0 .4652a.6977.6977 0 1 0 0 1.3953.6977.6977 0 0 0 0-1.3953ZM6.8837 6.186a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954Zm10.2326 0a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954ZM15.6337 7.666a.6978.6978 0 0 0-.2861.0627l-5 2.2729a.6977.6977 0 0 0-.3461.346l-2.273 5a.6977.6977 0 0 0 .924.924l5-2.273a.6977.6977 0 0 0 .346-.346l2.273-5a.6977.6977 0 0 0-.6378-.9866Zm-1.4026 2.103-1.3944 3.0678-3.0678 1.3944 1.3944-3.0678 3.0678-1.3944Zm-9.673 1.5334a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954Zm14.8838 0a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954ZM6.8837 16.4186a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954Zm9.7675 0a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954ZM12 18.7442a.6977.6977 0 1 0 0 1.3953.6977.6977 0 0 0 0-1.3953Z",
    play_trail: "M12 2C6.4854 2 2 6.4854 2 12s4.4854 10 10 10 10-4.4854 10-10S17.5146 2 12 2Zm0 1.3953c4.7605 0 8.6047 3.8442 8.6047 8.6047 0 4.7605-3.8442 8.6047-8.6047 8.6047-4.7605 0-8.6047-3.8442-8.6047-8.6047 0-4.7605 3.8442-8.6047 8.6047-8.6047Zm0 .4652a.6977.6977 0 1 0 0 1.3953.6977.6977 0 0 0 0-1.3953ZM6.8837 6.186a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954Zm10.2326 0a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954ZM15.6337 7.666a.6978.6978 0 0 0-.2861.0627l-5 2.2729a.6977.6977 0 0 0-.3461.346l-2.273 5a.6977.6977 0 0 0 .924.924l5-2.273a.6977.6977 0 0 0 .346-.346l2.273-5a.6977.6977 0 0 0-.6378-.9866Zm-1.4026 2.103-1.3944 3.0678-3.0678 1.3944 1.3944-3.0678 3.0678-1.3944Zm-9.673 1.5334a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954Zm14.8838 0a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954ZM6.8837 16.4186a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954Zm9.7675 0a.6977.6977 0 1 0 0 1.3954.6977.6977 0 0 0 0-1.3954ZM12 18.7442a.6977.6977 0 1 0 0 1.3953.6977.6977 0 0 0 0-1.3953Z",
    steps: "M4.9412 2C3.3272 2 2 3.3273 2 4.9412s1.3273 2.9412 2.9412 2.9412h14.1176C20.6728 7.8824 22 6.555 22 4.9412 22 3.3272 20.6727 2 19.0588 2H4.9412Zm0 1.7647h14.1176c.6602 0 1.1765.5163 1.1765 1.1765 0 .6602-.5163 1.1764-1.1765 1.1764H4.9412c-.6602 0-1.1765-.5162-1.1765-1.1764 0-.6602.5163-1.1765 1.1765-1.1765Zm0 5.2941C3.3272 9.0588 2 10.3861 2 12c0 1.614 1.3273 2.9412 2.9412 2.9412h14.1176C20.6728 14.9412 22 13.6139 22 12c0-1.614-1.3273-2.9412-2.9412-2.9412H4.9412Zm0 1.7647h14.1176c.6602 0 1.1765.5163 1.1765 1.1765 0 .6602-.5163 1.1765-1.1765 1.1765H4.9412c-.6602 0-1.1765-.5163-1.1765-1.1765 0-.6602.5163-1.1765 1.1765-1.1765Zm0 5.2941C3.3272 16.1176 2 17.445 2 19.0588 2 20.6728 3.3273 22 4.9412 22h14.1176C20.6728 22 22 20.6727 22 19.0588s-1.3273-2.9412-2.9412-2.9412H4.9412Zm0 1.7648h14.1176c.6602 0 1.1765.5162 1.1765 1.1764 0 .6602-.5163 1.1765-1.1765 1.1765H4.9412c-.6602 0-1.1765-.5163-1.1765-1.1765 0-.6602.5163-1.1764 1.1765-1.1764Z",
    star: "M12.005 2a.83.83 0 00-.743.437L8.428 7.86 1.7 8.851a.816.816 0 00-.658.535.766.766 0 00.2.804l4.655 4.454L4.86 21.09a.775.775 0 00.335.762.856.856 0 00.861.059L12 18.943l5.944 2.968a.856.856 0 00.861-.059.775.775 0 00.335-.762l-1.036-6.446 4.655-4.454a.766.766 0 00.2-.804.816.816 0 00-.658-.535l-6.73-.99-2.833-5.424A.83.83 0 0012.005 2zM12 4.555l2.287 4.377c.12.229.348.388.612.427l5.541.816-3.798 3.635a.769.769 0 00-.232.679l.852 5.3-4.88-2.436a.858.858 0 00-.763 0l-4.88 2.436.851-5.3a.769.769 0 00-.232-.679L3.56 10.175 9.1 9.36a.823.823 0 00.613-.427L12 4.555z",
};
