import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import countries from 'i18n-iso-countries';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { PrizesService } from '../../services/prizes.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PrizesActions } from '../../redux/ducks/prizes';
import { imageParser, numberMask, getDateTime } from '../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import {
    Grid, Divider, AppBar, Toolbar, IconButton, Typography,
    Container, ListItem, ListItemText, Button
} from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import QrImage from '../../components/QrImage';
import QrList from '../../components/QrList';
import { Icon, Points } from '../../components/Images/Images';
import { LoadingBounce, LoadingContent } from '../../components/Loading';

countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export default function MyRedeemsDetails(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const user = useSelector(state => state.auth.user);
    const redeemedDetails = useSelector(state => state.prizes.redeemedDetails);
    const [didMount, setDidMount] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    let language = user.language.substr(0, 2);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(PrizesActions.getRedeemedDetails({
                id_redeem: props.match.params.idRedeem
            }));
        }

        return () => {
            dispatch(PrizesActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" component="h4" noWrap>
                            {intl.get("MY_REDEEMS_VOUCHER_TITLE")}
                        </Typography>
                    </div>
                    {
                        !loadingDownload ?
                            <IconButton onClick={() => downloadVoucher()}>
                                <Icon ic="download" />
                            </IconButton>
                            :
                            <LoadingBounce size={24} color="white" />
                    }
                </Toolbar>
            </AppBar>
        )
    }

    async function downloadVoucher() {
        let ret;
        setLoadingDownload(true);
        try {
            ret = await PrizesService.getDownloadVoucher({
                id_redeem: props.match.params.idRedeem
            });
            let link = document.createElement("a");
            link.download = "Voucher.pdf";
            link.href = URL.createObjectURL(new Blob([ret], { type: 'application/pdf' }));
            link.click();
            setLoadingDownload(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    const countryList = Object.keys(countries.getNames(language)).map((country) => {
        return {
            name: countries.getName(country, language),
            code: countries.getAlpha2Code(countries.getName(country, language), language)
        }
    });

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="my-redeems" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md" className="pt-3 pb-4">
                        {
                            redeemedDetails && redeemedDetails.load === "finished" ?
                                !redeemedDetails.error ?
                                    redeemedDetails.data.length !== 0 ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" component="p">
                                                    {`${intl.get("LABEL_CODE")}: ${redeemedDetails.data.voucher_number}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={4} align="center">
                                                        <QrImage
                                                            src={imageParser(redeemedDetails.data.prize_image)}
                                                            effect="blur"
                                                            className="voucher-image embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                                            alt={redeemedDetails.data.prize_name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={8} className="d-flex flex-column justify-content-center">
                                                        <Typography variant="subtitle1" component="h1">
                                                            {redeemedDetails.data.prize_name}
                                                        </Typography>
                                                        <Typography variant="body2" component="p" className="font-weight-bold">
                                                            {redeemedDetails.data.company_name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {
                                                redeemedDetails.data.redeem_code ?
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Divider />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2} justify='center'>
                                                                <Grid item xs={12} align="center">
                                                                    <Typography variant="body2" component="p">
                                                                        {`${intl.get("LABEL_PROMOTIONAL_CODE")}:`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} align="center">
                                                                    <Typography variant="h3" component="h3">
                                                                        {redeemedDetails.data.redeem_code}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={8} lg={4}>
                                                                    <Button
                                                                        id="btn_prize_redeem_my_redeems_details"
                                                                        variant="contained"
                                                                        className="btn-block"
                                                                        onClick={() => navigator.clipboard.writeText(redeemedDetails.data.redeem_code)}
                                                                    >
                                                                        <Icon ic="copy" className="mr-2" /> {intl.get("BTN_COPY_CODE")}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                    : null
                                            }
                                            <Grid item xs={12}>
                                                <QrList>
                                                    <Divider />
                                                    <ListItem disableGutters>
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <div>
                                                                    <Typography component="span" variant="body1" className="fs-sm font-weight-bold">
                                                                        {intl.get("LABEL_QIS_VALUE")}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            secondary={
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <Points type="qis" className="img-xs mr-1" />
                                                                    <Typography variant="body1" className="text-qis" component="span">
                                                                        {numberMask(redeemedDetails.data.qi_spent)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disableGutters>
                                                        <ListItemText primary={intl.get("LABEL_REDEEM_DATE")} secondary={getDateTime(redeemedDetails.data.redeem_date)} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disableGutters>
                                                        <ListItemText primary={intl.get("LABEL_VALIDITY")} secondary={redeemedDetails.data.prize_expiracy} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disableGutters>
                                                        <ListItemText primary={intl.get("LABEL_AVAILABILITY")} secondary={redeemedDetails.data.prize_availability} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem disableGutters>
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <Typography variant="h6" component="p">
                                                                    {intl.get("LABEL_ADDRESS")}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <div className="text-right">
                                                                    <Typography variant="body1" component="p">
                                                                        {`${redeemedDetails.data.address.address}, ${redeemedDetails.data.address.number} - ${redeemedDetails.data.address.neighborhood}`}
                                                                    </Typography>
                                                                    {
                                                                        redeemedDetails.data.address.complement ?
                                                                            <Typography variant="body2" component="p">
                                                                                {`${intl.get("LABEL_COMPLEMENT")}: ${redeemedDetails.data.address.complement}`}
                                                                            </Typography>
                                                                            : null
                                                                    }
                                                                    <Typography variant="body2" component="p">
                                                                        {`${redeemedDetails.data.address.city}, ${redeemedDetails.data.address.state}`}
                                                                    </Typography>
                                                                    <Typography variant="body2" component="p">
                                                                        {`${intl.get("LABEL_ZIP_CODE")}: ${redeemedDetails.data.address.zip_code}`}
                                                                    </Typography>
                                                                    <Typography variant="body2" component="p">
                                                                        {countryList.find(country => country.code === redeemedDetails.data.address.country).name}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </QrList>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" component="p">
                                                    {intl.get("LABEL_DESCRIPTION")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" component="div">
                                                    {ReactHtmlParser(redeemedDetails.data.prize_description)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" component="p">
                                                    {intl.get("LABEL_REDEEM_PROCEDURES")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" component="div">
                                                    {ReactHtmlParser(redeemedDetails.data.prize_procedures)}
                                                </Typography>
                                                {
                                                    redeemedDetails.data.redeem_link ?
                                                        <Grid container justify='center'>
                                                            <Grid item xs={12} sm={8} lg={4} className="mt-3 mb-2">
                                                                <Button
                                                                    id="btn_prize_redeem_my_redeems_details"
                                                                    variant="contained"
                                                                    className="btn-block"
                                                                    onClick={() => window.open(redeemedDetails.data.redeem_link, '_blank')}
                                                                >
                                                                    {intl.get("BTN_ACCESS_WEBSITE")}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        <EmptyContent icon={<Icon ic="shopping_bag_filled" className="icon-xxl" />} text={intl.get("EMPTY_VOUCHER")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingContent />
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}