import React from 'react';
import config from '../../../config';
import { imageParser, getSectionType } from '../../../utils/utils';
import { CardHeader, Avatar } from '@material-ui/core';
import { Icon } from '../../../components/Images/Images';

export default function FeedCardHeader(props) {
    const { type, title, date, avatar } = props;
    let sectionObj = getSectionType(type);

    return (
        <CardHeader
            avatar={
                <Avatar aria-label="category" src={sectionObj.image ? avatar.blob ? imageParser(avatar) : avatar : null} className={sectionObj.section}>
                    {
                        sectionObj.icon ?
                            <Icon ic={sectionObj.icon} />
                            : null
                    }
                </Avatar>
            }
            title={title}
            subheader={config.feeds.cards.date ? date : null}
            className="card-title"
        />
    )
}
