import React, { useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import config from '../../config';
import { actions as NotificationActions } from '../../redux/ducks/notification_module';
import { Container, AppBar, Typography, IconButton, Toolbar, useMediaQuery } from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import ScrollToTop from '../../components/ScrollToTop';
import NotificationsList from './list.notifications';
import { Icon } from '../../components/Images/Images';

export default function Notifications(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_NOTIFICATIONS');

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        config.layout.navbar && !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('MENU_NOTIFICATIONS')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("SUBMENU_NOTIFICATIONS")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                    <IconButton
                        onClick={() => dispatch(NotificationActions.updateAll(true))}
                    >
                        <Icon ic="double_tick" />
                    </IconButton>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="notifications" bottomNavigation={bottomMenuItem} hideSidebar>
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            <ScrollToTop />
                            <Container maxWidth="md" className="container-list">
                                <Switch>
                                    <Route path={`${props.match.path}`} exact component={(props) =>
                                        <NotificationsList {...props} />} />
                                </Switch>
                            </Container>
                        </QrContent>
                    </div>
                </div>
            }
        </div>
    )
};