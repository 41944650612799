import React from 'react';
import intl from 'react-intl-universal';
import { Typography, Grid, Button } from '@material-ui/core';
import { Icon } from './Images/Images';

export default function ErrorContent(props) {
    const { type, padding, button } = props;

    return (
        <Grid
            container
            className={`empty-content${type === "section" ? " section" : ""}${padding ? " content-padding" : ""}`}
            justify="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} className="empty-icon">
                        <Icon ic="broken_robot" className="icon-xxl" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4">
                            {intl.get('ERROR_TITLE')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="p">
                            {intl.get('ERROR_TEXT')}
                        </Typography>
                    </Grid>
                    {
                        button !== false ?
                            <Grid item xs={12} sm={8} md={6} lg={4}>
                                <Button
                                    id="btn_error_content"
                                    variant="contained"
                                    className="btn-block"
                                    onClick={() => window.location.reload()}
                                >
                                    {intl.get('BTN_REFRESH')}
                                </Button>
                            </Grid>
                            : null
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
