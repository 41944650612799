import React, { useState, forwardRef } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { examDateTime } from '../../../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Typography, Grid, ListItem, ListItemText,
    Divider, Box, Button, Slide,
} from '@material-ui/core';
import DialogExamRules from './DialogExamRules';
import DialogExamStart from './DialogExamStart';
import QrList from '../../../../components/QrList';
import { Icon } from '../../../../components/Images/Images';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExamDetailsIntro(props) {
    const { item } = props;
    const { history } = useReactRouter();
    const [openStart, setOpenStart] = useState(false);
    const [openRules, setOpenRules] = useState(false);

    const Header = () => {
        return (
            <div id="exam-details-header" className="__intro">
                <div className="scenario-content"></div>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <Container maxWidth="md" className="pt-3 pb-4 __bottom-bar">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} xl={12}>
                        <Grid container justify="center" spacing={2}>
                            {
                                item.description ?
                                    <Grid item xs={12}>
                                        <Typography variant="body2" component="p">
                                            {item.description}
                                        </Typography>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <QrList>
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_THEME")} secondary={item.name} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_NUMBER_QUESTIONS")} secondary={item.total_question} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_TIME")} secondary={item.duration} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_AVAILABLE")} secondary={examDateTime(item.exam_start_date)} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_FINISH")} secondary={examDateTime(item.exam_end_date)} />
                                    </ListItem>
                                </QrList>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={2} className="highlight-box">
                                    <Grid container direction="row" alignItems="center" className="mb-1">
                                        <Icon ic="error" color="warning" className="icon-xs mr-1" />
                                        <Typography variant="body1" component="h5">
                                            {item.exam_feedback_title}
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body2" component="div">
                                        {ReactHtmlParser(item.exam_feedback_message)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div id="bottom-bar" className="relative-bar">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={item.available === 'Y' ? 6 : 12} lg={item.available === 'Y' ? 6 : 8}>
                                            <Button id="btn_exam_details_rules" variant="contained" className="btn-block" onClick={() => setOpenRules(true)}>
                                                {intl.get("BTN_RULE")}
                                            </Button>
                                        </Grid>
                                        {
                                            item.available === 'Y' ?
                                                !item.session ?
                                                    <Grid item xs={6}>
                                                        <Button id="btn_exam_details_start" variant="contained" className="btn-block" onClick={() => setOpenStart(true)}>
                                                            {intl.get("BTN_START")}
                                                        </Button>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={6}>
                                                        <Button
                                                            id="btn_exam_details_continue"
                                                            variant="contained"
                                                            className="btn-block"
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}/questions`,
                                                                    state: {
                                                                        origin: 'details'
                                                                    }
                                                                })}>
                                                            {intl.get("BTN_CONTINUE")}
                                                        </Button>
                                                    </Grid>
                                                : null
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* Dialog Start */}
            <DialogExamStart
                open={openStart}
                close={() => setOpenStart(false)}
                transition={Transition}
                item={item}
                {...props}
            />

            {/* Dialog Rules */}
            <DialogExamRules
                open={openRules}
                close={() => setOpenRules(false)}
                transition={Transition}
                item={item}
            />
        </div>
    )
}