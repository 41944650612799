import * as React from "react"

function ImgBalloon2(props) {
    return (
        <svg width={64} height={64} viewBox="0 0 64 64" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M28.15 57.164a3.164 3.164 0 100 6.329 3.164 3.164 0 000-6.329zm-3.165-14.239a4.746 4.746 0 100 9.493 4.746 4.746 0 000-9.493zM59 22.095c0-4.453-2.776-8.252-6.697-9.8-.614-5.61-5.388-9.977-11.19-9.977a11.249 11.249 0 00-8.062 3.39A11.253 11.253 0 0023.887 1c-6.22 0-11.262 5.018-11.262 11.206 0 .03.004.058.004.087C8.657 14.731 6 19.087 6 24.073c0 7.645 6.229 13.842 13.913 13.842 2.708 0 5.228-.783 7.365-2.114a12.582 12.582 0 009.86 4.751c5.32 0 9.86-3.29 11.702-7.933C54.488 32.39 59 27.772 59 22.095z"
                    fill="#FFF"
                />
                <g fillRule="nonzero">
                    <path
                        d="M44.572 12.133l-23.674-1.655-1.26 18.037L43.31 30.17l1.26-18.037zM30.583 25.882l2.255.157-.158 2.255-2.255-.158.158-2.254zm4.51.315l2.254.158-.158 2.254-2.254-.157.157-2.255zm4.509.315l2.254.158-.158 2.255-2.254-.158.158-2.255zm-13.528-.945l2.254.157-.157 2.255-2.255-.158.158-2.254zm-4.51-.316l2.255.158-.158 2.254-2.254-.157.158-2.255zm9.965-12.897l2.255.158-.158 2.254-2.255-.157.158-2.255zm4.51.315l2.254.158-.158 2.255-2.254-.158.157-2.255zm4.508.316l2.255.157-.158 2.255-2.254-.158.157-2.254zm-13.527-.946l2.254.157-.157 2.255-2.255-.158.158-2.254zm-4.51-.316l2.255.158-.158 2.255-2.254-.158.157-2.255z"
                        fill="#3F51B5"
                    />
                    <path fill="#80D8FF" d="M30.65 16.824l-.473 6.764 5.853-2.694z" />
                </g>
            </g>
        </svg>
    )
}

export default ImgBalloon2
