import * as React from "react"

function ImgBalloon3(props) {
    return (
        <svg width={60} height={60} viewBox="0 0 60 60" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M11.12 51.045a3.164 3.164 0 100 6.328 3.164 3.164 0 000-6.328zm11.865-7.12a4.746 4.746 0 100 9.493 4.746 4.746 0 000-9.493zM57 23.095c0-4.453-2.776-8.252-6.697-9.8-.614-5.61-5.388-9.977-11.19-9.977a11.249 11.249 0 00-8.062 3.39A11.253 11.253 0 0021.888 2c-6.22 0-11.263 5.018-11.263 11.206 0 .03.004.058.004.087C6.657 15.731 4 20.087 4 25.073c0 7.645 6.229 13.842 13.913 13.842 2.708 0 5.228-.783 7.365-2.114a12.582 12.582 0 009.86 4.751c5.32 0 9.86-3.29 11.702-7.933C52.488 33.39 57 28.772 57 23.095z"
                    fill="#FFF"
                />
                <g fillRule="nonzero">
                    <path
                        fill="#D32F2F"
                        d="M19.105 11.231l24.93 4.396-3.036 17.212-24.929-4.395z"
                    />
                    <path
                        d="M29.372 25.893a3.014 3.014 0 10-1.047 5.936 3.014 3.014 0 001.047-5.936z"
                        fill="#D32F2F"
                    />
                    <path
                        d="M29.163 27.08a1.808 1.808 0 10-.628 3.562 1.808 1.808 0 00.628-3.562z"
                        fill="#4C0808"
                    />
                    <path
                        fill="#D7CCC8"
                        d="M41.38 13.323l-9.217.21-1.187-.209-8.589-3.35-1.71 2.758-2.512 14.245 21.367 3.768L42.044 16.5z"
                    />
                    <path
                        fill="#BCAAA4"
                        d="M19.666 25.406l8.903 1.57-.314 1.78 1.187.21.314-1.78 8.903 1.569.873 1.99-21.367-3.768z"
                    />
                    <path
                        d="M41.38 13.323s-8.903-1.57-9.217.21l-2.72 15.433c.313-1.781 9.216-.211 9.216-.211l2.721-15.432zm-18.993-3.35s8.903 1.57 8.59 3.351c-.315 1.78-2.722 15.432-2.722 15.432.314-1.78-8.59-3.35-8.59-3.35l2.722-15.432z"
                        fill="#EFEBE9"
                    />
                    <path
                        d="M32.159 20.5c1.201-.216 3.816-.093 6.673.335l.21-1.193c-2.187-.34-4.858-.619-6.67-.355L32.16 20.5zm-.419 2.374c1.202-.215 3.817-.093 6.673.335l.21-1.192c-2.187-.34-4.858-.62-6.669-.356l-.214 1.213zm.837-4.748c1.202-.216 3.817-.093 6.673.335l.21-1.193c-2.187-.34-4.858-.619-6.669-.356l-.214 1.214zm.633-3.588l-.214 1.214c1.202-.216 3.816-.093 6.673.334l.21-1.192c-2.187-.34-4.858-.62-6.669-.356zm1.395 9.442c-1.122-.071-2.196-.072-3.07.055l-.213 1.214c.659-.12 1.752-.132 3.069-.052l.214-1.217zm-6.007-4.108c-1.056-.613-3.555-1.393-6.385-1.968l.21-1.192c2.171.429 4.777 1.08 6.389 1.947l-.214 1.213zm-.42 2.375c-1.054-.614-3.553-1.393-6.384-1.968l.21-1.193c2.172.429 4.777 1.08 6.389 1.947l-.214 1.214zm-.418 2.374c-1.055-.614-3.554-1.393-6.385-1.968l.21-1.193c2.172.43 4.778 1.08 6.39 1.947l-.215 1.214zm1.256-7.123c-1.055-.614-3.554-1.393-6.385-1.968l.21-1.192c2.172.429 4.778 1.08 6.39 1.946l-.215 1.214zm.633-3.588l-.214 1.214c-1.055-.614-3.554-1.393-6.385-1.968l.21-1.192c2.172.428 4.777 1.08 6.389 1.946zm1.327-.586l1.187.21-2.72 15.432-1.188-.21 2.721-15.432z"
                        fill="#BCAAA4"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgBalloon3
