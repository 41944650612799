import React from 'react';
import config from '../config';
import CharIntroLogin from './img/chars/CharIntroLogin';
import CharIntroSignup from './img/chars/CharIntroSignup';
import CharTrailIntroFlashcards from './img/chars/CharTrailIntroFlashcards';
import CharTrailIntroValidation from './img/chars/CharTrailIntroValidation';
import CharTrailPratice from './img/chars/CharTrailPratice';
import CharTrailNoQuestions from './img/chars/CharTrailNoQuestions';
import CharTrailFlashcardsFinishedStars3 from './img/chars/CharTrailFlashcardsFinishedStars3';
import CharTrailFlashcardsFinishedStars2 from './img/chars/CharTrailFlashcardsFinishedStars2';
import CharTrailFlashcardsFinishedStars1 from './img/chars/CharTrailFlashcardsFinishedStars1';
import CharTrailFlashcardsFinishedStars0 from './img/chars/CharTrailFlashcardsFinishedStars0';
import CharTrailSubcategoryFinished from './img/chars/CharTrailSubcategoryFinished';
import CharSurveyFinished from './img/chars/CharSurveyFinished';
import CharSettingsHelpRanking from './img/chars/CharSettingsHelpRanking';

export default function CharImg(props) {
    switch (props.type) {
        case 'intro-login':
            return (
                <CharIntroLogin className={props.className} />
            )
        case 'intro-signup':
            return (
                <CharIntroSignup className={props.className} />
            )
        case 'trail-intro-flashcards':
            return (
                config.learn.trail.chars && <CharTrailIntroFlashcards className={props.className} />
            )
        case 'trail-intro-validation':
            return (
                config.learn.trail.chars && <CharTrailIntroValidation className={props.className} />
            )
        case 'trail-pratice':
            return (
                config.learn.trail.chars && <CharTrailPratice className={props.className} />
            )
        case 'trail-no-questions':
            return (
                config.learn.trail.chars && <CharTrailNoQuestions className={props.className} />
            )
        case 'trail-finished-stars-3':
            return (
                <CharTrailFlashcardsFinishedStars3 className={props.className} />
            )
        case 'trail-finished-stars-2':
            return (
                <CharTrailFlashcardsFinishedStars2 className={props.className} />
            )
        case 'trail-finished-stars-1':
            return (
                <CharTrailFlashcardsFinishedStars1 className={props.className} />
            )
        case 'trail-finished-stars-0':
            return (
                <CharTrailFlashcardsFinishedStars0 className={props.className} />
            )
        case 'trail-subcategory-finished':
            return (
                config.learn.trail.chars && <CharTrailSubcategoryFinished className={props.className} />
            )
        case 'survey-finished':
            return (
                config.learn.trail.chars && <CharSurveyFinished className={props.className} />
            )
        case 'settings-help-ranking':
            return (
                <CharSettingsHelpRanking className={props.className} />
            )
        default:
            return <span className="text-danger font-weight-bold">Char não encontrado</span>
    }
}