import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProfileActions } from '../../redux/ducks/profile';
import { goToTopAnimation } from '../../utils/utils';
import {
    AppBar, Toolbar, Container, Typography, Grid,
    IconButton, List, Divider
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import QrSidebar from '../../components/QrSidebar';
import ListItemStatistics from '../../components/ListItemStatistics';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function ListClassroom(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const classroomList = useSelector(state => state.profile.classroomList);
    const [didMount, setDidMount] = useState(false);
    const [page, setPage] = useState(1);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const nameUser = props.location.state && props.location.state.nameUser ? props.location.state.nameUser : '';
    const itemsPerPage = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(ProfileActions.getClassroomList({
                id_user: props.match.params.id,
                limit: 0,
                page: 0,
                order_list: 'training_finished asc'
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(ProfileActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('PROFILE_CARD_CLASSROOM_TITLE')}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {nameUser ? nameUser : intl.get('PROFILE_TITLE')}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const ClassroomList = () => {
        return (
            <List component="nav" className="w-100">
                {
                    classroomList.data.trainings
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((item, index) => {
                            return (
                                <div key={index}>
                                    <ListItemStatistics
                                        type="classroom"
                                        image={item.image ? item.image : ""}
                                        title={item.training_name}
                                        dateStart={item.training_date_start}
                                        dateEnd={item.training_date_end}
                                        completed={item.training_finished === "Y" ? true : false}
                                        gutters={true}
                                        action={() => history.push({
                                            pathname: `${history.location.pathname.replace(/./g, '')}/classroom/${item.id_training}`,
                                            state: {
                                                origin: "profile"
                                            }
                                        })}
                                    />
                                    <Divider />
                                </div>
                            )
                        })
                }
            </List>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="profile-classroom">
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            {
                                classroomList && classroomList.load === "finished" ?
                                    !classroomList.error ?
                                        classroomList.data.trainings.length !== 0 ?
                                            <Container maxWidth="md" className="container-list pb-3">
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        <ClassroomList />
                                                    </Grid>
                                                    {
                                                        classroomList.data.trainings.length > itemsPerPage ?
                                                            <Grid item xs={12} sm={10} xl={12}>
                                                                <Pagination
                                                                    count={Math.ceil(classroomList.data.trainings.length / itemsPerPage)}
                                                                    page={page}
                                                                    onChange={(e, value) => { setPage(value); goToTopAnimation(); }}
                                                                    defaultPage={1}
                                                                    color="primary"
                                                                />
                                                            </Grid>
                                                            : null
                                                    }
                                                </Grid>
                                            </Container>
                                            :
                                            <Container maxWidth="md">
                                                <EmptyContent icon={<Icon ic="play_classroom" className="icon-xxl" />} text={intl.get("EMPTY_CLASSROOM")} />
                                            </Container>
                                        :
                                        <Container maxWidth="md">
                                            <ErrorContent />
                                        </Container>
                                    :
                                    <Container maxWidth="md">
                                        <LoadingContent />
                                    </Container>
                            }
                        </QrContent>

                        {/* Sidebar */}
                        <QrSidebar />
                    </div>
                </div>
            }
        </div>
    )
}