import React from 'react';
import config from '../config';
import Confetti from 'react-confetti';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

const project = config.app.name_project.toLowerCase()
let theme = require(`../assets/scss/projects/${project}/_export.scss`)

export default function ConfettiEffect() {
    function draw(ctx) {
        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        }
        if (!this.newRan) {
            this.w = getRandomInt(12, 6);
            this.h = getRandomInt(12, 6);
            this.newRan = true;
        }
        ctx.fillRect(-this.w / 2, -this.h / 2, this.w, this.h);
        ctx.closePath();
        ctx.restore();
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <Confetti
                    width={width}
                    height={height}
                    colors={[
                        `${theme['blue']}`,
                        `${theme['purple']}`,
                        `${theme['pink']}`,
                        `${theme['red']}`,
                        `${theme['orange']}`,
                        `${theme['yellow']}`,
                        `${theme['lime']}`,
                        `${theme['green']}`,
                        `${theme['teal']}`,
                        `${theme['cyan']}`
                    ]}
                    drawShape={draw}
                />
            )}
        </AutoSizer>
    )
}
