import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#C83A7B', // Pink[200]
            main: '#BB0A5B', // Pink[300]
            dark: '#950848', // Pink[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#CFDE32', // Lime[200]
            main: '#C4D600', // Lime[300]
            dark: '#9CAB00', // Lime[400]
            contrastText: '#fff',
        },
        error: {
            light: '#EF453D', // Red[200]
            main: '#EB170D', // Red[300]
            dark: '#BC120A', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
