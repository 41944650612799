import * as React from "react"

function ImgArrow(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <path
                d="M28 15.644c1.986-.758 5.288-.444 5.94 2.029.705 2.678-1.98 4.336-4.064 4.941-2.21.642-4.61.295-6.752-.578.598-2.854 2.142-5.348 4.877-6.392M8.979 9.547c-.07 2.64-.22 5.282-.325 7.922-.04 1.013 1.494 1.16 1.62.141.206-1.677.394-3.357.596-5.035 2.692 4.016 5.92 8.537 10.067 10.956-.26 2.76.093 5.567.968 7.74 2.686 6.675 11.75 11.635 18.47 7.315.771-.495-.06-1.738-.869-1.313-5.528 2.901-11.685-.458-14.73-5.097-1.309-1.993-2.023-4.882-1.935-7.717 2.324.913 4.917 1.142 7.834.307 5.825-1.668 8.237-10.823.82-11.745-5.838-.726-9.045 3.305-10.172 8.126a14.178 14.178 0 01-.82-.517c-3.472-2.362-5.785-6.055-7.94-9.623 1.703.258 3.474.285 5.059-.116 1.31-.333 1.438-2.408-.089-2.456-2.37-.073-4.628.062-6.99-.2-.8-.266-1.731.419-1.564 1.312"
                className="svg-arrow"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default ImgArrow
