import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { Button, TextField, Typography, Grid, Hidden, IconButton } from '@material-ui/core';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import ImgAccessCode from '../../dist/img/svgr/ImgAccessCode';

export default function AccessCode(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ activation_code: history.location.state && history.location.state.code ? history.location.state.code : '' });
    const [error, setError] = useState({ activation_code: false });
    const [auth_captcha, setAuth_captcha] = useState('');
    const [captcha, setCaptcha] = useState(config.captcha.type === 'invisible' ? true : false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [redirectPage, setRedirectPage] = useState(false);
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';
    const login = props.location.state && props.location.state.login ? props.location.state.login : '';
    const auth_password = props.location.state && props.location.state.auth_password ? props.location.state.auth_password : '';

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })

        // Valida os demais
        if (e.target.value.length !== 0) {
            setError({ ...error, [e.target.name]: false });
        } else {
            if (e.target.value.length === 0) {
                setError({ ...error, [e.target.name]: true });
            }
        }
    }

    // --- Google Captcha ---
    const sendData = () => {
        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            dispatch(AuthActions.login(
                {
                    activation_code: data.activation_code,
                    login: login,
                    auth_password: auth_password,
                    auth_provider: 'QRANIO',
                    auth_captcha: auth_captcha,
                }
            ));
            setData({ activation_code: '' });
            auth.code = '';
            setLoading(true);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        if (config.captcha.type === 'invisible') {
            dispatch(AuthActions.login(
                {
                    activation_code: data.activation_code,
                    login: login,
                    auth_password: auth_password,
                    auth_provider: 'QRANIO',
                    auth_captcha: token,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
                }
            ));
            setData({ activation_code: '' });
            auth.code = '';
            setLoading(true);
        } else {
            setAuth_captcha(token);
            setCaptcha(true);
        }
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (redirect === "N") {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }

        if (loading && auth && auth.authenticated && Object.keys(auth.user).length !== 0) {
            auth.status = '';
            setLoading(false);
            history.push('/');
        }

        if (loading && auth.loginError && auth.loginError.length > 0) {
            window.grecaptcha.reset();
            setAuth_captcha('');
            setCaptcha(config.captcha.type === 'invisible' ? true : false);
            setData({ ...data, activation_code: '' })
            setLoading(false);
            setSnackStatus(auth.status.length !== 0 ? auth.status : snackStatus)
            setSnackOpen(true);
            setSnackMessage(auth.loginError);

            if (auth.status.length !== 0 && auth.status !== 'success') {
                auth.loginError = "";
                auth.status = "";
            }
        }
    }, [auth, data, history, loading, snackStatus, redirect]);

    return (
        !redirectPage ?
            <IntroLayout title={intl.get('INTRO_ACCESS_CODE_TITLE')} appbarMobile="default">
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={2}>
                                <IconButton aria-label={intl.get('BTN_BACK')} onClick={() => history.push('/')}>
                                    <Icon ic="back" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get('INTRO_ACCESS_CODE_TITLE')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Hidden>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} align="center">
                            <ImgAccessCode className="img-xl" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="p" variant="body2">
                                {intl.get("INTRO_ACCESS_CODE_TEXT")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="activation_code"
                                name="activation_code"
                                autoComplete="activation_code"
                                type="text"
                                label={intl.get('LABEL_ACCESS_CODE')}
                                fullWidth
                                required
                                variant={config.layout.input_variant}
                                onChange={handleChange}
                                value={data.activation_code}
                                error={error.activation_code}
                                helperText={error.activation_code ? intl.get('ERROR_REQUIRED_FIELD') : null}
                            />
                        </Grid>

                        {/* Captcha */}
                        <Grid container justify="center">
                            {config.captcha.type === 'invisible'
                                ? <div className="g-recaptcha"
                                    data-sitekey={config.captcha.invisible_key}
                                    data-callback="myCallback"
                                    data-size="invisible">
                                </div>
                                : <div className="g-recaptcha mt-3"
                                    data-sitekey={config.captcha.key}
                                    data-callback="myCallback"
                                >
                                </div>
                            }
                        </Grid>

                        {/* Button */}
                        <Grid item xs={12} sm={8}>
                            {
                                !loading
                                    ?
                                    <Button
                                        id="btn_intro_access_code_send"
                                        disabled={captcha && !error.activation_code && data.activation_code.length !== 0 ? false : true}
                                        variant="contained"
                                        color="primary"
                                        value="confirmated"
                                        className="btn-block"
                                        onClick={sendData}
                                    >
                                        {intl.get("BTN_CONFIRM")}
                                    </Button>
                                    :
                                    <Grid container alignItems="center" justify="center">
                                        <LoadingBounce />
                                    </Grid>
                            }
                        </Grid>

                        {
                            config.login_method.have_alternative_code ?
                                <Grid item xs={12}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => history.push({
                                            pathname: '/access-code-alternative',
                                            state: {
                                                origin: 'access-code',
                                                login: login,
                                                auth_password: auth_password,
                                                redirect: "N"
                                            }
                                        })}
                                    >
                                        {intl.get('BTN_NO_EMAIL')}
                                    </Button>
                                </Grid>
                                : null
                        }
                    </Grid>
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}
