import * as React from "react"

function ImgBriefFinished(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M18 3h-5a1 1 0 1 1-2 0H6c-1.106 0-2 .894-2 2v16c0 1.105.894 2 2 2h12a2 2 0 0 0 2-2V5c0-1.106-.895-2-2-2"
                    fill="#455A64"
                />
                <path
                    d="M18 21.5H6a.5.5 0 0 1-.5-.5V5a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v16a.5.5 0 0 1-.5.5"
                    fill="#FFF"
                />
                <path
                    d="M13 3a1 1 0 1 1-2 0H7.5v2a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3H13Z"
                    fill="#90A4AE"
                />
                <path
                    d="M12 1a2 2 0 1 0 .001 3.999A2 2 0 0 0 12 1m0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2"
                    fill="#90A4AE"
                />
                <path
                    fill="#46CA64"
                    d="m11.594 16.89-3.99-3.376 1.292-1.527 2.51 2.123 3.608-4.026 1.486 1.338z"
                />
            </g>
        </svg>
    )
}

export default ImgBriefFinished
