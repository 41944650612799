import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector }                from 'react-redux';

import Slider               from '@material-ui/core/Slider';
import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import intl                 from 'react-intl-universal';
import RotateRightIcon      from '@material-ui/icons/RotateRight';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';
import { actions as AuthActions }   from '../../redux/ducks/auth';


const CropperDialog = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0)

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);


  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.img,
        croppedAreaPixels,
        rotation
      )
    setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, props])


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    }, [])

  useEffect(() => {
    if (croppedImage) {
      var user = auth.user;
      dispatch(AuthActions.updatePhoto({type: "avatar", id_user: user.id_user, photo: croppedImage}));
      setLoading(true);
    }
  }, [croppedImage, auth, auth.user, auth.success, dispatch]);

  if(auth.success){
    props.closeModal(false);
    auth.success = false;
    setCroppedImage(null);
    setLoading(false);
  }

  return (
    <div>
      <Dialog
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent className="image-dialog">
        <Cropper
            image={props.img}
            rotation={rotation}
            crop={crop}
            zoom={zoom}
            cropShape={"round"}
            showGrid={false}
            aspect={5 / 5}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />

        </DialogContent>
        <DialogContent className="d-flex flex-row image-slider-content align-items-center">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              className="mr-3"
            />
            <RotateRightIcon
              aria-labelledby="Rotation"
              onClick={(() => setRotation(rotation+90))}
              color='primary'
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => props.closeModal(false) } color="primary">
            {loading ? '' : intl.get("MODAL_CANCEL_CHANGE_PROFILE_PHOTO")}
          </Button>
          <Button onClick={ showCroppedImage } color="primary" autoFocus>
            {loading ? intl.get("MODAL_LOADING_CHANGE_PROFILE_PHOTO") : intl.get("MODAL_CONFIRM_CHANGE_PROFILE_PHOTO")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default CropperDialog