import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import {
    Dialog, DialogContent, DialogTitle, DialogContentText,
    DialogActions, Button
} from '@material-ui/core';

export default function DialogExamStart(props) {
    const { open, close, transition, item } = props;
    const { history } = useReactRouter();

    return (
        <Dialog id="dialog-exam-start" open={open} onClose={close} TransitionComponent={transition}>
            <DialogTitle>{intl.get("EXAM_DETAILS_CONFIRMATION_DIALOG_TITLE")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {intl.get("EXAM_DETAILS_CONFIRMATION_DIALOG_TEXT") + ` ${item.duration}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    {intl.get("BTN_CANCEL")}
                </Button>
                <Button
                    color="primary"
                    autoFocus
                    onClick={() =>
                        history.push({
                            pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}/questions`,
                            state: {
                                origin: 'details'
                            }
                        })
                    }
                >
                    {intl.get("BTN_START")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}