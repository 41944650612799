import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ManagerActions } from "../../redux/ducks/manager";
import { numberMask, verifyIfIsValidNumber } from '../../utils/utils';
import { Container, Grid } from '@material-ui/core';
import ActivityInfoCard from './components/ActivityInfoCard';
import TableTeamEmployees from "./components/TableTeamEmployees";
import TableTeamActivities from "./components/TableTeamActivities";
import TableGeneralActivities from "./components/TableGeneralActivities";
import { LoadingBounce } from "../../components/Loading";

export default function ManagerPanel(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { totalScorm, totalClassroom, totalTrail } = useSelector(state => state.manager);
    const [didMount, setDidMount] = useState(false);
    let page = 0;
    let rows = 10;

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }
    }, [auth, history]);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (config.manager.head_cards.scorm && (auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y')) {
                dispatch(ManagerActions.getTotalScorm({
                    id_manager: auth.user.id_user
                }));
            }

            if (config.manager.head_cards.classroom && (auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y')) {
                dispatch(ManagerActions.getTotalClassroom({
                    id_manager: auth.user.id_user
                }));
            }

            if (config.manager.head_cards.trail && (auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y')) {
                dispatch(ManagerActions.getTotalTrail({
                    id_manager: auth.user.id_user
                }));
            }

            if (config.manager.table_team_employees) {
                dispatch(ManagerActions.getTeamEmployees({
                    id_manager: auth.user.id_user,
                    offset: page,
                    limit: rows,
                }));
            }

            if (config.manager.table_team_activities) {
                dispatch(ManagerActions.getTeamActivities({
                    id_manager: auth.user.id_user,
                    offset: page,
                    limit: rows,
                }));
            }

            if (config.manager.table_all_users) {
                dispatch(ManagerActions.getGeneralActivities({
                    id_manager: auth.user.id_user,
                    my_team: 'Y',
                    offset: page,
                    limit: rows,
                }));
            }
        }
        
        return () => {
            setDidMount(false);
        }
    }, [dispatch, didMount, page, rows, auth]);

    return (
        <Container maxWidth="lg" className="pt-3 pb-4">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="stretch" className="activities-info" spacing={2}>
                        {
                            // SCORM
                            config.manager.head_cards.scorm && (auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y') ?
                                <ActivityInfoCard
                                    type="scorm"
                                    title={intl.get('SCORM_TITLE')}
                                    total={
                                        totalScorm && totalScorm.load === "finished" ?
                                            !totalScorm.error ?
                                                numberMask(verifyIfIsValidNumber(parseInt(totalScorm.data.total_scorm_available)))
                                                :
                                                "-"
                                            :
                                            <LoadingBounce color="white" size={20} />
                                    }
                                />
                                : null
                        }
                        {
                            // Classroom
                            config.manager.head_cards.classroom && (auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y') ?
                                <ActivityInfoCard
                                    type="classroom"
                                    title={intl.get('CLASSROOM_TITLE')}
                                    total={
                                        totalClassroom && totalClassroom.load === "finished" ?
                                            !totalClassroom.error ?
                                                numberMask(verifyIfIsValidNumber(parseInt(totalClassroom.data.total_classroom_available)))
                                                :
                                                "-"
                                            :
                                            <LoadingBounce color="white" size={20} />
                                    }
                                />
                                : null
                        }
                        {
                            // Trail
                            config.manager.head_cards.trail && (auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y') ?
                                <ActivityInfoCard
                                    type="trail"
                                    title={intl.get('TRAIL_TITLE')}
                                    total={
                                        totalTrail && totalTrail.load === "finished" ?
                                            !totalTrail.error ?
                                                numberMask(verifyIfIsValidNumber(parseInt(totalTrail.data.team_course.total_course_available)))
                                                :
                                                "-"
                                            :
                                            <LoadingBounce color="white" size={20} />
                                    }
                                />
                                : null
                        }
                    </Grid>
                </Grid>
                {
                    config.manager.table_team_employees ?
                        <Grid item xs={12}>
                            <TableTeamEmployees />
                        </Grid>
                        : null
                }
                {
                    config.manager.table_team_activities ?
                        <Grid item xs={12}>
                            <TableTeamActivities />
                        </Grid>
                        : null
                }
                {
                    config.manager.table_all_users ?
                        <Grid item xs={12}>
                            <TableGeneralActivities />
                        </Grid>
                        : null
                }
            </Grid>
        </Container>
    )
};
