import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#597297', // Blue[200]
            main: '#304F7E', // Blue[300]
            dark: '#263f64', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#edb141', // Yellow[200]
            main: '#e99e12', // Yellow[300]
            dark: '#ba7e0e', // Yellow[400]
            contrastText: '#fff',
        },
        error: {
            light: '#EA7569', // Red[200]
            main: '#E65748', // Red[300]
            dark: '#BD483B', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
