import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { imageParser } from '../../../utils/utils';
import {
    Grid, Typography, Card, CardHeader, CardContent
} from '@material-ui/core';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import QrImage from '../../../components/QrImage';

const CardAchievementsBadges = () => {
    const badgesList = useSelector(state => state.profile.badgesList);

    return (
        <Card className="profile-card __achievements">
            <CardHeader
                title={
                    <Typography variant="h3" component="h2" align="center">
                        {intl.get("PROFILE_CARD_ACHIEVEMENTS_BADGES_TITLE")}
                    </Typography>
                }
                subheader={
                    <Typography variant="body2" component="p" className="fs-md" align="center">
                        {intl.get("PROFILE_CARD_ACHIEVEMENTS_BADGES_SUBTITLE")}
                    </Typography>
                }
            />
            <div className="profile-card-content">
                {
                    badgesList && badgesList.load === "finished" ?
                        !badgesList.error ?
                            <>
                                <CardContent className="w-100">
                                    {
                                        badgesList.data.length !== 0 ?
                                            <Grid container direction="column" alignItems="center" justify="space-between" className="chart-wrap">
                                                <Grid item xs={12} className="chart-content flex-row align-items-start justify-content-start">
                                                    <Grid container spacing={2}>
                                                        {
                                                            badgesList.data.map((item, index) => {
                                                                return (
                                                                    <Grid key={index} item xs align="center" className="badge-content">
                                                                        <QrImage
                                                                            src={imageParser(item.image)}
                                                                            effect="blur"
                                                                            className="badge-image"
                                                                            alt="Badge"
                                                                        />
                                                                        <Typography variant="body1" component="h4" className="badge-name">
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container direction="column" alignItems="center" justify="space-between" className="chart-wrap justify-content-center">
                                                <Typography variant="body1" component="h4">
                                                    {intl.get("EMPTY_ACHIEVEMENTS_BADGES")}
                                                </Typography>
                                            </Grid>
                                    }
                                </CardContent>
                            </>
                            :
                            <Grid container alignItems="center" justify="center" className="chart-wrap">
                                <ErrorContent type="section" padding={true} />
                            </Grid>
                        :
                        <Grid container alignItems="center" justify="center" className="chart-wrap">
                            <LoadingBounce />
                        </Grid>
                }
            </div>
        </Card>
    );
};

export default CardAchievementsBadges;