import React from 'react';
import useReactRouter from 'use-react-router';
import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';
import ImgBrief from '../../../../dist/img/svgr/ImgBrief';

export default function CardNewExam(props) {
    const { history } = useReactRouter();
    const { subheader, description, reference } = props;

    return (
        <Card className="card card-new-exam">
            <CardActionArea
                onClick={() => history.push({
                    pathname: `${history.location.pathname.replace(/./g, '')}/exam/${JSON.parse(reference).id}`,
                    state: { id_exam: JSON.parse(reference).id, origin: history.location.pathname, }
                })}>
                <FeedCardHeader {...props} />
                <CardContent className="d-flex flex-row pt-0">
                    <div className="card-image-square mr-3">
                        <ImgBrief />
                    </div>
                    <div>
                        <Typography gutterBottom variant="body1" component="h4">
                            {subheader}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <FeedCardFooter {...props} />
        </Card>
    )
}