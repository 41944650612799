import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { actions as ExamActions } from '../../../redux/ducks/exam';
import {
    Typography, AppBar, Toolbar, IconButton, Button,
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Container, Backdrop
} from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingContent, LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';
import ExamNavigation from './components/ExamNavigation';
import QrGame from '../../../components/QrGame';
import ExamTimer from './components/ExamTimer';
import SnackBar from '../../../components/snackBar';

export default function ExamQuestions(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const question = useSelector(state => state.exam.question);
    const finish = useSelector(state => state.exam.finish);
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const loadingBackdrop = useSelector(state => state.exam.loading);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (origin === "details") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount) {
            dispatch(ExamActions.getQuestions({
                id_exam: props.match.params.idExam,
            }));
        }

        return () => {
            dispatch(ExamActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match, origin]);

    useEffect(() => {
        if (loading && (finish.load === "finished" && finish.data?.done === "Y")) {
            setLoading(false);

            history.push({
                pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                state: { origin: 'questions' }
            })
        }

        if (loading && (finish.load === "finished" && finish.error)) {
            setLoading(false);
            setSnackOpen(true);
        }
    }, [loading, finish, history, props.match]);

    function goBack() {
        let currentOrigin = "questions";

        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
            state: {
                origin: currentOrigin
            }
        })
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    {
                        config.layout.navbar ?
                            <IconButton
                                color="inherit"
                                aria-label="close"
                                edge="start"
                                onClick={() => setOpenAlert(true)}
                            >
                                <Icon ic="close" />
                            </IconButton>
                            : null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {question.data && question.data.name}
                        </Typography>
                    </div>
                    {
                        !loading && question.data?.allow_free_navigation === "Y" && question.data?.session?.user_answered_all === "Y" &&
                        <Button
                            id="btn_exam_question_finish"
                            variant="contained"
                            onClick={() => examFinish()}
                        >
                            {intl.get("BTN_FINISH")}
                        </Button>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const sendTo = async (answer, idQuestion) => {
        setLoadingQuestion(true);

        await dispatch(ExamActions.getExamAnswer({
            id_session: question.data.session.id_session,
            id_exam: question.data.id_exam,
            id_question: idQuestion,
            answer: answer
        }));

        setLoadingQuestion(false);
    }

    async function examFinish() {
        setLoading(true);

        await dispatch(ExamActions.setExamFinish({
            id_session: question.data.session.id_session,
            id_exam: question.data.id_exam,
        }));
    }

    return (
        !redirect ?
            !timeOut ?
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="exam-details" hideSidebar>
                            <QrAppBar />
                            {
                                !loadingQuestion && !loading && (question && question.load === "finished") ?
                                    !question.error ?
                                        question.data.length !== 0 ?
                                            question.data.done && question.data.done === "Y" ?
                                                <Redirect to={{
                                                    pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                                                    state: {
                                                        origin: 'questions'
                                                    }
                                                }} />
                                                :
                                                <div className={`w-100${question.data.allow_free_navigation === "Y" ? " __game-bar" : ""}`}>
                                                    <QrGame
                                                        data={question.data.session}
                                                        send={sendTo}
                                                    >
                                                        {
                                                            config.learn.exam.navigation === "top" && question.data.allow_free_navigation === "Y" &&
                                                            <div className="qr-game-exam-nav __top">
                                                                <ExamNavigation
                                                                    idExam={props.match.params.idExam}
                                                                    questions={question.data.session.user_questions_status}
                                                                />
                                                            </div>
                                                        }
                                                        <ExamTimer
                                                            startDate={question.data.session.user_start_date}
                                                            finishDate={question.data.session.user_limit_finish_date}
                                                            serverDate={question.data.server_date}
                                                            timeOut={(t) => setTimeOut(t)}
                                                            currentQuestion={question.data.session.current_question}
                                                            totalQuestion={question.data.session.total_question}
                                                        />
                                                    </QrGame>
                                                    {
                                                        config.learn.exam.navigation === "bottom" && question.data.allow_free_navigation === "Y" &&
                                                        <div className={`qr-game-bar qr-game-exam-nav __bottom ${menuOpen ? "__menu-drawer-open" : "__menu-drawer-close"}`}>
                                                            <Container maxWidth="md" style={{ zIndex: -2 }}>
                                                                <ExamNavigation
                                                                    idExam={props.match.params.idExam}
                                                                    questions={question.data.session.user_questions_status}
                                                                />
                                                            </Container>
                                                        </div>
                                                    }
                                                    <div className="loading-backdrop">
                                                        <Backdrop open={loadingBackdrop}>
                                                            <LoadingBounce color="white" />
                                                        </Backdrop>
                                                    </div>
                                                </div>
                                            :
                                            <Container maxWidth="md">
                                                <EmptyContent icon={<Icon ic="play_exam" className="icon-xxl" />} text={intl.get("EMPTY_EXAM")} />
                                            </Container>
                                        :
                                        <Redirect to={{
                                            pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                                            state: {
                                                origin: 'questions'
                                            }
                                        }} />
                                    :
                                    <Container maxWidth="md">
                                        <LoadingContent />
                                    </Container>
                            }
                        </QrContent>
                        <SnackBar
                            open={snackOpen}
                            message={intl.get('ERROR_FINISH_EXAM')}
                            status="error"
                            time={3}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            closeSnack={() => setSnackOpen(false)}
                        />
                        <Dialog
                            open={openAlert}
                            onClose={() => setOpenAlert(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {intl.get("EXAM_QUESTION_ALERT_TITLE")}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {intl.get("EXAM_QUESTION_ALERT_TEXT")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenAlert(false)} color="primary">
                                    {intl.get("BTN_CANCEL")}
                                </Button>
                                <Button onClick={() => goBack()} color="primary">
                                    {intl.get("BTN_YES")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
                :
                <Redirect to={{
                    pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}/timeout`,
                    state: {
                        origin: 'questions'
                    }
                }} />
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/exam`,
                state: {
                    origin: 'questions'
                }
            }} />
    )
}