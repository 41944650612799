import { faqService } from '../../services/faq.service';

// CONST
export const Types = {
    LIST: 'faq/LIST',
    FETCHED_LIST: 'faq/FETCHED_LIST',
    QUESTION: 'faq/QUESTION',
    SENDEMAIL: 'faq/SENDEMAIL',
    USER_RATING: 'faq/USER_RATING',
    FETCHED_RESET: 'faq/FETCHED_RESET',
    QUESTION_RESET: 'faq/QUESTION_RESET',
    RESET: 'faq/RESET',
};

// INITIAL STATE
const initialState = {
    list: {},
    fetchedList: {},
    question: {},
    sendEmail: {},
    userRating: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.FETCHED_LIST:
            return {
                ...state,
                fetchedList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.QUESTION:
            let data = action.payload.data
            let id_category = action.payload.id_category
            let id_question = action.payload.id_question
            let category = data && data.filter(item => item.id_category === id_category)[0]
            let question = category && category.questions.filter(question => question.id_question === id_question)[0]
            
            return {
                ...state,
                question: {
                    data: data && question ? { category: category, question: question } : [],
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SENDEMAIL:
            return {
                ...state,
                sendEmail: {
                    status: action.payload.status,
                    messages: action.payload.messages,
                }
            }
        case Types.USER_RATING:
            return {
                ...state,
                userRating: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.FETCHED_RESET:
            return {
                ...state,
                fetchedList: {},
            }
        case Types.QUESTION_RESET:
            return {
                ...state,
                question: {},
            }
        case Types.RESET:
            return {
                ...state,
                fetchedList: {},
                sendEmail: {},
                userRating: {}
            }
        default:
            return state
    }
}

// ACTIONS
function getList(params) {
    return dispatch => {
        return faqService.getList(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.faq,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getFetchedList(params) {
    return dispatch => {
        return faqService.getList(params).then(data => {
            dispatch({
                type: Types.FETCHED_LIST,
                payload: {
                    data: data.faq,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FETCHED_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getQuestion(params) {
    return dispatch => {
        return faqService.getList(params).then(data => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: data.faq,
                    id_category: params.id_category,
                    id_question: params.id_question,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTION,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function sendEmail(params) {
    return dispatch => {
        return faqService.sendEmail(params).then(data => {
            dispatch({
                type: Types.SENDEMAIL,
                payload: {
                    status: 'success',
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SENDEMAIL,
                payload: {
                    status: 'error',
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            })
        })
    }
}

function setUserRating(params) {
    return dispatch => {
        return faqService.setUserRating(params).then(data => {
            dispatch({
                type: Types.USER_RATING,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.USER_RATING,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function fetchedReset() {
    return dispatch => {
        dispatch({
            type: Types.FETCHED_RESET
        });
    }
}

function questionReset() {
    return dispatch => {
        dispatch({
            type: Types.QUESTION_RESET
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getList,
    getFetchedList,
    getQuestion,
    sendEmail,
    fetchedReset,
    setUserRating,
    questionReset,
    reset,
}
