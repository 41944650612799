import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import config from '../../../config';
import ExamList from './list';
import ExamDetails from './details';
import ExamQuestions from './questions';
import ExamTimeOut from './timeout';

export default function Exam(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.EXAM && auth.modules.EXAM.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <Switch>
                    <Route path={`${props.match.path}`} exact component={(props) =>
                        <ExamList {...props} />}
                    />

                    <Route path={`${props.match.path}/:idExam`} exact component={(props) =>
                        <ExamDetails  {...props} />}
                    />

                    <Route path={`${props.match.path}/:idExam/questions`} exact component={(props) =>
                        <ExamQuestions  {...props} />}
                    />

                    <Route path={`${props.match.path}/:idExam/timeout`} exact component={(props) =>
                        <ExamTimeOut  {...props} />}
                    />
                </Switch>
            }
        </div>
    )
};
