import React, { useEffect, useState, useRef } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as FeedsActions } from '../../../redux/ducks/feeds';
import { imageParser, feedDateTime, getSectionType } from '../../../utils/utils';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Container, Grid, Typography, IconButton, Divider,
    List, ListItem, ListItemText, ListItemAvatar, Avatar,
    OutlinedInput, InputAdornment, useMediaQuery
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import CommentItem from './CommentItem';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Comments(props) {
    const dispatch = useDispatch();
    const { comments, feeds } = useSelector(state => state.feeds);
    const { feed } = props;
    const [didMount, setDidMount] = useState(false);
    const [typedComment, setTypedComment] = useState("");
    const [loadingSend, setLoadingSend] = useState(false);
    const [sendError, setSendError] = useState(false);
    const [totalComments, setTotalComments] = useState(parseInt(feed.total_comment));
    const isScreenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
    const scrollbarComment = useRef(null);
    let sectionObj = getSectionType(feed.feed_type);

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(FeedsActions.getCommentsList({
                id_feed: feed.id_feed
            }));

        return () => {
            dispatch(FeedsActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, feed]);

    props.total(totalComments);

    async function sendComment() {
        setLoadingSend(true);
        await dispatch(FeedsActions.addComment({
            id_feed: feed.id_feed,
            comment: typedComment
        }));
        setLoadingSend(false);

        if (comments.error) {
            setSendError(true);
            setTimeout(function () {
                setSendError(false);
            }, 3000);
        } else {
            setTypedComment("");
            setTotalComments(comments.total + 1);
            feeds.data.find(feedItem => feedItem.id_feed === feed.id_feed).total_comment = comments.total + 1;

            // ScrollTop
            scrollbarComment.current.view.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    const FeedInfoItem = () => {
        let title = ""

        if (feed.exam) {
            title = feed.exam.name;
        } else if (feed.training) {
            title = feed.training.name;
        } else if (feed.scorm) {
            title = feed.scorm.name;
        } else if (feed.course) {
            title = feed.course.name;
        } else if (feed.category) {
            title = feed.category.name;
        }

        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar aria-label="category" src={sectionObj.image ? feed.card_icon.blob ? imageParser(feed.card_icon) : feed.card_icon : null} className={sectionObj.section}>
                        {
                            sectionObj.icon ?
                                <Icon ic={sectionObj.icon} />
                                : null
                        }
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="body1" component="h4" className="mb-1">
                            {
                                title ?
                                    title
                                    : feed.message
                            }
                        </Typography>
                    }
                    secondary={
                        <div>
                            {
                                title ?
                                    <Typography variant="body1" component="p" className="font-weight-normal mb-1">
                                        {feed.message}
                                    </Typography>
                                    : null
                            }
                            <Typography variant="caption" component="p">
                                {feedDateTime(feed.date)}
                            </Typography>
                        </div>
                    }
                />
            </ListItem>
        )
    }

    return (
        <Scrollbars
            ref={scrollbarComment}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            renderView={props => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
        >
            <Container maxWidth="md" className="container-full">
                <Grid
                    container
                    id="feeds-list-comments"
                    justify="center"
                >
                    {
                        !isScreenSm ?
                            <Grid item xs={12} className="title-bar">
                                <Grid container className="pt-2 pb-2 pl-3 pr-3" justify="space-between" alignItems="center">
                                    <Grid item xs={2}>
                                        <IconButton aria-label={intl.get('BTN_BACK')} onClick={() => props.pageComment(false)}>
                                            <Icon ic="close" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="h4" component="h4" align="center">
                                            {intl.get('FEEDS_COMMENTS_TITLE')} {`(${totalComments})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={10} lg={12}>
                        <List>
                            <FeedInfoItem />
                            <Divider component="li" />
                            {
                                comments && comments.load === "finished" ?
                                    !comments.error ?
                                        comments.data.length !== 0 ?
                                            comments.data.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <CommentItem
                                                            id={item.id_comment}
                                                            idFeed={feed.id_feed}
                                                            avatar={item.user_photo}
                                                            name={item.user_name}
                                                            date={item.date_comment}
                                                            comment={item.comment}
                                                            canDelete={item.can_delete === "Y" ? true : false}
                                                            totalLike={item.total_like}
                                                            userLiked={item.user_liked}
                                                            totalComment={(total) => setTotalComments(total)}
                                                        />
                                                        {
                                                            index < comments.data.length - 1
                                                                ?
                                                                <Divider variant="inset" />
                                                                : null
                                                        }
                                                    </div>
                                                )
                                            })
                                            :
                                            <EmptyContent
                                                type="section"
                                                icon={<Icon ic="speech_filled" className="icon-xl" />}
                                                text={intl.get("EMPTY_COMMENTS")}
                                            />
                                        :
                                        <ErrorContent type="section" />
                                    :
                                    <Grid container justify="center" className="pt-4">
                                        <LoadingBounce size={24} />
                                    </Grid>
                            }
                        </List>
                    </Grid>
                    <div className="textfield-bar">
                        {
                            !loadingSend ?
                                sendError ?
                                    <Alert severity="error" className="rounded-0">{intl.get('ERROR_SENT_COMMENT')}</Alert>
                                    :
                                    <OutlinedInput
                                        id="input_comment"
                                        name="comment"
                                        label=""
                                        notched={false}
                                        placeholder={intl.get('LABEL_ADD_COMMENT')}
                                        value={typedComment}
                                        multiline
                                        fullWidth
                                        margin="dense"
                                        onChange={(e) => setTypedComment(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={intl.get('LABEL_COMMENT')}
                                                    edge="end"
                                                    onClick={() => sendComment()}
                                                    disabled={typedComment ? false : true}
                                                >
                                                    <Icon ic="send" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                :
                                <LoadingBounce size={16} />
                        }
                    </div>
                </Grid>
            </Container>
        </Scrollbars>
    )
}