import * as React from "react"

function ImgBriefInProgress(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M18 3h-5a1 1 0 1 1-2 0H6c-1.106 0-2 .894-2 2v16c0 1.105.894 2 2 2h12a2 2 0 0 0 2-2V5c0-1.106-.895-2-2-2"
                    fill="#455A64"
                />
                <path
                    d="M18 21.5H6a.5.5 0 0 1-.5-.5V5a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v16a.5.5 0 0 1-.5.5"
                    fill="#FFF"
                />
                <path
                    d="M13 3a1 1 0 1 1-2 0H7.5v2a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3H13Z"
                    fill="#90A4AE"
                />
                <path
                    d="M12 1a2 2 0 1 0 .001 3.999A2 2 0 0 0 12 1m0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2"
                    fill="#90A4AE"
                />
                <path
                    d="M7 10h4.5v1H7v-1Zm0 6h4.5v1H7v-1Zm10-7v3h-3V9h3Zm-1 1h-1v1h1v-1Zm1 5v3h-3v-3h3Zm-1 1h-1v1h1v-1Z"
                    fill="#03A9F4"
                />
                <path
                    d="M24 18.649V24h-2.802l-2.747-1.946 1.45-2.381-3.583-1.48 1.163-3.053-3.381 1.568a.86.86 0 0 1-.712.02.905.905 0 0 1-.454-.455c-.204-.456-.022-.998.417-1.214l4.198-1.931a.91.91 0 0 1 .724-.015l3.239 1.338c.238.1.477.198.602.344L24 18.649Z"
                    fill="#FFB74D"
                />
                <path
                    fill="#E69329"
                    d="m19.128 16.526-.001-.002-2.355 1.26 1.51.831 2.135-.3-.136-1.622-.46-.54-.071.035-.311.17-.013.007z"
                />
                <path
                    d="m23.863 14.45-.739-1.78a.505.505 0 0 0-.675-.28l-.805.363-.132 1.698 1.278 1.065.805-.363a.544.544 0 0 0 .268-.703"
                    fill="#E57373"
                />
                <path
                    fill="#B0BEC5"
                    d="m22.792 15.516-1.327.597-1.165-1.117.018-1.647 1.327-.597z"
                />
                <path
                    fill="#00ACC1"
                    d="m12.852 16.705 7.467-3.36 1.146 2.764-7.467 3.36-.974-1.199z"
                />
                <path
                    fill="#FFC107"
                    d="m12.85 16.708-.813 1.177-.226.931.805.466 1.38.19z"
                />
                <path
                    d="M14.113 17.189c-.386.492-.21 1.132.188 1.513l3.081 2.472c.199.192 1.128 1.198 2.07 1.203 1.05.006 2.13-.449 2.13-.449l-1.12-3.73-2.135.3-2.535-1.395c-.699-.437-1.277-.41-1.679.086"
                    fill="#FFB74D"
                />
                <path
                    d="M14.44 18.246a.68.68 0 0 1 .013-.933.616.616 0 0 1 .896.014c.244.261-.666 1.18-.91.92"
                    fill="#FFE0B2"
                />
                <path fill="#37474F" d="m12.036 17.885-.832 1.203 1.41.194z" />
            </g>
        </svg>
    )
}

export default ImgBriefInProgress
