import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { numberMask } from '../../../../utils/utils';

const PeriodicQuestionHeader = ({ data }) => {
    return (
        <Grid container justify="center">
            <div className="question-points">
                <div className="question-points-content">
                    <Typography variant="h5" component="span" className={parseInt(data.question.qis) > 999 ? "__small" : ""}>
                        {numberMask(data.question.qis)}
                    </Typography>
                </div>
            </div>
        </Grid>
    )
}

export default PeriodicQuestionHeader;