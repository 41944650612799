import { qrService } from './base.service'


function list(params) {
    return qrService('/v1/app/scorm/list', params);
}

function themes(params) {
    return qrService('/v1/app/scorm/category/list', params)
}

function details(params) {
    return qrService('/v1/app/scorm/package/list', params);
}

function getImgLink(params) {
    return qrService('/v1/app/blob/link', params);
}

function setVar(params) {
    return qrService('/v2/app/scorm/package/item/user/set/value', params);
}

function certificate(params) {
    return qrService('/v1/app/scorm/user/certificate', params);
}

function packageOpen(params) {
    return qrService('/v1/app/scorm/package/open', params);
}

export const scormService = {
    list,
    themes,
    details,
    getImgLink,
    setVar,
    certificate,
    packageOpen
}