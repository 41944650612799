import { myHistoryService } from '../../services/my_history.service'

// CONST
export const Types = {
    PROFILE_ACTIVITIES: 'my_history/PROFILE_ACTIVITIES',
    COURSES_ALL: 'my_history/COURSES_ALL',
    COURSES_OBLIGATORY: 'my_history/COURSES_OBLIGATORY',
    RESET: 'my_history/RESET',
};

// INITIAL STATE
const initialState = {
    userActivities: {},
    coursesAll: {},
    coursesObligatory: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.PROFILE_ACTIVITIES:
            return {
                ...state,
                userActivities: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.COURSES_ALL:
            return {
                ...state,
                coursesAll: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.COURSES_OBLIGATORY:
            return {
                ...state,
                coursesObligatory: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                userActivities: {},
                coursesAll: {},
                coursesObligatory: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getProfileActivities(params) {
    return dispatch => {
        return myHistoryService.getProfileActivities(params).then(data => {
            dispatch({
                type: Types.PROFILE_ACTIVITIES,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PROFILE_ACTIVITIES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStatisticCoursesAll(params) {
    return dispatch => {
        return myHistoryService.getStatisticCoursesAll(params).then(data => {
            dispatch({
                type: Types.COURSES_ALL,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COURSES_ALL,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStatisticCoursesObligatory(params) {
    return dispatch => {
        return myHistoryService.getStatisticCoursesObligatory(params).then(data => {
            dispatch({
                type: Types.COURSES_OBLIGATORY,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COURSES_OBLIGATORY,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getProfileActivities,
    getStatisticCoursesAll,
    getStatisticCoursesObligatory,
    reset,
}
