import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { actions as FeedsActions } from '../../../redux/ducks/feeds';
import { feedDateTime } from '../../../utils/utils';
import {
    Typography, IconButton, ListItem, ListItemText, ListItemAvatar, Avatar,
    ListItemSecondaryAction, Fade
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

function Alert(props) {
    return <MuiAlert elevation={0} variant="filled" {...props} />;
}

export default function CommentItem(props) {
    const dispatch = useDispatch();
    const { comments, feeds } = useSelector(state => state.feeds);
    const { id, idFeed, avatar, name, date, comment, canDelete, totalLike, userLiked, totalComment } = props;
    const [clicked, setClicked] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [itemActionLike, setItemActionLike] = useState(userLiked);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    useEffect(() => {
        if (clicked) {
            dispatch(FeedsActions.setLikeComment({
                id_feed: idFeed,
                id_comment: id,
                liked: itemActionLike
            }));
            setClicked(false);
        }
    }, [dispatch, clicked, id, idFeed, itemActionLike]);

    async function deleteComment(id) {
        setLoadingDelete(true);
        await dispatch(FeedsActions.deleteComment({
            id_feed: idFeed,
            id_comment: id
        }));
        setLoadingDelete(false);
        totalComment(comments.total - 1);
        feeds.data.find(feedItem => feedItem.id_feed === idFeed).total_comment = comments.total - 1;

        // Exibir alerta de sucesso no delete
        setDeleteSuccess(true);
        setTimeout(function () {
            setDeleteSuccess(false);
        }, 3000);
    }

    function setLikeComment() {
        if (itemActionLike === "Y") {
            setItemActionLike("N");
            setClicked(true);
        } else {
            setItemActionLike("Y");
            setClicked(true);
        }
    }

    return (
        <>
            {
                deleteSuccess ?
                    <Fade in={deleteSuccess}>
                        <Alert severity="success" className="rounded-0">{intl.get('SUCCESS_DELETE_COMMENT')}</Alert>
                    </Fade>
                    : null
            }
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar aria-label={intl.get('LABEL_AVATAR')} alt={name} src={avatar ? avatar.source : null} />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="h6" component="h6">
                            {name}
                        </Typography>
                    }
                    secondary={
                        <div>
                            <Typography variant="body2" component="p" className="fs-md mb-1">
                                {comment}
                            </Typography>
                            <Typography variant="caption" component="p">
                                {feedDateTime(date)}
                            </Typography>
                            <div className="d-flex align-items-center">
                                <IconButton aria-label={intl.get('ARIA_LABEL_LIKE')} className={`btn-like${userLiked === "Y" ? " __active" : " __disabled"}`} onClick={() => setLikeComment()}>
                                    <Icon ic={`heart${userLiked === "Y" ? "_filled" : "_outline"}`} className="icon-xs" /> <span className="label-like ml-1">{totalLike}</span>
                                </IconButton>
                            </div>
                        </div>
                    }
                />
                {
                    canDelete ?
                        <ListItemSecondaryAction>
                            {
                                !loadingDelete ?
                                    <IconButton aria-label="delete" onClick={() => deleteComment(id)}>
                                        <Icon ic="trash" className="icon-xs" />
                                    </IconButton>
                                    :
                                    <LoadingBounce size={24} />
                            }
                        </ListItemSecondaryAction>
                        : null
                }
            </ListItem>
        </>
    )
}