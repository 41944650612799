import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from "date-fns/locale/pt-BR";
import * as yup from 'yup';
import { validateCPF } from 'validations-br';
import { maskCPF, getDateFormat } from '../../utils/utils';
import { Button, TextField, Grid, IconButton, Typography, Hidden, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import { Form, Formik } from 'formik';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import ImgIdentification from '../../dist/img/svgr/ImgIdentification';

export default function FirstAccess(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [dataFirstAccess, setDataFirstAccess] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [tokenCaptcha, setTokenCaptcha] = useState("");
    const [redirect, setRedirect] = useState(false);
    const regex = config.login_method.password.complex ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%])[a-zA-Z\d]/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;

    const validations = yup.object().shape({
        cpf: config.login_method.username.login.label === 'CPF' ?
            yup.string()
                .test("is-cpf", intl.get('ERROR_INVALID_CPF'), (value) => validateCPF(value))
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
        pincode: config.login_method.first_access.code_verify ?
            yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            : null,
        birth_date:
            yup.date().required(() => intl.get('ERROR_REQUIRED_FIELD')).typeError(() => intl.get('ERROR_INVALID_DATE')).nullable(),
        password: yup.string()
            .min(config.login_method.password.min, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .matches(regex, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .required(() => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`),
        confirm_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            .oneOf([yup.ref('password'), null], () => intl.get('ERROR_PASSWORD_NOT_MATCH'))
    });

    // --- Google Captcha ---
    const sendData = (data) => {
        setDataFirstAccess({ ...dataFirstAccess, ...data });

        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            dispatch(AuthActions.setFirstAccess({
                cpf: data.cpf,
                pincode: data.pincode,
                password: data.password,
                birth_date: getDateFormat(data.birth_date),
                auth_captcha: tokenCaptcha,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N",
            }));
            setLoading(true);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        if (config.captcha.type === 'invisible') {
            dispatch(AuthActions.setFirstAccess({
                cpf: dataFirstAccess.cpf,
                pincode: dataFirstAccess.pincode,
                password: dataFirstAccess.password,
                birth_date: getDateFormat(dataFirstAccess.birth_date),
                auth_captcha: token,
                token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
            }));
            setLoading(true);
        } else {
            setTokenCaptcha(token);
        }
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (config.login_method.first_access.enabled) {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (loading && auth.firstAccessStatus === "success") {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
            setTokenCaptcha("");

            if (config.login_method.first_access.questions && (auth.firstAccess.data && auth.firstAccess.data.questions)) {
                history.push({
                    pathname: '/first-access-questions',
                    state: {
                        data: dataFirstAccess,
                        questions: auth.firstAccess.data ? auth.firstAccess.data.questions : "",
                        date: dataFirstAccess.birth_date,
                        redirect: "N"
                    }
                });
            }

            if (!config.login_method.first_access.questions || (auth.firstAccess.data && !auth.firstAccess.data.questions)) {
                setSnackStatus("success");
                setSnackOpen(true);
                setSnackMessage(auth.firstAccess.messages);
                setTimeout(function () {
                    history.replace('/');
                }, 4000);
            }

            setLoading(false);
            auth.firstAccessStatus = "";
        }

        if (loading && auth.firstAccessStatus === "error") {
            window.grecaptcha.reset();
            setTokenCaptcha("");
            setLoading(false);
            setSnackStatus("error");
            setSnackOpen(true);
            setSnackMessage(auth.firstAccess.messages);
            auth.firstAccessStatus = "";
        }
    }, [auth, tokenCaptcha, dataFirstAccess, history, loading, snackStatus]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        !redirect ?
            <IntroLayout title={intl.get('INTRO_FIRST_ACCESS_TITLE')} appbarMobile="default">
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={2}>
                                <IconButton aria-label={intl.get('BTN_BACK')}
                                    onClick={() => history.goBack()}
                                >
                                    <Icon ic="back" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get('INTRO_FIRST_ACCESS_TITLE')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Hidden>
                    <Formik
                        validationSchema={validations}
                        initialValues={{
                            cpf: '',
                            pincode: '',
                            birth_date: null,
                            password: '',
                            confirm_password: '',
                        }}
                        onSubmit={(data) => { sendData(data) }}
                    >
                        {
                            ({ values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit} className="w-100">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} align="center">
                                            <ImgIdentification className="img-xl" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component="p" variant="body2">
                                                {intl.get("INTRO_FIRST_ACCESS_TEXT")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="cpf"
                                                autoComplete="cpf"
                                                type="text"
                                                label={intl.get(`LABEL_${config.login_method.username.login.label}`)}
                                                fullWidth
                                                required
                                                variant={config.layout.input_variant}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={config.login_method.username.login.label === "CPF" ? maskCPF(values.cpf) : values.cpf}
                                                error={(errors.cpf && touched.cpf)}
                                                helperText={(errors.cpf && touched.cpf) && errors.cpf}
                                            />
                                        </Grid>
                                        {
                                            config.login_method.first_access.code_verify ?
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="pincode"
                                                        type="text"
                                                        label={intl.get('LABEL_CHECKER_CODE')}
                                                        fullWidth
                                                        required
                                                        variant={config.layout.input_variant}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.pincode}
                                                        error={(errors.pincode && touched.pincode)}
                                                        helperText={(errors.pincode && touched.pincode) && errors.pincode}
                                                    />
                                                    <Alert icon={false} severity="info" className="rounded mt-3">
                                                        {intl.get('INFO_CHECKER_CODE')}
                                                    </Alert>
                                                </Grid>
                                                : null
                                        }
                                        <Grid item xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                                <KeyboardDatePicker
                                                    id="birth_date"
                                                    name="birth_date"
                                                    margin="none"
                                                    label={intl.get('LABEL_BIRTHDAY')}
                                                    fullWidth
                                                    required
                                                    inputVariant={config.layout.input_variant}
                                                    format="dd/MM/yyyy"
                                                    onBlur={handleBlur}
                                                    onChange={value => setFieldValue("birth_date", value)}
                                                    value={values.birth_date}
                                                    KeyboardButtonProps={{
                                                        'aria-label': intl.get('LABEL_BIRTHDAY'),
                                                    }}
                                                    okLabel={intl.get('BTN_CONFIRM')}
                                                    clearLabel={intl.get('BTN_CLEAR')}
                                                    cancelLabel={intl.get('BTN_CANCEL')}
                                                    error={(errors.birth_date && touched.birth_date)}
                                                    helperText={(errors.birth_date && touched.birth_date) && errors.birth_date}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="password"
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                label={intl.get('LABEL_PASSWORD')}
                                                fullWidth
                                                required
                                                variant={config.layout.input_variant}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                                value={values.password}
                                                error={(errors.password && touched.password)}
                                                helperText={(errors.password && touched.password) && errors.password}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="confirm_password"
                                                name="confirm_password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                label={intl.get('LABEL_RETYPE_PASSWORD')}
                                                fullWidth
                                                required
                                                variant={config.layout.input_variant}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowConfirmPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showConfirmPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                                value={values.confirm_password}
                                                error={(errors.confirm_password && touched.confirm_password)}
                                                helperText={(errors.confirm_password && touched.confirm_password) && errors.confirm_password}
                                            />
                                        </Grid>

                                        {/* Captcha */}
                                        <Grid container justify="center">
                                            {config.captcha.type === 'invisible'
                                                ? <div className="g-recaptcha"
                                                    data-sitekey={config.captcha.invisible_key}
                                                    data-callback="myCallback"
                                                    data-size="invisible">
                                                </div>
                                                : <div className="g-recaptcha mt-3"
                                                    data-sitekey={config.captcha.key}
                                                    data-callback="myCallback"
                                                >
                                                </div>
                                            }
                                        </Grid>

                                        {/* Button */}
                                        <Grid item xs={12} sm={8}>
                                            {
                                                !loading
                                                    ?
                                                    <Button
                                                        id="btn_intro_first_access_send"
                                                        variant="contained"
                                                        className="btn-block"
                                                        type="submit"
                                                        color="primary"
                                                        onSubmit={() => this.onSubmit()}
                                                    >
                                                        {intl.get('BTN_CONFIRM')}
                                                    </Button>
                                                    :
                                                    <Grid container alignItems="center" justify="center">
                                                        <LoadingBounce />
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}