import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';
import { Points } from '../../../../components/Images/Images';

export default function CardBonusLogin(props) {
    const { description } = props;

    return (
        <Card className="card card-bonus-login">
            <FeedCardHeader {...props} />
            <CardContent className="d-flex flex-row pt-0">
                <div className="card-image-square mr-3">
                    <Points type="neuros" />
                </div>
                <div>
                    <Typography variant="body2" component="p">
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
