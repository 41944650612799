import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import Intro from './components/IntroLayout';
import FormSignupEmail from './components/FormSignupEmail';
import FormSignupUsername from './components/FormSignupUsername';
import { Grid, IconButton, Typography, Hidden } from '@material-ui/core';
import { Icon } from '../../components/Images/Images';

export default function SignUp(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const type = props.location.state && props.location.state.type ? props.location.state.type : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if ((config.login_method.email.signup.enabled || config.login_method.username.signup.enabled) && origin === "intro") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount) {
            dispatch(AuthActions.getEnterprise({}));
            dispatch(AuthActions.getTermsOfUse({
                language: 'pt_BR',
                type: 'TERMS_OF_USE'
            }));
            dispatch(AuthActions.getPrivacyPolitics({
                language: 'pt_BR',
                type: 'PRIVACY_STATEMENT'
            }));
        }

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch, origin]);

    return (
        !redirect ?
            <Intro title={intl.get('INTRO_SIGNUP_TITLE')} id="intro-signup" char="intro-signup" appbarMobile={config.intro.vector_images ? "overlay" : "default"}>
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={2}>
                                <IconButton aria-label={intl.get('BTN_BACK')} onClick={() => history.goBack()}>
                                    <Icon ic="back" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get('INTRO_SIGNUP_TITLE')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Hidden>

                    {
                        type === "email" ?
                            <FormSignupEmail />
                            :
                            <FormSignupUsername />
                    }
                </Grid>
            </Intro>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}
