import { trailService } from '../../services/trail.service';

// CONST
export const Types = {
    THEMES: 'trail/THEMES',
    LIST: 'trail/LIST',
    DETAILS: 'trail/DETAILS',
    STATISTICS: 'trail/STATISTICS',
    STEPS: 'trail/STEPS',
    MATERIALS: 'trail/MATERIALS',
    START: 'trail/START',
    COMMIT: 'trail/COMMIT',
    QUESTIONS: 'trail/QUESTIONS',
    RATING_COMMENTS: 'trail/RATING_COMMENTS',
    LOADMORE_RATING_COMMENTS: 'trail/LOADMORE_RATING_COMMENTS',
    PAGE_RATING_COMMENTS: 'trail/PAGE_RATING_COMMENTS',
    RATING_SUBMIT: 'trail/RATING_SUBMIT',
    RESET_RATING_SUBMIT: 'trail/RESET_RATING_SUBMIT',
    RESET_RATING_COMMENTS: 'trail/RESET_RATING_COMMENTS',
    RESET: 'trail/RESET',
    PLAY: 'trail/PLAY',
    ERROR: 'trail/ERROR',
    CLEAR: 'trail/CLEAR',
    GETANSWERS: 'trail/GETANSWERS',
    CLEARANSWERS: 'trail/CLEARANSWERS',
    LOADING: 'trail/LOADING',
    REQUESTING: 'free/REQUESTING',
    ANSWER: 'free/ANSWER',
};

// INITIAL STATE
const initialState = {
    themes: {},
    trails: {},
    details: {},
    statistics: {},
    steps: {},
    materials: {},
    session: {},
    result: {},
    questions: {},
    ratingSubmit: {},
    ratingComments: {},
    pageRatingComments: 1,
    hasMoreRatingComments: true,
    list: [],
    finished: false,
    err: null,
    title: '',
    status: '',
    requesting: false,
    answerReturn: null
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.THEMES:
            return {
                ...state,
                themes: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LIST:
            return {
                ...state,
                trails: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.DETAILS:
            return {
                ...state,
                details: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.STATISTICS:
            return {
                ...state,
                statistics: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.STEPS:
            return {
                ...state,
                steps: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.MATERIALS:
            return {
                ...state,
                materials: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.START:
            return {
                ...state,
                session: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.COMMIT:
            return {
                ...state,
                result: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.QUESTIONS:
            return {
                ...state,
                questions: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RATING_COMMENTS:
            return {
                ...state,
                hasMoreRatingComments: action.payload.data.length >= 10 ? true : false,
                ratingComments: {
                    data: action.payload.data,
                    total: action.payload.total,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.LOADMORE_RATING_COMMENTS:
            return {
                ...state,
                hasMoreRatingComments: [...state.ratingComments.data, ...action.payload.data].length > state.ratingComments.data.length ? true : false,
                ratingComments: {
                    data: [...state.ratingComments.data, ...action.payload.data],
                    total: action.payload.total,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.PAGE_RATING_COMMENTS:
            return {
                ...state,
                pageRatingComments: action.payload.pageRatingComments
            }
        case Types.RATING_SUBMIT:
            return {
                ...state,
                ratingSubmit: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET_RATING_COMMENTS:
            return {
                ...state,
                ratingComments: action.payload.ratingComments,
                pageRatingComments: 1,
                hasMoreRatingComments: true
            }
        case Types.RESET_RATING_SUBMIT:
            return {
                ...state,
                ratingSubmit: action.payload.ratingSubmit
            }
        case Types.RESET:
            return {
                ...state,
                themes: action.payload.themes,
                trails: action.payload.trails,
                details: action.payload.details,
                statistics: action.payload.statistics,
                steps: action.payload.steps,
                materials: action.payload.materials,
                session: action.payload.session,
                result: action.payload.result,
                questions: action.payload.questions,
                ratingSubmit: action.payload.ratingSubmit,
                ratingComments: action.payload.ratingComments,
                pageRatingComments: action.payload.pageRatingComments,
                hasMoreRatingComments: action.payload.hasMoreRatingComments,
            }
        case Types.LOADING:
            return {
                ...state,
                status: action.payload.status,
            }
        case Types.PLAY:
            return {
                ...state,
                status: action.payload.status,
                trails: action.payload.trails,
                finished: action.payload.finished,
                err: action.payload.err,
            }
        case Types.ANSWER:
            return {
                ...state,
                answerReturn: action.payload.session,
                requesting: action.payload.requesting,
            }
        case Types.ERROR:
            return {
                ...state,
                err: action.payload.err,
            }
        case Types.CLEAR:
            return {
                ...state,
                trails: action.payload.trails,
                err: action.payload.err,
                finished: action.payload.finished,
                title: action.payload.title,
                status: action.payload.status,
                requesting: action.payload.requesting,
                answerReturn: action.payload.answerReturn
            }
        case Types.REQUESTING:
            return {
                ...state,
                requesting: action.payload.requesting,
            }
        case Types.GETANSWERS:
            return {
                ...state,
                answers: action.payload.answers,

            }
        case Types.CLEARANSWERS:
            return {
                ...state,
                answers: action.payload.answers,
            }
        default:
            return state
    }
}

// ACTIONS
function getThemesList(params) {
    return dispatch => {
        return trailService.themesList(params).then(data => {
            dispatch({
                type: Types.THEMES,
                payload: {
                    data: data.themes,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.THEMES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTrailList(params) {
    return dispatch => {
        return trailService.list(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: data.courses,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getDetails(params) {
    return dispatch => {
        return trailService.details(params).then(data => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    data: data.course,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStatistics(params) {
    return dispatch => {
        return trailService.statistics(params).then(data => {
            dispatch({
                type: Types.STATISTICS,
                payload: {
                    data: data.stats,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.STATISTICS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStepsList(params) {
    return dispatch => {
        return trailService.stepsList(params).then(data => {
            dispatch({
                type: Types.STEPS,
                payload: {
                    data: data.course,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.STEPS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getMaterialsList(params) {
    return dispatch => {
        return trailService.materialsList(params).then(data => {
            dispatch({
                type: Types.MATERIALS,
                payload: {
                    data: data.course,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.MATERIALS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStepStart(params) {
    return dispatch => {
        return trailService.stepStart(params).then(data => {
            dispatch({
                type: Types.START,
                payload: {
                    data: data.session,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.START,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStepCommit(params) {
    return dispatch => {
        return trailService.stepCommit(params).then(data => {
            dispatch({
                type: Types.COMMIT,
                payload: {
                    data: data.session,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COMMIT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getStepFlashcardsQuestion(params) {
    return dispatch => {
        return trailService.stepFlashcardsQuestion(params).then(data => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: data.session,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.QUESTIONS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRatingComments(params) {
    return dispatch => {
        return trailService.ratingComments(params).then(data => {
            dispatch({
                type: Types.RATING_COMMENTS,
                payload: {
                    data: data.list_feedback,
                    total: data.total,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.RATING_COMMENTS,
                payload: {
                    data: [],
                    total: 0,
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getLoadmoreRatingComments(params) {
    return dispatch => {
        return trailService.ratingComments(params).then(data => {
            dispatch({
                type: Types.LOADMORE_RATING_COMMENTS,
                payload: {
                    data: data.list_feedback,
                    total: data.total,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.LOADMORE_RATING_COMMENTS,
                payload: {
                    data: [],
                    total: 0,
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setPageRatingComments(params) {
    return dispatch => {
        dispatch({
            type: Types.PAGE_RATING_COMMENTS,
            payload: {
                pageRatingComments: params.page,
            }
        });
    }
}

function setRatingSubmit(params) {
    return dispatch => {
        return trailService.ratingSubmit(params).then(data => {
            dispatch({
                type: Types.RATING_SUBMIT,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.RATING_SUBMIT,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function resetRatingComments() {
    return dispatch => {
        dispatch({
            type: Types.RESET_RATING_COMMENTS,
            payload: {
                ratingComments: {},
            }
        });
    }
}

function resetRatingSubmit() {
    return dispatch => {
        dispatch({
            type: Types.RESET_RATING_SUBMIT,
            payload: {
                ratingSubmit: {},
            }
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET,
            payload: {
                themes: {},
                trails: {},
                details: {},
                statistics: {},
                steps: {},
                materials: {},
                session: {},
                result: {},
                questions: {},
                ratingSubmit: {},
                ratingComments: {},
                pageRatingComments: 1,
                hasMoreRatingComments: true,
            }
        });
    }
}

function play(params) {
    return dispatch => {
        return trailService.play(params).then(data => {
            dispatch({
                type: Types.PLAY,
                payload: {
                    status: 'finished',
                    finished: true,
                    trails: data.session,
                    err: null,
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    err: err,
                }
            });
        });
        /* dispatch({
            type: Types.LOADING,
            payload: {
                status: 'initializing'
            }
        });
        if (params.status === 'initializing') {
            delete params.status;
            return trailService.play(params).then(data => {
                dispatch({
                    type: Types.PLAY,
                    payload: {
                        status: 'loading',
                        finished: true,
                        trails: data.session,
                        err: null,
                    }
                });
            }).catch(err => {
                dispatch({
                    type: Types.ERROR,
                    payload: {
                        err: err,
                    }
                });
            }).finally(() => {
                dispatch({
                    type: Types.LOADING,
                    payload: {
                        status: 'finished'
                    }
                });
            })
        } */
    }
}



function clear() {
    return dispatch => {
        dispatch({
            type: Types.CLEAR,
            payload: {
                trails: [],
                list: [],
                finished: false,
                err: null,
                title: '',
                status: '',
                requesting: false,
                answerReturn: null
            }
        });
    }
}

function mockExamAnswers(params) {

    return dispatch => {
        return trailService.mockExamAnswers(params).then(data => {
            dispatch({
                type: Types.GETANSWERS,
                payload: {
                    answers: data.session,
                }
            })
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    err: err,
                }
            });
        })
    }
}

function answer(params) {
    return dispatch => {
        dispatch({
            type: Types.REQUESTING,
            payload: {
                requesting: true,
            }
        });
        return trailService.answer(params).then(data => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    session: data.session,
                    requesting: false,
                }
            })
        }).catch(err => {
            dispatch({
                type: Types.ANSWER,
                payload: {
                    error: err.error,
                    errorStatus: err && err.localized_messages ? err.messages[0] : "Undefined error",
                    errorMessage: err && err.localized_messages ? err.localized_messages[0] : "Undefined error",
                }
            });
        })
    }
}

function clearAnswers() {

    return dispatch => {
        dispatch({
            type: Types.CLEARANSWERS,
            payload: {
                answers: null,
            }
        });
    }
}

export const actions = {
    getThemesList,
    getTrailList,
    getDetails,
    getStatistics,
    getStepsList,
    getMaterialsList,
    getStepStart,
    getStepCommit,
    getStepFlashcardsQuestion,
    getRatingComments,
    getLoadmoreRatingComments,
    setPageRatingComments,
    setRatingSubmit,
    resetRatingComments,
    resetRatingSubmit,
    reset,


    play,
    answer,
    clear,
    mockExamAnswers,
    clearAnswers
}
