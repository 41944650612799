import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#F08542',
            main: '#ed6d1c',
            dark: '#D05A11',
            contrastText: '#fff',
        },
        secondary: {
            light: '#1759B5',
            main: '#134790', 
            dark: '#0E356C',
            contrastText: '#fff',
        },
        error: {
            light: '#DA1B1B',
            main: '#b71817',
            dark: '#911212',
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
