import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import {
    Container, Grid, Typography, Button
} from '@material-ui/core';
import GameHeader from './partials/GameHeader';
import { Icon } from '../Images/Images';

// Função para criar a palavra secreta
function createSecretWord(letters = [], wordLengthArr) {
    const wordLength = wordLengthArr.reduce((acc, curr) => acc + curr, 0);
    const secretArr = Array(wordLength).fill('');
    letters.forEach((letter, idx) => (secretArr[idx] = letter));

    let splitArr = [];
    let offset = 0;

    wordLengthArr.forEach((size) => {
        splitArr.push(secretArr.slice(offset, offset + size));
        offset += size;
    });

    return splitArr;
}

// Função para buscar os índices e letras da resposta
function findAnswerIndicesAndLetters(userAnswer, answers) {
    const cleanAnswer = userAnswer.replace(/\s+/g, '').toUpperCase();
    let indices = [];
    let letters = [];

    cleanAnswer.split('').forEach((char) => {
        const index = answers.findIndex(
            (item, i) => item.answer === char && !indices.includes(i)
        );

        if (index !== -1) {
            indices.push(index);
            letters.push(char);
        }
    });

    return { indices, letters };
}

const SecretWord = ({ children, data, send, onlyView }) => {
    const { answers, character_length, user_answer } = data.question;

    const wordLengthArr = character_length.map(Number);
    const totalWordLength = wordLengthArr.reduce((a, b) => a + b, 0);

    const [typedWord, setTypedWord] = useState([]);
    const [activeKey, setActiveKey] = useState([]);
    const [word, setWord] = useState([]);
    const [isInitialized, setInitialized] = useState(false);

    const isEnabledBackspace = typedWord.length > 0;
    const isEnabledSend = typedWord.length === totalWordLength;

    // Efeito para inicializar estados com a resposta do usuário
    useEffect(() => {
        if (user_answer && !isInitialized) {
            const { indices, letters } = findAnswerIndicesAndLetters(user_answer, answers);
            setActiveKey(indices);
            setTypedWord(letters);
            setWord(createSecretWord(letters, wordLengthArr));
            setInitialized(true);
        }

        if (!user_answer && !isInitialized) {
            setWord(createSecretWord([], wordLengthArr));
            setInitialized(true);
        }
    }, [user_answer, answers, wordLengthArr, isInitialized]);

    // Função para adicionar letra
    function typeWord(letter, index) {
        if (typedWord.length < totalWordLength) {
            const updatedWord = [...typedWord, letter];
            setActiveKey((prev) => [...prev, index]);
            setTypedWord(updatedWord);
            setWord(createSecretWord(updatedWord, wordLengthArr));
        }
    }

    // Função para apagar a última letra digitada
    function backspace() {
        if (typedWord.length > 0) {
            const updatedWord = typedWord.slice(0, -1);
            setActiveKey((prev) => prev.slice(0, -1));
            setTypedWord(updatedWord);
            setWord(createSecretWord(updatedWord, wordLengthArr));
        }
    }

    // Enviar a palavra secreta para o WS
    function sendSecretWordAnswer() {
        const mergedWords = word.map((arr) => arr.join('')).join(' ');
        send(mergedWords, data.question.id_question);
    }

    const SecretWordHeader = () => (
        <Grid item xs={12}>
            <Grid container className="qr-game-question-secret-word" justify="center" spacing={2}>
                {
                    character_length.map((num, index) => (
                        <div key={index} className="word-content">
                            <Grid container justify="center">
                                {
                                    word.length > 0 &&
                                    word[index].map((letter, letterIndex) => (
                                        <div key={letterIndex} className="letter">
                                            <Typography variant="h3" component="span">
                                                {letter}
                                            </Typography>
                                        </div>
                                    ))
                                }
                            </Grid>
                        </div>
                    ))}
            </Grid>
        </Grid>
    )

    return (
        <div className={`w-100${config.layout.points.neuros && data.help && !onlyView ? " __game-bar" : ""}`}>
            <GameHeader data={data} questionCustom={<SecretWordHeader />}>
                {children}
            </GameHeader>
            <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
                <Grid container justify="center">
                    <div className="answer-type-secret-word">
                        {answers.map((item, index) => (
                            <Button
                                disableRipple
                                key={index}
                                variant="outlined"
                                className={`btn-card __answer${activeKey.includes(index) ? ' active' : ''}`}
                                disabled={activeKey.includes(index)}
                                onClick={() => typeWord(item.answer, index)}
                            >
                                <div className="answer-type-keyboard">
                                    <Typography variant="h3" component="p">
                                        {item.answer}
                                    </Typography>
                                </div>
                            </Button>
                        ))}

                        <Button
                            disableRipple
                            className="btn-backspace btn-card __answer"
                            variant="outlined"
                            onClick={backspace}
                            disabled={!isEnabledBackspace}
                        >
                            <Icon ic="backspace" />
                        </Button>

                        <Button
                            disableRipple
                            className={`btn-check btn-card __answer${isEnabledSend ? ' active' : ''}`}
                            variant="outlined"
                            onClick={sendSecretWordAnswer}
                            disabled={!isEnabledSend}
                        >
                            <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                        </Button>
                    </div>
                </Grid>
            </Container>
        </div>
    )
}

export default SecretWord