import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as CodeRedeemActions } from '../../redux/ducks/code_redeem';
import {
    AppBar, Toolbar, Container, Grid, IconButton,
    Typography, TextField, Button, useMediaQuery
} from '@material-ui/core';
import { Formik } from 'formik';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import ImgHeaderCodeRedeem from '../../dist/img/svgr/ImgHeaderCodeRedeem';

export default function CodeRedeem(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const codeRedeem = useSelector(state => state.codeRedeem.codeRedeem);
    const auth = useSelector(state => state.auth);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [loadingValidation, setLoadingValidation] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackStatus, setSnackStatus] = useState("error");
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_CODE_REDEEM');

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.REDEEM_CODE && auth.modules.REDEEM_CODE.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    async function sendCodeRedeem(code) {
        setLoadingValidation(true);
        await dispatch(CodeRedeemActions.sendCodeRedeem({
            redeem_code: code
        }));
        setLoadingValidation(false);
    }

    if (codeRedeem && codeRedeem.load === "finished") {
        if (codeRedeem.error) {
            setSnackMessage(codeRedeem.messages);
            setSnackStatus("error");
            setSnackOpen(true);
            dispatch(CodeRedeemActions.reset());
        } else {
            setSnackMessage(codeRedeem.data.message);
            setSnackStatus("success");
            setSnackOpen(true);

            switch (codeRedeem.data.type) {
                // Neuros
                case "NE":
                    auth.user.neuros = codeRedeem.data.user.neuros;
                    localStorage.setItem('auth.state.user', JSON.stringify(auth.user));
                    break;

                // Qi$
                case "QI":
                    auth.user.qi_credit = codeRedeem.data.user.qi_credit;
                    localStorage.setItem('auth.state.user', JSON.stringify(auth.user));
                    break;

                // Premium
                case "PM":
                    auth.user.premium = codeRedeem.data.user.premium;
                    auth.user.premium_active = codeRedeem.data.user.premium_active;
                    localStorage.setItem('auth.state.user', JSON.stringify(auth.user));
                    break;
                default:
            }

            dispatch(CodeRedeemActions.reset());
        }

        setTimeout(function () {
            setSnackOpen(false);
        }, 5000);
    }

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('CODE_REDEEM_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("CODE_REDEEM_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="code-redeem" bottomNavigation={bottomMenuItem} hideSidebar>
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                                <Container maxWidth="md" className="pt-3 pb-4">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} sm={10} align="center">
                                            <ImgHeaderCodeRedeem />
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <Typography variant="body2" component="p" className="fs-md">
                                                {intl.get("CODE_REDEEM_TEXT")}
                                            </Typography>
                                        </Grid>
                                        <Formik
                                            initialValues={{
                                                code: ''
                                            }}
                                        >
                                            {
                                                ({ values, handleBlur, handleChange }) => (
                                                    <>
                                                        <Grid item xs={12} sm={10}>
                                                            <TextField
                                                                fullWidth
                                                                name="code"
                                                                type="text"
                                                                label={intl.get("LABEL_CODE")}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.code}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={10} align="center">
                                                            {
                                                                loadingValidation
                                                                    ?
                                                                    <LoadingBounce size={24} />
                                                                    :
                                                                    <Button
                                                                        id="btn_code_redeem"
                                                                        disabled={values.code.length === 0}
                                                                        variant="contained"
                                                                        onClick={() => sendCodeRedeem(values.code)}
                                                                        className="btn-block-xs w-50"
                                                                    >
                                                                        {intl.get("BTN_REDEEM")}
                                                                    </Button>
                                                            }
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </Formik>
                                        <SnackBar
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            open={snackOpen}
                                            message={snackMessage}
                                            status={snackStatus}
                                            time={5}
                                            closeSnack={() => setSnackOpen(false)}
                                        />
                                    </Grid>
                                </Container>
                            </div>
                        </QrContent>
                    </div>
                </div>
            }
        </div>
    )
}