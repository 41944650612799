import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';

export default function CardChampMonth(props) {
    const { description, image } = props;

    return (
        <Card className="card card-champ-month">
            <FeedCardHeader {...props} />
            <CardContent className="d-flex flex-row pt-0">
                <img src={image} className="card-image-circle avatar mr-3" alt={description} />
                <div>
                    <Typography variant="body2" component="p">
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
