import { classroomService } from '../../services/classroom.service';
import { examService } from '../../services/exam.service';
import { freeService } from '../../services/free.service';
import { highlightService } from '../../services/highlight.service';
import { knowmoreService } from '../../services/knowmore.service';
import { scormService } from '../../services/scorm.service';
import { trailService } from '../../services/trail.service';

// CONST
export const Types = {
    CLASSROOM: 'home/CLASSROOM',
    EXAM: 'home/EXAM',
    FREE: 'home/FREE',
    HIGHLIGHT_LIST: 'home/HIGHLIGHT_LIST',
    HIGHLIGHT_DETAILS: 'home/HIGHLIGHT_DETAILS',
    KNOWMORE_LIST: 'home/KNOWMORE_LIST',
    KNOWMORE_DETAILS: 'home/KNOWMORE_DETAILS',
    SCORM: 'home/SCORM',
    TRAIL: 'home/TRAIL',
    ONGOING_COURSES: 'home/ONGOING_COURSES',
    RESET: 'home/RESET',
};

// INITIAL STATE

const initialState = {
    classroom: {},
    exams: {},
    free: {},
    highlights: {},
    highlightDetails: {},
    knowmore: {},
    knowmoreDetails: {},
    scorms: {},
    trails: {},
    ongoing: {}
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.CLASSROOM:
            return {
                ...state,
                classroom: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.EXAM:
            return {
                ...state,
                exams: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.FREE:
            return {
                ...state,
                free: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.HIGHLIGHT_LIST:
            return {
                ...state,
                highlights: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.HIGHLIGHT_DETAILS:
            return {
                ...state,
                highlightDetails: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.KNOWMORE_LIST:
            return {
                ...state,
                knowmore: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.KNOWMORE_DETAILS:
            return {
                ...state,
                knowmoreDetails: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SCORM:
            return {
                ...state,
                scorms: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.TRAIL:
            return {
                ...state,
                trails: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ONGOING_COURSES:
            return {
                ...state,
                ongoing: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                classroom: {},
                exams: {},
                free: {},
                highlights: {},
                highlightDetails: {},
                knowmore: {},
                knowmoreDetails: {},
                scorms: {},
                trails: {},
                ongoing: {}
            }
        default:
            return state
    }
}

// ACTIONS
function getClassroomList(params) {
    return dispatch => {
        return classroomService.listUpcoming(params).then(data => {
            dispatch({
                type: Types.CLASSROOM,
                payload: {
                    data: data.list_training_class,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.CLASSROOM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExamList(params) {
    return dispatch => {
        return examService.getExamList(params).then(data => {
            dispatch({
                type: Types.EXAM,
                payload: {
                    data: data.exams,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.EXAM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getFreeList(params) {
    return dispatch => {
        return freeService.getList(params).then(data => {
            dispatch({
                type: Types.FREE,
                payload: {
                    data: data.categories,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FREE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getHighlightList(params) {
    return dispatch => {
        return highlightService.highlightList(params).then(data => {
            dispatch({
                type: Types.HIGHLIGHT_LIST,
                payload: {
                    data: data.highlights,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.HIGHLIGHT_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getHighlightDetails(params) {
    return dispatch => {
        return highlightService.getHighlight(params).then(data => {
            dispatch({
                type: Types.HIGHLIGHT_DETAILS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.HIGHLIGHT_DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getKnowmoreList(params) {
    return dispatch => {
        return knowmoreService.knowMoreList(params).then(data => {
            dispatch({
                type: Types.KNOWMORE_LIST,
                payload: {
                    data: data.know_more,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.KNOWMORE_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getKnowmoreDetails(params) {
    return dispatch => {
        return knowmoreService.getKnowMore(params).then(data => {
            dispatch({
                type: Types.KNOWMORE_DETAILS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.KNOWMORE_DETAILS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormThemesList(params) {
    return dispatch => {
        return scormService.themes(params).then(data => {
            dispatch({
                type: Types.SCORM,
                payload: {
                    data: data.categories,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SCORM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormList(params) {
    return dispatch => {
        return scormService.list(params).then(data => {
            dispatch({
                type: Types.SCORM,
                payload: {
                    data: data.scorms,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SCORM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTrailThemesList(params) {
    return dispatch => {
        return trailService.themesList(params).then(data => {
            dispatch({
                type: Types.TRAIL,
                payload: {
                    data: data.themes,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TRAIL,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTrailList(params) {
    return dispatch => {
        return trailService.list(params).then(data => {
            dispatch({
                type: Types.TRAIL,
                payload: {
                    data: data.courses,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TRAIL,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getOngoingCoursesList(params) {
    return dispatch => {
        return trailService.list(params).then(data => {
            dispatch({
                type: Types.ONGOING_COURSES,
                payload: {
                    data: data.courses,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ONGOING_COURSES,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getClassroomList,
    getExamList,
    getFreeList,
    getHighlightList,
    getHighlightDetails,
    getKnowmoreList,
    getKnowmoreDetails,
    getScormThemesList,
    getScormList,
    getTrailThemesList,
    getTrailList,
    getOngoingCoursesList,
    reset,
}
