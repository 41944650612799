import * as React from "react";
import config from "../../../config";
import AssaiCharCourseSubcategoryFinished from '../projects/assai/chars/CharCourseSubcategoryFinished';

function CharCourseSubcategoryFinished(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={164} height={164} viewBox="0 0 164 164" {...props}>
                <defs>
                    <path id="prefix__a" d="M0 0h164v164H0z" />
                    <path id="prefix__c" d="M.252.315h77.043v39.514H.252z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <g mask="url(#prefix__b)">
                        <path
                            d="M38.204 82.503l13.185 69.505S52.745 158 61.406 158c8.904 0 9.762-6.259 9.762-6.259l1.979-13.895 16.794.033 2.435 13.196S93.926 158 102.561 158c7.159 0 10.083-6.525 10.083-6.525l11.138-68.972H38.204z"
                            fill="#11496F"
                        />
                        <path
                            d="M51.293 99.616s-17.732-8.04-23.055-13.614C18.525 75.835 8.29 43.38 8.29 43.38S4.87 27.04 21.564 26.16c3.977-.21 17.533.88 17.533.88s1.922.514 2.047 2.321c.123 1.784-1.462 2.404-1.462 2.404l-8.77 2.497s2.907 2.636 2.923 5.278c.013 2.309-3.508 3.913-3.508 3.913l20.967 35.091v21.072zm61.414 0c0-1.116 17.731-7.647 23.055-13.253 9.713-10.229 19.95-42.88 19.95-42.88s3.42-16.437-13.275-17.324c-3.977-.21-17.533.887-17.533.887s-1.921.517-2.047 2.335c-.123 1.794 1.462 2.418 1.462 2.418l8.77 2.511s-2.907 2.653-2.923 5.311c-.013 2.322 3.508 3.936 3.508 3.936L112.707 78.86v20.756z"
                            fill="#F9C623"
                        />
                        <path
                            fill="#000"
                            opacity={0.08}
                            d="M133.475 34.185l10.443 1.402-12.081.612z"
                        />
                        <path
                            d="M40.01 55.325L17.062 69.976s7.865 13.207 11.916 16.839c4.634 4.155 11.263 6.779 11.263 6.779 2.003 18.83 3.437 28.947 4.302 30.35.865 1.404 1.56 2.23 2.084 2.48 11.257 4.944 22.88 7.417 34.87 7.417 8.52 0 20.634-2.543 36.343-7.63.659-.21 1.386-.844 2.182-1.899 1.194-1.583 1.641-7.674 3.351-24.887.131-1.318.364-3.407.697-6.269 0 0 9.174-3.936 12.595-7.435 5.031-5.147 9.268-12.683 9.268-12.683l-21.9-15.527-12.745 19.026h-56.06L40.011 55.325z"
                            fill="#46CA64"
                        />
                        <path
                            d="M43.549 49.271s-.272 23.305-.31 23.411c.091 3.832 3.059 14.246 15.376 14.373l46.21.481s14.825-.923 15.922-13.516c.035.213-.002-27.755-.002-27.755H44.067"
                            fillOpacity={0.1}
                            fill="#000"
                        />
                        <path
                            d="M43.549 46.251s-.272 23.306-.31 23.411c.091 3.832 3.059 14.246 15.376 14.373l46.21.482S119.65 83.593 120.747 71c.035.213-.002-27.755-.002-27.755H44.067"
                            fill="#F9C623"
                        />
                        <g transform="translate(43.238 6)">
                            <mask id="prefix__d" fill="#fff">
                                <use xlinkHref="#prefix__c" />
                            </mask>
                            <path
                                d="M51.603.543c3.65.397 2.109.116 4.645.67 13.667 3.52 21.138 20.5 21.046 34.125l-.031 4.476c.144.093-77.035-.276-77.01-.412l.03-4.75C.378 21.027 8.078 4.182 21.792.907c3.95-.944 8.59-.815 12.372.782 2.05.865 3.67 2.15 4.826 3.768 1.181-1.598 2.816-2.853 4.877-3.682C46.65.629 48.693.648 51.603.543"
                                fill="#2D2F2B"
                                mask="url(#prefix__d)"
                            />
                        </g>
                        <path
                            d="M115.727 41.232l-32.038-.283.152-22.064c.024-3.644 1.741-5.676 4.326-6.705 2.967-1.182 6.728-1.167 9.795-.386 2.532.646 4.807 1.866 6.819 3.503-1.109 1.914-3.088 2.622-5.261 2.099-1.277-.308-2.44-.708-3.766-.83-4.329-.394-8.424 2.569-9.567 6.906-.525 1.876.406 3.382 1.49 3.65 1.191.296 2.42-.237 2.796-2.078.92-4.316 4.825-3.802 8.015-3.035 3.76.908 7.404-.36 9.62-3.358a29.426 29.426 0 012.816 4.152c-1.045.728-1.964 1.72-2.702 2.962-.694 1.171-1.186 2.48-1.905 3.615-1.369 2.171-3.639 2.721-5.91 1.76-1.215-.514-2.303-1.1-3.592-1.435-4.215-1.101-8.715 1.15-10.508 5.244-.806 1.765-.118 3.406.912 3.85 1.131.486 2.428.162 3.08-1.594 1.57-4.11 5.348-2.962 8.38-1.68 4.299 1.819 8.912.508 11.474-3.549.76-1.206 1.256-2.52 1.972-3.728.257-.434.546-.802.862-1.104 1.838 4.63 2.77 9.638 2.74 14.088m-68.462-.604l32.039.282.152-22.064c.024-3.644-1.665-5.705-4.237-6.781-2.947-1.233-6.71-1.285-9.787-.558-2.54.601-4.833 1.781-6.866 3.382 1.083 1.933 3.05 2.676 5.231 2.192 1.282-.286 2.45-.665 3.776-.764 4.335-.318 8.39 2.717 9.472 7.074.5 1.885-.451 3.375-1.54 3.625-1.195.274-2.417-.281-2.766-2.128-.861-4.333-4.774-3.888-7.973-3.176-3.773.84-7.398-.492-9.574-3.528a29.333 29.333 0 00-2.872 4.102c1.034.747 1.941 1.754 2.662 3.01.677 1.182 1.153 2.5 1.853 3.647 1.343 2.196 3.602 2.786 5.887 1.864 1.221-.491 2.317-1.058 3.611-1.371 4.23-1.027 8.699 1.305 10.434 5.429.783 1.779.073 3.407-.964 3.833-1.137.466-2.43.12-3.058-1.648-1.513-4.137-5.306-3.056-8.356-1.827-4.322 1.742-8.917.35-11.422-3.752-.746-1.219-1.222-2.542-1.922-3.762a5.21 5.21 0 00-.846-1.119c-1.903 4.597-2.903 9.587-2.934 14.038"
                            fill="#FFF"
                        />
                        <path
                            d="M72.659 60.358c-.421 0-.845-.144-1.185-.437-4.66-4.004-8.488-5.212-16.364-5.145h-.015c-.981 0-1.78-.769-1.789-1.724-.007-.96.787-1.744 1.775-1.752 8.812-.068 13.383 1.394 18.764 6.018.74.636.81 1.734.155 2.453a1.809 1.809 0 01-1.341.587m25.12 7.044c-6.116.102-11.151-4.548-11.247-10.385-.096-5.837 4.784-10.649 10.9-10.75 6.115-.101 11.151 4.548 11.247 10.384.095 5.837-4.784 10.65-10.9 10.751"
                            fill="#2D2F2B"
                        />
                        <path
                            d="M100.21 60.357c-3.058.047-5.576-2.167-5.624-4.945-.048-2.779 2.392-5.07 5.45-5.12 3.057-.047 5.575 2.167 5.623 4.945.048 2.779-2.392 5.071-5.45 5.12m-3.61 10.993s-21.46 1.617-35.192-8.973c-.473-.365 2.425 15.882 19.518 18.113 11.495-.457 15.054-8.57 15.674-9.14"
                            fill="#FFF"
                        />
                        <path
                            d="M96.599 71.336S79.745 83.372 61.41 62.38c-.394-.451 1.214 14.4 15.95 18.21C94.41 85 95.98 71.906 96.6 71.337"
                            fill="#2D2F2B"
                        />
                        <path
                            fill="#000"
                            opacity={0.08}
                            d="M37.197 92.616l7.048-17.159-5.51 18.12zm88.599-.114L117.74 77.47l5.825 16.106zM30.447 33.179l-10.365 1.457 12.081.556z"
                        />
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCourseSubcategoryFinished {...props} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (gender === "male") {
            return (
                <svg width={164} height={164} viewBox="0 0 164 164" {...props}>
                    <defs>
                        <path id="prefix__a" d="M0 0h164v164H0z" />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <mask id="prefix__b" fill="#fff">
                            <use xlinkHref="#prefix__a" />
                        </mask>
                        <g mask="url(#prefix__b)">
                            <path
                                d="M98.38 121.09s8.809 17.938 15.376 31.046l-5.305 4.2s-6.215-12.382-12.64-25.145c-2.505 1.234-6.626 2.232-13.313 2.51-6.8-.124-11.235-1.001-14.095-2.172-5.065 11.592-10.951 26.908-10.951 26.908l-5.44-4.094c4.862-11.397 10.023-24.914 11.505-28.828a3.3 3.3 0 01.137-.556.375.375 0 01.134-.165l.149-.391.252.185c3.285-1.248 20.598-1.602 29.57-.82l4.62-2.679z"
                                className="svg-char-pants"
                            />
                            <path
                                d="M70.623 99.58l-1.015-6.939S40.23 75.328 27.3 103.881l9.01 3.307s7.394-20.484 34.312-7.608"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M37.604 109.755c.556-5.082-1.71-7.207-5.9-8.426-3.751-1.092-7.864 1.824-8.534 5.287-.859 4.442 1.623 7.053 5.416 7.992 4.024.996 8.568-.746 9.018-4.853m88.61-46.213s.855-3.233-.297-7.565c-1.512-5.683-6.549-3.032-4.992 1.172 1.72 4.65 1.118 8.19 1.118 8.19l4.17-1.797z"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M92.283 90.063l1.428 6.714s34.627 8.678 39.059-22.352l-9.576-.655s-1.466 21.131-30.91 16.293"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M119.262 70.135c1.097 6.23 4.524 7.97 9.922 7.97 4.834-.002 8.71-4.887 8.309-9.232-.516-5.574-5.377-6.655-10.206-6.458-5.125.208-8.91 2.687-8.025 7.72"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M126.767 69.39c.08-2.04-3.19-2.515-4.838-2.624.146-.164.306-.323.49-.472 0 0 6.317.197 5.88 3.53-.602 4.582-5.441 3.28-7.021 2.49 0 0-.044-.116-.102-.307 2.173.503 5.462.65 5.591-2.618"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M107.908 155.175s4.21-.905 5.229-4.3c0 0 4.548-2.178 6.692-.14 0 0 .203 1.984-3.71 5.13-2.771 2.228-6.19 3.582-6.344 3.428 0 0-1.125-2.148-1.867-4.118M58.087 156.8s-4.31-.427-5.535-3.752c0 0-4.672-1.898-6.687.268 0 0-.537 1.342 4.259 4.852 3.146 2.302 6.1 2.993 6.241 2.762 0 0 .588-1.15 1.722-4.13"
                                className="svg-char-shoes"
                            />
                            <path
                                d="M92.944 90.178s4.604 17.035 7.213 34.225c0 0-5.448 3.284-17.935 3.284-6.81 0-14.649-1.036-18.572-2.539 0 0-.045-10.614 1.86-32.521 0 0 4.102.73 12.757.73 8.654 0 14.677-3.18 14.677-3.18"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M81.925 103.11c.685-2.03 2.683-1.998 3.505.08 1.313 3.318 4.892 14.122 4.804 14.93-.168 1.526-4.057 6.415-5.531 6.11-1.697-.351-6.172-5.411-6.153-6.417.044-2.518 2.128-10.998 3.375-14.703zm1.254-9.585c1.41 0 2.623 1.437 2.711 3.208.088 1.771-.984 3.208-2.394 3.208-1.409 0-2.623-1.437-2.711-3.208-.088-1.771.983-3.208 2.394-3.208z"
                                className="svg-char-necktie"
                            />
                            <path
                                d="M133.16 23.012l6.027.104a1.313 1.313 0 01-.022 2.628h-.023l-6.028-.105a1.314 1.314 0 01-1.29-1.337 1.323 1.323 0 011.335-1.29zm4.107-8.3c.392.61.215 1.422-.394 1.815l-5.256 3.378a1.314 1.314 0 01-1.42-2.21l5.255-3.378a1.313 1.313 0 011.815.395zm-7.31-6.864c.657.304.946 1.084.642 1.743l-2.282 4.947a1.313 1.313 0 11-2.386-1.1l2.281-4.947a1.314 1.314 0 011.744-.643z"
                                fillOpacity={0.8}
                                fill="#000"
                            />
                            <path
                                d="M92.59 50.899c-.254 9.006 5.119 13.255 12.298 13.457 7.18.203 12.112-12.955 12.547-18.87.709-9.627-4.355-16.611-11.535-16.814-7.18-.203-13.055 13.22-13.31 22.227"
                                className={`svg-char-${color}-hair-shade-2`}
                            />
                            <path
                                d="M41.25 77.85c3.13 5.347 9.06 6.549 12.5 5.336 3.426-1.212 1.607-4.932-.062-9.722-1.667-4.79-5.805-7.692-9.24-6.479-3.433 1.213-5.78 6.45-3.197 10.864"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M44.754 76.972c1.022 2.69 5.071 4.095 7.22 3.555 2.15-.539 1.274-2.722.56-5.508-.71-2.786-3.032-4.605-5.181-4.065s-3.793 2.88-2.6 6.018"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M116.199 68.045c-1.289 4.3-5.128 7.286-7.905 7.042-2.765-.248-2.273-3.67-2.131-7.931.14-4.26 2.504-7.516 5.276-7.268 2.771.246 5.974 4.119 4.76 8.157"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M113.489 67.199c.104 2.424-2.812 5.315-4.497 5.34-1.686.024-1.537-1.942-1.64-4.365-.108-2.423 1.176-4.405 2.861-4.428 1.685-.024 3.172 1.032 3.276 3.453"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M91.34 92.036s26.517-8.85 18.776-44.846c-7.74-35.998-31.798-34.675-43.89-31.764-12.092 2.91-23.552 11.844-23.664 22.85-.386 38.109 13.87 49.605 21.848 53.317 4.368 2.033 16.194 4.578 26.93.443"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M60.477 64.657c.556 0 1.036-.426 1.107-1.008.298-2.454 2.012-3.978 4.474-3.978 2.196 0 4.031 1.441 4.91 3.856.216.595.86.896 1.436.673.578-.223.87-.886.654-1.482-1.218-3.35-3.835-5.349-7-5.349-3.566 0-6.255 2.409-6.69 5.995-.076.63.358 1.205.97 1.284.047.006.094.009.14.009m31.769-4.92c-.106 3.753 1.906 6.857 4.496 6.93 2.589.073 4.774-2.912 4.88-6.665.106-3.754-1.907-6.857-4.497-6.93-2.589-.073-4.773 2.911-4.879 6.665"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M94.001 56.372c-.037 1.335.678 2.439 1.6 2.465.92.026 1.698-1.036 1.735-2.371.038-1.336-.678-2.44-1.599-2.466-.921-.025-1.698 1.036-1.736 2.372"
                                fill="#FFF"
                            />
                            <path
                                d="M80.671 68.042a3.5 3.5 0 107 .198 3.502 3.502 0 00-7-.198"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M89.448 79.132s-17.638-4.5-29.095-6.325c0 0-1.42 10.687 10.619 13.81 15.151 3.93 18.476-7.485 18.476-7.485"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M70.972 86.617c-4.748-1.232-7.398-3.64-8.871-6.105 2.521-.9 5.971-1.165 9.66-.572 5.544.892 9.964 3.453 11.283 6.24-2.78 1.286-6.672 1.838-12.072.437"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M45.78 66.66s12.574-11.27 6.053-27.302c0 0 3.616-2.061 19.184 1.598 15.324 3.602 38.617 6.731 49.883-4.19 8.472-8.212.385-17.422-7.895-12.267 0 0 4.674-.924 3.84-8.767-.926-8.692-12.788-11.137-19.415-4.668 0 0 4.025-2.991 1.036-6.733-3.368-4.216-10.63-1.462-12.812 4.71 0 0-26.846-3.4-41.036 12.682-10.574 11.985-10.083 27.82 1.161 44.937"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M77.172 39.97c10.977 2.15 26.569 2.12 36.37-.773 7.935-2.342 10.294-8.301 10.905-10.487.28 2.406-.691 5.288-3.547 8.057-11.266 10.92-34.559 7.792-49.883 4.19-15.568-3.66-19.184-1.599-19.184-1.599 6.028-3.947 19.665-.498 25.34.613M45.95 53.139c5.906.378 7.709-3.587 7.709-3.587s-.067.98-.222 2.541c-1.39 8.947-7.658 14.567-7.658 14.567-4.44-6.76-7.196-13.317-8.263-19.519 1.507 2.619 4.236 5.729 8.434 5.998"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M104.63 10.216c-.214.916 1.077 2 2.884 2.421 1.807.423 3.446.023 3.66-.892.213-.915-1.078-1.998-2.886-2.421-1.807-.422-3.444-.023-3.658.892"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                        </g>
                    </g>
                </svg>
            )
        } else {
            return (
                <svg width={164} height={164} viewBox="0 0 164 164" {...props}>
                    <g fill="none" fillRule="evenodd">
                        <path
                            d="M99.709 121.5s8.912 18.15 15.556 31.411l-5.367 4.248s-8.68-17.29-16.184-32.182l5.995-3.476zm-34.846 3.353S58.619 141.5 52.8 155.143l5.502 4.142s7.027-18.284 12.384-30.162l-5.823-4.27z"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M73.18 99.43l-1.027-7.02s-29.72-17.517-42.802 11.371l9.116 3.345s7.48-20.723 34.714-7.696"
                            className="svg-char-shirt"
                        />
                        <path
                            d="M39.775 109.724c.563-5.142-1.73-7.292-5.968-8.526-3.796-1.104-7.957 1.846-8.635 5.35-.869 4.494 1.642 7.135 5.48 8.086 4.07 1.006 8.668-.755 9.123-4.91m88.095-44.27s.865-3.271-.301-7.654c-1.53-5.749-6.626-3.067-5.05 1.186 1.74 4.706 1.13 8.285 1.13 8.285l4.22-1.817z"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M94.675 92.556l1.243 7.143s33.539 7.453 38.584-23.235l-9.688-.663s.606 21.103-30.139 16.755"
                            className="svg-char-shirt"
                        />
                        <path
                            d="M120.836 72.124c1.11 6.302 4.577 8.063 10.038 8.062 4.891 0 8.813-4.943 8.406-9.34-.522-5.638-5.44-6.732-10.325-6.533-5.185.21-9.015 2.718-8.12 7.811"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M128.429 71.37c.081-2.065-3.227-2.545-4.895-2.655.148-.166.31-.326.495-.477 0 0 6.392.199 5.95 3.572-.61 4.635-5.505 3.318-7.104 2.518 0 0-.044-.117-.103-.31 2.199.509 5.526.658 5.657-2.649"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M109.349 155.985s4.26-.915 5.29-4.349c0 0 4.602-2.205 6.77-.143 0 0 .206 2.008-3.753 5.191-2.804 2.254-6.056 3.571-6.211 3.416 0 0-1.32-2.251-2.096-4.115m-50.404 1.644s-4.36-.432-5.6-3.795c0 0-4.727-1.92-6.765.27 0 0-.544 1.359 4.308 4.91 3.184 2.328 6.134 3.105 6.277 2.87 0 0 .633-1.24 1.78-4.255"
                            className="svg-char-shoes"
                        />
                        <path
                            d="M127.127 23.664l6.099.105a1.328 1.328 0 01-.022 2.658h-.024l-6.098-.106a1.33 1.33 0 01-1.306-1.352 1.339 1.339 0 011.351-1.305zm4.156-8.398a1.33 1.33 0 01-.399 1.837l-5.317 3.417a1.33 1.33 0 01-1.437-2.236l5.317-3.417a1.329 1.329 0 011.836.4zm-7.396-6.943a1.33 1.33 0 01.65 1.763l-2.309 5.005a1.329 1.329 0 11-2.414-1.113l2.309-5.005a1.33 1.33 0 011.764-.65z"
                            fillOpacity={0.8}
                            fill="#000"
                        />
                        <path
                            d="M94.863 92.338s.018 12.027 2.88 26.193c0 0-3.85 1.92-14.19 1.605-5.639-.172-12.062-1.2-15.212-2.502 0 0 1.604-3.88 2.559-7.993.975-4.208-.26-10.03 1.113-18 0 0 3.35.688 10.515.907 7.166.219 12.335-.21 12.335-.21"
                            className="svg-char-shirt"
                        />
                        <path
                            d="M99.023 117.607s4.555 6.004 6.832 12.836c0 0-20.29 10.144-44.097.207 0 0 2.484-6.832 6.417-13.25 0 0 14.926 3.303 30.848.207"
                            className="svg-char-pants"
                        />
                        <path
                            d="M67.336 117.817s2.146-4.717 3.563-8.177c.597-1.459-1.061-9.353.126-17.705 0 0 3.525.319 9.717.607.956 4.813 3.063 8.796 4.281 9.037 1.236.245 2.536-1.683 3.996-8.785 2.964-.072 5.212-1.084 5.212-1.084s2.551 4.635 3.103 6.93c.436 1.818-1.018 6.183-.836 7.017.619 2.839.463 5.192 1.22 7.495.587 1.782 2.253 3.281 2.463 5.17 0 0-5.193 3.356-16.478 3.011-6.153-.187-13.126-1.13-16.367-3.516"
                            className="svg-char-suit"
                        />
                        <path
                            d="M37.54 20.908c0 9.72 8.11 17.598 18.115 17.598 10.004 0 18.115-7.879 18.115-17.598S65.66 3.311 55.655 3.311c-10.005 0-18.116 7.878-18.116 17.597"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M43.783 36.34c4.464 7.733 15.87 9.505 25.474 3.96 9.604-5.545 13.771-16.309 9.307-24.04C74.1 8.526 62.695 6.753 53.091 12.3c-9.605 5.545-13.773 16.309-9.308 24.04"
                            className="svg-char-ribbon"
                        />
                        <path
                            d="M37.54 20.908a17.15 17.15 0 012.956-9.63c-.267 2.13-.485 7.458 3.254 10.872 0 0-.621 2.485 2.484 3.727l-3.484 7.378c-3.221-3.177-5.21-7.535-5.21-12.347"
                            className={`svg-char-${color}-hair-shade-1`}
                        />
                        <path
                            d="M114.762 67.784s6.832-11.49 4.658-29.192c-2.174-17.701-17.085-29.37-36.644-30.123-10.978-.422-21.318 3.004-28.919 9.035-9.172 7.277-13.862 18.26-11.867 31.026C44.89 67.076 52.652 76.79 57 79.895c8.963 6.402 42.545 15.838 57.762-12.111"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M120.442 69.366c-1.143 4.249-4.822 7.28-7.55 7.118-2.715-.167-2.328-3.533-2.307-7.711.02-4.179 2.245-7.433 4.968-7.268 2.722.165 5.967 3.871 4.89 7.861"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M117.764 68.612c.17 2.372-2.608 5.285-4.258 5.355-1.651.071-1.56-1.86-1.728-4.23-.173-2.37 1.03-4.348 2.681-4.418 1.65-.07 3.136.924 3.305 3.293"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M47.361 80.709c3.214 5.152 9.057 6.165 12.394 4.882 3.323-1.282 1.438-4.877-.33-9.523-1.766-4.647-5.9-7.376-9.231-6.093-3.331 1.284-5.485 6.48-2.833 10.734"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M50.769 79.752c1.076 2.607 5.082 3.871 7.172 3.283 2.092-.587 1.174-2.702.397-5.411-.773-2.711-3.098-4.429-5.19-3.84-2.089.59-3.636 2.927-2.38 5.968"
                            className={`svg-char-${color}-skin-shade-2`}
                        />
                        <path
                            d="M96.75 93.559s25.735-9.404 17.155-44.457c-8.58-35.055-32.112-33.094-43.879-29.907-11.766 3.187-22.748 12.256-22.552 23.042.676 37.347 14.96 48.217 22.88 51.633 4.336 1.87 15.993 4.037 26.396-.311"
                            className={`svg-char-${color}-skin`}
                        />
                        <path
                            d="M67.074 65c.533 0 .995-.409 1.063-.968.286-2.357 1.933-3.822 4.298-3.822 2.109 0 3.872 1.385 4.715 3.704.209.572.827.861 1.38.647.556-.214.836-.851.629-1.423C77.989 59.921 75.475 58 72.435 58c-3.426 0-6.009 2.313-6.427 5.758-.073.605.344 1.158.932 1.234.045.005.09.008.134.008m30.036-2.885c0 3.68 2.057 6.665 4.596 6.665s4.597-2.985 4.597-6.665c0-3.681-2.058-6.665-4.597-6.665-2.539 0-4.596 2.984-4.596 6.665"
                            className={`svg-char-${color}-eyes`}
                        />
                        <path
                            d="M98 59.371c0 1.309.732 2.371 1.635 2.371s1.635-1.062 1.635-2.371c0-1.31-.732-2.371-1.635-2.371S98 58.06 98 59.371"
                            fill="#FFF"
                        />
                        <path
                            d="M85.634 70.346a3.431 3.431 0 106.863 0 3.431 3.431 0 00-6.863 0"
                            className={`svg-char-${color}-skin-shade-1`}
                        />
                        <path
                            d="M94.062 82.72s-17.435-3.787-28.722-5.17c0 0-1.014 10.518 10.888 13.153 14.98 3.317 17.834-7.982 17.834-7.982"
                            className="svg-char-mouth"
                        />
                        <path
                            d="M76.228 90.703c-4.694-1.039-7.374-3.305-8.905-5.667 2.44-.971 5.809-1.352 9.443-.901 5.462.679 9.881 3.032 11.271 5.714-2.678 1.358-6.47 2.037-11.809.854"
                            className="svg-char-mouth-tongue"
                        />
                        <path
                            d="M46.131 37.04c2.484-20.497 29.317-26.513 46.582-20.497 11.487 4.003 15.95 10.347 17.656 15.15 8.941 5.165 6.256 19.235 6.256 19.235-12.058-5.256-18.806-13.744-21.45-17.707l-.014.019c-3.958 5.45-15.52 18.338-37.023 19.574-.432 13.177-6.844 16.902-6.844 16.902s-7.647-12.18-5.163-32.677z"
                            className={`svg-char-${color}-hair`}
                        />
                        <path
                            d="M49.521 70.906c-2.691-4.347-5.667-11.516-7.223-20.545.066-.333.106-.52.106-.52s1.424 9.627 15.088 8.695c0 0-1.372 9.032-7.97 12.37"
                            className={`svg-char-${color}-hair-shade-1`}
                        />
                        <path
                            d="M95.75 31.897c.22.133.29.42.156.64-.146.24-3.664 5.946-11.702 11.477-6.8 4.679-18.138 9.993-34.546 9.993a78.33 78.33 0 01-4.485-.13.466.466 0 01.053-.93c18.582 1.061 31.167-4.689 38.45-9.7 7.875-5.42 11.4-11.136 11.434-11.194.133-.22.42-.29.64-.156zm4.934-.849c.056.109 5.68 10.954 17.434 18.452a.466.466 0 01-.501.786C105.64 42.646 99.91 31.582 99.854 31.47a.466.466 0 01.83-.423zm2.84-6.372c.038.116 3.892 11.708 14.325 20.957a.466.466 0 01-.618.697c-10.63-9.424-14.554-21.248-14.592-21.367a.465.465 0 11.886-.287zm-4.328-.523c.209.15.256.441.106.65-.15.21-3.783 5.186-12.481 10.072-7.543 4.236-20.397 9.12-39.555 9.12-1.154 0-2.33-.018-3.53-.055a.466.466 0 11.028-.93c20.907.639 34.707-4.513 42.6-8.947 8.52-4.785 12.146-9.753 12.182-9.803a.466.466 0 01.65-.107zm-16.644-7.132c8.547.522 14.287 2.391 14.344 2.41a.466.466 0 01-.292.885c-.224-.074-22.675-7.268-45.777 3.242a.466.466 0 01-.386-.848c12.252-5.574 24.226-6.17 32.11-5.689zm17.431-1.022c.555-.84 2.348-.634 4.009.461 1.66 1.096 2.554 2.664 2 3.505-.555.84-2.35.634-4.009-.462-1.66-1.095-2.555-2.664-2-3.504z"
                            fillOpacity={0.1}
                            fill="#FFF"
                        />
                    </g>
                </svg>
            )
        }
    }
}

export default CharCourseSubcategoryFinished
