import * as React from "react"

const LogoBadgeColor = (props) => (
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#3C3D3B"
                d="M62.325 69.708c-1.388 4.615-5.36 7.9-10.624 7.9-5.134 0-9.039-3.123-10.516-7.559h-20.44C11.177 70.05 4 62.883 4 53.303V33.729c0-12.11 7.047-27.142 19.767-30.161 3.662-.87 7.975-.794 11.499.597 1.909.752 3.42 1.88 4.507 3.31 1.086-1.43 2.597-2.558 4.507-3.31 3.523-1.39 7.836-1.466 11.5-.597 12.719 3.02 19.765 18.05 19.765 30.16v19.575c0 8.326-5.489 14.87-13.22 16.405"
            />
            <path
                fill="#FDC436"
                d="M8.06 37.79v15.513c0 7.437 5.459 12.685 12.686 12.685h22.15c1.413 0 1.551.574 1.815 1.48 1.248 8.125 12.793 8.105 13.989-.064.19-1.243.666-1.39 1.326-1.469 6.582-.563 11.458-5.661 11.458-12.632V37.789H8.061Z"
            />
            <path
                fill="#3C3D3B"
                d="M21.74 42.877h2.323c1.105 0 2.009.906 2.009 2.01v3.83h3.832c1.105 0 2.01.906 2.01 2.01v2.323c0 1.104-.906 2.01-2.01 2.01h-3.832v3.831c0 1.105-.905 2.01-2.009 2.01H21.74a2.015 2.015 0 0 1-2.009-2.01V55.06H15.9a2.016 2.016 0 0 1-2.01-2.01v-2.322c0-1.105.905-2.01 2.01-2.01h3.832v-3.83c0-1.106.904-2.01 2.01-2.01M51.954 60.9a4.323 4.323 0 1 0 0-8.647 4.323 4.323 0 0 0 0 8.647m9.377-9.377a4.325 4.325 0 0 0 0-8.648 4.324 4.324 0 0 0 0 8.648"
            />
            <path
                fill="#FEFEFE"
                d="M71.484 33.728h-29.68V13.977c0-3.26 1.577-5.093 3.966-6.034 2.739-1.08 6.225-1.099 9.071-.424 2.349.558 4.465 1.635 6.338 3.082-1.015 1.722-2.843 2.372-4.86 1.92-1.185-.265-2.265-.614-3.493-.712-4.012-.32-7.789 2.365-8.819 6.257-.474 1.683.397 3.024 1.404 3.256 1.105.255 2.24-.232 2.577-1.883.824-3.87 4.445-3.442 7.405-2.78 3.489.782 6.856-.38 8.89-3.082a26.384 26.384 0 0 1 2.634 3.694c-.962.661-1.808 1.553-2.483 2.674-.635 1.053-1.084 2.229-1.74 3.25-1.258 1.956-3.355 2.464-5.466 1.622-1.129-.45-2.14-.966-3.337-1.257-3.911-.952-8.065 1.1-9.7 4.779-.735 1.585-.088 3.048.87 3.438 1.05.426 2.248.126 2.843-1.45 1.427-3.693 4.935-2.694 7.751-1.57 3.994 1.593 8.26.383 10.607-3.269.697-1.086 1.147-2.265 1.803-3.352.236-.39.5-.722.792-.996 1.731 4.13 2.627 8.606 2.627 12.588M8.06 33.728h29.682V13.977c0-3.26-1.578-5.093-3.966-6.034-2.74-1.08-6.226-1.099-9.072-.424-2.349.558-4.465 1.635-6.338 3.082 1.015 1.722 2.843 2.372 4.86 1.92 1.184-.265 2.264-.614 3.493-.712 4.012-.32 7.79 2.365 8.819 6.257.475 1.683-.396 3.024-1.403 3.256-1.105.255-2.242-.232-2.578-1.883-.824-3.87-4.445-3.442-7.404-2.78-3.49.782-6.857-.38-8.891-3.082a26.388 26.388 0 0 0-2.634 3.694c.962.661 1.809 1.553 2.484 2.674.635 1.053 1.084 2.229 1.74 3.25 1.257 1.956 3.354 2.464 5.465 1.622 1.129-.45 2.14-.966 3.337-1.257 3.911-.952 8.065 1.1 9.7 4.779.736 1.585.088 3.048-.87 3.438-1.05.426-2.248.126-2.842-1.45-1.428-3.693-4.935-2.694-7.753-1.57-3.993 1.593-8.259.383-10.606-3.269-.698-1.086-1.147-2.265-1.802-3.352a4.722 4.722 0 0 0-.792-.996C8.957 25.27 8.06 29.746 8.06 33.728"
            />
        </g>
    </svg>
)

export default LogoBadgeColor