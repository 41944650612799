import * as React from "react"

function ImgDiploma(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M21.5 2h-19C1.892 2 1 2.815 1 3.4v15.1c0 .585.892 1.5 1.5 1.5h19c.608 0 1.5-.915 1.5-1.5V3.4c0-.585-.892-1.4-1.5-1.4z"
                    fill="#2B97DD"
                />
                <path
                    d="M19.222 3c0 1.473 1.244 2.667 2.778 2.667v10.666c-1.534 0-2.778 1.194-2.778 2.667H4.778c0-1.473-1.244-2.667-2.778-2.667V5.667c1.534 0 2.778-1.194 2.778-2.667zm-1.997 6.222H6.775a.823.823 0 00-.724.413.84.84 0 000 .84c.15.26.426.418.724.414h10.45a.823.823 0 00.724-.413.84.84 0 000-.84.823.823 0 00-.724-.414zm1.1-3.333H5.675a.823.823 0 00-.724.413.84.84 0 000 .84.82.82 0 00.724.413h12.65a.823.823 0 00.724-.413.84.84 0 000-.84.823.823 0 00-.724-.413z"
                    fill="#F5F5F5"
                />
                <path
                    d="M13.482 21.956L12 21.18l-1.482.774c-.221.117-.518-.008-.518-.217V17h4v4.738c0 .209-.297.334-.518.218z"
                    fill="#E65748"
                />
                <path
                    d="M15.785 15.62l-.28-.168a.535.535 0 01-.238-.33.527.527 0 01.042-.402l.159-.285a.442.442 0 00-.38-.657l-.329-.006a.534.534 0 01-.37-.166.532.532 0 01-.163-.367l-.006-.33a.442.442 0 00-.656-.378l-.283.16a.535.535 0 01-.41.04.533.533 0 01-.323-.236l-.168-.28a.442.442 0 00-.758 0l-.167.278a.541.541 0 01-.326.238l-.004.002a.535.535 0 01-.404-.042l-.284-.158a.442.442 0 00-.657.379l-.006.329a.535.535 0 01-.163.367c-.002 0-.002.002-.003.003a.532.532 0 01-.367.163l-.33.006a.442.442 0 00-.379.656l.159.286a.537.537 0 01.042.401l-.003.007a.537.537 0 01-.235.323l-.28.168a.442.442 0 000 .758l.28.168a.535.535 0 01.238.33.527.527 0 01-.042.402l-.159.285a.442.442 0 00.38.657l.329.006a.534.534 0 01.37.166c.1.098.16.228.163.367l.006.33c.005.333.364.54.656.378l.286-.158a.535.535 0 01.401-.042l.008.001a.533.533 0 01.323.236l.168.28a.442.442 0 00.758 0l.168-.28c.072-.119.19-.2.323-.236l.008-.001a.527.527 0 01.402.042l.285.158a.442.442 0 00.657-.379l.005-.33a.534.534 0 01.166-.37.536.536 0 01.368-.162l.329-.006a.442.442 0 00.379-.657l-.158-.285a.535.535 0 01-.043-.401l.002-.008a.533.533 0 01.236-.323l.28-.168a.444.444 0 00-.002-.76z"
                    fill="#FDC436"
                />
            </g>
        </svg>
    )
}

export default ImgDiploma
