import React from 'react';
import { desaturate } from 'polished';
import { imageParser, getDate, getMonth, getHours } from '../../../../utils/utils';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';

export default function ClassroomListItem(props) {
    const { action, image, title, theme, dateStart, dateEnd } = props;
    const stc = require('string-to-color');

    return (
        <ListItem button className="classroom-list-item" onClick={action}>
            <div className="classroom-list-item-image">
                <div className="calendar-content">
                    <div className="calendar-date">
                        <Typography variant="body2" className="day" component="p" noWrap>
                            {getDate(dateStart)}
                        </Typography>
                        <Typography variant="caption" className="month" component="p" noWrap>
                            {getMonth(dateEnd)}
                        </Typography>
                    </div>
                </div>
                {
                    image ?
                        <div className="banner" style={{ backgroundImage: `url(${imageParser(image)})` }}></div>
                        :
                        <div className="banner __default" style={{ backgroundColor: desaturate(0.25, stc(title)) }}></div>
                }
            </div>
            <ListItemText
                disableTypography
                primary={
                    <div className="w-100">
                        <Typography variant="body1" component="h5" className="mb-1">
                            {title ? title : theme}
                        </Typography>
                        {
                            title && theme ?
                                <Typography variant="body2" component="p" className="mb-1">
                                    {theme}
                                </Typography>
                                : null
                        }
                    </div>
                }
                secondary={
                    <div className="d-flex align-items-center w-100">
                        <Icon ic="clock" className="icon-xxs mr-1" />
                        <Typography variant="body2" component="p">
                            {getHours(dateStart)} - {getHours(dateEnd)}
                        </Typography>
                    </div>
                }
            />
        </ListItem>
    )
}