import { qrService } from './base.service'

function highlightList(params) {
    return qrService('/v3/app/highlight/list', params)
}

function getHighlight(params) {
    return qrService('/v3/app/highlight/get', params)
}


export const highlightService = {
    highlightList,
    getHighlight
}