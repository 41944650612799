import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" fillRule="nonzero" d="M0 0h512v512H0z" />
                <path
                    fill="#D8A420"
                    fillRule="nonzero"
                    d="M368.68 76.8c10.115 42.604 35.494 56.15 35.346 79.552-.18 28.879-25.885 60.021-63.997 58.979-31.12-.864-46.115-35.489-33.441-62.403 9.937-21.168 36.118-34.328 62.092-76.128"
                />
                <path
                    fill="#C2C3C8"
                    d="M190.526 146.944h99.4c-17.493 52.31 10.95 83.035 46.205 82.827-28.026 36.65-26.27 199.743-70.75 203.137-25.913 1.34-45.073 2.292-127.813 2.292-29.782 0-48.257-24.235-43.11-54.215l30.823-179.796c5.92-34.625 35.464-54.245 65.245-54.245"
                />
                <path
                    fill="#004175"
                    d="m335.239 273.12-25.2 127.544c-7.795 25.723-30.258 32.154-33.62 33.554 95.087 0 94.462-6.014 112.462-102l26.866-150.381c-24.129 61.004-72.832 30.666-80.508 91.282"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon
