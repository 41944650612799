import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <defs>
                <linearGradient id="LogoAppIcon_a" x1="-.023%" x2="99.977%" y1="49.989%" y2="49.989%">
                    <stop offset="30%" stopColor="#E74A21" />
                    <stop offset="70%" stopColor="#EC9A1E" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" fillRule="nonzero" d="M0 0h512v512H0z" />
                <path
                    fill="url(#LogoAppIcon_a)"
                    fillRule="nonzero"
                    d="m218.878 160.565-.889 2.297c14.82 6.52 48.088 32.38 48.088 84.469 0 49.051-41.345 98.473-106.327 98.473-80.838 0-108.698-47.273-108.698-90.693 0-55.646 38.9-92.694 38.9-143.375-.436-36.913-26.314-68.63-62.388-76.467l-1.334 2.297a65.649 65.649 0 0 1 19.932 47.496C46.162 139.818 0 178.718 0 241.552 0 299.42 40.456 358.4 157.601 358.4c104.4 0 159.454-51.126 159.454-105.512-.074-46.68-33.491-86.173-98.177-92.323Z"
                    transform="translate(99.2 76.8)"
                />
                <path
                    fill="#0460A9"
                    d="M258.209 356.807 287.847 76.8h-61.5l29.64 280.007z"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon
