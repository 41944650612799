import React from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { yiq } from 'yiq';
import { Doughnut } from 'react-chartjs-2';
import {
    Grid, Typography, Divider, CardActionArea,
    Card, CardHeader, CardContent
} from '@material-ui/core';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const project = config.app.name_project.toLowerCase()
let theme = require(`../../../assets/scss/projects/${project}/_export.scss`);

export default function CardScormChart(props) {
    const { isLoggedUser, name } = props;
    const { history } = useReactRouter();
    const scormStats = useSelector(state => state.profile.scormStats);
    let totalAvailable = scormStats.data && scormStats.data.total_scorm_available ? parseInt(scormStats.data.total_scorm_available) : 0;
    let totalFinished = scormStats.data && scormStats.data.total_scorm_finished ? parseInt(scormStats.data.total_scorm_finished) : 0;
    let total = parseInt(totalAvailable) !== 0 ? Math.round((parseInt(totalFinished) / parseInt(totalAvailable)) * 100) : 0;

    const dataChart = {
        datasets: [{
            data: [total, 100 - total],
            backgroundColor: [
                yiq(theme['primary'], { threshold: 225, colors: { light: theme['primary'], dark: theme['overlay'] } }),
                'rgba(0, 0, 0, 0.1)'
            ],
            borderWidth: 0
        }]
    }

    const options = {
        cutoutPercentage: 85,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            }
        },
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false
        },
        title: {
            display: false,
        },
        hover: {
            mode: null
        }
    }

    return (
        <Card className="profile-card __scorm">
            <CardHeader
                title={
                    <Typography variant="h3" component="h2" align="center">
                        {intl.get("PROFILE_CARD_SCORM_TITLE")}
                    </Typography>
                }
            />
            <div className="profile-card-content">
                {
                    scormStats && scormStats.load === "finished" ?
                        !scormStats.error ?
                            <>
                                <CardContent className="w-100">
                                    <Grid container direction="column" alignItems="center" justify="space-between" className="chart-wrap">
                                        <Grid item xs={12} className="chart-content">
                                            <Grid container direction="column" alignItems="center" justify="center">
                                                <div className="doughnut-chart">
                                                    <Doughnut data={dataChart} options={options} width={162} height={162} />
                                                    <div className="doughnut-chart-text">
                                                        <Typography variant="body2" component="p" className="font-weight-bold">
                                                            {intl.get("LABEL_TOTAL")}
                                                        </Typography>
                                                        <Typography variant="h2" component="h3">
                                                            {total}%
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="chart-footer">
                                            <Grid container direction="row" alignItems="center" justify="center">
                                                <Grid item xs={6} className="chart-data-item">
                                                    <div className="chart-data-item-icon left">
                                                        <Icon ic="play_scorm" className="icon-sm" />
                                                    </div>
                                                    <div className="chart-data-item-text">
                                                        <Typography variant="body2" component="p">
                                                            {intl.get("PROFILE_CARD_SCORM_TITLE")}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {totalAvailable}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} className="chart-data-item">
                                                    <div className="chart-data-item-icon right">
                                                        <Icon ic="checked" className="icon-sm" />
                                                    </div>
                                                    <div className="chart-data-item-text">
                                                        <Typography variant="body2" component="p">
                                                            {intl.get("PROFILE_CARD_SCORM_COMPLETED")}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {totalFinished}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {
                                    isLoggedUser
                                        ?
                                        <Grid container direction="column">
                                            <Divider />
                                            <CardActionArea
                                                disabled={totalAvailable === 0}
                                                className="card-action-button"
                                                onClick={() => history.push({
                                                    pathname: `${history.location.pathname + '/scorm'}`,
                                                    state: {
                                                        nameUser: name
                                                    }
                                                })}
                                            >
                                                <Typography variant="body1" component="p">
                                                    {intl.get("BTN_SEE_ALL")}
                                                </Typography>
                                            </CardActionArea>
                                        </Grid>
                                        : null
                                }
                            </>
                            :
                            <Grid container alignItems="center" justify="center" className="chart-wrap">
                                <ErrorContent type="section" padding={true} />
                            </Grid>
                        :
                        <Grid container alignItems="center" justify="center" className="chart-wrap">
                            <LoadingBounce />
                        </Grid>
                }
            </div>
        </Card>
    )
}
