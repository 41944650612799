import * as React from "react"

const LogoBadgeColor = (props) => (
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#D8A420"
                fillRule="nonzero"
                d="M88.352 24c1.888 7.953 6.625 10.482 6.598 14.85-.034 5.392-4.832 11.205-11.947 11.01-5.81-.16-8.609-6.624-6.243-11.649C78.615 34.26 83.503 31.803 88.351 24"
            />
            <path
                fill="#C2C3C8"
                d="M55.094 37.094H73.65c-3.266 9.765 2.044 15.501 8.625 15.462-5.232 6.842-4.904 37.288-13.207 37.922-4.838.25-8.414.428-23.86.428-5.56 0-9.009-4.525-8.048-10.121l5.754-33.564c1.105-6.464 6.62-10.127 12.18-10.127"
            />
            <path
                fill="#004175"
                d="M213.084 79.945h12.524l1.755 7.77H208.33l-1.756 3.007h-8.77c9.52-15.79 19.034-31.324 28.548-47.114h1.5c3.754 15.79 7.764 31.324 11.769 47.114h-9.014l-6.76-28.817-10.763 18.04Zm75.379 0h12.519l1.755 7.77h-18.784l-2.005 3.007h-8.764c9.514-15.79 19.033-31.324 28.547-47.114h1.5c4.01 15.79 7.765 31.324 11.769 47.114h-9.014l-6.76-28.817-10.763 18.04Zm-107.187 0h-3.755l-1.755 10.777h-8.764l8.514-46.363h11.77c2.004 0 3.504.255 5.009.5 4.005.75 7.015 2.256 9.014 4.513 2.255 2.506 3.255 5.763 3.255 9.27 0 1.25-.25 2.507-.5 3.757-1 5.514-3.505 9.777-7.515 13.033-4.004 3.013-9.008 4.514-15.273 4.514Zm1.255-27.817-3.505 20.048h3.255c4.005 0 7.015-.756 9.264-2.507 2.005-1.756 3.255-4.263 4.005-7.52v-2.757c0-1.756-.25-3.256-1.25-4.513-1-1.25-2.755-2.006-4.76-2.506-1 0-2.255-.25-3.504-.25h-3.505v.005ZM147.22 78.69c-3.755-7.52-8.015-15.034-11.27-19.547l-5.51 31.575h-8.264l9.265-53.128 1.25 1c8.752 10.221 16.372 22.765 21.788 35.087l5.26-29.067h7.764c-3.505 19.797-7.265 39.594-10.77 59.391l-.75-3.757c-1.249-4.758-4.759-13.028-8.763-21.553Zm-41.822 12.033 9.264-53.127 1.25 1.25c2.255 2.507 4.004 5.013 6.009 7.52l-7.515 44.357h-9.008Zm158.773-15.04-10.02-10.526c-1.25-1.75-2.505-3.257-3.005-4.513-.75-1.5-1-3.007-1-4.513.25-3.257 1.5-6.264 3.755-8.27 2.005-2 5.01-3.507 8.015-3.507h17.778l-3.005 8.114c-2.821 0-13.168-.294-15.273.406-1.25.25-2.005 1-2.505 2.507v.506c0 2.729 10.38 12.56 11.769 14.033 1.505 1.756 2.505 3.257 3.005 4.513.75 1.506 1 3.007 1 4.513 0 3.263-1.506 6.264-3.505 8.27-2.255 2.001-5.01 3.507-8.265 3.507h-21.538l2.755-8.52c13.04.311 20.66 1.918 21.788-2.757v-.5c0-.5-.25-1.25-.5-1.75-.244-.506-.744-1.007-1.25-1.512ZM82.109 60.65l-4.704 23.81c-1.456 4.802-5.649 6.002-6.276 6.263 17.75 0 17.634-1.122 20.994-19.041l5.015-28.073c-4.504 11.388-13.596 5.725-15.03 17.04"
            />
        </g>
    </svg>
)

export default LogoBadgeColor