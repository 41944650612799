import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#ED4C52', // Red[200]
            main: '#E91A22', // Red[300]
            dark: '#B1282F', // Red[500]
            contrastText: '#fff',
        },
        secondary: {
            light: '#7B96AE', // Grey[200]
            main: '#5C7A96', // Grey[300]
            dark: '#485F75', // Grey[500]
            contrastText: '#fff',
        },
        error: {
            light: '#aaaaaa',
            main: '#969696',
            dark: '#878787',
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
