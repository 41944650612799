import React from 'react';
import { CardMedia, Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';
import { Icon, Points } from '../../../../components/Images/Images';

export default function CardFavoritePrizeAvailable(props) {
    const { image, name, area, partner, value, premium } = props;

    return (
        <Card className="card card-favorite-prize-available">
            <FeedCardHeader {...props} />
            <CardMedia
                className="card-image-full"
                image={image}
            >
                {
                    premium ?
                        <div className="badge-premium">
                            <Icon ic="crown_filled" />
                        </div>
                        :
                        null
                }
            </CardMedia>
            <CardContent>
                <Typography gutterBottom variant="body1" component="h4">
                    {name}
                </Typography>
                <Typography gutterBottom variant="body2" className="font-weight-bold" component="p">
                    {partner}
                </Typography>
                <Typography variant="body2" component="p">
                    {area}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                    <Points type="qis" className="img-xs" />
                    <Typography variant="body1" className="text-qis ml-1" component="span">
                        {value}
                    </Typography>
                </div>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
