import React from 'react';
import intl from 'react-intl-universal';
import {
    Button, Dialog, DialogContent, DialogContentText,
    DialogTitle, DialogActions
} from '@material-ui/core';

export default function DialogWarningProcess(props) {
    const { open, close, warning, send } = props;

    return (
        <Dialog
            id="dialog-warning-process"
            open={open} onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {intl.get("FREE_DIALOG_WARNING_PROCESS_TITLE")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="text-light">
                    {warning.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary" autoFocus>
                    {intl.get("BTN_CANCEL")}
                </Button>
                <Button onClick={() => send(warning.type)} color="primary">
                    {intl.get("BTN_CONTINUE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}