import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { notificationService } from './services/notification.service';
import project from './config';

// Inicializa o Firebase com as configurações do projeto
const firebaseApp = initializeApp(project.firebase.config);
const analytics = getAnalytics(firebaseApp);

// Verifica se o contexto suporta Service Workers
const isServiceWorkerSupported = 'serviceWorker' in navigator;

let messaging;
if (isServiceWorkerSupported) {
    import('firebase/messaging').then(({ getMessaging }) => {
        messaging = getMessaging(firebaseApp);
        console.log("Firebase Messaging inicializado com sucesso.");
    }).catch((error) => {
        console.error("Erro ao inicializar Firebase Messaging:", error);
    });
} else {
    console.warn("Service Workers não são suportados. Firebase Messaging será desativado.");
}

// Função para registrar o Service Worker e garantir que ele está ativo antes de solicitar permissões
const ensureServiceWorkerActive = async () => {
    if (isServiceWorkerSupported) {
        const registration = await navigator.serviceWorker.ready;
        return registration;
    } else {
        throw new Error('Service Workers não são suportados neste navegador.');
    }
};

// Solicita permissão para notificações somente se o usuário estiver autenticado e se o token ainda não foi gerado
export const askUserPermission = async () => {
    try {
        if (!isServiceWorkerSupported || !messaging) {
            console.warn("Firebase Messaging desativado. Permissões de notificação não podem ser solicitadas.");
            return;
        }

        const registration = await ensureServiceWorkerActive();

        const isAuthenticated = JSON.parse(localStorage.getItem('auth.state.authenticated'));
        const user = JSON.parse(localStorage.getItem('auth.state.user'));
        const existingToken = localStorage.getItem('auth.state.notification.token');

        if (isAuthenticated && user) {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                const { getToken } = await import('firebase/messaging');
                const token = await getToken(messaging, {
                    vapidKey: project.firebase.vapidKey,
                    serviceWorkerRegistration: registration,
                });

                if (existingToken !== token) {
                    await notificationService.setUserToken({ id_register: token });
                    localStorage.setItem('auth.state.notification.token', JSON.stringify(token));
                }

                return token;
            } else {
                console.warn("Permissão de notificação negada pelo usuário.");
            }
        } else {
            console.warn("Usuário não autenticado ou token já gerado. Nenhuma ação necessária.");
        }
    } catch (error) {
        console.error("Erro ao solicitar permissão de notificação:", error);
    }
};

// Captura mensagens quando o app está em primeiro plano
export const getMessage = () => {
    if (!isServiceWorkerSupported || !messaging) {
        console.warn("Firebase Messaging desativado. Listener de mensagens não pode ser configurado.");
        return;
    }

    try {
        import('firebase/messaging').then(({ onMessage }) => {
            onMessage(messaging, (payload) => {
                console.log("Mensagem recebida em primeiro plano:", payload);
            });
        });
    } catch (error) {
        console.error("Erro ao configurar listener de mensagens:", error);
    }
};

// Função para registrar o recebimento de notificações no Analytics
export const reportNotification = () => {
    try {
        logEvent(analytics, 'notification_received');
    } catch (error) {
        console.error("Erro ao registrar evento de notificação no Analytics:", error);
    }
};

// Função para registrar o evento de login no Analytics
export const loginEvent = (method) => {
    try {
        setUserId(analytics, method.data.user.id_user);
        logEvent(analytics, 'login', { method: method.data.user.id_user });
    } catch (error) {
        console.error("Erro ao registrar evento de login no Analytics:", error);
    }
};

export default firebaseApp;
