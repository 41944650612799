import * as React from 'react';
import config from '../../../config';
import AssaiCharCoursePratice from '../projects/assai/chars/CharCoursePratice';

function CharTrailNoQuestions(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                <defs>
                    <filter
                        x="-6.2%"
                        y="-5.5%"
                        width="112.5%"
                        height="111.1%"
                        filterUnits="objectBoundingBox"
                        id="CharTrailNoQuestions__b"
                    >
                        <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                        />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <circle id="CharTrailNoQuestions__a" cx={90} cy={90} r={90} />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="CharTrailNoQuestions__c" fill="#fff">
                        <use xlinkHref="#CharTrailNoQuestions__a" />
                    </mask>
                    <use fillOpacity={0.4} fill="#FFF" xlinkHref="#CharTrailNoQuestions__a" />
                    <g mask="url(#CharTrailNoQuestions__c)">
                        <path
                            d="M175.302 140.443l-16.072-14.886c-5.239-4.85-14.606-4.897-19.49.302-4.883 5.203-7.165 15.358-1.926 20.208l20.355 9.425c5.234 4.846 12.897 8.352 17.773 3.149a12.809 12.809 0 00-.64-18.198m-88.488 11.786c-5.234.91-9.781-1.193-15.207-.419l-24.672-.12c-12.532 1.535-31.833 6.275-33.277-3.413-4.66-31.209 14.415-21.613 14.4-21.757-2.316-19.79 18.416-19.271 10.694-1.62l42.161 5.645"
                            fill="#FDC436"
                        />
                        <path
                            d="M146.605 122.095L67.53 123.92c-14.065 27.995-1.162 69.03 1.169 71.46l4.01 13.058c1.817 5.842 12.7 4.427 18.58 2.63 5.875-1.802 4.607-5.965 2.793-11.799L92.762 195l20.652-.07h1.003c-3.472 6.566-.851 18.05 5.226 18.95 6.085.898 16.482 1.1 17.387-4.936l2.316-21.567 1.452-53.34 3.1-10.853"
                            fill="#11496F"
                        />
                        <path
                            d="M135.002 123.078c5.464 0 3.96 23.51 4.746 27.557 2.513.151-.059 13.956-.059 13.956l-.728 16.513s-18.874 11.42-44.1 8.181c-19.82-2.544-31.728-11.392-31.728-11.392l-2.93-23.226s2.07-8.198 3.775-18.372c1.516-9.04.998-10.13 2.667-12.269l68.357-.948z"
                            fill="#46CA64"
                        />
                        <path
                            d="M149.21 152.814l24.006-16.48-11.692-9.786c-6.2-4.046-10.814-5.59-13.844-4.634-3.03.956-7.086 2.683-12.17 5.183l3.717 25.717 1.333 2.834.738-6.324 7.911 3.49z"
                            fill="#46CA64"
                        />
                        <path
                            d="M140.361 147.26l-.105 9.722s7.465 8.541 10.897-4.772c-.078.287-10.792-4.95-10.792-4.95zm3.082-2.963l6.369 2.11c12.753 5.474 23.116 11.454 20.23 14.814l-13.9 13.662c-6.798 7.623-18.644 10.566-24.638 5.298v.306c-1.446-1.84-17.048-4.273-6.08-12.875-5.168-.767 3.526-16.695 12.996-12.283 0 0-.468-12.04.073-12.71l4.95 1.678z"
                            fillOpacity={0.1}
                            fill="#000"
                        />
                        <path
                            d="M178.28 146.408c3.088 8.188-.721 10.2-3.604 13.569l-13.893 13.662c-6.798 7.623-18.732 13.228-24.724 7.956l-.622-.57c-9.696-7.328-16.347-6.062-5.38-14.657-10.011-.747 8.694-13.832 14.74-7.367 0 0 2.027-1.547 5.42-5.034 2.032-2.092 13.13-6.02 13.13-6.02"
                            fill="#FDC436"
                        />
                        <path
                            d="M57.51 81.434s.323 30.5.276 30.64c.211 5.02 4.774 16.36 18.495 18.38 16.393 2.405 52.438 3.78 52.438 3.78s15.445-1.835 16.335-18.287c.046.275-.172-31.19-.172-31.19l-87.373-3.323"
                            fillOpacity={0.1}
                            fill="#000"
                        />
                        <path
                            d="M52.533 77.337s-.356 30.586-.402 30.725c.117 5.027 4.006 18.694 20.144 18.857 17.832.192 60.543.638 60.543.638s19.424-1.213 20.863-17.74c.046.283.956-34.014.956-34.014L53.212 73.39"
                            fill="#FDC436"
                        />
                        <path
                            d="M121.524 26.148c4.81.612 2.782.203 6.119.996 17.96 4.95 27.388 27.282 26.913 45.049l-.16 5.831c.192.127-101.763-2.379-101.723-2.554l.164-6.197c.475-17.764 11.088-39.526 29.288-43.431 5.249-1.13 11.373-.84 16.33 1.341 2.687 1.181 4.79 2.898 6.276 5.043 1.603-2.053 3.795-3.652 6.538-4.675 3.712-1.422 6.406-1.344 10.255-1.403"
                            fill="#2D2F2B"
                        />
                        <path
                            fill="#46CA64"
                            d="M66.764 126.269l-19.467-1.49-2.732 28.583 17.925 2.26z"
                        />
                        <path
                            fill="#212321"
                            opacity={0.08}
                            d="M58.608 155.114l4.683-29.3-2.706 29.3z"
                        />
                        <path
                            d="M118.812 105.301c-7.894.132-14.4-5.814-14.52-13.274-.126-7.46 6.177-13.616 14.071-13.74 7.901-.131 14.403 5.814 14.528 13.275.125 7.46-6.178 13.615-14.08 13.74"
                            fill="#212321"
                        />
                        <path
                            d="M123.148 94.499c-3.854.058-7.021-2.84-7.082-6.477-.066-3.637 3.01-6.64 6.864-6.705 3.849-.059 7.024 2.839 7.082 6.476.06 3.638-3.015 6.64-6.864 6.706"
                            fill="#FFF"
                        />
                        <path
                            d="M80.676 105.485c-7.9.124-14.402-5.814-14.527-13.28-.125-7.462 6.177-13.61 14.079-13.741 7.894-.13 14.4 5.816 14.52 13.274.125 7.46-6.178 13.615-14.072 13.747"
                            fill="#212321"
                        />
                        <path
                            d="M85.006 94.675c-3.85.059-7.024-2.839-7.083-6.476-.059-3.638 3.01-6.64 6.865-6.698 3.853-.066 7.023 2.831 7.082 6.469.059 3.637-3.01 6.64-6.864 6.705m.118 14.375s27.524 5.152 46.81-6.306c.667-.392-5.42 19.802-27.847 20.17-14.805-2.24-18.242-13.05-18.963-13.864"
                            fill="#FFF"
                        />
                        <path
                            d="M85.124 109.05s20.098 17.699 46.849-6.272c.574-.51-3.638 18.09-23.265 20.791-22.714 3.129-22.863-13.705-23.584-14.519"
                            fill="#212321"
                        />
                        <path
                            d="M150.26 72.028l-43.028-1.213.797-28.974c.13-4.777 2.496-7.406 5.994-8.692 4.016-1.468 9.068-1.349 13.162-.248 3.388.918 6.406 2.576 9.069 4.777-1.542 2.486-4.217 3.365-7.125 2.622-1.703-.438-3.26-.996-5.033-1.19-5.805-.631-11.382 3.15-13.034 8.82-.758 2.452.453 4.451 1.897 4.834 1.594.418 3.258-.251 3.817-2.657 1.346-5.644 6.58-4.87 10.843-3.776 5.028 1.293 9.956-.28 13.015-4.161a39.088 39.088 0 013.666 5.532c-1.42.925-2.682 2.203-3.705 3.815-.966 1.522-1.66 3.224-2.657 4.7-1.898 2.811-4.962 3.474-7.987 2.156-1.613-.708-3.065-1.507-4.788-1.98-5.629-1.56-11.73 1.286-14.25 6.609-1.126 2.3-.253 4.47 1.12 5.079 1.51.67 3.26.282 4.18-2.011 2.22-5.357 7.26-3.75 11.303-1.987 5.727 2.503 11.952.903 15.501-4.36 1.057-1.566 1.756-3.278 2.753-4.844a6.813 6.813 0 011.185-1.429c2.346 6.129 3.466 12.732 3.304 18.578m-91.935-2.589l43.02 1.21.799-28.971c.127-4.787-2.081-7.538-5.506-9.014-3.928-1.697-8.98-1.862-13.133-.988-3.427.72-6.536 2.208-9.313 4.26 1.404 2.57 4.027 3.592 6.97 3.015 1.728-.34 3.309-.806 5.091-.906 5.83-.3 11.194 3.791 12.535 9.54.615 2.49-.701 4.416-2.17 4.718-1.613.33-3.24-.433-3.662-2.865-1.035-5.715-6.3-5.23-10.615-4.378-5.092 1.003-9.927-.84-12.768-4.884a38.936 38.936 0 00-3.967 5.31c1.368 1.01 2.56 2.352 3.494 4.017.88 1.576 1.483 3.319 2.39 4.846 1.748 2.916 4.766 3.75 7.857 2.602 1.653-.616 3.14-1.331 4.891-1.711C89.944 54 95.884 57.18 98.1 62.646c1.003 2.355.005 4.478-1.395 5.009-1.54.577-3.27.092-4.067-2.248-1.917-5.473-7.04-4.15-11.166-2.615-5.857 2.175-11.993.222-15.244-5.225-.97-1.626-1.574-3.377-2.48-4.996a6.634 6.634 0 00-1.109-1.487c-2.682 5.984-4.158 12.514-4.315 18.355"
                            fill="#FFF"
                        />
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCoursePratice {...props} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (gender === "male") {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <defs>
                        <filter
                            x="-7.3%"
                            y="-6.4%"
                            width="114.6%"
                            height="112.8%"
                            filterUnits="objectBoundingBox"
                            id="CharTrailNoQuestions__b"
                        >
                            <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                            <feGaussianBlur
                                stdDeviation={2}
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                            />
                            <feColorMatrix
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <circle id="CharTrailNoQuestions__a" cx={90} cy={90} r={90} />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <mask id="CharTrailNoQuestions__c" fill="#fff">
                            <use xlinkHref="#CharTrailNoQuestions__a" />
                        </mask>
                        <use fillOpacity={0.4} fill="#FFF" xlinkHref="#CharTrailNoQuestions__a" />
                        <g mask="url(#CharTrailNoQuestions__c)">
                            <path
                                d="M84.4 141.27l-.127 8.372s-31.53 7.856-58.135-15.075l12.723-7.391s15.005 14.902 45.54 14.094"
                                className="svg-char-shirt-shade-1"
                            />
                            <path
                                d="M39.9 120.893c2.564 8.27-.52 12.533-7.093 15.909-5.886 3.024-13.663-.499-15.892-6.041-2.86-7.108 1.368-12.355 7.372-15.137 6.369-2.951 13.543-1.414 15.613 5.269"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M39.34 119.47s-2.655-8.242-3.66-12.263c-1.005-4.02-6.515-2.773-6.803 1.413-.288 4.187 2.3 8.493 4.223 10.232 1.924 1.737 5.727 1.045 6.24.618"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M82.366 141.014s-4.796 18.139-6.284 37.11c0 0 6.282 2.774 23.434 2.774 9.351 0 20.047-1.133 25.334-2.775 0 0-.66-11.6-4.772-35.543 0 0-5.584.8-17.47.8-11.887 0-20.242-2.366-20.242-2.366"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M98.247 154.038c1.966 4.048 5.407 13.317 5.64 16.069.092 1.098-5.708 6.627-8.015 7.011-2.002.334-7.678-5.008-8.013-6.677-.095-.477 1.085-4.152 2.437-8l.248-.7c1.079-3.039 2.224-6.071 2.895-7.617.987-2.271 3.73-2.307 4.808-.086zm-2.374-10.477a3.507 3.507 0 010 7.012 3.505 3.505 0 110-7.012z"
                                className="svg-char-necktie"
                            />
                            <path
                                d="M119.677 142.49l1.03 8.434s15.833 2.105 16.33 8.606c.393 5.152-14.435 7.217-14.435 7.217l2.23 11.169s22.515-3.951 22.858-17.355c.345-13.403-19.37-18.072-28.013-18.072"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M115.464 164.582c3.7-2.302 10.902-1.462 13.84 1.994 2.92 3.436 4.983 10.139.515 13.92-5.093 4.31-9.516 2.4-13.403-1.72-3.996-4.233-7.17-10.327-.952-14.194zM21.71 116.979c4.023-1.27 7.832 1.65 10.256 7.113 2.174 4.896 1.596 12.55-3.044 14.186-4.108 1.45-9.713-1.343-12.05-7.97-2.523-7.157.915-12.09 4.838-13.329z"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M82.988 85.74c.35 12.37-7.028 18.206-16.89 18.484-9.861.278-16.634-17.794-17.232-25.918-.974-13.222 5.98-22.815 15.843-23.094 9.86-.278 17.93 18.157 18.28 30.528"
                                className={`svg-char-${color}-hair-shade-2`}
                            />
                            <path
                                d="M149.111 107.834c4.716 1.665 7.94 8.859 4.392 14.922-4.297 7.345-12.443 8.995-17.168 7.33-4.706-1.665-2.206-6.775.086-13.353 2.289-6.58 7.973-10.565 12.69-8.899zm-92.01-9.748c3.808-.34 7.053 4.13 7.246 9.983.196 5.851.87 10.551-2.927 10.892-3.813.336-9.086-3.765-10.857-9.671-1.667-5.546 2.732-10.865 6.538-11.204z"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M145.122 113.285c2.95.743 5.21 3.955 3.57 8.266-1.405 3.694-6.966 5.624-9.917 4.883-2.953-.74-1.75-3.739-.77-7.565.976-3.828 4.165-6.326 7.117-5.584zm-86.337-9.9c2.315.032 4.078 2.754 3.93 6.082-.142 3.327.063 6.028-2.254 5.994-2.313-.033-6.32-4.004-6.175-7.334.142-3.324 2.184-4.774 4.499-4.742z"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M84.707 142.241s-36.42-12.154-25.788-61.595c10.631-49.442 43.673-47.626 60.28-43.627 16.609 3.998 32.35 16.267 32.502 31.385.531 52.34-19.05 68.13-30.007 73.229-6 2.791-22.242 6.287-36.987.608"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M99.36 109.286a4.808 4.808 0 01-4.671 4.942 4.809 4.809 0 11-.272-9.614 4.81 4.81 0 014.943 4.672"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M83.46 97.879c.146 5.156-2.618 9.417-6.174 9.518-3.556.1-6.557-4-6.702-9.154-.146-5.157 2.619-9.417 6.175-9.518 3.556-.1 6.556 3.998 6.702 9.154zm35.97-3.252c4.899 0 8.592 3.308 9.19 8.233.104.866-.492 1.656-1.333 1.764a1.55 1.55 0 01-.191.012c-.763 0-1.423-.584-1.52-1.384-.41-3.37-2.764-5.464-6.146-5.464-3.015 0-5.536 1.98-6.742 5.296-.298.817-1.182 1.231-1.974.925-.794-.307-1.195-1.217-.898-2.035 1.674-4.6 5.267-7.347 9.614-7.347z"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M81.421 93.676c.052 1.834-.93 3.35-2.196 3.386-1.265.036-2.332-1.423-2.384-3.256-.052-1.835.931-3.35 2.197-3.386 1.265-.036 2.331 1.421 2.383 3.256"
                                fill="#FFF"
                            />
                            <path
                                d="M87.305 124.518s24.225-6.182 39.962-8.688c0 0 1.95 14.678-14.586 18.968-20.809 5.399-25.376-10.28-25.376-10.28"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M112.682 134.798c6.52-1.692 10.16-5 12.184-8.385-3.464-1.237-8.202-1.6-13.27-.786-7.614 1.225-13.683 4.743-15.495 8.57 3.819 1.766 9.164 2.526 16.58.6"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M147.283 107.387s-17.27-15.478-8.315-37.498c0 0-4.967-2.832-26.35 2.195-21.046 4.948-53.038 9.245-68.51-5.754-11.638-11.28-.53-23.93 10.843-16.85 0 0-6.421-1.269-5.274-12.04 1.271-11.939 17.562-15.298 26.665-6.412 0 0-5.528-4.109-1.422-9.247 4.625-5.79 14.6-2.01 17.596 6.468 0 0 36.873-4.669 56.361 17.42 14.524 16.459 13.85 38.209-1.594 61.718"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M158.633 80.579c-1.466 8.518-5.252 17.525-11.35 26.808 0 0-8.609-7.718-10.518-20.006-.213-2.144-.304-3.49-.304-3.49l.002.004.048.096c.386.74 3.08 5.302 10.537 4.825 5.767-.37 9.514-4.64 11.585-8.237zM39.234 55.264c.84 3.003 4.08 11.187 14.978 14.404 13.462 3.973 34.877 4.015 49.953 1.063 7.793-1.527 26.524-6.263 34.803-.842l-.008-.005-.016-.008c-.395-.199-5.686-2.567-25.688 2.06l-.637.148c-21.047 4.948-53.039 9.245-68.511-5.754-3.924-3.803-5.26-7.76-4.874-11.066z"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M66.453 29.864c.294 1.257-1.48 2.746-3.961 3.325-2.482.581-4.732.031-5.027-1.226-.292-1.256 1.48-2.745 3.963-3.326 2.483-.579 4.731-.03 5.025 1.227"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                        </g>
                    </g>
                </svg>
            )
        } else {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <defs>
                        <filter
                            x="-7.2%"
                            y="-6.4%"
                            width="114.4%"
                            height="112.8%"
                            filterUnits="objectBoundingBox"
                            id="CharTrailNoQuestions__b"
                        >
                            <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                            <feGaussianBlur
                                stdDeviation={2}
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                            />
                            <feColorMatrix
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <circle id="CharTrailNoQuestions__a" cx={90} cy={90} r={90} />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <mask id="CharTrailNoQuestions__c" fill="#fff">
                            <use xlinkHref="#CharTrailNoQuestions__a" />
                        </mask>
                        <use fillOpacity={0.4} fill="#FFF" xlinkHref="#CharTrailNoQuestions__a" />
                        <g mask="url(#CharTrailNoQuestions__c)">
                            <path
                                d="M113.047 144.281l.26 7.925s15.875 3.679 16.236 9.1c.343 5.155-14.434 7.217-14.434 7.217l2.23 11.167s22.513-3.95 22.857-17.353c.344-13.402-18.557-16.839-27.149-18.056"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M81.4 143.27l-.127 8.372s-31.53 7.856-58.135-15.075l12.723-7.391s15.005 14.902 45.54 14.094"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M36.9 122.893c2.564 8.27-.52 12.533-7.093 15.909-5.886 3.024-13.663-.499-15.892-6.041-2.86-7.108 1.368-12.355 7.372-15.137 6.369-2.951 13.543-1.414 15.613 5.269"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M13.872 132.307c2.337 6.628 7.942 9.42 12.05 7.971 4.64-1.636 5.218-9.29 3.044-14.186-2.424-5.462-6.233-8.382-10.256-7.113-3.923 1.238-7.36 6.172-4.838 13.328"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M36.34 121.47s-2.655-8.242-3.66-12.263c-1.005-4.02-6.515-2.773-6.803 1.413-.288 4.187 2.3 8.493 4.223 10.232 1.924 1.737 5.727 1.045 6.24.618"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M81.239 143.124s-.79 16.308-5.577 35.336c0 0 5.098 2.85 19.14 3.081 7.657.126 16.433-.857 20.788-2.422 0 0-1.929-5.364-2.96-11.003-1.055-5.768.992-13.584-.361-24.48 0 0-4.587.721-14.318.56-9.732-.16-16.712-1.072-16.712-1.072"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M116.941 179.43s-2.61-6.533-4.31-11.315c-.718-2.016 2.034-12.614.958-24.016 0 0-4.801.207-13.215.204-1.604 6.464-4.715 11.73-6.382 11.98-1.69.254-3.331-2.443-4.858-12.166-4.014-.287-6.998-1.803-6.998-1.803s-3.756 6.122-4.65 9.199c-.708 2.437.985 8.448.685 9.568-1.02 3.81-.958 7.011-2.133 10.085-.909 2.38-3.262 4.306-3.667 6.855 0 0 7.294 4.163 22.619 4.416 8.355.138 17.403.023 21.951-3.006"
                                className="svg-char-suit"
                            />
                            <path
                                d="M158.63 45.53c0 13.193-11.01 23.889-24.592 23.889-13.582 0-24.59-10.696-24.59-23.89 0-13.193 11.008-23.889 24.59-23.889 13.581 0 24.592 10.696 24.592 23.89"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M150.154 66.479c-6.06 10.497-21.543 12.903-34.581 5.375-13.039-7.528-18.695-22.139-12.636-32.636 6.06-10.496 21.543-12.902 34.581-5.375 13.039 7.528 18.696 22.14 12.636 32.636"
                                className="svg-char-ribbon"
                            />
                            <path
                                d="M158.63 45.53c0-4.828-1.48-9.318-4.014-13.074.362 2.892.659 10.125-4.418 14.76 0 0 .844 3.372-3.372 5.059l4.73 10.016c4.372-4.313 7.074-10.23 7.074-16.761"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M53.8 109.163s-9.276-15.598-6.324-39.628c2.95-24.029 23.192-39.87 49.745-40.891 14.901-.574 28.938 4.076 39.257 12.264 12.451 9.878 18.818 24.787 16.11 42.118-3.936 25.177-14.475 38.363-20.377 42.579-12.167 8.69-57.755 21.5-78.412-16.442"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M46.087 111.311c1.553 5.768 6.548 9.883 10.25 9.663 3.686-.227 3.161-4.796 3.132-10.468-.027-5.672-3.048-10.09-6.745-9.865-3.694.223-8.1 5.254-6.637 10.67"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M49.724 110.287c-.23 3.22 3.54 7.176 5.78 7.27 2.241.097 2.117-2.524 2.347-5.743.233-3.218-1.4-5.902-3.641-5.996-2.24-.095-4.258 1.254-4.486 4.469"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M145.296 126.71c-4.362 6.994-12.294 8.369-16.825 6.626-4.51-1.74-1.951-6.62.449-12.927 2.396-6.308 8.009-10.013 12.531-8.27 4.522 1.742 7.446 8.796 3.845 14.57"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M140.67 125.41c-1.46 3.539-6.9 5.256-9.736 4.458-2.84-.798-1.593-3.669-.539-7.347 1.05-3.68 4.206-6.012 7.044-5.212 2.838.8 4.937 3.973 3.231 8.102"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M78.25 144.153s-34.935-12.765-23.289-60.35c11.648-47.588 43.594-44.925 59.568-40.6 15.972 4.327 30.88 16.638 30.615 31.281-.918 50.699-20.31 65.453-31.06 70.091-5.887 2.54-21.711 5.48-35.834-.422"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M119.061 111.221a1.55 1.55 0 01-1.34-1.547c.032-3.324-2.05-5.67-5.43-6.125-3.013-.404-5.79 1.178-7.43 4.232a1.547 1.547 0 11-2.725-1.462c2.272-4.237 6.222-6.419 10.567-5.835 4.897.658 8.157 4.362 8.112 9.218a1.546 1.546 0 01-1.754 1.52"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M93.34 112.641a4.658 4.658 0 11-9.317 0 4.659 4.659 0 019.317 0"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M146.966 67.428c3.271 26.989-6.398 43.356-6.98 44.314l-.028.045s-8.705-5.057-9.29-22.947c-29.225-1.678-44.923-19.213-50.279-26.596-3.59 5.38-12.75 16.903-29.12 24.038l-.007-.044c-.182-1.008-3.3-19.252 8.501-26.067 2.314-6.521 8.374-15.133 23.967-20.567 23.437-8.167 59.864 0 63.236 27.824z"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M142.364 113.402c3.653-5.902 7.694-15.633 9.805-27.89-.09-.453-.144-.706-.144-.706s-1.933 13.068-20.481 11.804c0 0 1.862 12.26 10.82 16.792"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M76.023 101.842c-.007 4.996-2.803 9.045-6.25 9.04-3.447-.004-6.235-4.058-6.23-9.055.006-4.997 2.804-9.044 6.25-9.04 3.447.004 6.236 4.058 6.23 9.055"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M74.338 97.53c-.002 1.776-.998 3.216-2.223 3.215-1.227-.001-2.219-1.444-2.217-3.22.003-1.78.998-3.218 2.224-3.217 1.226.001 2.217 1.443 2.216 3.221"
                                fill="#FFF"
                            />
                            <path
                                d="M78.368 128.634s23.634-5.296 38.943-7.275c0 0 1.471 14.269-14.663 17.952-20.304 4.635-24.28-10.677-24.28-10.677"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M102.648 139.31c6.364-1.451 9.981-4.551 12.038-7.771-3.32-1.297-7.896-1.784-12.826-1.14-7.41.97-13.386 4.204-15.25 7.857 3.648 1.82 8.802 2.708 16.038 1.055"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M80.477 60.658c.048.079 4.866 7.878 15.577 15.234 9.88 6.784 26.953 14.566 52.14 13.13a.633.633 0 01.072 1.263c-2.082.119-4.114.176-6.089.176-22.275 0-37.664-7.214-46.895-13.566-10.912-7.508-15.688-15.253-15.886-15.58a.633.633 0 011.081-.657zm-6.718-1.64a.633.633 0 01.277.851c-.077.15-7.855 15.17-24.112 25.54a.63.63 0 01-.874-.193.633.633 0 01.193-.873c15.956-10.179 23.59-24.9 23.665-25.048a.633.633 0 01.851-.276zm-3.91-8.78a.632.632 0 01.407.796c-.013.04-.356 1.074-1.088 2.801l-.183.428c-2.32 5.362-8.003 16.438-18.537 25.776a.63.63 0 01-.893-.053.633.633 0 01.054-.893c14.175-12.566 19.393-28.292 19.444-28.45a.63.63 0 01.797-.406zm5.964-.159c.006.008.083.114.235.305l.1.124c1.289 1.595 6.358 7.365 16.276 12.921 10.708 5.998 29.431 12.97 57.755 12.103.352-.033.64.263.652.612.01.35-.264.64-.613.652-1.628.05-3.227.073-4.792.073-26.011 0-43.457-6.629-53.696-12.38-11.809-6.632-16.74-13.388-16.945-13.672a.633.633 0 011.028-.738zm65.303-2.104a.633.633 0 01-.524 1.152c-31.38-14.278-61.84-4.503-62.144-4.402a.632.632 0 11-.396-1.201c.31-.102 31.237-10.027 63.064 4.451zm-67.255-9.11c.754 1.14-.461 3.27-2.715 4.756-2.252 1.488-4.69 1.768-5.442.628-.751-1.141.462-3.27 2.716-4.758 2.253-1.486 4.689-1.766 5.441-.626z"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                            <path
                                d="M109.095 166.358c-6.218 3.867-3.043 9.96.951 14.193 3.89 4.12 8.31 6.03 13.404 1.72 4.467-3.781 2.405-10.483-.515-13.918-2.938-3.457-10.139-4.298-13.84-1.995"
                                className={`svg-char-${color}-skin`}
                            />
                        </g>
                    </g>
                </svg>
            )
        }
    }
}

export default CharTrailNoQuestions
