import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#3869E4', // Blue[200]
            main: '#0744DE', // Blue[300]
            dark: '#0536B1', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#0537b7',
            main: '#042885',
            dark: '#031953',
            contrastText: '#fff',
        },
        error: {
            light: '#E53869', // Red[200]
            main: '#DF0744', // Red[300]
            dark: '#B20536', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
