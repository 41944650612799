import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import * as yup from 'yup';
import {
    AppBar, Toolbar, IconButton, Slide, Box,
    Typography, Dialog, Container, Grid, Radio,
    FormControl, Divider, RadioGroup, FormControlLabel,
    FormHelperText, Button, TextField
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import EmptyContent from '../../EmptyContent';
import ErrorContent from '../../ErrorContent';
import { LoadingBounce } from '../../Loading';
import { Icon } from '../../Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogReportError(props) {
    const { open, close, question, reasons, sendReport } = props;
    const [value, setValue] = useState('');

    const validations = yup.object().shape({
        reason: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
        message: value === "OUTROS" ? yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')) : null,
    });

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog id="dialog-report-error" fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={close} TransitionComponent={Transition}>
            <AppBar className="appbar appbar-default" position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('REPORT_ERROR_TITLE')}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Container maxWidth="md" className="pt-3 pb-3">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Box p={2} className="highlight-box">
                            <Typography variant="body2" component="h5" className="mb-2">
                                {intl.get("LABEL_QUESTION")}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {question}
                            </Typography>
                        </Box>
                    </Grid>
                    {
                        reasons && reasons.load === "finished" ?
                            !reasons.error ?
                                reasons.data.length !== 0 ?
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <Formik
                                            validationSchema={validations}
                                            initialValues={{
                                                reason: '',
                                                message: ''
                                            }}
                                            onSubmit={(data) => sendReport(data)}
                                        >
                                            {
                                                ({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                                                    <Form noValidate onSubmit={handleSubmit}>
                                                        <Grid container justify="center" spacing={2}>
                                                            <Grid item xs={12} className="pb-0">
                                                                <div className="subheader-content">
                                                                    <Typography variant="subtitle2" component="h3">
                                                                        {intl.get("REPORT_ERROR_SUBTITLE")}
                                                                    </Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset">
                                                                    <RadioGroup aria-label="reason" name="reason" value={values.reason} onChange={(e) => { handleRadioChange(e); handleChange(e) }}>
                                                                        {
                                                                            reasons.data.reasons.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <FormControlLabel control={<Radio color="primary" />} value={item.reason_code} label={item.reason} />
                                                                                        {
                                                                                            index < reasons.data.reasons.length - 1
                                                                                                ?
                                                                                                <Divider />
                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </RadioGroup>
                                                                    {
                                                                        values.reason === "OUTROS" ?
                                                                            <TextField
                                                                                name="message"
                                                                                type="text"
                                                                                label={intl.get('LABEL_TELL_US_REASON')}
                                                                                multiline
                                                                                variant={config.layout.input_variant}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                error={(errors.message && touched.message)}
                                                                                helperText={(errors.message && touched.message) && errors.message}
                                                                            />
                                                                            : null
                                                                    }
                                                                </FormControl>
                                                                <FormHelperText error>
                                                                    {(errors.reason && touched.reason) && errors.reason}
                                                                </FormHelperText>
                                                            </Grid>
                                                            <Grid item xs={12} sm={8} lg={6}>
                                                                <Button
                                                                    id="btn_report_error_send"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                    className="btn-block"
                                                                    onSubmit={() => this.onSubmit()}
                                                                >
                                                                    {intl.get('BTN_SEND')}
                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                    </Form>
                                                )
                                            }
                                        </Formik>
                                    </Grid>
                                    :
                                    <EmptyContent icon={<Icon ic="error" className="icon-xxl" />} text={intl.get("EMPTY_REPORT_REASONS")} />
                                :
                                <ErrorContent />
                            :
                            <Grid container justify="center" className="pt-5">
                                <LoadingBounce />
                            </Grid>
                    }
                </Grid>
            </Container>
        </Dialog >
    )
};
