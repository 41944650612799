import * as React from "react"

function ImgBirthday(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    d="M20.947 22H3.053C2.473 22 2 21.538 2 20.974V12.77h20v8.205c0 .564-.474 1.026-1.053 1.026z"
                    fill="#C45562"
                />
                <path
                    d="M12.526 6.103h-1.052a.52.52 0 00-.527.512v3.59h2.106v-3.59a.52.52 0 00-.527-.512z"
                    fill="#955BA5"
                />
                <path
                    d="M13.579 4.564c0 .85-.707 1.539-1.579 1.539s-1.579-.69-1.579-1.539C10.421 3.714 12 2 12 2s1.579 1.714 1.579 2.564z"
                    fill="#E65748"
                />
                <path
                    d="M12.79 5.333a.78.78 0 01-.79.77.78.78 0 01-.79-.77c0-.424.79-1.282.79-1.282s.79.858.79 1.282z"
                    fill="#FDCE5A"
                />
                <path
                    d="M17.79 6.103h-1.053a.52.52 0 00-.526.512v3.59h2.105v-3.59a.52.52 0 00-.527-.512z"
                    fill="#955BA5"
                />
                <path
                    d="M18.842 4.564c0 .85-.707 1.539-1.579 1.539s-1.579-.69-1.579-1.539c0-.85 1.58-2.564 1.58-2.564s1.578 1.714 1.578 2.564z"
                    fill="#E65748"
                />
                <path
                    d="M18.053 5.333a.78.78 0 01-.79.77.78.78 0 01-.79-.77c0-.424.79-1.282.79-1.282s.79.858.79 1.282z"
                    fill="#FDCE5A"
                />
                <path
                    d="M7.263 6.103H6.211a.52.52 0 00-.527.512v3.59H7.79v-3.59a.52.52 0 00-.526-.512z"
                    fill="#955BA5"
                />
                <path
                    d="M8.316 4.564c0 .85-.707 1.539-1.58 1.539-.871 0-1.578-.69-1.578-1.539C5.158 3.714 6.737 2 6.737 2s1.579 1.714 1.579 2.564z"
                    fill="#E65748"
                />
                <path
                    d="M7.526 5.333a.78.78 0 01-.79.77.78.78 0 01-.789-.77c0-.424.79-1.282.79-1.282s.79.858.79 1.282z"
                    fill="#FDCE5A"
                />
                <path
                    d="M22 12.77c0-1.417-1.178-2.565-2.632-2.565H4.632C3.178 10.205 2 11.353 2 12.77v1.026a.52.52 0 00.526.513h12.632c.29 0 .526.23.526.513v3.022c0 .51.359.984.876 1.066.66.104 1.23-.388 1.23-1.012v-3.59a.52.52 0 01.526-.512c.29 0 .526.23.526.513v1.538a.52.52 0 00.526.513.52.52 0 00.527-.513v-1.025a.52.52 0 01.526-.513h1.053a.52.52 0 00.526-.513V12.77z"
                    fill="#F49EA8"
                />
            </g>
        </svg>
    )
}

export default ImgBirthday
