import React from 'react';
import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import { imageParser, numberMask, getDateTime } from '../../../utils/utils';
import QrImage from '../../../components/QrImage';
import { Icon, Points } from '../../../components/Images/Images';

export default function RedeemItem(props) {
    const { item, action } = props;

    return (
        <Card className="redeem-card">
            <CardActionArea onClick={action}>
                <CardContent className="redeem-card-content">
                    <QrImage
                        src={item.prize_image ? imageParser(item.prize_image) : ""}
                        effect="blur"
                        className="redeem-card-image"
                        alt={item.prize_name}
                    />
                    <div className="redeem-card-content-info">
                        <div className="redeem-card-content-info-title">
                            <Typography variant="body1" component="h4">
                                {item.prize_name}
                            </Typography>
                        </div>
                        <Typography variant="body2" component="p" className="font-weight-bold">
                            {item.company_name}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {item.prize_availability}
                        </Typography>
                    </div>
                    <div className="d-flex align-self-center">
                        <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                    </div>
                </CardContent>
                <div className="redeem-card-footer">
                    <div className="redeem-card-footer-date">
                        <Icon ic="date_finish" color="lighter" className="icon-xs mr-1" />
                        <Typography variant="body2" component="p">
                            {getDateTime(item.redeem_date)}
                        </Typography>
                    </div>
                    <div className="redeem-card-footer-price">
                        <Points type="qis" size="md" />
                        <Typography variant="body1" component="p" className="text-qis ml-1">
                            {numberMask(item.qi_spent)}
                        </Typography>
                    </div>
                </div>
            </CardActionArea>
        </Card>
    )
}