import React, { forwardRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AchievementsActions } from '../../redux/ducks/achievements';
import { imageParser } from '../../utils/utils';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import {
    AppBar, CardActionArea, Grid, Container, Typography, IconButton, List, Divider,
    Toolbar, useMediaQuery, Slide, Tabs, Tab, ListItem, ListItemAvatar, ListItemText,
    Badge
} from '@material-ui/core';
import DialogBadgeDetails from './components/DialogBadgeDetails';
import DialogMissionsDetails from './components/DialogMissionsDetails';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import QrImage from '../../components/QrImage';
import { Icon } from '../../components/Images/Images';
import { LoadingContent } from '../../components/Loading';

// Componente para transição de diálogo
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Propriedades de acessibilidade para tabs
function tabsProps(index) {
    return {
        id: `appbar-tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

// Painel de conteúdo vinculado a cada tab
function TabPanel({ children, value, index, ...other }) {
    return (
        <Grid
            container
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            className="qr-tabpanel"
            {...other}
        >
            {value === index && children}
        </Grid>
    );
}

export default function AchievementsIndex(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();

    // Seletores e estados globais
    const auth = useSelector(state => state.auth);
    const badgeList = useSelector(state => state.achievements.badgeList);
    const missionList = useSelector(state => state.achievements.missionList);
    const modules = useSelector(state => state.auth.modules);

    // Estados e hooks
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme || config.layout.theme);
    const [value, setValue] = useState(0);
    const [openBadges, setOpenBadges] = useState(false);
    const [openMissions, setOpenMissions] = useState(false);
    const [data, setData] = useState([]);
    const bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_ACHIEVEMENTS');

    // Fetch inicial para listas de badges e missões
    useEffect(() => {
        if (modules.MISSIONS.BADGE_ENABLED === "Y") {
            dispatch(AchievementsActions.getBadgeList({}));
        }

        if (modules.MISSIONS.ACHIEVEMENT_ENABLED === "Y") {
            dispatch(AchievementsActions.getMissionList({
                page: 1,
                limit: 0
            }));
        }

        return () => {
            dispatch(AchievementsActions.reset());
        };
    }, [dispatch, modules.MISSIONS]);

    // Criação dinâmica de abas (tabs) com base no config
    function createOrderedTabs() {
        const configMap = {
            MISSIONS: () => modules.MISSIONS.ACHIEVEMENT_ENABLED === "Y" && "ACHIEVEMENTS_MISSIONS_TITLE",
            BADGES: () => modules.MISSIONS.BADGE_ENABLED === "Y" && "ACHIEVEMENTS_BADGES_TITLE",
        };

        return config.achievements.order
            .map(key => {
                const labelKey = configMap[key]?.(); // Obtém a chave do título
                return labelKey
                    ? {
                        type: key,
                        label: intl.get(labelKey),
                        notifications: key === "MISSIONS" ? JSON.parse(localStorage.getItem('notifications_count'))?.total_achievements_pending_redeem || 0 : JSON.parse(localStorage.getItem('notifications_count'))?.total_badge_pending_redeem || 0
                    }
                    : null; // Retorna `null` se a chave do título for inválida
            })
            .filter(Boolean); // Remove valores nulos
    }

    const tabs = createOrderedTabs();

    // Controle de troca de abas
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Componente de AppBar com tabs dinâmicas
    const QrAppBar = () => (
        <AppBar
            className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}${tabs.length > 1 ? " __tabs" : ""}`}
            position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`}
            color="primary"
        >
            <Toolbar>
                {config.layout.navbar && !bottomMenuItem && !isScreenUpMd && (
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                )}
                <div className="w-100 overflow-hidden">
                    <Typography variant="h4" noWrap>
                        {intl.get('ACHIEVEMENTS_TITLE')}
                    </Typography>
                    {(bottomMenuItem || isScreenUpMd) && (
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {intl.get("ACHIEVEMENTS_SUBTITLE")}
                            </Typography>
                        </div>
                    )}
                </div>
            </Toolbar>

            {tabs.length > 1 && (
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                    variant={isScreenUpMd ? "standard" : "fullWidth"}
                    className="appbar-default-tabs"
                    centered
                >
                    {tabs.map((item, index) => (
                        
                        <Tab 
                        key={index} 
                        label={
                            <Badge
                              badgeContent={item.notifications}
                                color="error"
                                className={item.notifications && item.notifications > 0 ? "__has-notifications" : ""}
                            >
                              {item.label}
                            </Badge>
                          } 
                        {...tabsProps(index)} 
                        />
                    ))}
                </Tabs>
            )}
        </AppBar>
    );

    // Controle de diálogo para detalhes de badges
    const handleOpenBadges = (data) => {
        setOpenBadges(true);
        setData(data);
    };

    const handleCloseBadges = () => {
        setOpenBadges(false);
        setData([]);
    };

    // Controle de diálogo para detalhes de missões
    const handleOpenMissions = (data) => {
        setOpenMissions(true);
        setData(data);
    };

    const handleCloseMissions = () => {
        setOpenMissions(false);
        setData([]);
    };

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {auth.authenticated && (
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="achievements-list" bottomNavigation={bottomMenuItem} hideSidebar>
                            {config.layout.navbar && <QrAppBar />}
                            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                                {tabs.map((item, index) => (
                                    <TabPanel key={index} value={value} index={index}>
                                        {item.type === "MISSIONS" ? (
                                            missionList && missionList.load === "finished" ? (
                                                !missionList.error ? (
                                                    missionList.data.achievements.length !== 0 ? (
                                                        <Container maxWidth="lg" className="container-list missions">
                                                            <div className="subheader-content justify-content-between">
                                                                <Typography variant="subtitle2" component="h3">
                                                                    {intl.get("ACHIEVEMENTS_LIST_MISSIONS_SUBHEADER")}
                                                                </Typography>
                                                                <Typography variant="subtitle2" component="p">
                                                                    {`${missionList.data.total_user} / ${missionList.data.total}`}
                                                                </Typography>
                                                            </div>
                                                            <List>
                                                                {
                                                                    missionList.data.achievements.map((item, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <ListItem button className="missions-content" onClick={() => handleOpenMissions(item)}>
                                                                                    <ListItemAvatar>
                                                                                        <QrImage
                                                                                            src={imageParser(item.image)}
                                                                                            effect="blur"
                                                                                            className="missions-image"
                                                                                            alt="Badge"
                                                                                        />
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText
                                                                                        disableTypography
                                                                                        primary={
                                                                                            <Typography
                                                                                                component="p"
                                                                                                variant="body1"
                                                                                            >
                                                                                                {item.title}
                                                                                            </Typography>
                                                                                        }
                                                                                        secondary={
                                                                                            <React.Fragment>
                                                                                                {
                                                                                                    item.description &&
                                                                                                    <Typography
                                                                                                        component="p"
                                                                                                        variant="body2"
                                                                                                        className="mt-1"
                                                                                                    >
                                                                                                        {item.description}
                                                                                                    </Typography>
                                                                                                }
                                                                                                <div className="mt-2">
                                                                                                    {
                                                                                                        item.levels.map((level, index) => {
                                                                                                            return (
                                                                                                                <Icon
                                                                                                                    key={index}
                                                                                                                    ic={`checkbox_${level.completed === "Y" && level.is_redeemed === "Y" ? "checked" : "unchecked"}`}
                                                                                                                    color={`${level.completed === "Y" ? "primary" : "light"}`}
                                                                                                                    className="icon-xs mr-1"
                                                                                                                />
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    />
                                                                                </ListItem>
                                                                                <Divider variant="inset" component="hr" />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </List>
                                                        </Container>
                                                    ) : (
                                                        <Container maxWidth="lg">
                                                            <EmptyContent
                                                                icon={<Icon ic="medal_filled" className="icon-xxl" />}
                                                                text={intl.get("EMPTY_ACHIEVEMENTS_MISSIONS")}
                                                            />
                                                        </Container>
                                                    )
                                                ) : (
                                                    <Container maxWidth="lg">
                                                        <ErrorContent />
                                                    </Container>
                                                )
                                            ) : (
                                                <Container maxWidth="lg">
                                                    <LoadingContent />
                                                </Container>
                                            )
                                        ) : item.type === "BADGES" ? (
                                            badgeList && badgeList.load === "finished" ? (
                                                !badgeList.error ? (
                                                    badgeList.data.badges.length !== 0 ? (
                                                        <Container maxWidth="lg" className="pt-2 pb-4">
                                                            <div className="subheader-content justify-content-between">
                                                                <Typography variant="subtitle2" component="h3">
                                                                    {intl.get("ACHIEVEMENTS_LIST_BADGES_SUBHEADER")}
                                                                </Typography>
                                                                <Typography variant="subtitle2" component="p">
                                                                    {`${badgeList.data.total_user} / ${badgeList.data.total}`}
                                                                </Typography>
                                                            </div>
                                                            <Grid container spacing={2}>
                                                                {
                                                                    badgeList.data.badges.map((item, index) => {
                                                                        return (
                                                                            <Grid key={index} item xs={4} sm={3} lg={2} align="center">
                                                                                <CardActionArea aria-hidden="false" className={`badge-content${item.redeem_available === "Y" ? " __redeemed" : ""}`} onClick={() => handleOpenBadges(item)}>
                                                                                    <QrImage
                                                                                        src={imageParser(item.image)}
                                                                                        effect="blur"
                                                                                        className="badge-image"
                                                                                        alt="Badge"
                                                                                    />
                                                                                    <Typography variant="body1" component="h4" className="badge-name">
                                                                                        {item.name}
                                                                                    </Typography>
                                                                                </CardActionArea>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Container>
                                                    ) : (
                                                        <Container maxWidth="lg">
                                                            <EmptyContent
                                                                icon={<Icon ic="medal_filled" className="icon-xxl" />}
                                                                text={intl.get("EMPTY_ACHIEVEMENTS_BADGES")}
                                                            />
                                                        </Container>
                                                    )
                                                ) : (
                                                    <Container maxWidth="lg">
                                                        <ErrorContent />
                                                    </Container>
                                                )
                                            ) : (
                                                <Container maxWidth="lg">
                                                    <LoadingContent />
                                                </Container>
                                            )
                                        ) : (
                                            <Container maxWidth="lg">
                                                <EmptyContent
                                                    icon={<Icon ic="medal_filled" className="icon-xxl" />}
                                                    text={intl.get("EMPTY_ACHIEVEMENTS")}
                                                />
                                            </Container>
                                        )}
                                    </TabPanel>
                                ))}
                            </div>
                        </QrContent>
                    </div>
                    <DialogMissionsDetails
                        details
                        open={openMissions}
                        close={handleCloseMissions}
                        transition={Transition}
                        data={data}
                    />
                    <DialogBadgeDetails
                        details
                        open={openBadges}
                        close={handleCloseBadges}
                        transition={Transition}
                        data={data}
                    />
                </div>
            )}
        </div>
    );
};
