import { combineReducers } from 'redux';
import authReducer from './auth';
import homeReducer from './home';
import feedsReducer from './feeds';
import scormReducer from './scorm';
import settingsReducer from './settings';
import classroom_courseReducer from './classroom_course';
import libraryReducer from './library';
import freeReducer from './free';
import examReducer from './exam';
import trailReducer from './trail';
import codeRedeemReducer from './code_redeem';
import notification_moduleReducer from './notification_module';
import periodicReducer from './periodic';
import profileReducer from './profile';
import prizesReducer from './prizes';
import achievementsReducer from './achievements';
import rankingReducer from './ranking';
import introReducer from './intro';
import managerReducer from './manager';
import myHistoryReducer from './my_history';
import avatarReducer from './avatar';
import qrLayoutReducer from './qr_layout';
import surveyReducer from './survey';
import gameReducer from './game';
import publicReducer from './public';
import faqReducer from './faq';


export default combineReducers({
    auth: authReducer,
    home: homeReducer,
    feeds: feedsReducer,
    scorm: scormReducer,
    settings: settingsReducer,
    classroom_course: classroom_courseReducer,
    library: libraryReducer,
    free: freeReducer,
    exam: examReducer,
    trail: trailReducer,
    codeRedeem: codeRedeemReducer,
    notification: notification_moduleReducer,
    periodic: periodicReducer,
    profile: profileReducer,
    prizes: prizesReducer,
    achievements: achievementsReducer,
    ranking: rankingReducer,
    intro: introReducer,
    manager: managerReducer,
    myHistory: myHistoryReducer,
    avatar: avatarReducer,
    qrLayout: qrLayoutReducer,
    survey: surveyReducer,
    game: gameReducer,
    public: publicReducer,
    faq: faqReducer
});