import * as React from "react"

function ImgTimeout(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path
                    fill="#C45562"
                    d="m6.59 4.499-.846-1.27-1.077.718.788 1.181.98.315.155-.944Zm9.367.63.787-1.182-1.077-.718-.846 1.27v1.117l1.136-.487Z"
                />
                <path
                    fill="#6ADFDF"
                    d="M10.059 2.294V3.61l.323.733.324-.755.431-.647-.431-.647-.324-.507z"
                />
                <path
                    fill="#49D8D8"
                    d="m10.706 3.588.324.68.323-.658V2.294l-.324-.507-.323.507v1.294Z"
                />
                <path
                    fill="#7A4B88"
                    d="M1 13.294A9.7 9.7 0 0 0 10.706 23l.82-10.159-.82-9.253A9.7 9.7 0 0 0 1 13.294Z"
                />
                <path
                    fill="#5F3A6A"
                    d="M10.706 3.588V23a9.611 9.611 0 0 0 3.908-.82l5.786-9.351c-.239-5.08-4.425-9.24-9.694-9.24Z"
                />
                <path
                    fill="#8BE6E6"
                    d="M3.588 13.294c0 4.04 3.321 7.124 7.118 7.122l.647-7.445-.647-6.794c-3.925 0-7.118 3.193-7.118 7.117Z"
                />
                <path
                    fill="#6ADFDF"
                    d="M10.706 6.176v14.24a7.116 7.116 0 0 0 1.968-.28l5.032-8.132a7.12 7.12 0 0 0-7-5.827Z"
                />
                <path
                    fill="#E9F4FF"
                    d="M10.059 8.765V7.507a5.796 5.796 0 0 0-2.986 1.24l.888.887-.915.915-.888-.888a5.795 5.795 0 0 0-1.24 2.986h1.258v1.294H4.918a5.796 5.796 0 0 0 1.24 2.986l.888-.888.915.915-.888.888a5.81 5.81 0 0 0 3.633 1.276l.82-5.436-.82-4.917h-.647Z"
                />
                <path
                    fill="#CDE8FF"
                    d="M16.41 12.109a5.788 5.788 0 0 0-1.148-2.456l-.896.896-.915-.915.895-.895a5.802 5.802 0 0 0-2.993-1.233v1.259h-.647v10.353a5.823 5.823 0 0 0 1.482-.19l4.222-6.82Z"
                />
                <path
                    fill="#C45562"
                    d="M12 17.5c0 3.033 2.467 5.5 5.5 5.5l.949-5.176L17.5 12a5.506 5.506 0 0 0-5.5 5.5Z"
                />
                <path
                    fill="#99424C"
                    d="M17.5 12v11c3.033 0 5.5-2.467 5.5-5.5S20.533 12 17.5 12Z"
                />
                <path fill="#8BE6E6" d="M10.706 1H8.118v1.294h2.588l.82-.647-.82-.647Z" />
                <path fill="#6ADFDF" d="M10.706 1v1.294h2.588V1h-2.588Z" />
                <path
                    fill="#C45562"
                    d="M10.059 12.647H8.118v1.294h2.588l.54-1.941-.54-1.941h-.647z"
                />
                <path fill="#99424C" d="M11.353 13.941V10.06h-.647v3.882z" />
                <path fill="#FFDA44" d="M16.853 20.412h.647l.474-.647-.474-.647h-.647z" />
                <path fill="#FDCE5A" d="M16.853 14.588v3.236h.647l.54-1.618-.54-1.618z" />
                <path
                    fill="#FDC436"
                    d="M17.5 19.118h1v1.294h-1v-1.294Zm0-4.53h1v3.236h-1v-3.236Z"
                />
            </g>
        </svg>
    )
}

export default ImgTimeout
