import { notificationModuleService }  from '../../services/notification_module.service';

// CONST
export const Types = {
    LIST: 'notification_module/LIST',
    PAGE: 'notification_module/PAGE',
    CLEAR: 'notification_module/CLEAR',
    UPDATEALL: 'notification_module/UPDATEALL',
    NOTIFICATIONALL: 'notification_module/NOTIFICATIONALL',
    NOTIFICATION: 'notification_module/NOTIFICATION',

};

// INITIAL STATE

const initialState = {
    notifications: [],
    page: 1,
    limit:  100,
    status: '',
    updateAll: false
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case Types.LIST:
            // var newArray = state.notifications.concat(action.payload.notifications);
            // if(newArray.length === 0) {
            //     newArray = 'noNews'
            // }

            return {
                ...state,
                notifications: action.payload.notifications,
                status: action.payload.status
            }
        case Types.PAGE:
            return {
                ...state,
                page: action.payload.page
            }
        case Types.CLEAR:
            return {
                ...state,
                page: 1,
                notifications: [],
                status: ''
            }
        case Types.UPDATEALL:
            return {
                ...state,
                updateAll:  action.payload.updateAll,
            }
        default:
            return state
    }
}

// ACTIONS
function list(params) {
    var userAux = JSON.parse(localStorage.getItem('auth.state.user'));
    return dispatch => {
        return notificationModuleService.notificationList(params).then(data => {
            let unreadTotal = data.list_notifications.filter((not) =>{ return (not.read === "N")}).length;
            if(parseInt(userAux.notification_count) !== unreadTotal) {
                userAux.notification_count = unreadTotal;
                localStorage.setItem('auth.state.user', JSON.stringify(userAux))
            }
            dispatch({
                type: Types.LIST,
                payload: {
                    notifications: data.list_notifications,
                    status: 'finished',
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        })
    }
}

function clear() {
    return dispatch => {
        dispatch({
            type: Types.CLEAR,
        });
    }
}

function page(params) {
    return dispatch => {
        dispatch({
            type: Types.PAGE,
            payload: {
                page: params.page
            }
        });
    }
}

function updateAll(params) {
    return dispatch => {
        dispatch({
            type: Types.UPDATEALL,
            payload: {
                updateAll: params
            }
        });
    }
}

function notificationUpdateAll(params) {
    return dispatch => {
        return notificationModuleService.notificationUpdateAll(params).then(data => {
            dispatch(clear());
            dispatch(list({page: 1, limit: 100}));
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        })
    }
}

function notificationUpdate(params) {
    return dispatch => {
        return notificationModuleService.notificationUpdate(params).then(data => {
            dispatch(list({page: 1, limit: 100}));
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        })
    }
}

export const actions = {
    list,
    page,
    clear,
    updateAll,
    notificationUpdateAll,
    notificationUpdate,
}
