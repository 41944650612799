import React, {useState} from 'react';
import intl from 'react-intl-universal';
import {managerService} from '../../../services/manager.service';
import {useSelector, useDispatch} from 'react-redux';
import {actions as ManagerActions} from '../../../redux/ducks/manager';
import {
    dateMask,
    getTypeCourse,
    download_csv,
    getWordByInitials,
    getStatusCourseByType,
    getIconCourseByType
} from '../../../utils/utils';
import {
    Grid, TextField, IconButton, Tooltip, Select, MenuItem,
    FormControl, InputLabel
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import ErrorContent from '../../../components/ErrorContent';
import {LoadingBounce} from '../../../components/Loading';
import {Icon} from '../../../components/Images/Images';

export default function TableTeamActivities(props) {
    const dispatch = useDispatch();
    const [responsive] = useState('standard');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [colName, setColName] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [loading, setLoading] = useState(false);
    const [download, setDownload] = useState(false);
    const [textFilter, setTextFilter] = useState('');
    const [obligatoryFilter, setObligatoryFilter] = useState('');
    const [typeCourseFilter, setTypeCourseFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [enableFilter, setEnableFilter] = useState('');
    const auth = useSelector(state => state.auth);
    const teamActivities = useSelector(state => state.manager.teamActivities);
    let data = [];
    let downloadList = [];

    const columns = [
        {
            name: "complete",
            label: intl.get("LABEL_FINISHED"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_registration",
            label: intl.get("LABEL_REGISTER"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_full_name",
            label: intl.get("LABEL_NAME"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "user_role",
            label: intl.get("LABEL_ROLE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "date_start",
            label: intl.get("LABEL_START"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "date_finish",
            label: intl.get("LABEL_END"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "type_course",
            label: intl.get("LABEL_TYPE_COURSE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "name",
            label: intl.get("LABEL_COURSE"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "workload",
            label: intl.get("LABEL_WORKLOAD"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "obligatory",
            label: intl.get("LABEL_OBLIGATORY"),
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "enable",
            label: intl.get("LABEL_ENABLED"),
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    const options = {
        serverSide: true,
        page: page,
        loading: false,
        count: teamActivities && teamActivities.load === "finished" ? parseInt(teamActivities.data.total_filtered) : 0,
        rowsPerPageOptions: [10, 50, 100],
        print: false,
        filter: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        responsive,
        selectableRowsHeader: false,
        textLabels: {
            body: {
                noMatch: intl.get("TABLE_BODY_NO_MATCH"),
                toolTip: intl.get("TABLE_BODY_ORDER"),
                columnHeaderTooltip: column => intl.get("TABLE_BODY_ORDER_BY") + " " + column.label
            },
            pagination: {
                next: intl.get("TABLE_PAGINATION_NEXT"),
                previous: intl.get("TABLE_PAGINATION_PREVIOUS"),
                rowsPerPage: intl.get("TABLE_PAGINATION_ROWS_PAGE"),
                displayRows: intl.get("TABLE_PAGINATION_DISPLAY_ROWS"),
            },
            toolbar: {
                search: intl.get("TABLE_TOOLBAR_SEARCH"),
                downloadCsv: intl.get("TABLE_TOOLBAR_DOWNLOAD"),
                print: intl.get("TABLE_TOOLBAR_PRINT"),
                viewColumns: intl.get("TABLE_TOOLBAR_VIEW_COLUMNS"),
                filterTable: intl.get("TABLE_TOOLBAR_FILTER_TABLE"),
            },
            filter: {
                all: intl.get("TABLE_FILTER_ALL"),
                title: intl.get("TABLE_FILTER_TITLE"),
                reset: intl.get("TABLE_FILTER_RESET"),
            },
            viewColumns: {
                title: intl.get("TABLE_COLUMNS_TITLE"),
                titleAria: intl.get("TABLE_COLUMNS_TITLE_ARIA"),
            },
            selectedRows: {
                text: intl.get("TABLE_ROWS_TEXT"),
                delete: intl.get("TABLE_ROWS_DELETE"),
                deleteAria: intl.get("TABLE_ROWS_DELETE_ARIA"),
            },
        },
        onChangePage: async (currentPage) => {
            setPage(currentPage);
            setLoading(true);
            await dispatch(ManagerActions.getTeamActivities({
                id_manager: auth.user.id_user,
                offset: currentPage,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onChangeRowsPerPage: async (numberOfRows) => {
            setRows(numberOfRows);
            setLoading(true);
            await dispatch(ManagerActions.getTeamActivities({
                id_manager: auth.user.id_user,
                offset: page,
                limit: numberOfRows,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
                order_list: `${colName} ${orderBy}`
            }));
            setLoading(false);
        },
        onColumnSortChange: async (col, dir) => {
            setLoading(true);
            setColName(col);
            setOrderBy(dir);
            await dispatch(ManagerActions.getTeamActivities({
                id_manager: auth.user.id_user,
                offset: page,
                limit: rows,
                filter: textFilter ? 'filtro_geral:' + textFilter : null,
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
                order_list: `${col} ${dir}`
            }));
            setLoading(false);
        },
        customToolbar: () => {
            return <CustomToolbar/>
        },
        customSearchRender: () => {
            return (
                EnhancedTableToolbar()
            );
        },
    }

    async function filterTableList() {
        setLoading(true);
        await dispatch(ManagerActions.getTeamActivities({
            id_manager: auth.user.id_user,
            offset: 0,
            limit: rows,
            filter: textFilter ? 'filtro_geral:' + textFilter : "",
            obligatory: obligatoryFilter,
            type_course: typeCourseFilter,
            status: statusFilter,
            enable: enableFilter,
            order_list: `${colName} ${orderBy}`
        }));
        setLoading(false);
    }

    if (teamActivities && teamActivities.load === "finished") {
        if (!teamActivities.error) {
            teamActivities.data.team_activities.map((row) => {
                let cols;

                cols = {
                    [columns[0].name]: row.complete
                        ? <div className="text-center pr-3">{getIconCourseByType(row.complete, row.type_course)}</div>
                        : <div className="text-center pr-3">{getIconCourseByType(null, row.type_course)}</div>,
                    [columns[1].name]: row.user_registration
                        ? <div className="text-center pr-3">{row.user_registration}</div>
                        : '-',
                    [columns[2].name]: row.user_full_name
                        ? row.user_full_name
                        : '-',
                    [columns[3].name]: row.user_role
                        ? row.user_role
                        : '-',
                    [columns[4].name]: row.date_start
                        ? dateMask(row.date_start)
                        : '-',
                    [columns[5].name]: row.date_finish
                        ? dateMask(row.date_finish)
                        : '-',
                    [columns[6].name]: row.type_course
                        ? getTypeCourse(row.type_course)
                        : '-',
                    [columns[7].name]: row.name
                        ? row.name
                        : '-',
                    [columns[8].name]: row.workload
                        ? row.workload
                        : '-',
                    [columns[9].name]: row.obligatory
                        ? <div className="text-center pr-3">{getWordByInitials(row.obligatory)}</div>
                        : '-',
                    [columns[10].name]: row.enable
                        ? <div className="text-center pr-3">{getWordByInitials(row.enable)}</div>
                        : '-',
                }

                return data.push(cols);
            })
        }
    }

    async function downloadCSV() {
        let ret;
        let csv;

        csv = `${columns[0].label};${columns[1].label};${columns[2].label};${columns[3].label};${columns[4].label};${columns[5].label};${columns[6].label};${columns[7].label};${columns[8].label};${columns[9].label};${columns[10].label}\n`;

        setDownload(true);

        try {
            ret = await managerService.getTeamActivities({
                id_manager: auth.user.id_user,
                offset: 0,
                limit: 0,
                filter: textFilter ? 'filtro_geral:' + textFilter : "",
                obligatory: obligatoryFilter,
                type_course: typeCourseFilter,
                status: statusFilter,
                enable: enableFilter,
            });
        } catch (err) {
            console.log(err)
        }

        ret.team_activities.map(row => {
            const list = [
                row.complete ? getStatusCourseByType(row.complete, row.type_course) : getStatusCourseByType(null, row.type_course),
                row.user_registration ? row.user_registration : '-',
                row.user_full_name ? row.user_full_name : '-',
                row.user_role ? row.user_role : '-',
                row.date_start ? dateMask(row.date_start) : '-',
                row.date_finish ? dateMask(row.date_finish) : '-',
                row.type_course ? getTypeCourse(row.type_course) : '-',
                row.name ? row.name : '-',
                row.workload ? row.workload : '-',
                row.obligatory ? getWordByInitials(row.obligatory) : '-',
                row.enable ? getWordByInitials(row.enable) : '-',
            ]

            return downloadList.push(list);
        })

        download_csv(csv, downloadList);
        setDownload(false);
    }

    const CustomToolbar = () => {
        return (
            <Tooltip title={intl.get("TABLE_TOOLBAR_DOWNLOAD")}>
                <IconButton color="primary" onClick={() => downloadCSV()}>
                    {download ? <LoadingBounce size={24}/> : <Icon ic="download"/>}
                </IconButton>
            </Tooltip>
        )
    }

    const EnhancedTableToolbar = () => {
        return (
            <Grid container direction="row" alignItems="center" className="table-custom-search" spacing={2}>
                <Grid item xs={12} md>
                    <TextField
                        fullWidth
                        id="filter_text"
                        label={intl.get("TABLE_TOOLBAR_SEARCH")}
                        value={textFilter}
                        onChange={(e) => setTextFilter(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <FormControl className="w-100">
                        <InputLabel id="obligatory-filter-label">
                            {intl.get("LABEL_OBLIGATORY")}
                        </InputLabel>
                        <Select
                            labelId="obligatory-filter-label"
                            id="obligatory-filter-select"
                            value={obligatoryFilter}
                            onChange={(e) => setObligatoryFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="Y">{intl.get("LABEL_YES")}</MenuItem>
                            <MenuItem value="N">{intl.get("LABEL_NO")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md>
                    <FormControl className="w-100">
                        <InputLabel id="type-course-filter-label">
                            {intl.get("LABEL_TYPE_COURSE")}
                        </InputLabel>
                        <Select
                            labelId="type-course-filter-label"
                            id="type-course-filter-select"
                            value={typeCourseFilter}
                            onChange={(e) => setTypeCourseFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            {
                                auth.modules.TRAIL && auth.modules.TRAIL.ENABLED === 'Y' && <MenuItem value="C">{intl.get("TRAIL_TITLE")}</MenuItem>
                            }
                            {
                                auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'Y' && <MenuItem value="S">{intl.get("SCORM_TITLE")}</MenuItem>
                            }
                            {
                                auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'Y' && <MenuItem value="T">{intl.get("CLASSROOM_TITLE")}</MenuItem>
                            }
                            {
                                auth.modules.EXAM &&  auth.modules.EXAM.ENABLED === 'Y' && <MenuItem value="E">{intl.get("EXAM_TITLE")}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md>
                    <FormControl className="w-100">
                        <InputLabel id="status-filter-label">
                            {intl.get("LABEL_STATUS")}
                        </InputLabel>
                        <Select
                            labelId="status-filter-label"
                            id="status-filter-select"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="NS">{intl.get("LABEL_NOT_STARTED")}</MenuItem>
                            <MenuItem value="IP">{intl.get("LABEL_IN_PROGRESS")}</MenuItem>
                            <MenuItem value="DO">{intl.get("LABEL_COMPLETED")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md>
                    <FormControl className="w-100">
                        <InputLabel id="enable-filter-label">
                            {intl.get("LABEL_ENABLED")}
                        </InputLabel>
                        <Select
                            labelId="enabled-filter-label"
                            id="enabled-filter-select"
                            value={enableFilter}
                            onChange={(e) => setEnableFilter(e.target.value)}
                        >
                            <MenuItem value="">{intl.get("LABEL_ALL")}</MenuItem>
                            <MenuItem value="Y">{intl.get("LABEL_YES")}</MenuItem>
                            <MenuItem value="N">{intl.get("LABEL_NO")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md>
                    <IconButton onClick={() => filterTableList()}>
                        <Icon ic="send"/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    return (
        <MUIDataTable
            className="qr-table"
            title={intl.get("MANAGER_TABLE_TEAM_ACTIVITIES_TITLE")}
            data={
                !loading && (teamActivities && teamActivities.load === "finished") ?
                    !teamActivities.error ?
                        data
                        :
                        [[<ErrorContent type="section"/>]]
                    :
                    [[<Grid container direction="row" alignItems="center" justify="center"><LoadingBounce/></Grid>]]
            }
            columns={
                !loading && (teamActivities && teamActivities.load === "finished") ?
                    !teamActivities.error ?
                        columns
                        :
                        [[]]
                    :
                    [[]]
            }
            options={options}
        />
    )
}
