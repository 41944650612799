import React, { useEffect, useState } from 'react';
import config from '../../../config';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { yiq } from 'yiq';
import { Doughnut } from 'react-chartjs-2';
import {
    AppBar, Toolbar, Container, List, Typography,
    Grid, Divider, Box, IconButton
} from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import ListItemStatistics from '../../../components/ListItemStatistics';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images'

const project = config.app.name_project.toLowerCase()
let theme = require(`../../../assets/scss/projects/${project}/_export.scss`)

export default function TrailStatistics(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const trail = props.location.state && props.location.state.trail ? props.location.state.trail : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const { statistics } = useSelector(state => state.trail);
    const [didMount, setDidMount] = useState(false);
    let generalAverage = Math.round(statistics.data && statistics.data.general_average * 100);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            setTimeout(() => {
                dispatch(TrailActions.getStatistics({
                    id_course: props.match.params.idTrail
                }));
            }, 500);
        }

        return () => {
            dispatch(TrailActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    const data = {
        datasets: [{
            data: [generalAverage, 100 - generalAverage],
            backgroundColor: [
                yiq(theme['appbar'], { threshold: 225, colors: { light: theme['white'], dark: theme['overlay'] } }),
                'rgba(0, 0, 0, 0.1)'
            ],
            borderWidth: 0
        }]
    }

    const options = {
        cutoutPercentage: 85,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            }
        },
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false
        },
        title: {
            display: false,
        },
        hover: {
            mode: null
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => {
                            origin === "details" ?
                                history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                                    state: {
                                        origin: "statistics"
                                    }
                                })
                                :
                                history.goBack()
                        }}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("TRAIL_STATISTICS_TITLE")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {trail.title}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const Header = () => {
        return (
            <Grid container id="trail-statistics-header" className="appbar-custom-content __default" alignItems="center" justify="center">
                <div className="doughnut-chart">
                    <Doughnut data={data} options={options} width={148} height={148} />
                    <div className="doughnut-chart-text">
                        <Typography variant="body2" component="p">
                            {intl.get("TRAIL_STATISTICS_GENERAL_AVERAGE_LABEL")}
                        </Typography>
                        <Typography variant="h2" component="h2">
                            {generalAverage}%
                        </Typography>
                    </div>
                </div>
            </Grid>
        )
    }

    const ListStatistics = () => {
        let performanceArray = statistics.data.performance;
        performanceArray.sort((a, b) => parseFloat(b.progress) - parseFloat(a.progress));

        return (
            <List>
                {
                    performanceArray.map((item, index) => {
                        return (
                            <div key={index}>
                                <ListItemStatistics
                                    title={item.title}
                                    progress={Math.round(item.progress * 100)}
                                    gutters={true}
                                    action={() => history.push({
                                        pathname: `${history.location.pathname.replace(/./g, '')}/trail/${props.match.params.idTrail}/steps`,
                                        state: {
                                            trail: trail,
                                            origin: "statistics",
                                            stage: item
                                        }
                                    })}
                                />
                                {index < performanceArray.length - 1
                                    ?
                                    <Divider />
                                    : null
                                }
                            </div>
                        )
                    })
                }
            </List>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="trail-statistics" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    {
                        statistics && statistics.load === "finished" ?
                            !statistics.error ?
                                statistics.data.length !== 0 ?
                                    <div>
                                        {/* Header */}
                                        <Header />

                                        {/* Content */}
                                        <Container maxWidth="md" className="pt-3 pb-4">
                                            <Grid container justify="center">
                                                {/* List */}
                                                <Grid item xs={12} sm={10} xl={12}>
                                                    <ListStatistics />
                                                </Grid>

                                                {/* Tip Box */}
                                                <Grid item xs={12} sm={10} xl={12}>
                                                    <Box p={2} className="highlight-box">
                                                        <Grid container direction="row" alignItems="center" className="mb-1">
                                                            <Icon ic="idea" color="info" className="icon-xs mr-1" />
                                                            <Typography variant="body1" component="h5">
                                                                {intl.get("TRAIL_STATISTICS_TIP_TITLE")}
                                                            </Typography>
                                                        </Grid>
                                                        <Typography variant="body2" component="p">
                                                            {statistics.data.tip}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </div>
                                    :
                                    <Container maxWidth="md">
                                        <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL")} />
                                    </Container>
                                :
                                <Container maxWidth="md">
                                    <ErrorContent />
                                </Container>
                            :
                            <Container maxWidth="md">
                                <LoadingContent />
                            </Container>
                    }
                </QrContent>
            </div>
        </div>
    )
}
