import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { imageParser } from '../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AchievementsActions } from '../../../redux/ducks/achievements';
import {
    Grid, Dialog, Button, DialogContent, Typography, IconButton
} from '@material-ui/core';
import { Icon } from '../../../components/Images/Images';
import ImgBadgeStars from '../../../dist/img/svgr/ImgBadgeStars';
import QrImage from '../../../components/QrImage';
import PointsBar from '../../../components/PointsBar';
import { LoadingBounce } from '../../../components/Loading';
import SnackBar from '../../../components/snackBar';

const DialogBadgeDetails = ({ data, open, close, transition, details }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const badgeRedeem = useSelector(state => state.achievements.badgeRedeem);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const DialogTitle = ({ onClose }) => {
        return (
            <div className="achievements-details-content-title">
                <div className="__empty"></div>
                {
                    !details &&
                    <div className="w-100">
                        <Typography variant="h3" component="h2" align="center" className="text-primary">
                            {intl.get('ACHIEVEMENTS_DIALOG_TITLE')}
                        </Typography>
                        <Typography variant="body2" component="p" align="center">
                            {intl.get('ACHIEVEMENTS_DIALOG_TEXT')}
                        </Typography>
                    </div>
                }
                {onClose ? (
                    <IconButton aria-label="close" onClick={onClose} className="__button">
                        <Icon ic="close" />
                    </IconButton>
                ) : null}
            </div>
        );
    };

    const setBadgeRedeem = async (id) => {
        setLoading(true);
        dispatch(AchievementsActions.setBadgeRedeem({
            id_badge: id
        }));
    }

    useEffect(() => {
        if (loading) {
            if (!badgeRedeem?.error && badgeRedeem?.data?.badge) {
                setLoading(false);
                user.neuros = badgeRedeem.data.user.neuros;
                user.qi_credit = badgeRedeem.data.user.qis;
                user.total_points = badgeRedeem.data.user.xps;
                localStorage.setItem('auth.state.user', JSON.stringify(user));
                data.image = badgeRedeem.data.badge.image;
                data.redeem_available = "N";
                data.is_redeemed = "Y";

                dispatch(AchievementsActions.setBadgeUpdate({
                    total_user: badgeRedeem.data.total_list.total_user
                }));

                dispatch(AchievementsActions.resetBadgeRedeem({}));
            } else if (badgeRedeem?.error === true) {
                setSnackOpen(true);
                setLoading(false);
                dispatch(AchievementsActions.resetBadgeRedeem({}));
            }
        }
    }, [dispatch, loading, badgeRedeem, user, data]);

    return (
        <Dialog
            id="dialog-achievements-details"
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={close}
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={transition}
        >
            <DialogContent className={`achievements-details-content${data.badge_active === "Y" ? " __active" : ""}${details ? " __details" : ""} __badges`}>
                <DialogTitle onClose={close} />

                <div className={`achievements-details-content-image${details ? " __details" : ""}`}>
                    <div className="achievements-image">
                        <QrImage
                            src={data.image && imageParser(data.image)}
                            effect="blur"
                            className="__image"
                            alt="Badge"
                        />
                    </div>
                    {
                        data.badge_active === "Y" &&
                        <ImgBadgeStars className="achievements-stars" />
                    }
                </div>
                <div className="achievements-details-content-info">
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} align="center">
                            <Typography variant="h4" component="p" className="text-dark">
                                {data.name}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {data.requirement}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="center" className="d-flex justify-content-center">
                            {
                                data.xps && data.xps > 0 ?
                                    <PointsBar type="xp" value={data.xps} className="ml-2 mr-2" />
                                    : null
                            }
                            {
                                data.qis && data.qis > 0 ?
                                    <PointsBar type="qis" value={data.qis} className="ml-2 mr-2" />
                                    : null
                            }
                            {
                                data.neuros && data.neuros > 0 ?
                                    <PointsBar type="neuros" value={data.neuros} className="ml-2 mr-2" />
                                    : null
                            }
                        </Grid>
                        <Grid item xs={12} sm={8} lg={6} align="center">
                            {
                                data.redeem_available === "Y" ?
                                    !loading ?
                                        <Button
                                            id="btn_achievements_redeem"
                                            variant="contained"
                                            className="btn-block mt-2"
                                            onClick={() => setBadgeRedeem(data.id_badge)}
                                        >
                                            {intl.get('BTN_REDEEM')}
                                        </Button>
                                        :
                                        <LoadingBounce size={24} />
                                    : data.is_redeemed === "Y" ?
                                        <div className="achievements-redeemed">
                                            <Icon ic="check" className="icon-xxs mr-1" />
                                            <Typography variant="body2" component="p" className="font-weight-bold">
                                                {intl.get('LABEL_REDEEMED')}
                                            </Typography>
                                        </div>
                                        : null
                            }
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <SnackBar
                open={snackOpen}
                message={intl.get('ERROR_UPDATE_DATA')}
                status="error"
                time={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </Dialog>
    )
}

export default DialogBadgeDetails