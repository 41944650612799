import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Typography, Avatar } from '@material-ui/core';
import moment from 'moment';

export default function ExamListItem(props) {
    const { action, image, text, status, date } = props;
    moment.locale('pt-br'); // pt-br

    return (
        <ListItem button onClick={action} alignItems="flex-start" className="notification-item">
            <ListItemAvatar>
                <Avatar alt="Avatar" src={image} />
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={
                    <Typography
                        component="p"
                        variant="body2"
                        className={`notification-item-title ${status === "N" ? "__active" : ""}`}
                    >
                        {text}
                    </Typography>
                }
                secondary={
                    <Typography
                        component="p"
                        variant="caption"
                        className={`notification-item-date ${status === "N" ? "__active" : ""}`}    
                    >
                        {process.env.REACT_APP_QR_PROJECT === 'ASSAI' ? null : moment(date).fromNow()}
                    </Typography>
                }
            />
        </ListItem>
    )
}