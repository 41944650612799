import * as React from "react"

function ImgTrophy(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none">
                <path fill="#FFA000" d="M12.39 17l-.52 1-.52-1-.519-4h2.078z" />
                <path
                    d="M14.468 22c0 .552-.466 1-1.04 1h-3.116c-.574 0-1.04-.448-1.04-1v-3.5c0-.552 2.079-1.5 2.079-1.5h1.039s2.078.948 2.078 1.5V22z"
                    fill="#FBC02D"
                />
                <path
                    d="M12.39 17v.5h-1.04V17l-.519-4h2.078l-.52 4zm-2.748-4.514C9.379 12.42 3.19 10.867 2.166 6.1c-.455-2.122.109-3.347.663-4.002C3.514 1.29 4.45 1 5.094 1c.862 0 1.53.229 1.983.68a1.9 1.9 0 01.56 1.338C7.637 3.804 7.084 5 5.559 5c-.064 0-.393-.009-.735-.183-.497-.25-.77-.72-.77-1.317h1.04c0 .287.115.382.215.433A.71.71 0 005.56 4c.99 0 1.04-.833 1.04-1a.93.93 0 00-.278-.635C6.07 2.123 5.657 2 5.094 2c-.313 0-.971.155-1.46.73-.59.699-.746 1.794-.45 3.169.893 4.158 6.653 5.602 6.712 5.615l-.254.972zm4.353.014l-.311-.954c.052-.014 5.231-1.631 6.705-5.688.451-1.24.42-2.221-.094-2.916-.496-.675-1.337-.919-1.78-.919-1.541 0-1.56.96-1.56 1 .005.231.091 1 1.04 1 .234-.005.52-.097.52-.5h1.038c0 1.1-.932 1.5-1.558 1.5-1.525 0-2.078-1.197-2.078-2 0-.691.543-2 2.597-2 .79 0 1.95.419 2.63 1.339.493.67.934 1.878.227 3.825-1.649 4.538-7.143 6.243-7.376 6.313z"
                    fill="#E5A24A"
                />
                <path
                    d="M14.468 19.5H9.273v-1c0-.552 2.078-1.5 2.078-1.5h1.039s2.078.948 2.078 1.5v1zm2.597-18c0 4-1.083 12.5-5.195 12.5S6.675 5.5 6.675 1.5h10.39z"
                    fill="#FFBA57"
                />
                <path fill="#7A534B" d="M8.234 19h7.272v4H8.234z" />
                <path fill="#FFE082" d="M9.273 20.5h5.195v1H9.273z" />
            </g>
        </svg>
    )
}

export default ImgTrophy
