import * as React from "react"

function ImgMedal(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          d="M12 8.857l2.35 4.557 4.65.681-3.35 3.51L16.5 23 12 20.381 7.5 23l.85-5.395L5 14.095l4.65-.68L12 8.856zm1 0h-2c-.55 0-1-.471-1-1.047v-.524c0-.576.45-1.048 1-1.048h2c.55 0 1 .472 1 1.048v.524c0 .576-.45 1.047-1 1.047zm-2-1.571v.524h2v-.524h-2z"
          fill="#FFD54F"
        />
        <path
          d="M12 13.571c.65 0 1.25.21 1.7.577l.6-.734L12 8.857v4.714zm3.65 4.034l3.35-3.51-4.3 1.31c.2.419.3.838.3 1.31 0 .209 0 .418-.05.575l.7.315zM12 19.857v.524L16.5 23l-2.55-3.876c-.55.471-1.2.733-1.95.733zM9.05 17.29l-.7.262L7.5 23l2.55-3.876a3.44 3.44 0 01-1-1.834zm1.25-3.142l-.6-.734-4.7.681 4.3 1.31c.2-.524.55-.943 1-1.257zm3.7 2.566c0 1.153-.9 2.096-2 2.096s-2-.943-2-2.096c0-1.152.9-2.095 2-2.095s2 .943 2 2.095z"
          fill="#FFB300"
        />
        <path fill="#E65748" d="M6.5 1h11v2.619L13 7.809h-2L6.5 3.62z" />
      </g>
    </svg>
  )
}

export default ImgMedal
