import React from 'react';
import intl from 'react-intl-universal';
import { imageParser } from '../../../../utils/utils';
import { Grid } from '@material-ui/core';
import FeedbackBox from '../components/FeedbackBox';
import QrImage from '../../../../components/QrImage';

const QuizImageAnswer = ({ data }) => {
    return (
        // Resposta Correta
        data.correct === "Y" ?
            <Grid container justify="center">
                <Grid item xs={12}>
                    <FeedbackBox
                        correct
                        title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                    >
                        {
                            data.answers.filter(answer => answer.id === data.user_answer).map((item, index) =>
                                <Grid key={index} item xs={12} align="center">
                                    <QrImage
                                        src={imageParser(item.image_answer)}
                                        effect="blur"
                                        className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                        alt={intl.get("LABEL_USER_ANSWER")}
                                    />
                                </Grid>
                            )
                        }
                    </FeedbackBox>
                </Grid>
            </Grid>
            :
            // Resposta Incorreta
            data.correct === "N" ?
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <FeedbackBox
                            correct={false}
                            title={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                        >
                            {
                                data.answers.filter(answer => answer.id === data.user_answer).map((item, index) =>
                                    <Grid key={index} item xs={12} align="center">
                                        <QrImage
                                            src={imageParser(item.image_answer)}
                                            effect="blur"
                                            className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                            alt={intl.get("LABEL_USER_ANSWER")}
                                        />
                                    </Grid>
                                )
                            }
                        </FeedbackBox>
                    </Grid>
                    <Grid item xs={12}>
                        <FeedbackBox
                            correct
                            title={intl.get("GAME_SOLUTION_ANSWER_CORRECT_SUBTITLE")}
                        >
                            {
                                data.answers.filter(answer => answer.answer_right === "Y").map((item, index) =>
                                    <Grid key={index} item xs={12} align="center">
                                        <QrImage
                                            src={imageParser(item.image_answer)}
                                            effect="blur"
                                            className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                            alt={intl.get("LABEL_CORRECT_ANSWER")}
                                        />
                                    </Grid>
                                )
                            }
                        </FeedbackBox>
                    </Grid>
                </Grid>
                :
                // Aguardando Correção
                data.user_answer ?
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <FeedbackBox
                                disabled
                                title={intl.get("GAME_SOLUTION_ANSWER_SENT_SUBTITLE")}
                            >
                                {
                                    data.answers.filter(answer => answer.id === data.user_answer).map((item, index) =>
                                        <Grid key={index} item xs={12} align="center">
                                            <QrImage
                                                src={imageParser(item.image_answer)}
                                                effect="blur"
                                                className="embed-responsive embed-responsive-4by3 img-fluid rounded-lg"
                                                alt={intl.get("LABEL_USER_ANSWER")}
                                            />
                                        </Grid>
                                    )
                                }
                            </FeedbackBox>
                        </Grid>
                    </Grid>
                    :
                    // Sem Resposta
                    <Grid container justify="center" spacing={2}>
                        {
                            data.answers.map((item, index) =>
                                <Grid key={index} item xs={6} className="answer-type-image">
                                    <FeedbackBox
                                        correct={item.answer_right === "Y"}
                                    >
                                        <Grid item xs={12} align="center">
                                            <img src={imageParser(item.image_answer)} alt="Answer" />
                                        </Grid>
                                    </FeedbackBox>
                                </Grid>
                            )
                        }
                    </Grid>
    )
}

export default QuizImageAnswer;