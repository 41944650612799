import React from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import { desaturate } from 'polished';
import { imageParser, getMonth, getDate, diffDate, diffMoment } from '../../../../utils/utils';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';

export default function ClassroomCategoryItem(props) {
    const { action, image, title, dateStart, dateEnd, capacity, subscriptions, userSubscription } = props;
    const stc = require('string-to-color');
    let singleClass = diffDate(dateStart, dateEnd) ? true : false;
    let courseFinished = !diffMoment(dateEnd) ? true : false;
    let soldOff = parseInt(subscriptions) >= parseInt(capacity) ? true : false;

    return (
        <div className={`category-list-item${config.learn.trail.categories.grid.style === "card" ? " __card" : " __default"}`}>
            <div className={`category-list-item-content${config.learn.trail.categories.grid.size === "large" ? " __large" : ""}`}>
                <Card>
                    <CardActionArea onClick={action}>
                        <div className="calendar-content">
                            <div className="calendar-date">
                                <Typography variant="body2" className="day" component="p" noWrap>
                                    {getDate(dateStart)}{singleClass ? "+" : null}
                                </Typography>
                                <Typography variant="caption" className="month" component="p" noWrap>
                                    {getMonth(dateEnd)}
                                </Typography>
                            </div>
                        </div>
                        {
                            image ?
                                <CardMedia
                                    className='category-image __banner'
                                    image={imageParser(image)}
                                    title={title}
                                />
                                :
                                <div className="category-image __banner--default" style={{ backgroundColor: desaturate(0.25, stc(title)) }}></div>
                        }
                        <CardContent className="category-text">
                            <div className="category-text-info">
                                <Typography variant="body2" component="h4">
                                    {title}
                                </Typography>
                                
                                {
                                    !courseFinished ?
                                        <div className="category-text-info-stats">
                                            {
                                                userSubscription ?
                                                    <div className="badge badge-pill badge-success">
                                                        {intl.get('CLASSROOM_REGISTERED')}
                                                    </div>
                                                    : !userSubscription && soldOff ?
                                                        <div className="badge badge-pill badge-light">
                                                            {intl.get('CLASSROOM_SOLD_OFF')}
                                                        </div>
                                                        :
                                                        <>
                                                            <Icon ic="team" className="icon-xxs mr-1" />
                                                            <Typography variant="caption" component="p">
                                                                {`${subscriptions} / ${capacity}`}
                                                            </Typography>
                                                        </>
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    )
}