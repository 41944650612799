import React, { useEffect, useState } from 'react';
import config from '../../config';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import ReactHtmlParser from 'react-html-parser';
import { useSelector, useDispatch } from 'react-redux';
import { actions as FaqActions } from '../../redux/ducks/faq';
import {
    AppBar, Toolbar, IconButton, Typography, Container, Grid,
    Divider, Button
} from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import SnackBar from '../../components/snackBar';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingBounce, LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function FrequentlyAskedAnswer(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const userRating = useSelector(state => state.faq.userRating);
    const question = useSelector(state => state.faq.question);
    const [didMount, setDidMount] = useState(false);
    const [clickRating, setClickRating] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(FaqActions.getQuestion({
                id_category: props.match.params.idCategory,
                id_question: props.match.params.idQuestion
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(FaqActions.reset());
        }
    }, [didMount, dispatch, props.match.params]);

    useEffect(() => {
        if (clickRating && (userRating && (userRating.load === "finished" && userRating.error === false))) {
            setClickRating(false);
            setSnackStatus('success');
            setSnackOpen(true);
            setSnackMessage(intl.get('SUCCESS_FAQ_USER_RATING'));
            dispatch(FaqActions.reset());
        }

        if (clickRating && (userRating && (userRating.load === "finished" && userRating.error === true))) {
            setClickRating(false);
            setSnackStatus('error');
            setSnackOpen(true);
            setSnackMessage(intl.get('ERROR_FAQ_USER_RATING'));
            dispatch(FaqActions.reset());
        }
    }, [dispatch, clickRating, userRating]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {question.data && question.data.category.category_name}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {question.data && question.data.question.question}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    async function sendUserRating(val) {
        setLoading(true);
        await dispatch(FaqActions.setUserRating({
            id_question: props.match.params.idQuestion,
            answer: val,
        }));
        setLoading(false);
        setClickRating(true);
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="faq-questions-answer" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md">
                        {
                            question && question.load === "finished" ?
                                !question.error ?
                                    question.data.length !== 0 ?
                                        <Grid container spacing={2} className="pt-4 pb-3">
                                            <Grid item xs={12}>
                                                <Typography variant="h4" component="h2" className="mb-2">
                                                    {question.data.question.question}
                                                </Typography>
                                                <Typography variant="body2" component="div">
                                                    {ReactHtmlParser(question.data.question.answer)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <Typography variant="body1" component="p" className="mt-3">
                                                    {intl.get("FAQ_FREQUENTLY_ASKED_INFO")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8} lg={4}>
                                                {
                                                    !loading
                                                        ?
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Button variant="outlined" color="primary" className="btn-block" onClick={() => sendUserRating("Y")}>
                                                                    {intl.get("BTN_YES")}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button variant="outlined" color="primary" className="btn-block" onClick={() => sendUserRating("N")}>
                                                                    {intl.get("BTN_NO")}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid container alignItems="center" justify="center">
                                                            <LoadingBounce />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        <EmptyContent icon={<Icon ic="help_filled" className="icon-xxl" />} text={intl.get("EMPTY_FAQ")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingContent />
                        }
                    </Container>
                    <SnackBar
                        open={snackOpen}
                        message={snackMessage}
                        status={snackStatus}
                        time={2}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        closeSnack={() => setSnackOpen(false)}
                    />
                </QrContent>
            </div>
        </div>
    )
}