import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';

export default function CardNewCategoryDuels(props) {
    const { image, bgColor, subheader, description } = props;

    return (
        <Card className="card card-new-category-duels">
            <FeedCardHeader {...props} />
            <CardContent className="d-flex flex-row pt-0">
                <div className="card-image-circle mr-3" style={{ backgroundColor: bgColor }}>
                    <img src={image} alt={subheader} />
                </div>
                <div>
                    <Typography gutterBottom variant="body1" component="h4">
                        {subheader}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <FeedCardFooter {...props} />
        </Card>
    )
}
