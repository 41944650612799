import * as React from 'react';
import config from '../../../config';
import AssaiCharCourseFlashcardsFinishedStars0 from '../projects/assai/chars/CharCourseFlashcardsFinishedStars0';

function CharCourseFlashcardsFinishedStars0(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                <defs>
                    <path id="prefix__a" d="M0 0h180v63H0z" />
                    <path id="prefix__c" d="M0 0h31.5v31.5H0z" />
                    <path id="prefix__e" d="M0 0h40.5v40.5H0z" />
                    <path id="prefix__g" d="M0 0h31.5v31.5H0z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <circle cx={90} cy={90} r={81} />
                    <path
                        d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                        fill="#FFF"
                    />
                    <path
                        d="M56.407 157.17l-4.515-29.097c-.667-5.413 5.979 1.284 11.8-.432 5.822-1.717 20.909-12.723 21.576-7.31l-6.062 29.601c.666 5.414-5.228 12.214-11.05 13.931-5.821 1.717-11.081-1.28-11.749-6.693zm62.571 1.41l4.352-29.268c.794-5.315-5.242 1.894-11.122.126-5.88-1.77-22.014-12.108-22.808-6.793l8.283 29.53c-.795 5.315 3.328 11.058 9.21 12.827 5.88 1.768 11.29-1.106 12.085-6.422"
                        fill="#004573"
                    />
                    <path
                        d="M128.122 79.638L59.95 95.274c-2.296 5.11-2.045 9.333-3.365 12.839-8.065 21.424-2.091 16.055-4.706 22.4l13.747 7.896 53.345 3.894 9.143-42.386"
                        fill="#004573"
                    />
                    <path
                        d="M59.21 90.182l-9.637 36.01s-4.313 10.866 36.384 11.195c41.375.334 42.552-13.124 42.552-13.124l2.059-44.56L59.21 90.182z"
                        fill="#46CA64"
                    />
                    <path
                        fill="#46CA64"
                        d="M41.592 81.356l-6.705 25.678 24.616-3.4 9.703-42.733zm83.424-23.802l-12.04 18.323 12.067 18.416 36.778-11.92z"
                    />
                    <path
                        d="M49.38 46.49s-9.864 23.289 5.917 46.773c1.859 3.912 7.143 12.38 21.829 10.167 17.539-2.644 48.507-13.787 48.507-13.787s5.239-11.258 4.771-25.19c-.398-11.903-10.786-30.583-10.786-30.583L49.38 46.49z"
                        fill="#000"
                        opacity={0.1}
                    />
                    <path
                        d="M47.67 89.287c1.2 4.052 7.496 14.32 21.304 11.087l51.806-12.127s16.313-5.038 13.937-18.736c.1.217-6.346-25.475-6.346-25.475L41.32 64.293s6.363 24.87 6.35 24.994z"
                        fill="#FDC436"
                    />
                    <path
                        d="M89.923 11.324c4.276-.524 2.442-.424 5.486-.491 16.542.2 29.495 16.32 32.927 30.833l1.129 4.767c.188.062-88.163 19.677-88.171 19.526l-1.197-5.06c-3.432-14.513.998-34.422 15.83-41.46 4.272-2.028 9.612-3.093 14.35-2.376 2.57.388 4.754 1.336 6.498 2.756.936-2.004 2.478-3.764 4.62-5.18 2.885-1.94 5.227-2.448 8.528-3.315"
                        fill="#212321"
                    />
                    <path
                        d="M123.327 42.758l-36.605 7.979-5.599-23.67c-.924-3.91.503-6.529 3.18-8.3 3.075-2.032 7.367-2.989 11.068-2.945 3.056.037 5.97.756 8.69 1.99-.763 2.335-2.833 3.605-5.448 3.607-1.537 0-2.967-.127-4.511.084-5.04.697-8.934 4.929-9.103 9.87-.107 2.145 1.347 3.518 2.654 3.525 1.435.009 2.698-.88 2.645-2.95-.081-4.86 4.506-5.319 8.345-5.321 4.525-.001 8.347-2.3 10.091-6.085a33.27 33.27 0 014.296 3.72c-1 1.05-1.79 2.349-2.307 3.871-.484 1.434-.703 2.964-1.225 4.364-.994 2.68-3.437 3.855-6.28 3.413-1.519-.236-2.911-.583-4.47-.61-5.095-.088-9.636 3.487-10.61 8.334-.457 2.098.755 3.68 2.046 3.887 1.419.229 2.813-.454 3.095-2.503.715-4.807 5.324-4.555 9.117-3.964 5.377.836 10.294-1.76 12.154-6.768.553-1.489.773-3.024 1.274-4.503.18-.53.413-1 .694-1.404 3.307 4.483 5.68 9.605 6.809 14.38m-78.22 17.049l36.606-7.98-5.6-23.67c-.924-3.908-3.39-5.68-6.603-6.168-3.683-.558-7.987.36-11.307 1.933-2.738 1.3-5.043 3.157-6.943 5.398 1.74 1.79 4.178 2.077 6.538.995 1.386-.638 2.62-1.345 4.106-1.794 4.86-1.461 10.277.74 12.651 5.128 1.063 1.89.367 3.73-.807 4.279-1.293.603-2.831.324-3.713-1.563-2.114-4.418-6.46-2.93-9.922-1.341-4.08 1.875-8.563 1.386-11.838-1.304a31.574 31.574 0 00-2.2 5.136c1.373.533 2.67 1.376 3.82 2.535 1.082 1.092 1.968 2.381 3.068 3.427 2.105 2.005 4.835 2.053 7.2.476 1.264-.843 2.365-1.733 3.759-2.404 4.553-2.192 10.258-.85 13.316 3.119 1.359 1.703.974 3.631-.095 4.354-1.176.794-2.74.756-3.92-.973-2.807-4.04-6.849-1.902-10.004.203-4.473 2.982-10.077 2.679-14.006-1.066-1.169-1.114-2.059-2.407-3.175-3.533a5.946 5.946 0 00-1.259-.98c-.966 5.415-.801 11.019.328 15.793"
                        fill="#FFF"
                    />
                    <path
                        d="M76.029 92.344s12.212.151 33.947-4.535c.451.302-6.554-10.965-18.855-8.665-12.42 2.32-14.801 12.518-15.092 13.2"
                        fill="#212321"
                    />
                    <path
                        d="M23.548 97.045C25.932 95.231 42.986 73.598 45.5 71.81l2.394-2.27c4.975-7.29 20.855 3.021 20.855 3.021 4.026 7.181-.622 12.662-5.801 16.71 8.694 5.191-26.99 11.807-26.99 11.807"
                        fill="#000"
                        opacity={0.1}
                    />
                    <path
                        d="M36.738 106.711c-23.277-2.205-9.122-20.534-6.625-22.682l13.544-10.403c5.845-4.828 9.038-4.62 14.114-3.35l6.254 2.57c5.433 5.004 2.97 7.938-5.467 13.94 9.64 4.456-22.12 19.843-22.12 19.843"
                        fill="#FDC436"
                    />
                    <path
                        d="M62.023 69.236c6.239-1.812 10.41-1.278 17.783 2.313a.815.815 0 10.714-1.466c-7.72-3.758-12.3-4.343-18.952-2.413a.815.815 0 10.455 1.566zm52.436-8.763c-6.924-.203-11.21 1.512-17.742 7.085a.815.815 0 101.058 1.24c6.24-5.325 10.143-6.886 16.636-6.696a.815.815 0 10.048-1.629z"
                        fill="#212321"
                        fillRule="nonzero"
                    />
                    <path
                        d="M152.134 61.326c-3.105-.405-11.773-6.368-14.984-6.681l-11.37-2.283c-7.409-.568-12.394.283-17.188 5.851l-2.721 5.75c-2.63 7.36-2.255 11.54 7.793 10.443-5.462 9.38 19.906 11.265 18.726 2.413l9.422.766"
                        fill="#000"
                        opacity={0.1}
                    />
                    <path
                        d="M161.423 82.169c9.755-19.597-16.854-24.78-20.014-25.472l-17.42-3.986c-7.309-1.441-13.628 3.1-14.65 9.021l-.093.627c-2.192 8.863-3.223 10.995 6.893 11.098-6.46 8.66 45.284 8.712 45.284 8.712"
                        fill="#FDC436"
                    />
                    <path
                        d="M34.099 60.457c-2.855-1.765-16.247-7.637-16.453.072-.195 7.325 17.68.687 16.453-.072m99.091-24.213c1.985-2.707 12.195-13.175 15.278-6.105 2.929 6.716-16.131 7.27-15.278 6.105M29.107 70.904c-2.773-.296-14.918.118-12.3 5.968 2.486 5.558 13.493-5.84 12.3-5.968m18.91-5.319c-2.65-.873-14.61-3.023-13.281 3.246 1.261 5.958 14.42-2.87 13.28-3.246m93.717-21.528c2.46-1.315 13.873-5.487 13.642.918-.22 6.085-14.7-.352-13.642-.918m-17.888 4.334c1.582-2.297 9.81-11.24 12.542-5.442 2.596 5.507-13.223 6.43-12.542 5.442"
                        fill="#E9ECE6"
                    />
                    <g>
                        <g transform="translate(0 117)">
                            <mask id="prefix__b" fill="#fff">
                                <use xlinkHref="#prefix__a" />
                            </mask>
                            <g mask="url(#prefix__b)">
                                <path
                                    d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                    fill="#BD483B"
                                />
                                <path
                                    d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                    fill="#93382E"
                                />
                                <path
                                    d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                    fill="#E65748"
                                />
                            </g>
                        </g>
                        <g transform="rotate(-12 659.94 -89.693)">
                            <mask id="prefix__d" fill="#fff">
                                <use xlinkHref="#prefix__c" />
                            </mask>
                            <g mask="url(#prefix__d)" fill="#BD483B">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                        <g transform="translate(69.755 123.75)">
                            <mask id="prefix__f" fill="#fff">
                                <use xlinkHref="#prefix__e" />
                            </mask>
                            <g mask="url(#prefix__f)" fill="#BD483B">
                                <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                            </g>
                        </g>
                        <g transform="rotate(12 -554.159 616.797)">
                            <mask id="prefix__h" fill="#fff">
                                <use xlinkHref="#prefix__g" />
                            </mask>
                            <g mask="url(#prefix__h)" fill="#BD483B">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCourseFlashcardsFinishedStars0 {...props} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (config.learn.trail.chars && config.learn.trail.type === "stars") {
            if (gender === "male") {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__a" d="M0 0h180v63H0z" />
                            <path id="prefix__c" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__e" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__g" d="M0 0h31.5v31.5H0z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle fill="none" cx={90} cy={90} r={81} />
                            <path
                                d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                fill="#FFF"
                            />
                            <g>
                                <path
                                    d="M80.149 98.45l.08 7.37s-32.866 10.676-47.603-13.077C16.97 67.508 46.073 46.14 46.073 46.14l6.134 11.224s-19.67 15.27-10.433 30.114c10.928 17.56 38.375 10.97 38.375 10.97"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M124.326 35.578s4.558-3.648 4.331-6.838c-.228-3.191-3.42-7.066-5.47-5.47-2.052 1.595 0 4.102 1.367 5.925 0 0-2.051 2.28-2.735 3.192-.684.912.456 2.963 2.507 3.19"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M128.784 36.856s5.056-2.92 5.311-6.108c.255-3.189-2.317-7.5-4.585-6.232-2.268 1.27-.617 4.056.46 6.065 0 0-2.37 1.945-3.184 2.743-.813.799.005 2.998 1.998 3.532"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M105.084 94.65l1.135 6.631s37.826.624 44.11-29.599c6.077-29.225-30.18-39.295-30.18-39.295l-2.497 12.034s24.755 9.088 21.104 26.524c-4.399 21.009-33.672 23.705-33.672 23.705"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M115.897 35.968c-1.114 7.407 1.759 10.815 7.265 13.127 4.932 2.071 10.637-1.707 11.784-6.717 1.47-6.427-3.1-9.716-8.097-11.566-5.302-1.964-10.052-.828-10.952 5.156"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M132.591 39.882s3.999-.954 5.963-3.563c1.965-2.61 2.028-7.317-.606-7.63-2.634-.315-3.227 1.865-3.397 4.187 0 0-2.832 2.507-3.721 3.257-.89.75-.226 3.051 1.761 3.75"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M105.568 95.383s5.28 15.473 6.557 30.355c0 0-4.49 1.983-16.746 1.983-6.683 0-14.326-.81-18.103-1.983 0 0 2.532-12.513 1.687-27.394 0 0 4.327-2.11 12.238-2.96 9.946-1.23 14.367 0 14.367 0"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M95.506 104.885c.664-1.634 2.62-1.739 3.432-.167 1.295 2.512 4.835 10.734 4.751 11.371-.16 1.207-3.954 5.284-5.398 5.14-1.662-.163-6.06-3.832-6.046-4.62.036-1.972 2.05-8.744 3.26-11.724zm1.197-7.583a2.506 2.506 0 11.331 5.001 2.506 2.506 0 01-.331-5z"
                                    className="svg-char-necktie"
                                />
                                <path
                                    d="M101.764 56.178c.9 8.856 6.704 12.335 13.764 11.618 7.06-.716 10.215-14.237 9.888-20.088-.533-9.522-6.384-15.72-13.445-15.003-7.06.716-11.106 14.616-10.207 23.473"
                                    className={`svg-char-${color}-hair-shade-2`}
                                />
                                <path
                                    d="M54.898 89.127c3.748 4.841 9.71 5.262 12.927 3.635 3.203-1.624.946-5.037-1.3-9.517-2.244-4.48-6.668-6.796-9.878-5.17-3.21 1.626-4.842 7.056-1.749 11.052"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M58.219 87.821c1.344 2.505 5.49 3.365 7.526 2.563 2.039-.802.902-2.83-.152-5.468-1.051-2.64-3.558-4.126-5.595-3.323-2.036.804-3.349 3.305-1.78 6.228"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M127.08 69.968c-.715 4.378-4.096 7.793-6.847 7.907-2.74.11-2.696-3.306-3.099-7.498-.405-4.193 1.495-7.683 4.243-7.794 2.746-.112 6.378 3.274 5.703 7.385"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M124.317 69.485c.411 2.361-2.078 5.566-3.725 5.804-1.65.239-1.754-1.706-2.164-4.067-.414-2.36.591-4.465 2.24-4.703 1.648-.238 3.239.607 3.649 2.966"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M105.782 96.643s24.852-12.05 12.68-46.333c-12.173-34.282-35.574-29.92-47.05-25.526C59.936 29.177 49.846 39.39 51.14 50.188c4.478 37.386 19.911 46.833 28.2 49.453 4.54 1.435 16.45 2.42 26.442-2.998"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M106.89 89.592c.698-1.535 1.34-4.278-.272-7.293-1.806-3.376-3.89-3.588-7.094-1.256-3.934 2.864-5.6 2.513-8.338 2.184-4.397-.528-9.038-1.306-11.42 2.719-3.895 6.578-.71 16.643 7.826 9.527 5.855-4.882 9.67-4.753 11.685-4.192 3.376.938 6.62.494 7.613-1.689"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M79.577 86.278c3.47-1.265 9.84-.88 14.195.254 3.787.986 6.15 3.76 7.096 5.09a13.882 13.882 0 01-1.591-.341c-2.016-.56-5.83-.69-11.685 4.192-8.394 6.998-11.613-2.618-8.015-9.195"
                                    className="svg-char-mouth-tongue"
                                />
                                <path
                                    d="M114.114 61.596c.005-.5-1.75-2.156-5.544-.798-2.498.893-6.238 3.182-7.576 5.807-1.053 2.07 2.115.629 6.546.018 4.477-.616 5.705.113 6.33-.184.198-.094.28-1.709-1.748-2.24-1.014-.264-2.818-.155-5.71.555-1.109.272.954-1.158 2.836-1.845 3.028-1.105 4.86-.744 4.866-1.313M70.24 69.852c-.21-.463.87-2.654 5.237-2.81 2.874-.102 7.569.626 9.99 2.564 1.907 1.527-1.864 1.371-6.562 2.458-4.746 1.098-5.679 2.234-6.429 2.191-.237-.014-.982-1.481.834-2.731.909-.623 2.765-1.195 5.96-1.615 1.223-.162-1.434-.719-3.606-.654-3.493.104-5.184 1.123-5.423.597"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M92.27 74.494a3.46 3.46 0 106.884-.698 3.46 3.46 0 00-6.883.698"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M57.91 77.587s10.882-12.644 2.45-27.52c0 0 3.281-2.482 19-.88 15.473 1.576 38.693 1.672 48.339-10.463 7.254-9.127-1.843-17.12-9.3-11.013 0 0 4.463-1.501 2.646-9.079-2.015-8.398-13.948-9.282-19.616-2.1 0 0 3.561-3.443.156-6.728-3.837-3.7-10.602-.077-11.952 6.248 0 0-26.736.091-38.589 17.656-8.832 13.09-6.333 28.541 6.865 43.879"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M85.265 47.437c11.028.707 26.3-1.31 35.535-5.394 7.476-3.306 9.028-9.445 9.348-11.665.582 2.322-.004 5.27-2.45 8.346-9.645 12.135-32.865 12.039-48.338 10.463-15.719-1.602-19 .88-19 .88 5.403-4.637 19.204-2.996 24.905-2.63M56.353 64.317c5.835-.382 7.095-4.495 7.095-4.495s.06.969.107 2.517c-.22 8.943-5.646 15.248-5.646 15.248-5.212-6.057-8.748-12.13-10.584-18.07 1.81 2.373 4.88 5.072 9.028 4.8"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M108.374 14.785c-.093.924 1.31 1.822 3.135 2.004 1.824.184 3.378-.417 3.472-1.34.091-.924-1.31-1.821-3.136-2.006-1.825-.182-3.377.418-3.47 1.342"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                                <path
                                    d="M42.496 53.507s-4.099-1.367-5.897-4.092c-1.799-2.726-2.85-6.886-.253-7.582 2.247-.602 3.57 1.295 4.63 4.56 0 0 2.671 2.677 3.511 3.48.84.805.036 3.06-1.99 3.634"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M37.849 46.26c-3.816 5.513-2.879 10.25.201 13.892 3.235 3.827 8.74 4.504 13.272.784 2.848-2.336 4.867-9.807 3.07-11.764-3.318-3.614-13.1-7.889-16.543-2.913"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M45.312 46.76s-1.034-5.13 1.073-7.537c2.108-2.406 6.992-3.569 7.664-1.058.672 2.51-1.64 3.44-3.831 4.067 0 0-.578 2.887-.583 4.027-.005 1.14-2.55 1.556-4.323.5M51.075 50.733s-.089-5.838 2.517-7.693c2.606-1.856 7.626-1.861 7.701.736.075 2.598-2.39 2.97-4.666 3.075 0 0-1.11 3.924-1.858 6.206-.355 1.083-2.212-.888-3.694-2.324M39.883 45.716s-1.876-4.06-1.155-7.487c.722-3.426 2.796-6.83 5.331-6.049 2.822.87 1.558 3.617-.07 6.919 0 0-.077 4.028-.073 5.266.005 1.238-2.198 2.393-4.033 1.35"
                                    className={`svg-char-${color}-skin`}
                                />
                            </g>
                            <g>
                                <g transform="translate(0 117)">
                                    <mask id="prefix__b" fill="#fff">
                                        <use xlinkHref="#prefix__a" />
                                    </mask>
                                    <g mask="url(#prefix__b)">
                                        <path
                                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                            className="svg-ribbon-stars-back"
                                        />
                                        <path
                                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                            className="svg-ribbon-stars-shadow"
                                        />
                                        <path
                                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                            className="svg-ribbon-stars-front"
                                        />
                                    </g>
                                </g>
                                <g transform="rotate(-12 659.94 -89.693)">
                                    <mask id="prefix__d" fill="#fff">
                                        <use xlinkHref="#prefix__c" />
                                    </mask>
                                    <g mask="url(#prefix__d)" className="svg-ribbon-stars-back">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                                <g transform="translate(69.755 123.75)">
                                    <mask id="prefix__f" fill="#fff">
                                        <use xlinkHref="#prefix__e" />
                                    </mask>
                                    <g mask="url(#prefix__f)" className="svg-ribbon-stars-back">
                                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                    </g>
                                </g>
                                <g transform="rotate(12 -554.159 616.797)">
                                    <mask id="prefix__h" fill="#fff">
                                        <use xlinkHref="#prefix__g" />
                                    </mask>
                                    <g mask="url(#prefix__h)" className="svg-ribbon-stars-back">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            } else {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__a" d="M0 180h180V0H0z" />
                            <path id="prefix__c" d="M0 0h180v63H0z" />
                            <path id="prefix__e" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__g" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__i" d="M0 0h31.5v31.5H0z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <mask id="prefix__b" fill="#fff">
                                <use xlinkHref="#prefix__a" />
                            </mask>
                            <g mask="url(#prefix__b)">
                                <circle cx={90} cy={90} r={81} />
                                <path
                                    d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                    fill="#FFF"
                                />
                                <path
                                    d="M125.216 35.959s4.599-3.688 4.365-6.91c-.234-3.222-3.46-7.13-5.53-5.517-2.07 1.613.004 4.142 1.388 5.982 0 0-2.069 2.304-2.759 3.226-.69.92.464 2.99 2.536 3.219"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M129.719 37.244s5.102-2.953 5.355-6.174c.254-3.22-2.348-7.57-4.636-6.287-2.29 1.284-.62 4.097.471 6.124 0 0-2.392 1.966-3.212 2.773-.82.807.008 3.027 2.022 3.564"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M102.63 95.01l1.154 6.695s41.42 1.204 47.73-29.32c6.102-29.517-30.52-39.644-30.52-39.644l-2.507 12.155s25.007 9.148 21.34 26.758c-4.417 21.218-37.196 23.357-37.196 23.357"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M116.705 36.363c-1.116 7.48 1.788 10.917 7.351 13.246 4.982 2.086 10.738-1.736 11.891-6.797 1.477-6.49-3.141-9.806-8.19-11.669-5.355-1.977-10.15-.824-11.052 5.22"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M133.566 40.295s4.037-.968 6.017-3.604c1.98-2.637 2.04-7.39-.621-7.704-2.66-.316-3.256 1.887-3.425 4.231 0 0-2.856 2.535-3.754 3.293-.897.759-.225 3.082 1.783 3.784"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M104.064 97.43s.892 12.35 4.86 26.69c0 0-3.814 2.252-14.456 2.68-5.802.232-12.473-.356-15.803-1.465 0 0 1.366-4.1 2.047-8.395.696-4.392-.996-10.28-.164-18.565 0 0 3.49.464 10.864.168 7.375-.296 12.652-1.113 12.652-1.113"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M77.647 125.596s1.86-5 3.065-8.656c.507-1.542-1.77-9.528-1.158-18.191 0 0 3.644.07 10.023-.083 1.332 4.873 3.785 8.81 5.054 8.97 1.286.162 2.481-1.913 3.465-9.312 3.038-.29 5.273-1.492 5.273-1.492s2.957 4.574 3.69 6.89c.58 1.836-.596 6.424-.348 7.267.841 2.87.852 5.299 1.797 7.608.732 1.788 2.551 3.207 2.905 5.131 0 0-5.457 3.288-17.07 3.754-6.333.254-13.195.33-16.696-1.886"
                                    className="svg-char-suit"
                                />
                                <path
                                    d="M40.564 28.42c.972 9.96 10.07 17.22 20.32 16.22 10.252-1.001 17.774-9.885 16.802-19.843-.972-9.958-10.07-17.22-20.321-16.219-10.252 1-17.774 9.885-16.801 19.843"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M48.504 43.609c5.348 7.476 17.211 8.152 26.497 1.509 9.287-6.642 12.48-18.088 7.133-25.564-5.348-7.476-17.211-8.152-26.497-1.51-9.287 6.643-12.48 18.089-7.133 25.565"
                                    className="svg-char-ribbon"
                                />
                                <path
                                    d="M40.564 28.42a17.659 17.659 0 012.065-10.163c-.06 2.21.25 7.692 4.422 10.815 0 0-.387 2.608 2.919 3.57l-2.832 7.909c-3.619-2.933-6.093-7.2-6.574-12.13"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M124.376 68.726s5.851-12.457 1.853-30.376c-3.998-17.92-20.443-28.385-40.56-27.2-11.29.666-21.541 5.21-28.727 12.15-8.67 8.374-12.376 20.096-9.055 32.977 4.825 18.714 13.751 27.89 18.517 30.637 9.824 5.662 45.177 11.971 57.972-18.188"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M130.355 69.78c-.747 4.467-4.213 7.94-7.023 8.047-2.8.1-2.74-3.387-3.136-7.67-.398-4.283 1.556-7.84 4.363-7.943 2.806-.103 6.5 3.37 5.796 7.565"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M127.535 69.274c.411 2.414-2.143 5.677-3.827 5.914-1.685.238-1.784-1.75-2.195-4.163-.413-2.41.622-4.557 2.307-4.794 1.683-.236 3.305.633 3.715 3.043"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M56.609 88.712c3.808 4.957 9.896 5.41 13.188 3.762 3.276-1.646.984-5.14-1.292-9.725-2.273-4.584-6.783-6.966-10.068-5.318-3.284 1.648-4.972 7.187-1.828 11.28"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M60.005 87.39c1.364 2.563 5.595 3.458 7.677 2.647 2.085-.811.932-2.886-.135-5.585-1.063-2.7-3.618-4.227-5.7-3.415-2.083.813-3.434 3.362-1.842 6.354"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M108.5 96.938s25.427-12.21 13.13-47.268c-12.297-35.06-36.214-30.696-47.95-26.254-11.737 4.442-22.082 14.833-20.803 25.866 4.428 38.198 20.152 47.906 28.608 50.614 4.63 1.483 16.79 2.537 27.016-2.958"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M78.06 67.002c-.152-.568.763-3.307 4.198-3.592 2.26-.187 5.925.616 7.766 2.967 1.451 1.852-1.505 1.74-5.229 3.185-3.76 1.46-4.528 2.887-5.114 2.85-.187-.01-.726-1.814.738-3.4.732-.794 2.208-1.54 4.729-2.13.966-.225-1.104-.86-2.811-.733-2.746.203-4.105 1.5-4.278.853m42.082-4.297c.001-.588-1.583-3.002-4.975-2.4-2.234.396-5.571 2.109-6.752 4.852-.93 2.161 1.9 1.297 5.869 1.744 4.008.452 5.116 1.635 5.673 1.45.177-.059.238-1.94-1.583-3.1-.91-.58-2.528-.926-5.116-.85-.99.027.848-1.115 2.532-1.429 2.706-.505 4.352.404 4.352-.267"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M110.908 88.268c.887-1.604 1.757-4.5-.03-7.784-2-3.678-4.456-3.997-8.338-1.654-4.767 2.878-6.723 2.43-9.946 1.957-5.178-.758-10.636-1.793-13.614 2.39-4.868 6.838-1.506 17.705 8.873 10.5 7.119-4.941 11.624-4.634 13.985-3.948 3.955 1.15 7.809.822 9.07-1.46"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M78.744 83.523c4.154-1.194 11.67-.5 16.775.901 4.44 1.22 7.121 4.28 8.187 5.74a18.019 18.019 0 01-1.868-.435c-2.36-.686-6.866-.993-13.985 3.949-10.206 7.084-13.627-3.306-9.11-10.155"
                                    className="svg-char-mouth-tongue"
                                />
                                <path
                                    d="M95.77 73.589a3.533 3.533 0 107.032-.686 3.533 3.533 0 00-7.032.686"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M50.98 44.09c.496-21.25 27.388-30.098 45.679-25.66 12.17 2.952 17.379 9.006 19.606 13.757 9.678 4.396 8.334 19.082 8.334 19.082-12.881-4.18-20.644-12.202-23.75-15.998-3.497 5.967-14.054 20.36-35.988 23.78.876 13.546-5.322 18.003-5.322 18.003S50.485 65.34 50.98 44.09z"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M57.841 78.452c-3.192-4.186-6.958-11.233-9.455-20.329.034-.348.057-.544.057-.544s2.42 9.722 16.328 7.4c0 0-.502 9.392-6.93 13.473"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M100.666 34.082a.48.48 0 01.865.415c-.126.261-3.16 6.459-10.843 12.93-7.075 5.96-19.586 13.23-39.005 14.011h-.02a.48.48 0 01-.019-.958c19.122-.77 31.43-7.904 38.388-13.753 7.542-6.34 10.604-12.583 10.634-12.645zm2.741-8.33a.48.48 0 01.83.482c-.133.23-3.358 5.693-11.782 11.568-7.744 5.402-21.532 12.146-43.239 13.599h-.032a.48.48 0 01-.03-.958c21.449-1.435 35.06-8.074 42.7-13.39 8.272-5.757 11.522-11.246 11.553-11.3zm2.21 6.596a.48.48 0 01.661.146c.067.106 6.915 10.655 19.708 17.163a.48.48 0 11-.435.855c-13.035-6.63-20.012-17.394-20.081-17.501a.48.48 0 01.146-.663zm2.302-6.915a.48.48 0 01.632.248c.05.115 5.147 11.6 16.772 20.04a.48.48 0 01-.563.777c-11.834-8.593-17.037-20.316-17.089-20.434a.48.48 0 01.248-.631zm-53.956 3.543c22.955-13.273 47.03-8.06 47.271-8.006a.479.479 0 11-.21.936c-.237-.054-23.946-5.188-46.58 7.9a.476.476 0 01-.656-.174.48.48 0 01.175-.656zm50.09-11.831c.485-.916 2.343-.884 4.154.071 1.81.957 2.883 2.474 2.4 3.391-.484.916-2.344.885-4.153-.073-1.81-.955-2.885-2.473-2.4-3.39z"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                                <path
                                    d="M79.643 99.335s2.079.317 2.242 2.875c.162 2.557-2.233 4.158-2.233 4.158s-32.055 11.385-46.963-12.582c-15.838-25.462 13.524-47.07 13.524-47.07l6.206 11.325S32.575 73.483 41.92 88.461c11.054 17.718 37.723 10.874 37.723 10.874"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M42.61 54.157s-4.14-1.375-5.96-4.126c-1.819-2.75-2.886-6.95-.264-7.654 2.268-.61 3.606 1.303 4.68 4.598 0 0 2.7 2.7 3.55 3.511.85.811.04 3.09-2.007 3.671"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M37.909 46.844c-3.846 5.572-2.895 10.353.219 14.028 3.271 3.86 8.83 4.537 13.402.776 2.873-2.362 4.903-9.908 3.087-11.881-3.355-3.647-13.237-7.952-16.708-2.923"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M45.445 47.34s-1.05-5.178 1.075-7.61c2.126-2.433 7.056-3.612 7.738-1.078.681 2.534-1.653 3.475-3.864 4.112 0 0-.58 2.915-.584 4.066-.004 1.15-2.574 1.575-4.365.51m5.823 4.006s-.096-5.894 2.534-7.77c2.629-1.877 7.698-1.889 7.777.734.078 2.623-2.41 3-4.709 3.11 0 0-1.116 3.964-1.868 6.268-.358 1.094-2.235-.893-3.734-2.342m-11.306-5.053s-1.9-4.098-1.175-7.558c.725-3.46 2.815-6.901 5.376-6.114 2.851.875 1.578 3.65-.063 6.985 0 0-.073 4.068-.067 5.318.007 1.25-2.217 2.42-4.071 1.369"
                                    className={`svg-char-${color}-skin`}
                                />
                                <g>
                                    <g transform="translate(0 117)">
                                        <mask id="prefix__d" fill="#fff">
                                            <use xlinkHref="#prefix__c" />
                                        </mask>
                                        <g mask="url(#prefix__d)">
                                            <path
                                                d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                                className="svg-ribbon-stars-back"
                                            />
                                            <path
                                                d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                                className="svg-ribbon-stars-shadow"
                                            />
                                            <path
                                                d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                                className="svg-ribbon-stars-front"
                                            />
                                        </g>
                                    </g>
                                    <g transform="rotate(-12 659.94 -89.693)">
                                        <mask id="prefix__f" fill="#fff">
                                            <use xlinkHref="#prefix__e" />
                                        </mask>
                                        <g mask="url(#prefix__f)" className="svg-ribbon-stars-back">
                                            <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                        </g>
                                    </g>
                                    <g transform="translate(69.755 123.75)">
                                        <mask id="prefix__h" fill="#fff">
                                            <use xlinkHref="#prefix__g" />
                                        </mask>
                                        <g mask="url(#prefix__h)" className="svg-ribbon-stars-back">
                                            <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                        </g>
                                    </g>
                                    <g transform="rotate(12 -554.159 616.797)">
                                        <mask id="prefix__j" fill="#fff">
                                            <use xlinkHref="#prefix__i" />
                                        </mask>
                                        <g mask="url(#prefix__j)" className="svg-ribbon-stars-back">
                                            <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            }
        } else {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <g fill="none" fillRule="evenodd" transform="translate(9 9)">
                        <circle cx={81} cy={81} r={81} fill="none" />
                        <path
                            fill="#FFF"
                            d="M81 150.75c38.522 0 69.75-31.228 69.75-69.75S119.522 11.25 81 11.25 11.25 42.478 11.25 81 42.478 150.75 81 150.75ZM81 162c-44.735 0-81-36.265-81-81S36.265 0 81 0s81 36.265 81 81-36.265 81-81 81Z"
                        />
                    </g>
                </svg>
            )
        }
    }
}

export default CharCourseFlashcardsFinishedStars0
