import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import { actions as FeedsActions } from '../../redux/ducks/feeds';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Grid, Typography, Paper, Slide, useMediaQuery,
    Dialog, DialogContent, AppBar, Toolbar, IconButton
} from '@material-ui/core';
import EmptyContent from '../../components/EmptyContent';
import FeedCard from './components/FeedCard';
import Comments from './components/Comments';
import { LoadingMore, LoadingFeedsList } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FeedsList(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const feeds = useSelector(state => state.feeds.feeds);
    const page = useSelector(state => state.feeds.page);
    const hasMore = useSelector(state => state.feeds.hasMore);
    const [didMount, setDidMount] = useState(false);
    const [pageComment, setPageComment] = useState(false);
    const [feedItem, setFeedItem] = useState({});
    const [total, setTotal] = useState(0);
    const isScreenLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let limit = feeds && (feeds.load === "finished" && feeds.data.length !== 0) ? feeds.data.length : 10;
    let hasData = feeds && (feeds.load === "finished" && feeds.data.length !== 0) ? true : false;

    useEffect(() => {
        setDidMount(true);

        if (didMount && !hasData)
            dispatch(FeedsActions.getFeedsList({
                limit: limit,
                page: 1
            }));

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch, limit, hasData]);

    const fetchMoreData = async () => {
        let pageNumber = page;
        limit = 10;

        pageNumber++;

        await dispatch(FeedsActions.getLoadMore({
            limit: limit,
            page: pageNumber
        }));

        dispatch(FeedsActions.setPage({
            page: pageNumber
        }));
    }

    return (
        <Grid item xs={12}>
            <Grid
                container
                id="feeds-list"
                justify="center"
                alignItems="center"
                direction="column"
                className="page-bottom-menu"
            >
                {
                    feeds && feeds.load === "finished" ?
                        !feeds.error ?
                            feeds.data.length !== 0 ?
                                <>
                                    <InfiniteScroll
                                        dataLength={feeds.data.length}
                                        next={fetchMoreData}
                                        hasMore={(feeds.data.length >= limit) && hasMore ? hasMore : false}
                                        scrollableTarget={props.type === "sidebar" ? "feeds" : false}
                                        loader={<LoadingMore />}
                                        endMessage={
                                            <Typography variant="h5" component="p" align="center" className="p-3 mb-4">
                                                {intl.get("NO_MORE_FEEDS")}
                                            </Typography>
                                        }
                                    >
                                        <FeedCard
                                            data={feeds.data}
                                            pageComment={(page) => setPageComment(page)}
                                            feedItem={(item) => setFeedItem(item)}
                                        />
                                    </InfiniteScroll>
                                    <Slide direction="left" in={isScreenLg && pageComment} mountOnEnter unmountOnExit>
                                        <Paper elevation={4} id="comments-panel">
                                            {
                                                pageComment ?
                                                    <Comments
                                                        feed={feedItem}
                                                        pageComment={(page) => setPageComment(page)}
                                                        total={(comments) => setTotal(comments)}
                                                    />
                                                    :
                                                    null
                                            }
                                        </Paper>
                                    </Slide>
                                    <Dialog
                                        id="dialog-feeds-list-comments"
                                        className={themeMode === "DARK" ? "__dark" : "__light"}
                                        fullScreen
                                        disableBackdropClick
                                        disableEscapeKeyDown
                                        open={!isScreenLg && pageComment}
                                        onClose={() => setPageComment(false)}
                                        TransitionComponent={Transition}
                                    >
                                        {
                                            pageComment ?
                                                <>
                                                    <AppBar className="appbar appbar-default" position="sticky" color="primary">
                                                        <Toolbar>
                                                            <IconButton
                                                                color="inherit"
                                                                aria-label="close"
                                                                edge="start"
                                                                onClick={() => setPageComment(false)}
                                                            >
                                                                <Icon ic="close" />
                                                            </IconButton>
                                                            <div className="w-100 overflow-hidden">
                                                                <Typography variant="h4" noWrap>
                                                                    {intl.get('FEEDS_COMMENTS_TITLE')} {`(${total})`}
                                                                </Typography>
                                                            </div>
                                                        </Toolbar>
                                                    </AppBar>
                                                    <DialogContent className="container-full">
                                                        <Comments
                                                            feed={feedItem}
                                                            pageComment={(page) => setPageComment(page)}
                                                            total={(comments) => setTotal(comments)}
                                                        />
                                                    </DialogContent>
                                                </>
                                                : null
                                        }
                                    </Dialog>
                                </>
                                :
                                <EmptyContent icon={<Icon ic="feed_filled" className="icon-xxl" />} text={intl.get("EMPTY_FEEDS")} />
                            :
                            <LoadingFeedsList limit={limit} />
                        :
                        <LoadingFeedsList limit={limit} />
                }
            </Grid>
        </Grid>
    )
}