import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { actions as FreeActions } from '../../../redux/ducks/free';
import { Container, Grid, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import CategoryItem from './components/CategoryItem';
import { LoadingCategoryList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const FreeCategoriesList = (props) => {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [didMount, setDidMount] = useState(false);
    const list = useSelector(state => state.free.list);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(FreeActions.getList({}));
        }

        return () => {
            setDidMount(false);
            dispatch(FreeActions.reset());
        }
    }, [didMount, dispatch]);

    function goBack() {
        let currentOrigin = "categories";

        switch (origin) {
            case 'subcategories':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'question':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FREE_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="free-list" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="lg" className="pt-3 pb-3">
                        {
                            list && list.load === "finished" ?
                                !list.error ?
                                    list.data.length !== 0 ?
                                        <Grid container spacing={2} className="category-list __fluid">
                                            {
                                                list.data.map((item, index) => {
                                                    return (
                                                        <Grid item key={index} xs={config.learn.free.categories.grid.mobile === "full" ? 12 : 6} sm={config.learn.free.categories.grid.mobile === "full" ? 6 : 4} md={4} lg={3}>
                                                            <CategoryItem
                                                                item={item}
                                                                origin="categories"
                                                                path={props.match.path}
                                                            />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        :
                                        <EmptyContent icon={<Icon ic="play_free" className="icon-xxl" />} text={intl.get("EMPTY_FREE")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingCategoryList limit={8} mobileSize={config.learn.free.categories.grid.mobile} />
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}
export default FreeCategoriesList;
