import { qrService } from './base.service';

function getList(params) {
    return qrService('/v4/app/faq/list', params)
}

function sendEmail(params) {
    return qrService('/v1/app/contact/send', params);
}

function setUserRating(params) {
    return qrService('/v4/app/faq/useful', params);
}

export const faqService = {
    getList,
    setUserRating,
    sendEmail,
}
