import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Button, Grid } from '@material-ui/core';

export default function ButtonSignup(props) {
    const { type, buttonStyle } = props;
    const { history } = useReactRouter();

    return (
        <Grid container justify="center">
            <Button
                id={buttonStyle === "button" ? `btn_intro_signup_${type}` : ""}
                variant={buttonStyle === "button" ? "contained" : "text"}
                className={buttonStyle === "button" ? "btn-block" : ""}
                color="primary"
                onClick={() => history.push({
                    pathname: '/signup',
                    state: {
                        type: type,
                        origin: "intro"
                    }
                })}
            >
                {intl.get('BTN_SIGNUP')}
            </Button>
        </Grid>
    )
}
