import React from 'react';
import ImgHandLeft from '../../../../../dist/img/intros/duels/ImgHandLeft';
import ImgHandRight from '../../../../../dist/img/intros/duels/ImgHandRight';
import ImgSwordLeft from '../../../../../dist/img/intros/duels/ImgSwordLeft';
import ImgSwordRight from '../../../../../dist/img/intros/duels/ImgSwordRight';
import ImgShield from '../../../../../dist/img/intros/duels/ImgShield';

export default function DuelIntro() {
    return (
        <div id="intro-slider-duels">
            <div className="intro-content">
                <div className="hand-left">
                    <ImgHandLeft />
                </div>
                <div className="hand-right">
                    <ImgHandRight />
                </div>
                <div className="shield">
                    <ImgShield />
                </div>
                <div className="sword-left">
                    <ImgSwordLeft />
                </div>
                <div className="sword-right">
                    <ImgSwordRight />
                </div>
            </div>
        </div>
    )
}
