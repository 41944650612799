import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#67D380', // Green[200]
            main: '#46CA64', // Green[300]
            dark: '#3AA652', // Green[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#51A9E3', // Blue[200]
            main: '#2B97DD', // Blue[300]
            dark: '#247CB5', // Blue[400]
            contrastText: '#fff',
        },
        error: {
            light: '#EA7569', // Red[200]
            main: '#E65748', // Red[300]
            dark: '#BD483B', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
