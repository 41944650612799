import React from 'react';
import { List } from '@material-ui/core';

export default function QrList(props) {
    const { children, className } = props;

    return (
        <List className={`qr-list ${className ? className : ""}`}>
            { children}
        </List>
    );
}