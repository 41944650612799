import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { actions as FeedsActions } from '../../../redux/ducks/feeds';
import {
    CardActions, Divider, Accordion, AccordionSummary, Typography,
    AccordionDetails, OutlinedInput, InputAdornment, Grid
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FeedCardFooter(props) {
    const dispatch = useDispatch();
    const { id, hasLike, totalLike, userLiked, hasComment, totalComment, hasShare, hasEmail, /* hasFavorite, totalFavorite, userFavorite, reference, */ showFooter, feed } = props;
    const { feeds, sendEmail } = useSelector(state => state.feeds);
    const [likeCount, setLikeCount] = useState(JSON.parse(totalLike ? totalLike : 0));
    const [liked, setLiked] = useState(userLiked);
    const [clicked, setClicked] = useState(false);
    /* const [favoriteCount, setFavoriteCount] = useState(JSON.parse(totalFavorite ? totalFavorite : 0));
    const link = JSON.parse(reference ? reference : null);
    const [favorited, setFavorited] = useState(userFavorite ? userFavorite : null); */
    const [openEmail, setOpenEmail] = useState(false);
    const [typedEmail, setTypedEmail] = useState("");
    const [loadingData, setLoadingData] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    let options = [];

    /* let favorites = [{
        aria: intl.get('ARIA_LABEL_FAVORITE'),
        classButton: 'btn-favorite',
        icon: 'bookmark',
        classLabel: 'label-favorite',
        qtd: favoriteCount,
        active: favorited
    }] */

    let likes = [{
        aria: intl.get('ARIA_LABEL_LIKE'),
        classButton: 'btn-like',
        icon: 'heart',
        classLabel: 'label-like',
        qtd: likeCount,
        active: liked
    }]

    useEffect(() => {
        if (id && liked && clicked) {
            dispatch(FeedsActions.setLike({
                id_feed: id,
                liked: liked,
                like_count: likeCount,
                feeds: feeds.data
            }))
            setClicked(false);
        }
    }, [id, liked, dispatch, userLiked, clicked, feeds, likeCount]);

    /* useEffect(() => {
        if (link && link.id && userFavorite && clicked) {
            dispatch(FeedsActions.favorite({
                id_prize: JSON.parse(link.id),
                favorited: favorited,
            }))
            setClicked(false);
        }
    }, [userFavorite, link, favorited, dispatch, clicked]); */

    function onClickHandler(button) {
        if (button.active === 'Y' && button.classButton === 'btn-like') {
            setLikeCount(likeCount - 1);
            setLiked('N');
            setClicked(true);
        } else if (button.active === 'N' && button.classButton === 'btn-like') {
            setLikeCount(likeCount + 1);
            setLiked('Y');
            setClicked(true);
        }
        /* else if (button.active === 'Y' && button.classButton === 'btn-favorite') {
            setFavoriteCount(favoriteCount - 1);
            setFavorited('N');
            setClicked(true);
        } else if (button.active === 'N' && button.classButton === 'btn-favorite') {
            setFavoriteCount(favoriteCount + 1);
            setFavorited('Y');
            setClicked(true);
        } */
    }

    async function sendMessage() {
        setLoadingData(true);
        await dispatch(FeedsActions.sendEmail({
            id_feed: id,
            message: typedEmail
        }));
        setLoadingData(false);

        if (!sendEmail.error) {
            setEmailSuccess(true);
            setTimeout(function () {
                setEmailSuccess(false);
                setTypedEmail("");
            }, 3000);
        } else {
            setEmailError(true);
            setTimeout(function () {
                setEmailError(false);
            }, 3000);
        }

        return dispatch(FeedsActions.reset({}));
    }

    function actionComment() {
        props.pageComment(true);
        props.feedItem(feed);
    }

    if (hasLike) {
        options = [...likes]
    } else {
        options = []
    }

    return (
        showFooter && (hasLike || /* hasFavorite || */ hasEmail || hasShare || hasComment) ?
            <>
                <Divider />
                <CardActions disableSpacing>
                    {
                        options ?
                            (() => {
                                return (
                                    options.map((button, index) => (
                                        <IconButton key={index} aria-label={button.aria} onClick={() => onClickHandler(button)} className={[button.classButton, button.active === 'Y' ? '__active' : '__disabled'].join(" ")}>
                                            <Icon ic={button.active === 'Y' ? `${button.icon}_filled` : `${button.icon}_outline`} /> <span className={button.classLabel + ' ml-2'}>{button.qtd}</span>
                                        </IconButton>
                                    ))
                                )
                            })()
                            : null
                    }
                    {
                        hasComment ?
                            <IconButton aria-label={intl.get('LABEL_COMMENT')} onClick={() => actionComment()}>
                                <Icon ic="speech_outline" />
                                <Typography className="fs-md font-weight-bold ml-2" variant="body2" component="span">{totalComment}</Typography>
                            </IconButton>
                            : null
                    }
                    {
                        hasEmail ?
                            <IconButton color={`${openEmail ? "primary" : "inherit"}`} aria-label={intl.get('LABEL_EMAIL')} onClick={() => setOpenEmail(!openEmail)}>
                                <Icon ic="envelope_outline" />
                            </IconButton>
                            : null
                    }
                    {
                        /*props.can_share && props.can_share === 'Y'
                            ?
                            <>
                                <IconButton className="share-button" aria-label={intl.get('ARIA_LABEL_SHARE')} >
                                    <Icon ic="share" />
                                </IconButton>
                            </>
                            : null*/
                    }
                </CardActions>
                {
                    hasEmail ?
                        !loadingData ?
                            emailSuccess ?
                                <Alert severity="success">{intl.get('SUCCESS_SENT_MESSAGE')}</Alert>
                                : emailError ?
                                    <Alert severity="error">{intl.get('ERROR_SENT_MESSAGE')}</Alert>
                                    :
                                    <Accordion expanded={openEmail}>
                                        <AccordionSummary></AccordionSummary>
                                        <AccordionDetails>
                                            <OutlinedInput
                                                id={`message_${id}`}
                                                name={`message_${id}`}
                                                label=""
                                                notched={false}
                                                placeholder={intl.get('LABEL_MESSAGE')}
                                                value={typedEmail}
                                                multiline
                                                fullWidth
                                                margin="dense"
                                                onChange={(e) => setTypedEmail(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={intl.get('LABEL_MESSAGE')}
                                                            edge="end"
                                                            onClick={() => sendMessage()}
                                                            disabled={typedEmail ? false : true}
                                                        >
                                                            <Icon ic="send" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                            :
                            <Grid container justify="center">
                                <LoadingBounce size={24} />
                            </Grid>
                        : null
                }
            </>
            : null
    )
}
