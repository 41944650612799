import { qrService } from './base.service'

function getTotalScorm(params) {
    return qrService('/v3/app/profile/manager/myteam/scorm/total', params)
}

function getTotalClassroom(params) {
    return qrService('/v3/app/profile/manager/myteam/classroom/total', params)
}

function getTotalTrail(params) {
    return qrService('/v3/app/profile/manager/myteam/course/total', params)
}

function getTeamEmployees(params) {
    return qrService('/v3/app/profile/manager/myteam/list/score-and-activity', params)
}

function getTeamActivities(params) {
    return qrService('/v3/app/profile/manager/myteam/activities', params)
}

function getGeneralActivities(params) {
    return qrService('/v3/app/profile/manager/general/activities', params)
}

function getManagerUsersList(params) {
    return qrService('/v3/app/profile/manager/user/list', params)
}

function getRegionalList(params) {
    return qrService('/v1/app/regional/list', params)
}

function getSubareaList(params) {
    return qrService('/v1/app/subarea/list', params)
}

function getBrandList(params) {
    return qrService('/v1/app/brand/list', params)
}

function getCostCenterList(params) {
    return qrService('/v1/app/cost-center/list', params)
}

export const managerService = {
    getTotalScorm,
    getTotalClassroom,
    getTotalTrail,
    getTeamEmployees,
    getTeamActivities,
    getGeneralActivities,
    getManagerUsersList,
    getRegionalList,
    getSubareaList,
    getBrandList,
    getCostCenterList,
}