import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { useLastLocation } from 'react-router-last-location';
import {
    AppBar, Button, Divider, Drawer, IconButton, List, Grid,
    ListItem, ListItemIcon, ListItemText, Toolbar, Typography
} from '@material-ui/core';
import { Icon } from '../../../components/Images/Images';

export default function StepScorm(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const session = props.location.state && props.location.state.session ? props.location.state.session : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const scormHost = process.env.REACT_APP_QR_HOST_SCORM;
    const { result } = useSelector(state => state.trail);
    const [, setRunning] = useState(false);
    const [scormState, setScormState] = useState({ emptyData: 1 });
    const [, setLink] = useState(null);
    const [saving, setSaving] = useState(false);
    const [newLink, setNewlink] = useState(false);
    const [linkFrame, setlinkFrame] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [scormFrameId, setscormFrameId] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const lastLocation = useLastLocation();

    useEffect(() => {
        if (lastLocation && (lastLocation.pathname.includes("intro") && origin === "scorm")) {
            setRedirect(false);
        } else {
            setRedirect(true);
        }
    }, [lastLocation, origin]);

    const buildQueryString = useCallback((state) => {
        if (session) {
            const userAction = [];
            let cookie_name = `qrc${step.id_step + selectedItem.id_item + auth.user.id_user}`;
            setscormFrameId(cookie_name)
            userAction.push(`cookie=${cookie_name}`);
            userAction.push(`cmi.core.student_id=${auth.user.id_user}`);
            userAction.push(`cmi.core.student_name=${auth.user.email}`);
            for (var i in state) {
                userAction.push(`${i}=${state[i]}`);
            }
            return window.btoa(window.location.origin + '#&qr&#' + selectedItem.index_path + '#&qr&#' + userAction.join('#&qr&#'));
        }
    }, [auth, step, selectedItem, session]);

    let defineLink = useCallback((state) => {
        const link = `${scormHost}/qrscplayer.html?${(new Date()).getTime()}#${buildQueryString(state)}`;
        setLink(link);

        if (!linkFrame || newLink) {
            setNewlink(false);
            setlinkFrame(link);
        }
    }, [buildQueryString, linkFrame, newLink, scormHost]);

    const beforeUnLoad = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = '';
    }

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnLoad);

        return () => {
            window.removeEventListener('beforeunload', beforeUnLoad);
        };
    }, []);

    useEffect(() => {
        if (Object.keys(scormState).length > 0)
            defineLink(scormState);
    }, [defineLink, scormState]);

    useEffect(() => {
        const channelPostMessage = new BroadcastChannel('qranioscorms');

        window.addEventListener("message", (event) => {
            channelPostMessage.postMessage(event.data);
        }, false);

        const channel = new BroadcastChannel('qranioscorms');

        channel.onmessage = (e) => {
            const ev = e.data;
            // Adicionado para que so envie informações para o servidor se for o mesmo id para não ter problemas com abertura em mais de uma aba
            if ((ev.scorm_frame_id !== scormFrameId) || !session) {
                return
            }

            switch (ev.type) {
                case 'dataObjectScorm':
                    setRunning(true);
                    const obj = JSON.parse(ev.data);
                    const entries = Object.entries(obj);
                    entries.forEach(([key, value]) => {
                        // Pega o status do usuario no pacote para quando mudar fazer a atualização
                        if (key === 'cmi.core.lesson_status' && (value === 'completed' || value === 'passed')) {
                            session.scorm.map((item, index) => {
                                if (item.id_item === selectedItem.id_item) {
                                    session.scorm[index].completed = 'Y';
                                }
                                return null;
                            })
                        }

                        // Colocado para só colocar no state e nao definir o link ainda, somente depois que processar fazer o define no link
                        currentState[key] = String(value);
                    });

                    // Adicionado depois do foreach para não travar o navegador do usuário quando tem muitas variáves
                    setScormState(currentState);
                    defineLink(currentState);
                    break

                case 'dataObjectScormFinished':
                    setSaving(true);
                    dispatch(TrailActions.getStepCommit({
                        id_step: session.id_step,
                        id_session: session.id_session,
                        refers_id: selectedItem.id_item,
                        content: ev.data
                    }));
                    channel.close();
                    break

                default:
                    break
            }
        }

        const currentState = {};

        if (session) {
            for (var i = 0; i < selectedItem.user_actions.length; i++) {
                currentState[selectedItem.user_actions[i].var] = String(selectedItem.user_actions[i].value);
            }
        }

        setScormState(currentState);
        defineLink(currentState);

        if (Object.keys(currentState).length > 0) {
            setRunning(true);
        }

        return function cleanup() {
            setScormState({});
            channel.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defineLink, selectedItem]);

    const handleClose = async () => {
        let data = {
            data: 'closescorm',
            scorm_frame_id: scormFrameId
        }
        const propagateSignal = (type, data = {}) => {
            const send = { type, ...data };
            if (document.querySelector('#scorm-package-content-iframe').contentWindow) {
                document.querySelector('#scorm-package-content-iframe').contentWindow.postMessage(send, scormHost);
            }
            return true;
        }
        propagateSignal('message', data)
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default appbar-sm" position="sticky" color="primary">
                <Toolbar>
                    {
                        saving ?
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h5" aria-label="close" noWrap>
                                    {intl.get('SCORM_PACKAGE_SAVING_TEXT')}
                                </Typography>
                            </div>
                            :
                            <div className="d-flex align-items-center w-100 overflow-hidden">
                                <Button className="button-sm" color="inherit" onClick={handleClose} aria-label="close">
                                    <Icon ic="close" className="mr-3" />
                                    {intl.get('SCORM_PACKAGE_SAVE_BUTTON')}
                                </Button>
                                <Typography variant="h4" className="ml-3" noWrap>
                                    {session && session.step_name}
                                </Typography>
                            </div>
                    }
                    {
                        session.scorm?.length > 1
                            ?
                            <IconButton color="inherit" onClick={() => setOpenDrawer(true)} aria-label="open-drawer">
                                <Icon ic="menu_filled" />
                            </IconButton>
                            : null
                    }
                </Toolbar>
            </AppBar>
        )
    }

    function handleSelectedItem(item) {
        setSelectedItem(item);
        setNewlink(true);
        defineLink(scormState);
    }

    if (!selectedItem && session) {
        let i = 0;
        while (i < session.scorm.length && session.scorm[i].completed === 'Y') {
            i++;
        }
        if (i >= session.scorm.length) {
            setSelectedItem(session.scorm[0]);
        } else {
            setSelectedItem(session.scorm[i]);
        }
    }

    if (result && (result.load === "finished" && !result.error)) {
        if (result.data.step_result) {
            return <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                state: {
                    origin: 'scorm',
                    step: step,
                    stepResult: result.data
                }
            }} />
        } else {
            return <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}`,
                state: props.location.state
            }} />
        }
    }

    return (
        !redirect ?
            <Grid container id="scorm-package-page">
                <QrAppBar />
                <div id="scorm-package-content" className="embed-responsive embed-responsive-1by1">
                    <iframe allow="camera *; accelerometer; fullscreen;" allowvr="yes" title="scorm-package" id="scorm-package-content-iframe" className="embed-responsive-item" src={linkFrame} />
                </div>
                <Drawer id="scorm-package-drawer" anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                    <div className="drawer-dialog-header">
                        <IconButton onClick={() => setOpenDrawer(false)}>
                            <Icon ic="swipe_right" />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {
                            session.scorm?.map((item, index) => {
                                return (
                                    <ListItem button key={index} disabled={item.blocked === 'Y'} onClick={() => handleSelectedItem(item)}>
                                        <ListItemIcon>
                                            <Icon ic={`checkbox_${item.completed === 'Y' ? "checked" : "unchecked"}`} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Drawer>
            </Grid>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                state: {
                    origin: 'scorm'
                }
            }} />
    )
}
