import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { getSecondToHours } from '../../../../utils/utils';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import ImgTimer from '../../../../dist/img/svgr/ImgTimer';

const ExamTimer = ({ finishDate, startDate, serverDate, timeOut, currentQuestion, totalQuestion }) => {
    const [timeRemaining, setTimeRemaining] = useState(""); // Tempo restante no formato HH:MM:SS
    const [timeProgress, setTimeProgress] = useState(0); // Progresso da barra (0% a 100%)
    const [elapsedSeconds, setElapsedSeconds] = useState(0); // Tempo decorrido em segundos
    const timeTotal = useRef(0); // Tempo total da prova em segundos
    const intervalId = useRef(null); // Referência para o intervalo do temporizador

    useEffect(() => {
        const finishDateMoment = moment(finishDate);
        const startDateMoment = moment(startDate);
        const serverDateMoment = moment(serverDate);

        timeTotal.current = finishDateMoment.diff(startDateMoment, 'seconds');
        setElapsedSeconds(serverDateMoment.diff(startDateMoment, 'seconds'));

        intervalId.current = setInterval(() => {
            setElapsedSeconds(prev => prev + 1);
        }, 1000);

        return () => clearInterval(intervalId.current);
    }, [finishDate, startDate, serverDate]);

    useEffect(() => {
        const remainingSeconds = timeTotal.current - elapsedSeconds;
        if (remainingSeconds <= 0) {
            timeOut(true);
            setTimeRemaining("00:00:00");
            setTimeProgress(100);
            clearInterval(intervalId.current);
            return;
        }

        setTimeRemaining(getSecondToHours(remainingSeconds));
        const progress = (elapsedSeconds / timeTotal.current) * 100;
        setTimeProgress(progress > 100 ? 100 : progress);
    }, [elapsedSeconds, timeOut]);

    return (
        <Grid container>
            <Grid container justify="center">
                <div className="question-timer-progress">
                    <div className="timer-image">
                        <ImgTimer className="img-md" />
                    </div>
                    <div className="w-100">
                        <LinearProgress
                            variant="determinate"
                            className="progress-bar"
                            value={timeProgress}
                        />
                    </div>
                </div>
            </Grid>
            <Grid container justify="center">
                <Typography variant="body2" component="p">
                    {`Pergunta ${parseInt(currentQuestion) <= parseInt(totalQuestion) ? currentQuestion : totalQuestion} / ${totalQuestion}`} - {timeRemaining}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ExamTimer