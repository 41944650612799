import * as React from "react"

function Classroom(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          d="M21.976 4H4.56c-.563 0-1.024.465-1.024 1.032v13.42H23V5.032C23 4.465 22.539 4 21.976 4z"
          fill="#2AB69C"
        />
        <path
          d="M3.536 9.848c.404.371.934.604 1.52.604h.002c1.254-.005 2.277-1.046 2.28-2.323 0-1.28-1.023-2.323-2.281-2.323-.587 0-1.117.233-1.521.604v3.438z"
          fill="#000"
          opacity={0.07}
        />
        <path
          d="M21.03 7.097h-7.606a.256.256 0 01-.253-.258v-.516c0-.143.113-.258.253-.258h7.607c.14 0 .253.115.253.258v.516a.256.256 0 01-.253.258zm0 2.064h-7.606a.256.256 0 01-.253-.258v-.516c0-.142.113-.258.253-.258h7.607c.14 0 .253.116.253.258v.516a.256.256 0 01-.253.258zm-2.028 2.065h-5.578a.256.256 0 01-.253-.258v-.516c0-.143.113-.258.253-.258h5.578c.14 0 .254.115.254.258v.516a.256.256 0 01-.254.258z"
          fill="#FFF"
        />
        <path
          d="M1 20v-5.333c.002-1.9 1.515-3.44 3.38-3.441h1.353c1.867.002 1.35 1.541 1.352 3.44V20H1z"
          fill="#5F5F5F"
        />
        <path
          d="M5.057 10.194c-1.12 0-2.029-.925-2.029-2.065s.909-2.064 2.029-2.064c1.12 0 2.028.924 2.028 2.064-.003 1.139-.91 2.061-2.028 2.065"
          fill="#FDC436"
        />
        <path
          d="M5.564 11.226H4.55c-1.12 0-2.029.924-2.029 2.064V20h5.071v-6.71c0-1.14-.908-2.064-2.028-2.064z"
          fill="#5F5F5F"
        />
        <path
          d="M14.04 13.806a1.007 1.007 0 00-1.332-.541l-2.632 1.137-2.926-2.725c-.015-.014-.033-.023-.048-.036a1.5 1.5 0 00-1.03-.415h-2.03c-.84 0-1.52.693-1.52 1.548V20h5.07v-5.113l1.598 1.487a1.003 1.003 0 001.084.187l3.233-1.4c.515-.224.753-.83.532-1.355z"
          fill="#5F5F5F"
        />
        <path fill="#FFF" d="M5.057 14.323l-1.014-3.097H6.07z" />
        <path
          d="M12.883 15.434l.624-.272c.515-.225.753-.831.532-1.356a1.007 1.007 0 00-1.331-.541l-.57.248.745 1.92z"
          fill="#FDC436"
        />
      </g>
    </svg>
  )
}

export default Classroom
