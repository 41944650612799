import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from "../../config";
import { AppBar, Toolbar, Typography, Container, Grid, useMediaQuery } from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import FeedsList from './list';

export default function Feeds(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('lg'));
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_FEEDS');

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }
    }, [auth, history]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-main" position="static">
                <Toolbar>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FEEDS_TITLE")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {intl.get("FEEDS_SUBTITLE")}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                isScreenUpMd ?
                    <Redirect to={{
                        pathname: `${history.location.pathname.replace(/./g, '')}/home`,
                        state: {
                            origin: 'feeds'
                        }
                    }} />
                    :
                    auth.authenticated &&
                    <div id="qr-wrap">
                        <QrMenu />
                        <div id="qr-content">
                            <QrContent id="feeds" bottomNavigation={bottomMenuItem}>
                                {
                                    config.layout.navbar ?
                                        <QrAppBar />
                                        : null
                                }
                                <Container maxWidth="md" className="container-full pt-3 pb-3">
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={10}>
                                            <Switch>
                                                <Route path={`${props.match.path}`} exact component={(props) =>
                                                    <FeedsList type="page" {...props} />} />
                                            </Switch>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </QrContent>
                        </div>
                    </div>
            }
        </div>
    )
};