import React from 'react';
import Confetti from '../../../../../components/Confetti';
import ImgPodium from '../../../../../dist/img/intros/ranking/ImgPodium';
import ImgSunrise from '../../../../../dist/img/intros/ranking/ImgSunrise';

export default function RankingIntro() {
    return (
        <div id="intro-slider-ranking">
            <Confetti />
            <div className="intro-content">
                <div className="sunrise">
                    <ImgSunrise />
                </div>
                <div className="podium">
                    <ImgPodium />
                </div>
            </div>
        </div>
    )
}
