import React, { useEffect, useState } from "react";
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from "../../config";
import {
    DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText,
    Button

} from '@material-ui/core';
import PrizesList from "./list";
import PrizeDetails from "./details";
import PrizeRedeem from "./redeem";
import PrizeBookmarkList from "./bookmark.list";

export default function Prizes(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.auth.user);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.PRIZES && auth.modules.PRIZES.ENABLED === 'N')) {
            history.replace('/');
        }

        // @TODO - Flavio corrigir depois com calma
        if (!user.cpf || !user.birth_date || user.has_main_address === "N" || user.confirmed_redeem_data === "N") {
            setOpenDialog(true)
        }
    }, [auth, user, history]);

    const handleButtonClick = () => {
        const { has_main_address, confirmed_redeem_data } = user;
        const basePath = props.match.path.replace(/./g, '');
        let redirect = 'N';
        let path = `${basePath}/settings`;

        if (confirmed_redeem_data === "N") {
            path = `${basePath}/settings/data-redeem/info`;
        } else if (has_main_address === "N") {
            path = `${basePath}/settings/data-redeem/address`;
        }

        history.push({
            pathname: path,
            state: {
                origin: 'prizes',
                redirect: redirect
            }
        });
    };

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <Switch>
                    <Route path={`${props.match.path}`} exact component={
                        (props) => <PrizesList {...props} />}
                    />

                    <Route path={`${props.match.path}/bookmarks`} exact component={
                        (props) => <PrizeBookmarkList {...props} />}
                    />

                    <Route path={`${props.match.path}/:idPrize`} exact component={
                        (props) => <PrizeDetails {...props} />}
                    />

                    <Route path={`${props.match.path}/:idPrize/redeem`} exact component={
                        (props) => <PrizeRedeem {...props} />}
                    />
                </Switch>
            }

            {/* Dialog Data Redeem */}
            <Dialog
                fullWidth
                maxWidth="sm"
                disableEscapeKeyDown
                disableBackdropClick
                open={openDialog}
                aria-labelledby="dialog-data-redeem-title"
                aria-describedby="dialog-data-redeem-conditions"
            >
                <DialogTitle>
                    {intl.get("PRIZES_DIALOG_DATA_REDEEM_TITLE")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {intl.get("PRIZES_DIALOG_DATA_REDEEM_TEXT")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleButtonClick}>
                        {intl.get("BTN_COMPLETE_MY_DATA")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};