import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function QrImage(props) {
    return (
        <div>
            <LazyLoadImage
                alt={props.alt}
                height={props.height}
                src={props.src} // use normal <img> attributes as props
                width={props.width}
                effect={props.effect}
                className={props.className}
            />
            <span>{props.caption}</span>
        </div>
    );
}