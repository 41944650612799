import React from 'react';
import intl from "react-intl-universal";
import config from '../../../../config';
import { getDateTime } from '../../../../utils/utils';
import { Button, Typography } from '@material-ui/core';
import { Icon, StageMedal } from '../../../../components/Images/Images';

export default function ButtonStep(props) {
    const { action, type, available, cleared, grade, number, numberDescription, name, dependency, dateRelease } = props;
    let unlockStep = available === 'Y';
    let clearedStep = cleared === 'Y';

    const Type = () => {
        switch (type) {
            case "FLASHCARD":
                return (
                    <Typography variant="h3" component="h3">
                        {number}
                    </Typography>
                )
            case "SCORM":
                return (
                    <Typography variant="h3" component="h3">
                        {number}
                    </Typography>
                )
            case "EXAM":
                return (
                    <Icon ic="pencil" />
                )
            case "CODE":
                return (
                    <Icon ic="key" />
                )
            default:
        }
    }

    const Level = () => {
        return (
            <div className={`step-content-medal ${clearedStep ? "__cleared" : "__bordered"}`}>
                {
                    clearedStep ?
                        <>
                            <StageMedal
                                className="medal"
                                type={
                                    grade === 1 ?
                                        "bronze"
                                        :
                                        grade === 2 ?
                                            "silver"
                                            :
                                            grade === 3 ?
                                                "gold"
                                                : null
                                }
                            />
                            <div className={`step-content-medal-number${config.learn.trail.type === "step" ? " __clean" : ""}`}>
                                <Type />
                            </div>
                        </>
                        :
                        <Type />
                }
            </div>
        )
    }

    return (
        <Button variant="outlined" className="btn-card __step" disabled={!unlockStep} onClick={action} fullWidth>
            <div className="step-content">
                <Level />
                <div className="step-content-text">
                    <Typography variant="caption" component="p">
                        {numberDescription}
                    </Typography>
                    <Typography variant="body1" component="h4">
                        {name}
                    </Typography>
                    {
                        available && available === "N" ?
                            dependency ?
                                <Typography variant="caption" component="p">
                                    {`${intl.get("TRAIL_STEP_DEPENDENCY_TEXT_1")} ${dependency} ${intl.get("TRAIL_STEP_DEPENDENCY_TEXT_2")}`}
                                </Typography>
                                :
                                dateRelease ?
                                    <Typography variant="caption" component="p">
                                        {`${intl.get("TRAIL_STEP_DATE_RELEASE_TEXT")} ${getDateTime(dateRelease)}`}
                                    </Typography>
                                    : null
                            : null
                    }
                </div>
                {
                    available && available === "N" ?
                        <Icon ic="lock" className="mr-3 icon-sm" />
                        : null
                }
            </div>
        </Button>
    )
}