import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import countries from 'i18n-iso-countries';
import config from '../../config';
import { Redirect } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import * as yup from 'yup';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { maskCellphone, getDateFormat } from '../../utils/utils';
import { Form, Formik } from 'formik';
import {
    Button, TextField, Grid, Hidden, Typography, Link, FormControl,
    FormGroup, FormControlLabel, Checkbox, FormHelperText, MenuItem
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import IntroLayout from './components/IntroLayout';
import DialogTermsPrivacy from './components/DialogTermsPrivacy';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';

countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export default function RecoverPassword(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [user] = useState(auth.user);
    const [didMount, setDidMount] = useState(true);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [isNumValid, setNumValid] = useState(false);
    const [isNumValidYup, setNumValidYup] = useState(true);
    const [countryCode, setCountryCode] = useState('');
    const [redirectPage, setRedirectPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [typeDialog, setTypeDialog] = useState("");
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';
    let haveCellNumber = config.login_method.complete_data.form.cell_number.enabled;
    let haveCity = config.login_method.complete_data.form.city.enabled;
    let haveCountry = config.login_method.complete_data.form.country.enabled;
    let haveEmail = config.login_method.complete_data.form.email.enabled;
    let haveBirthdate = config.login_method.complete_data.form.birthdate.enabled;
    let haveState = config.login_method.complete_data.form.state.enabled;
    let haveBrand = config.login_method.complete_data.form.brand.enabled;
    let haveRole = config.login_method.complete_data.form.role.enabled;
    let haveRoleFamily = config.login_method.complete_data.form.role_family.enabled;
    let haveRoleGroup = config.login_method.complete_data.form.role_group.enabled;
    let havePosition = config.login_method.complete_data.form.position.enabled;
    let haveRegional = config.login_method.complete_data.form.regional.enabled;
    let haveSubarea = config.login_method.complete_data.form.subarea.enabled;
    let haveBoard = config.login_method.complete_data.form.board.enabled;
    let haveExecutiveBoard = config.login_method.complete_data.form.executive_board.enabled;
    let haveManaging = config.login_method.complete_data.form.managing.enabled;
    let haveCostCenter = config.login_method.complete_data.form.cost_center.enabled;
    let haveEnterprise = config.login_method.complete_data.form.enterprise.enabled;
    let emailDomain = config.login_method.complete_data.form.email.domain;
    let phoneRegExp = /^(?:\+)[0-9]{2} \([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;
    let language = user ? user.language.substr(0, 2) : process.env.REACT_APP_LANGUAGE_DEFAULT;
    let locale = require(`date-fns/locale/${language}`);
    let states = require("../../utils/states.json");

    const validations = yup.object().shape({
        cellNumber: haveCellNumber ?
            config.login_method.complete_data.form.cell_number.required ?
                yup.string()
                    .test("is-cell-number", intl.get('ERROR_INVALID_CELLPHONE'), (value) => validCellNumberYup(value))
                    .required(() => intl.get('ERROR_REQUIRED_FIELD'))
                :
                yup.string()
                    .test("is-cell-number", intl.get('ERROR_INVALID_CELLPHONE'), (value) => value && value.length > 0 ? validCellNumberYup(value) : true)
            :
            null,
        email: haveEmail ?
            config.login_method.complete_data.form.email.required ?
                yup.string()
                    .email(() => intl.get('ERROR_INVALID_EMAIL'))
                    .required(() => intl.get('ERROR_REQUIRED_FIELD'))
                    .test("is-email", intl.get('ERROR_INVALID_EMAIL_DOMAIN') + emailDomain.join(` ${intl.get('ERROR_INVALID_EMAIL_DOMAIN_DIVIDER')} `), (value) => validEmailDomain(value))
                :
                yup.string()
                    .email((value) => value.value && value.value.length > 0 ? intl.get('ERROR_INVALID_EMAIL') : null)
                    .test("is-email", (value) => value.value && value.value.length > 0 ? intl.get('ERROR_INVALID_EMAIL_DOMAIN') + emailDomain.join(` ${intl.get('ERROR_INVALID_EMAIL_DOMAIN_DIVIDER')} `) : null, (value) => validEmailDomain(value))
            :
            null,
        birth_date: haveBirthdate ?
            config.login_method.complete_data.form.birthdate.required && yup.date()
                .required(() => intl.get('ERROR_REQUIRED_FIELD')).typeError(() => intl.get('ERROR_INVALID_DATE')).nullable()
            :
            yup.date().typeError(() => intl.get('ERROR_INVALID_DATE')).nullable(),
        country: haveCountry ?
            config.login_method.complete_data.form.country.required && yup.string()
                .required(() => intl.get('ERROR_SELECT_COUNTRY'))
            :
            null,
        state: haveState ?
            config.login_method.complete_data.form.state.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        city: haveCity ?
            config.login_method.complete_data.form.city.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        brand: haveBrand ?
            config.login_method.complete_data.form.brand.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        role: haveRole ?
            config.login_method.complete_data.form.role.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        roleFamily: haveRoleFamily ?
            config.login_method.complete_data.form.role_family.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        roleGroup: haveRoleGroup ?
            config.login_method.complete_data.form.role_group.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        position: havePosition ?
            config.login_method.complete_data.form.position.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        regional: haveRegional ?
            config.login_method.complete_data.form.regional.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        subarea: haveSubarea ?
            config.login_method.complete_data.form.subarea.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        board: haveBoard ?
            config.login_method.complete_data.form.board.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        executiveBoard: haveExecutiveBoard ?
            config.login_method.complete_data.form.executive_board.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        managing: haveManaging ?
            config.login_method.complete_data.form.managing.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        costCenter: haveCostCenter ?
            config.login_method.complete_data.form.cost_center.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        enterprise: haveEnterprise ?
            config.login_method.complete_data.form.enterprise.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        termsConfirmation: yup.boolean().oneOf([true], intl.get('ERROR_TERMS_CONFIRMATION'))
    });

    const countryList = Object.keys(countries.getNames(language)).map((country) => {
        return {
            name: countries.getName(country, language),
            code: countries.getAlpha2Code(countries.getName(country, language), language)
        }
    });

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(AuthActions.getTermsOfUse({
                language: process.env.REACT_APP_LANGUAGE_DEFAULT,
                type: 'TERMS_OF_USE'
            }));

            dispatch(AuthActions.getPrivacyPolitics({
                language: process.env.REACT_APP_LANGUAGE_DEFAULT,
                type: 'PRIVACY_STATEMENT'
            }));

            if (haveBrand) {
                dispatch(AuthActions.getBrandList({
                    limit: 0
                }));
            }

            if (haveRole) {
                dispatch(AuthActions.getRoleList({
                    limit: 0
                }));
            }

            if (haveRoleFamily) {
                dispatch(AuthActions.getRoleFamilyList({
                    limit: 0
                }));
            }

            if (haveRoleGroup) {
                dispatch(AuthActions.getRoleGroupList({
                    limit: 0
                }));
            }

            if (havePosition) {
                dispatch(AuthActions.getPositionList({
                    limit: 0
                }));
            }

            if (haveRegional) {
                dispatch(AuthActions.getRegionalList({
                    limit: 0
                }));
            }

            if (haveSubarea) {
                dispatch(AuthActions.getSubareaList({
                    limit: 0
                }));
            }

            if (haveBoard) {
                dispatch(AuthActions.getBoardList({
                    limit: 0
                }));
            }

            if (haveExecutiveBoard) {
                dispatch(AuthActions.getExecutiveBoardList({
                    limit: 0
                }));
            }

            if (haveManaging) {
                dispatch(AuthActions.getManagingList({
                    limit: 0
                }));
            }

            if (haveCostCenter) {
                dispatch(AuthActions.getCostCenterList({
                    limit: 0
                }));
            }

            if (haveEnterprise) {
                dispatch(AuthActions.getExternalEnterpriseList({
                    limit: 0
                }));
            }
        }

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch, haveBrand, haveRole, haveRoleFamily, haveRoleGroup, havePosition,
        haveRegional, haveSubarea, haveBoard, haveExecutiveBoard, haveManaging, haveCostCenter, haveEnterprise]);

    useEffect(() => {
        if (redirect === "N" && auth.user) {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }

        if (auth.completeDataStatus === "success") {
            setLoading(false);
            auth.completeDataStatus = "";
            history.replace('/home');
        }

        if (auth.completeDataStatus === "error") {
            setLoading(false);
            auth.completeDataStatus = "";
            setSnackOpen(true);
        }
    }, [history, auth.completeDataStatus, auth.user, redirect]);

    const sendData = (data) => {
        setLoading(true);
        dispatch(AuthActions.completeData({
            phone_ddi: haveCellNumber ? data.cellNumber.split((/[\s]+/), 1)[0] : '',
            phone_number: haveCellNumber ? data.cellNumber.split(`${data.cellNumber.split((/[\s]+/), 1)[0]} `)[1] : '',
            email: haveEmail ? data.email : '',
            birth_date: haveBirthdate ? getDateFormat(data.birth_date) : '',
            country: haveCountry ? data.country : '',
            state: haveState ? data.state : '',
            city: haveCity ? data.city : '',
            brand_id: haveBrand ? data.brand : '',
            role_id: haveRole ? data.role : '',
            role_family_id: haveRoleFamily ? data.roleFamily : '',
            role_group_id: haveRoleGroup ? data.roleGroup : '',
            position_id: havePosition ? data.position : '',
            regional_id: haveRegional ? data.regional : '',
            sub_area_id: haveSubarea ? data.subarea : '',
            board_id: haveBoard ? data.board : '',
            executive_board_id: haveExecutiveBoard ? data.executiveBoard : '',
            management_id: haveManaging ? data.managing : '',
            cost_center_id: haveCostCenter ? data.costCenter : '',
            external_enterprise_id: haveEnterprise ? data.enterprise : '',
        }));
    };

    function validCellNumberYup(val) {
        if (val && val.match(phoneRegExp)) {
            setNumValid(true);
            setNumValidYup(true);
            return true;
        } else {
            setNumValid(false);
            setNumValidYup(false);
            return false;
        }
    }

    function validEmailDomain(val) {
        var domain = val ? val.split("@")[1] : "";

        if (emailDomain[0] === "general") {
            return true;
        } else {
            if (emailDomain.some(el => el === domain)) {
                return true;
            } else {
                return false;
            }
        }
    }

    function showTermsAndPrivacy(type) {
        setOpen(true);
        setTypeDialog(type);
    }

    return (
        !redirectPage ?
            <IntroLayout title={intl.get("INTRO_COMPLETE_DATA_TITLE")} appbarMobile="default" backButton={false}>
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get("INTRO_COMPLETE_DATA_TITLE")}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Hidden>
                    <Formik
                        validationSchema={validations}
                        initialValues={{
                            cellNumber: '',
                            city: '',
                            country: haveCountry ? 'BR' : '',
                            birth_date: auth.user?.birth_date ? moment(auth.user.birth_date) : null,
                            email: '',
                            state: '',
                            brand: auth.user?.information?.brand_id ? auth.user.information.brand_id : '',
                            role: auth.user?.information?.role_id ? auth.user.information.role_id : '',
                            roleFamily: auth.user?.information?.role_family_id ? auth.user.information.role_family_id : '',
                            roleGroup: auth.user?.information?.role_group_id ? auth.user.information.role_group_id : '',
                            position: auth.user?.information?.position_id ? auth.user.information.position_id : '',
                            regional: auth.user?.information?.regional_id ? auth.user.information.regional_id : '',
                            subarea: auth.user?.information?.sub_area_id ? auth.user.information.sub_area_id : '',
                            board: auth.user?.information?.board_id ? auth.user.information.board_id : '',
                            executiveBoard: auth.user?.information?.executive_board_id ? auth.user.information.executive_board_id : '',
                            managing: auth.user?.information?.management_id ? auth.user.information.management_id : '',
                            costCenter: auth.user?.information?.center_id ? auth.user.information.center_id : '',
                            enterprise: auth.user?.information?.external_enterprise_id ? auth.user.information.external_enterprise_id : '',
                            termsConfirmation: false
                        }}
                        onSubmit={(data) => { sendData(data) }}
                    >
                        {
                            ({ values, handleBlur, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit} className="w-100">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography component="p" variant="body2">
                                                {intl.get("INTRO_COMPLETE_DATA_TEXT")}
                                            </Typography>
                                        </Grid>
                                        {
                                            // * Celular *
                                            haveCellNumber ?
                                                <Grid item xs={12}>
                                                    <PhoneInput
                                                        country={'br'}
                                                        onChange={(cellNumber, data, e) => {
                                                            if (data.countryCode === "br") {
                                                                if ((data.format.length === (maskCellphone(cellNumber).length + 2))) {
                                                                    setNumValid(true)
                                                                } else {
                                                                    setNumValid(false);
                                                                }
                                                            } else {
                                                                if (data.format.replace(/([-()+\w]+|\s+)/g, '').length === cellNumber.length) {
                                                                    setNumValid(true);
                                                                } else {
                                                                    setNumValid(false);
                                                                }
                                                            }
                                                            setCountryCode(data.countryCode);
                                                            handleChange(e)
                                                        }}
                                                        localization={{ br: 'Brasil', es: 'España' }}
                                                        preferredCountries={['br', 'us', 'es']}
                                                        specialLabel={`${intl.get('LABEL_CELL_NUMBER')}${config.login_method.complete_data.form.cell_number.required ? " *" : ""}`}
                                                        defaultErrorMessage={intl.get('ERROR_INVALID_CELLPHONE')}
                                                        countryCodeEditable={false}
                                                        inputProps={{
                                                            name: 'cellNumber',
                                                            required: config.login_method.complete_data.form.cell_number.required
                                                        }}
                                                        onBlur={handleBlur}
                                                        masks={{ br: '(..) .....-....' }}
                                                        enableLongNumbers={["br", "es", "us"].some(el => el === countryCode) ? false : true}
                                                        isValid={(cellNumber, data) => {
                                                            if (!isNumValidYup || cellNumber.length > data.countryCode.length) {
                                                                if (!isNumValid) {
                                                                    if (data.iso2 === "br") {
                                                                        if (values.cellNumber.match(phoneRegExp)) {
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    } else {
                                                                        return data.format.length === values.cellNumber.length
                                                                    }
                                                                } else {
                                                                    return true;
                                                                }
                                                            } else {
                                                                return true;
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * E-mail *
                                            haveEmail ?
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        autoComplete="email"
                                                        type="email"
                                                        label={intl.get('LABEL_EMAIL')}
                                                        fullWidth
                                                        required={config.login_method.complete_data.form.email.required}
                                                        variant={config.layout.input_variant}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        error={(errors.email && touched.email)}
                                                        helperText={(errors.email && touched.email) && errors.email}
                                                    />
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Data de aniversário *
                                            haveBirthdate ?
                                                <Grid item xs={12}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            required={config.login_method.complete_data.form.birthdate.required}
                                                            id="birth_date"
                                                            name="birth_date"
                                                            margin="none"
                                                            label={intl.get('LABEL_BIRTHDAY')}
                                                            inputVariant={config.layout.input_variant}
                                                            format="dd/MM/yyyy"
                                                            value={values.birth_date}
                                                            onBlur={handleBlur}
                                                            onChange={value => setFieldValue("birth_date", value)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': intl.get('LABEL_BIRTHDAY'),
                                                            }}
                                                            okLabel={intl.get('BTN_CONFIRM')}
                                                            clearLabel={intl.get('BTN_CLEAR')}
                                                            cancelLabel={intl.get('BTN_CANCEL')}
                                                            error={(errors.birth_date && touched.birth_date)}
                                                            helperText={(errors.birth_date && touched.birth_date) && errors.birth_date}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * País *
                                            haveCountry ?
                                                <Grid item xs={12}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        required={config.login_method.complete_data.form.country.required}
                                                        name="country"
                                                        label={intl.get('LABEL_COUNTRY')}
                                                        defaultValue={values.country ? values.country : ""}
                                                        variant={config.layout.input_variant}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={(errors.country && touched.country)}
                                                        helperText={(errors.country && touched.country) && errors.country}
                                                    >
                                                        {countryList.map((country) => (
                                                            <MenuItem
                                                                value={country.code}
                                                                key={country.code}
                                                            >
                                                                {country.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Estado *
                                            haveState ?
                                                <Grid item xs={12}>
                                                    {
                                                        values.country && values.country === "BR" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.state.required}
                                                                name="state"
                                                                label={intl.get('LABEL_STATE')}
                                                                defaultValue={values.state ? values.state : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.state && touched.state)}
                                                                helperText={(errors.state && touched.state) && errors.state}
                                                            >
                                                                {states.br.map((item) => (
                                                                    <MenuItem
                                                                        value={item.uf}
                                                                        key={item.uf}
                                                                    >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="state"
                                                                type="text"
                                                                label={intl.get('LABEL_STATE')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.state.required}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.state && touched.state)}
                                                                helperText={(errors.state && touched.state) && errors.state}
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Cidade *
                                            haveCity ?
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="city"
                                                        type="text"
                                                        label={intl.get('LABEL_CITY')}
                                                        variant={config.layout.input_variant}
                                                        fullWidth
                                                        required={config.login_method.complete_data.form.city.required}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={(errors.city && touched.city)}
                                                        helperText={(errors.city && touched.city) && errors.city}
                                                    />
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Diretoria *
                                            haveBoard ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.board && auth.board.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.board.required}
                                                                name="board"
                                                                label={intl.get('LABEL_BOARD')}
                                                                defaultValue={values.board ? values.board : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.board && touched.board)}
                                                                helperText={(errors.board && touched.board) && errors.board}
                                                            >
                                                                {

                                                                    auth.board.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.board_id}
                                                                            key={item.board_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="board"
                                                                type="text"
                                                                label={intl.get('LABEL_BOARD')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.board.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Diretoria Executiva *
                                            haveExecutiveBoard ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.executiveBoard && auth.executiveBoard.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.executive_board.required}
                                                                name="executiveBoard"
                                                                label={intl.get('LABEL_EXECUTIVE_BOARD')}
                                                                defaultValue={values.executiveBoard ? values.executiveBoard : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.executiveBoard && touched.executiveBoard)}
                                                                helperText={(errors.executiveBoard && touched.executiveBoard) && errors.executiveBoard}
                                                            >
                                                                {
                                                                    auth.executiveBoard.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.executive_board_id}
                                                                            key={item.executive_board_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="executiveBoard"
                                                                type="text"
                                                                label={intl.get('LABEL_EXECUTIVE_BOARD')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.executive_board.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Sub-area *
                                            haveSubarea ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.subarea && auth.subarea.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.subarea.required}
                                                                name="subarea"
                                                                label={intl.get('LABEL_UNITY')}
                                                                defaultValue={values.subarea ? values.subarea : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.subarea && touched.subarea)}
                                                                helperText={(errors.subarea && touched.subarea) && errors.subarea}
                                                            >
                                                                {

                                                                    auth.subarea.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.sub_area_id}
                                                                            key={item.sub_area_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="subarea"
                                                                type="text"
                                                                label={intl.get('LABEL_UNITY')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.subarea.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Gerência *
                                            haveManaging ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.managing && auth.managing.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.managing.required}
                                                                name="managing"
                                                                label={intl.get('LABEL_MANAGING')}
                                                                defaultValue={values.managing ? values.managing : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.managing && touched.managing)}
                                                                helperText={(errors.managing && touched.managing) && errors.managing}
                                                            >
                                                                {
                                                                    auth.managing.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.management_id}
                                                                            key={item.management_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="managing"
                                                                type="text"
                                                                label={intl.get('LABEL_MANAGING')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.managing.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Centro de Custo *
                                            haveCostCenter ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.costCenter && auth.costCenter.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.cost_center.required}
                                                                name="costCenter"
                                                                label={intl.get('LABEL_COST_CENTER')}
                                                                defaultValue={values.costCenter ? values.costCenter : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.costCenter && touched.costCenter)}
                                                                helperText={(errors.costCenter && touched.costCenter) && errors.costCenter}
                                                            >
                                                                {
                                                                    auth.costCenter.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.cost_center_id}
                                                                            key={item.cost_center_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="costCenter"
                                                                type="text"
                                                                label={intl.get('LABEL_COST_CENTER')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.cost_center.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Empresa *
                                            haveEnterprise ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.externalEnterprise && auth.externalEnterprise.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.enterprise.required}
                                                                name="enterprise"
                                                                label={intl.get('LABEL_ENTERPRISE')}
                                                                defaultValue={values.enterprise ? values.enterprise : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.enterprise && touched.enterprise)}
                                                                helperText={(errors.enterprise && touched.enterprise) && errors.enterprise}
                                                            >
                                                                {
                                                                    auth.externalEnterprise.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.enterprise_id}
                                                                            key={item.enterprise_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="enterprise"
                                                                type="text"
                                                                label={intl.get('LABEL_ENTERPRISE')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.enterprise.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Marca *
                                            haveBrand ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.brand && auth.brand.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.brand.required}
                                                                name="brand"
                                                                label={intl.get('LABEL_BRAND')}
                                                                defaultValue={values.brand ? values.brand : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.brand && touched.brand)}
                                                                helperText={(errors.brand && touched.brand) && errors.brand}
                                                            >
                                                                {
                                                                    auth.brand.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.brand_id}
                                                                            key={item.brand_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="brand"
                                                                type="text"
                                                                label={intl.get('LABEL_BRAND')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.brand.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Cargo *
                                            haveRole ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.role && auth.role.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.role.required}
                                                                name="role"
                                                                label={intl.get('LABEL_ROLE')}
                                                                defaultValue={values.role ? values.role : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.role && touched.role)}
                                                                helperText={(errors.role && touched.role) && errors.role}
                                                            >
                                                                {
                                                                    auth.role.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.role_id}
                                                                            key={item.role_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="role"
                                                                type="text"
                                                                label={intl.get('LABEL_ROLE')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.role.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Cargo Família *
                                            haveRoleFamily ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.roleFamily && auth.roleFamily.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.role_family.required}
                                                                name="roleFamily"
                                                                label={intl.get('LABEL_ROLE_FAMILY')}
                                                                defaultValue={values.roleFamily ? values.roleFamily : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.roleFamily && touched.roleFamily)}
                                                                helperText={(errors.roleFamily && touched.roleFamily) && errors.roleFamily}
                                                            >
                                                                {
                                                                    auth.roleFamily.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.family_id}
                                                                            key={item.family_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="roleFamily"
                                                                type="text"
                                                                label={intl.get('LABEL_ROLE_FAMILY')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.role_family.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Cargo Grupo *
                                            haveRoleGroup ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.roleGroup && auth.roleGroup.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.role_group.required}
                                                                name="roleGroup"
                                                                label={intl.get('LABEL_ROLE_GROUP')}
                                                                defaultValue={values.roleGroup ? values.roleGroup : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.roleGroup && touched.roleGroup)}
                                                                helperText={(errors.roleGroup && touched.roleGroup) && errors.roleGroup}
                                                            >
                                                                {
                                                                    auth.roleGroup.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.role_group_id}
                                                                            key={item.role_group_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="roleGroup"
                                                                type="text"
                                                                label={intl.get('LABEL_ROLE_GROUP')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.role_group.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Função *
                                            havePosition ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.position && auth.position.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.position.required}
                                                                name="position"
                                                                label={intl.get('LABEL_POSITION')}
                                                                defaultValue={values.position ? values.position : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.position && touched.position)}
                                                                helperText={(errors.position && touched.position) && errors.position}
                                                            >

                                                                {
                                                                    auth.position.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.position_id}
                                                                            key={item.position_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="position"
                                                                type="text"
                                                                label={intl.get('LABEL_POSITION')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.position.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {
                                            // * Regional *
                                            haveRegional ?
                                                <Grid item xs={12}>
                                                    {
                                                        auth.regional && auth.regional.load === "finished" ?
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.regional.required}
                                                                name="regional"
                                                                label={intl.get('LABEL_REGIONAL')}
                                                                defaultValue={values.regional ? values.regional : ""}
                                                                variant={config.layout.input_variant}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={(errors.regional && touched.regional)}
                                                                helperText={(errors.regional && touched.regional) && errors.regional}
                                                            >
                                                                {
                                                                    auth.regional.data.map((item) => (
                                                                        <MenuItem
                                                                            value={item.regional_id}
                                                                            key={item.regional_id}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                name="regional"
                                                                type="text"
                                                                label={intl.get('LABEL_REGIONAL')}
                                                                variant={config.layout.input_variant}
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.regional.required}
                                                                value={intl.get('LABEL_LOADING')}
                                                                disabled
                                                            />
                                                    }
                                                </Grid>
                                                : null
                                        }

                                        {/* Terms */}
                                        <Grid item xs={12} className="mb-3">
                                            <FormControl component="fieldset">
                                                <FormGroup row className="align-items-center flex-nowrap">
                                                    <FormControlLabel
                                                        className="terms-confirmation mr-0"
                                                        control={<Checkbox color="primary" checked={values.termsConfirmation} onChange={handleChange} name="termsConfirmation" />}
                                                        inputprops={{ 'aria-label': 'terms-confirmation' }}
                                                    />
                                                    <Typography variant="body2" component="div">
                                                        {`${intl.get('INTRO_COMPLETE_DATA_TERMS_TEXT_1')} `}
                                                        <Link onClick={() => showTermsAndPrivacy("terms")} className="cursor-pointer">{intl.get('INTRO_COMPLETE_DATA_TERMS_TEXT_2')}</Link>
                                                        {` ${intl.get('INTRO_COMPLETE_DATA_TERMS_TEXT_3')} `}
                                                        <Link onClick={() => showTermsAndPrivacy("privacy")} className="cursor-pointer">{intl.get('INTRO_COMPLETE_DATA_TERMS_TEXT_4')}</Link>
                                                    </Typography>
                                                </FormGroup>
                                                <FormHelperText error>
                                                    {(errors.termsConfirmation && touched.termsConfirmation) && errors.termsConfirmation}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        {/* Button */}
                                        <Grid item xs={12} sm={8}>
                                            {
                                                !loading
                                                    ?
                                                    <Button
                                                        id="btn_intro_complete_data_send"
                                                        variant="contained"
                                                        className="btn-block"
                                                        type="submit"
                                                        color="primary"
                                                        value={"confirmated"}
                                                        onSubmit={() => this.onSubmit()}
                                                    >
                                                        {intl.get('BTN_SEND')}
                                                    </Button>
                                                    :
                                                    <Grid container alignItems="center" justify="center">
                                                        <LoadingBounce />
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Grid>
                <DialogTermsPrivacy
                    open={open}
                    close={() => setOpen(false)}
                    type={typeDialog}
                />
                <SnackBar
                    open={snackOpen}
                    message={intl.get('ERROR_COMPLETE_DATA')}
                    status="error"
                    time={2}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/`
            }} />
    )
}
