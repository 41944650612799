import { achievementsService } from "../../services/achievements.service";

// CONST
export const Types = {
    BADGE_LIST: 'achievements/BADGE_LIST',
    BADGE_REDEEM: 'achievements/BADGE_REDEEM',
    BADGE_UPDATE: 'achievements/BADGE_UPDATE',
    MISSION_LIST: 'achievements/MISSION_LIST',
    MISSION_REDEEM: 'achievements/MISSION_REDEEM',
    MISSION_UPDATE: 'achievements/MISSION_UPDATE',
    RESET_BADGE_REDEEM: 'achievements/RESET_BADGE_REDEEM',
    RESET_MISSION_REDEEM: 'achievements/RESET_MISSION_REDEEM',
    RESET: 'achievements/RESET',
};

// INITIAL STATE
const initialState = {
    badgeList: {},
    badgeRedeem: {},
    missionList: {},
    missionRedeem: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.BADGE_LIST:
            return {
                ...state,
                badgeList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BADGE_REDEEM:
            return {
                ...state,
                badgeRedeem: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BADGE_UPDATE:
            return {
                ...state,
                badgeList: {
                    ...state.badgeList, // preserva todas as propriedades atuais de list
                    data: {
                        ...state.badgeList.data, // preserva as propriedades existentes em data
                        total_user: action.payload.total_user, // substitui total_user
                    },
                },
            }
        case Types.MISSION_LIST:
            return {
                ...state,
                missionList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.MISSION_REDEEM:
            return {
                ...state,
                missionRedeem: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.MISSION_UPDATE:
            return {
                ...state,
                missionList: {
                    ...state.missionList, // preserva todas as propriedades atuais de list
                    data: {
                        ...state.missionList.data, // preserva as propriedades existentes em data
                        total_user: action.payload.total_user, // substitui total_user
                    },
                },
            }
        case Types.RESET_BADGE_REDEEM:
            return {
                ...state,
                badgeRedeem: {},
            }
        case Types.RESET_MISSION_REDEEM:
            return {
                ...state,
                missionRedeem: {},
            }
        case Types.RESET:
            return {
                ...state,
                badgeList: {},
                badgeRedeem: {},
                missionList: {},
                missionRedeem: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getBadgeList(params) {
    return dispatch => {
        return achievementsService.getBadgeList(params).then(data => {
            dispatch({
                type: Types.BADGE_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BADGE_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setBadgeRedeem(params) {
    return dispatch => {
        return achievementsService.setBadgeRedeem(params).then(data => {
            dispatch({
                type: Types.BADGE_REDEEM,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BADGE_REDEEM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setBadgeUpdate(params) {
    return dispatch => {
        dispatch({
            type: Types.BADGE_UPDATE,
            payload: {
                total_user: params.total_user
            }
        });
    }
}

function getMissionList(params) {
    return dispatch => {
        return achievementsService.getMissionList(params).then(data => {
            dispatch({
                type: Types.MISSION_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.MISSION_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setMissionRedeem(params) {
    return dispatch => {
        return achievementsService.setMissionRedeem(params).then(data => {
            dispatch({
                type: Types.MISSION_REDEEM,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.MISSION_REDEEM,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setMissionUpdate(params) {
    return dispatch => {
        dispatch({
            type: Types.MISSION_UPDATE,
            payload: {
                total_user: params.total_user
            }
        });
    }
}

function resetBadgeRedeem() {
    return dispatch => {
        dispatch({
            type: Types.RESET_BADGE_REDEEM
        });
    }
}

function resetMissionRedeem() {
    return dispatch => {
        dispatch({
            type: Types.RESET_MISSION_REDEEM
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getBadgeList,
    setBadgeRedeem,
    setBadgeUpdate,
    getMissionList,
    setMissionRedeem,
    setMissionUpdate,
    resetBadgeRedeem,
    resetMissionRedeem,
    reset,
}
