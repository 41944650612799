import * as React from "react";

const ImgRating1 = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFCA28"
                d="M22 12c0 5.5-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2s10 4.5 10 10Z"
            />
            <path
                fill="#B76C09"
                d="M10.5 18c-.6 0-1.25-.3-1.9-1.2l.8-.6c.9 1.25 1.45.85 2.4.1.6-.45 1.3-.95 2.1-.8.55.1 1.05.5 1.5 1.2l-.85.55c-.3-.45-.6-.7-.85-.75-.35-.05-.85.25-1.35.6-.45.4-1.1.9-1.85.9Z"
            />
            <path
                fill="#6D4C41"
                d="M18.55 9.75 13 12.1s1.35 2.15 3.55 1.1c1.55-.75 2-3.45 2-3.45Z"
            />
            <path
                fill="#FFF"
                d="M16.5 11.25c0 .412-.338.75-.75.75s-.75-.338-.75-.75.338-.75.75-.75.75.338.75.75Z"
            />
            <path
                fill="#FFCA28"
                d="M18.55 9.75 13 12.1s.1-2.65 1.6-3.15 3.95.8 3.95.8Z"
            />
            <path
                fill="#6D4C41"
                d="M5.45 9.75 11 12.1s-1.35 2.15-3.55 1.1c-1.55-.75-2-3.45-2-3.45Z"
            />
            <path
                fill="#FFF"
                d="M9 11.25c0 .412-.338.75-.75.75s-.75-.338-.75-.75.338-.75.75-.75.75.338.75.75Z"
            />
            <path
                fill="#FFCA28"
                d="M5.45 9.75 11 12.1s-.1-2.65-1.6-3.15-3.95.8-3.95.8Z"
            />
        </g>
    </svg>
)
export default ImgRating1
