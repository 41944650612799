import * as React from 'react';
import config from '../../../config';
import AssaiCharCoursePratice from '../projects/assai/chars/CharCoursePratice';

function CharCoursePratice(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                <defs>
                    <filter
                        x="-36%"
                        y="-128.8%"
                        width="172%"
                        height="404.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__e"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <filter
                        x="-36%"
                        y="-128.8%"
                        width="172%"
                        height="404.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__g"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <filter
                        x="-36%"
                        y="-128.8%"
                        width="172%"
                        height="404.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__i"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <filter
                        x="-36%"
                        y="-128.8%"
                        width="172%"
                        height="404.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__k"
                    >
                        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            in="shadowBlurOuter1"
                        />
                    </filter>
                    <rect
                        id="prefix__f"
                        x={0}
                        y={0}
                        width={18.054}
                        height={4.269}
                        rx={0.6}
                    />
                    <rect
                        id="prefix__h"
                        x={0}
                        y={0}
                        width={18.054}
                        height={4.269}
                        rx={0.6}
                    />
                    <rect
                        id="prefix__j"
                        x={0}
                        y={0}
                        width={18.054}
                        height={4.269}
                        rx={0.6}
                    />
                    <rect
                        id="prefix__l"
                        x={0}
                        y={0}
                        width={18.054}
                        height={4.269}
                        rx={0.6}
                    />
                    <circle id="prefix__a" cx={90} cy={90} r={90} />
                    <path
                        d="M22.302.64H1.912C1.147.64.637 1.153.637 1.921v37.142c0 .768.51 1.28 1.275 1.28h20.39c.765 0 1.274-.512 1.274-1.28V1.921c0-.768-.51-1.28-1.274-1.28z"
                        id="prefix__c"
                    />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <use fillOpacity={0.4} fill="#FFF" xlinkHref="#prefix__a" />
                    <g mask="url(#prefix__b)">
                        <path
                            d="M32.604 123.146l75.408.327c9.091 27.621 4.666 59.306 4.666 69.771l-10.442 10.37c-1.71 5.612-7.59 8.76-13.135 7.03-5.545-1.732-8.654-7.684-6.945-13.296l1.25-4.104-19.484-.066h-.946c3.275 6.315.74 15.138-4.999 16.003-5.74.865-14.701.724-15.556-5.084l-1.996-10.92-7.82-69.12"
                            fill="#46CA64"
                        />
                        <path
                            d="M89.014 152.12c4.94.878 10.874 1.743 15.994 2.487l22.678 1.298c11.826 1.475 28.985 1.726 30.35-7.584 4.394-30.01-13.605-20.786-13.589-20.919 2.187-19.03-17.376-18.536-10.085-1.558l-39.778 5.424"
                            fill="#F3C430"
                        />
                        <path
                            d="M32.604 123.146l24.522 3.059 22.118 37.989 13.434-38.907 15.334-1.814c8.538 27.621 4.113 53.192 5.772 68.756l-11.548 11.385c-1.71 5.612-7.59 8.76-13.135 7.03-5.545-1.732-8.654-7.684-6.945-13.296l1.25-4.104-19.484-.066h-.946c3.275 6.315.74 15.138-4.999 16.003-5.74.865-14.701.724-15.556-5.084l-1.996-10.92-7.82-70.031z"
                            fill="#46CA64"
                        />
                        <path
                            d="M116.665 84.05s-.305 29.325-.267 29.457c-.195 4.825-4.5 15.735-17.448 17.672-15.464 2.314-49.468 3.64-49.468 3.64s-14.57-1.768-15.414-17.588c-.043.269.16-29.987.16-29.987l82.437-3.194"
                            fillOpacity={0.15}
                            fill="#000"
                        />
                        <path
                            fill="#46CA64"
                            d="M106.269 125.395l19.64.018.932 31.278-17.601 2.537zM13.669 180c0-6.115 0-47.806 18.454-62.81 2.352-1.913 5.05 19.024 8.096 62.81h-26.55z"
                        />
                        <path
                            d="M123.178 109.65c-.118 4.835-3.893 17.975-19.56 18.136-17.314.18-58.783.607-58.783.607s-18.86-1.165-20.254-17.055c-.045.27-1.263-30.026-1.263-30.026h99.86c-.034 18.803-.034 28.25 0 28.338z"
                            fill="#FDC436"
                        />
                        <path
                            d="M55.707 34.163c-4.714.59-2.727.199-5.998.96-17.605 4.757-26.843 26.232-26.374 43.313l.153 5.61c-.183.12 99.738-2.29 99.704-2.46l-.164-5.955c-.47-17.08-10.87-38.003-28.711-41.763-5.139-1.084-11.144-.805-16 1.292-2.632 1.136-4.695 2.787-6.152 4.846-1.57-1.973-3.718-3.506-6.41-4.493-3.631-1.367-6.277-1.29-10.048-1.35m6.889 77.237c7.451.124 13.586-5.59 13.703-12.765.117-7.174-5.83-13.09-13.281-13.214-7.45-.124-13.587 5.59-13.703 12.764-.117 7.174 5.829 13.09 13.28 13.215"
                            fill="#212321"
                        />
                        <path
                            d="M65.921 107.861c3.635.06 6.626-2.728 6.683-6.225.057-3.498-2.842-6.384-6.476-6.446-3.634-.06-6.626 2.728-6.683 6.226-.057 3.498 2.843 6.384 6.476 6.445M28.994 78.277l41.357-1.164-.765-27.856c-.126-4.6-2.396-7.123-5.759-8.358-3.86-1.416-8.717-1.303-12.656-.24-3.252.88-6.158 2.478-8.712 4.595 1.48 2.388 4.053 3.233 6.846 2.518 1.64-.422 3.131-.956 4.84-1.143 5.578-.607 10.943 3.03 12.53 8.479.726 2.356-.436 4.28-1.83 4.648-1.53.403-3.132-.24-3.664-2.555-1.297-5.428-6.327-4.68-10.426-3.63-4.83 1.24-9.567-.269-12.507-3.999a37.093 37.093 0 00-3.528 5.314c1.368.894 2.58 2.122 3.565 3.673.926 1.46 1.595 3.102 2.553 4.515 1.823 2.708 4.767 3.345 7.677 2.074 1.553-.679 2.943-1.445 4.6-1.902 5.413-1.497 11.28 1.234 13.7 6.358 1.087 2.207.242 4.297-1.077 4.882-1.448.643-3.132.267-4.017-1.933-2.133-5.15-6.982-3.606-10.864-1.91-5.503 2.404-11.492.865-14.905-4.194-1.013-1.504-1.686-3.15-2.642-4.658a6.564 6.564 0 00-1.14-1.371c-2.255 5.891-3.33 12.238-3.176 17.857zm88.374-2.486l-41.357 1.163-.765-27.856c-.126-4.6 2.002-7.246 5.294-8.67 3.774-1.63 8.63-1.79 12.623-.95 3.294.695 6.284 2.127 8.95 4.098-1.348 2.468-3.869 3.456-6.697 2.899-1.661-.329-3.18-.778-4.894-.869-5.605-.292-10.762 3.642-12.048 9.17-.596 2.394.671 4.25 2.082 4.538 1.551.316 3.114-.415 3.518-2.757 1-5.491 6.062-5.028 10.211-4.21 4.892.966 9.539-.807 12.27-4.696a37.06 37.06 0 013.813 5.108c-1.315.969-2.46 2.263-3.358 3.867-.845 1.51-1.424 3.186-2.3 4.652-1.676 2.806-4.577 3.607-7.552 2.502-1.59-.59-3.018-1.278-4.698-1.641-5.486-1.19-11.196 1.866-13.33 7.118-.965 2.266-.005 4.304 1.343 4.815 1.481.56 3.14.09 3.906-2.157 1.848-5.262 6.772-3.992 10.741-2.517 5.625 2.09 11.523.217 14.65-5.025.932-1.559 1.512-3.24 2.385-4.799.313-.56.67-1.039 1.064-1.433 2.575 5.755 3.994 12.032 4.149 17.65z"
                            fill="#FFF"
                        />
                        <path
                            d="M98.578 111.049c7.452.124 13.587-5.591 13.704-12.765.117-7.174-5.83-13.09-13.28-13.214-7.452-.124-13.587 5.591-13.704 12.763-.117 7.175 5.829 13.092 13.28 13.216"
                            fill="#212321"
                        />
                        <path
                            d="M101.681 107.755c3.634.06 6.626-2.727 6.683-6.225.057-3.498-2.843-6.384-6.477-6.445-3.634-.06-6.626 2.727-6.683 6.226-.056 3.497 2.843 6.383 6.477 6.444"
                            fill="#FFF"
                        />
                        <path
                            fillOpacity={0.15}
                            fill="#000"
                            d="M42.984 138.309L38.331 180h4.653zm65.822-10.562l7.19 30.574h-2.212z"
                        />
                        <path
                            d="M127.376 141.694l21.718-34.757c1.493-2.39 4.66-3.129 7.038-1.643l17.292 10.805c2.378 1.486 3.101 4.656 1.608 7.046L153.314 157.9c-1.493 2.39-4.66 3.13-7.038 1.644l-17.292-10.806c-2.378-1.485-3.101-4.656-1.608-7.045z"
                            fill="#3D3D3D"
                        />
                        <path
                            fill="#000"
                            fillRule="nonzero"
                            opacity={0.2}
                            d="M137.103 147.772l6.484 4.051-1.357 2.173-6.485-4.052z"
                        />
                        <g transform="rotate(32 -110.163 319.961)">
                            <mask id="prefix__d" fill="#fff">
                                <use xlinkHref="#prefix__c" />
                            </mask>
                            <use fill="#FFF" xlinkHref="#prefix__c" />
                            <path
                                fill="#FDC436"
                                mask="url(#prefix__d)"
                                d="M0 0h24.426v15.565H0z"
                            />
                            <g mask="url(#prefix__d)" fill="#FFF">
                                <path d="M6.637 3.113h9.956V4.18H6.637zM5.31 8.538h13.806v1.067H5.312zm0 4.27h13.806v1.067H5.312zm-2.124-2.135H21.24v1.067H3.187z" />
                            </g>
                            <g mask="url(#prefix__d)">
                                <g transform="translate(3.186 18.144)">
                                    <use
                                        fill="#000"
                                        filter="url(#prefix__e)"
                                        xlinkHref="#prefix__f"
                                    />
                                    <use fill="#FFF" xlinkHref="#prefix__f" />
                                    <ellipse
                                        fill="#FDC436"
                                        cx={2.124}
                                        cy={2.135}
                                        rx={1.062}
                                        ry={1.067}
                                    />
                                </g>
                            </g>
                            <g mask="url(#prefix__d)">
                                <g transform="translate(3.186 23.48)">
                                    <use
                                        fill="#000"
                                        filter="url(#prefix__g)"
                                        xlinkHref="#prefix__h"
                                    />
                                    <use fill="#FFF" xlinkHref="#prefix__h" />
                                    <ellipse
                                        fill="#FDC436"
                                        cx={2.124}
                                        cy={2.135}
                                        rx={1.062}
                                        ry={1.067}
                                    />
                                </g>
                            </g>
                            <g mask="url(#prefix__d)">
                                <g transform="translate(3.186 28.817)">
                                    <use
                                        fill="#000"
                                        filter="url(#prefix__i)"
                                        xlinkHref="#prefix__j"
                                    />
                                    <use fill="#FFF" xlinkHref="#prefix__j" />
                                    <ellipse
                                        fill="#FDC436"
                                        cx={2.124}
                                        cy={2.135}
                                        rx={1.062}
                                        ry={1.067}
                                    />
                                </g>
                            </g>
                            <g mask="url(#prefix__d)">
                                <g transform="translate(3.186 34.153)">
                                    <use
                                        fill="#000"
                                        filter="url(#prefix__k)"
                                        xlinkHref="#prefix__l"
                                    />
                                    <use fill="#FFF" xlinkHref="#prefix__l" />
                                    <ellipse
                                        fill="#FDC436"
                                        cx={2.124}
                                        cy={2.135}
                                        rx={1.062}
                                        ry={1.067}
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCoursePratice {...props} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (gender === "male") {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <defs>
                        <circle id="prefix__a" cx={90} cy={90} r={90} />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <mask id="prefix__b" fill="#fff">
                            <use xlinkHref="#prefix__a" />
                        </mask>
                        <use fillOpacity={0.4} fill="#FFF" xlinkHref="#prefix__a" />
                        <g mask="url(#prefix__b)">
                            <path
                                d="M108.017 141.338l1.836 14.088s12.531 21.601 24.195 24.424c10.55 2.552 15.52-6.93 15.52-15.803 0-5.362-1.976-12.134-1.976-12.134s-11.005 1.976-15.802 5.361c0 0 3.104 5.08 3.48 8.373.406 3.556-3.512.863-4.89-.189-6.304-4.807-20.482-20.733-22.363-24.12"
                                className="svg-char-shirt-shade-1"
                            />
                            <path
                                d="M108.384 141.036s5.25 19.857 6.879 40.625c0 0-6.878 3.037-25.654 3.037-10.238 0-21.947-1.24-27.735-3.037 0 0 .722-12.7 5.224-38.91 0 0 6.113.874 19.126.874 13.012 0 22.16-2.59 22.16-2.59"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M90.998 155.294c1.18-2.431 4.183-2.392 5.263.093 1.725 3.971 6.3 16.898 6.109 17.864-.366 1.827-6.58 7.676-8.773 7.31-2.524-.42-8.875-6.473-8.773-7.676.254-3.013 4.02-13.16 6.174-17.591zm2.599-11.47a3.84 3.84 0 11-.002 7.678 3.84 3.84 0 01.002-7.678z"
                                className="svg-char-necktie"
                            />
                            <path
                                d="M67.537 142.65l-1.128 9.234s-17.333 2.305-17.876 9.422c-.43 5.64 15.802 7.901 15.802 7.901l-2.442 12.227s-24.647-4.326-25.023-19c-.377-14.672 21.206-19.783 30.667-19.783"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M72.15 166.837c6.807 4.234 3.331 10.905-1.042 15.54-4.257 4.508-9.098 6.6-14.673 1.88-4.892-4.138-2.634-11.475.564-15.237 3.216-3.784 11.099-4.704 15.15-2.183"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M108.017 80.569c.053 13.546 8.33 19.673 19.13 19.632 10.8-.042 17.577-20.054 17.946-28.965.601-14.501-7.345-24.754-18.146-24.712-10.8.041-18.982 20.497-18.93 34.045"
                                className={`svg-char-${color}-hair-shade-2`}
                            />
                            <path
                                d="M32.162 123.547c4.96 7.886 13.93 9.405 19.041 7.417 5.091-1.986 2.177-7.49-.562-14.608-2.736-7.118-9.095-11.28-14.198-9.29-5.101 1.987-8.376 9.971-4.281 16.48"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M37.384 122.06c1.666 3.992 7.82 5.909 11.021 4.994 3.206-.914 1.785-4.152.577-8.304-1.202-4.154-4.779-6.776-7.983-5.86-3.202.916-5.56 4.51-3.615 9.17"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M144.323 105.198c-1.729 6.525-7.355 11.197-11.539 10.964-4.168-.24-3.594-5.406-3.586-11.816.006-6.41 3.4-11.416 7.579-11.178 4.176.237 9.176 5.903 7.546 12.03"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M140.21 104.057c.274 3.638-3.97 8.124-6.5 8.241-2.533.118-2.404-2.844-2.677-6.48-.278-3.635 1.556-6.676 4.088-6.792 2.53-.117 4.816 1.398 5.089 5.03"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M108.12 142.452s39.425-14.578 26.056-68.302c-13.37-53.726-49.459-50.578-67.491-45.62-18.032 4.958-34.824 18.936-34.46 35.482 1.257 57.29 23.235 73.879 35.404 79.072 6.663 2.844 24.558 6.1 40.492-.632"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M60.42 102.793c.834-.026 1.536-.69 1.614-1.567.33-3.703 2.832-6.077 6.533-6.195 3.3-.106 6.127 1.971 7.563 5.558.355.884 1.337 1.306 2.193.943.857-.364 1.264-1.374.911-2.258-1.993-4.975-6.022-7.854-10.778-7.702-5.36.173-9.285 3.922-9.766 9.331-.084.951.596 1.795 1.52 1.884.07.006.141.009.21.006m46.804-1.895c.835.011 1.565-.62 1.683-1.494.494-3.684 3.1-5.943 6.802-5.896 3.302.041 6.034 2.243 7.308 5.89.315.899 1.277 1.364 2.148 1.04.873-.325 1.324-1.316 1.012-2.215-1.77-5.06-5.665-8.115-10.424-8.175-5.362-.068-9.45 3.502-10.173 8.885-.126.946.516 1.82 1.434 1.95.07.01.141.014.21.015"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M90.93 106.907a5.265 5.265 0 1010.53-.04 5.265 5.265 0 00-10.53.04"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M104.656 123.15s-26.723-5.913-44.03-8.103c0 0-1.619 16.13 16.624 20.242 22.96 5.177 27.406-12.139 27.406-12.139"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M77.25 135.29c-7.194-1.623-11.292-5.114-13.626-8.747 3.747-1.475 8.918-2.04 14.492-1.326 8.374 1.074 15.139 4.71 17.255 8.832-4.116 2.068-9.938 3.087-18.12 1.24"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M38.428 106.513S56.78 88.97 46.208 65.19c0 0 5.337-3.273 28.909 1.476 23.203 4.674 58.358 8.253 74.762-8.701 12.337-12.752-.26-26.202-12.456-18.056 0 0 6.98-1.615 5.348-13.36-1.81-13.019-19.754-16.122-29.401-6.08 0 0 5.904-4.689 1.23-10.168-5.264-6.173-16.045-1.685-19.026 7.696 0 0-40.51-3.814-61.058 21.038-15.313 18.52-13.812 42.295 3.912 67.477"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M84.32 64.89c16.599 2.7 40.03 1.902 54.62-2.918 11.812-3.902 15.07-12.971 15.882-16.286.539 3.603-.783 7.98-4.943 12.28-16.403 16.954-51.56 13.376-74.762 8.701-23.573-4.749-28.908-1.477-28.908-1.477 8.868-6.222 29.53-1.697 38.11-.3M38.033 86.184c8.893.283 11.411-5.761 11.411-5.761s-.052 1.476-.21 3.83c-1.657 13.512-10.806 22.26-10.806 22.26-7-9.944-11.457-19.667-13.36-28.935 2.392 3.863 6.642 8.404 12.965 8.606"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M124.148 18.85c-.277 1.385 1.716 2.952 4.452 3.498 2.736.55 5.179-.132 5.457-1.517.275-1.385-1.716-2.952-4.453-3.5-2.737-.546-5.178.133-5.456 1.518"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                            <path
                                d="M133.83 155.692h-11.903a5.327 5.327 0 01-5.345-5.539l.997-28.544c.107-3.046 2.686-5.539 5.732-5.539h11.903a5.327 5.327 0 015.345 5.539l-.996 28.544c-.107 3.046-2.686 5.539-5.732 5.539"
                                className="svg-smartphone"
                            />
                            <path
                                d="M126.726 150.896c-.124 4.921 4.032 11.78 10.266 11.759 5.239-.019 11.165-2.276 11.763-9.89.49-6.233-3.282-11.841-9.515-11.953-6.962-.126-12.375 4.626-12.514 10.084"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M148.633 149.71s-.249-6.677-3.293-10.505c-2.301-2.894-6.34-4.716-9.812-3.204-3.42 1.488-2.205 5.756.613 5.355 2.819-.401 5.283 1.646 5.3 3.528.016 1.88 4.198 6.546 7.192 4.826"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M136.7 135.661s.52-.096-.701.383c-.984.384-1.603 1.292-1.77 1.896-.274.99.063 1.833.726 2.299.641.45 1.933.452 2.827.586.895.132 2.214.31 3.165 1.524.951 1.212 1.061 2.905.59 2.768 0 0-.54-2.582-2.24-3.225-1.7-.643-2.966-.302-3.767-.483-.801-.18-1.981-.664-2.136-2.214-.155-1.551.606-3.11 3.307-3.534"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                        </g>
                    </g>
                </svg>
            )
        } else {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <defs>
                        <circle id="prefix__a" cx={90} cy={90} r={90} />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <mask id="prefix__b" fill="#fff">
                            <use xlinkHref="#prefix__a" />
                        </mask>
                        <use fillOpacity={0.4} fill="#FFF" xlinkHref="#prefix__a" />
                        <g mask="url(#prefix__b)">
                            <path
                                d="M111.552 140.926l-.188 12.859s10.488 14.988 22.72 16.735c10.774 1.538 15.558-6.965 15.558-15.885 0-5.389-1.98-12.196-1.98-12.196s-10.823 4.065-15.631 7.468c0 0 3.701 3.402 3.084 7.113-.635 3.823-7.094.31-8.637-.618-5.446-3.276-13.04-12.072-14.926-15.476m-33.524 1.498l-.286 8.72s-17.422 4.049-17.818 10.015c-.377 5.673 15.841 7.942 15.841 7.942l-2.448 12.29s-24.708-4.349-25.086-19.098c-.377-14.748 20.368-18.53 29.797-19.87"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M112.94 141.15s.867 17.947 6.12 38.885c0 0-5.596 3.136-21.008 3.391-8.403.14-18.035-.942-22.814-2.665 0 0 2.116-5.903 3.249-12.108 1.157-6.347-1.09-14.949.396-26.938 0 0 5.033.793 15.714.616 10.68-.177 18.342-1.18 18.342-1.18"
                                className="svg-char-shirt"
                            />
                            <path
                                d="M73.755 181.104s2.864-7.19 4.73-12.452c.787-2.219-2.234-13.882-1.052-26.429 0 0 5.27.229 14.504.224 1.761 7.115 5.175 12.91 7.005 13.185 1.855.279 3.656-2.69 5.332-13.39 4.405-.315 7.68-1.983 7.68-1.983s4.122 6.737 5.103 10.123c.777 2.682-1.08 9.297-.752 10.53 1.12 4.192 1.053 7.715 2.341 11.097.998 2.62 3.582 4.739 4.026 7.543 0 0-8.006 4.582-24.826 4.861-9.17.152-19.1.025-24.091-3.309"
                                className="svg-char-suit"
                            />
                            <path
                                d="M28 33.753c0 14.519 12.084 26.289 26.99 26.289 14.906 0 26.99-11.77 26.99-26.29 0-14.518-12.084-26.288-26.99-26.288C40.084 7.464 28 19.234 28 33.753"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M37.302 56.806c6.651 11.552 23.644 14.2 37.954 5.916 14.31-8.284 20.52-24.363 13.868-35.914-6.652-11.551-23.644-14.2-37.954-5.916-14.31 8.284-20.519 24.364-13.868 35.914"
                                className="svg-char-ribbon"
                            />
                            <path
                                d="M28 33.753c0-5.313 1.623-10.253 4.405-14.387-.397 3.183-.723 11.142 4.849 16.242 0 0-.926 3.712 3.701 5.567l-5.19 11.023C30.964 47.452 28 40.94 28 33.753"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M143.055 103.778s10.179-17.165 6.94-43.608c-3.239-26.443-25.455-43.875-54.597-45-16.355-.63-31.76 4.487-43.086 13.496-13.665 10.871-20.653 27.278-17.68 46.35 4.318 27.706 15.885 42.216 22.363 46.855 13.354 9.564 63.388 23.66 86.06-18.093"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M42.634 123.087c4.788 7.697 13.494 9.21 18.466 7.293 4.95-1.915 2.141-7.285-.492-14.226-2.63-6.942-8.79-11.018-13.754-9.101-4.962 1.917-8.172 9.68-4.22 16.034"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M47.711 121.658c1.604 3.894 7.572 5.783 10.686 4.905 3.116-.878 1.748-4.037.591-8.085-1.152-4.05-4.616-6.615-7.73-5.736-3.115.88-5.419 4.372-3.547 8.916"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M151.518 106.142c-1.704 6.348-7.185 10.876-11.248 10.633-4.046-.249-3.47-5.278-3.438-11.519.03-6.242 3.344-11.104 7.402-10.856 4.055.246 8.89 5.782 7.284 11.742"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M147.527 105.015c.253 3.544-3.885 7.896-6.343 8-2.46.107-2.324-2.777-2.576-6.32-.256-3.54 1.536-6.494 3.996-6.598 2.458-.104 4.672 1.38 4.923 4.918"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M116.219 142.283s38.343-14.047 25.56-66.413c-12.783-52.368-47.845-49.437-65.377-44.677-17.53 4.761-33.891 18.309-33.6 34.423 1.007 55.791 22.29 72.028 34.089 77.131 6.46 2.795 23.828 6.031 39.328-.464"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M133.895 151.443h-11.617a5.186 5.186 0 01-5.216-5.38l.973-27.729c.104-2.959 2.62-5.38 5.593-5.38h11.617a5.186 5.186 0 015.217 5.38l-.973 27.73c-.104 2.958-2.621 5.38-5.594 5.38"
                                className="svg-smartphone"
                            />
                            <path
                                d="M126.745 141.416c-.125 4.948 4.042 11.842 10.29 11.82 5.253-.019 11.193-2.288 11.793-9.94.491-6.266-3.29-11.903-9.539-12.015-6.979-.127-12.405 4.65-12.544 10.135"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M148.706 140.225s-.25-6.712-3.302-10.56c-2.306-2.908-6.356-4.74-9.836-3.22-3.428 1.496-2.21 5.786.615 5.382 2.826-.403 5.295 1.655 5.312 3.547.017 1.89 4.209 6.58 7.21 4.85"
                                className={`svg-char-${color}-skin`}
                            />
                            <path
                                d="M136.744 126.103s.52-.096-.704.385c-.987.386-1.607 1.299-1.774 1.906-.274.995.063 1.842.728 2.31.642.453 1.937.456 2.834.59.897.133 2.219.311 3.173 1.532.953 1.218 1.064 2.92.59 2.782 0 0-.541-2.595-2.245-3.241-1.703-.646-2.972-.304-3.776-.486-.803-.181-1.986-.668-2.141-2.226-.155-1.559.607-3.125 3.315-3.552"
                                className={`svg-char-${color}-skin-shade-2`}
                            />
                            <path
                                d="M70.097 101.799a1.7 1.7 0 001.836-1.297c.847-3.557 3.686-5.511 7.405-5.1 3.318.37 5.857 2.796 6.793 6.493a1.698 1.698 0 103.29-.838c-1.298-5.128-4.927-8.507-9.71-9.036-5.389-.598-9.842 2.493-11.08 7.69a1.703 1.703 0 001.466 2.088m47.694-1.336a1.699 1.699 0 001.862-1.26c.92-3.538 3.798-5.433 7.508-4.944 3.31.436 5.799 2.914 6.66 6.63a1.698 1.698 0 103.306-.77c-1.193-5.155-4.753-8.606-9.523-9.235-5.377-.708-9.892 2.29-11.236 7.462a1.703 1.703 0 001.423 2.117"
                                className={`svg-char-${color}-eyes`}
                            />
                            <path
                                d="M99.657 107.606a5.12 5.12 0 005.113 5.126 5.12 5.12 0 005.113-5.126 5.12 5.12 0 00-5.113-5.127 5.12 5.12 0 00-5.113 5.127"
                                className={`svg-char-${color}-skin-shade-1`}
                            />
                            <path
                                d="M40.801 57.85c3.702-30.618 43.68-39.606 69.403-30.618 17.115 5.98 23.765 15.457 26.305 22.633 13.322 7.714 9.322 28.733 9.322 28.733-17.967-7.852-28.02-20.533-31.961-26.453l-.019.03c-5.897 8.14-23.124 27.394-55.16 29.24-.643 19.686-10.198 25.25-10.198 25.25S37.1 88.47 40.801 57.85z"
                                className={`svg-char-${color}-hair`}
                            />
                            <path
                                d="M45.852 108.444c-4.01-6.495-8.444-17.204-10.761-30.692.098-.499.158-.777.158-.777s2.12 14.381 22.479 12.99c0 0-2.044 13.491-11.876 18.478"
                                className={`svg-char-${color}-hair-shade-1`}
                            />
                            <path
                                d="M113.528 125.003s-25.975-5.658-42.792-7.726c0 0-1.512 15.713 16.222 19.65 22.317 4.954 26.57-11.924 26.57-11.924"
                                className="svg-char-mouth"
                            />
                            <path
                                d="M86.958 136.927c-6.994-1.553-10.987-4.938-13.267-8.465 3.633-1.453 8.653-2.02 14.069-1.347 8.138 1.014 14.721 4.529 16.792 8.536-3.99 2.029-9.64 3.043-17.594 1.276"
                                className="svg-char-mouth-tongue"
                            />
                            <path
                                d="M113.775 50.401a.693.693 0 111.186.723c-.217.359-5.458 8.882-17.435 17.145-10.132 6.99-27.022 14.929-51.47 14.929-2.167 0-4.396-.063-6.683-.194a.695.695 0 01.08-1.39c27.654 1.586 46.381-6.983 57.226-14.45 11.755-8.093 17.044-16.677 17.096-16.763zm7.373-1.804a.693.693 0 01.933.304c.083.162 8.445 16.352 25.974 27.564a.697.697 0 01-.374 1.282.69.69 0 01-.373-.109c-17.843-11.413-26.38-27.94-26.464-28.106a.697.697 0 01.304-.935zm4.29-9.663a.694.694 0 01.875.446c.056.174 5.797 17.492 21.341 31.308a.698.698 0 01-.46 1.217.694.694 0 01-.461-.175c-15.838-14.078-21.684-31.743-21.74-31.92a.696.696 0 01.445-.876zm-6.544-.175a.694.694 0 01.969-.159c.31.225.381.659.158.971-.224.313-5.636 7.748-18.596 15.046-11.238 6.33-30.385 13.624-58.932 13.624-1.718 0-3.474-.027-5.26-.081a.696.696 0 01.042-1.392c31.095.956 51.635-6.717 63.388-13.318 12.725-7.148 18.178-14.616 18.23-14.691zm-71.672-2.315c18.255-8.327 36.092-9.219 47.842-8.499 12.734.78 21.288 3.573 21.372 3.6a.696.696 0 01-.435 1.322c-.333-.11-33.762-10.867-68.204 4.844a.694.694 0 01-.919-.345.697.697 0 01.344-.922zm73.814-10.025c.826-1.255 3.498-.947 5.972.689 2.473 1.636 3.806 3.98 2.981 5.236-.827 1.254-3.502.946-5.973-.691-2.473-1.635-3.807-3.979-2.98-5.234z"
                                fillOpacity={0.1}
                                fill="#FFF"
                            />
                            <path
                                d="M82.365 166.718c6.825 4.256 3.34 10.961-1.044 15.62-4.267 4.532-9.12 6.635-14.71 1.891-4.903-4.16-2.64-11.535.566-15.316 3.224-3.804 11.126-4.729 15.188-2.195"
                                className={`svg-char-${color}-skin`}
                            />
                        </g>
                    </g>
                </svg>
            )
        }
    }
}

export default CharCoursePratice
