import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { numberToLetter } from '../../utils/utils';
import {
    Container, Grid, Typography, Button
} from '@material-ui/core';
import GameHeader from './partials/GameHeader';
import SnackBar from '../snackBar';

const QuizAnswer = ({ children, data, send, onlyView }) => {
    const help = useSelector(state => state.game.help);
    const [answerAlert, setAnswerAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackStatus, setSnackStatus] = useState("info");
    const [pressAnswer, setPressAnswer] = useState({});

    useEffect(() => {
        if (answerAlert) {
            let timer = setTimeout(() => {
                setSnackOpen(true);
                setSnackMessage(intl.get("LABEL_ANSWER_CONFIRMATION"));
                setSnackStatus("info");
            }, 3000);

            return () => {
                setAnswerAlert(false);
                clearTimeout(timer);
            }
        }
    }, [answerAlert]);

    useEffect(() => {
        if (data.question.user_answer) {
            setPressAnswer({ id: data.question.user_answer, press: false })
        }

        if (!data.question.user_answer) {
            setPressAnswer({})
        }
    }, [data]);

    function pressButton(id) {
        if (config.learn.answer_double_click) {
            if (pressAnswer.id === id && pressAnswer.press === true) {
                return send(id, data.question.id_question);
            } else {
                setPressAnswer({ id: id, press: true });
                setAnswerAlert(true);
            }
        } else {
            return send(id, data.question.id_question);
        }
    }

    return (
        <div className={`w-100${config.layout.points.neuros && data.help && !onlyView ? " __game-bar" : ""}`}>
            <GameHeader data={data}>
                {children}
            </GameHeader>
            <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10}>
                        <Grid container spacing={2}>
                            {
                                data.question.answers.map((item, index) => {
                                    let answerHistory = help.answer_history && help.answer_history.find(help => help.answer === item.id);
                                    let eliminate = help.answers_to_eliminate && help.answers_to_eliminate.includes(item.id);

                                    return (
                                        <Grid item xs={12} key={index}>
                                            <Button
                                                disableRipple
                                                variant="outlined"
                                                className={`btn-card __answer${pressAnswer.id === item.id && snackOpen ? " shake active" : pressAnswer.id === item.id ? " active" : ""}
                                            ${onlyView ? item.answer_right === "Y" || item.id === data.question.id_correct_answer ? "answer-view-success" : "answer-view-danger" : ""}
                                            `}
                                                onClick={() => pressButton(item.id)}
                                                disabled={eliminate || onlyView}
                                            >
                                                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                    <div className="answer-type-text">
                                                        <div className="answer-option">
                                                            <Typography variant="h6" component="span">
                                                                {numberToLetter(onlyView ? parseInt(item.id) : (index + 1))}
                                                            </Typography>
                                                        </div>
                                                        <Typography variant="body2" component="p">
                                                            {ReactHtmlParser(item.answer)}
                                                        </Typography>
                                                    </div>
                                                    {
                                                        answerHistory ?
                                                            <Typography variant="h5" component="span" className="answer-help">
                                                                {answerHistory.answer_percent}
                                                            </Typography>
                                                            : null
                                                    }
                                                </div>
                                            </Button>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <SnackBar
                        open={snackOpen}
                        message={snackMessage}
                        status={snackStatus}
                        time={3}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        closeSnack={() => { setSnackOpen(false); setAnswerAlert(false) }}
                    />
                </Grid>
            </Container>
        </div>
    )
}

export default QuizAnswer;