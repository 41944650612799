import * as React from "react"

const LogoBadgeClean = (props) => (
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <g fill="none" fillRule="nonzero">
            <path
                fill="#2B97DD"
                d="M30.036 11.422H13.499A5.507 5.507 0 0 0 8 16.92v16.537a5.507 5.507 0 0 0 5.499 5.498h16.537a5.507 5.507 0 0 0 5.499-5.498V16.92a5.507 5.507 0 0 0-5.5-5.5"
            />
            <path
                fill="#46CA64"
                d="M70.39 21.297 58.697 9.607a5.507 5.507 0 0 0-7.777 0l-1.617 1.618-10.075 10.073a5.46 5.46 0 0 0-1.606 3.877 5.46 5.46 0 0 0 1.606 3.9l1.62 1.617 8.454 8.463 1.62 1.618a5.499 5.499 0 0 0 7.776-.003L68.77 30.692l1.62-1.615a5.502 5.502 0 0 0 0-7.777"
            />
            <path
                fill="#E65748"
                d="M30.036 44.463H13.499A5.507 5.507 0 0 0 8 49.962v16.537a5.507 5.507 0 0 0 5.499 5.499h16.537a5.507 5.507 0 0 0 5.499-5.499V49.962a5.507 5.507 0 0 0-5.5-5.499"
            />
            <path
                fill="#FDC436"
                d="M63.077 44.463H46.54a5.507 5.507 0 0 0-5.499 5.499v16.537a5.507 5.507 0 0 0 5.5 5.499h16.536a5.507 5.507 0 0 0 5.499-5.499V49.962a5.507 5.507 0 0 0-5.499-5.499"
            />
        </g>
    </svg>
)

export default LogoBadgeClean