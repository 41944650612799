import React from 'react';
import ImgGifts from '../../../../../dist/img/intros/prizes/ImgGifts';
import ImgBrightness from '../../../../../dist/img/intros/prizes/ImgBrightness';
import ImgStars1 from '../../../../../dist/img/intros/prizes/ImgStars1';
import ImgStars2 from '../../../../../dist/img/intros/prizes/ImgStars2';
import ImgStars3 from '../../../../../dist/img/intros/prizes/ImgStars3';

export default function PrizesIntro() {
    return (
        <div id="intro-slider-prizes">
            <div className="intro-content">
                <div className="brightness">
                    <ImgBrightness />
                </div>
                <div className="gifts">
                    <ImgGifts />
                </div>
                <div className="stars1">
                    <ImgStars1 />
                </div>
                <div className="stars2">
                    <ImgStars2 />
                </div>
                <div className="stars3">
                    <ImgStars3 />
                </div>
            </div>
        </div>
    )
}
