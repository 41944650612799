import * as React from "react"

function ImgHands(props) {
    return (
        <svg width={292} height={124} viewBox="0 0 292 124" {...props}>
            <g transform="translate(6 10)" fill="none" fillRule="evenodd">
                <path
                    d="M100.354 32.068c6.242 7.955 14.241 13.394 14.241 13.394s-3.111 5.298-11.943 1.864c-2.81-1.093-5.324-2.583-7.448-4.106 6.878 6.024 14.226 9.633 14.226 9.633s-2.313 5.676-11.553 3.498c-4.7-1.108-8.8-3.326-11.697-5.237a38.89 38.89 0 012.523-4.18c2.766-4.024 7.034-9.443 11.651-14.866m109.121 5.102c6.171.854 17.782.958 24.996.93 1.993.453 3.285.637 3.285.637s.922 1.836 2.254 2.754c1.332.919 2.818 1.404 2.818 1.404s-1.952 4.8-5.32 9.58c-6.386-.913-22.526-3.449-25.472-6.123-3.688-3.348-2.561-9.182-2.561-9.182m2.088 17.806c-.19-1.814.1-3.23.1-3.23 6.47.847 18.846.924 26.065.887-1.744 2.341-3.861 4.678-6.283 6.393-8.331 5.901-14.497 2.01-19.882-4.05"
                    className="svg-hand-shade"
                />
                <path
                    d="M108.767 60.225v-46.11c0-2.151 1.754-3.911 3.9-3.911h95.66c2.145 0 3.9 1.76 3.9 3.91v46.111c0 2.152-1.755 3.912-3.9 3.912h-95.66c-2.146 0-3.9-1.76-3.9-3.912"
                    className="svg-smartphone"
                />
                <path
                    d="M113.502 31.826v10.77a.771.771 0 01-.769.769.771.771 0 01-.769-.77v-10.77c0-.423.346-.769.77-.769.422 0 .768.346.768.77"
                    fillOpacity={0.3}
                    fill="#000"
                />
                <path
                    d="M117.51 58.693V14.919c0-.99.817-1.8 1.817-1.8h83.069c1 0 1.816.81 1.816 1.8v43.774c0 .99-.817 1.8-1.816 1.8h-83.069c-1 0-1.817-.81-1.817-1.8"
                    fill="#FFF"
                />
                <path
                    d="M124.796 39.357c0-6.413 5.247-11.662 11.657-11.662v11.662h-11.657z"
                    className="svg-smartphone-chart-disabled"
                />
                <path
                    d="M148.11 39.357c0-6.413-5.246-11.662-11.657-11.662v11.662l7.967 8.487c2.266-2.075 3.69-5.119 3.69-8.487z"
                    className="svg-smartphone-chart-active"
                />
                <path
                    d="M136.453 39.357h-11.657c0 3.044 1.229 5.896 3.172 7.969 2.138 2.267 5.182 3.692 8.485 3.692 3.043 0 5.829-1.23 7.967-3.174l-7.967-8.487z"
                    className="svg-smartphone-chart-active"
                />
                <path className="svg-smartphone-appbar" d="M117.51 13.119h86.703v5.831H117.51z" />
                <rect
                    className="svg-smartphone-text"
                    x={155.499}
                    y={26.308}
                    width={40.073}
                    height={5.102}
                    rx={2.5}
                />
                <rect
                    className="svg-smartphone-text-light"
                    x={155.499}
                    y={36.511}
                    width={34.973}
                    height={5.102}
                    rx={2.5}
                />
                <rect
                    className="svg-smartphone-text-light"
                    x={154.771}
                    y={47.444}
                    width={37.887}
                    height={5.102}
                    rx={2.5}
                />
                <path
                    d="M108.767 27.695v-4.56c6.503-7.269 12.416-12.931 14.517-12.931h4.426l-4.194 3.054c-3.042 1.22-12.22 11.522-14.75 14.437"
                    fillOpacity={0.1}
                    fill="#000"
                />
                <path
                    d="M186.726 38.282c6.228 12.461 7.634 31.154 8.84 45.396 2.387 28.212 10.849 19.267 18.28 42.132 4.82 14.835 5.624 38.176 5.624 38.176h48.32c-3.29-8.301-11.498-31.032-8.745-44.901 3.415-17.21 18.883-29.67 18.883-34.22 0-4.55-.602-10.88-.602-10.88s2.51-5.934 1.807-9.395c-.703-3.462-9.542-12.561-9.542-12.561s1.105-4.55-.904-6.824c-2.009-2.275-9.04-7.121-12.455-11.473-3.415-4.351-6.528-9.692-14.966-10.483-8.437-.792-29.127-1.384-29.127-1.384s-.219 2.351 0 4.697c.181 1.934.601 3.066 7.632 6.923s15.97 5.044 15.97 5.044.904 1.681 2.21 2.522c1.306.84 2.762 1.286 2.762 1.286s-4.369 10.038-11.3 14.885c-20.419 14.277-27.414-31.38-42.687-18.94"
                    className="svg-hand"
                />
                <path
                    d="M212.227 51.775v5.074c-2.871-2.956-5.469-6.866-8.015-10.424v-5.61c2.008 2.489 3.772 5.189 5.293 7.314.766 1.07 1.689 2.357 2.722 3.646"
                    fillOpacity={0.1}
                    fill="#000"
                />
                <path
                    d="M270.515 53.486a97.272 97.272 0 00-1.464-1.74c-.464 4.486-5.094 14.577-5.094 14.577s4.212-5.812 6.558-12.837m5.828 22.87a164.225 164.225 0 00-.213-2.744c-1.71 3.433-7.801 10.932-7.801 10.932s4.576-3.428 8.014-8.188"
                    className="svg-hand-shade"
                />
                <path
                    d="M18.421 143.386c7.966-8.255 21.223-21.015 29.879-24.176 13.066-4.773 22.766-7.557 31.081-17.302 8.315-9.744 26.132-25.554 31.379-26.25 5.246-.696 16.827-.696 16.827-.696s-1.386-12.081-14.6-13.523c-13.215-1.442-21.332 6.115-25.192 5.717-3.86-.397-7.078-7.855 1.039-19.986 8.117-12.13 29.349-36.789 33.754-36.789h4.405s.61-10.607-13.006-7.056h-.006c-2.063.109-2.842-.201-5.45-1.372l-.08-.036-.018-.008c-7.7-3.31-28.14-2.579-36.773 5.538-7.721 7.258-17.323 21.875-17.323 21.875s-4.553-1.193-10.295 4.773C38.62 39.73 8.622 102.689.914 114.426l17.507 28.96zm82.439-132.26c-13.013-.55-12.535-.367-16.828 12.28l16.828-12.28z"
                    className="svg-hand"
                />
                <path
                    d="M54.122 29.253s-.896-.247-2.39.036c-3.556 7.884-7.082 18.085-7.082 18.085s3.142-6.622 9.472-18.12m30.063-6.597c-8.627 4.511-12.577 5.767-12.577 5.767s13.387-8.375 14.571-10.93l.001-.002c-.58 1.408-1.212 3.11-1.995 5.165"
                    className="svg-hand-shade"
                />
            </g>
        </svg>
    )
}

export default ImgHands
