import * as React from "react"

function Exam(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g transform="translate(1 3)" fill="none">
                <path
                    d="M7.595 14.914c-.723 0-1.31.576-1.31 1.286 0 .71.587 1.286 1.31 1.286.724 0 1.31-.576 1.31-1.286 0-.71-.586-1.286-1.31-1.286z"
                    fill="#D0A12D"
                />
                <path
                    d="M5.5 14.4c-.723 0-1.31.576-1.31 1.286 0 .71.587 1.285 1.31 1.285.723 0 1.31-.575 1.31-1.285S6.223 14.4 5.5 14.4zm4.19 1.029c-.723 0-1.309.575-1.309 1.285S8.967 18 9.691 18c.723 0 1.309-.575 1.309-1.286 0-.71-.586-1.285-1.31-1.285z"
                    fill="#D0A12D"
                />
                <path
                    d="M20.403 10.286a3.181 3.181 0 01-1.85-.591l-5.842-4.176a1.317 1.317 0 00-.925-.376H6.024c-.38 0-.718.16-.957.413l-.009.004L.35 10.698l.003.002c-.217.23-.352.534-.352.871 0 .71.586 1.286 1.31 1.286.361 0 .688-.144.926-.376l.84 1.027 1.025 1.788 7.843 2.096.065.01c.18.049.367.084.562.084H22v-7.2h-1.597z"
                    fill="#FDC436"
                />
                <path
                    d="M8.695 8.229l-3.881 4.616h4.614c1.47 0 2.095-.555 2.095-1.53 0-.763-1.478-1.97-2.828-3.086z"
                    fill="#D0A12D"
                />
                <path
                    d="M1.184 13.644c-.17.183-.303.396-.394.626L.031 16.2c-.144.368.246.717.605.543l1.893-.917c.231-.111.438-.265.611-.452l5.782-6.26-1.95-1.738-5.788 6.268zM15.284.337a1.334 1.334 0 00-1.86.085L7.848 6.433l1.95 1.737 5.574-6.007a1.276 1.276 0 00-.087-1.826z"
                    fill="#2B97DD"
                />
                <path fill="#78BCE9" d="M6.971 7.377l1.95 1.737.876-.943-1.95-1.737z" />
                <path
                    d="M11.356 17.234l-1.804-4.377c-.295 0-4.738-.51-4.738-.51l-.001.006c-.034-.002-.065-.01-.099-.01-.868 0-1.571.69-1.571 1.543 0 .79.606 1.433 1.386 1.524l6.827 1.824z"
                    fill="#FDC436"
                />
                <ellipse fill="#FDD97F" cx={4.714} cy={13.886} rx={1.048} ry={1.029} />
            </g>
        </svg>
    )
}

export default Exam
