import React from 'react';
import useReactRouter from 'use-react-router';
import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';
import FeedCardHeader from '../FeedCardHeader';
import FeedCardFooter from '../FeedCardFooter';
import { Icon } from '../../../../components/Images/Images';

export default function CardNewTraining(props) {
    const { history } = useReactRouter();
    const { subheader, instructor, description, reference, day, month, singleClass } = props;

    return (
        <Card className="card card-new-training">
            <CardActionArea
                onClick={() => history.push({
                    pathname: `${history.location.pathname.replace(/./g, '')}/classroom/${JSON.parse(reference).id}`,
                    state: { i: JSON.parse(reference).id, origin: history.location.pathname, mode: 'detail' }
                })}
            >
                <FeedCardHeader {...props} />
                <CardContent className="d-flex flex-row pt-0">
                    <div className="card-image-square mr-3">
                        <div className="calendar-content">
                            <div className="calendar-date">
                                <Typography variant="body2" className="day" component="p" noWrap>
                                    {day}
                                    {singleClass ? "+" : null}
                                </Typography>
                                <Typography variant="caption" className="month" component="p" noWrap>
                                    {month}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Typography gutterBottom variant="body1" component="h4">
                            {subheader}
                        </Typography>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <Icon ic="tuition" color="light" className="icon-xxs mr-1" />
                            <Typography variant="body2" className="font-weight-bold" component="p">
                                {instructor}
                            </Typography>
                        </div>
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <FeedCardFooter {...props} />
        </Card>
    )
}