import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { validateCPF } from 'validations-br';
import { maskCPF } from '../../utils/utils';
import * as moment from 'moment';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from "date-fns/locale/pt-BR";
import { Button, TextField, Grid, Hidden, IconButton, Typography, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import ImgChangePassword from '../../dist/img/svgr/ImgChangePassword';

export default function RecoverPassword(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [recoveryData, setRecoveryData] = useState({});
    const [loading, setLoading] = useState(false);
    const [tokenCaptcha, setTokenCaptcha] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");
    const [iconFocus, setIconFocus] = useState("");
    const [redirectPage, setRedirectPage] = useState(false);
    const regex = config.login_method.password.complex ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%])[a-zA-Z\d]/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';
    const type = props.location.state && props.location.state.type ? props.location.state.type : '';
    let haveEmail = type === "email";

    const validations = yup.object().shape({
        email: haveEmail ?
            yup.string().email(() => intl.get('ERROR_INVALID_EMAIL')).required(() => intl.get('ERROR_REQUIRED_FIELD'))
            : null,
        cpf: !haveEmail ?
            yup.string()
                .test("is-cpf", intl.get('ERROR_INVALID_CPF'), (value) => validateCPF(value))
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            : null,
        pincode: !haveEmail && config.login_method.first_access.code_verify ?
            yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            : null,
        birth_date: !haveEmail ?
            yup.date().required(() => intl.get('ERROR_REQUIRED_FIELD')).typeError(() => intl.get('ERROR_INVALID_DATE')).nullable()
            : null,
        password: !haveEmail ?
            yup.string()
                .min(config.login_method.password.min, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
                .matches(regex, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
                .required(() => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            : null,
        confirm_password: !haveEmail ?
            yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
                .oneOf([yup.ref('password'), null], () => intl.get('ERROR_PASSWORD_NOT_MATCH'))
            : null
    });

    // --- Google Captcha ---
    const sendData = function (data) {
        setRecoveryData({ ...recoveryData, ...data });

        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            if (haveEmail) {
                dispatch(AuthActions.setRecoverPasswordEmail({
                    email: data.email,
                    auth_captcha: tokenCaptcha,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
                }));
            } else {
                dispatch(AuthActions.setRecoverPasswordCPF({
                    cpf: data.cpf,
                    password: data.password,
                    birth_date: moment(data.birth_date).format('YYYY-MM-DD'),
                    auth_captcha: tokenCaptcha,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
                }));
            }
            setLoading(true);
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallbackRecovery = onSubmitWithRecoveryReCAPTCHA;

    async function onSubmitWithRecoveryReCAPTCHA(token) {

        if (config.captcha.type === 'invisible') {
            if (haveEmail) {
                dispatch(AuthActions.setRecoverPasswordEmail({
                    email: recoveryData.email,
                    auth_captcha: token,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
                }));
            } else {
                dispatch(AuthActions.setRecoverPasswordCPF({
                    cpf: recoveryData.cpf,
                    password: recoveryData.password,
                    birth_date: moment(recoveryData.birth_date).format('YYYY-MM-DD'),
                    auth_captcha: token,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
                }));
            }
            setLoading(true);
        } else {
            setTokenCaptcha(token);
        }
    }
    // --- end. Google Captcha ---

    useEffect(() => {
        if (redirect === "N") {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }

        if (loading && auth.status === "success") {
            window.grecaptcha.reset();
            setTokenCaptcha("");

            if (haveEmail) {
                setSnackStatus("success");
                setSnackOpen(true);
                setSnackMessage(intl.get('INTRO_RECOVER_PASSWORD_SUCCESS'));

                setTimeout(function () {
                    history.replace('/');
                }, 4000);
            }

            if (!haveEmail && (config.login_method.first_access.questions && (auth.recoverPassword.data && auth.recoverPassword.data.questions))) {
                history.push({
                    pathname: '/first-access-questions',
                    state: {
                        data: recoveryData,
                        questions: auth.recoverPassword.data ? auth.recoverPassword.data.questions : "",
                        date: recoveryData.birth_date,
                        redirect: "N"
                    }
                });
            }

            if (!haveEmail && (!config.login_method.first_access.questions || (auth.recoverPassword.data && !auth.recoverPassword.data.questions))) {
                setSnackStatus("success");
                setSnackOpen(true);
                setSnackMessage(auth.recoverPassword.messages);

                setTimeout(function () {
                    history.replace('/');
                }, 4000);
            }

            setLoading(false);
            setRecoveryData({});
            auth.status = "";
        }

        if (loading && auth.status === "error") {
            window.grecaptcha.reset();
            setTokenCaptcha("");
            setRecoveryData({});
            setLoading(false);
            setSnackStatus("error");
            setSnackOpen(true);
            setSnackMessage(auth.recoverPassword.messages);
            auth.status = "";
        }
    }, [auth, loading, recoveryData, haveEmail, history, redirect]);

    const handleFocusIn = (e) => {
        if (e.currentTarget === e.target) {
            setIconFocus(e.target.name);
        }
    };

    const handleFocusOut = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIconFocus("");
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        !redirectPage ?
            <IntroLayout title={intl.get("INTRO_RECOVER_PASSWORD_TITLE")} appbarMobile="default">
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={2}>
                                <IconButton aria-label={intl.get('BTN_BACK')} onClick={() => history.push('/')}>
                                    <Icon ic="back" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get("INTRO_RECOVER_PASSWORD_TITLE")}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Hidden>
                    <Formik
                        validationSchema={validations}
                        initialValues={{
                            email: '',
                            cpf: '',
                            pincode: '',
                            birth_date: null,
                            password: '',
                            confirm_password: '',
                        }}
                        onSubmit={(data) => { sendData(data) }}
                    >
                        {
                            ({ values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit} className="w-100">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} align="center">
                                            <ImgChangePassword className="img-xl" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component="p" variant="body2">
                                                {
                                                    haveEmail ?
                                                        intl.get("INTRO_RECOVER_PASSWORD_TEXT_1")
                                                        :
                                                        intl.get("INTRO_RECOVER_PASSWORD_TEXT_2")
                                                }
                                            </Typography>
                                        </Grid>
                                        {
                                            haveEmail ?
                                                <Grid item xs={12}>
                                                    <Grid container alignItems="center" className="flex-nowrap" spacing={1}>
                                                        <Grid item>
                                                            <Icon ic="envelope_filled" color={iconFocus === "email" ? "primary" : "light"} />
                                                        </Grid>
                                                        <Grid item className="w-100">
                                                            <TextField
                                                                name="email"
                                                                autoComplete="email"
                                                                type="email"
                                                                fullWidth
                                                                label={intl.get('LABEL_EMAIL')}
                                                                variant={config.layout.input_variant}
                                                                onBlur={e => {
                                                                    handleBlur(e)
                                                                    handleFocusOut(e)
                                                                }}
                                                                onChange={handleChange}
                                                                onFocus={handleFocusIn}
                                                                value={values.email}
                                                                error={(errors.email && touched.email)}
                                                                helperText={(errors.email && touched.email) && errors.email}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="cpf"
                                                            type="text"
                                                            label={intl.get('LABEL_CPF')}
                                                            fullWidth
                                                            required
                                                            variant={config.layout.input_variant}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={maskCPF(values.cpf)}
                                                            error={(errors.cpf && touched.cpf)}
                                                            helperText={(errors.cpf && touched.cpf) && errors.cpf}
                                                        />
                                                    </Grid>
                                                    {
                                                        config.login_method.first_access.code_verify ?
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    name="pincode"
                                                                    type="text"
                                                                    label={intl.get('LABEL_CHECKER_CODE')}
                                                                    fullWidth
                                                                    required
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.pincode}
                                                                    error={(errors.pincode && touched.pincode)}
                                                                    helperText={(errors.pincode && touched.pincode) && errors.pincode}
                                                                />
                                                                <Alert icon={false} severity="info" className="rounded mt-3">
                                                                    {intl.get('INFO_CHECKER_CODE')}
                                                                </Alert>
                                                            </Grid>
                                                            : null
                                                    }
                                                    <Grid item xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                                            <KeyboardDatePicker
                                                                id="birth_date"
                                                                name="birth_date"
                                                                margin="none"
                                                                label={intl.get('LABEL_BIRTHDAY')}
                                                                fullWidth
                                                                required
                                                                inputVariant={config.layout.input_variant}
                                                                format="dd/MM/yyyy"
                                                                onBlur={handleBlur}
                                                                onChange={value => setFieldValue("birth_date", value)}
                                                                value={values.birth_date}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': intl.get('LABEL_BIRTHDAY'),
                                                                }}
                                                                okLabel={intl.get('BTN_CONFIRM')}
                                                                clearLabel={intl.get('BTN_CLEAR')}
                                                                cancelLabel={intl.get('BTN_CANCEL')}
                                                                error={(errors.birth_date && touched.birth_date)}
                                                                helperText={(errors.birth_date && touched.birth_date) && errors.birth_date}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="password"
                                                            name="password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            label={intl.get('LABEL_PASSWORD')}
                                                            fullWidth
                                                            required
                                                            variant={config.layout.input_variant}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                        >
                                                                            {showPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                            }}
                                                            value={values.password}
                                                            error={(errors.password && touched.password)}
                                                            helperText={(errors.password && touched.password) && errors.password}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                            label={intl.get('LABEL_RETYPE_PASSWORD')}
                                                            fullWidth
                                                            required
                                                            variant={config.layout.input_variant}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowConfirmPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                        >
                                                                            {showConfirmPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                            }}
                                                            value={values.confirm_password}
                                                            error={(errors.confirm_password && touched.confirm_password)}
                                                            helperText={(errors.confirm_password && touched.confirm_password) && errors.confirm_password}
                                                        />
                                                    </Grid>
                                                </>
                                        }

                                        {/* Captcha */}
                                        <Grid container justify="center">
                                            {config.captcha.type === 'invisible'
                                                ? <div className="g-recaptcha"
                                                    data-sitekey={config.captcha.invisible_key}
                                                    data-callback="myCallbackRecovery"
                                                    data-size="invisible">
                                                </div>
                                                : <div className="g-recaptcha mt-3"
                                                    data-sitekey={config.captcha.key}
                                                    data-callback="myCallbackRecovery"
                                                >
                                                </div>
                                            }
                                        </Grid>

                                        {/* Button */}
                                        <Grid item xs={12} sm={8}>
                                            {
                                                !loading
                                                    ?
                                                    <Button
                                                        id="btn_intro_recover_password_send"
                                                        variant="contained"
                                                        className="btn-block"
                                                        type="submit"
                                                        color="primary"
                                                        value={"confirmated"}
                                                        onSubmit={() => this.onSubmit()}
                                                    >
                                                        {intl.get('BTN_SEND')}
                                                    </Button>
                                                    :
                                                    <Grid container alignItems="center" justify="center">
                                                        <LoadingBounce />
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{ pathname: `${props.match.path.replace(/./g, '')}/` }} />
    )
}
