import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ManagerActions } from "../../redux/ducks/manager";
import { Route, Switch } from 'react-router-dom';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import {
    AppBar, Toolbar, IconButton, Typography, useMediaQuery
} from '@material-ui/core';
import Panel from './panel';
import { Icon } from '../../components/Images/Images';

export default function Manager(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_MANAGER_PANEL');

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }

        return () => {
            dispatch(ManagerActions.reset());
        }
    }, [auth, history, dispatch]);

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('MANAGER_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("MANAGER_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                auth.authenticated &&
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="manager" bottomNavigation={bottomMenuItem} hideSidebar>
                            {
                                config.layout.navbar ?
                                    <QrAppBar />
                                    : null
                            }
                            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                                <Switch>
                                    <Route path={`${props.match.path}`} exact component={
                                        (props) => <Panel {...props} />}
                                    />
                                </Switch>
                            </div>
                        </QrContent>
                    </div>
                </div>
            }
        </div>
    )
};
