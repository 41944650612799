import React, { useState } from 'react';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';

export default function MenuMore(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {
                isScreenUpMd ?
                    <Redirect to={{
                        pathname: `${history.location.pathname.replace(/./g, '')}/home`,
                        state: {
                            origin: 'more'
                        }
                    }} />
                    :
                    auth.authenticated &&
                    <div id="qr-wrap">
                        <div id="qr-content">
                            <QrContent id="more" bottomNavigation={true}>
                                <QrMenu more={true} />
                            </QrContent>
                        </div>
                    </div>
            }
        </div>

    )
}