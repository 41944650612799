import * as React from "react"

const EducationalPartnerships = (props) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <defs>
            <clipPath id="a">
                <path d="M24 0v24H0V0h24Z" />
            </clipPath>
            <clipPath id="b">
                <path d="M20.167 9.184v11.595l-7.05.001A2.442 2.442 0 0 1 11 22c-.906 0-1.695-.49-2.117-1.22h-7.05V9.183h18.334ZM11 0l11 5.493v3.08L11 3.051 0 8.573v-3.08L11 0Z" />
            </clipPath>
            <clipPath id="c">
                <path d="M1.222 0a1.22 1.22 0 1 1 0 2.441 1.22 1.22 0 1 1 0-2.441Z" />
            </clipPath>
            <clipPath id="d">
                <path d="m15.278 0 .61 2.181v9.415H0V2.18L.611 0l6.722 1.22h1.223L15.278 0Z" />
            </clipPath>
            <clipPath id="e">
                <path d="m15.84 0 .049.001v10.375h-.543c-1.698.01-6.79.13-6.79 1.22V1.223C8.556.13 13.648.01 15.346 0ZM7.334 1.223v10.375c0-1.091-5.092-1.211-6.79-1.221H0V.001h.543c1.698.01 6.79.13 6.79 1.22Z" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)">
            <g clipPath="url(#b)" transform="translate(1 1)">
                <path fill="#E65748" d="M0 0h22v22H0V0z" />
            </g>
            <g clipPath="url(#c)" transform="translate(10.778 19.338)">
                <path fill="#93382E" d="M0 0h2.444v2.441H0V0z" />
            </g>
            <g clipPath="url(#d)" transform="translate(4.056 8.963)">
                <path fill="#C3C3C3" d="M0 0h15.889v11.596H0V0z" />
            </g>
            <g clipPath="url(#e)" transform="translate(4.056 8.962)">
                <path fill="#EBEBEB" d="M0 0h15.889v11.597H0V0z" />
            </g>
        </g>
    </svg>
)

export default EducationalPartnerships
