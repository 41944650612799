import * as React from "react";

function ImgHeaderCodeRedeem(props) {
    return (
        <svg width={280} height={120} viewBox="0 0 280 120" className="img-header" {...props}>
            <defs>
                <path id="ImgHeaderCodeRedeem__a" d="M0 0h32.111v32.111H0z" />
                <path id="ImgHeaderCodeRedeem__c" d="M0 0h16.056v16.056H0z" />
                <path id="ImgHeaderCodeRedeem__e" d="M0 0h25.148v25.148H0z" />
                <path id="ImgHeaderCodeRedeem__g" d="M0 0h16.765v16.765H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M214.755 88.894h-8.88c-2.923 0-5.29-1.614-5.29-3.607 0-1.992 2.367-3.606 5.29-3.606h1.09c3.013 0 5.825-1.3 6.605-3.284 1.18-2.999-2.15-5.733-6.372-5.733h-41V52.828h37.032c6.177 0 11.163-3.657 10.525-7.952-.555-3.742-5.507-6.474-11.025-6.474h-23.306c-2.923 0-5.29-1.614-5.29-3.607 0-1.992 2.367-3.606 5.29-3.606h14.17c3.46 0 6.61-1.697 6.957-4.043.396-2.7-2.701-4.973-6.579-4.973h-21.161c-2.19 0-3.968-1.212-3.968-2.705 0-1.494 1.778-2.705 3.968-2.705h10.202c3.46 0 6.61-1.697 6.957-4.043.397-2.7-2.7-4.974-6.579-4.974H96.48c-3.46 0-6.61 1.697-6.957 4.043-.397 2.7 2.7 4.974 6.579 4.974h9.258c2.923 0 5.29 1.614 5.29 3.606 0 1.993-2.367 3.607-5.29 3.607H71.472c-5.518 0-10.47 2.73-11.025 6.474-.638 4.295 4.348 7.952 10.525 7.952h6.613c5.113 0 9.258 2.826 9.258 6.312 0 3.485-4.145 6.311-9.258 6.311h-8.759c-5.517 0-10.47 2.73-11.025 6.474-.637 4.295 4.349 7.952 10.525 7.952h44.968v21.64H75.172c-3.013 0-5.824 1.3-6.605 3.283-1.18 3 2.15 5.733 6.372 5.733h3.968c2.923 0 5.29 1.614 5.29 3.607 0 1.992-2.367 3.606-5.29 3.606h-7.435c-5.518 0-10.47 2.73-11.025 6.474-.638 4.295 4.348 7.952 10.525 7.952h129.113c5.518 0 10.47-2.73 11.025-6.473.638-4.296-4.349-7.953-10.525-7.953h-1.323c-2.19 0-3.967-1.212-3.967-2.705s1.777-2.705 3.967-2.705h15.871c3.878 0 6.976-2.274 6.579-4.973-.344-2.346-3.497-4.043-6.957-4.043z"
                    fill="#000"
                    opacity={0.1}
                />
                <g fillRule="nonzero">
                    <path
                        d="M180.48 30.746h-81.6a6.133 6.133 0 00-6.134 6.134v51.733a6.133 6.133 0 006.134 6.133h81.6a6.133 6.133 0 006.133-6.133V36.88a6.133 6.133 0 00-6.133-6.134z"
                        fill="#E65748"
                    />
                    <path
                        d="M114.21 58.556l-4.525 4.525-11.014-11.014c-.455-.455-.485-1.159-.067-1.575l3.017-3.016c.416-.416 1.122-.386 1.574.066l11.015 11.014z"
                        fill="#FDC436"
                    />
                    <path
                        d="M124.305 47.371l3.016 3.017c.416.416.384 1.124-.074 1.583l-11.11 11.11-4.526-4.525 11.11-11.11c.46-.459 1.168-.49 1.584-.075z"
                        fill="#FDC436"
                    />
                    <path fill="#FDCE5A" d="M109.813 30.746h6.4v64h-6.4z" />
                    <path
                        d="M178.08 77.68h-27.734a2.134 2.134 0 010-4.267h27.734a2.134 2.134 0 010 4.267zm0 8.533h-27.734a2.134 2.134 0 010-4.267h27.734a2.134 2.134 0 010 4.267z"
                        fill="#BD483B"
                    />
                    <path fill="#FDCE5A" d="M92.746 58.48h93.867v6.4H92.746z" />
                </g>
                <g transform="rotate(-20 52.132 -101.17)">
                    <mask id="ImgHeaderCodeRedeem__b" fill="#fff">
                        <use xlinkHref="#ImgHeaderCodeRedeem__a" />
                    </mask>
                    <path
                        d="M16.063 2.676a1.11 1.11 0 00-.995.585l-3.791 7.256-9.004 1.326a1.09 1.09 0 00-.88.715c-.13.379-.026.794.268 1.076l6.228 5.96-1.387 8.624c-.063.394.11.788.448 1.02.338.232.784.262 1.153.078l7.953-3.97 7.952 3.97c.37.184.815.154 1.153-.078.338-.232.512-.626.448-1.02l-1.387-8.625 6.228-5.96c.294-.28.398-.696.269-1.075a1.092 1.092 0 00-.881-.715l-9.003-1.326-3.792-7.256a1.11 1.11 0 00-.98-.585z"
                        fill="#FDC436"
                        mask="url(#ImgHeaderCodeRedeem__b)"
                    />
                </g>
                <g transform="rotate(-40 84.55 -25.223)">
                    <mask id="ImgHeaderCodeRedeem__d" fill="#fff">
                        <use xlinkHref="#ImgHeaderCodeRedeem__c" />
                    </mask>
                    <path
                        d="M8.031 1.338a.555.555 0 00-.497.292L5.638 5.26l-4.501.662a.546.546 0 00-.44.358.512.512 0 00.134.538l3.113 2.98-.693 4.312a.518.518 0 00.224.51c.17.116.392.13.577.039l3.976-1.985 3.976 1.985a.573.573 0 00.577-.04.518.518 0 00.224-.509l-.694-4.312 3.114-2.98a.512.512 0 00.134-.538.546.546 0 00-.44-.358l-4.502-.662-1.895-3.63a.555.555 0 00-.49-.292z"
                        fill="#FDD97F"
                        mask="url(#ImgHeaderCodeRedeem__d)"
                    />
                </g>
                <g transform="rotate(50 97.905 261.44)">
                    <mask id="ImgHeaderCodeRedeem__f" fill="#fff">
                        <use xlinkHref="#ImgHeaderCodeRedeem__e" />
                    </mask>
                    <path
                        d="M12.58 2.096a.87.87 0 00-.78.457L8.832 8.237l-7.05 1.038a.855.855 0 00-.69.56.802.802 0 00.21.842l4.877 4.668-1.086 6.754a.812.812 0 00.351.799.897.897 0 00.903.061l6.228-3.11 6.228 3.11c.29.144.638.12.903-.061.265-.182.4-.49.351-.799l-1.086-6.754 4.877-4.668a.802.802 0 00.21-.842.855.855 0 00-.69-.56l-7.05-1.038-2.97-5.684a.87.87 0 00-.767-.457z"
                        fill="#FDCE5A"
                        mask="url(#ImgHeaderCodeRedeem__f)"
                    />
                </g>
                <g transform="rotate(20 78.635 588.747)">
                    <mask id="ImgHeaderCodeRedeem__h" fill="#fff">
                        <use xlinkHref="#ImgHeaderCodeRedeem__g" />
                    </mask>
                    <path
                        d="M8.387 1.397a.58.58 0 00-.52.305l-1.98 3.789-4.7.692a.57.57 0 00-.46.374.535.535 0 00.14.561L4.12 10.23l-.724 4.503a.541.541 0 00.234.532.598.598 0 00.602.041l4.152-2.073 4.152 2.073c.192.096.425.08.602-.04a.541.541 0 00.234-.533l-.724-4.503 3.251-3.112a.535.535 0 00.14-.561.57.57 0 00-.46-.374l-4.7-.692-1.98-3.789a.58.58 0 00-.511-.305z"
                        fill="#FDD97F"
                        mask="url(#ImgHeaderCodeRedeem__h)"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgHeaderCodeRedeem
