// Função para registrar o Service Worker
export const registerServiceWorker = () => {
    // console.log('[serviceWorker.js:3] Verificando se o navegador é o IE11.');
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // Verifica se o navegador suporta Service Workers e não é o IE11
    if ('serviceWorker' in navigator && !isIE11) {
        // console.log('[serviceWorker.js:7] O navegador suporta Service Workers e não é IE11.');

        // Registra o Service Worker quando a página é carregada
        window.addEventListener('load', function () {
            // console.log('[serviceWorker.js:11] Tentando registrar os Service Workers.');

            // Registra o Service Worker principal para cache e funcionalidade offline (sw.js)
            navigator.serviceWorker.register('/sw.js').then(function (registration) {
                if (registration) {
                    // console.log('[serviceWorker.js:14] Registro do Service Worker sw.js bem-sucedido:', registration);
                }
            }).catch(function (err) {
                // console.error('[serviceWorker.js:18] Falha ao registrar o Service Worker sw.js:', err);
            });

            // Registra o Service Worker para Firebase Messaging (firebase-messaging-sw.js)
            navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
                if (registration) {
                    // console.log('[serviceWorker.js:22] Registro do Service Worker firebase-messaging-sw.js bem-sucedido:', registration);
                }
            }).catch(function (err) {
                // console.error('[serviceWorker.js:26] Falha ao registrar o Service Worker firebase-messaging-sw.js:', err);
            });
        });
    } else {
        // console.warn('[serviceWorker.js:30] O navegador não suporta Service Workers ou é IE11.');
    }
};
