import * as React from "react"

function MedalGold(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <path className="svg-medal-gold-ribbon" d="M18 14H6v10l6-1.667L18 24z" />
                <path
                    d="M18 17l-.298.006c-1.097.02-2.09.519-2.66 1.335l-.169.242c-.219.315-.68.423-1.05.246l-.286-.138A3.568 3.568 0 0012 18.346c-.528 0-1.056.115-1.538.346l-.284.136c-.37.177-.833.07-1.052-.245l-.168-.243c-.569-.815-1.563-1.314-2.66-1.334L6 17v2.086l.249.005a.81.81 0 01.656.33l.17.242c.887 1.273 2.757 1.71 4.263.991l.285-.136a.886.886 0 01.756 0l.287.137a3.569 3.569 0 001.538.345c1.079 0 2.125-.478 2.724-1.338l.168-.242a.812.812 0 01.657-.33l.247-.004V17z"
                    fill="#000"
                    fillRule="nonzero"
                    opacity={0.16}
                />
                <path
                    d="M21.603 10.938a1.946 1.946 0 010-1.877l.154-.28a1.947 1.947 0 00-.698-2.604l-.274-.166a1.949 1.949 0 01-.938-1.626l-.008-.32a1.946 1.946 0 00-1.906-1.906l-.32-.007a1.945 1.945 0 01-1.625-.939l-.166-.274a1.948 1.948 0 00-2.605-.698l-.28.154a1.946 1.946 0 01-1.877 0l-.28-.153A1.947 1.947 0 008.177.94l-.165.274c-.346.57-.96.924-1.626.938l-.32.007A1.946 1.946 0 004.16 4.066l-.007.32a1.945 1.945 0 01-.94 1.625l-.273.166a1.948 1.948 0 00-.698 2.605l.154.28a1.946 1.946 0 010 1.877l-.153.28a1.947 1.947 0 00.698 2.604l.273.166c.571.346.925.959.94 1.625l.006.32a1.946 1.946 0 001.907 1.907l.32.007c.667.013 1.28.368 1.625.939l.166.273a1.948 1.948 0 002.605.698l.28-.154a1.946 1.946 0 011.877 0l.28.154a1.947 1.947 0 002.605-.698l.165-.273c.346-.57.96-.925 1.626-.94l.32-.006a1.946 1.946 0 001.906-1.907l.007-.32c.014-.667.369-1.28.94-1.625l.273-.166a1.948 1.948 0 00.698-2.605l-.156-.28z"
                    className="svg-medal-gold"
                />
                <path
                    d="M12 18c-4.411 0-8-3.588-8-8 0-4.41 3.588-8 8-8s8 3.589 8 8-3.589 8-8 8zm0-14.667A6.674 6.674 0 005.333 10 6.674 6.674 0 0012 16.667 6.674 6.674 0 0018.667 10 6.674 6.674 0 0012 3.333z"
                    fill="#000"
                    opacity={0.199}
                />
                <path
                    d="M13.77 13.5c.487 0 .73-.207.73-.622 0-.408-.243-.612-.73-.612h-.93V7.25c0-.23-.07-.413-.21-.548a.746.746 0 00-.54-.202c-.233 0-.467.07-.7.207l-1.58.938a.569.569 0 00-.31.524c0 .17.058.325.175.464a.537.537 0 00.425.207c.1 0 .2-.03.3-.089l.88-.523v4.038h-.93c-.493 0-.74.204-.74.612 0 .415.247.622.74.622h3.42z"
                    fill="#000"
                    opacity={0.2}
                />
            </g>
        </svg>
    )
}

export default MedalGold
