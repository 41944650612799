import React from 'react';
import intl from 'react-intl-universal';
import ReactHtmlParser from 'react-html-parser';
import {
    Dialog, AppBar, Toolbar, IconButton, Typography,
    DialogContent, Container, DialogContentText
} from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';

export default function DialogExamRules(props) {
    const { open, close, transition, item } = props;

    return (
        <Dialog id="dialog-exam-rules" fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={close} TransitionComponent={transition}>
            <AppBar className="appbar appbar-default" position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("EXAM_DETAILS_RULE_DIALOG_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className="p-0">
                <Container maxWidth="md" className="pt-3">
                    <DialogContentText>
                        {ReactHtmlParser(item.rules)}
                    </DialogContentText>
                </Container>
            </DialogContent>
        </Dialog>
    )
}