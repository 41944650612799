import * as React from "react"

const ImgTimer = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        {...props}
    >
        <defs>
            <path id="ImgTimer__a" d="M0 24h24V0H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="ImgTimer__b" fill="#fff">
                <use xlinkHref="#ImgTimer__a" />
            </mask>
            <g mask="url(#ImgTimer__b)">
                <path
                    fill="#2B97DD"
                    d="m17.98 5.155 1.75 1.383-1.768 2.139-1.75-1.383zM10.313 3.75h3.375v2.2h-3.375z"
                />
                <path
                    fill="#000"
                    opacity={0.1}
                    d="m17.98 5.155 1.75 1.383-1.768 2.139-1.75-1.383zM10.313 3.75h3.375v2.2h-3.375z"
                />
                <path
                    d="M12 6.775c-4.194 0-7.594 3.324-7.594 7.425 0 4.1 3.4 7.425 7.594 7.425 4.194 0 7.594-3.324 7.594-7.425 0-4.1-3.4-7.425-7.594-7.425Z"
                    fill="#F5F5F5"
                />
                <path
                    d="M11.232 15.365a1.43 1.43 0 0 0 1.964-.42 1.395 1.395 0 0 0-.43-1.942 1.432 1.432 0 0 0-1.965.425 1.389 1.389 0 0 0 .43 1.937"
                    fill="#E65748"
                />
                <path fill="#E65748" d="m6.813 10.9 5.299 2.246-1.023 1.573z" />
                <path
                    d="M11.744 14.58c.22.138.514.075.653-.143a.46.46 0 0 0-.143-.645.478.478 0 0 0-.656.14.466.466 0 0 0 .146.648"
                    fill="#93382E"
                />
                <path
                    d="M12.557 5.983c-.21-.014-.419-.033-.63-.033-.166 0-.33.017-.495.026V8.7h1.125V5.983Zm0 13.75c-.21-.014-.419-.033-.63-.033-.166 0-.33.017-.495.026v2.724h1.125v-2.717ZM3.591 14.75c-.014-.206-.034-.41-.034-.616 0-.163.018-.322.027-.484H6.37v1.1H3.59Zm14.063 0c-.014-.206-.034-.41-.034-.616 0-.163.017-.322.026-.484h2.786v1.1h-2.778Zm-1.002-7.413c-.176-.114-.347-.231-.53-.334-.145-.08-.295-.144-.443-.218l-1.382 2.366.977.546 1.378-2.36Zm-6.975 11.94c-.176-.114-.347-.232-.53-.334-.145-.08-.295-.145-.443-.218l-1.382 2.366.977.546 1.378-2.36Zm6.952-1.856c.092-.185.179-.372.284-.55.082-.142.178-.272.268-.408l2.42 1.351-.558.955-2.414-1.348Zm2.835-7.028c-.098-.183-.19-.367-.299-.543-.087-.14-.187-.266-.28-.4l-2.379 1.418.586.939 2.372-1.414ZM7.456 17.549c-.097-.183-.19-.367-.3-.542-.086-.14-.185-.267-.28-.4l-2.378 1.417.585.94 2.373-1.415Zm-.315-10.08c.172-.118.34-.24.52-.348.143-.085.291-.153.437-.23l1.45 2.326-.961.572-1.446-2.32ZM14.46 19.21c.172-.118.34-.241.52-.348.142-.085.29-.153.437-.23l1.45 2.326-.961.572-1.446-2.32Z"
                    fill="#2B97DD"
                />
                <path
                    d="M19.814 7.312a.57.57 0 0 1-.791.082l-1.75-1.383a.541.541 0 0 1-.083-.773l1.061-1.283a.57.57 0 0 1 .79-.082l1.75 1.383a.54.54 0 0 1 .084.773l-1.061 1.283ZM12 5.4c-4.97 0-9 3.94-9 8.8 0 4.86 4.03 8.8 9 8.8s9-3.94 9-8.8c0-4.86-4.03-8.8-9-8.8Zm0 15.95c-4.04 0-7.313-3.2-7.313-7.15 0-3.949 3.274-7.15 7.313-7.15 4.04 0 7.313 3.201 7.313 7.15 0 3.95-3.274 7.15-7.313 7.15Zm2.813-17.6c0 .304-.252.55-.563.55h-4.5a.556.556 0 0 1-.563-.55v-2.2c0-.304.252-.55.563-.55h4.5c.311 0 .563.246.563.55v2.2Z"
                    fill="#2B97DD"
                />
            </g>
        </g>
    </svg>
)

export default ImgTimer
