import { qrService } from './base.service'


function setUserToken(params) {
    return qrService('/v1/mobile/push/register', params);
}

export const notificationService = {
    setUserToken,
}
