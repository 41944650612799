import React, { useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import config from '../config';
import { useSelector, useDispatch } from 'react-redux';
import { actions as QrLayoutActions } from '../redux/ducks/qr_layout';
import { actions as NotificationActions } from '../redux/ducks/notification_module';
import { Grid, Hidden, Drawer, Typography, IconButton } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import FeedsList from '../scenes/Feeds/list';
import NotificationsList from '../scenes/Notifications/list.notifications';
import { Icon } from './Images/Images';

const QrSidebar = (props) => {
    const dispatch = useDispatch();
    const sidebarOpen = useSelector(state => state.qrLayout.sidebarOpen);
    const scrollbarFeeds = useRef(null);
    let scrollPosition = localStorage.getItem('scrollPosition') ? parseInt(localStorage.getItem('scrollPosition')) : 0;

    useEffect(() => {
        scrollbarFeeds.current && scrollbarFeeds.current.view.scroll({
            top: scrollPosition
        });
    });

    window.onunload = function () {
        localStorage.removeItem('scrollPosition');
    }

    const handleSidebarDrawer = () => {
        dispatch(QrLayoutActions.sidebarDrawer({
            sidebarOpen: !sidebarOpen
        }))
    };

    function handleScrollFrame(e) {
        return localStorage.setItem('scrollPosition', e.scrollTop);
    }

    return (
        <Hidden mdDown>
            <aside id="qr-right" className={sidebarOpen ? "__open" : "__close"}>
                <Grid container id="qr-right_header" direction="row" justify="space-between" alignItems="center">
                    {
                        sidebarOpen ?
                            <Typography variant="h4" component="h3" className="ml-2">
                                {
                                    config.feeds.enabled ?
                                        intl.get("RIGHT_PRINCIPAL_COLUMN_TITLE")
                                        :
                                        intl.get("MENU_NOTIFICATIONS")
                                }
                            </Typography>
                            : null
                    }
                    <div>
                        {
                            sidebarOpen && !config.feeds.enabled ?
                                <IconButton
                                    onClick={() => dispatch(NotificationActions.updateAll(true))}
                                >
                                    <Icon ic="double_tick" />
                                </IconButton>
                                : null
                        }
                        <IconButton onClick={handleSidebarDrawer}>
                            <Icon ic={sidebarOpen ? "swipe_right" : config.feeds.enabled ? "feed_filled" : "bell_filled"} />
                        </IconButton>
                    </div>
                </Grid>
                <Drawer
                    id="qr-right_drawer"
                    variant="persistent"
                    anchor="right"
                    open={sidebarOpen}
                >
                    <Grid container id="qr-right_content">
                        <Scrollbars
                            ref={scrollbarFeeds}
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            onScrollFrame={(e) => handleScrollFrame(e)}
                            renderView={props => (
                                <div id="feeds" {...props} style={{ ...props.style, overflowX: 'hidden' }} />
                            )}
                        >
                            <Grid container className="pt-1">
                                {
                                    config.feeds.enabled ?
                                        <FeedsList type="sidebar" />
                                        :
                                        <NotificationsList />
                                }
                            </Grid>
                        </Scrollbars>
                    </Grid>
                </Drawer>
            </aside>
        </Hidden>
    )
}

export default QrSidebar