import * as React from "react"

function ImgStars2(props) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M11.412 16.022a9.834 9.834 0 013.81 1.005 9.934 9.934 0 013.134 2.418c.15-2.68 1.36-5.232 3.372-7.022a9.83 9.83 0 01-3.81-1.005A9.93 9.93 0 0114.783 9a10.185 10.185 0 01-3.371 7.022"
                    className="svg-stars-top-1"
                />
                <path
                    d="M9 29.346a7.51 7.51 0 012.967.454 7.556 7.556 0 012.564 1.57 7.69 7.69 0 012.009-5.55 7.543 7.543 0 01-2.967-.455 7.552 7.552 0 01-2.565-1.57A7.692 7.692 0 019 29.345"
                    className="svg-stars-top-3"
                />
                <path
                    d="M25.82 21.073a5.792 5.792 0 012.281.349c.743.272 1.41.683 1.973 1.208a5.916 5.916 0 011.545-4.27 5.794 5.794 0 01-2.282-.35 5.811 5.811 0 01-1.973-1.207 5.915 5.915 0 01-1.545 4.27"
                    className="svg-stars-top-2"
                />
            </g>
        </svg>
    )
}

export default ImgStars2
