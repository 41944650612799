import React from 'react';
import config from '../config';
import intl from 'react-intl-universal'
import { Grid, Typography } from '@material-ui/core';
import { Logo } from '../components/Images/Images'

export default function Footer() {
    return (
        <footer>
            <Grid container>
                {
                    config.intro.logo.footer.qranio.display || config.intro.logo.footer.group.display ?
                        <Grid item xs={12}>
                            <Typography variant="caption" component="p" align="center" className="mb-1">
                                {intl.get("POWERED_BY")}
                            </Typography>
                        </Grid>
                        : null
                }
                <Grid item xs={12} className="d-flex flex-row align-items-center justify-content-center">
                    {
                        /* Logo Qranio */
                        config.intro.logo.footer.qranio.display ?
                            <Logo type={config.intro.logo.footer.qranio.type} className="logo-footer" />
                            : null
                    }

                    {
                        /* Logo Group */
                        config.intro.logo.footer.group.display ?
                            <Logo type={config.intro.logo.footer.group.type} className="logo-footer ml-2" />
                            : null
                    }
                </Grid>
            </Grid>
        </footer>
    );
};