import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import { libraryService } from '../../services/library.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as libraryActions } from '../../redux/ducks/library';
import { goToTopAnimation } from '../../utils/utils';
import useReactRouter from 'use-react-router';
import {
    Grid, Slide, List, Divider, useMediaQuery, AppBar,
    Toolbar, IconButton, Typography, Container, InputBase
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import DialogFile from './dialog.file';
import FileItem from './components/FileItem';
import FileCategoryItem from './components/FileCategoryItem';
import ErrorContent from '../../components/ErrorContent';
import EmptyContent from '../../components/EmptyContent';
import SnackBar from '../../components/snackBar';
import { Icon } from '../../components/Images/Images';
import { LoadingContent, LoadingAvatarList } from '../../components/Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LibraryList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const list = useSelector(state => state.library.list);
    const file = useSelector(state => state.library.file);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [didMount, setDidMount] = useState(false);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [loadFile, setLoadFile] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [filteredFileList, setFilteredFileList] = useState(null);
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const itemsPerPage = 10;
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_LIBRARY');

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (config.library.have_category) {
                dispatch(libraryActions.getCategoryList({}));
            } else {
                dispatch(libraryActions.getFileList({
                    page: 1,
                    limit: 0,
                    order: 3
                }));
            }
        }

        return () => {
            setDidMount(false);
            dispatch(libraryActions.reset());
        }
    }, [didMount, dispatch]);

    useEffect(() => {
        setFilteredFileList(null);

        async function fetchDataFiltered() {
            let list = await libraryService.getFileList({
                page: 1,
                limit: 0,
                order: 3,
                pattern: search
            });
            setFilteredFileList(list);
        }

        const timer = setTimeout(() => {
            if (search !== "") {
                fetchDataFiltered()
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [search]);

    async function openDialog(item) {
        setLoadFile(true);
        await dispatch(libraryActions.getFile({
            id_file: item.id_file
        }));
        setOpenFile(true);
    }

    if (openFile && (file && file.load === "finished")) {
        if (!file.error && file.data.length !== 0) {
            if (file.data.type === "LINK") {
                window.open(file.data.source, '_blank');
            } else {
                setOpen(true);
            }
        } else {
            setSnackOpen(true);
        }
        setOpenFile(false);
        setLoadFile(false);
    }

    function goBack() {
        let currentOrigin = (origin === "learn") || (origin === "files_learn") ? "list_learn" : "list";

        switch (origin) {
            case 'learn':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'files':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'files_learn':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || (isScreenUpMd && (origin !== "learn" && origin !== "files_learn")) ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        (!bottomMenuItem && !isScreenUpMd) || (origin === "learn" || origin === "files_learn") ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('LIBRARY_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || (isScreenUpMd && (origin !== "learn" && origin !== "files_learn")) ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("LIBRARY_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(''); setFilteredFileList(null); setSearchOpen(false) }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const CategoryList = () => {
        return (
            <List component="nav" className="w-100">
                {
                    list.data
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((item, index) => {
                            return (
                                <div key={index}>
                                    <FileCategoryItem
                                        image={item.image}
                                        bgColor={item.bg_color}
                                        title={item.title}
                                        description={`${item.total_file} ${parseInt(item.total_file) === 1 ? intl.get('LABEL_FILE') : intl.get('LABEL_FILES')}`}
                                        action={() => {
                                            history.push({
                                                pathname: `${props.match.path}/${item.id_category}`,
                                                state: {
                                                    category: item.title,
                                                    origin: (origin === "learn") || (origin === "files_learn") ? "list_learn" : "list"
                                                }
                                            })
                                        }}
                                    />
                                    <Divider variant="inset" />
                                </div>
                            )
                        })
                }
            </List>
        )
    }

    const FileList = () => {
        return (
            <List component="nav" className="w-100">
                {
                    list.data
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((file, index) => {
                            return (
                                <div key={index}>
                                    <FileItem
                                        file={file}
                                        bgColor={file.bgcolor}
                                        blob={file.blob}
                                        content={file.content}
                                        title={file.title}
                                        description={file.description}
                                        date={file.file_date}
                                        type={file.type}
                                        openDialog={openDialog}
                                    />
                                    <Divider variant="inset" />
                                </div>
                            )
                        })
                }
            </List>
        )
    }

    const FilteredFiles = () => {
        if (filteredFileList && filteredFileList.files.length > 0) {
            return (
                <List component="nav" className="w-100">
                    {
                        filteredFileList.files.map((file, index) => {
                            return (
                                <div key={index}>
                                    <FileItem
                                        file={file}
                                        bgColor={file.bgcolor}
                                        blob={file.blob}
                                        content={file.content}
                                        title={file.title}
                                        description={file.description}
                                        date={file.file_date}
                                        type={file.type}
                                        openDialog={openDialog}
                                    />
                                    <Divider variant="inset" />
                                </div>
                            )
                        })
                    }
                </List>
            )
        } else if (filteredFileList && filteredFileList.files.length === 0) {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            );
        }

        return (
            <LoadingContent />
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="library" bottomNavigation={bottomMenuItem} hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    {
                        !loadFile ?
                            list && list.load === "finished" ?
                                !list.error ?
                                    list.data.length !== 0 ?
                                        <>
                                            {
                                                search.length !== 0 ?
                                                    <Container maxWidth="md" className="container-list pt-2 pb-4">
                                                        <FilteredFiles />
                                                    </Container>
                                                    :
                                                    <Container maxWidth="md" className="container-list pt-2 pb-4">
                                                        {
                                                            config.library.have_category ?
                                                                <CategoryList />
                                                                :
                                                                <FileList />
                                                        }
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Pagination
                                                                    count={Math.ceil(list.data.length / itemsPerPage)}
                                                                    page={page}
                                                                    onChange={(e, value) => { setPage(value); goToTopAnimation(); }}
                                                                    defaultPage={1}
                                                                    color="primary"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                            }
                                            {
                                                (file && file.load === "finished") && (!file.error && file.data.length !== 0) ?
                                                    <DialogFile
                                                        open={open}
                                                        close={() => setOpen(false)}
                                                        transition={Transition}
                                                        title={file.data.title}
                                                        file={file.data}
                                                    />
                                                    : null
                                            }
                                        </>
                                        :
                                        <Container maxWidth="md">
                                            <EmptyContent icon={<Icon ic="book_stack_filled" className="icon-xxl" />} text={intl.get("EMPTY_LIBRARY")} />
                                        </Container>
                                    :
                                    <Container maxWidth="md">
                                        <ErrorContent />
                                    </Container>
                                :
                                <Container maxWidth="md" className="container-list pt-2 pb-4">
                                    <LoadingAvatarList limit={10} />
                                </Container>
                            :
                            <Container maxWidth="md">
                                <LoadingContent />
                            </Container>
                    }
                    <SnackBar
                        open={snackOpen}
                        message={intl.get('ERROR_LOAD_FILE')}
                        status="error"
                        time={4}
                        closeSnack={() => setSnackOpen(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    />
                </QrContent>
            </div>
        </div>
    )
}
